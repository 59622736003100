import React from 'react'
import styled from 'styled-components'
import EventStatusesList from 'src/Components/Calendar/Components/Sidebar/Tabs/Legend/Components/EventStatusesList'
import EventTypesList from 'src/Components/Calendar/Components/Sidebar/Tabs/Legend/Components/EventTypesList'
import SlotTypesList from 'src/Components/Calendar/Components/Sidebar/Tabs/Legend/Components/SlotTypesList'

const Divider = styled.div`
  height: 1px;
  margin: 8px 1px;
  overflow: hidden;
  background-color: #e5e5e5;
  border-bottom: 1px solid #fff;
`

const LegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 9px 15px;
  margin-bottom: 20px;
  background-color: #e5e5e5;
  border: 1px solid #d4d4d4;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
  -webkit-transition: 400ms;
  -moz-transition: 400ms;
  -o-transition: 400ms;
  transition: 400ms;
  font-size: 13px;
  color: #4d4d4d;
`

const LegendSidebar = () => {
  return (
    <LegendWrapper>
      <EventStatusesList />
      <Divider/>
      <SlotTypesList />
      <Divider/>
      <EventTypesList />
      <Divider/>
    </LegendWrapper>
  )
}
export default LegendSidebar
