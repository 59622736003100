export enum SidebarTab {
  EVENTS = 'events',
  SLOTS = 'slots',
  OPTIONS = 'options',
  LEGEND = 'legend',
}

export enum View {
  MONTH = 'dayGridMonth',
  WEEK = 'timeGridWeek',
  DAY = 'timeGridDay',
}

export interface Options {
  startWeek: number
  startDay: string
  endDay: string
  weekends: boolean
  initialView: View
}

export enum WeekDay {
  SUNDAY = 'sunday',
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
}
