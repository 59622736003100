import { call, put, takeLatest, select } from 'redux-saga/effects'
import * as types from './actionTypes'
import apiMethods from '../../../Services/api/apiMethods'
import { GlobalActions } from 'src/Types/GlobalActions'
import { Action } from 'src/Services/Store/reducers'
import { setIsLoading, setData } from './actions'
import { contentRangeToPagination } from 'src/Utils/EffectsUtil'
import { AuditTrailPaginationDetails, AuditTrailsState } from 'src/Views/AuditTrails/state/reducer'

const formatPagination = (pagination: any, rows: any): AuditTrailPaginationDetails => {
  const { from, to, totalItemsCount } = pagination

  return {
    // temporarily disabled to prevent bug on search reset
    // offset: from === 0 ? 0 : from - 1,
    // limit: to,
    itemsCount: rows.length,
    totalItemsCount,
  }
}

function* doFetchData(props: GlobalActions, { payload }: Action) {
  try {
    // @ts-ignore
    const state = ((yield select()).AuditTrails as AuditTrailsState)

    const params = {
      offset: state.pagination.offset,
      limit: state.pagination.limit,
      sortItems: state.sortItems,
      filters: state.filters,
    }

    const { data, headers } = yield call(apiMethods.create, '/audit_trails', { data: params })

    const pagination = contentRangeToPagination(headers['content-range'])

    yield put(setData(data, formatPagination(pagination, data)))
    yield put(setIsLoading(false))
  } catch (error) {
    yield put(setIsLoading(false))
    yield put(props.globalActions.handleError(error, 'actionFailed'))
  }
}

export default function* zmrSagaWatcher(props: GlobalActions) {
  yield takeLatest(types.FETCH_DATA, doFetchData, props)
  yield takeLatest(types.PAGINATE, doFetchData, props)
  yield takeLatest(types.SORT, doFetchData, props)
}
