
export default {
  english: {
    title: 'Permissions',
    adminHaveAllRights: 'Admin have all rights regardless the permissions',
    changePermissionsFor: 'Change permissions for',
    noMoreModule: 'No more module',
    role: 'role...',
    regarding: 'regarding',
    subject: 'subject...',
    selectAForm: 'Select a form',
    alreadyHadAPermission: 'There is already a permission for this',
    accessLevel: 'Access level',
    defaultSection: 'Default section',
    allowedSections: 'Allowed sections',
    undefined: 'Undefined',
    authorized: 'Authorized',
    unauthorized: 'Unauthorized',
    allowedSectionsAllByDefault: 'All sections are allowed by default',
    grantAccessToInstancesCreatedByMe: 'Grant access to instances created by me',
    grantAccessToInstancesThatRefersToMe: 'Grant access to instances that refers to me',
    grantAccessToAllLocations: 'Grant access to all locations',
    selectAll: 'Select all',
    permission: {
      landingPage: 'Landing page',
      navigationItem: 'Navigation item',
      module: 'Module',
      form: 'Form',
      section: 'Section',
      field: 'Field',
      list: 'List',
      numericReport: 'Numeric report',
      logAsRole: 'Login as',
      efax: 'Efax',
    },
    formAccessLevels: {
      NONE: 'None',
      READ: 'Read instance',
      READ_DOCUMENT: 'Read document',
      UPDATE: 'Update instance',
      DELETE_DOCUMENT: 'Delete document',
      CREATE: 'Create instance',
      DELETE: 'Delete instance',
      UNLOCK_DOCUMENT: 'Unlock document',
      RESTORE_DOCUMENT: 'Restore document',
      RESTORE: 'Restore instance',
    },
    accessLevels: {
      DEFAULT: 'Default',
      NONE: 'None',
      READ: 'Read',
      LIST: 'Read',
      UPDATE: 'Update',
      CREATE: 'Create',
      DELETE: 'Delete',
      RESTORE: 'Restore',
    },
    moduleDeactivated: 'Has been deactivated, will be unavailable for everyone',
    addField: 'Add field',
    addSection: 'Add section',
    undo: 'Undo',
  },
  french: {
    title: 'Permissions',
    adminHaveAllRights: 'Les administrateurs ont tous les droits sans tenir compte des permissions',
    changePermissionsFor: 'Changer les permissions pour',
    noMoreModule: 'Pas de module restant',
    role: 'role...',
    regarding: 'à propos de',
    subject: 'subject...',
    selectAForm: 'Sélectionnez un formulaire',
    alreadyHadAPermission: 'Il y a déjà une permission pour cela',
    accessLevel: 'Niveau d\'accès',
    defaultSection: 'Section par défaut',
    allowedSections: 'Sections autorisées',
    undefined: 'Indéfini',
    authorized: 'Autorisé',
    unauthorized: 'Non autorisé',
    allowedSectionsAllByDefault: 'Toutes les sections sont autorisées par défaut',
    grantAccessToInstancesCreatedByMe: 'Accès aux instances créées par moi',
    grantAccessToInstancesThatRefersToMe: 'Accès aux instances qui me référencent',
    grantAccessToAllLocations: 'Accès aux instances de toutes les locations',
    selectAll: 'Tout sélectionner',
    permission: {
      landingPage: 'Page d\'accueil',
      navigationItem: 'Élément de navigation',
      module: 'Module',
      form: 'Formulaire',
      section: 'Section',
      field: 'Champ',
      list: 'Liste',
      numericReport: 'Rapport chiffré',
      logAsRole: 'S\'identifier en tant que',
      efax: 'Efax',
    },
    formAccessLevels: {
      NONE: 'Rien',
      READ: 'Lecture d\'instance',
      READ_DOCUMENT: 'Lecture de document',
      UPDATE: 'Modification d\'instance',
      DELETE_DOCUMENT: 'Suppression de document',
      CREATE: 'Création d\'instance',
      DELETE: 'Suppression d\'instance',
      UNLOCK_DOCUMENT: 'Déverrouillage de document',
      RESTORE_DOCUMENT: 'Restoration de document',
      RESTORE: 'Restoration d\'instance',
    },
    accessLevels: {
      DEFAULT: 'Défaut',
      NONE: 'Rien',
      READ: 'Lecture',
      LIST: 'Lecture',
      UPDATE: 'Modification',
      CREATE: 'Création',
      DELETE: 'Suppression',
      RESTORE: 'Restoration',
    },
    moduleDeactivated: 'A été désactivé, donc indisponible pour tout le monde',
    addField: 'Ajouter field',
    addSection: 'Ajouter section',
    undo: 'Annuler',
  }
}
