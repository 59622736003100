import {
  ITEMS_RECEIVED,
  ITEM_CREATED,
  ITEM_DELETED,
  ITEM_RECEIVED,
  FETCH_ITEMS,
  CREATE_ITEM,
  SET_QUERY,
  DELETE_ITEM,
  UPDATE_ITEM,
  ITEM_UPDATED,
  SET_LIMIT,
  SET_PAGE,
  SET_DELETING_ITEM,
  SET_UPDATING_ITEM,
  SET_CREATING_ITEM,
  ADD_ROW,
  REQUEST_NEW_CONTENT,
  ADD_CONTENT,
  UPDATE_CONTENT,
  DELETE_ROW, DELETE_CONTENT, REQUEST_EDIT_CONTENT
} from './actionTypes'

const initialState = {
  isLoading: false,
  creatingItem: null,
  updatingItem: null,
  deletingItem: null,
  items: [],
  query: null,
  pagination: {
    offset: 0,
    limit: 10,
    itemsCount: 10,
    totalItemsCount: 10,
    pageLimit: 10,
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPagesCount: 1,
  },
  form: {
    // Temporary id
    rows: [ { id: `_${ 1 }`, contents: [] } ],
    editingContent: null
  }
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ITEMS:
      return {
        ...state,
        isLoading: true
      }
    case CREATE_ITEM:
      return {
        ...state,
        isLoading: true
      }
    case UPDATE_ITEM:
      return {
        ...state,
        isLoading: true
      }
    case DELETE_ITEM:
      return {
        ...state,
        isLoading: true
      }
    case ITEMS_RECEIVED:
      return {
        ...state,
        isLoading: false,
        items: payload.items,
        pagination: payload.pagination
      }
    case ITEM_CREATED:
      return {
        ...state,
        creatingItem: null,
        form: initialState.form
      }
    case ITEM_UPDATED:
      return {
        ...state,
        updatingItem: null,
        items: state.items.map(_ => _.id === payload.id ? payload : _),
        form: initialState.form
      }
    case ITEM_DELETED:
      return {
        ...state,
        deletingItem: null,
        items: state.items.filter(_ => _.id !== payload)
      }
    case SET_DELETING_ITEM:
      return {
        ...state,
        deletingItem: payload
      }
    case SET_UPDATING_ITEM:
      const form = payload ? {
        ...state.form,
        rows: payload.contents.reduce((rows, content) => {

          const newContent = {
            id: content.id,
            label: content.label,
            type: content.type,
            size: content.size,
            list: content.list || null,
            numericReport: content.numericReport || null
          }

          if (!rows[content.row - 1])
            rows[content.row - 1] = { id: content.row, contents: [] }

          rows[content.row - 1].contents.push(newContent)
          return rows
        }, [])
      } : initialState.form

      return {
        ...state,
        updatingItem: payload,
        form
      }
    case SET_CREATING_ITEM:
      return {
        ...state,
        creatingItem: payload
      }
    case SET_QUERY:
      return {
        ...state,
        query: payload
      }
    case SET_LIMIT:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          pageLimit: +payload,
          currentPage: 1
        }
      }
    case SET_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: payload
        }
      }
    case ADD_ROW:
      return {
        ...state,
        form: {
          ...state.form,
          rows: [
            ...state.form.rows,
            { id: `_${ state.form.rows.length + 1 }`, ...payload.row }
          ]
        }
      }
    case REQUEST_NEW_CONTENT:
      return {
        ...state,
        form: {
          ...state.form,
          editingContent: { row: payload.row, ...payload.content }
        }
      }
    case REQUEST_EDIT_CONTENT:
      return {
        ...state,
        form: {
          ...state.form,
          editingContent: { row: payload.row, ...payload.content }
        }
      }
    case ADD_CONTENT:
      return {
        ...state,
        form: {
          ...state.form,
          rows: state.form.rows.map(r =>
            r.id === payload.row.id
              ? {
                ...r,
                contents: [ ...r.contents, { id: `_${ r.contents.length + 1 }`, ...payload.content } ]
              } : r
          ),
          editingContent: null
        }
      }
    case UPDATE_CONTENT:
      return {
        ...state,
        form: {
          ...state.form,
          rows: state.form.rows.map(r =>
            r.id === payload.row.id
              ? {
                ...r,
                contents: r.contents.map(c => c.id === payload.content.id ? payload.content : c)
              } : r),
          editingContent: null
        }
      }
    case DELETE_ROW:
      return {
        ...state,
        form: {
          ...state.form,
          rows: state.form.rows.filter(r => r.id !== payload.row.id)
        }
      }
    case DELETE_CONTENT:
      return {
        ...state,
        form: {
          ...state.form,
          rows: state.form.rows.map(r =>
            r.id === payload.row.id
              ? {
                ...r,
                contents: r.contents.filter(c => c.id !== payload.content.id)
              } : r
          ),
          editingContent: null
        }
      }
    default:
      return state
  }
}
