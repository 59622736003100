import * as React from 'react'
import { ChangeEvent, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Accordion, Card, Col, Container, Form, FormControl, Row, Spinner } from 'react-bootstrap'
import AccordionContext from 'react-bootstrap/AccordionContext'
import { useAccordionButton } from 'react-bootstrap/AccordionButton'
import {  Field } from 'src/Types/Field'
import Modal from 'src/Components/Modal'
import { translate } from 'src/Services/translation'
import { FieldEvent } from 'src/Views/FormFiller/Types/Field'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { ConsentFieldValue } from 'src/Types/FieldType/ConsentField'
import { StoreState } from 'src/Services/Store/reducers'
import translation from 'src/Views/FormFiller/translations'
import { fieldEvent, saveInstanceFieldValue, toggleSaveLoading } from '../state/actions'

interface ConsentFieldsModalProps {
  isOpen: boolean
  onClose: () => void
  onValid: () => void
  fields: Field[]
}

const ConsentFieldsModal = ({ isOpen = false, onClose, fields, onValid }: ConsentFieldsModalProps) => {
  const dispatch = useDispatch()
  const { id, instanceId: secondId } = useParams()

  const { language } = useSelector((state: StoreState) => state.Root.user)
  const { saveLoading } = useSelector((state: StoreState) => state.FormFiller)

  const [ openedToggle, setOpenedToggle ] = React.useState<string>('0')

  const trans = translate(translation)(language)

  const instanceId = secondId ? secondId : id

  interface CustomToggleProps {
    children: ReactJSXElement
    eventKey: string
  }


  const CustomToggle = ({ children, eventKey }: CustomToggleProps) => {
    const { activeEventKey } = useContext(AccordionContext)

    const decoratedOnClick = useAccordionButton(eventKey, e => {
                                          e.preventDefault()
                                          if (activeEventKey !== eventKey) {
                                            setOpenedToggle(eventKey)
                                          }
                                        },
    )

    return (
        <div className={ 'd-flex align-items-center w-75' }>
          <a href={ '#' } onClick={ decoratedOnClick }>
            { children }
          </a>
        </div>
    )
  }

  const hasCheckFieldsNecessaryLeft = () => {
      const required = fields?.filter(field =>
          (field?.options?.necessary === true) && (field?.value === null || (field?.value as any)?.value === null)
      )
      return required?.length > 0
  }

  const Footer = () => {
    return (
        <Container fluid>
          <Row>
            <Col md={ 3 }>
              <button
                  className={ 'btn btn-outline-danger' }
                  onClick={ onClose }
              >
                { trans('modal.consentFields.cancel') }
              </button>
            </Col>
            <Col md={ 9 } className={ 'd-flex justify-content-end' }>
              <button
                  className={ 'btn btn-outline-primary me-2' }
                  onClick={ onAccept }
                  disabled={
                      saveLoading ||
                      fields.filter(
                          item => item.value === null || (item.value as ConsentFieldValue)?.value === null
                      ).length === fields.length
                      || hasCheckFieldsNecessaryLeft()
                  }
              >
                {
                    saveLoading &&
                    <Spinner as={ 'span' }
                             animation={ 'border' }
                             size={ 'sm' }
                             role={ 'status' }
                             aria-hidden={ 'true' }
                             className={ 'me-2' }
                    />
                }
                { trans('modal.consentFields.accept') }
              </button>
              <button
                  className={ 'btn btn-primary' }
                  onClick={ onAcceptAll }
                  disabled={ saveLoading }
              >
                {
                    saveLoading &&
                    <Spinner as={ 'span' }
                             animation={ 'border' }
                             size={ 'sm' }
                             role={ 'status' }
                             aria-hidden={ 'true' }
                             className={ 'me-2' }
                    />
                }
                { trans('modal.consentFields.acceptAll') }
              </button>
            </Col>
          </Row>
        </Container>
    )
  }

  const onAcceptAll = () => {
    dispatch(toggleSaveLoading(true))
    fields.map(field => {
                 checkConsent(field, { accepted: true, value: field.options?.consentDescription })
                 save((field), { accepted: true, value: field.options?.consentDescription })
               },
    )
    dispatch(toggleSaveLoading(false))
    onValid()
    onClose()
  }

  const onAccept = () => {
    fields.map(field => {
                 (field.value as ConsentFieldValue)?.value &&  null !== (field.value as ConsentFieldValue).value
                  ? save((field), { accepted: true, value: (field.value as ConsentFieldValue).value })
                  : save((field), { accepted: false, value: null })
               },
    )
    onValid()
    onClose()
  }

  const save = (field: Field, value: object | null) => {
      dispatch(saveInstanceFieldValue(instanceId, field.id, value))
      dispatch(fieldEvent(field, FieldEvent.VALUE_CHANGE, { value: value }))
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>, field: Field) => {
    const consentValue = {
      accepted: false, value: e.target.checked ? field.options?.consentDescription : null,
    }

    checkConsent(field, consentValue)
  }

  const checkConsent = (field: Field, value: object | null) => {
    dispatch(fieldEvent(field, FieldEvent.VALUE_CHANGE, {
      value: value,
    }))
  }

  return (
      <Modal isOpen={ isOpen }
             title={ trans('modal.consentFields.title') }
             onClose={ onClose }
             size={ 'lg' }
             footer={ <Footer/> }
             modalClass={ 'text-primary border border-primary border-1' }
             animation={ 'false' }
      >
        {
            fields.length &&
            <Accordion defaultActiveKey={ fields[0].systemName }>
              {
                fields.map((field, index) =>
                  <Card key={ field.id }>
                    <Card.Header className={ 'd-flex bg-white text-primary' }>
                      <CustomToggle eventKey={ field.systemName }>
                        <div>
                          {
                            openedToggle === field.systemName
                              ? <i className={ 'fa-solid fa-angle-down text-primary' }></i>
                              : <i className={ 'fa-solid fa-angle-up text-primary' }></i>
                          }
                          <span className={ 'ms-4' }>
                            { field.label }
                          </span>
                          {
                            field?.options.necessary &&
                              <span className={ 'text-danger fw-bold ms-2' }>*</span>
                          }
                        </div>
                      </CustomToggle>
                      <div className={ 'd-flex justify-content-end w-25' }>
                        <Form.Check
                          type={ 'switch' }
                          id={ `custom-switch-field${ field.id }` }
                          label={ '' }
                          checked={
                            field.value !== null && (field.value as ConsentFieldValue)?.value !== null
                          }
                          onChange={ e => onChange(e, field) }
                          isValid={
                            field.value !== null && (field.value as ConsentFieldValue)?.value !== null
                          }
                          isInvalid={
                            field.value === null || (field.value as ConsentFieldValue)?.value === null
                          }
                        />
                        <FormControl
                          id={ `field${ index }` }
                          type={ 'hidden' }
                          value={ (field.value as ConsentFieldValue)?.value || '' }
                        />
                      </div>
                    </Card.Header>
                    <Accordion.Collapse eventKey={ field.systemName }>
                      <Card.Body
                        dangerouslySetInnerHTML={ {
                          __html: field.options?.consentDescription.replace(/\n/g, '<br/>'),
                        } }
                        className={ 'text-break' }
                      />
                    </Accordion.Collapse>
                  </Card>
                )
              }
            </Accordion>
        }
      </Modal>
  )
}

export default ConsentFieldsModal
