import React, { useEffect } from 'react'
import { fieldEvent } from '../../state/actions'
import SignatureCanvas from 'react-signature-canvas'
import Modal from '../../../../Components/Modal'
import { Button } from 'react-bootstrap'
import { FieldEvent } from 'src/Views/FormFiller/Types/Field'
import { useDispatch, useSelector } from 'react-redux'

const SignatureField = ({
  id = null,
  field = null,
  value,
  disabled = false,
  trans,
}) => {
  const dispatch = useDispatch()

  const { user: currentUser } = useSelector(state => state.Root)

  const [ isCanvasModalOpen, setIsCanvasModalOpen ] = React.useState(false)
  const [ canvas, setCanvas ] = React.useState(null)
  const [ canvasData, setCanvasData ] = React.useState(value?.data || null)

  const hasAlreadySigned = !!value?.signedAt
  const getValue = () => JSON.stringify({
    signedBy: { id: currentUser.id, firstName: currentUser.firstName, lastName: currentUser.lastName },
    signedAt: Date.now(),
    data: canvasData
  })

  useEffect(() => {
    if (!canvas) return

    if (canvasData)
      canvas.fromData(canvasData)

    if (canvas.toData() !== canvasData)
      setCanvasData(canvas.toData())
  }, [ canvas ])

  const handleChange = e => setCanvasData(canvas.toData())
  const handleSubmit = e => {
    if (!hasAlreadySigned)
      dispatch(fieldEvent(field, FieldEvent.VALUE_CHANGE, { value: getValue() }))
    setIsCanvasModalOpen(false)
  }

  return <div>
    <Modal title={ trans('modal.sign.title') }
           isOpen={ isCanvasModalOpen }
           onClose={ () => setIsCanvasModalOpen(false) }
    >
      <SignatureCanvas id={ id }
                       ref={ setCanvas }
                       onEnd={ handleChange }
                       canvasProps={ { width: 400, height: 100, className: 'm-auto d-block mb-2 signature-canvas' } }
      />
      <small className={'text-muted'}>{ trans('signInTheBox') }</small>
      <Button onClick={ handleSubmit } disabled={hasAlreadySigned} className={ 'float-right' }>
        { trans('submit') }
      </Button>
    </Modal>
    <Button size={ 'sm' } variant={ 'info' } disabled={ disabled } onClick={ () => setIsCanvasModalOpen(true) }>
      <i className={ 'me-1 fas fa-signature' }/>
      { hasAlreadySigned
        ? `${trans('signedBy')} ${value.signedBy.firstName} ${value.signedBy.lastName}`
        : trans('sign') }
    </Button>
  </div>
}

export default SignatureField
