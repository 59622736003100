import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
  LOAD_FORM_LIST,
  DELETE_FORM_LIST,
  ADD_FORM_FORM_LIST,
  SEARCH_FORM_LIST,
  SET_FORM_UNLOCKED
} from './actionTypes'
import {
  receiveFormList,
  loadFormList,
  toggleAddForm,
  formAddedFormList,
  errorAddingFormList,
  setFormUnlocked
} from './actions'
import apiMethods from 'src/Services/api/apiMethods'
import { contentRangeToPagination } from 'src/Utils/EffectsUtil'

export function* loadFormListHandler(props) {
  try {
    const { pagination } = (yield select()).FormList

    const { headers, data } = yield call(apiMethods.get, '/forms', { ...pagination })

    const forms = data.map(raw => ({
      ...raw,
      locked: Boolean(raw.lockedBy),
      lockedBy: raw.lockedBy?.username
    }))

    yield put(receiveFormList(forms, contentRangeToPagination(headers['content-range'], pagination)))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'loadFormListFailed'))
  }
}

function* deleteFormHandler(props, { payload }) {
  try {
    yield call(apiMethods.delete, `/forms/${payload}`)
    yield put(loadFormList())
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'deleteFormFailed'))
  }
}

export function* addFormHandler(props, { payload }) {
  try {
    yield call(apiMethods.create, '/forms', {
      data: { ...payload }
    })
    yield put(loadFormList())
    yield put(toggleAddForm())
    yield put(formAddedFormList())
  } catch (error) {
    yield put(props.globalActions.handleError(error, error.message ? error.message.trim() : 'Internal error'))
    yield put(errorAddingFormList(error.message ? error.message.trim() : 'Internal error'))
  }
}

export function* searchFormHandler(props, { payload }) {
  try {
    const { pagination } = (yield select()).FormList

    const { headers, data } = yield call(apiMethods.get, '/forms',
      { query: payload, ...pagination }
    )

    const forms = data.map(raw => ({
      ...raw,
      locked: Boolean(raw.lockedBy),
      lockedBy: raw.lockedBy?.username
    }))

    yield put(receiveFormList(forms, contentRangeToPagination(headers['content-range'], pagination)))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'searchFormFailed'))
    yield put(errorAddingFormList(error))
  }
}

export function* setFormUnlock(props, { payload }) {
  try {
    if (payload.isLocked) {
      yield call(apiMethods.get, `/forms/${payload?.id}/unlock`)
      yield put(setFormUnlocked(payload?.id))
    }
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'lockFormFailed'))
  }
}

export default function* FormListSagaWatcher(props) {
  yield takeLatest(LOAD_FORM_LIST, loadFormListHandler, props)
  yield takeLatest(DELETE_FORM_LIST, deleteFormHandler, props)
  yield takeLatest(ADD_FORM_FORM_LIST, addFormHandler, props)
  yield takeLatest(SEARCH_FORM_LIST, searchFormHandler, props)
  yield takeLatest(SET_FORM_UNLOCKED, setFormUnlock, props)
}
