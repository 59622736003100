import React, { ChangeEvent, MutableRefObject } from 'react'
import styled from 'styled-components'
import { startWeek, startDay } from 'src/Components/Calendar/Constants/calendarOptionConfig'
import { Options, SidebarTab } from 'src/Components/Calendar/Types/Calendar'
import CalendarEvent from 'src/Components/Calendar/Types/CalendarEvent'

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #e5e5e5;
  border: 1px solid #d4d4d4;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
  -webkit-transition: 400ms;
  -moz-transition: 400ms;
  -o-transition: 400ms;
  transition: 400ms;
`
const Title = styled.div`
  margin-bottom: 17px;
  font-size: 19.5px;
  line-height: 34px;
  color: #4d4d4d;
`

const OptionPickers = styled.select`
  padding: 4px 8px;
  width: 170px;
  overflow: hidden;
  border: 1px solid #aaa;
  white-space: nowrap;
  line-height: 26px;
  color: #444;
  text-decoration: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  background-color: #fff;
  outline: none;
  cursor: pointer;
`
const OptionItem = styled.option`
  padding: 3px 7px 4px;
  margin: 0;
  cursor: pointer;
  min-height: 1em;
`

const TitleOptions = styled.div`
  font-size: 13px;
  line-height: 17px;
  color: #4d4d4d;
  padding: 8px 0;
`
const CheckboxWrapper = styled.div`
  display: flex;
`
const CheckBox = styled.input`
  margin: 10px 5px 0 0;
`

interface Props {
  calendarOptions: Options
  setCalendarOptions: (options: Options) => void
}

const OptionsTab = ({ setCalendarOptions, calendarOptions }: Props) => {
  const changeStartWeek = (e: ChangeEvent<HTMLSelectElement>) => {
    setCalendarOptions({
                         startWeek: parseInt(e.target.value, 10),
                         startDay: calendarOptions.startDay,
                         endDay: calendarOptions.endDay,
                         weekends: calendarOptions.weekends,
                         initialView: calendarOptions.initialView,
                       })
  }
  const changeWeekend = (e: ChangeEvent<HTMLInputElement>) => {
    setCalendarOptions({
                         startWeek: calendarOptions.startWeek,
                         startDay: calendarOptions.startDay,
                         endDay: calendarOptions.endDay,
                         weekends: e.target.checked,
                         initialView: calendarOptions.initialView,
                       })
  }
  const changeStartDay = (e: ChangeEvent<HTMLSelectElement>) => {
    setCalendarOptions({
                         startWeek: calendarOptions.startWeek,
                         startDay: e.target.value,
                         endDay: calendarOptions.endDay,
                         weekends: calendarOptions.weekends,
                         initialView: calendarOptions.initialView,
                       })
  }
  const changeEndDay = (e: ChangeEvent<HTMLSelectElement>) => {
    setCalendarOptions({
                         startWeek: calendarOptions.startWeek,
                         startDay: calendarOptions.startDay,
                         endDay: e.target.value,
                         weekends: calendarOptions.weekends,
                         initialView: calendarOptions.initialView,
                       })
  }

  return (
      <OptionWrapper>
        <Title>Edit Calendar Options</Title>
        <TitleOptions>Start Of Week</TitleOptions>
        <OptionPickers value={calendarOptions.startWeek} onChange={changeStartWeek}>
          {startWeek.map(({
                            value,
                            title,
                          }) => (
              <OptionItem key={title} value={value}>
                {title}
              </OptionItem>
          ))}
        </OptionPickers>

        <TitleOptions>Start Of Day</TitleOptions>
        <OptionPickers value={calendarOptions.startDay} onChange={changeStartDay}>
          {startDay.map(({
                           value,
                           title,
                         }, index) => (
              <OptionItem key={index + 1} value={value}>
                {title}
              </OptionItem>
          ))}
        </OptionPickers>
        <TitleOptions>End Of Day</TitleOptions>
        <OptionPickers value={calendarOptions.endDay} onChange={changeEndDay}>
          {startDay.map(({
                           value,
                           title,
                         }, index) => (
              <OptionItem key={index} value={value}>
                {title}
              </OptionItem>
          ))}
        </OptionPickers>
        <CheckboxWrapper>
          <CheckBox type="checkbox" checked={calendarOptions.weekends} onChange={changeWeekend} />
          <TitleOptions>Show Weekends</TitleOptions>
        </CheckboxWrapper>
      </OptionWrapper>
  )
}

export default OptionsTab
