import * as types from './actionTypes'
import { AuditTrailPaginationDetails } from 'src/Views/AuditTrails/state/reducer'
import { AuditTrailLog } from 'src/Types/AuditTrailLog'
import { Filter } from 'src/Types/RequestFilter'
import { SortItem } from 'src/Types/Pagination'

export const fetchData = () => ({
  type: types.FETCH_DATA,
  payload: {}
})
export const setData = (rows: AuditTrailLog[], paginationDetails: AuditTrailPaginationDetails) => ({
  type: types.SET_DATA,
  payload: { rows, paginationDetails }
})

export const paginate = (offset: number, limit: number) => ({
  type: types.PAGINATE,
  payload: { offset, limit }
})

export const filter = (filters: Filter[]) => ({
  type: types.FILTER,
  payload: { filters }
})
export const sort = (sortItems: SortItem[]) => ({
  type: types.SORT,
  payload: { sortItems }
})

export const setIsLoading = (isLoading: boolean) => ({
  type: types.SET_IS_LOADING,
  payload: { isLoading }
})
