export default {
    english: {
        sidebar: {
            tabs: {
                events: {
                    title: 'Events'
                },
                slots: {
                    title: 'Slots'
                },
                options: {
                    title: 'Options'
                },
                legend: {
                    title: 'Legend',
                    captions: {
                        eventStatuses: 'Event statuses',
                        eventTypes: 'Event types',
                        slotTypes: 'Slot types',
                    }
                }
            },
        },
        filter: {
            locations: 'Locations',
            eventStatus: 'Status',
            eventTypes: 'Types',
            slotTypes: 'Slot types',
            resources: 'Resources',
        },
        form: {
            event: {
                id: 'Event ID',
                type: 'Event type',
                startAt: 'Start at',
                endAt: 'End at',
                resources: 'Resources',
                location: 'Location',
                duration: 'Duration (minutes)',
                status: 'Event Status',
                goToInstance: 'Go to instance',
                inASlot: '(Creating in a slot)',
                changeLogs: 'Audit trails',
                createdAt: 'Created Date',
                createdBy: 'Created By',
                notes: 'Notes',
                cancel: 'Cancel',
                submit: 'Save',
                reason: 'Cancellation reason'
            },
            slot: {
                date: 'Start at',
                resource: 'Resource',
                location: 'Location',
                duration: 'Duration (minutes)',
                disabledDeleteTitle: 'This slot is used by an event',
                deleteTitle: 'Delete the current slot',
                seriesId: 'Slot series Id',
                id: 'Slot ID'
            },
            eventType: {
                eventTypeRequired: 'Please select an event type',
                slotRequired: 'This event type needs a slot',
            }
        },
        modal: {
            createEvent: {
                title: 'Create event',
            },
            selectEventType: {
                title: 'Select event type',
            },
            editEvent: {
                title: 'Edit event',
            },
            editSlot: {
                title: 'Edit slot',
            },
            confirmDeleteSlotModal: {
                title: 'Delete slot',
            },
            confirmDeleteEventModal: {
                title: 'Delete event',
            }
        },
        monthText: 'Month',
        newEvent: 'New event',
        createEvent: 'Create event',
        updateEvent: 'Update event',
        location: 'Location',
        fetchEventsError: 'Warning ! We failed at loading events for this period !',
        dayCellTitle: 'Click to open this day'
    },
    french: {
        sidebar: {
            tabs: {
                events: {
                    title: 'Évènements'
                },
                slots: {
                    title: 'Créneaux'
                },
                options: {
                    title: 'Options'
                },
                legend: {
                    title: 'Légende',
                    captions: {
                        eventStatuses: 'Status d\'évènements',
                        eventTypes: 'Types d\'évènements',
                        slotTypes: 'Status de créneaux',
                    }
                }
            },
        },
        filter: {
            locations: 'Emplacements',
            eventStatus: 'Status',
            eventTypes: 'Types',
            slotTypes: 'Types de créneaux',
            resources: 'Ressources',
        },
        form: {
            event: {
                id: 'Event ID',
                type: 'Type d\'évènement',
                startAt: 'Commence',
                endAt: 'Termine',
                resources: 'Ressources',
                location: 'Lieu',
                duration: 'Durée (minutes)',
                status: 'Statut',
                goToInstance: 'Aller sur le formulaire',
                inASlot: '(Création dans un créneau)',
                changeLogs: 'Historique des modifications',
                createdAt: 'Date de création',
                createdBy: 'Créé par',
                submit: 'Enrgistrer',
                cancel: 'Annuler',
                notes: 'Notes',
                reason: 'Motif d\'annulation'
            },
            slot: {
                date: 'Début à',
                resource: 'Ressource',
                location: 'Emplacement',
                duration: 'Durée (minutes)',
                disabledDeleteTitle: 'Ce créneau est utilisé par un évènement',
                deleteTitle: 'Supprimer le créneau actuel',
                seriesId: 'Id de la série de créneaux',
                id: 'Slot ID'
            },
            eventType: {
                eventTypeRequired: 'Veuillez sélectionner un type d\'évènement',
                slotRequired: 'Ce type d\'évènement nécessite un créneau',
            }
        },
        modal: {
            createEvent: {
                title: 'Créer évènement',
            },
            selectEventType: {
                title: 'Sélectionner un type d\'évènement',
            },
            editEvent: {
                title: 'Modifier évènement',
            },
            editSlot: {
                title: 'Modifier créneau',
            },
            confirmDeleteSlotModal: {
                title: 'Supprimer créneau',
            },
            confirmDeleteEventModal: {
                title: 'Supprimer évènement',
            }
        },
        monthText: 'Mois',
        newEvent: 'Nouvel évènement',
        createEvent: 'Créer l\'évènement',
        updateEvent: 'Editer l\'évènement',
        location: 'Emplacement',
        fetchEventsError: 'Attention ! Nous n\'avons pas réussi à charger les évènements pour cette période !',
        dayCellTitle: 'Cliquez pour ouvrir ce jour'
    }
}
