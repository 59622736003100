import React from 'react'
import { flexRender } from '@tanstack/react-table'
import ColumnFilters from './ColumnFilters'

const ColumnHeader = ({ header, filters, onFiltersChange, trans }) =>
  <th>
    { !header.isPlaceholder && <div>
      <span>
        <span>{ flexRender(header.column.columnDef.header, header.getContext()) }</span>
        { header.column.getCanSort() && <span className="cursor-pointer ms-2"
                                              onClick={ header.column.getToggleSortingHandler() }
        >
        { {
          asc: <i className="fa-solid fa-sort-up"/>,
          desc: <i className="fa-solid fa-sort-down"/>
        }[header.column.getIsSorted()] ?? <i className="fa-solid fa-sort"></i> }
        </span> }
        { header.column.getCanFilter() && <span className="ms-2">
          <ColumnFilters column={ header.column } filters={filters} trans={trans} onChange={onFiltersChange} />
        </span> }
      </span>
    </div> }
  </th>

export default ColumnHeader
