export const FIELD_PREFIX_MS_SANTE = 'MS_SANTE'
export const FIELD_MS_SANTE_RECIPIENTS_PREFIX = FIELD_PREFIX_MS_SANTE + '_RECIPIENTS'
export const FIELD_MS_SANTE_RECIPIENTS_PREFIX_LAST_NAME = 'LAST_NAME'
export const FIELD_MS_SANTE_RECIPIENTS_PREFIX_FIRST_NAME = 'FIRST_NAME'
export const FIELD_MS_SANTE_RECIPIENTS_PREFIX_RPPS = 'RPPS'
export const FIELD_MS_SANTE_RECIPIENTS_PREFIX_TELECOM = 'TELECOM'
export const FIELD_MS_SANTE_RECIPIENTS_PREFIX_ZIPCODE = 'ZIPCODE'
export const FIELD_MS_SANTE_RECIPIENTS_PREFIX_CITY = 'CITY'
export const FIELD_MS_SANTE_RECIPIENTS_PREFIX_COUNTRY = 'COUNTRY'
export const FIELD_MS_SANTE_RECIPIENTS_PREFIX_ADDRESS = 'ADDRESS'
export const FIELD_MS_SANTE_RECIPIENTS_PREFIX_LIFENID = 'LIFENID'

export const FIELD_MS_SANTE_SENDER_PREFIX = FIELD_PREFIX_MS_SANTE + '_SENDER'
export const FIELD_MS_SANTE_SENDER_PREFIX_RPPS = 'RPPS'

export const FIELD_MS_SANTE_PREFIX_CONTENT_TO_SEND = FIELD_PREFIX_MS_SANTE + '_CONTENT_TO_SEND'
export const FIELD_MS_SANTE_PREFIX_SEND_BUTTON = FIELD_PREFIX_MS_SANTE + '_SEND_BUTTON'
export const FIELD_MS_SANTE_PREFIX_TRACKING_LIST = FIELD_PREFIX_MS_SANTE + '_TRACKING_LIST'

export const EXTERNAL_PROVIDER_MS_SANTE = 'list_runner_ms_sante'

export const MS_SANTE_TRACKING_STATUS_ENTERED_IN_ERROR = 'entered-in-error'
export const MS_SANTE_TRACKING_STATUS_UNKNOWN = 'unknown'
export const MS_SANTE_TRACKING_STATUS_PREPARATION = 'preparation'
export const MS_SANTE_TRACKING_STATUS_IN_PROGRESS = 'in-progress'
export const MS_SANTE_TRACKING_STATUS_COMPLETED = 'completed'

export const MS_SANTE_TRACKING_STATUS = [
    { value: MS_SANTE_TRACKING_STATUS_ENTERED_IN_ERROR, label: 'enteredInError', color:'danger' },
    { value: MS_SANTE_TRACKING_STATUS_UNKNOWN, label: 'unknown', color: 'danger' },
    { value: MS_SANTE_TRACKING_STATUS_PREPARATION, label: 'preparation', color: 'info' },
    { value: MS_SANTE_TRACKING_STATUS_IN_PROGRESS, label: 'inProgress', color: 'info' },
    { value: MS_SANTE_TRACKING_STATUS_COMPLETED, label: 'completed', color: 'success' },
]

export const MS_SANTE_CAN_REFRESH_STATUS = [
    MS_SANTE_TRACKING_STATUS_PREPARATION, MS_SANTE_TRACKING_STATUS_IN_PROGRESS
]
