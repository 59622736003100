export const startWeek = [
  {
    value: '0',
    title: 'Sunday'
  },
  {
    value: '1',
    title: 'Monday'
  },
  {
    value: '2',
    title: 'Tuesday'
  },
  {
    value: '3',
    title: 'Wednesday'
  },
  {
    value: '4',
    title: 'Thursday'
  },
  {
    value: '5',
    title: 'Friday'
  },
  {
    value: '6',
    title: 'Saturday'
  }
]
export const startDay = [
  {
    value: '00:00',
    title: '12:00 AM'
  },
  {
    value: '01:00',
    title: '1:00 AM'
  },
  {
    value: '02:00',
    title: '2:00 AM'
  },
  {
    value: '03:00',
    title: '3:00 AM'
  },
  {
    value: '04:00',
    title: '4:00 AM'
  },
  {
    value: '05:00',
    title: '5:00 AM'
  },
  {
    value: '06:00',
    title: '6:00 AM'
  },
  {
    value: '07:00',
    title: '7:00 AM'
  },
  {
    value: '08:00',
    title: '8:00 AM'
  },
  {
    value: '09:00',
    title: '9:00 AM'
  },
  {
    value: '10:00',
    title: '10:00 AM'
  },
  {
    value: '11:00',
    title: '11:00 AM'
  },
  {
    value: '12:00',
    title: '12:00 PM'
  },
  {
    value: '13:00',
    title: '1:00 PM'
  },
  {
    value: '14:00',
    title: '2:00 PM'
  },
  {
    value: '15:00',
    title: '3:00 PM'
  },
  {
    value: '16:00',
    title: '4:00 PM'
  },
  {
    value: '17:00',
    title: '5:00 PM'
  },
  {
    value: '18:00',
    title: '6:00 PM'
  },
  {
    value: '19:00',
    title: '7:00 PM'
  },
  {
    value: '20:00',
    title: '8:00 PM'
  },
  {
    value: '21:00',
    title: '9:00 PM'
  },
  {
    value: '22:00',
    title: '10:00 PM'
  },
  {
    value: '23:00',
    title: '11:00 PM'
  },
  {
    value: '00:00',
    title: '12:00 AM'
  }
]
