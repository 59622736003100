import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'

interface Announcement {
  id: number
  text: string
}

interface AnnouncementsProps {
  announcements: Announcement[]
}

const AnnouncementDisplay : React.FC<AnnouncementsProps> = ({ announcements }) => (
  <>
    { announcements.map(announcement => <Row key={ announcement.id }>
      <Col>
        <Card body>{ announcement.text }</Card>
      </Col>
    </Row>) }
  </>
)

export default AnnouncementDisplay
