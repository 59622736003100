import CalendarFilter from 'src/Types/CalendarFilter'

export const setDefaultActiveFilters = (filters: CalendarFilter[]) => {
  if (filters)
    localStorage.setItem('calendarActiveFilters', JSON.stringify(filters))
}

export const getDefaultActiveFilters = (): CalendarFilter[] => {
  const calendarActiveFilters = localStorage.getItem('calendarActiveFilters')

  return calendarActiveFilters ? (JSON.parse(calendarActiveFilters) as CalendarFilter[]) : []
}
