import React, { ChangeEvent, useContext, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Accordion, Card, Form, FormControl } from 'react-bootstrap'
import AccordionContext from 'react-bootstrap/AccordionContext'
import { useAccordionButton } from 'react-bootstrap/AccordionButton'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { FieldEvent } from 'src/Views/FormFiller/Types/Field'
import { Field } from 'src/Types/Field'
import { ConsentFieldValue } from 'src/Types/FieldType/ConsentField'
import { fieldEvent } from '../../../state/actions'
import  './Style/accordion.scss'


interface ConsentFieldProps {
  id: string | null
  field: Field | null
  isRequired: boolean
}

const ConsentField = ({
  id = null,
  field = null,
  isRequired = false
}: ConsentFieldProps) => {
  const dispatch = useDispatch()
  const [ isCollapsed, setIsCollapsed ] = React.useState(true)
  const switchField = useRef(null)

  const onChange = (e: ChangeEvent<HTMLInputElement> ) => {
    const consentValue = {
      accepted: false , value: e.target.checked ? field.options?.consentDescription : null
    }

    dispatch(fieldEvent(field, FieldEvent.VALUE_CHANGE, { value: consentValue }))
  }

  interface CustomToggleProps {
    children: ReactJSXElement
    eventKey: string
  }

  const CustomToggle = ({ children, eventKey }: CustomToggleProps)  => {
    const { activeEventKey } = useContext(AccordionContext)

    const decoratedOnClick = useAccordionButton(eventKey, e => {
        e.preventDefault()
        setIsCollapsed(activeEventKey === '0')
      }
    )

    return (
      <div>
        <a
          href="#"
          onClick={decoratedOnClick}
        >
          { children }
        </a>
      </div>
    )
  }

  return <div className="form-filler-field-consent">
    <Accordion>
      <Card>
        <Card.Header
          className="d-flex justify-content-between bg-white"
        >
          <CustomToggle eventKey="0">
            {
              isCollapsed
                ? <i className="fa-solid fa-angle-down"></i>
                : <i className="fa-solid fa-angle-up"></i>
            }
          </CustomToggle>
          <div className="ms-4">
            { field.label }
            {
              isRequired &&
              <span className="text-danger fw-bold ms-2">*</span>
            }
          </div>
          <div>
              <Form.Check
                type="switch"
                id={ `custom-switch-field${ id }` }
                label=""
                checked={ field.value !== null && (field.value as ConsentFieldValue)?.value !== null }
                onChange={ onChange }
                isValid={ field.value !== null && (field.value as ConsentFieldValue)?.value !== null}
                isInvalid={ field.value === null || (field.value as ConsentFieldValue)?.value === null }
                disabled={ field.options?.necessary && (field.value as ConsentFieldValue)?.accepted }
                ref={switchField}
              />
            <FormControl
              id={ `field${ id }` }
              type="hidden"
              value={ (field.value as ConsentFieldValue)?.value  || '' }
            />
          </div>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body
            dangerouslySetInnerHTML={{ __html: field.options?.consentDescription?.replace(/\n/g,'<br/>') }}
            className="text-break"
          />
        </Accordion.Collapse>
      </Card>
    </Accordion>
  </div>
}

export default ConsentField
