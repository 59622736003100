import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Accordion, Badge } from 'react-bootstrap'
import styled from 'styled-components'
import { path } from 'ramda'
import { formatDateTime } from 'src/Utils/Date.ts'
import { translate, translateConf } from 'src/Services/translation'
import { setEditingEvent } from 'src/Components/Calendar/state/actions'
import translation from '../../translations'

const Appointments = () => {
  const dispatch = useDispatch()

  const { sidebarEvents: events } = useSelector((state) => state.Patient)
  const { language, locale, portalTimezone } = useSelector((state) => state.Root.user)
  const { configTranslations } = useSelector((state) => state.Root)

  const trans = translate(translation)(language)
  const transConf = translateConf(configTranslations)

  const onEditClick = event => () =>
    dispatch(setEditingEvent({ ...event, eventType: event.type }))

  if (!events || events.length === 0) {
    return <Accordion.Item eventKey={'appointments'}>
      <Accordion.Header>
        <Badge pill className={'me-1'}>0</Badge>
        <span>{trans('appointments')}</span>
      </Accordion.Header>
      <Accordion.Body className={'p-3'}>
        {trans('none')}
      </Accordion.Body>
    </Accordion.Item>
  }

  return <Accordion.Item eventKey={'appointments'}>
    <Accordion.Header>
      <Badge pill className="me-1">{events ? events.length : 0}</Badge>
      <span>{trans('appointments')}</span>
    </Accordion.Header>

    <Accordion.Body className="p-1">
      <>
        <AppointmentHeaders>
          <span></span>
          <span>{trans('date')}</span>
          <span>{trans('type')}</span>
          <span>{trans('status')}</span>
        </AppointmentHeaders>

        {events.map(event =>
          <Appointment key={event.id} tabIndex="0" onClick={onEditClick(event)} style={{ borderColor: event.status?.color }}>
            <div>{formatDateTime(locale, portalTimezone)(event.date)}</div>
            <div>{ transConf('EVENT_TYPE')(event.type) }</div>
            <div className={'text-elipsis'}>{path(['status', 'name'], event) || ''}</div>
          </Appointment>
        )}
      </>
    </Accordion.Body>

  </Accordion.Item>
}

export default Appointments

const Appointment = styled.div`
  align-items: center;
  font-size: 0.8em;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 0.25em 0.5em;
  margin: 0.5em 0;
  border-radius: 6px;
  border: solid 1px rgba(84,105,141, 0.3);
  position: relative;

  > i {
    color: red;
    cursor: pointer;
  }

  > i:hover {
    color: black;
  }

  > div {
    margin-left: 0.5em;
    width: 25%;
  }

  > div:first-of-type {
    width: 40%;
  }

  > div:last-of-type {
    margin-right: 0.5em;
  }

  :hover {
    border-color: ${props => props.theme.styleguideColors.darkGrey};
  }
`
const AppointmentHeaders = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
  font-size: 0.9em;
  margin-top: 0.5em;
  width: 100%;
  color: ${props => props.theme.colors.grey.dark};
  background-color: ${props => props.theme.colors.grey.extralight};
`
