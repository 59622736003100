export const TYPE_ROLE = 'ROLE_TYPE'
export const TYPE_STANDARD = 'STANDARD_TYPE'
export const TYPE_LOCATION = 'LOCATION_TYPE'
export const TYPE_PATIENT = 'PATIENT_TYPE'
export const TYPE_PERSON = 'PERSON_TYPE'
export const TYPE_QUESTIONNAIRE = 'QUESTIONNAIRE_TYPE'

export const TYPES = [
  TYPE_ROLE,
  TYPE_STANDARD,
  TYPE_LOCATION,
  TYPE_PATIENT,
  TYPE_PERSON,
  TYPE_QUESTIONNAIRE,
]

export const TYPES_ALLOWED_FOR_CREATION = [
  TYPE_ROLE,
  TYPE_STANDARD,
  TYPE_LOCATION,
  TYPE_QUESTIONNAIRE,
]

export const TYPES_ALLOWED_FOR_PATIENT = [
  TYPE_STANDARD,
  TYPE_QUESTIONNAIRE,
]
