import {
  SWP_ACCESS_TOKENS_FIELD_SYSTEM_NAME, SWP_END_RECORD_AT_FIELD_SYSTEM_NAME, SWP_OPEN_MONITOR_FIELD_SYSTEM_NAME, SWP_OPEN_PLAYBACK_FIELD_SYSTEM_NAME,
  SWP_OPEN_RECORD_FIELD_SYSTEM_NAME, SWP_RECORD_DURATION_FIELD_SYSTEM_NAME,
  SWP_RECORDER_SERIAL_FIELD_SYSTEM_NAME,
  SWP_SESSION_ID_FIELD_SYSTEM_NAME, SWP_SESSION_STATUS_FIELD_SYSTEM_NAME, SWP_START_RECORD_AT_FIELD_SYSTEM_NAME,
} from 'src/Services/Constants/Swp'
import {
    FIELD_MS_SANTE_PREFIX_CONTENT_TO_SEND,
    FIELD_MS_SANTE_PREFIX_SEND_BUTTON,
    FIELD_MS_SANTE_PREFIX_TRACKING_LIST,
    FIELD_MS_SANTE_RECIPIENTS_PREFIX,
    FIELD_MS_SANTE_RECIPIENTS_PREFIX_ADDRESS,
    FIELD_MS_SANTE_RECIPIENTS_PREFIX_CITY,
    FIELD_MS_SANTE_RECIPIENTS_PREFIX_COUNTRY,
    FIELD_MS_SANTE_RECIPIENTS_PREFIX_FIRST_NAME,
    FIELD_MS_SANTE_RECIPIENTS_PREFIX_LAST_NAME,
    FIELD_MS_SANTE_RECIPIENTS_PREFIX_LIFENID,
    FIELD_MS_SANTE_RECIPIENTS_PREFIX_RPPS,
    FIELD_MS_SANTE_RECIPIENTS_PREFIX_TELECOM,
    FIELD_MS_SANTE_RECIPIENTS_PREFIX_ZIPCODE, FIELD_MS_SANTE_SENDER_PREFIX,
    FIELD_MS_SANTE_SENDER_PREFIX_RPPS,
} from 'src/Services/Constants/MsSante'
import { SwpSystemFieldConditions, SystemFieldCondition } from 'src/Views/FormEditor/Components/FeatureConfig/Types'

export const SWP_NEEDED_SYSTEM_FIELDS: SwpSystemFieldConditions = {
  eeg: [
    {
      name: 'sessionIdField',
      prefix: SWP_SESSION_ID_FIELD_SYSTEM_NAME,
      isRequired: true,
      isMultiple: false,
    },
    {
      name: 'accessTokensField',
      prefix: SWP_ACCESS_TOKENS_FIELD_SYSTEM_NAME,
      isRequired: true,
      isMultiple: false,
    },
    {
      name: 'openDeviceButtons',
      prefix: SWP_OPEN_RECORD_FIELD_SYSTEM_NAME,
      isRequired: false,
      isMultiple: true,
    },
    {
      name: 'openPlaybackButtons',
      prefix: SWP_OPEN_PLAYBACK_FIELD_SYSTEM_NAME,
      isRequired: false,
      isMultiple: true,
    },
    {
      name: 'openMonitorButtons',
      prefix: SWP_OPEN_MONITOR_FIELD_SYSTEM_NAME,
      isRequired: false,
      isMultiple: true,
    },
    {
      name: 'productSerialNumberField',
      prefix: SWP_RECORDER_SERIAL_FIELD_SYSTEM_NAME,
      isRequired: false,
      isMultiple: false,
    },
    {
      name: 'startRecordAtField',
      prefix: SWP_START_RECORD_AT_FIELD_SYSTEM_NAME,
      isRequired: false,
      isMultiple: false,
    },
    {
      name: 'endRecordAtField',
      prefix: SWP_END_RECORD_AT_FIELD_SYSTEM_NAME,
      isRequired: false,
      isMultiple: false,
    },
    {
      name: 'recordDurationField',
      prefix: SWP_RECORD_DURATION_FIELD_SYSTEM_NAME,
      isRequired: false,
      isMultiple: false,
    },
    {
      name: 'sessionStatus',
      prefix: SWP_SESSION_STATUS_FIELD_SYSTEM_NAME,
      isRequired: false,
      isMultiple: false,
    },
  ],
  ecg: [],
}

export const MS_SANTE_NEEDED_SYSTEM_FIELDS: SystemFieldCondition[] = [
    {
        name: 'recipient',
        prefix: FIELD_MS_SANTE_RECIPIENTS_PREFIX,
        isRequired: true,
        isMultiple: false,
        isReference: true,
        childs: [
            {
                name: 'lastName',
                prefix: FIELD_MS_SANTE_RECIPIENTS_PREFIX_LAST_NAME,
                isRequired: true,
                isMultiple: false,
            },
            {
                name: 'firstName',
                prefix: FIELD_MS_SANTE_RECIPIENTS_PREFIX_FIRST_NAME,
                isRequired: true,
                isMultiple: false,
            },
            {
                name: 'rpps',
                prefix: FIELD_MS_SANTE_RECIPIENTS_PREFIX_RPPS,
                isRequired: true,
                isMultiple: false,
            },
            {
                name: 'telecom',
                prefix: FIELD_MS_SANTE_RECIPIENTS_PREFIX_TELECOM,
                isRequired: true,
                isMultiple: false,
            },
            {
                name: 'zipCode',
                prefix: FIELD_MS_SANTE_RECIPIENTS_PREFIX_ZIPCODE,
                isRequired: true,
                isMultiple: false,
            },
            {
                name: 'city',
                prefix: FIELD_MS_SANTE_RECIPIENTS_PREFIX_CITY,
                isRequired: true,
                isMultiple: false,
            },
            {
                name: 'country',
                prefix: FIELD_MS_SANTE_RECIPIENTS_PREFIX_COUNTRY,
                isRequired: true,
                isMultiple: false,
            },
            {
                name: 'address',
                prefix: FIELD_MS_SANTE_RECIPIENTS_PREFIX_ADDRESS,
                isRequired: true,
                isMultiple: false,
            },
            {
                name: 'lifenId',
                prefix: FIELD_MS_SANTE_RECIPIENTS_PREFIX_LIFENID,
                isRequired: true,
                isMultiple: false,
            }
        ]
    },
    {
        name: 'sender',
        prefix: FIELD_MS_SANTE_SENDER_PREFIX,
        isRequired: true,
        isMultiple: false,
        isReference: true,
        childs: [
            {
                name: 'rpps',
                prefix: FIELD_MS_SANTE_SENDER_PREFIX_RPPS,
                isRequired: true,
                isMultiple: false,
            }
        ]
    },
    {
        name: 'contentToSend',
        prefix: FIELD_MS_SANTE_PREFIX_CONTENT_TO_SEND,
        isRequired: true,
        isMultiple: true,
        isReference: false,
    },
    {
        name: 'sendButton',
        prefix: FIELD_MS_SANTE_PREFIX_SEND_BUTTON,
        isRequired: true,
        isMultiple: true,
        isReference: false,
    },
    {
        name: 'trackingList',
        prefix: FIELD_MS_SANTE_PREFIX_TRACKING_LIST,
        isRequired: true,
        isMultiple: false,
        isReference: false,
    }
]

