export type UuidV4 = string
/*
Doesn't work well with pattern

export type UuidV4 = string & { __UuidV4StringBrand: never }
*/
export function isUuidV4String(value: string): value is UuidV4 {
  const uuidv4Regex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
  return uuidv4Regex.test(value)
}
