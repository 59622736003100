import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Container } from 'react-bootstrap'
import { useSelector } from 'src/Services/Store'
import { setOptions } from 'src/Layouts/View/state/actions'
import { loadFormList, toggleAddForm } from './state/actions'
import DeleteModal from './Components/DeleteModal'
import AddModal from './Components/AddModal'
import FormListTable from './Components/FormListTable'

const FormList = () => {
  const dispatch = useDispatch()
  const { pagination } = useSelector(state => state.FormList)

  React.useEffect(() => {
    dispatch(setOptions({
      hasPrimaryButton: true,
      onPrimaryButtonClick: () => dispatch(toggleAddForm())
    }))
  }, [ dispatch ])

  useEffect(() => {
    dispatch(loadFormList())
  }, [ pagination.pageLimit, pagination.currentPage, dispatch ])

  return (
    <Container>
      <FormListTable/>
      <DeleteModal/>
      <AddModal/>
    </Container>)
}

export default FormList
