import { List } from 'src/Types/List'
import NumericReport from 'src/Types/NumericReport'
import Role from 'src/Types/Role'

export default interface Permission {
    id: string | null
    accessLevel: PermissionAccessLevel | null
    list?: List | null
    numericReport?: NumericReport | null
    logAsRole?: Role | null
}

export enum PermissionAccessLevel {
    NONE = null,
    READ = 0,
    LIST = 1,
    UPDATE = 2,
    CREATE = 3,
    DELETE = 4,
    RESTORE = 5,
}
