import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
  FETCH_FIELD_TYPES,
  FETCH_BASE_FIELD_TYPES,
  CREATE_FIELD_TYPE,
  DELETE_FIELD_TYPE,
  UPDATE_FIELD_TYPE
} from './actionTypes'
import {
  receiveFieldTypes,
  receiveBaseFieldTypes,
  fieldTypeCreated,
  fieldTypeDeleted,
  fieldTypeUpdated, toggleCreateFieldType, toggleDeleteFieldType, toggleUpdateFieldType
} from './actions'
import apiMethods from '../../../Services/api/apiMethods'
import { contentRangeToPagination } from '../../../Utils/EffectsUtil.ts'

function* updateFieldType(props, { payload }) {
  try {
    const state = yield select()
    const { id } = state.FieldTypes.fieldTypeOnUpdate

    const { data } = yield call(apiMethods.update, `/field_types/${id}`, { data: payload })
    yield put(toggleUpdateFieldType(null))
    yield put(fieldTypeUpdated(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'updateFieldTypeFailed'))
  }
}

function* deleteFieldType(props, { payload }) {
  try {
    const state = yield select()
    const { id } = state.FieldTypes.fieldTypeOnDelete

    yield call(apiMethods.delete, `/field_types/${id}`)
    yield put(toggleDeleteFieldType(null))
    yield put(fieldTypeDeleted(id))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'deleteFieldTypeFailed'))
  }
}

function* createFieldType(props, { payload }) {
  try {
    const { data } = yield call(apiMethods.create, '/field_types', { data: payload })
    yield put(toggleCreateFieldType())
    yield put(fieldTypeCreated(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'createFieldTypeFailed'))
  }
}

function* fetchFieldTypes(props) {
  try {
    const state = yield select()
    const { searchQuery, pagination } = state.FieldTypes

    const { data, headers } = yield call(apiMethods.get, '/field_types', {
      query: searchQuery, ...pagination
    })
    yield put(receiveFieldTypes({
      fieldTypes: data,
      pagination: contentRangeToPagination(headers['content-range'], pagination)
    }))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'loadFieldTypeListFailed'))
  }
}

function* loadBaseFieldTypes(props) {
  try {
    const { data } = yield call(apiMethods.get, '/base_field_types')
    yield put(receiveBaseFieldTypes(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'loadFieldTypeListFailed'))
  }
}

export default function* fieldTypesSagaWatcher(props) {
  yield takeLatest(FETCH_FIELD_TYPES, fetchFieldTypes, props)
  yield takeLatest(FETCH_BASE_FIELD_TYPES, loadBaseFieldTypes, props)
  yield takeLatest(CREATE_FIELD_TYPE, createFieldType, props)
  yield takeLatest(DELETE_FIELD_TYPE, deleteFieldType, props)
  yield takeLatest(UPDATE_FIELD_TYPE, updateFieldType, props)
}
