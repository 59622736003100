import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import NavTable from '../../../Components/NavTable'
import translation from '../translations'
import { translate } from 'src/Services/translation'
import { toggleConfirmModal } from 'src/Services/Store/GlobalControls/actions'
import { changeLimit, changePage, fetchRemoveSystemField } from '../state/actions'
import { Button, ButtonGroup } from 'react-bootstrap'

const SystemFieldsTable = () => {
  const dispatch = useDispatch()

  const { language } = useSelector(state => state.Root.user)
  const loading = useSelector(state => state.UserList.loading)
  const fields = useSelector(state => state.SystemFields.formFieldsList)
  const pagination = useSelector(state => state.SystemFields.pagination)

  const trans = translate(translation)(language)

  const getFieldsAsRows = () =>
    fields.map(field => [
      field.name,
      field.fields.map((f, i) =>
        <span key={f.id}>
          <span>
            <small className={ 'text-muted' }>{ f.label }</small> <span className={'text-bold'}>{ f.systemName }</span>
          </span>
          { field.fields.length > i+1 && <span> --- </span> }
        </span>
      ),
      <ActionCell key={field.id} item={ field } trans={ trans } dispatch={ dispatch } />
    ])

  const onPageClick = (currentPage, page) => {
    if (currentPage !== page)
      dispatch(changePage(page))
  }

  const onLimitChange = limit => {
    dispatch(changeLimit(limit))
  }

  const columnHeaders = [
    trans('table.fieldName'),
    trans('table.ids'),
    trans('actions')
  ]

  return <NavTable pagination={ pagination }
                   rows={ getFieldsAsRows() }
                   columnHeaders={ columnHeaders }
                   onLimitChange={ onLimitChange }
                   onPageClick={ onPageClick }
                   isLoading={ loading }
  />
}

export const ActionCell = ({
  item,
  trans,
  dispatch
}) => {
  const params = useParams()

  const onDeleteClick = e =>
    dispatch(toggleConfirmModal(() =>
      dispatch(fetchRemoveSystemField({ formId: params.id, id: item.id }))
    ))

  return <ButtonGroup>
    <Button size={ 'sm' } variant="outline-danger" onClick={ onDeleteClick }>
      <i className="me-1 fas fa-trash"/>
      <span>{ trans('delete') }</span>
    </Button>
  </ButtonGroup>
}

export default SystemFieldsTable
