import React, { useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'

export const OverlayLoader = () => {

  const { displayOverlayLoader } = useSelector(state => state.Root)

  useEffect(() => {
    const overlayLoader = document.getElementById('overlayLoader')

   if (displayOverlayLoader)
      overlayLoader.classList.remove('d-none')
    else
      overlayLoader.classList.add('d-none')
  }, [displayOverlayLoader])

  return <div id={'overlayLoader'} className={'w-100 h-100 position-fixed bg-secondary bg-opacity-25'} style={{ zIndex: 1025 }}>
      <div className={'vh-100 d-flex align-items-center justify-content-center'}>
        <Spinner variant={'primary'} animation="border" role="main">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    </div>
}
