import React from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { translate } from 'src/Services/translation'
import { FIELD_BASE_TYPES_NOT_AUTO_SETABLE } from 'src/Services/Constants'
import FieldValueSetter from 'src/Components/FieldValueSetter/index'
import SystemFieldSelect from 'src/Components/SystemFieldSelect/index'
import { FieldOptionAutocompleteFieldType } from 'src/Types/Field'
import { getFields } from '../state/formReducer'
import translations from '../translations'

const AutocompleteField = ({ item, onChange, onDelete }) => {
  const { language } = useSelector(state => state.Root.user)
  const { form } = useSelector(state => state.FormEditor.formReducer)

  const trans = translate(translations)(language)

  const getFieldsForSelect = () =>
    getFields(form)
      .filter(f => f.systemName && !FIELD_BASE_TYPES_NOT_AUTO_SETABLE.includes(f.type.baseFieldType))
      .map(f => ({ systemName: f.systemName, label: f.label }))

  const getTargetField = () => item?.field ? getFields(form).find(f => f.systemName === item.field) : null

  const onFieldChange = field => newValue => {
    const newItem = item
    newItem[field] = newValue
    onChange(newItem)
  }

  const onTypeChange = type => {
    onFieldChange('value')(null)
    onFieldChange('type')(type)
  }

  return <Row className={'mb-1'}>
    <Col sm={ 5 }>
      <Form.Group className={ 'mb-2' }>
        <Select name={ 'field' } options={ getFieldsForSelect(form) }
                getOptionValue={ field => field.systemName }
                getOptionLabel={ field => field.systemName }
                value={ getTargetField() }
                onChange={ field => onFieldChange('field')(field?.systemName || null) }
        />
      </Form.Group>
    </Col>
    <Col sm={ 2 }>
      <Form.Select value={ item.type } onChange={ e => onTypeChange(e.target.value) }>
        { Object.values(FieldOptionAutocompleteFieldType).map(type => <option value={ type }
                                    key={ type }>{ trans(`modal.autocompleteFields.type.${ type }`) }</option>) }
      </Form.Select>
    </Col>
    { getTargetField() && <>
      { item.type === FieldOptionAutocompleteFieldType.CONSTANT && <Col sm={ 4 }>
        <Form.Group className={ 'mb-2' }>
          <FieldValueSetter field={ getTargetField() } value={ item.value } onChange={ onFieldChange('value') }/>
        </Form.Group>
      </Col> }
      { item.type === FieldOptionAutocompleteFieldType.DYNAMIC && <Col sm={ 4 }>
        <SystemFieldSelect formId={form.id} name={'field'}
                           value={ item?.value }
                           onChange={ onFieldChange('value') }
        />
        <Form.Text>{ trans('modal.autocompleteFields.dynamicValueBasedOn') }</Form.Text>
      </Col> }
    </> }
    <Col sm={ 1 } className={ 'd-flex align-items-start' }>
      <Button variant={ 'danger' } onClick={ onDelete } size={ 'sm' }>
        <i className={ 'fa fa-trash' }/>
      </Button>
    </Col>
  </Row>
}

export default AutocompleteField
