import {
  RECEIVE_SECTIONS,
  SET_DRAGGED_ELEMENT_ID,
  SET_TARGET_ELEMENT_ID,
  REORDER_SECTIONS,
  SECTIONS_REORDERED,
  ERROR_SWAPPING_ELEMENTS,
  ADD_SECTION,
  SECTION_ADDED,
  ERROR_ADDING_SECTION
} from './actionTypes'

export const initialState = {
  addError: '',
  draggedElementId: null,
  loadingAddSection: false,
  sections: {},
  swapError: '',
  targetElementId: null
}

export default (state = initialState, {
  type,
  payload
}) => {
  switch (type) {
    case RECEIVE_SECTIONS:
      return {
        ...state,
        sections: payload
      }
    case SET_DRAGGED_ELEMENT_ID:
      return {
        ...state,
        draggedElementId: payload
      }
    case SET_TARGET_ELEMENT_ID:
      return {
        ...state,
        targetElementId: payload
      }
    case REORDER_SECTIONS:
      return {
        ...state,
        draggedElementId: null,
        targetElementId: null,
        sections: {
          ...state.sections,
          [state.targetElementId]: {
            ...state.sections[state.targetElementId],
            sortOrder: state.sections[state.draggedElementId].sortOrder
          },
          [state.draggedElementId]: {
            ...state.sections[state.draggedElementId],
            sortOrder: state.sections[state.targetElementId].sortOrder
          }
        }
      }
    case ERROR_SWAPPING_ELEMENTS:
      return {
        ...state,
        swapError: payload
      }
    case ADD_SECTION:
      return {
        ...state,
        loadingAddSection: true,
        addError: ''
      }
    case SECTION_ADDED:
      return {
        ...state,
        loadingAddSection: false,
        addError: ''
      }
    case ERROR_ADDING_SECTION:
      return {
        ...state,
        addError: payload,
        loadingAddSection: false
      }
    default:
      return state
  }
};
