import React from 'react'
import { useSelector } from 'react-redux'
import { StoreState } from 'src/Services/Store/reducers'

const fieldGetter = () => {

  const { openedInstance } = useSelector((state: StoreState) => state.FormFiller)

  const getField = React.useCallback(
      (cell: any) => openedInstance.fields[cell?.id || cell] || null,
      [ openedInstance.fields ],
  )

  return { getField }
}

export default fieldGetter
