import { ReactReduxContext, connect } from 'react-redux'
import { defaultTo, pipe, prop, tap, uncurryN } from 'ramda'
import { useContext, useEffect } from 'react'

// useActions :: (Action -> *) -> [Action] -> [Action] -> Unit
export const useActions = uncurryN(3, startActions => stopActions => effectDependencies => {
  const { store } = useContext(ReactReduxContext)

  useEffect(() => {
    startActions.map(store.dispatch)

    return () => stopActions.map(store.dispatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, effectDependencies)
})

// preventDefault :: Event -> Event
export const preventDefault = tap(event => event.preventDefault())

// stopPropagation :: Event -> Event
export const stopPropagation = tap(event => event.stopPropagation())

// queryTargetValue :: CssSelector -> Event -> a
export const queryTargetValue = selector => pipe(
  event => event.target.querySelector(selector),
  defaultTo({ value: null }),
  prop('value')
)

const makeRecord = mapper => subject => {
  const destination = {}

  for (const key in mapper)
    destination[key] = mapper[key](subject)

  return destination
}

// withState :: Map String (State -> a) -> React.Component (Map String a)
export const withState = mapper => connect(makeRecord({
  ...mapper
}))
