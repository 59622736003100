import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { Accordion, Card } from 'react-bootstrap'
import { useAccordionButton } from 'react-bootstrap/AccordionButton'
import AccordionContext from 'react-bootstrap/AccordionContext'
import styled from 'styled-components'
import { ReactSortable } from 'react-sortablejs'
import { FIELD_BASE_TYPE_REFERENCE } from 'src/Services/Constants'
import { sortFieldsByOrder } from 'src/Views/FormFiller/utils/Fields.ts'
import { FieldOption } from 'src/Types/FieldOption'
import { Name, commonFormFieldStyle } from './shared'
import { openEditField, scrollExtensibleField, sortInsideAnExtensible } from '../../state/actions'
import  './Style/accordion.scss'

const ExtensibleField = ({
  fields = [],
  id = '',
  label,
  systemName,
  trans,
  isDragging = false,
  dispatch,
}) => {

  const { fieldTypes } = useSelector(state => state.Dictionary)
  const { extensibleScrollButtons, form } = useSelector(state => state.FormEditor.formReducer)
  const [ isCollapsed, setIsCollapsed ] = useState(true)
  fields.sort(function(x,y) {
    return x.options[FieldOption.SORT_ORDER] > y.options[FieldOption.SORT_ORDER]
  })

  const onScrollButtonClick = (direction, id) => () => dispatch(scrollExtensibleField(direction, id))

  const CustomToggle = ({ children, eventKey })  => {
    const { activeEventKey } = useContext(AccordionContext)

    const decoratedOnClick = useAccordionButton(eventKey, e => {
        e.preventDefault()
        setIsCollapsed(activeEventKey === '0')
      }
    )

    return (<a href={ '#' } onClick={ decoratedOnClick }>{ children }</a>)
  }

  const onDragField = datas => {
    const sortedFields = sortFieldsByOrder(datas)
    dispatch(sortInsideAnExtensible(form.id,id,sortedFields))
  }

  return (
    <ExtensibleFieldFieldWrapper className={ 'form-filler-extensible-field' }>
      <Accordion className={ isDragging ? 'undroppable-target' : '' } >
        <Card>
          <Card.Header className={ 'd-flex justify-content-between bg-white' }>
            <div>
              <div>
              <span className={ 'fa-stack' }>
                <i className={ 'fas fa-circle fa-stack-2x' }/>
                <i
                  className={ `
                  ${ fieldTypes.find(_ => _.baseFieldType === FIELD_BASE_TYPE_REFERENCE)?.icon }
                  fa-stack-1x` }
                />
              </span>
                <Name
                  onClick={ e => dispatch(openEditField(id)) }
                  className={ label === null ? 'empty' : '' }
                >
                  { label }
                </Name>
              </div>
            </div>
            <div>
              <CustomToggle eventKey={ '0' }>
                {
                  isCollapsed
                  ? <i className={ 'fa-solid fa-angle-down fa-2x' }></i>
                  : <i className={ 'fa-solid fa-angle-up fa-2x' }></i>
                }
              </CustomToggle>
            </div>
          </Card.Header>
          <Accordion.Collapse eventKey={ '0' }>
            <Card.Body className={ 'pb-2 pt-0' }>
              <ChildFields id={ `extensible_${ id }` }>
                { fields.length === 0 ?
                  <Placeholder>
                    <i className={ 'icon fas fa-bullseye' }/>
                    <span>{ trans('emptyPlaceholder.dragDropExtensible') }</span>
                  </Placeholder>
                 :
                  <ReactSortable list={ fields } setList={ onDragField }>
                    {
                      fields.map(field =>
                        <ChildField
                          key={ `extensibleChildField${ field.id }` }
                          onClick={ e => dispatch(openEditField(field.id)) }
                          className={ 'draggable' }
                        >
                          <i className={ `icon ${ field.type.icon } me-2 mt-2` } />
                          { field.label }
                        </ChildField>
                      )
                    }
                  </ReactSortable>
                 }
              </ChildFields>
              <ScrollButtons>
                { extensibleScrollButtons[id] && extensibleScrollButtons[id].showLeft &&
                  <ScrollButton className={ 'icon fas fa-chevron-left' }
                                onClick={ onScrollButtonClick('left', id) }
                  />
                }
                { extensibleScrollButtons[id] && extensibleScrollButtons[id].showRight &&
                  <RightScrollButton className={ 'icon fas fa-chevron-right' }
                                     onClick={ onScrollButtonClick('right', id) }
                  />
                }
              </ScrollButtons>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

    </ExtensibleFieldFieldWrapper>
  )
}

export default ExtensibleField

const ExtensibleFieldFieldWrapper = styled.div`
  ${ commonFormFieldStyle }
  span.empty {
    border-color: ${ props => props.theme.colors.grey.lightest };
    color: ${ props => props.theme.colors.grey.median };
    font-style: italic;
  }
`
const ChildFields = styled.div`
  background-color: white;
  color: ${ props => props.theme.colors.secondary.median };
  display: flex;
  align-items: center;
  position: relative;

  overflow-y: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  &::-webkit-scrollbar {
    display: none;
  }
`
const ChildField = styled.div`
  &:last-of-type {
    margin-right: 1.5em;
  }

  &:hover {
    color: #0d6189;

    i {
      border-color: #0d6189;
    }
  }

  i {
    padding: 0.5em;
    margin-left: 0.5em;
    border: 1px solid ${ props => props.theme.colors.secondary.median };
    border-radius: 50px;
    background-color: ${ props => props.theme.colors.secondary.light };
  }
`
const ScrollButtons = styled.div`
  position: absolute;
  right: 0;
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
`
const ScrollButton = styled.div`
  pointer-events: all;
  position: absolute;
  height: 100%;
  padding: 0 0.5em;
  background-color: ${ props => props.theme.colors.secondary.median };
  color: #0040f9;
  opacity: 0.5;

  &:hover {
    opacity: 0.8;
  }
`
const RightScrollButton = styled(ScrollButton)`
  right: 0;
`
const Placeholder = styled.div`
  display: flex;
  align-items: center;
  font-style: italic;
  color: ${ props => props.theme.styleguideColors.mainGrey };

  i {
    margin: 0 0.25em 0 0;
    font-size: 1.25em;
  }
`
