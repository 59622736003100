import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Accordion, Badge, Button, Card } from 'react-bootstrap'
import { useActions } from 'src/Components/Util'
import { translate, translateConf } from 'src/Services/translation'
import { getFormFillerOpenedPane } from 'src/Services/Store/Router/selectors'
import SectionNavigator from 'src/Components/SectionNavigator/index'
import { FIRST_NAME, LAST_NAME, EMAIL, NAME } from 'src/Services/Constants/Config/Patient'
import { MODULE_PATIENT_ACCOUNTS, MODULE_SCHEDULING } from 'src/Services/Constants/Config/Modules'
import { createPatientUser } from 'src/Views/UserForm/state/actions.ts'
import { impersonateUser } from 'src/Views/UserList/state/actions.ts'
import { getHeadersHeight, hasUserModulePermission } from 'src/Utils/index.ts'
import { PermissionAccessLevel } from 'src/Types/Permission'
import InstanceCard from './InstanceCard.tsx'
import Appointments from './Appointments'
import { setIsAddMenuOpen } from '../../state/actions'
import translations from '../../translations'

const PatientSidebar = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const { configTranslations } = useSelector(state => state.Root)
  const user = useSelector(state => state.Root.user)
  const { isHeaderSticky } = useSelector(state => state.View)
  const { roles } = useSelector(state => state.Dictionary)
  const { patient, instanciatedForms, htmlDetails, isSectionNav }
    = useSelector(state => state.Patient)

  const openedPane = getFormFillerOpenedPane(location)
  const trans = translate(translations)(user.language)
  const transConf = translateConf(configTranslations)

  const [ showPatientInfo, setShowPatientInfo ] = React.useState(true)
  const [ headersHeight, setHeadersHeight ] = React.useState(0)

  useActions([])([ setIsAddMenuOpen(false) ])([])

  /** @todo move that in a dedicated component and update the View store with fresh data for everyone */
  React.useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setHeadersHeight(getHeadersHeight(isHeaderSticky, true))
    })

    resizeObserver.observe(document.getElementById('viewHeader'))
  }, [ openedPane, isHeaderSticky ])

  const shouldDisplayFormSections = () =>
    (openedPane === null || [ 'instance' ].includes(openedPane)) && isSectionNav
  const shouldDisplayPatientAccountButton = accessLevel =>
    hasUserModulePermission(user, MODULE_PATIENT_ACCOUNTS, accessLevel)

  const onCreatePatientAccountButtonClick = () => {
    dispatch(createPatientUser(
      patient.id,
      patient[FIRST_NAME],
      patient[LAST_NAME],
      patient[EMAIL],
      roles.find(role => role.name === NAME)?.id
    ))
  }

  const onOpenPatientAccountButtonClick = () => navigate(`/user/${ patient.user?.id }`)

  const onPatientLogAsButtonClick = () => dispatch(impersonateUser(patient.user))

  const getNavigationStyle = () => ({
    position: 'sticky',
    top: `${ headersHeight + 10 }px`,
    maxHeight: `calc(100vh - ${ headersHeight + 20 }px)`,
    overflowY: 'auto'
  })

  return <div className={ 'h-100' }>

    { htmlDetails && <Card className={ 'mb-2 p-2' }>
      { showPatientInfo && <div dangerouslySetInnerHTML={ { __html: htmlDetails } }/> }
      <span className={ 'text-primary cursor-pointer' } onClick={ () => setShowPatientInfo(!showPatientInfo) }>
          { showPatientInfo ? 'Hide' : 'Show' } patient details
        </span>
    </Card> }

    { shouldDisplayPatientAccountButton(PermissionAccessLevel.CREATE) && !patient?.user?.id &&
      <Button variant={ 'secondary' } onClick={ onCreatePatientAccountButtonClick } size={ 'sm' }
              className={ 'mb-2' }>
        { trans('createPatientAccountButton') }
      </Button> }
    { shouldDisplayPatientAccountButton(PermissionAccessLevel.READ) && patient?.user?.id &&
      <Button variant={ 'secondary' } onClick={ onOpenPatientAccountButtonClick } size={ 'sm' } className={ 'mb-2' }>
        { trans('openPatientAccountButton') }
      </Button> }
    { shouldDisplayPatientAccountButton(PermissionAccessLevel.READ) && patient?.user?.id &&
      <Button variant={ 'secondary' } onClick={ onPatientLogAsButtonClick } size={ 'sm' } className={ 'ms-1 mb-2' }>
        { trans('logAsPatient') }
      </Button> }

    <div id={ 'patientSidebarNavigation' } style={ getNavigationStyle() }>
      <Accordion alwaysOpen defaultActiveKey={ [ 'sections' ] }>

        { /* SECTIONS */ }
        <Accordion.Item eventKey={ 'sections' }>
          <Accordion.Header>
            { trans('sections') }
          </Accordion.Header>
          <Accordion.Body className={ 'p-0' }>
            { shouldDisplayFormSections() && <SectionNavigator isFloatingAlone={ false }/> }
          </Accordion.Body>
        </Accordion.Item>

        <div className={ 'bg-grey' } style={ { height: '30px' } }>

        </div>

        { /* FORMS */ }
        <div id={ 'patientSidebarFormList' }>
          { instanciatedForms.map(({ headers, form, instances }, i) =>
            <Accordion.Item key={ i } eventKey={ `forms${ i }` }>
              <Accordion.Header>
                <Badge pill className={ 'me-1' }>{ instances ? instances.length : 0 }</Badge>
                <span>{ transConf('FORM')(form) }</span>
              </Accordion.Header>

              <Accordion.Body className={ 'p-1' }>
                { instances.map(instance =>
                  <InstanceCard key={ instance.id }
                                instance={ instance }
                                form={ form }
                                headers={ headers }
                  />
                ) }
              </Accordion.Body>
            </Accordion.Item>
          ) }
        </div>

        { /* APPOINTMENTS */ }
        { hasUserModulePermission(user, MODULE_SCHEDULING, PermissionAccessLevel.READ) && <Appointments/> }

      </Accordion>
    </div>
  </div>
}

export default PatientSidebar
