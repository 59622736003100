import Pagination from 'src/Types/Pagination'

export const contentRangeToPagination = (contentRangeRaw: string, currentPagination: Pagination = null): Pagination => {

  const contentRange = contentRangeRaw.match(/^(?:\w+ )?(\d+|\*)-(\d+|\*)\/(\d+|\*)$/u)

  const offset = contentRange[1] === '*' ? Infinity : Number(contentRange[1])
  const limit = contentRange[2] === '*' ? Infinity : Number(contentRange[2])
  const totalItemsCount = contentRange[3] === '*' ? Infinity : Number(contentRange[3])

  const itemsCount = limit - offset

  const newPagination: Pagination = {
    offset, limit, itemsCount, totalItemsCount
  }

  if (currentPagination?.pageLimit) {
    newPagination.totalPagesCount = Math.ceil(newPagination.totalItemsCount / currentPagination.pageLimit)
    newPagination.pageLimit = currentPagination.pageLimit

    if (currentPagination.currentPage) {
      newPagination.previousPage = Number(currentPagination.currentPage) - 1 < 1
        ? null : Number(currentPagination.currentPage) - 1
      newPagination.nextPage = Number(currentPagination.currentPage) + 1 > newPagination.totalPagesCount
        ? null : Number(currentPagination.currentPage) + 1

      newPagination.currentPage = currentPagination.currentPage
    }
  }

  return newPagination
}
