import React, { useState } from 'react'
import { Form as BForm, Button, Row, Col } from 'react-bootstrap'
import Select from 'react-select'
import { useSelector } from 'react-redux'
import EntitySelect from '../../../Components/EntitySelect'
import LocalizedDatepicker from 'src/Components/LocalizedDatepicker'

const Form = ({
  trans,
  onSubmit = () => {},
  item = null
}) => {
  const roles = useSelector(state => state.Dictionary.roles)

  const [ startAt, setStartAt ] = useState(item?.startAt || null)
  const [ endAt, setEndAt ] = useState(item?.endAt || null)
  const [ user, setUser ] = useState(item?.user || null)
  const [ role, setRole ] = useState(item?.role || null)
  const [ text, setText ] = useState(item?.text || '')

  const onFormSubmit = e => {
    e.preventDefault()

    onSubmit({
      ...item,
      startAt, endAt, text,
      user: user?.id || null,
      role: role?.id || null
    })
  }

  return (
    <BForm onSubmit={onFormSubmit}>
      <Row className={'mb-2'}>
        <Col>
          <BForm.Group>
            <BForm.Label>{trans('form.startAt')}</BForm.Label>
            <LocalizedDatepicker selected={ startAt }
                   onChange={ setStartAt }
                   size={ 'md' }
                   required
                   placeholder={trans('form.startAt')}
            />
          </BForm.Group>
        </Col>
        <Col>
          <BForm.Group>
            <BForm.Label>{trans('form.endAt')}</BForm.Label>
            <LocalizedDatepicker selected={ endAt }
                   onChange={ setEndAt }
                   size={ 'md' }
                   required
                   placeholder={trans('form.endAt')}
            />
          </BForm.Group>
        </Col>
      </Row>
      <Row className={'mb-2'}>
        <Col>
          <BForm.Group>
            <BForm.Label>{trans('form.text')}</BForm.Label>
            <BForm.Control as={'textarea'} value={text} name={'text'}
                           required type="text" onChange={ e => setText(e.target.value)}
                           placeholder={trans('form.text')} />
          </BForm.Group>
        </Col>
      </Row>
      <Row className={'mb-2'}>
        <Col>
          <BForm.Group>
            <BForm.Label>{trans('form.user')}</BForm.Label>
            <EntitySelect value={user} name={'user'} onChange={setUser} clearable
                          fetchUrl={'/users'} labelKey={'username'} disabled={role}
                          placeholder={trans('form.user')} />
          </BForm.Group>
        </Col>
        <Col>
          <BForm.Group>
            <BForm.Label>{trans('form.role')}</BForm.Label>
            <Select value={role} name={'role'} onChange={setRole} isClearable
                    options={roles} placeholder={trans('form.role')} disabled={user}
                    getOptionLabel={ r => r.name }
                    getOptionValue={ r => r.id } />
          </BForm.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button variant={'success'} style={{ float: 'right' }} type={'submit'}>{trans('submit')}</Button>
        </Col>
      </Row>
    </BForm>
  )
}

export default Form
