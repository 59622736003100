
interface Params {
    [key: string]: string
}

export const getParams = (): Params => {
    const params = new URLSearchParams(window.location.search)

    const result = {} as Params
    // @ts-ignore
    for (const [key, value] of params)
        result[key] = value

    return result
}
