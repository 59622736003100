import CalendarEvent, { CalendarEventExtended } from 'src/Components/Calendar/Types/CalendarEvent'
import { EventImpl } from '@fullcalendar/core/internal'
import { Event } from 'src/Types/Event'
import Slot from 'src/Types/Slot'
import { isUuidV4String } from 'src/Types/Uuid'
import { formatDateToIso8601 } from 'src/Utils/Date'
export * from 'src/Components/Calendar/Utils/filters'

export const getEventsOnRange = (startAt: string, endAt: string, events: CalendarEvent[]): CalendarEvent[] =>
    events.filter(e => (new Date(e.start) <= new Date(endAt)) && (new Date(startAt) <= new Date(e.end)))

export const calendarEventToEvent = (event: CalendarEvent | CalendarEventExtended): Event => {
  if ('extendedProps' in event)
    event = event.extendedProps

  return { ...event, type: event.eventType }
}

export const calendarEventToSlot = (event: CalendarEvent | CalendarEventExtended): Slot => {
  if ('extendedProps' in event)
    event = event.extendedProps

  return {
    ...event,
    type: event.slotType,
    isAvailability: 'isAvailability' in event ? event.isAvailability : true,
    event: event.event ? calendarEventToEvent(event.event) : null,
  }
}

/** Has to do it this way because full calendar event uses getters and not regular public properties */
export const fullCalendarEventToCalendarEvent = (event: EventImpl, timeZone: string): CalendarEvent => ({
  id: isUuidV4String(event.id) ? event.id : null,
  title: event.title,
  start: formatDateToIso8601(event.start, timeZone),
  end: formatDateToIso8601(event.end, timeZone),
  allDay: event.allDay,
  extendedProps: (event.extendedProps as CalendarEventExtended),
})
