import React from 'react'
import { Button, Row, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { translate } from '../Services/translation'

const ConfirmModal = ({
  title = '',
  isOpen = false,
  onClose = () => null,
  onConfirm = () => null,
  children = null,
  ...props
}) => {
  const { language } = useSelector(state => state.Root?.user)
  const trans = translate({ french: {}, english: {} })(language)

  const onConfirmClick = () => {
    onConfirm()
    onClose()
  }
  const onCancelClick = () => {
    onClose()
  }

  return <Modal show={ isOpen } onHide={ onClose } title={ title } { ...props }>
    <Modal.Header closeButton>
      <Modal.Title>{ title }</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      { children }
    </Modal.Body>
    <Modal.Footer>
      <Row>
        <Col>
          <Button variant={ 'danger' } onClick={ onCancelClick }>
            { trans('cancel') }
          </Button>
          <Button variant={ 'success' } onClick={ onConfirmClick } style={ { float: 'right' } }>
            { trans('confirm') }
          </Button>
        </Col>
      </Row>
    </Modal.Footer>
  </Modal>
}

export default ConfirmModal
