import * as types from './actionTypes'
import { Action } from 'src/Services/Store/reducers'
import { Instance } from 'src/Types/Instance'

export interface HeaderState {
  isUserDropdownOpen: boolean,
  isSearchInput: boolean,
  searchResults: [],
  isSearching: boolean,
  searchInputValue: string,
  isBurgerOpen: boolean,
  instanceData: Instance
}

const initialState: HeaderState = {
  isUserDropdownOpen: false,
  isSearchInput: false,
  searchResults: [],
  isSearching: false,
  searchInputValue: '',
  isBurgerOpen: false,
  instanceData: null
}

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.TOGGLE_USER_DROPDOWN:
      return {
        ...state,
        isUserDropdownOpen: !state.isUserDropdownOpen
      }
    case types.SET_IS_SEARCH_INPUT:
      return {
        ...state,
        isSearchInput: payload
      }
    case types.SET_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: payload,
        isSearching: false
      }
    case types.SET_SEARCH_INPUT_VALUE:
      return {
        ...state,
        searchInputValue: payload,
      }
    case types.LAUNCH_FETCH_INPUT_VALUE:
      return {
        ...state,
        isSearching: true
      }
    case types.CLEAR:
      return {
        ...initialState
      }
    case types.RESET_PATIENT_SEARCH:
      return {
        ...state,
        isUserDropdownOpen: initialState.isUserDropdownOpen,
        isSearchInput: initialState.isSearchInput,
        searchResults: initialState.searchResults,
        isSearching: initialState.isSearching,
        searchInputValue: initialState.searchInputValue,
        isBurgerOpen: initialState.isBurgerOpen,
        instanceData: initialState.instanceData
      }
    case types.TOGGLE_BURGER:
      return {
        ...state,
        isBurgerOpen: !state.isBurgerOpen
      }
    case types.SET_INSTANCE_DATA:
      return {
        ...state,
        instanceData: payload.instance,
      }
    default:
      return state
  }
}
