import React, { useRef } from 'react'
import { Modal as BModal } from 'react-bootstrap'

const Modal = ({
  title = 'Dialog',
  isOpen = false,
  onClose = () => {},
  children,
  footer = null,
  modalClass = null,
  ...props
}) => {
  const wrapperRef = useRef()

  return <BModal show={isOpen} onHide={onClose} ref={wrapperRef} {...props} className={modalClass}>
    <BModal.Header closeButton>
      <BModal.Title>{title}</BModal.Title>
    </BModal.Header>
    <BModal.Body>
      {children}
    </BModal.Body>
    {
      footer &&
      <BModal.Footer>
        {footer}
      </BModal.Footer>
    }
  </BModal>
}

export default Modal
