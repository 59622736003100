export const ERROR_HANDLER = '[USER_LIST] ERROR_HANDLER'
export const LOAD = '[USER_LIST] LOAD'
export const RECEIVE = '[USER_LIST] RECEIVE'
export const CHANGE_LIMIT = '[USER_LIST] CHANGE_LIMIT'
export const CHANGE_PAGE = '[USER_LIST] CHANGE_PAGE'
export const SEARCH_USER = '[USER_LIST] SEARCH_USER'
export const SORT_USERS = '[USER_LIST] SORT_USERS'
export const USER_LOADED = '[USER_LIST] USER_LOADED'
export const IMPERSONATE_USER = '[USER_LIST] IMPERSONATE_USER'
export const DELETE_USER = '[USER_LIST] DELETE_USER'
export const USER_DELETED = '[USER_LIST] USER_DELETED'
