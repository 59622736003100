export const FETCH_ITEMS = '[LANDING_PAGES] FETCH_ITEMS'
export const FETCH_ITEM = '[LANDING_PAGES] FETCH_ITEM'
export const CREATE_ITEM = '[LANDING_PAGES] CREATE_ITEM'
export const DELETE_ITEM = '[LANDING_PAGES] DELETE_ITEM'
export const UPDATE_ITEM = '[LANDING_PAGES] UPDATE_ITEM'

export const ITEMS_RECEIVED = '[LANDING_PAGES] ITEMS_RECEIVED'
export const ITEM_RECEIVED = '[LANDING_PAGES] ITEM_RECEIVED'
export const ITEM_CREATED = '[LANDING_PAGES] ITEM_CREATED'
export const ITEM_DELETED = '[LANDING_PAGES] ITEM_DELETED'
export const ITEM_UPDATED = '[LANDING_PAGES] ITEM_UPDATED'

export const SET_DELETING_ITEM = '[LANDING_PAGES] SET_DELETING_ITEM'
export const SET_UPDATING_ITEM = '[LANDING_PAGES] SET_UPDATING_ITEM'
export const SET_CREATING_ITEM = '[LANDING_PAGES] SET_CREATING_ITEM'

export const SET_QUERY = '[LANDING_PAGES] SET_QUERY'
export const SET_LIMIT = '[LANDING_PAGES] SET_LIMIT'
export const SET_PAGE = '[LANDING_PAGES] SET_PAGE'

export const ADD_ROW = '[LANDING_PAGES] ADD_ROW'
export const REQUEST_NEW_CONTENT = '[LANDING_PAGES] REQUEST_NEW_CONTENT'
export const REQUEST_EDIT_CONTENT = '[LANDING_PAGES] REQUEST_EDIT_CONTENT'
export const ADD_CONTENT = '[LANDING_PAGES] ADD_CONTENT'
export const UPDATE_CONTENT = '[LANDING_PAGES] UPDATE_CONTENT'
export const DELETE_ROW = '[LANDING_PAGES] DELETE_ROW'
export const DELETE_CONTENT = '[LANDING_PAGES] DELETE_CONTENT'
