export const LOAD_FORM_LIST = 'LOAD_FORM_LIST'
export const RECEIVE_FORM_LIST = 'RECEIVE_FORM_LIST'
export const CHANGE_LIMIT_FORM_LIST = 'CHANGE_LIMIT_FORM_LIST'
export const TOGGLE_LOCK_FORM = 'TOGGLE_LOCK_FORM'
export const CHANGE_PAGE_FORM_LIST = 'CHANGE_PAGE_FORM_LIST'
export const TOGGLE_ADD_FORM_FORM_LIST = 'TOGGLE_ADD_FORM_FORM_LIST'
export const ADD_FORM_FORM_LIST = 'ADD_FORM_FORM_LIST'
export const FORM_LIST_ADDED = 'FORM_LIST_ADDED'
export const ERROR_FORM_LIST_ADD = 'ERROR_FORM_LIST_ADD'
export const SEARCH_FORM_LIST = 'SEARCH_FORM_LIST'
export const DELETE_FORM_LIST = 'DELETE_FORM_LIST'
export const SET_TO_DELETE_FORM_LIST = 'SET_TO_DELETE_FORM_LIST'
export const SET_FORM_LOCKED = 'SET_FORM_LOCKED'
export const SET_FORM_UNLOCKED = 'SET_FORM_UNLOCKED'
export const SET_FORM_UNLOCKED_SUCCESS = 'SET_FORM_UNLOCKED_SUCCESS'
