import React, { FormEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Form, Row } from 'react-bootstrap'
import LocalizedDatepicker from 'src/Components/LocalizedDatepicker'
import { editSlot, setDeletingSlot, setEditingSlot } from 'src/Components/Calendar/state/actions'
import { SlotForApi } from 'src/Types/Slot'
import { StoreState } from 'src/Services/Store/reducers'
import { translate } from 'src/Services/translation'
import translations from 'src/Components/Calendar/translations'
import { Size } from 'src/Types/Size'
import AuditTrailsSimpleTable from '../../AuditTrailsSimpleTable'
import { ColumnValueType, FilterOperator, RequestFilter } from '../../../Types/RequestFilter'
import { AuditTrailLogFields } from '../../../Types/AuditTrailLog'
import { SortItemDirection } from '../../../Types/Pagination'

interface Props {
  onSubmit: (slot: SlotForApi) => void,
  onClose: () => void
}

// interface SlotForm extends HTMLFormElement {
//   readonly elements: SlotFormElements
// }

// interface SlotFormElements extends HTMLFormControlsCollection {
// }

const SlotForm = ({ onSubmit, onClose }: Props) => {
  const dispatch = useDispatch()

  const { language } = useSelector((state: StoreState) => state.Root.user)
  const { editingSlot } = useSelector((state: StoreState) => state.Calendar)

  const trans = translate(translations)(language)

  const handleDeleteClick = () => {
    dispatch(setEditingSlot(null))
    dispatch(setDeletingSlot(editingSlot))
  }

  const filter = React.useMemo((): RequestFilter => ({
    offset: 0,
    limit: 100,
    sortItems: [ { column: AuditTrailLogFields.TIMESTAMP, direction: SortItemDirection.DESC } ],
    filters: [
      {
        column: AuditTrailLogFields.CONTEXT,
        type: ColumnValueType.STRING,
        operator: FilterOperator.EQUAL,
        value: 'SLOT',
      },
      {
        column: AuditTrailLogFields.CONTEXT_IDENTIFIER,
        type: ColumnValueType.STRING,
        operator: FilterOperator.EQUAL,
        value: editingSlot?.id,
      },
    ],
  }), [ editingSlot?.id ])

  //   const handleSubmit = (e: FormEvent<SlotForm>) => {
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    const data: SlotForApi = {
      ...editingSlot,
      type: editingSlot.type?.id || null,
      location: editingSlot.location?.id || null,
      event: editingSlot.event?.id || null,
      resource: editingSlot.resource?.id || null,
      serie: editingSlot.serie?.id || null,
    }

    dispatch(editSlot(data))
    onSubmit(data)
  }

  return editingSlot ? <Form onSubmit={ handleSubmit }>
    <Row className="mb-2">
      <Col>
        <div>
          { editingSlot.event &&
            <small className="text-muted">{ trans('form.slot.disabledDeleteTitle') }</small> }</div>
      </Col>
    </Row>
    <Row className="mb-2">
      <Col>
        <Form.Group>
          <Form.Label>{ trans('form.slot.date') }</Form.Label>
          <LocalizedDatepicker selected={ editingSlot.start } disabled size={Size.MEDIUM}/>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Label>{ trans('form.slot.duration') }</Form.Label>
          <Form.Control name="duration" type="number" min={ 1 }
                        defaultValue={ editingSlot.type?.duration } disabled />
        </Form.Group>
      </Col>
    </Row>
    <Row className="mb-2">
      <Col>
        <Form.Group>
          <Form.Label>{ trans('form.slot.resource') }</Form.Label>
          <Form.Select name={ 'resource' } defaultValue={ editingSlot.resource?.id }>
            <option>{ trans('none') }</option>
            <option value={ editingSlot.resource?.id }>{ editingSlot.resource?.identityString }</option>
          </Form.Select>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Label>{ trans('form.slot.location') }</Form.Label>
          <Form.Select name={ 'location' } defaultValue={ editingSlot.location?.id }>
            <option>{ trans('none') }</option>
            <option value={ editingSlot.location?.id }>{ editingSlot.location?.identityString }</option>
          </Form.Select>
        </Form.Group>
      </Col>
    </Row>
    <Row className="mb-2">
      <Col md={6}>
        <Form.Group>
          <Form.Label>{ trans('form.event.type') }</Form.Label>
          <Form.Control name="duration" defaultValue={ editingSlot.type.eventType.label } disabled />
        </Form.Group>
      </Col>
    </Row>
    <Row className="mb-2">
      <Col md={6}>
        <h4>{trans('informations')}</h4>
      </Col>
    </Row>
    <Row className="mb-2">
      <Col md={6}>
        <Form.Group>
          <Form.Label>{ trans('form.slot.id') }</Form.Label>
          <Form.Control name="duration" defaultValue={ editingSlot.id } disabled />
        </Form.Group>
      </Col>
      <Col md={6}>
        <Form.Group>
          <Form.Label>{ trans('form.slot.seriesId') }</Form.Label>
          <Form.Control name="duration" defaultValue={ editingSlot.serie?.id } disabled />
        </Form.Group>
      </Col>
    </Row>
    <Row className="mb-2">
      <Col md={6}>
        <Form.Group>
          <Form.Label>{ trans('form.event.createdBy') }</Form.Label>
          <Form.Control name="duration" defaultValue={ editingSlot.createdBy?.username } disabled />
        </Form.Group>
      </Col>
      <Col md={6}>
        <Form.Group>
          <Form.Label>{ trans('form.event.createdAt') }</Form.Label>
          <LocalizedDatepicker selected={ editingSlot.createdAt } disabled size={Size.MEDIUM}/>
        </Form.Group>
      </Col>
    </Row>
    <Row className="d-flex justify-content-end">
      <Col className="d-flex justify-content-evenly" md={3}>
        <Button variant="danger" onClick={ handleDeleteClick } disabled={ !!editingSlot.event }
                title={ trans('delete') }>
          <i className="icon fas fa-trash" />
        </Button>
        <Button onClick={onClose} className="ms-1" variant={'outline-primary'}>{ trans('cancel') }</Button>
        <Button type="submit" variant="primary" className="ms-1" disabled>{ trans('submit') }</Button>
      </Col>
    </Row>
    <Row>
      <Col>
        <AuditTrailsSimpleTable filter={filter} />
      </Col>
    </Row>
  </Form> : <></>
}

export default SlotForm
