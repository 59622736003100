import React, { FormEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Row, Col } from 'react-bootstrap'
import { translate } from 'src/Services/translation'
import SearchInput from 'src/Components/SearchInput/index'
import LimitWrapper from 'src/Components/LimitWrapper'
import { changeListsPageSizeAction, changeListsSearchAction } from '../../state/actions'
import translation from '../../translations'
import { StoreState } from 'src/Services/Store/reducers'

const ListsHeader: React.FC = () => {
  const dispatch = useDispatch()

  const { language } = useSelector((state: StoreState) => state.Root.user)
  const { listsPagination } = useSelector((state: StoreState) => state.Homepage)
  const trans = translate(translation)(language)

  const formSubmitNoop = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    return false
  }

  return (
    <Form name="lists" onSubmit={formSubmitNoop}>
      <Row className="justify-content-between">
        <Col className="mb-2" md={12} lg={8}>
          <SearchInput
            name="lists-search"
            onChange={(e: any) =>
              dispatch(changeListsSearchAction(e.target.value))
            }
            placeholder={trans('listsSearchDefaultValue')}
          />
        </Col>
        <Col md={12} lg={4}>
          <LimitWrapper
            onLimitChange={limit => dispatch(changeListsPageSizeAction(limit))}
            limit={listsPagination.pageLimit}
            totalMsg={trans('listsSearchTotalResults')(listsPagination.totalItemsCount)}
          />
        </Col>
      </Row>
    </Form>
  )
}

export default ListsHeader
