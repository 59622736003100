export default interface Pagination {
  offset: number
  limit: number
  itemsCount: number
  totalItemsCount: number
  pageLimit?: number
  currentPage?: number
  nextPage?: number | null
  previousPage?: number | null
  totalPagesCount?: number
}

export enum SortItemDirection {
  ASC = 'asc',
  DESC = 'desc'
}

export interface SortItem {
  column: string
  direction: SortItemDirection
}
