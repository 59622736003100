import styled from 'styled-components'

export const Spinner = styled.span.attrs({ className: 'control is-loading' })`
  &.control.is-loading::after {
    border-left-color: ${props => props.theme.colors.primary.median};
    border-bottom-color: ${props => props.theme.colors.primary.median};
  }
`

export const StickyMenu = styled.div`
  border-radius: 6px 6px 8px 8px;
  position: sticky;
  top: ${props => props.theme.sizes.stickyMenus.top};

  header {
    color: ${props => props.theme.colors.white.median};
    font-size: 1em;
    font-weight: 900;
    padding: 0.5em 0.75em;
    background-color: ${props => props.theme.colors.grey.darkest};
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  ul {
    background-color: white;

    li {
      border-bottom: 1px solid ${props => props.theme.colors.secondary.alpha.greyish(0.12)};
      color: ${props => props.theme.colors.grey.median};
      padding: 0.5em;

      &:last-child {
        border-bottom: none;
      }
    }
  }
`
export const Section = styled.section`
  box-shadow: 0 10px 20px 0 rgba(153,153,153,0.16), 0 0 8px 0 rgba(25,25,25,0.18);
  background-color: white;
  padding: 1.5em;
  margin-bottom: 16px;

  h2 {
    font-size: 1.2em;
    color: #363636;
    font-weight: 700;
    padding-bottom: 1em;
  }
`
export const Form = styled.form`
  section {
    padding-bottom: 1.5em;

    &:last-of-type {
      padding-bottom: 0;
    }
  }

  fieldset {
    display: inline-block;
  }

  input, textarea {
    padding: 0.75em;
    width: 100%;
  }

  input, textarea, select {
    border-radius: 0;
    border: solid 1px rgba(74, 83, 97, 0.3);
    background-color: #ffffff;
  }

  input[type="checkbox"] {
    width: inherit;
  }

  label {
    display: block;
    color: ${props => props.theme.styleguideColors.lightBlueGrey};
    margin-bottom: 0.2em;
    padding-left: 0.1em;
  }

`
