import styled from 'styled-components'

export default styled.div`
  background-color: #FFFFFF;
  border-radius: 3px;
  box-shadow: 0 0 5px 0 rgba(25,25,25,0.18);
  display: flex;
  align-items: center;
  height: 3em;
  max-height: 55px;
  position: relative;
  padding-right: 1em;

  &.current-drag-and-drop-target {
    box-shadow: 0 0 5px 0 ${props => props.theme.colors.primary.alpha.light(0.58)};
  }

  ${props => props.draggable

  ? `
      cursor: pointer;

      &:hover {
        box-shadow: 0 0 5px 0 rgba(25,25,25,0.39);
      }
    `
  : ''
}
`
