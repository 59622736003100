export const FETCH_ITEMS = '[TASK_CATEGORIES] FETCH_ITEMS'
export const FETCH_ITEM = '[TASK_CATEGORIES] FETCH_ITEM'
export const CREATE_ITEM = '[TASK_CATEGORIES] CREATE_ITEM'
export const DELETE_ITEM = '[TASK_CATEGORIES] DELETE_ITEM'
export const UPDATE_ITEM = '[TASK_CATEGORIES] UPDATE_ITEM'

export const ITEMS_RECEIVED = '[TASK_CATEGORIES] ITEMS_RECEIVED'
export const ITEM_RECEIVED = '[TASK_CATEGORIES] ITEM_RECEIVED'
export const ITEM_CREATED = '[TASK_CATEGORIES] ITEM_CREATED'
export const ITEM_DELETED = '[TASK_CATEGORIES] ITEM_DELETED'
export const ITEM_UPDATED = '[TASK_CATEGORIES] ITEM_UPDATED'

export const SET_DELETING_ITEM = '[TASK_CATEGORIES] SET_DELETING_ITEM'
export const SET_UPDATING_ITEM = '[TASK_CATEGORIES] SET_UPDATING_ITEM'
export const SET_CREATING_ITEM = '[TASK_CATEGORIES] SET_CREATING_ITEM'

export const SET_QUERY = '[TASK_CATEGORIES] SET_QUERY'
export const SET_LIMIT = '[TASK_CATEGORIES] SET_LIMIT'
export const SET_PAGE = '[TASK_CATEGORIES] SET_PAGE'
