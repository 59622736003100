import { call, put, takeLatest, select } from 'redux-saga/effects'
import { path, reduce, merge } from 'ramda'
import apiMethods from 'src/Services/api/apiMethods'
import { showSuccess } from 'src/Services/notifier/actions'
import { formatColumnBody } from 'src/Utils/index.ts'
import { FIELD_BASE_TYPE_EXTENSIBLE } from 'src/Services/Constants'
import { JSON_OPTIONS } from 'src/Services/Constants'
import { getId } from 'src/Services/Selectors'
import { toggleOverlayLoader } from 'src/Services/Store/Root/actions'
import { LOAD_FORM, ADD_ROW, ADD_FIELD, FIELD_ADDED, UNDO_CHANGES, SAVE_FORM, OPEN_EDIT_FIELD, UPDATE_FIELD,
  FIELD_UPDATED, DELETE_FIELD, FIELD_DELETED, MOVE_FIELD_TO_ANOTHER_PLACE, ROW_ADDED, UPDATE_DETAILS, DETAILS_UPDATED,
  FIELD_MOVED, ADD_FIELD_IN_EXTENSIBLE, ADD_FULL_ROW_FIELD, ADD_REFERENCE_FIELD, INIT_EXTENSIBLE_SCROLL_BUTTONS,
  SCROLL_EXTENSIBLE_FIELD, SORT_INSIDE_AN_EXTENSIBLE, DELETE_SECTION, SECTION_DELETED, ROW_DELETED, DELETE_ROW,
  UPDATE_SECTION, SECTION_UPDATED, CREATE_CUSTOMIZATION_ORDER, UPDATE_CUSTOMIZATION_ORDER, DELETE_CUSTOMIZATION_ORDER,
  REORDER_ROW, ROW_REORDERED, RECEIVE_FORM, REPLACE_FIELD_OPTION_VALUES
} from './state/actionTypes'
import {
  ADD_SECTION, SECTION_ADDED, REORDER_SECTIONS, SECTIONS_REORDERED
} from './Components/Section/state/actionTypes'
import {
  closeFullRowModal, detailsUpdated, fieldAdded, fieldDeleted, fieldMoved, fieldUpdated,
  initExtensibleScrollButtons, receiveEditField, receiveForm, rowAdded, rowDeleted, sectionDeleted, sectionUpdated,
  setExtensibleScrollButtons, setRefFieldModalOpened, setSavingRefField, requestAddField, addFieldInExtensible,
  setIsCustomizationOrderLoading, loadForm, rowReordered, updateExtensibleFieldsOrder,
  setSystemFields
} from './state/actions'
import { receiveSections, sectionAdded, sectionsReordered } from './Components/Section/state/actions'
import { getHighestSortOrder } from './state/formReducer'

const formatDraggableSections = reduce(
  (sections, section) => merge(sections, { [section.id]: formatDraggableSection(section) })
)({})

const formatDraggableSection = raw => ({
  id: raw.id,
  sectionId: raw.id,
  name: raw.name,
  sortOrder: raw.sortOrder
})

function* reorderSections(props) {
  const state = yield select()
  const formId = state.FormEditor.formReducer.form?.id
  const sections = state.FormEditor.sectionReducer.sections

  try {
    yield call(apiMethods.update, `/forms/${ formId }/sections/sort`, {
      data: JSON.stringify(sections)
    })
    yield put(sectionsReordered())
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'reorderSectionFailed'))
  }
}

function* reorderRow(props, { payload: { row, targetRow, targetSection, targetRowSortOrder } }) {
  const state = yield select()

  const { form } = state.FormEditor.formReducer
  const formId = form?.id

  yield put(toggleOverlayLoader(true))

  try {
    yield call(apiMethods.update, `/forms/${ formId }/sections/${ getId(row.section) }/row/${ getId(row) }`, {
      data: {
        sortOrder: targetRowSortOrder || targetRow?.sortOrder || targetSection.rows.length + 1,
        section: getId(targetSection)
      }
    })

    if (targetRow)
      yield call(apiMethods.update, `/forms/${ formId }/sections/${ getId(targetSection) }/row/${ getId(targetRow) }`, {
        data: {
          sortOrder: row.sortOrder,
          section: getId(row.section)
        }
      })

    yield put(rowReordered())
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'reorderSectionFailed'))
  }

  yield put(toggleOverlayLoader(false))
}

function* updateForm(props, { payload }) {
  const state = yield select()
  const { form } = state.FormEditor.formReducer

  try {
    yield call(apiMethods.update, `/forms/${ form.id }`, {
      data: JSON.stringify(payload)
    })
    yield put(detailsUpdated())
  } catch (error) {
    yield put(props.globalActions.handleError(error, error.toString()))
  }
}

function* loadFormsHandler(props, { payload }) {
  const state = yield select()

  const getAllExtensibleIds = form => {
    if (!form.sections)
      return []

    return form.sections.reduce((acc, section) => {
      if (!section.fields || section.fields.length === 0)
        return acc

      const extensibles = section.fields.filter(field => field.type?.baseFieldType === FIELD_BASE_TYPE_EXTENSIBLE)
      const extensibleIds = extensibles.map(ext => ext.id)

      return [ ...acc, ...extensibleIds ]
    }, [])
  }

  try {

    let formId = payload?.formId
    if (!formId) {
      formId = state.FormEditor.formReducer.form.id
    }

    const { data } = yield call(apiMethods.get, `/forms/${ formId }`, { withUnsavedChanges: true })
    yield put(receiveForm(data))
    yield put(receiveSections(formatDraggableSections(data.sections || [])))
    yield put(initExtensibleScrollButtons(getAllExtensibleIds(data)))
    yield put(setIsCustomizationOrderLoading(false))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'loadFormFailed'))
    yield put(setIsCustomizationOrderLoading(false))
  }
}

function* setFormHandler(props, { payload: { form } }) {
  try {
    /** Get system fields */
    const { data: systemFields } = yield call(
      apiMethods.get,
      `/forms/${ form.id }/system_fields`,
      { all: true }
    )
    yield put(setSystemFields(systemFields))
    /** */
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'loadFormFailed'))
  }
}

function* addSectionHandler(props, { payload }) {
  try {
    yield call(apiMethods.create, `/forms/${ payload.id }/sections`, {
      data: { name: payload.data }
    })
    yield put(sectionAdded())
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'addSectionFailed'))
  }
}

function* addRow(props, { payload }) {
  const { sectionId, formId } = payload
  try {
    const { data } = yield call(apiMethods.create, `/forms/${ formId }/sections/${ sectionId }/rows`, {
      data: {}
    })
    yield put(rowAdded(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'addRowFailed'))
  }
}

function* saveFormChanges(props, { payload: { formId, navigate, withExit } }) {
  try {
    yield call(apiMethods.get, `/forms/${ formId }/unlock`)
    if (withExit) {
      yield call(navigate, '/forms')
    }
    yield put(showSuccess('saveFormSucceeded'))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'saveFormFailed'))
  }
}


function* undoFormChanges(props, { payload: { formId, navigate } }) {
  try {
    yield call(apiMethods.get, `/forms/${ formId }/unlock`, { cancel: true })
    yield call(navigate, '/forms')
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'undoChangesFailed'))
  }
}

function* doAddField(props, { payload }) {
  const {
    form: { id: formId },
    targetField,
    newField
  } = (yield select()).FormEditor.formReducer

  const field = {
    label: newField.label,
    systemName: newField.systemName,
    type: newField.type.id,
    row: targetField.row?.id,
    rowSize: targetField.rowSize,
    rowColumn: targetField.rowColumn,
    section: targetField.sectionId
  }

  if (newField.list?.id)
    field.list = newField.list.id

  try {
    yield call(apiMethods.create, `/forms/${ formId }/fields`, { data: field })
    yield put(fieldAdded(formId))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'createFieldFailed'))
  }
}

function* loadField(props, { payload }) {
  const state = yield select()
  const { form } = state.FormEditor.formReducer
  try {
    const { data } = yield call(apiMethods.get, `/forms/${ form.id }/fields/${ payload }`)
    yield put(receiveEditField(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'loadFieldFailed'))
  }
}

function* updateField(props, { payload }) {
  const state = yield select()
  const { editedField, form } = state.FormEditor.formReducer

  const javaScriptCode = payload.javaScriptCode || null
  for (const option in payload.options) {
    if (JSON_OPTIONS.includes(option) && payload.options[option])
      payload.options[option] = JSON.stringify(payload.options[option])
  }

  if (editedField.type.baseFieldType === FIELD_BASE_TYPE_EXTENSIBLE) {
    const fieldSectionId = state.FormEditor.formReducer.editedField.sectionId
    const fieldRowId = state.FormEditor.formReducer.editedField.row.id
    const form = state.FormEditor.formReducer.form

    const section = form.sections.find( section => section.id === fieldSectionId)
    const row = section.rows.find(row => row.id === fieldRowId)
    const field = row.fields.find(field => field.id === editedField.id)

    const fields = field.fields && field.fields.map(field => {
      return {
        id: field.id,
        javaScriptCode: null,
        options: { ...field.options },
        rowSize: field.rowSize
      }
    })
    payload.fields = fields
  }
  payload.type = payload.type.id || payload.type
  payload.form = payload.form.id || payload.form
  payload.list = payload.list?.id || payload.list || null
  payload.row = payload.row?.id || payload.row || null
  payload.extensible = payload.extensible?.id || payload.extensible || null

  delete payload.referenceFields

  try {
    yield call(apiMethods.update, `/forms/${ form.id }/fields/${ editedField.id }`, {
      data: { ...payload, javaScriptCode, }
    })
    yield put(fieldUpdated())
    yield put(showSuccess('updateFieldSucceeded'))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'updateFieldFailed'))
  }
}

function* updateSection(props, { payload }) {
  const state = yield select()
  const { form } = state.FormEditor.formReducer

  try {
    yield call(apiMethods.update, `/forms/${ form.id }/sections/${ payload.id }`, {
      data: payload.details
    })
    yield put(sectionUpdated())
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'updateSectionFailed'))
  }
}

function* deleteField(props, { payload }) {
  const state = yield select()
  const { form } = state.FormEditor.formReducer

  try {
    yield call(apiMethods.delete, `/forms/${ form.id }/fields/${ payload }`)
    yield put(fieldDeleted())
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'deleteFieldFailed'))
  }
}

function* deleteSection(props, { payload }) {
  const state = yield select()
  const { form } = state.FormEditor.formReducer

  try {
    yield call(apiMethods.delete, `/forms/${ form.id }/sections/${ payload }`)
    yield put(sectionDeleted())
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'deleteSectionFailed'))
  }
}

function* deleteRow(props, { payload }) {
  const state = yield select()
  const form = state.FormEditor.formReducer?.form

  try {
    yield call(apiMethods.delete, `/forms/${ form.id }/sections/${ payload.sectionId }/rows/${ payload.id }`)
    yield put(rowDeleted())
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'deleteRowFailed'))
  }
}

function* moveFieldToAnotherPlace(props, { payload }) {
  const state = yield select()
  const { form, targetField } = state.FormEditor.formReducer

  const data = {
    row: payload.rowId || targetField.row.id,
    rowSize: payload.rowSize || targetField.rowSize,
    rowColumn: payload.rowColumn || targetField.rowColumn
  }

  try {
    yield call(apiMethods.update, `/forms/${ form.id }/fields/${ payload.fieldId }`, { data })
    yield put(fieldMoved())
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'moveFieldFailed'))
  }
}

function* doAddFieldInExtensible(props, { payload }) {
  try {
    const {
      form: { id: formId, sections },
      targetField,
      newField
    } = (yield select()).FormEditor.formReducer

    if (newField.type.baseFieldType === FIELD_BASE_TYPE_EXTENSIBLE)
      return alert('Can\'t add extensible in extensible field.')

    const extensibleId = targetField.id
    const sortOrder = getHighestSortOrder(sections)(extensibleId)

    const field = {
      label: newField.label,
      systemName: newField.systemName,
      type: newField.type.id,
      extensible: extensibleId,
      options: {
        sortOrder: sortOrder + 1
      }
    }

    if (newField.list?.id)
      field.list = newField.list.id

    yield call(apiMethods.create, `/forms/${ formId }/fields`, { data: field })
    yield put(fieldAdded(formId))
    yield put(closeFullRowModal())
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'addFieldInExtensible'))
  }
}

function* addFullRowField(props, { payload }) {
  try {
    yield call(apiMethods.create, `/forms/${ payload.formId }/fields`, {
      data: {
        name: payload.fieldType.name,
        type: payload.fieldType.id,
        newRowAfter: payload.afterRow,
        section: payload.sectionId
      }
    })
    yield put(fieldAdded())
    yield put(closeFullRowModal())
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'addFullRowField'))
  }
}

function* addReferenceFieldList(props, { payload }) {
  try {
    const { targetField } = (yield select()).FormEditor.formReducer
    const { newField, newList } = payload

    const { data } = yield call(apiMethods.create, '/lists', {
      data: {
        form: newList.form?.id,
        referenceForm: newList.refForm?.id,
        type: 'Reference',
        label: newList.label,
        systemName: newList.systemName
      }
    })
    for (const column of newList.columns)
      yield call(apiMethods.create, `/lists/${ data.id }/columns`, {
        data: formatColumnBody(column.field, column)
      })

    yield put(setRefFieldModalOpened(false))
    yield put(requestAddField(targetField, { ...newField, list: data }))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'addReferenceFieldFailed'))
    yield put(setSavingRefField(false))
  }
}

function* initExtensibleScrollButtonsHandler(props, { payload }) {
  try {
    const extensibles = payload.reduce((acc, id) => {
      const el = document.querySelector(`#extensible_${ id }`)

      const showRightButton = el.scrollWidth > el.clientWidth

      return [ ...acc, [ id, showRightButton ] ]
    }, [])
    for (const row of extensibles) {
      const [ id, showRight ] = row
      yield put(setExtensibleScrollButtons(id, false, showRight))
    }
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'initExtensibleScrollButtons'))
  }
}

function* scrollExtensibleField(props, { payload }) {
  const SCROLL_DISTANCE = 150

  const scrollHorizontally = ({ id, direction }) => {
    const childFieldsEl = document.querySelector(`#extensible_${ id }`)
    const left = direction === 'right' ? SCROLL_DISTANCE : -SCROLL_DISTANCE
    const isScrollBySupported = Boolean(document.body.scrollBy)

    if (!isScrollBySupported)
      childFieldsEl.scrollLeft += left
    else
      childFieldsEl.scrollBy({
        left,
        behavior: 'smooth'
      })

    return { id, direction, childFieldsEl }
  }

  const showOrHideScrollButtons = ({
    id,
    direction,
    childFieldsEl
  }) => {
    const showLeft = direction === 'right' || childFieldsEl.scrollLeft - SCROLL_DISTANCE > 0
    const showRight = direction === 'left'
      || childFieldsEl.scrollLeft + SCROLL_DISTANCE + childFieldsEl.clientWidth < childFieldsEl.scrollWidth

    return setExtensibleScrollButtons(id, showLeft, showRight)
  }

  try {
    scrollHorizontally()
    showOrHideScrollButtons()
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'scrollExtensibleField'))
  }
}

function* sortInsideAnExtensible(props, { payload }) {
  try {
    yield put(updateExtensibleFieldsOrder(payload))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'sortInsideAnExtensible'))
  }
}

function* searchCreateFormFields(props, { payload }) {
  const state = yield select()
  const { id } = path([ 'FormEditor', 'formReducer', 'form' ], state)

  const { data } = yield call(apiMethods.get, `/forms/${ id }/reference_fields`, { query: payload.newValue })
}

function* doCreateCustomizationOrder(props, { payload: { item } }) {
  const state = yield select()
  const { id } = path([ 'FormEditor', 'formReducer', 'form' ], state)

  try {
    yield put(setIsCustomizationOrderLoading(true))
    const { data } = yield call(apiMethods.create, `/forms/${ id }/customization_order`, { data: item })
    yield put(loadForm(id))
  } catch (error) {
    yield put(setIsCustomizationOrderLoading(false))
    yield put(props.globalActions.handleError(error, 'createFailed'))
  }
}

function* doUpdateCustomizationOrder(props, { payload: { item } }) {
  const state = yield select()
  const { id } = path([ 'FormEditor', 'formReducer', 'form' ], state)

  try {
    yield put(setIsCustomizationOrderLoading(true))
    const updatedItem = {
      ...item,
      conditions: item.conditions.map(condition => {
        if (condition.listColumn) {
          condition.listColumn = condition.listColumn.id
        }
        return condition
      })
    }
    const { data } = yield call(apiMethods.update, `/forms/${ id }/customization_order/${ updatedItem.id }`, { data: updatedItem })
    yield put(loadForm(id))
  } catch (error) {
    yield put(setIsCustomizationOrderLoading(false))
    yield put(props.globalActions.handleError(error, 'updateFailed'))
  }
}

function* doDeleteCustomizationOrder(props, { payload: { item } }) {
  const state = yield select()
  const { id } = path([ 'FormEditor', 'formReducer', 'form' ], state)

  try {
    yield put(setIsCustomizationOrderLoading(true))
    const { data } = yield call(apiMethods.delete, `/forms/${ id }/customization_order/${ item.id }`)
    yield put(loadForm(id))
  } catch (error) {
    yield put(setIsCustomizationOrderLoading(false))
    yield put(props.globalActions.handleError(error, 'deleteFailed'))
  }
}

function* replaceFieldOptionValues(props, { payload: { field, optionSystemName, replacementOptionSystemName } }) {
  const state = yield select()
  const { form } = state.FormEditor.formReducer

  try {
    yield call(apiMethods.update, `/forms/${ form.id }/fields/${ field.id }/replace_option_values`, {
      data: { oldOption: optionSystemName, replacementOption: replacementOptionSystemName }
    })
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'updateFailed'))
  }
}

export default function* FormEditorSagaWatcher(props) {
  yield takeLatest(LOAD_FORM, loadFormsHandler, props)
  yield takeLatest(RECEIVE_FORM, setFormHandler, props)
  yield takeLatest(FIELD_ADDED, loadFormsHandler, props)
  yield takeLatest(FIELD_UPDATED, loadFormsHandler, props)
  yield takeLatest(SECTION_UPDATED, loadFormsHandler, props)
  yield takeLatest(FIELD_DELETED, loadFormsHandler, props)
  yield takeLatest(SECTION_DELETED, loadFormsHandler, props)
  yield takeLatest(ROW_DELETED, loadFormsHandler, props)
  yield takeLatest(ROW_ADDED, loadFormsHandler, props)
  yield takeLatest(SECTION_ADDED, loadFormsHandler, props)
  yield takeLatest(SECTIONS_REORDERED, loadFormsHandler, props)
  yield takeLatest(ROW_REORDERED, loadFormsHandler, props)
  yield takeLatest(DETAILS_UPDATED, loadFormsHandler, props)
  yield takeLatest(FIELD_MOVED, loadFormsHandler, props)
  yield takeLatest(ADD_SECTION, addSectionHandler, props)
  yield takeLatest(ADD_FIELD, doAddField, props)
  yield takeLatest(ADD_ROW, addRow, props)
  yield takeLatest(UNDO_CHANGES, undoFormChanges, props)
  yield takeLatest(SAVE_FORM, saveFormChanges, props)
  yield takeLatest(OPEN_EDIT_FIELD, loadField, props)
  yield takeLatest(UPDATE_SECTION, updateSection, props)
  yield takeLatest(UPDATE_FIELD, updateField, props)
  yield takeLatest(DELETE_FIELD, deleteField, props)
  yield takeLatest(DELETE_ROW, deleteRow, props)
  yield takeLatest(DELETE_SECTION, deleteSection, props)
  yield takeLatest(MOVE_FIELD_TO_ANOTHER_PLACE, moveFieldToAnotherPlace, props)
  yield takeLatest(REORDER_SECTIONS, reorderSections, props)
  yield takeLatest(REORDER_ROW, reorderRow, props)
  yield takeLatest(UPDATE_DETAILS, updateForm, props)
  yield takeLatest(ADD_FIELD_IN_EXTENSIBLE, doAddFieldInExtensible, props)
  yield takeLatest(ADD_FULL_ROW_FIELD, addFullRowField, props)
  yield takeLatest(ADD_REFERENCE_FIELD, addReferenceFieldList, props)
  yield takeLatest(INIT_EXTENSIBLE_SCROLL_BUTTONS, initExtensibleScrollButtonsHandler, props)
  yield takeLatest(SCROLL_EXTENSIBLE_FIELD, scrollExtensibleField, props)
  yield takeLatest(SORT_INSIDE_AN_EXTENSIBLE, sortInsideAnExtensible, props)
  yield takeLatest(CREATE_CUSTOMIZATION_ORDER, doCreateCustomizationOrder, props)
  yield takeLatest(UPDATE_CUSTOMIZATION_ORDER, doUpdateCustomizationOrder, props)
  yield takeLatest(DELETE_CUSTOMIZATION_ORDER, doDeleteCustomizationOrder, props)
  yield takeLatest(REPLACE_FIELD_OPTION_VALUES, replaceFieldOptionValues, props)
}
