import React, { useEffect } from 'react'
import { Popover, OverlayTrigger, Button } from 'react-bootstrap'
import { FilterOperator, NextFilterOperator } from 'src/Types/RequestFilter'
import ColumnFilter from './ColumnFilter'

const ColumnFilters = ({ column, filters = [], onChange, trans }) => {
  const getEmptyFilter = () => ({
    column: column.columnDef.id,
    value: '',
    type: column.columnDef.valueType,
    operator: FilterOperator.EQUAL,
    nextFilterOperator: null
  })

  const [ columnFilters, setColumnFilters ] = React.useState(filters.filter(f => f.column === column.columnDef.id))
  const [ isOverlayOpen, setIsOverlayOpen ] = React.useState(false)

  useEffect(() => {
    if (filters)
      setColumnFilters(filters.filter(f => f.column === column.columnDef.id))
  }, [ filters ])

  const onFilterChange = (filterIndex, field, value) => {
    const updatedFilters = [ ...columnFilters ]

    if (field === 'nextFilterOperator') {
      updatedFilters[filterIndex -1].nextFilterOperator = value
    } else {
      updatedFilters[filterIndex][field] = value
    }

    setColumnFilters(updatedFilters)
  }

  const onRemoveFilter = index =>
    setColumnFilters(columnFilters => {
      columnFilters = columnFilters.filter((f, i) => index !== i)

      if (!columnFilters.length) {
        setIsOverlayOpen(false)
        onChange([])
      }
      else
        columnFilters[columnFilters.length - 1].nextFilterOperator = null

      return columnFilters
    })

  const onAddFilter = () => {
    let newFilters = columnFilters

    newFilters[newFilters.length - 1].nextFilterOperator = NextFilterOperator.AND
    newFilters = [ ...newFilters, getEmptyFilter() ]

    setColumnFilters(newFilters)
  }

  const onSubmit = () => {
    onChange(columnFilters)
    setIsOverlayOpen(false)
  }

  const onCancel = () => {
    onChange([])
    setIsOverlayOpen(false)
    setColumnFilters([])
  }

  const getPopover = () =>
    <Popover className={ 'table-filters-popover' }>
      <Popover.Body>
        { columnFilters.map((filter, i) =>
          <ColumnFilter key={ i }
                        column={ column }
                        filter={ { ...filter, filterOperator: columnFilters[i - 1]?.nextFilterOperator || null } }
                        onRemove={ onRemoveFilter }
                        onChange={ onFilterChange }
                        filterIndex={ i }
          />) }
        <div className={ 'p-3 d-flex justify-content-between' }>
          <div>
            <Button className={ 'me-1' } size={ 'sm' } variant={ 'primary' } onClick={ onSubmit }>
              { trans('submit') }
            </Button>
            <Button size={ 'sm' } variant={ 'outline-primary' } onClick={ onAddFilter }>
              { trans('add') }
            </Button>
          </div>
          <div>
            <Button size={ 'sm' } variant={ 'outline-danger' } onClick={ onCancel }>
              { trans('cancel') }
            </Button>
          </div>
        </div>
      </Popover.Body>
    </Popover>

  const onToggle = isToggle => {
    setIsOverlayOpen(isToggle)

    if (!columnFilters.length)
      setColumnFilters([ getEmptyFilter() ])
    if (!isToggle)
      setColumnFilters([])
  }

  return <div className={ 'column-filters d-inline-block position-relative' }>
    <OverlayTrigger trigger={ 'click' } placement={ 'bottom' } show={ isOverlayOpen }
                    overlay={ getPopover() } onToggle={ onToggle }
    >
      <i className={ 'bi bi-funnel cursor-pointer' } />
    </OverlayTrigger>
    { columnFilters.length !== 0 && <div className={ 'filter-count' }>
      { columnFilters.length }
    </div> }
  </div>
}

export default ColumnFilters
