import React from 'react'
import { path } from 'ramda'
import translation from '../translations'
import { FieldType } from './FieldType'
import { translate } from '../../../Services/translation'
import { withState } from '../../../Components/Util'
import { Card, Col, Row } from 'react-bootstrap'

const propsFromState = {
  fieldTypes: path([ 'Dictionary', 'fieldTypes' ]),
  language: path([ 'Root', 'user', 'language' ])
}

const FieldList = ({ language, fieldTypes = [] }) => {
  const trans = translate(translation)(language)

  return <Card>
    <Card.Header>{ trans('fieldTypes') }</Card.Header>
    <Card.Body className={'pb-0'}>
      <Row>
        { fieldTypes.map(fieldType =>
          <Col sm={6} className='' key={ fieldType.id }>
            <FieldType fieldType={ fieldType }/>
          </Col>
        ) }
      </Row>
    </Card.Body>
  </Card>
}

export default withState(propsFromState)(FieldList)
