import { Column } from 'src/Views/AuditTrails/Types'
import { ColumnValueType } from 'src/Types/RequestFilter'
import { AuditTrailLogFields } from 'src/Types/AuditTrailLog'

const columns: Column[] = [
  {
    name: AuditTrailLogFields.CODE,
    type: ColumnValueType.STRING,
    enableSorting: true,
    enableColumnFilter: true,
    showDateOnly: null,
    showTimeOnly: null,
  },
  {
    name: AuditTrailLogFields.TIMESTAMP,
    type: ColumnValueType.DATE,
    enableSorting: true,
    enableColumnFilter: true,
    showDateOnly: false,
    showTimeOnly: false,
  },
  {
    name: AuditTrailLogFields.IP,
    type: ColumnValueType.STRING,
    enableSorting: true,
    enableColumnFilter: true,
    showDateOnly: null,
    showTimeOnly: null,
  },
  {
    name: AuditTrailLogFields.USER,
    type: ColumnValueType.NUMBER,
    enableSorting: true,
    enableColumnFilter: true,
    showDateOnly: null,
    showTimeOnly: null,
  },
  {
    name: AuditTrailLogFields.IMPERSONATOR,
    type: ColumnValueType.NUMBER,
    enableSorting: true,
    enableColumnFilter: true,
    showDateOnly: null,
    showTimeOnly: null,
  },
  {
    name: AuditTrailLogFields.DATA,
    type: ColumnValueType.STRING,
    enableSorting: false,
    enableColumnFilter: true,
    showDateOnly: null,
    showTimeOnly: null,
  },
  {
    name: AuditTrailLogFields.WORKFLOW_ID,
    type: ColumnValueType.NUMBER,
    enableSorting: true,
    enableColumnFilter: true,
    showDateOnly: null,
    showTimeOnly: null,
  },
  {
    name: AuditTrailLogFields.CONTEXT,
    type: ColumnValueType.STRING,
    enableSorting: true,
    enableColumnFilter: true,
    showDateOnly: null,
    showTimeOnly: null,
  },
  {
    name: AuditTrailLogFields.CONTEXT_IDENTIFIER,
    type: ColumnValueType.STRING,
    enableSorting: true,
    enableColumnFilter: true,
    showDateOnly: null,
    showTimeOnly: null,
  },
  {
    name: AuditTrailLogFields.SERVICE,
    type: ColumnValueType.STRING,
    enableSorting: true,
    enableColumnFilter: true,
    showDateOnly: null,
    showTimeOnly: null,
  },
  {
    name: AuditTrailLogFields.CONFIG_ID,
    type: ColumnValueType.NUMBER,
    enableSorting: true,
    enableColumnFilter: true,
    showDateOnly: null,
    showTimeOnly: null,
  },
]

export default columns
