import { Locale } from 'src/Types/Locale'

export const US = Locale.US
export const FR = Locale.FR

export const DATE_TIME_SERVICE_DAY_JS = 'DAY_JS'
export const DATE_TIME_SERVICE_REACT_DATE_PICKER = 'REACT_DATE_PICKER'

export const DATE_TIME_FORMAT = {
  [DATE_TIME_SERVICE_REACT_DATE_PICKER]: {
    [US]: 'MM/dd/yyyy h:mm a',
    [FR]: 'dd/MM/yyyy HH:mm'
  },
  [DATE_TIME_SERVICE_DAY_JS]: {
    [US]: 'MM/DD/YYYY h:mm a',
    [FR]: 'DD/MM/YYYY HH:mm'
  }
}
export const DATE_FORMAT = {
  [DATE_TIME_SERVICE_REACT_DATE_PICKER]: {
    [US]: 'MM/dd/yyyy',
    [FR]: 'dd/MM/yyyy'
  },
  [DATE_TIME_SERVICE_DAY_JS]: {
    [US]: 'MM/DD/YYYY',
    [FR]: 'DD/MM/YYYY'
  }
}
export const TIME_FORMAT = {
  [DATE_TIME_SERVICE_REACT_DATE_PICKER]: {
    [US]: 'h:mm a',
    [FR]: 'HH:mm'
  },
  [DATE_TIME_SERVICE_DAY_JS]: {
    [US]: 'h:mm a',
    [FR]: 'HH:mm'
  }
}
