import React, { useEffect, useState, useRef } from 'react'
import { Form, InputGroup, Button, Overlay, Tooltip } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { toSnakeCase } from 'src/Utils/index.ts'
import translations from './translations'
import { translate } from '../../Services/translation/index'
import APIMethods from '../../Services/api/apiMethods'

const SystemNameInput = ({
  systemName, onChange, label, parent = null, onFieldBlur,
  model = null, name = 'systemName', required = false,
  ...props
}) => {
  const { language } = useSelector(state => state.Root.user)
  const trans = translate(translations)(language)

  const [ value, setValue ] = React.useState(systemName || null)
  const [ showTooltip, setShowToolTip ] = React.useState(false)
  const [ end, setEnd ] = React.useState(parent || null)

  const [ isChecking, setIsChecking ] = useState(false)
  const [ isAvailable, setIsAvailable ] = useState(null)
  const [ checkAvailabilityRequest, setCheckAvailabilityRequest ] = useState(null)
  const targetTooltipField = useRef(null)
  const systemNameInput = useRef(null)

  const getFullValue = (newValue = null) => {
    let result = toSnakeCase(newValue || value)

    return end ? `${ result }#${ end }` : result
  }

  const onValueChange = value => {

    onChange(getFullValue(value), null)

    clearTimeout(checkAvailabilityRequest)

    if (!model || !value || !getFullValue(value))
      return setIsAvailable(null)

    setIsChecking(true)

    setCheckAvailabilityRequest(setTimeout(() =>
        APIMethods.get(`/check/system_name_availability/${ model }`, { systemName: getFullValue(value) })
          .then(res => {
            setIsAvailable(res.data === true)
            onChange(getFullValue(value), res.data === true)
          })
          .finally(() => setIsChecking(false))
      , 1000))
  }

  const setValues = value => {

    if (!value || typeof value !== 'string' || value.length === 0)
      return

    value = value.split('#')

    if (value.length > 1) {
      setValue(toSnakeCase(value[0]))
      value.shift()
      setEnd(value.join('#'))
    } else {
      setValue(toSnakeCase(value[0]))
    }
  }

  React.useEffect(() => {
    setValues(label)
  }, [ label ])

  React.useEffect(() => {
    onValueChange(value)
  }, [ value ])
  React.useEffect(() => {
    setEnd(parent)
  }, [ parent ])

  // Component unmount
  useEffect(() =>
      () => {
        setValue(null)
        setEnd(null)
        setIsChecking(false)
        setIsAvailable(null)
      }
    , [])

  const onInputKeyDown = e => {

    /** Does not cancel anything typed after caret (^) */
    if (
      [ 37, 38, 39, 40, 8 ].includes(e.keyCode)
      || /^[a-zA-Z0-9_#]$/.test(e.key)
    )
      return

    e.preventDefault()
    return false
  }

  const onInputChange = e => {
    if (e.target.value !== value) {
      setValue(e.target.value)
    }
  }

  const onInputBlur = e => {
      setValue(e.target.value.toUpperCase())
      onFieldBlur(e)
  }

  const onCopyClick = e => {
    const fullValue = value + '#' + end
    navigator.clipboard.writeText(fullValue)
    setShowToolTip(true)
    setTimeout(() => setShowToolTip(false), 1500)
  }

  const getValue = () => !model || isAvailable ? getFullValue(value) : ''

  return <>
    <Form.Control type={ 'hidden' } name={ name }
                  required={ required } value={ getValue() }/>
    <InputGroup size={ props.size || 'md' }>
      <Form.Control
        ref={ systemNameInput }
        value={ value || '' }
        onKeyDown={ onInputKeyDown }
        onChange={ onInputChange }
        onBlur={ onInputBlur }
        required={ required }
        size={ props.size || 'md' }
        { ...props }
      />
      {
        end &&
        <>
          <InputGroup.Text>#{ end }</InputGroup.Text>
          <Button
            onClick={ onCopyClick }
            ref={ targetTooltipField }
          >
            <i className={ 'fas fa-copy' }/>
          </Button>
          <Overlay
            target={ targetTooltipField.current }
            show={ showTooltip }
            placement={ 'top' }
          >
            { props => (
              <Tooltip  { ...props }>
                { trans('contentIsCopyed') }
              </Tooltip>
            ) }
          </Overlay>
        </>
      }
    </InputGroup>
    { isChecking && <Form.Text>{ trans('checking') }</Form.Text> }
    { !isChecking && isAvailable !== null && <Form.Text className={ `text-${ isAvailable ? 'success' : 'danger' }` }>
      { trans(isAvailable ? 'isAvailable' : 'isNotAvailable') }
    </Form.Text> }
  </>
}

export default SystemNameInput
