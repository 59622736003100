import React, { FC, ChangeEventHandler } from 'react'
import { useSelector } from 'react-redux'
import { Form, InputGroup } from 'react-bootstrap'
import translation from './translations'
import { translate } from 'src/Services/translation'
import { StoreState } from 'src/Services/Store/reducers'

interface Props {
    name?: string
    placeholder?: string
    onChange?: ChangeEventHandler<HTMLInputElement>
    type?: 'primary' | 'light'
    label?: string
}

const SearchInput: FC<Props> = ({
  name= '', placeholder, onChange,
  type = 'primary', label = ''
}) => {
  const { language } = useSelector((state: StoreState) => state.Root.user)

  const trans = translate(translation)(language)

  return (
    <Form.Group style={{ maxWidth: '300px' }}>
      <InputGroup className={`${type}`}>
        <InputGroup.Text>
          <i className="fas fa-search me-1" />
          { label || trans('search')}
        </InputGroup.Text>
        <Form.Control name={name}
                      placeholder={placeholder}
                      size="sm"
                      onChange={onChange}
        />
     </InputGroup>
    </Form.Group>
  )
}

export default SearchInput
