import React from 'react'
import { useSelector } from 'react-redux'

interface ColumnProps {
  title: string
  selectable?: boolean
  items: any[]
  onClick?: (item: any) => void,
  onEditClick: (item: any) => void,
  renderText: (item: any) => string
}

const Column = ({
                  title, selectable = false, items, onClick = () => {}, onEditClick, renderText
}: ColumnProps) => {
  // @ts-ignore
  const { selectedWorkflow } = useSelector(state => state.WorkflowManager)

  return (
    <div className="workflow-column">
      <h5 className="heading">
        { title }
      </h5>
      {items.map(item => (
        <div
          id={item.id}
          className={
            `workflow-node
            ${selectable && 'selectable'}
            ${selectedWorkflow?.trigger?.id === item.id ? 'selected' : ''}`
          }
          key={ item.id }
          onClick={() => onClick(item)}
        >
          { renderText(item) }
          <i
            className="edit fas fa-pencil fa-xs"
            onClick={e => {
              e.stopPropagation()
              onEditClick(item)
            }}
          />
        </div>
      ))}
    </div>
  )
}

export default Column
