import React from 'react'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import { formatTime } from 'src/Utils/Date'
import renderIcon from 'src/Components/RenderIcon'
import CalendarEvent, { CalendarEventType } from 'src/Components/Calendar/Types/CalendarEvent'
import { Locale } from 'src/Types/Locale'
import ListCell from '../../ListCell'

interface Props {
    event: CalendarEvent
    locale: Locale
    portalTimezone: string | null
    onEditClick: (event: CalendarEvent) => void
}

const EventComponent = ({ event, locale, portalTimezone, onEditClick }: Props) => {
    const formatTimeLocalized = formatTime(locale, portalTimezone)
    const renderTitle = (event: CalendarEvent) =>
        <>
            <strong className={ 'me-1' }>{ formatTimeLocalized(event.extendedProps.start) }</strong>
            <span className={ 'text-truncate' } dangerouslySetInnerHTML={ { __html: event.title } }/>
        </>

    const renderIcons = (event: CalendarEvent) =>
        event.extendedProps.icons.map(({ content, type }) =>
            <span className={ 'me-1' } key={ type }>
          { renderIcon({ content, type, size: 'sm' }) }
        </span>)

    const renderPopover = (event: CalendarEvent) => (
        <Popover>
            <Popover.Header style={ { backgroundColor: event.extendedProps.color, color: '#FFFFFF' } }>
                { renderTitle(event) }
            </Popover.Header>
            <Popover.Body>
                <p className={ 'mb-1' }>{ formatTimeLocalized(event.extendedProps.start) } - { formatTimeLocalized(event.extendedProps.end) }</p>
                { event.extendedProps.additionalData?.map(data =>
                    <p key={ data.id } className={ 'mb-1' }>
                        { /* ListCell will not work fine as field is not pass as prop  */ }
                        <b>{ data.label || data.systemName } :</b> { <ListCell value={ data?.value }/> }
                    </p>) }
            </Popover.Body>
        </Popover>
    )

    const renderEvent = (event: CalendarEvent) => {
        return (
            <OverlayTrigger trigger={ [ 'hover', 'focus' ] } placement={ 'top' } overlay={ renderPopover(event) }>
                { /* eslint-disable-next-line max-len */ }
                <div
                    className={ 'calendar-event bg-body ps-1 cursor-pointer h-100 w-100 position-relative rounded d-flex justify-content-between' }
                    style={ { border: `1px solid ${ event.extendedProps.color }` } }
                >
                    <div className={ 'w-100' }>
                        <p className={ 'm-0 text-truncate' }
                           style={ {
                               whiteSpace: 'normal',
                               color: '#737373',
                               fontSize: '14px'
                           } }
                        >
                            { renderTitle(event) }
                        </p>

                        <div>{ renderIcons(event) }</div>
                    </div>
                    <div onClick={ e => {
                        onEditClick(event)
                        e.stopPropagation()
                    } }
                         className={ 'p-1 edit-event background-secondary position-absolute' }
                         style={ { backgroundColor: '#EFF4FE', borderRadius: '0 4px 4px 0', maxHeight: '25px', right: 0 } }>
                        <i className={ 'icon px-1 fas fa-pen edit-event text-primary' }/>
                    </div>
                </div>
            </OverlayTrigger>
        )
    }

    const renderSlot = (slot: CalendarEvent) =>
        <OverlayTrigger trigger={ [ 'hover', 'focus' ] } placement={ 'top' } overlay={ renderPopover(slot) }>
            <div className={ `calendar-event bg-body cursor-pointer h-100 w-100 rounded
                ${ slot.extendedProps.event ? 'pb-1' : '' }` }
                 style={ { border: `1px solid ${ slot.extendedProps.color }` } }
            >
                <div>
                    <p className={ 'm-0 px-1 text-truncate' }
                       style={ {
                           whiteSpace: 'normal',
                           color: '#737373',
                           fontSize: '14px'
                       } }
                    >
                        { renderTitle(slot) }
                    </p>
                </div>
                { slot.extendedProps.event && <div className={ 'px-1 pb-2 h-75' }>
                    { renderEvent(slot.extendedProps.event) }
                </div> }
            </div>
        </OverlayTrigger>


    return (event.extendedProps.type === CalendarEventType.EVENT ? renderEvent : renderSlot)(event)
}

export default EventComponent

