const serviceUrl = 'https://ej2services.syncfusion.com/production/web-services/api/documenteditor/'

const traductions = {
  "fr": {
    "documenteditorcontainer": {
      "New": "Nouveau",
      "Open": "Ouvert",
      "Undo": "annuler",
      "Redo": "Refaire",
      "Image": "Image",
      "Table": "Table",
      "Link": "Lien",
      "Bookmark": "Signet",
      "Table of Contents": "Table des matières",
      "HEADING - - - - 1": "RUBRIQUE - - - - 1",
      "HEADING - - - - 2": "RUBRIQUE - - - - 2",
      "HEADING - - - - 3": "RUBRIQUE - - - - 3",
      "Header": "Entête",
      "Footer": "Bas de page",
      "Page Setup": "Mise en page",
      "Page Number": "Numéro de page",
      "Break": "Pause",
      "Find": "Trouver",
      "Local Clipboard": "Presse-papiers local",
      "Restrict Editing": "Restreindre l'édition",
      "Upload from computer": "Télécharger d'un ordinateur",
      "By URL": "Par URL",
      "Page Break": "Saut de page",
      "Section Break": "Saut de section",
      "Header And Footer": "En-tête et pied de page",
      "Options": "Les options",
      "Levels": "Les niveaux",
      "Different First Page": "Première page différente",
      "Different header and footer for odd and even pages": "En-tête et pied de page différents pour les pages paires et impaires.",
      "Different Odd And Even Pages": "Pages paires et impaires différentes",
      "Different header and footer for first page": "En-tête et pied de page différents pour la première page.",
      "Position": "Position",
      "Header from Top": "En-tête du haut",
      "Footer from Bottom": "Pied de page du bas",
      "Distance from top of the page to top of the header": "Distance du haut de la page au haut de l'en-tête.",
      "Distance from bottom of the page to bottom of the footer": "Distance du bas de la page au bas du pied de page.",
      "Aspect ratio": "Ratio d'aspect",
      "W": "W",
      "H": "H",
      "Width": "Largeur",
      "Height": "la taille",
      "Text": "Texte",
      "Paragraph": "Paragraphe",
      "Fill": "Remplir",
      "Fill color": "La couleur de remplissage",
      "Border Style": "Style de bordure",
      "Outside borders": "Hors frontières",
      "All borders": "Toutes les frontières",
      "Inside borders": "Frontières intérieures",
      "Left border": "Bordure gauche",
      "Inside vertical border": "Bordure verticale intérieure",
      "Right border": "Bordure droite",
      "Top border": "Bordure supérieure",
      "Inside horizontal border": "Bordure horizontale intérieure",
      "Bottom border": "Bordure inférieure",
      "Border color": "Couleur de la bordure",
      "Border width": "Largeur de la bordure",
      "Cell": "Cellule",
      "Merge cells": "Fusionner des cellules",
      "Insert Or Delete": "Insérer / supprimer",
      "Insert columns to the left": "Insérer des colonnes à gauche",
      "Insert columns to the right": "Insérer des colonnes à droite",
      "Insert rows above": "Insérer des lignes au-dessus",
      "Insert rows below": "Insérer des lignes ci-dessous",
      "Delete rows": "Supprimer des lignes",
      "Delete columns": "Supprimer des colonnes",
      "Cell Margin": "Marge cellulaire",
      "Top": "Haut",
      "Bottom": "Bas",
      "Left": "La gauche",
      "Right": "Droite",
      "Align Text": "Aligner le texte",
      "Align top": "Aligner le haut",
      "Align bottom": "Aligner en bas",
      "Align center": "Aligner le centre",
      "Number of heading or outline levels to be shown in table of contents": "Nombre de niveaux de titre ou de plan à afficher dans la table des matières.",
      "Show page numbers": "Afficher les numéros de page",
      "Show page numbers in table of contents": "Afficher les numéros de page dans la table des matières.",
      "Right align page numbers": "Aligner à droite les numéros de page",
      "Right align page numbers in table of contents": "Alignez à droite les numéros de page dans la table des matières.",
      "Use hyperlinks": "Utilisez des hyperliens",
      "Use hyperlinks instead of page numbers": "Utilisez des hyperliens au lieu des numéros de page.",
      "Font": "Police de caractère",
      "Font Size": "Taille de police",
      "Font color": "Couleur de la police",
      "Text highlight color": "Couleur de surbrillance du texte",
      "Clear all formatting": "Effacer toute mise en forme",
      "Bold Tooltip": "Gras (Ctrl + B)",
      "Italic Tooltip": "Italique (Ctrl + I)",
      "Underline Tooltip": "Souligné (Ctrl + U)",
      "Strikethrough": "Barré",
      "Superscript Tooltip": "Exposant (Ctrl + Shift ++)",
      "Subscript Tooltip": "Indice (Ctrl + =)",
      "Align left Tooltip": "Aligner à gauche (Ctrl + L)",
      "Center Tooltip": "Centre (Ctrl + E)",
      "Align right Tooltip": "Aligner à droite (Ctrl + R)",
      "Justify Tooltip": "Justifier (Ctrl + J)",
      "Decrease indent": "Diminuer le retrait",
      "Increase indent": "Augmenter le retrait",
      "Line spacing": "Interligne",
      "Bullets": "Balles",
      "Numbering": "Numérotage",
      "Styles": "modes",
      "Manage Styles": "Gérer les styles",
      "Page": "Page",
      "of": "de",
      "Fit one page": "Ajuster une page",
      "Spell Check": "Vérification orthographique",
      "Underline errors": "Souligner les erreurs",
      "Fit page width": "Ajuster la largeur de la page",
      "Update": "Mise à jour",
      "Cancel": "Annuler",
      "Insert": "Insérer",
      "No Border": "Pas de frontière",
      "Create a new document": "Créez un nouveau document.",
      "Open a document": "Ouvrez un document.",
      "Undo Tooltip": "Annule la dernière opération (Ctrl + Z).",
      "Redo Tooltip": "Refait la dernière opération (Ctrl + Y).",
      "Insert inline picture from a file": "Insérez une image en ligne à partir d'un fichier.",
      "Insert a table into the document": "Insérer un tableau dans le document",
      "Create Hyperlink": "Créez un lien dans votre document pour un accès rapide aux pages Web et aux fichiers (Ctrl + K).",
      "Insert a bookmark in a specific place in this document": "Insérez un signet à un endroit spécifique de ce document.",
      "Provide an overview of your document by adding a table of contents": "Donnez un aperçu de votre document en ajoutant une table des matières.",
      "Add or edit the header": "Ajoutez ou modifiez l'en-tête.",
      "Add or edit the footer": "Ajoutez ou modifiez le pied de page.",
      "Open the page setup dialog": "Ouvrez la boîte de dialogue de mise en page.",
      "Add page numbers": "Ajoutez des numéros de page.",
      "Find Text": "Recherchez du texte dans le document (Ctrl + F).",
      "Toggle between the internal clipboard and system clipboard": "Basculez entre le presse-papiers interne et le presse-papiers système. </br> L'accès au presse-papiers système via un script est refusé en raison de la politique de sécurité des navigateurs. Au lieu de cela, </br> 1. Vous pouvez activer le presse-papiers interne pour couper, copier et coller dans le composant. </br> 2. Vous pouvez utiliser les raccourcis clavier (Ctrl + X, Ctrl + C et Ctrl + V) pour couper , copiez et collez avec le presse-papiers du système.",
      "Current Page Number": "Numéro de page actuel dans le document. Cliquez ou appuyez sur pour parcourir une page spécifique.",
      "Read only": "Lecture seulement",
      "Protections": "Protections",
      "Error in establishing connection with web server": "Erreur lors de l'établissement de la connexion avec le serveur Web",
      "Single": "Célibataire",
      "Double": "Double",
      "New comment": "Nouveau commentaire",
      "Comments": "commentaires",
      "Print layout": "Disposition d'impression",
      "Web layout": "mise en page Web",
      "Text Form": "Formulaire texte",
      "Check Box": "Case à cocher",
      "DropDown": "Menu déroulant",
      "Update Fields": "Champs de mise à jour",
      "Update cross reference fields": "Mettre à jour les champs de référence croisée",
      "Hide properties pane": "Masquer le volet des propriétés",
      "Show properties pane": "Afficher le volet des propriétés",
      "Form Fields": "Champs de formulaire",
      "Track Changes": "Gardez une trace des modifications apportées au document",
      "TrackChanges": "Suivi des modifications",
      "AllCaps": "Toutes en majuscules",
      "Change case Tooltip": "Changer de cas",
      "Insert Footnote": "Insérer une note de bas de page",
      "Insert Endnote": "Insérer une note de fin",
      "Footnote Tooltip": "Insérer une note de bas de page (Alt + Ctrl + F).",
      "Endnote Tooltip": "Insérer une note de fin (Alt + Ctrl + D).",
      "UPPERCASE": "MAJUSCULE",
      "No color": "Sans couleur",
      "Top margin": "Marge supérieure",
      "Bottom margin": "Marge inférieure",
      "Left margin": "Marge de gauche",
      "Right margin": "Marge droite",
      "Normal": "Normal",
      "Heading": "Titre",
      "Heading 1": "Rubrique 1",
      "Heading 2": "Rubrique 2",
      "Heading 3": "Rubrique 3",
      "Heading 4": "Rubrique 4",
      "Heading 5": "Rubrique 5",
      "Heading 6": "Rubrique 6",
      "ZoomLevelTooltip": "Le niveau de zoom. Cliquez ou appuyez sur pour ouvrir les options de zoom.",
      "None": "Aucun",
      "Borders": "Les frontières"
    }
  }
}

export default {
  component: {
    serviceUrl
  },
  traductions
}
