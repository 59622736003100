import {
  SET_FORM_SYSTEM_FIELDS,
  TOGGLE_FORM_MODAL,
  REMOVE_DELETED_SYSTEM_FIELD,
  ADD_NEW_SYSTEM_FIELD,
  SYSTEM_FIELD_LIMIT_CHANGE,
  SYSTEM_FIELD_PAGE_CHANGE, SET_FORM_ID, TOGGLE_LOADING
} from './actionTypes'

const initialState = {
  formId: null,
  loading: false,
  formFieldsList: [],
  isFormModalOpen: false,
  pagination: {
    offset: 0,
    limit: 50,
    itemsCount: 50,
    totalItemsCount: 50,
    pageLimit: 50,
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPagesCount: 1,
  },
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_FORM_ID:
      return {
        ...state,
        formId: payload
      }
    case TOGGLE_LOADING:
      return {
        ...state,
        loading: payload
      }
    case SET_FORM_SYSTEM_FIELDS:
      return {
        ...state,
        loading: false,
        formFieldsList: payload.data,
        pagination: payload.pagination
      }

    case SYSTEM_FIELD_LIMIT_CHANGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          pageLimit: payload,
          currentPage: 1
        }
      }

    case SYSTEM_FIELD_PAGE_CHANGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: payload
        }
      }

    case ADD_NEW_SYSTEM_FIELD: {
      return { ...state, formFieldsList: [ payload, ...state.formFieldsList ] }
    }

    case REMOVE_DELETED_SYSTEM_FIELD:
      const deletedIndex = state.formFieldsList.findIndex(el => el.id === payload)

      if (deletedIndex >= 0) {
        const newList = [ ...state.formFieldsList ]
        newList.splice(deletedIndex, 1)

        return { ...state, formFieldsList: newList }
      }
      return { ...state }

    case TOGGLE_FORM_MODAL:
      return {
        ...state,
        isFormModalOpen: !state.isFormModalOpen
      }

    default:
      return state
  }
}
