import { getFieldsFromForm } from '../../Utils/FormUtils'
import { PermissionSubject } from 'src/Views/Permissions/Types/Subject'

export const getFormPermissions = state => state.newSubjectData?.[PermissionSubject.FORM] || []
export const getSectionPermissions = state => state.newSubjectData?.[PermissionSubject.SECTION] || []
export const getFieldPermissions = state => state.newSubjectData?.[PermissionSubject.FIELD] || []
export const addPermission = (state, subject, permission) => {
  const { subjectData, newSubjectData } = state

  const existingPermission = subjectData[subject].find(p => p.id && p[subject].id === permission[subject].id)
  const newPermission = existingPermission ? { ...permission, id: existingPermission.id } : permission

  return [ ...newSubjectData[subject], newPermission ]
}
export const removeFormPermissions = (state, permission) => {
  const newSubjectData = {
    [PermissionSubject.FORM]: getFormPermissions(state).filter(p => p.id !== permission.id)
  }

  // Then remove orphans
  const form = state.forms.find(f => f.id === permission.form.id)

  newSubjectData[PermissionSubject.SECTION] =
    getSectionPermissions(state).filter(p => !form.sections.some(s => s.id === p.section.id))

  newSubjectData[PermissionSubject.FIELD] =
    getFieldPermissions(state).filter(p => !getFieldsFromForm(form).some(f => f.id === p.field.id))

  return newSubjectData
}
