import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Container } from 'react-bootstrap'
import { useActions } from 'src/Components/Util'
import { setOptions } from 'src/Layouts/View/state/actions'
import { load, toggleAddingList } from './state/actions'
import AddListModal from './Components/AddListModal'
import ZmrListTable from './Components/ZmrListTable'

const Lists = () => {
  useActions([ load() ])([])([])

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setOptions({
      hasPrimaryButton: true,
      onPrimaryButtonClick: () => dispatch(toggleAddingList(true))
    }))
  }, [ dispatch ])

  return <Container>
    <ZmrListTable />
    <AddListModal />
  </Container>
}

export default Lists
