import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { translate, translateConf } from 'src/Services/translation'
import translation from '../translations'
import { fetchRecentListsAction } from '../state/actions'

const RecentLists = () => {
  const dispatch = useDispatch()

  const { language } = useSelector(state => state.Root.user)
  const { configTranslations } = useSelector(state => state.Root)
  const {
    recentLists,
    recentListsFetchError
  } = useSelector(state => state.Homepage)

  useEffect(() => {
    dispatch(fetchRecentListsAction())
  }, [ dispatch ])

  const trans = translate(translation)(language)
  const transConf = translateConf(configTranslations)

  return recentListsFetchError
    ? trans('recentListsFetchError')
    : <ul>
      { recentLists.map((list, idx) =>
        <li key={ `${ list.id }_${ idx }` }>
          <Link to={ `/list/${ list.id }/run` }>{ transConf('LIST')(list) }</Link>
        </li>
      ) }
    </ul>
}

export default RecentLists
