import {
  FETCH_WORKFLOW_MANAGER,
  WORKFLOW_MANAGER_RECEIVED,
  SET_ACTION_FILTER,
  SET_FORM_FILTER,
  SET_TYPE_FILTER,
  SET_ROLE_FILTER,
  SELECT_WORKFLOW, RESET_FILTERS
} from './actionTypes'
import { ACTION_CREATED, ACTION_DELETED, ACTION_UPDATED } from '../Modals/Actions/state/actionTypes'
import { WORKFLOW_CREATED, WORKFLOW_DELETED, WORKFLOW_UPDATED } from '../Modals/Workflows/state/actionTypes'
import { TRIGGER_CREATED, TRIGGER_DELETED, TRIGGER_UPDATED } from '../Modals/Triggers/state/actionTypes'
import { Option } from 'src/Types/Components/Select'
import translations from '../Filters/translations'

export interface WorkflowManagerState {
  isLoading: boolean
  actionFilter: Option | null
  formFilter: Option | null
  roleFilter: Option | null
  typeFilter: Option | null
  isFilterApplied: boolean
  originalActions: any[]
  originalWorkflows: any[]
  originalTriggers: any[]
  filteredActions: any[]
  filteredWorkflows: any[]
  filteredTriggers: any[]
  selectedWorkflow: any | null,
}

const initialState: WorkflowManagerState = {
  isLoading: false,
  actionFilter: null,
  formFilter: null,
  roleFilter: null,
  typeFilter: null,
  isFilterApplied: false,
  originalActions: [],
  originalWorkflows: [],
  originalTriggers: [],
  filteredActions: [],
  filteredWorkflows: [],
  filteredTriggers: [],
  selectedWorkflow: null
}

export default (
  state = initialState,
  { type, payload }: { type: string, payload: any }
): any => {
  switch (type) {
    case FETCH_WORKFLOW_MANAGER:
      return {
        ...state,
        isLoading: true
      }
    case WORKFLOW_MANAGER_RECEIVED:
      return {
        ...state,
        isLoading: false,
        originalActions: payload.actions,
        originalWorkflows: payload.workflows,
        originalTriggers: payload.triggers
      }
    case SET_ACTION_FILTER: {
      const filteredActions = state.originalActions.filter((action: any) => action?.type === payload.value)

      const filteredWorkflows = state.originalWorkflows.filter((workflow: any) =>
        filteredActions.find((action: any) => action.id === workflow.action.id))

      const filteredTriggers = state.originalTriggers.filter((trigger: any) =>
        filteredWorkflows.find((workflow: any) => trigger.id === workflow.trigger.id))


      return {
        ...state,
        actionFilter: payload,
        roleFilter: null,
        formFilter: null,
        typeFilter: null,
        isFilterApplied: true,
        selectedWorkflow: null,
        filteredActions,
        filteredWorkflows,
        filteredTriggers
      }
    }
    case SET_FORM_FILTER: {
      const filteredTriggers = state.originalTriggers.filter((trigger: any) =>
        trigger?.form?.systemName === payload.value)

      const filteredWorkflows = state.originalWorkflows.filter((workflow: any) =>
        filteredTriggers.find((trigger: any) => trigger.id === workflow.trigger.id))

      const filteredActions = state.originalActions.filter((action: any) =>
        filteredWorkflows.find((workflow: any) => action.id === workflow.action.id))

      return {
        ...state,
        actionFilter: null,
        roleFilter: null,
        formFilter: payload,
        typeFilter: null,
        isFilterApplied: true,
        selectedWorkflow: null,
        filteredActions,
        filteredWorkflows,
        filteredTriggers
      }
    }
    case SET_ROLE_FILTER: {
      const filteredWorkflows = state.originalWorkflows.filter((workflow: any) =>
        workflow?.role?.systemName === payload.value)

      const filteredActions = state.originalActions.filter((action: any) =>
        filteredWorkflows.find((workflow: any) => action.id === workflow.action.id))

      const filteredTriggers = state.originalTriggers.filter((trigger: any) =>
        filteredWorkflows.find((workflow: any) => trigger.id === workflow.trigger.id))

      return {
        ...state,
        actionFilter: null,
        formFilter: null,
        roleFilter: payload,
        typeFilter: null,
        isFilterApplied: true,
        selectedWorkflow: null,
        filteredActions,
        filteredWorkflows,
        filteredTriggers
      }
    }
    case SET_TYPE_FILTER: {
      const label = payload.label
      const value = payload.value

      const isFieldEvent = value === 'fieldEvent'
      const isPlainFormEvent = value === 'formEvent'
      const isPlainEventStatus= value === 'fieldEvent'
      const isFormEvent = label.startsWith(translations.english.plainFormEvent) && value
      const isEventStatus= label.startsWith(translations.english.plainEventStatus) && value

      let filteredTriggers :any[] = []

      if (isFieldEvent) {
        filteredTriggers = state.originalTriggers.filter(trigger =>
          trigger.type === 'FIELD_VALUE_CHANGE'
        )
      }
      else if (isPlainFormEvent) {
        filteredTriggers = state.originalTriggers.filter(trigger =>
          trigger.type === 'FORM_EVENT'
        )
      }
      else if (isPlainEventStatus) {
        filteredTriggers = state.originalTriggers.filter(trigger =>
          trigger.type === 'EVENT_STATUS_CHANGE'
        )
      }
      else if (isFormEvent) {
        filteredTriggers = state.originalTriggers.filter(
          trigger => trigger.type === 'FORM_EVENT' && trigger.formEvent === value
        )
      }
      else if (isEventStatus) {
        filteredTriggers = state.originalTriggers.filter(trigger =>
          trigger.type === 'EVENT_STATUS_CHANGE' &&
            (trigger.oldValue === value || trigger.newValue === value)
        )
      }

      const filteredWorkflows = state.originalWorkflows.filter((workflow: any) =>
        filteredTriggers.find((trigger: any) => trigger.id === workflow.trigger.id))

      const filteredActions = state.originalActions.filter((action: any) =>
        filteredWorkflows.find((workflow: any) => action.id === workflow.action.id))

      return {
        ...state,
        actionFilter: null,
        formFilter: null,
        roleFilter: null,
        typeFilter: payload,
        isFilterApplied: true,
        selectedWorkflow: null,
        filteredActions,
        filteredWorkflows,
        filteredTriggers
      }
    }
    case RESET_FILTERS: {
      return {
        ...state,
        actionFilter: null,
        formFilter: null,
        roleFilter: null,
        typeFilter: null,
        isFilterApplied: false,
        selectedWorkflow: null,
        filteredActions: [],
        filteredWorkflows: [],
        filteredTriggers: []
      }
    }
    case SELECT_WORKFLOW:
      return {
        ...state,
        selectedWorkflow: payload
      }

    // ACTIONS
    case ACTION_CREATED:
      return {
        ...initialState,
        originalWorkflows: state.originalWorkflows,
        originalTriggers: state.originalTriggers,
        originalActions: [ ...state.originalActions, payload ]
      }
    case ACTION_UPDATED:
      return {
        ...initialState,
        originalWorkflows: state.originalWorkflows,
        originalTriggers: state.originalTriggers,
        originalActions: state.originalActions.map(_ => _.id === payload.id ? payload : _)
      }
    case ACTION_DELETED:
      return {
        ...initialState,
        originalWorkflows: state.originalWorkflows,
        originalTriggers: state.originalTriggers,
        originalActions: state.originalActions.filter(_ => _.id !== payload)
      }

    // WORKFLOWS
    case WORKFLOW_CREATED:
      return {
        ...initialState,
        originalActions: state.originalActions,
        originalTriggers: state.originalTriggers,
        originalWorkflows: [ ...state.originalWorkflows, payload ]
      }
    case WORKFLOW_UPDATED:
      return {
        ...initialState,
        originalActions: state.originalActions,
        originalTriggers: state.originalTriggers,
        originalWorkflows: state.originalWorkflows.map(_ => _.id === payload.id ? payload : _)
      }
    case WORKFLOW_DELETED:
      return {
        ...initialState,
        originalActions: state.originalActions,
        originalTriggers: state.originalTriggers,
        originalWorkflows: state.originalWorkflows.filter(_ => _.id !== payload)
      }

    // TRIGGERS
    case TRIGGER_CREATED:
      return {
        ...initialState,
        originalActions: state.originalActions,
        originalWorkflows: state.originalWorkflows,
        originalTriggers: [ ...state.originalTriggers, payload ]
      }
    case TRIGGER_UPDATED:
      return {
        ...initialState,
        originalActions: state.originalActions,
        originalWorkflows: state.originalWorkflows,
        originalTriggers: state.originalTriggers.map(_ => _.id === payload.id ? payload : _)
      }
    case TRIGGER_DELETED:
      return {
        ...initialState,
        originalActions: state.originalActions,
        originalWorkflows: state.originalWorkflows,
        originalTriggers: state.originalTriggers.filter(_ => _.id !== payload)
      }
    default:
      return state
    }
}
