import { call, put, select, takeLatest } from 'redux-saga/effects'
import { CREATE_WORKFLOW, DELETE_WORKFLOW, UPDATE_WORKFLOW } from './actionTypes'
import { itemUpdated, itemDeleted, itemCreated } from './actions'
import apiMethods from 'src/Services/api/apiMethods'

const STORE = 'WorkflowItemModals'

function* updateItem(props, { payload }) {
  try {
    const item = (yield select())[STORE].updatingItem
    const { data } = yield call(apiMethods.update, `/workflow/items/${item.id}`, { data: payload })
    yield put(itemUpdated(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'updateFailed'))
  }
}

function* deleteItem(props) {
  try {
    const { id } = (yield select())[STORE].deletingItem
    yield call(apiMethods.delete, `/workflow/items/${id}`)
    yield put(itemDeleted(id))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'deleteFailed'))
  }
}

function* createItem(props, { payload }) {
  try {
    const { data } = yield call(apiMethods.create, '/workflow/items', { data: payload })
    yield put(itemCreated(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'createFailed'))
  }
}

export default function* workflowItemsSagaWatcher(props) {
  yield takeLatest(CREATE_WORKFLOW, createItem, props)
  yield takeLatest(DELETE_WORKFLOW, deleteItem, props)
  yield takeLatest(UPDATE_WORKFLOW, updateItem, props)
}
