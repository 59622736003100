import React, { useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Row, Col, Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import PinInput from '../../Components/PinInput'
import { translate } from 'src/Services/translation'
import translation from './translations'

const ValidateUserIdentityModal = ({
  isOpen = false,
  onClose,
  onSubmit
}) => {
  const { language } = useSelector(state => state.Root.user)

  const trans = translate(translation)(language)

  const [ pin, setPin ] = useState(null)

  const onPinInputChange = val => setPin(val)

  const onSubmitClick = () => {
    onSubmit(pin)
    onClose()
  }

  return <Modal isOpen={ isOpen }
                onClosed={ onClose }
                size={ 'lg' }
                id={ 'validateUserIdentityModal' }
                toggle={ onClose }
  >
    <ModalHeader toggle={ onClose }>
      { trans('title') }
    </ModalHeader>
    <ModalBody>
      <Row className={ 'mt-2 text-center' }>
        <Col>
          <span>{ trans('pleaseEnterPin') }</span>
        </Col>
      </Row>
      <Row className={ 'mb-2' }>
        <Col>
          <PinInput onChange={ onPinInputChange }/>
        </Col>
      </Row>
      <Row className={ 'mb-2 text-center' }>
        <Col>
          <small className={ 'text-muted' }>{ trans('details') }</small>
        </Col>
      </Row>
    </ModalBody>
    <ModalFooter>
      <Button onClick={ onSubmitClick }
              title={ trans('submit') }
              variant={ 'primary' }
              className={ 'float-end' }
              disabled={ !pin }
      >
        { trans('submit') }
      </Button>
    </ModalFooter>
  </Modal>
}

export default ValidateUserIdentityModal
