import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-bootstrap'
import { translate } from 'src/Services/translation'
import { SaveStatus } from 'src/Components/SaveButton'
import { ListType } from 'src/Types/List'
import translation from '../translations'
import { setDescription, setType, setShowAddButtonOption, setSaveStatus, setLabel } from '../state/actions'

const ZmrEditInfos = () => {
  const dispatch = useDispatch()

  const { language } = useSelector(state => state.Root.user)
  const { listTypes } = useSelector(state => state.Dictionary)
  const { label, description, type, showAddButton } = useSelector(state => state.ListEdit)

  const trans = translate(translation)(language)

  const onLabelChange = e => {
    dispatch(setLabel(e.target.value))
    dispatch(setSaveStatus(SaveStatus.VALID))
  }

  const onShowAddButtonChange = e => {
    dispatch(setShowAddButtonOption(e.target.checked))
    dispatch(setSaveStatus(SaveStatus.VALID))
  }

  const onDescriptionChange = e => {
    dispatch(setDescription(e.target.value))
    dispatch(setSaveStatus(SaveStatus.VALID))
  }

  const onTypeChange = e => {
    dispatch(setType(e.target.value))
    dispatch(setSaveStatus(SaveStatus.VALID))
  }

  const getTypes = () => listTypes.filter(lt => ![
    ListType.REFERENCE, ListType.HOMEPAGE, ListType.SYSTEM
  ].includes(lt))

  return <Form>
    <Form.Group className={ 'mb-2' }>
      <Form.Label>{ trans('label') }</Form.Label>
      <Form.Control name={ 'label' } required
                    defaultValue={ label }
                    onChange={ onLabelChange }
      />
    </Form.Group>

    <Form.Group className={ 'mb-2' }>
      <Form.Label>{ trans('description') }</Form.Label>
      <Form.Control as={ 'textarea' }
                    value={ description }
                    onChange={ onDescriptionChange }
                    maxLength={ '65535' }
      />
    </Form.Group>

    <Form.Group className={ 'mb-2' }>
      <Form.Label>{ trans('listType') }</Form.Label>
      <Form.Select value={ type }
                   onChange={ onTypeChange }
      >
        { getTypes().map((type, i) => <option key={ i } value={ type }>{ type }</option>) }
      </Form.Select>
    </Form.Group>

    <Form.Group>
      <Form.Label>{ trans('options') }</Form.Label>
      <Form.Check type={ 'checkbox' }
                  label={ "Show 'Add' button on List" }
                  checked={ showAddButton }
                  onChange={ onShowAddButtonChange }
      />
    </Form.Group>
  </Form>
}

export default ZmrEditInfos
