import HttpClient from './httpClient'

class APIMethods {

  request(method, url, data = {}, params = {}, extraConfig = {}) {

    const config = Object.keys(extraConfig).length ? extraConfig : {}

    config.method = method
    config.url = url

    // Impersonated user
    const impersonatedUser = localStorage.getItem('impersonatedUser')
    if (impersonatedUser)
      params._switch_user = JSON.parse(impersonatedUser)?.username

    if (Object.keys(params).length)
      config.params = params

    if (Object.keys(data).length)
      config.data = data

    return HttpClient.request(config)
  }

  get = (resource, params = {}, config = {}) => {
    config = { ...config, headers: { 'Content-Type': 'text/plain', ...config.headers } }
    return this.request('get', resource, {}, params, config)
  }

  create = (resource, { data, ...params } = {}, config = {}) => {
    config = { ...config, headers: { 'Content-Type': 'application/json', ...config.headers } }
    return this.request('post', resource, data, params, config)
  }

  update = (resource, { data, ...params } = {}, config = {}) => {
    config = { ...config, headers: { 'Content-Type': 'application/json', ...config.headers } }
    return this.request('patch', resource, data, params, config)
  }

  delete = (resource, { data, ...params } = {}, config = {}) => {
    config = { ...config, headers: { 'Content-Type': 'text/plain', ...config.headers } }
    return this.request('delete', resource, data, params, config)
  }

  sendFiles = (resource, { data = {}, files = [], ...params } = {}, config = {}) => {
    const formData = new FormData()

    for (const file of files)
      formData.append(file.name, file)

    for (const key in data)
      formData.append(key, data[key])

    return this.request('post', resource, {}, params, {
      ...config,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data', ...config.headers }
    })
  }

  downloadBlobFile = async (method, resource, { data, ...params } = {}, config = {}, fileName = null) => {
    config = {
      ...config,
      responseType: 'blob'
    }

    const res = await this.request(method, resource, data, params, config)

    // Create a link and auto-click on it to trigger the download
    const url = URL.createObjectURL(res.data)
    const link = document.createElement('a')

    link.href = url

    if (fileName)
      link.download = fileName
    else if (res.headers['content-disposition']?.includes('filename=')) {
      link.download = res.headers['content-disposition'].split('filename=')[1]?.replace(/^"|"$/g, '')
    }

    document.body.append(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  }
  downloadFileFromUrl = async (method, resource, { data, ...params } = {}, config = {}) => {
    const res = await this.request(method, resource, data, params, config)
    const link = document.createElement('a')

    const { fileUrl } = res?.data
    if (fileUrl) {
      link.href = fileUrl
      const urlParts = fileUrl.split('/')
      link.download = urlParts[urlParts.length - 1]
      link.setAttribute('target', '_blank')

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

export default new APIMethods()
