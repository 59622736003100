import React, { ChangeEvent } from 'react'
import { FormCheck } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { FieldEvent } from 'src/Views/FormFiller/Types/Field'
import { Field } from 'src/Types/Field'
import { FieldOptionValue } from 'src/Types/FieldType/OptionsField'
import { translate } from 'src/Services/translation'
import translations from 'src/Views/FormFiller/translations'
import { StoreState } from 'src/Services/Store/reducers'
import { fieldEvent } from '../../state/actions'

export interface Props {
  field: Field
  isDisabled: boolean
  isRequired: boolean
}

const RadioField = ({ field, isDisabled = false, isRequired = false }: Props) => {

  const dispatch = useDispatch()

  const { language } = useSelector((state: StoreState) => state.Root.user)

  const trans = translate(translations)(language)

  const getValue = () => (field.value as FieldOptionValue)?.systemName || field.value

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(fieldEvent(field, FieldEvent.VALUE_CHANGE, { value: e.target.value }))
  }

  if (!field.options.values)
    return <div key={ `field${ field.id }` }>
        <span className={ 'text-danger text-bold' }>{ trans('invalidField') }</span>
    </div>

  const getOptions = () =>
      field.options.sortAlpha === true
      ? field.options.values.sort((a: FieldOptionValue, b: FieldOptionValue) => a.label > b.label ? 1 : -1)
      : field.options.values

  return <div key={ `field${ field.id }` }>
    { getOptions().map((option: FieldOptionValue, i: number) =>
      <FormCheck inline={ !field.options.alignOptionsVertically }
                 id={ `field${ field.id }.${ i }` }
                 key={ `${ field.id }.${ i }` }
                 type={ 'radio' }
                 name={ `field${ field.id }` }
                 label={ option.label }
                 value={ option.systemName }
                 checked={ getValue() === option.systemName }
                 onChange={ onChange }
                 disabled={ isDisabled }
                 required={ isRequired }
      />
    ) }
  </div>
}

export default RadioField
