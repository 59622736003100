export default {
  english: {
    logAsPatient: 'Log as',
    createPatientAccountButton: 'Create account',
    openPatientAccountButton: 'Open account',
    goToPatientAccountButton: 'Hide details',
    hideDetails: 'Hide details',
    showDetails: 'Show details',
    form: 'Form',
    patientDetails: 'Patient details',
    detailedOverview: 'Patient encounters',
    formList: 'Form List',
    createInstance: 'Add',
    backToForms: 'Back to Forms',
    nbSections: 'Number of sections',
    calendar: 'Patient calendar',
    sections: 'Sections',
    lastUpdatedAt: 'Last update at',
    selectAForm: 'Please select a form to display.',
    fetchDetailsFailed: 'We cant reach this resource for now.',
    fetchInstanceError: 'We cant reach this form for now.',
    noFormToInstantiate: 'No form to instantiate.',
    instanceForbidden: 'You do not have access to this instance',
    patientEncounters: 'Patient Encounters',
    recordInfo: 'Record Info',
    appointments: 'Appointments',
    noLabelFound: 'No label found',
    findAppointment: 'Find Appointment',
    noEventTypeAvailable: 'No event type available',
  },
  french: {
    logAsPatient: 'Se connecter en tant que',
    createPatientAccountButton: 'Créer un compte',
    openPatientAccountButton: 'Ouvrir le compte',
    createInstance: 'Ajouter',
    hideDetails: 'Cacher les détails',
    showDetails: 'Afficher les détails',
    form: 'Formulaire',
    patientDetails: 'Détails du patient',
    detailedOverview: 'Vue d\'ensemble',
    formList: 'Formulaires',
    backToForms: 'Retour aux formulaires',
    sections: 'Sections',
    calendar: 'Calendrier patient',
    lastUpdatedAt: 'Dernière mise à jour le',
    selectAForm: 'Sélectionnez un formulaire à afficher.',
    fetchDetailsFailed: 'Nous n\'arrivons pas à accéder à cette ressource pour l\'instant.',
    fetchInstanceError: 'Nous n\'arrivons pas à accéder à ce formulaire pour l\'instant.',
    instanceForbidden: 'Vous n\'avez pas accès à cette ressource',
    noFormToInstantiate: 'Pas de formulaire à instancier.',
    patientEncounters: 'Rencontres avec le patient',
    recordInfo: 'Informations de l\'enregistement',
    appointments: 'Rendez-vous',
    noLabelFound: 'Pas de label trouvé',
    findAppointment: 'Trouver un rendez-vous',
    noEventTypeAvailable: 'Pas de type d\'évènement disponible',
  }
}
