export const isAdmin = roles =>
  Array.isArray(roles) && (-1 !== roles.indexOf('ROLE_ADMIN') || -1 !== roles.indexOf('ADMIN'))

const censorField = value => {
  if (typeof value === 'string')
    return value.replace(/./g, '*')

  return '**CENSORED**'
}

const censorItem = (key, value, sensitiveFields = []) => {
  if (value === null)
    return value
  else if (Array.isArray(value))
    return value.map(v => censorItem(key, v, sensitiveFields))
  else if (typeof value === 'object') {
    return Object.keys(value).reduce((res, key) => {
      res[key] = censorItem(key, value[key], sensitiveFields)
      return res
    }, {})
  }
  else if (key.startsWith('field_')) {
    const fieldId = key.split('field_')[1]

    return sensitiveFields.some(sf => sf.id == fieldId)
      ? censorField(value)
      : value
  } else
    return value
}

export const censorData = (data, sensitiveFields = []) => censorItem('', data, sensitiveFields)
