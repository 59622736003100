export default {
  english: {
    title: 'Action',
    addNew: 'Add',
    entries: 'of {total} entries',
    form: {
      name: 'Name',
      label: 'Label',
      type: 'Type',
      code: 'Code',
      field: 'Field',
      fieldValue: 'Field value',
      messageTemplate: 'Message template',
      attachmentName: 'Attachment name',
      defaultTemplatePlaceholder: 'Default Template'
    },
    column: {
      name: 'Name',
      label: 'Label',
      type: 'Type',
      code: 'Code',
      field: 'Field',
      fieldValue: 'Field value',
      messageTemplate: 'Message template',
      attachmentName: 'Attachment name',
    },
    modal: {
      create: {
        title: 'Create a new action',
      },
      update: {
        title: 'Update an action'
      },
      confirmDelete: {
        title: 'Are you sure you want to delete this action ?'
      }
    },
    fieldTooltipTitle: 'Available Variables :',
    fieldTooltipItem1: '###date.now### (current date)',
    fieldTooltipItem2: "###currentUser.username### (connected user's username)",
    fieldTooltipItem3: "###currentUser.email### (connected user's email("
  },
  french: {
    title: 'Actions',
    addNew: 'Créer',
    entries: 'sur {total} entrées',
    form: {
      name: 'Nom',
      label: 'Label',
      type: 'Type',
      code: 'Code',
      field: 'Champ',
      fieldValue: 'Valeur du champ',
      messageTemplate: 'Template de message',
      attachmentName: 'Nom de pièce jointe',
      defaultTemplatePlaceholder: 'Template par défaut'
    },
    column: {
      name: 'Nom',
      label: 'Label',
      type: 'Type',
      code: 'Code',
      field: 'Champ',
      fieldValue: 'Valeur du champ',
      messageTemplate: 'Template de message',
      attachmentName: 'Nom de pièce jointe',
    },
    modal: {
      create: {
        title: 'Créer une nouvelle action'
      },
      update: {
        title: 'Modifier une action'
      },
      confirmDelete: {
        title: 'Êtes-vous sûr de vouloir supprimer cette action ?'
      }
    },
    fieldTooltipTitle: 'Variables disponibles  :',
    fieldTooltipItem1: '###date.now### (date courante)',
    fieldTooltipItem2: "###currentUser.username### (identifiant de l'utilisateur connecté)",
    fieldTooltipItem3: "###currentUser.email### (email de l'utilisateur connecté)"
  }
}
