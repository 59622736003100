import React from 'react'
import { path } from 'ramda'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import { withState } from 'src/Components/Util'
import { translate } from 'src/Services/translation'
import translations from '../../translations'
import {
  deleteDocument,
  downloadDocument
} from '../../state/actions'

// propsFromState :: Map String (State -> a)
const propsFromState = {
  documentsToDelete: path([ 'Patient', 'documentsToDelete' ]),
  documentsDeleteError: path([ 'Patient', 'documentsDeleteError' ]),
  locale: path([ 'Root', 'user', 'locale' ]),
  language: path([ 'Root', 'user', 'language' ])
}

const Actions = ({
  document,
  documentsToDelete,
  documentsDeleteError,
  language,
  dispatch
}) => {
  const trans = translate(translations)(language)

  if (documentsDeleteError.indexOf(document.id) !== -1) {
    // dispatch(notifyError(trans('fetchError')))
  }

  return <Wrapper>
    <Button variant="outline-danger"
      type="button"
      title={trans('delete')}
      onClick={e => dispatch(deleteDocument(document.id))}
      disabled={documentsToDelete.indexOf(document.id) !== -1}
    >
      <i className="icon fas fa-times"/>
    </Button>
    <Button
      variant="outline-primary"
      type="button"
      title={trans('download')}
      onClick={e => dispatch(downloadDocument(document))}
    >
      <i className="icon fas fa-file-download"/>
    </Button>
  </Wrapper>
}

export default withState(propsFromState)(Actions)

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
