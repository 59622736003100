import React, { SyntheticEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { translate } from 'src/Services/translation'
import Modal from 'src/Components/Modal'
import { toggleCalculationFieldModal, updateFieldOption } from '../state/actions'
import {
  OPTION_CALCULATION_FIELD, TYPE_VALUE, TYPE_OPERATOR, TYPE_SYSTEM_FIELD, OPTION_TARGETED_FIELDS
} from 'src/Services/Constants'
import SystemFieldSelect from 'src/Components/SystemFieldSelect'
import translations from '../translations'


const CalculationFieldModal = () => {
  const dispatch = useDispatch()

  // @ts-ignore
  const { language } = useSelector(state => state.Root.user)
  // @ts-ignore
  const { isCalculationFieldModalOpen, editedField, form } = useSelector(state => state.FormEditor.formReducer)

  const trans = translate(translations)(language)

  const [ fields, setFields ] = useState([])
  const formId = form.id

  useEffect(() => {
    setFields(editedField?.options?.[OPTION_CALCULATION_FIELD] ?? [])
  }, [ editedField ])

  const addField = (type: string, value = '') =>
    setFields(fields => [ ...fields, { type, value } ])

  const removeField = () => {
    const fieldsClone = [ ...fields ]
    fieldsClone.pop()
    setFields(fieldsClone)
  }

  const editField = (value: string, i: number) => {
    const fieldsClone = [ ...fields ]
    fieldsClone[i].value = value
    setFields(fields => fieldsClone)
  }
  const clear = () => {
    setFields(fields => [])
  }
  const closeModal = () => {
    dispatch(toggleCalculationFieldModal())
  }

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault()
    const targetedFields: any[] = []
    fields.map(e => e.type === TYPE_SYSTEM_FIELD ? targetedFields.push(e.value) : false)

    dispatch(updateFieldOption(OPTION_TARGETED_FIELDS, targetedFields.length !== 0 ? targetedFields : null))
    dispatch(updateFieldOption(OPTION_CALCULATION_FIELD, fields))
    closeModal()
  }

  const onFieldDelete = (i: number) =>
    setFields(fields => fields.filter((f, j) => i !== j))

  return <Modal title={ trans('modal.calculationField.title') }
                onClose={ closeModal }
                isOpen={ isCalculationFieldModalOpen }
                size={'lg'}
                id={'autocomplete-fields-modal'}
  >
    <Form onSubmit={ onSubmit }>
      <Row className="mb-4 border-top border-bottom bg-light p-2 d-flex justify-content-center">
        { ['+', '-', '*', '/', '²', '(', ')'].map((operator, i) =>
        <Col md="auto" key={i}>
          <Button className="pl-0" variant={ 'outline-primary' } onClick={ () => addField(TYPE_OPERATOR, operator) } >
            {operator}
          </Button>
        </Col>
          )
        }
        <Col md="auto" >
          <Button variant={ 'outline-primary' } onClick={ removeField } size={ 'sm' }>
            <i className="fas fa-lg fa-backspace"/>
          </Button>
        </Col>
        <Col md="auto" >
          <Button variant={ 'outline-danger' } onClick={ clear } size={ 'sm' }>
            <i className="fas fa-lg fa-trash"/>
          </Button>
          </Col>
      </Row>

      <Row className="p-1">
      { Array.isArray(fields) && fields.map((field, i) => {
        if (field.type === TYPE_VALUE)
          return (
              <Col className="p-0 mb-2" md={2} key={i}>
                <Form.Control
                    name={ 'sysField' + i }
                    onChange={e => editField(e.target.value, i)}
                    value={ field.value }
                />
              </Col>
          )

        if (field.type === TYPE_SYSTEM_FIELD)
          return (
              <Col className="p-0 mb-2" md={3} key={i}>
                <SystemFieldSelect
                    value={field ? { id: null, name: field.value } : null }
                    formId={formId} onChange={e => editField(e.name, i)}
                />
              </Col>
          )

        if (field.type === TYPE_OPERATOR)
          return <Col md="auto" key={i}><Button disabled>{field.value}</Button></Col>
      })}
      </Row>
      <Row className="mt-4">
        <Col>
          <Button className={'m-2'} variant={ 'outline-primary' } onClick={ () => addField(TYPE_VALUE) } size={ 'sm' }>
            { trans('editField.addValue') }
          </Button>
          <Button variant={ 'outline-primary' } onClick={ () => addField(TYPE_SYSTEM_FIELD) } size={ 'sm' }>
            { trans('editField.addSysField') }
          </Button>
        </Col>
        <Col>
          <Button className={'float-end'} onClick={ onSubmit }>{ trans('submit') }</Button>
        </Col>
      </Row>
    </Form>
  </Modal>
}

export default CalculationFieldModal
