// export const FETCH_ACCESS_LEVELS = '[PERMISSIONS] FETCH_ACCESS_LEVELS'
export const FETCH_LANDING_PAGES = '[PERMISSIONS] FETCH_LANDING_PAGES'
export const FETCH_FORMS = '[PERMISSIONS] FETCH_FORMS'
export const FETCH_LISTS = '[PERMISSIONS] FETCH_LISTS'
export const FETCH_NUMERIC_REPORTS = '[PERMISSIONS] FETCH_NUMERIC_REPORTS'
export const FETCH_LOG_AS_ROLES = '[PERMISSIONS] FETCH_LOG_AS_ROLES'
export const FETCH_USER = '[PERMISSIONS] FETCH_USER'

export const SAVE_NEW_SUBJECT_DATA = '[PERMISSIONS] SAVE_NEW_SUBJECT_DATA'

export const SET_ACCESS_LEVELS = '[PERMISSIONS] SET_ACCESS_LEVELS'
export const SET_LANDING_PAGES = '[PERMISSIONS] SET_LANDING_PAGES'
export const SET_LISTS = '[PERMISSIONS] SET_LISTS'
export const SET_FORMS = '[PERMISSIONS] SET_FORMS'
export const SET_NUMERIC_REPORTS = '[PERMISSIONS] SET_NUMERIC_REPORTS'
export const SET_LOG_AS_ROLES = '[PERMISSIONS] SET_LOG_AS_ROLES'

export const NEW_PERMISSIONS_SAVED = '[PERMISSIONS] NEW_PERMISSIONS_SAVED'

export const DELETE_FORM_PERMISSION = '[PERMISSIONS] DELETE_FORM_PERMISSION'
export const FORM_PERMISSION_DELETED = '[PERMISSIONS] FORM_PERMISSION_DELETED'

export const FORM_PERMISSIONS_SUBMIT =  '[PERMISSIONS] FORM_PERMISSIONS_SUBMIT'
export const FORM_PERMISSIONS_SUBMIT_SET_STATE =  '[PERMISSIONS] FORM_PERMISSIONS_SUBMIT_SET_STATE'

export const SET_HAS_DATA_CHANGED = '[PERMISSIONS] SET_HAS_DATA_CHANGED'
export const SET_ROLE = '[PERMISSIONS] SET_ROLE'
export const SET_USER = '[PERMISSIONS] SET_USER'
export const SET_SUBJECT = '[PERMISSIONS] SET_SUBJECT'
export const SET_SUBJECT_DATA = '[PERMISSIONS] SET_SUBJECT_DATA'
export const SET_NEW_SUBJECT_DATA = '[PERMISSIONS] SET_NEW_SUBJECT_DATA'

export const RESET = '[PERMISSIONS] RESET'
