import { call, put, select, takeLatest } from 'redux-saga/effects'
import { FETCH_DELETED_SECTIONS, FETCH_RESTORE_SECTION } from './actionTypes'
import {
  receiveDeletedSections
} from './actions'
import apiMethods from '../../../../../Services/api/apiMethods'
import { contentRangeToPagination } from 'src/Utils/EffectsUtil'

function* fetchDeletedSections(props) {
  try {
    const state = yield select()
    const { pagination, searchQuery } = state.RestoreDeletedSections

    const { data, headers } = yield call(apiMethods.get, '/sections', {
      query: searchQuery, ...pagination, onlyDeleted: true
    })
    yield put(receiveDeletedSections({
      slotTypes: data,
      pagination: contentRangeToPagination(headers['content-range'], pagination)
    }))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'fetchFailed'))
  }
}

function* fetchRestoreSection(props, { payload }) {
  try {
    const data = yield call(apiMethods.update, `/sections/${payload.id}/restore`, {})
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'fetchFailed'))
  }
}


export default function* deletedSectionsSagaWatcher(props) {
  yield takeLatest(FETCH_DELETED_SECTIONS, fetchDeletedSections, props)
  yield takeLatest(FETCH_RESTORE_SECTION, fetchRestoreSection, props)
}
