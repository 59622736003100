import { call, cancel, delay, put, select, takeLatest } from 'redux-saga/effects'
import { fromEvent } from 'rxjs'
import { ignoreElements } from 'rxjs/operators'
import { showError, showInfo, showSuccess, showWarning } from 'src/Services/notifier/actions'
import { addStoreData, executeScript, reset } from 'src/Services/ScriptExecutor/state/actions.ts'
import { FIELD_ACTION_OPTION_VALUE } from 'src/Services/Constants'
import { FieldEvent } from 'src/Views/FormFiller/Types/Field'
import { setIsLoading } from 'src/Layouts/View/state/actions'
import { SaveStatus } from 'src/Components/SaveButton'
import { SWP_OPEN_PLAYBACK_FIELD_SYSTEM_NAME, SWP_OPEN_RECORD_FIELD_SYSTEM_NAME } from 'src/Services/Constants/Swp'
import { FieldOption } from 'src/Types/FieldOption'
import { getExtensibleFieldsToSaveByRow, getExtensibleNewRowFieldsToSave, getFieldsBySystemFieldName, getFieldValueBySystemName } from 'src/Views/FormFiller/state/selectors'
import { SwpFeatureStatus } from 'src/Views/FormFiller/Types/Swp'
import {
  addExtensibleRow, addExtensibleRowLoading, changeFieldValue, changeReference, createInstanceForReference, deleteFileSucceed, editFileSucceed, editInstanceForReference,
  fetchInstance, fetchInstanceFailed, fieldEvent, initiateSwpAcquisition, initSwp, lockFileSucceed, pushCustomizationOrder, redirectToSwpDynamicLink, referenceInstanceCreated,
  referenceSearchSucceeded, removeExtensibleRow, removeExtensibleRowLoading, resetCustomizations, saveInstance, saveInstanceFailed, saveInstanceSucceeded, setCalculationFields,
  setDocumentData, setFormCustomizationsConditionsTriggered, setInstance, setInstanceField, setInstanceSystemFields, setIsGenerateTemplateLoading, setIsOpenedInstanceReady,
  setMsSanteContentsToSend, setMsSanteRecipients, setMsSanteTrackingData, setPendingProtectedAction, setReferenceSearchFilters, setSaveInstanceStatus, setSwpFeatureStatus,
  setUserCode, toggleMsSanteContentsFetching, toggleMsSanteModal, toggleMsSanteModalLoading, toggleMsSanteRecipientsFetching, toggleValidateUserIdentityModal, unlockFileSucceed
} from './actions'
import * as types from './actionTypes'
import {
  getDocumentFieldTypeValue, getFormCustomizationConditionsTriggered, getFormCustomizationConditionsTriggeredByEvent, getInstanceDataToSave, isValidateUserIdentityNeeded
} from '../utils'
import { fetchInstanciatedForms, init } from '../../Patient/state/actions'
import * as patientTypes from '../../Patient/state/actionTypes'
import apiMethods from '../../../Services/api/apiMethods'

export function* fetchInstanceHandler(props, { payload: id }) {
  try {
    /** Get instance */
    const { data: instance } = yield call(apiMethods.get, `/instances/${ id }`)
    /* */

    /** Get form */
    const { data: form } = yield call(apiMethods.get, `/forms/${ instance.form.id }`, { withAccessLevel: true, instance: id })
    /* */

    yield put(setInstance(instance, form))
  } catch (error) {
    yield put(fetchInstanceFailed(error.response?.status || null))
    yield put(props.globalActions.handleError(error))
  }
}

export function* fetchInstanceFieldHandler(props, { payload: { instanceId, fieldId, onSuccess } }) {
  try {
    const { data } = yield call(apiMethods.get, `/instances/${ instanceId }/field/${ fieldId }`)
    yield put(setInstanceField(fieldId, data))
    yield put(setDocumentData(getDocumentFieldTypeValue(data)))

    if (onSuccess)
      onSuccess()
    yield put(setIsGenerateTemplateLoading(false))
  } catch (error) {
    yield put(fetchInstanceFailed())
    yield put(props.globalActions.handleError(error))
  }
}

function* triggerCalculation(formFiller, field, data) {
  const { calculationFields } = formFiller

  let isFieldPartOfCalculation = false
  let calculationFieldId = null
  let tempValues = {}
  for (const calculationField in calculationFields) {
    if (calculationFields[calculationField] && calculationFields[calculationField].some(fieldSystemName => fieldSystemName === field.systemName)) {
      isFieldPartOfCalculation = true
      calculationFieldId = calculationField
    }
    if (isFieldPartOfCalculation) {
      for (const systemName in calculationFields[calculationField]) {
        if (calculationFields[calculationField][systemName] === field.systemName)
          tempValues[calculationFields[calculationField][systemName]] = data.value
        else
          tempValues[calculationFields[calculationField][systemName]] =
            getFieldValueBySystemName(formFiller, calculationFields[calculationField][systemName])
      }
      break
    }
  }

  if (isFieldPartOfCalculation) {

    let request = {
      data: tempValues
    }
    const calculationData = yield call(apiMethods.create, `/instances/${ formFiller.openedInstance.id }/calculate/` + calculationFieldId, request)
    if (calculationData.data !== false) {
      console.log(yield put(setInstanceField(calculationFieldId, calculationData.data)))
    }
  }
}

function* onFieldEvent(props, { payload: { field, event, data } }) {
  try {
    yield put(setIsLoading(true))
    const formFiller = (yield select()).FormFiller

    if (event === FieldEvent.VALUE_CHANGE) {
      yield triggerCalculation(formFiller, field, data)

      yield put(changeFieldValue(field, data[FIELD_ACTION_OPTION_VALUE]))
    }

    if (event === FieldEvent.VALUE_CHANGE && isValidateUserIdentityNeeded(formFiller, field.id))
      yield put(toggleValidateUserIdentityModal())

    /** Process form customizations */
    const conditionsTriggered = formFiller.formCustomizationConditionsTriggered
    const conditionsTriggeredForField = conditionsTriggered.filter(c => c.field.id === field.id)

    const conditionsTriggeredByEvent = getFormCustomizationConditionsTriggeredByEvent(
      formFiller.openedInstanceForm?.customizationOrders || [],
      event, field, data
    )

    const results = [
      // if cond has been trigger by same field but is not in those recently triggered then remove it
      ...conditionsTriggered.filter(condition =>
        conditionsTriggeredForField.some(c => c.id === condition.id)
          ? conditionsTriggeredByEvent.some(c => c.id === condition.id)
          : true
      ),
      ...conditionsTriggeredByEvent
    ]

    yield put(setFormCustomizationsConditionsTriggered(results))
    /** */
  } catch (error) {
    yield put(props.globalActions.handleError(error))
  } finally {
    yield put(setIsLoading(false))
  }
}

function* saveInstanceFieldValueHandler(props, { payload: { instanceId, fieldId, value, isSilent } }) {
  try {
    const { data } = yield call(apiMethods.update, `/instances/${ instanceId }/field/${ fieldId }/value`, {
      data: { value }
    })
    yield put(setInstanceField(fieldId, data))

    if (!isSilent)
      yield put(showSuccess('updateSucceeded'))

  } catch (error) {
    yield put(props.globalActions.handleError(error, 'editFailed'))
  }
}


function* saveInstanceHandler(props, { payload: { id, isSilent, onSuccess } }) {
  if (!id)
    yield cancel()

  const state = yield select()
  const data = getInstanceDataToSave(state.FormFiller)

  if (!data) {
    if (!isSilent)
      yield put(showWarning('noDataToSave'))

    return yield put(setSaveInstanceStatus(SaveStatus.VALID))
  }

  try {
    yield call(apiMethods.update, `/instances/${ id }`, { data: data.form })

    if (!isSilent)
      yield put(showSuccess('saveInstanceSucceeded'))

    yield put(saveInstanceSucceeded())

    if (onSuccess)
      onSuccess()

  } catch (e) {
    const errorCode = e.response?.data?.error?.code

    let translationKey = 'saveInstanceFailed'

    if (errorCode === 'userPinIncorrect') {
      yield put(setUserCode(null))

      yield put(saveInstanceFailed())
      yield put(setPendingProtectedAction({ type: types.SAVE_INSTANCE, payload: { id, isSilent, onSuccess } }))
      yield put(toggleValidateUserIdentityModal())
      yield cancel()
    } else if (errorCode === 'workflowFailed') {
      if (!isSilent)
        yield put(showWarning('workflowFailedError'))

      yield put(saveInstanceSucceeded())

      if (onSuccess)
        onSuccess()

      yield cancel()
    }

    if (!isSilent)
      yield put(showError(translationKey))

    yield put(props.globalActions.handleError(e, translationKey))
    yield put(saveInstanceFailed())
  }
}

function* setDocumentAsFinal(props, { payload: { instanceId, field, file } }) {
  const state = yield select()

  try {
    if (isValidateUserIdentityNeeded(state.FormFiller, field.id, field?.options[FieldOption.SAVE_TYPE])) {
      yield put(setPendingProtectedAction({
        type: types.SET_DOCUMENT_AS_FINAL,
        payload: { instanceId, field, file }
      }))
      yield put(toggleValidateUserIdentityModal())
      yield cancel()
    }

    const { data } = yield call(
      apiMethods.sendFiles,
      `/instances/${ instanceId }/fields/${ field.id }/document_as_final`,
      {
        files: [ file ],
        data: {
          userCode: state.FormFiller.userCode
        }
      }
    )

    yield put(showSuccess('saveAsFinalSucceeded'))

    yield put(fieldEvent(
      data.field,
      FieldEvent.VALUE_CHANGE,
      { value: data.value[`field_${ data.field.id }`] }
    ))
  } catch (e) {
    const errorCode = e.response?.data?.error?.code

    if (errorCode === 'userPinIncorrect') {
      yield put(setUserCode(null))

      yield put(setPendingProtectedAction({
        type: types.SET_DOCUMENT_AS_FINAL,
        payload: { instanceId, field, file }
      }))
      yield put(toggleValidateUserIdentityModal())
      yield cancel()
    }

    yield put(props.globalActions.handleError(e, e.toString()))
  }
}

function* deleteInstanceHandler(props, { payload: { id, navigate } }) {
  try {
    yield call(apiMethods.delete, `/instances/${ id }`)
    yield put(showSuccess('deleteSucceeded'))

    yield call(navigate, '/')
  } catch (error) {
    yield put(props.globalActions.handleError(error, error.toString()))
  }
}

function* addInstance(props, { payload: { patientId, formId, navigate } }) {
  try {
    const { data } = yield call(apiMethods.create, '/instances', {
      data: { form: formId, patientId }
    })
    yield put(fetchInstanciatedForms(patientId))
    if (patientId) {
      yield call(navigate, `/patient/${ patientId }/instance/${ data.id }`)
    } else {
      yield call(navigate, `/instance/${ data.id }`)
    }
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'createFailed'))
  }
}

function* requestInstanceCreationForReference(props, { payload: { referenceField } }) {
  try {
    const { referenceFields: referenceFieldIds, list } = referenceField
    const state = yield select()
    const { editedFields, openedInstance: { fields } } = state.FormFiller

    const values = {}
    let isEmpty = true

    for (const referenceFieldId of referenceFieldIds) {

      if (!editedFields.includes(referenceFieldId))
        continue

      let field = fields[referenceFieldId]

      values[field.listColumn.systemName] = field.value

      if (field.value !== null)
        isEmpty = false
    }

    if (isEmpty)
      yield put(showWarning('emptyForm'))
    else
      yield put(createInstanceForReference(referenceField, values))
  } catch (e) {

    const errorCode = e.response?.data?.error?.code

    if (errorCode === 'userPinIncorrect') {
      yield put(setUserCode(null))

      yield put(saveInstanceFailed())
      yield put(
        setPendingProtectedAction({
          type: types.REQUEST_INSTANCE_CREATION_FOR_REFERENCE,
          payload: { referenceField }
        })
      )
      yield put(toggleValidateUserIdentityModal())
      yield cancel()
    }

    yield put(props.globalActions.handleError(e, 'referenceInstanceCreationFailed'))
  }
}

function* doCreateInstanceForReference(props, { payload: { referenceField, values } }) {
  const state = yield select()
  const { id } = state.FormFiller.openedInstance

  try {
    const { data } = yield call(apiMethods.create, `/instances/${ id }/field/${ referenceField.id }`, {
      data: {
        data: values,
        userCode: state.FormFiller.userCode
      }
    })

    yield put(referenceInstanceCreated())
    yield put(changeReference(referenceField.id, data.value, values))

    yield put(showSuccess('createSucceeded'))
  } catch (e) {

    const errorCode = e.response?.data?.error?.code

    if (errorCode === 'userPinIncorrect') {
      yield put(setUserCode(null))

      yield put(saveInstanceFailed())
      yield put(
        setPendingProtectedAction({
          type: types.CREATE_INSTANCE_FOR_REFERENCE,
          payload: { referenceField, values }
        }))
      yield put(toggleValidateUserIdentityModal())
      yield cancel()
    }

    yield put(props.globalActions.handleError(e, 'referenceInstanceCreationFailed'))
  }
}

function* requestInstanceEditForReference(props, { payload: { referenceField } }) {
  try {
    const { referenceFields: referenceFieldIds, value } = referenceField
    const state = yield select()
    const { editedFields, openedInstance: { fields } } = state.FormFiller

    const values = {}
    let isEmpty = true

    for (const referenceFieldId of referenceFieldIds) {

      if (!editedFields.includes(referenceFieldId))
        continue

      let field = fields[referenceFieldId]

      values[field.listColumn.systemName] = field.value

      if (field.value !== null)
        isEmpty = false
    }

    if (isEmpty)
      yield put(showWarning('emptyForm'))
    else
      yield put(editInstanceForReference(referenceField, values, value?.id || value))
  } catch (e) {
    const errorCode = e.response?.data?.error?.code

    if (errorCode === 'userPinIncorrect') {
      yield put(setUserCode(null))

      yield put(setPendingProtectedAction(
        { type: types.REQUEST_INSTANCE_EDIT_FOR_REFERENCE, payload: { referenceField } }))
      yield put(toggleValidateUserIdentityModal())
      yield cancel()
    }

    yield put(props.globalActions.handleError(e, 'referenceInstanceCreationFailed'))
  }
}

function* doEditInstanceForReference(props, { payload: { referenceField, values, instanceId } }) {
  const state = yield select()

  try {
    yield call(apiMethods.update, `/instances/${ instanceId }/field/${ referenceField.id }`, {
      data: {
        data: values,
        userCode: state.FormFiller.userCode
      }
    })

    // yield put(referenceInstanceCreated())
    // yield put(changeReference(referenceField.id, instanceId, values))
    yield put(showSuccess('updateSucceeded'))
  } catch (e) {
    const errorCode = e.response?.data?.error?.code

    if (errorCode === 'userPinIncorrect') {
      yield put(setUserCode(null))

      yield put(setPendingProtectedAction(
        { type: types.EDIT_INSTANCE_FOR_REFERENCE, payload: { referenceField, values, instanceId } }))
      yield put(toggleValidateUserIdentityModal())
      yield cancel()
    }

    yield put(props.globalActions.handleError(e, 'referenceInstanceCreationFailed'))
  }
}

function* editFile(props, { payload }) {
  const state = yield select()

  try {
    yield call(apiMethods.update, `/files/${ payload.fileId }`, {
      data: {
        data: { name: payload.name, description: payload.description },
        userCode: state.FormFiller.userCode
      }
    })

    yield put(showSuccess('updateSucceeded'))
    yield put(editFileSucceed(payload.fieldId, payload.fileId, payload.name, payload.description))
  } catch (e) {
    const errorCode = e.response?.data?.error?.code

    if (errorCode === 'userPinIncorrect') {
      yield put(setUserCode(null))

      yield put(setPendingProtectedAction({ type: types.EDIT_FILE, payload }))
      yield put(toggleValidateUserIdentityModal())
      yield cancel()
    }

    yield put(props.globalActions.handleError(e, 'editFileFailed'))
  }
}

function* lockFile(props, { payload }) {
  try {
    const { id: instanceId } = (yield select()).FormFiller.openedInstance

    yield call(apiMethods.get, `/instances/${ instanceId }/files/${ payload.fileId }/lock`)
    yield put(showSuccess('updateSucceeded'))
    yield put(lockFileSucceed(payload.fieldId, payload.fileId))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'lockFileFailed'))
  }
}

function* printContent(props) {
  try {
    document.querySelector('body')
      .classList
      .add('print-content')
    window.print()

    // todo remove it to get rid of rxjs
    fromEvent(document, 'afterprint')

    document.querySelector('body')
      .classList
      .remove('print-content')

    // todo remove it to get rid of rxjs
    ignoreElements()

  } catch (error) {
    yield put(props.globalActions.handleError(error, error.toString()))
  }
}

function* restoreInstance(props, { payload: { instanceId } }) {
  try {
    const { data } = yield call(apiMethods.update, `/instances/${ instanceId }/restore`)

    const state = yield select()
    const { id: patientId } = state.Patient

    if (patientId === instanceId)
      yield put(init(patientId))

    yield put(fetchInstance(instanceId))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'restoreInstanceFailed'))
  }
}

function* createExtensibleRow(props, { payload }) {
  const state = yield select()

  try {
    const { id: instanceId, fields } = state.FormFiller.openedInstance

    const extensibleData = getExtensibleNewRowFieldsToSave(fields, payload.fieldId)

    if (!Object.keys(extensibleData).length)
      return yield put(showWarning('editExtensibleNoChange'))

    yield call(apiMethods.create, `/instances/${ instanceId }/table/${ payload.fieldId }/row`, {
      data: {
        data: extensibleData,
        userCode: state.FormFiller.userCode,
        useSystemNameAsKey: true
      }
    })

    const { data } = yield call(apiMethods.get, `/instances/${ instanceId }/table/${ payload.fieldId }`)

    const lastRowId = data.map(row => row.id).sort((a, b) => b - a)[0]

    yield put(addExtensibleRow(payload.fieldId, lastRowId, extensibleData))
    yield put(showSuccess('saveExtensibleSucceeded'))
  } catch (e) {
    const errorCode = e.response?.data?.error?.code

    if (errorCode === 'userPinIncorrect') {
      yield put(setUserCode(null))

      yield put(setPendingProtectedAction({ type: types.CREATE_EXTENSIBLE_ROW, payload: { payload } }))
      yield put(toggleValidateUserIdentityModal())
      yield cancel()
    }

    yield put(props.globalActions.handleError(e, 'saveExtensibleFailed'))
  }
}

function* editExtensibleRow(props, { payload: { extensibleFieldId, rowId } }) {
  const state = yield select()
  const { id: instanceId, fields } = state.FormFiller.openedInstance

  try {
    yield put(addExtensibleRowLoading(extensibleFieldId, rowId))
    const extensibleData = getExtensibleFieldsToSaveByRow(fields, extensibleFieldId, rowId)

    if (!Object.keys(extensibleData).length) {
      yield put(showWarning('editExtensibleNoChange'))
      yield put(removeExtensibleRowLoading(extensibleFieldId, rowId))
      return
    }

    yield call(apiMethods.update, `/instances/${ instanceId }/table/${ extensibleFieldId }/row/${ rowId }`, {
      data: {
        data: extensibleData,
        userCode: state.FormFiller.userCode,
        useSystemNameAsKey: true
      }
    })

    yield put(showSuccess('saveExtensibleSucceeded'))
  } catch (e) {
    const errorCode = e.response?.data?.error?.code

    if (errorCode === 'userPinIncorrect') {
      yield put(setUserCode(null))

      yield put(setPendingProtectedAction({
        type: types.EDIT_EXTENSIBLE_ROW,
        payload: { extensibleFieldId, rowId }
      }))
      yield put(toggleValidateUserIdentityModal())
      yield cancel()
    }

    yield put(props.globalActions.handleError(e, 'editExtensibleFailed'))
  }
  yield put(removeExtensibleRowLoading(extensibleFieldId, rowId))
}

function* deleteExtensibleRow(props, { payload: { extensibleFieldId, rowId } }) {
  const state = yield select()
  const { id: instanceId } = state.FormFiller.openedInstance

  try {
    yield put(addExtensibleRowLoading(extensibleFieldId, rowId))
    yield call(apiMethods.delete, `/instances/${ instanceId }/table/${ extensibleFieldId }/row/${ rowId }`)

    yield put(removeExtensibleRow(extensibleFieldId, rowId))
    yield put(showSuccess('deleteSucceeded'))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'deleteExtensibleFailed'))
  }
  yield put(removeExtensibleRowLoading(extensibleFieldId, rowId))
}

function* searchReference(props, { payload }) {
  try {
    const { data } = yield call(apiMethods.create, `/lists/${ payload.listId }/run`, {
      data: {
        filters: payload.filters,
        limit: 100,
        offset: 0,
        externalProvider: payload?.externalProvider
      }
    })
    yield put(setReferenceSearchFilters(payload.filters))
    yield put(referenceSearchSucceeded(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'searchReferenceFailed'))
  }
}

function* changeReferenceToSelf(props, { payload: { refId, instanceId, listId } }) {
  try {
    const { data } = yield call(apiMethods.create, `/lists/${ listId }/run/${ instanceId }`, {})
    const userDataFromList = data[0]

    yield put(changeReference(refId, instanceId, userDataFromList?.values || []))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'createFailed'))
  }
}

function* downloadFile(props, { payload }) {
  try {
    yield call(apiMethods.downloadBlobFile, 'get', `/download/${ payload.fileId }`)
  } catch (error) {
    yield put(props.globalActions.handleError(error))
  }
}

function* downloadFileFromUrl(props, { payload }) {
  try {
    yield call(apiMethods.downloadFileFromUrl, 'get', `/generate_url_file/${ payload.fileId }`)
  } catch (error) {
    yield put(props.globalActions.handleError(error))
  }
}

function* unlockFile(props, { payload }) {
  try {
    const { id: instanceId } = (yield select()).FormFiller.openedInstance

    yield call(apiMethods.get, `/instances/${ instanceId }/files/${ payload.fileId }/unlock`)
    yield put(showSuccess('updateSucceeded'))
    yield put(unlockFileSucceed(payload.fieldId, payload.fileId))
  } catch (error) {
    yield put(props.globalActions.handleError(error, error.toString()))
  }
}

function* deleteFile(props, { payload: { field, fileId } }) {
  const state = yield select()

  try {
    if (isValidateUserIdentityNeeded(state.FormFiller, field.id)) {
      yield put(setPendingProtectedAction({ type: types.DELETE_FILE, payload: { field, fileId } }))
      yield put(toggleValidateUserIdentityModal())
      yield cancel()
    }

    const { userCode, openedInstance } = state.FormFiller
    const newValue = field.value.reduce((value, f) =>
      f.id !== fileId ? [ ...value, f.id ] : value, [])

    yield call(apiMethods.update, `/instances/${ openedInstance.id }`, {
      data: {
        data: {
          [field.systemName]: newValue
        },
        userCode,
        useSystemNameAsKey: true,
      }
    })
    yield call(apiMethods.create, `/files/${ fileId }/delete`, { data: { userCode } })
    yield put(deleteFileSucceed(field.id, fileId))
  } catch (e) {
    const errorCode = e.response?.data?.error?.code

    if (errorCode === 'userPinIncorrect') {
      yield put(setUserCode(null))

      yield put(setPendingProtectedAction({ type: types.DELETE_FILE, payload: { field, fileId } }))
      yield put(toggleValidateUserIdentityModal())
      yield cancel()
    }

    yield put(props.globalActions.handleError(e, 'deleteFailed'))
  }
}

function* doChangeReference(props, { payload: { refId, instanceId, values, isFromExternalSource } }) {
    const state = yield select()
    const field = state.FormFiller.openedInstance.fields[refId]

    if (isFromExternalSource) {
        yield put(createInstanceForReference(field, values))
    } else {
        yield put(fieldEvent(field, FieldEvent.VALUE_CHANGE, { value: instanceId }))
    }

  if (isValidateUserIdentityNeeded(state.FormFiller))
    yield put(toggleValidateUserIdentityModal())
}

function* doClearReference(props, { payload }) {
  const state = yield select()

  yield put(fieldEvent(payload.field, FieldEvent.VALUE_CHANGE, { value: null }))

  if (isValidateUserIdentityNeeded(state.FormFiller))
    yield put(toggleValidateUserIdentityModal())
}

function* onInstanceSet(props, { payload }) {
  yield put(setIsLoading(true))

  /** Reset last instance custom script and customization orders applied */
  yield put(reset())
  yield put(resetCustomizations())

  try {
    /** Get system fields */
    const { data: systemFields } = yield call(
      apiMethods.get,
      `/forms/${ payload.instance.form.id }/system_fields`,
      { all: true }
    )
    yield put(setInstanceSystemFields(systemFields))
    /** */

    /** Trigger default form customization orders */
    const { openedInstance, openedInstanceForm: form } = (yield select()).FormFiller

    const conditionsTriggered = getFormCustomizationConditionsTriggered(
      form.customizationOrders || [],
      openedInstance
    )

    yield put(setFormCustomizationsConditionsTriggered(conditionsTriggered))
    /** */

    /** Trigger page load script */
    const onPageLoad = form.javaScriptCode?.onPageLoad || null

    if (onPageLoad)
      yield put(executeScript(onPageLoad, null))
    /** */

    /** Calculation */
    let values = {}
    for (const field in openedInstance.fields) {
      if (openedInstance.fields[field].type.name === 'Calculation') {
        values[openedInstance.fields[field].id] = openedInstance.fields[field].options.targetedFields
      }
    }
    yield put(setCalculationFields(values))
    /** */

    yield put(setIsOpenedInstanceReady(true))
  } catch (error) {
    yield put(props.globalActions.handleError(error))
  } finally {
    yield put(setIsLoading(false))
  }
}

function* sendTestPreviewEmail(props, { payload }) {
  try {
    const { instanceId } = payload

    yield call(apiMethods.get, `/message_templates/${ instanceId }/preview`)
    yield put(showSuccess('fetchSucceeded'))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'fetchFailed'))
  }
}


function* onSystemFieldsSet() {
  const { openedInstanceForm } = (yield select()).FormFiller

  if (openedInstanceForm?.isSwpEnabled) {
    yield put(setSwpFeatureStatus(SwpFeatureStatus.LOADING))
    yield put(initSwp())
  } else {
    yield put(setSwpFeatureStatus(SwpFeatureStatus.DISABLE))
  }
}

function* doInitSwp(props) {
  const state = (yield select()).FormFiller
  const { openedInstance: instance } = state

  try {
    const openRecordButtons = getFieldsBySystemFieldName(state, SWP_OPEN_RECORD_FIELD_SYSTEM_NAME)
    const openPlaybackButtons = getFieldsBySystemFieldName(state, SWP_OPEN_PLAYBACK_FIELD_SYSTEM_NAME)

    let { data: session } = yield call(
      apiMethods.get,
      `/middlewares/swp/acquisition/${ instance.id }`,
      { withAccessToken: true }
    )
    const accessToken = session?.accessToken || null

    const { data: swpConfig } = yield call(apiMethods.get, '/middlewares/swp/config')

    if (accessToken && swpConfig.audience) {
      try {
        // This fetch is necessary to have the auth cookie set
        /** https://bioserenity.atlassian.net/wiki/spaces/CLOUD/pages/1718190603/Acquisition+Session+API+usage */
        const response = yield call(apiMethods.get, '/acquisition-session/get', {}, {
          baseURL: swpConfig.audience,
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${ accessToken }`
          }
        })
        session = response?.data || session
      } catch (error) {

        try {
          for (const openRecordButton of openRecordButtons) {
            yield put(pushCustomizationOrder('FIELD', openRecordButton.id, 'DISABLE'))
            yield put(pushCustomizationOrder('FIELD', openRecordButton.id, 'SHOW_INFO'),
              { value: 'Error while fetching acquisition informations.', variant: 'danger' })
          }
        } catch (e) {
          console.error(e)
        }

        yield put(props.globalActions.handleError(error))
      }
    }

    if (session.sessionId) {
      yield put(addStoreData('session', session))
      for (const openRecordButton of openRecordButtons)
        yield put(pushCustomizationOrder(
          'FIELD', openRecordButton.id, 'SHOW_INFO',
          { value: 'Session available for recording.', variant: 'success' })
        )
    } else if (accessToken && !session) {
      //action.customizeForm('FIELD', openRecordButton.id, 'HIDE_INFO')
    }

    // Update playback button
    if (session?.canReadDataAsDeferred && session?.productContext?.product) {
      yield put(addStoreData(
        'playbackUrl',
        `${ swpConfig.playback }/${ session?.productContext?.product.toLowerCase() }/${ session.sessionId }`)
      )
      for (const openPlaybackButton of openPlaybackButtons) {
        yield put(pushCustomizationOrder('FIELD', openPlaybackButton.id, 'SHOW_INFO',
          { value: 'Session available for playback.', variant: 'success' }))
        yield put(pushCustomizationOrder('FIELD', openPlaybackButton.id, 'ENABLE'))
      }
    } else {
      for (const openPlaybackButton of openPlaybackButtons) {
        yield put(pushCustomizationOrder('FIELD', openPlaybackButton.id, 'SHOW_INFO',
          { value: 'Session unavailable for playback.', variant: 'danger' }))
        yield put(pushCustomizationOrder('FIELD', openPlaybackButton.id, 'DISABLE'))
      }
    }
  } catch (e) {
    yield put(props.globalActions.handleError(e))
  }

  yield put(setSwpFeatureStatus(SwpFeatureStatus.READY))
}

function* openSwpRecord() {
  const { store } = (yield select()).ScriptExecutor
  const state = (yield select()).FormFiller
  const { openedInstance: instance } = state

  const openRecordButtons = getFieldsBySystemFieldName(state, SWP_OPEN_RECORD_FIELD_SYSTEM_NAME)

  for (const openRecordButton of openRecordButtons)
    yield put(pushCustomizationOrder('FIELD', openRecordButton.id, 'START_LOADING'))

  const session = store.find(_ => _.id === 'session')

  yield put(saveInstance(instance.id, true))

  if (session?.data?.sessionId)
    yield put(redirectToSwpDynamicLink())
  else
    yield put(initiateSwpAcquisition())
}

function* doInitiateSwpAcquisition(props) {
  const state = (yield select()).FormFiller
  const { openedInstance: instance } = state

  const openRecordButtons = getFieldsBySystemFieldName(state, SWP_OPEN_RECORD_FIELD_SYSTEM_NAME)

  try {
    yield call(apiMethods.get, `/middlewares/swp/initiate_acquisition/${ instance.id }`)

    yield put(showSuccess('Initiated acquisition successfully'))
    yield put(showInfo('Now trying to launch acquisition...'))

    yield put(redirectToSwpDynamicLink())
  } catch (e) {
    const errorCode = e?.response?.data?.error?.code
    const errorMessage = errorCode === 'patientIncomplete'
      ? 'Patient is incomplete. Please fill all required fields.'
      : 'Initiate acquisition failed'

    yield put(showError(errorMessage))
    for (const openRecordButton of openRecordButtons) {
      yield put(pushCustomizationOrder('FIELD', openRecordButton.id, 'STOP_LOADING'))

      if (errorCode !== 'patientIncomplete')
        yield put(pushCustomizationOrder(
          'FIELD', openRecordButton.id, 'SHOW_INFO',
          { value: 'Error while fetching acquisition informations.', variant: 'danger' }
        ))
    }
    yield put(props.globalActions.handleError(e))
  }
}

function* doRedirectToSwpDynamicLink(props) {
  const state = (yield select()).FormFiller
  const { openedInstance: instance } = state

  const openRecordButtons = getFieldsBySystemFieldName(state, SWP_OPEN_RECORD_FIELD_SYSTEM_NAME)

  try {
    const { data } = yield call(apiMethods.get, `/middlewares/swp/dynamic_link/${ instance.id }`)

    yield put(showSuccess('You will be redirect to acquisition...'))
    const dynamicLink = decodeURI(data.dynamicLink)

    setTimeout(() => {
      //window.open(dynamicLink, '_blank')
      window.location.href = dynamicLink
    }, 2000)

    for (const openRecordButton of openRecordButtons)
      yield put(pushCustomizationOrder('FIELD', openRecordButton.id, 'STOP_LOADING'))

  } catch (error) {
    yield put(showError('Acquisition launch failed'))
    for (const openRecordButton of openRecordButtons) {
      yield put(pushCustomizationOrder('FIELD', openRecordButton.id, 'STOP_LOADING'))
      yield put(pushCustomizationOrder(
        'FIELD', openRecordButton.id, 'SHOW_INFO',
        { value: 'Error while fetching acquisition informations.', variant: 'danger' }
      ))
    }
    yield put(props.globalActions.handleError(error))
  }
}

function* openSwpPlayback() {
  const { store } = (yield select()).ScriptExecutor

  const playbackUrl = store.find(_ => _.id === 'playbackUrl')

  if (playbackUrl?.data)
    window.open(playbackUrl.data, '_blank')
  else {
    yield put(showWarning('Playback is not ready yet'))
  }
}

function* sendMsSanteDocuments(props, { payload: { instanceId, recipients, files } }) {
  let request = {
    recipients: recipients,
    files: files
  }

  try {
    const { data } = yield call(
      apiMethods.create, `/middlewares/ms_sante/send_documents/${ instanceId }`, { data: request }
    )
    yield put(showSuccess('msSanteSendSuccess'))

  } catch (e) {
    yield put(props.globalActions.handleError(e, 'msSanteSendFailed'))
  }
  yield put(toggleMsSanteModal(false))
  yield put(toggleMsSanteModalLoading(false))
}

function* fetchRecipients(props, { payload: { instanceId } }) {
  const { data } = yield call(apiMethods.get, `/middlewares/ms_sante/get_recipients/${ instanceId }`)
  yield put(setMsSanteRecipients(data))
  yield put(toggleMsSanteRecipientsFetching(false))
}

function* fetchContentsToSend(props, { payload: { instanceId } }) {
  const { data } = yield call(apiMethods.get, `/middlewares/ms_sante/get_contents/${ instanceId }`)
  yield put(setMsSanteContentsToSend(data))
  yield put(toggleMsSanteContentsFetching(false))
}

function* sendMsSanteTrackingRequest(props, { payload: { instanceId } }) {
  const { data } = yield call(apiMethods.get, `/middlewares/ms_sante/update/track_documents/${ instanceId }`)
  yield put(setMsSanteTrackingData(data))
}

function* fetchMsSanteTrackingDatas(props, { payload: { instanceId } }) {
  const { data } = yield call(apiMethods.get, `/middlewares/ms_sante/fetch/track_documents/${ instanceId }`)
  yield put(setMsSanteTrackingData(data))
}

function* setUserPinHandler(props, { payload: { userCode } }) {
  if (userCode) {
    // Reset user code after 10 seconds (it needs time to be sent to requests before being deleted)
    yield delay(10000)
    yield put(setUserCode(null))
  }
}

function* fillPatientDataInInstance(props, { payload }) {
  try {
    yield put(changeReference(
      payload.refId,
      payload.rowId,
      payload.values
    ))

    if (!payload.refPatientIds?.length)
      yield cancel()

    for (let refPatient of payload.refPatientIds) {
      const { data } = yield call(apiMethods.create, `/lists/${ refPatient?.listId }/run/${ payload.rowId }`, {})
      const userDataFromList = data[0]

      yield put(changeReference(
        refPatient?.id,
        payload.rowId,
        userDataFromList?.values || []
      ))
    }
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'referenceInstanceCreationFailed'))
  }
}

export default function* formFillerSagaWatcher(props) {
  yield takeLatest(types.FETCH_INSTANCE, fetchInstanceHandler, props)
  yield takeLatest(types.FETCH_INSTANCE_FIELD, fetchInstanceFieldHandler, props)
  yield takeLatest(types.SAVE_INSTANCE, saveInstanceHandler, props)
  yield takeLatest(types.SAVE_INSTANCE_FIELD_VALUE, saveInstanceFieldValueHandler, props)
  yield takeLatest(types.DELETE_INSTANCE, deleteInstanceHandler, props)
  yield takeLatest(patientTypes.ADD_INSTANCE, addInstance, props)
  yield takeLatest(types.REQUEST_INSTANCE_CREATION_FOR_REFERENCE, requestInstanceCreationForReference, props)
  yield takeLatest(types.CREATE_INSTANCE_FOR_REFERENCE, doCreateInstanceForReference, props)
  yield takeLatest(types.REQUEST_INSTANCE_EDIT_FOR_REFERENCE, requestInstanceEditForReference, props)
  yield takeLatest(types.EDIT_INSTANCE_FOR_REFERENCE, doEditInstanceForReference, props)
  yield takeLatest(types.EDIT_FILE, editFile, props)
  yield takeLatest(types.LOCK_FILE, lockFile, props)
  yield takeLatest(types.PRINT_CONTENT, printContent, props)
  yield takeLatest(types.RESTORE_INSTANCE, restoreInstance, props)
  yield takeLatest(types.CREATE_EXTENSIBLE_ROW, createExtensibleRow, props)
  yield takeLatest(types.EDIT_EXTENSIBLE_ROW, editExtensibleRow, props)
  yield takeLatest(types.DELETE_EXTENSIBLE_ROW, deleteExtensibleRow, props)
  yield takeLatest(types.SEARCH_REFERENCE, searchReference, props)
  yield takeLatest(types.UNLOCK_FILE, unlockFile, props)
  yield takeLatest(types.DOWNLOAD_FILE, downloadFile, props)
  yield takeLatest(types.DOWNLOAD_FILE_FROM_URL, downloadFileFromUrl, props)
  yield takeLatest(types.DELETE_FILE, deleteFile, props)
  yield takeLatest(types.CHANGE_REFERENCE, doChangeReference, props)
  yield takeLatest(types.CLEAR_REFERENCE, doClearReference, props)
  yield takeLatest(types.CHANGE_REFERENCE_TO_SELF, changeReferenceToSelf, props)
  yield takeLatest(types.SET_INSTANCE, onInstanceSet, props)
  yield takeLatest(types.SET_INSTANCE_SYSTEM_FIELDS, onSystemFieldsSet, props)
  yield takeLatest(types.FIELD_EVENT, onFieldEvent, props)
  yield takeLatest(types.SEND_EMAIl_PREVIEW, sendTestPreviewEmail, props)
  yield takeLatest(types.FILL_PATIENT_INSTANCE, fillPatientDataInInstance, props)
  yield takeLatest(types.SET_DOCUMENT_AS_FINAL, setDocumentAsFinal, props)
  yield takeLatest(types.SEND_MS_SANTE_DOCUMENTS, sendMsSanteDocuments, props)
  yield takeLatest(types.FETCH_MS_SANTE_RECIPIENTS, fetchRecipients, props)
  yield takeLatest(types.FETCH_MS_SANTE_CONTENTS_TO_SEND, fetchContentsToSend, props)
  yield takeLatest(types.SEND_MS_SANTE_TRACKING_REQUEST, sendMsSanteTrackingRequest, props)
  yield takeLatest(types.FETCH_MS_SANTE_TRACKING_DATA, fetchMsSanteTrackingDatas, props)
  yield takeLatest(types.SET_USER_PIN, setUserPinHandler, props)
}
