import {
  CHANGE_LIMIT,
  FETCH_SLOT_TYPES,
  RECEIVE_SLOT_TYPES,
  TOGGLE_CREATE_SLOT_TYPE,
  SLOT_TYPE_CREATED,
  TOGGLE_UPDATE_SLOT_TYPE,
  TOGGLE_DELETE_SLOT_TYPE,
  SLOT_TYPE_DELETED,
  SLOT_TYPE_UPDATED,
  CHANGE_PAGE,
  SEARCH_SLOT_TYPE,
  SET_FORM_ERRORS
} from './slotTypes'

const initialState = {
  loading: false,
  isCreatingSlotType: false,
  isUpdatingSlotType: false,
  isDeletingSlotType: false,
  isFormsDropdownOpened: false,
  slotTypeOnUpdate: null,
  slotTypeOnDelete: null,
  formErrors: {},
  slotTypes: [],
  searchQuery: null,
  pagination: {
    offset: 0,
    limit: 10,
    itemsCount: 10,
    totalItemsCount: 10,
    pageLimit: 10,
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPagesCount: 1,
  },
}

export default (state = initialState, {
  type,
  payload
}) => {
  switch (type) {
    case FETCH_SLOT_TYPES:
      return {
        ...state,
        loading: true
      }
    case SET_FORM_ERRORS:
      return {
        ...state,
        formErrors: payload
      }
    case RECEIVE_SLOT_TYPES:
      return {
        ...state,
        loading: false,
        slotTypes: payload.slotTypes,
        pagination: payload.pagination
      }
    case SLOT_TYPE_CREATED:
      return {
        ...state,
        loading: false,
        slotTypes: [ ...state.slotTypes, payload ]
      }
    case SLOT_TYPE_UPDATED:
      return {
        ...state,
        loading: false,
        slotTypes: state.slotTypes.map(_ => _.id === payload.id ? payload : _)
      }
    case SLOT_TYPE_DELETED:
      return {
        ...state,
        loading: false,
        slotTypeOnDelete: null,
        slotTypes: state.slotTypes.filter(_ => _.id !== payload)
      }
    case TOGGLE_CREATE_SLOT_TYPE:
      return {
        ...state,
        isCreatingSlotType: !state.isCreatingSlotType
      }
    case TOGGLE_UPDATE_SLOT_TYPE:
      return {
        ...state,
        isUpdatingSlotType: !!payload,
        slotTypeOnUpdate: payload
      }
    case TOGGLE_DELETE_SLOT_TYPE:
      return {
        ...state,
        isDeletingSlotType: !!payload,
        slotTypeOnDelete: payload
      }
    case CHANGE_LIMIT:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          pageLimit: +payload,
          currentPage: 1
        }
      }
    case CHANGE_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: payload
        }
      }
    case SEARCH_SLOT_TYPE:
      return {
        ...state,
        searchQuery: payload,
        pagination: {
          ...state.pagination,
          currentPage: initialState.pagination.currentPage,
          offset: initialState.pagination.offset,
        }
      }
    default:
      return state
  }
}
