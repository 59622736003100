import {
  ADD_FORM_FORM_LIST,
  CHANGE_LIMIT_FORM_LIST,
  CHANGE_PAGE_FORM_LIST,
  DELETE_FORM_LIST,
  ERROR_FORM_LIST_ADD,
  FORM_LIST_ADDED,
  LOAD_FORM_LIST,
  RECEIVE_FORM_LIST,
  SEARCH_FORM_LIST, SET_FORM_LOCKED,
  SET_FORM_UNLOCKED_SUCCESS,
  SET_TO_DELETE_FORM_LIST,
  TOGGLE_ADD_FORM_FORM_LIST
} from './actionTypes'

export const initialState = {
  loading: false,
  addingForm: false,
  formToDelete: '',
  addFormError: [],
  addFormDialogOpened: false,
  forms: [],
  pagination: {
    offset: 0,
    limit: 50,
    itemsCount: 50,
    totalItemsCount: 50,
    pageLimit: 50,
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPagesCount: 1,
  },
}

export default (state = initialState, {
  type,
  payload
}) => {
  switch (type) {
    case LOAD_FORM_LIST:
      return {
        ...state,
        loading: true
      }
    case RECEIVE_FORM_LIST:
      return {
        ...state,
        loading: false,
        forms: payload.data,
        pagination: payload.pagination
      }
    case TOGGLE_ADD_FORM_FORM_LIST:
      return {
        ...state,
        addFormDialogOpened: !state.addFormDialogOpened
      }
    case CHANGE_LIMIT_FORM_LIST:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          pageLimit: Number(payload),
          currentPage: 1
        }
      }
    case CHANGE_PAGE_FORM_LIST:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: payload
        }
      }
    case ADD_FORM_FORM_LIST:
      return {
        ...state,
        addingForm: true,
        addFormError: []
      }
    case FORM_LIST_ADDED:
      return {
        ...state,
        addingForm: false
      }
    case ERROR_FORM_LIST_ADD:
      return {
        ...state,
        addingForm: false,
        addFormError: payload
      }
    case SEARCH_FORM_LIST:
      return {
        ...state,
        loading: true,
        pagination: {
          ...state.pagination,
          currentPage: initialState.pagination.currentPage,
          offset: initialState.pagination.offset,
        }
      }
    case SET_TO_DELETE_FORM_LIST:
      return {
        ...state,
        formToDelete: payload
      }
    case DELETE_FORM_LIST:
      return {
        ...state,
        formToDelete: ''
      }
    case SET_FORM_LOCKED: {
      const formIndex = state.forms.findIndex(form => form.id === payload.formId)

      if (formIndex === -1)
        return state

      const forms = [ ...state.forms ]

      forms[formIndex] = {
        ...forms[formIndex],
        locked: payload.locked
      }

      return { ...state, forms }
    }
    case SET_FORM_UNLOCKED_SUCCESS: {
      const formIndex = state.forms.findIndex(form => form.id === payload.formId)

      if (formIndex === -1)
        return state

      const forms = [ ...state.forms ]

      forms[formIndex] = {
        ...forms[formIndex],
        locked: false
      }

      return { ...state, forms }
    }
    default:
      return state
  }
}
