import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
  FETCH_ITEMS, FETCH_ITEM,
  CREATE_ITEM,
  DELETE_ITEM,
  UPDATE_ITEM,
  FETCH_ITEMS_EVERYBODY_TASKS,
  FETCH_ITEMS_MY_TASKS,
  SORT_TASKS
} from './actionTypes'
import {
  itemUpdated,
  itemDeleted,
  itemCreated,
  itemsReceived,
  itemReceived,
  onSuccessResponse,
  onErrorResponse
} from './actions'
import apiMethods from '../../../Services/api/apiMethods'
import { contentRangeToPagination } from 'src/Utils/EffectsUtil.ts'

const STORE = 'Tasks'

function* updateItem(props, { payload }) {
  try {
    const item = (yield select())[STORE].updatingItem
    let idItem = item.id
    delete item.id
    const { data } = yield call(apiMethods.update, `/tasks/${ idItem }`, { data: item })

    yield put(itemUpdated(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'updateFailed'))
  }
}

function* deleteItem(props, { payload }) {
  try {
    const { id } = (yield select())[STORE].deletingItem
    yield call(apiMethods.delete, `/tasks/${ id }`)

    yield put(itemDeleted(id))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'deleteFailed'))
  }
}

function* createItem(props, { payload }) {
  try {
    const item = (yield select())[STORE].creatingItem
    const { data } = yield call(apiMethods.create, '/tasks', { data: item })
    yield put(itemCreated(data))
    yield put(onSuccessResponse())

  } catch (error) {
    yield put(props.globalActions.handleError(error, 'createFailed'))
    yield put(onErrorResponse(error))
  }
}

function* fetchItems(props, { payload }) {
  try {
    let url = '/tasks'
    if (payload && payload.url) {
      url = payload.url
    }
    const { query, sort, pagination } = (yield select())[STORE]

    const { data, headers } = yield call(apiMethods.get, url, {
      query, ...pagination, sortDir: sort.direction, sortCol: sort.column
    })

    yield put(itemsReceived({
      items: data,
      pagination: contentRangeToPagination(headers['content-range'], pagination)
    }))
  } catch (error) {
    yield put(props.globalActions.handleError(error))
  }
}

function* fetchItem(props, { payload }) {
  try {
    let { data } = yield call(apiMethods.get, `/tasks/${ payload }`)
    yield put(itemReceived(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'fetchFailed'))
  }
}

export default function* modulesSagaWatcher(props) {
  yield takeLatest(FETCH_ITEMS, fetchItems, props)
  yield takeLatest(SORT_TASKS, fetchItems, props)
  yield takeLatest(FETCH_ITEMS_MY_TASKS, fetchItems, props)
  yield takeLatest(FETCH_ITEMS_EVERYBODY_TASKS, fetchItems, props)
  yield takeLatest(FETCH_ITEM, fetchItem, props)
  yield takeLatest(FETCH_ITEM, fetchItem, props)
  yield takeLatest(CREATE_ITEM, createItem, props)
  yield takeLatest(DELETE_ITEM, deleteItem, props)
  yield takeLatest(UPDATE_ITEM, updateItem, props)
}
