import {
  ADD_COLUMN,
  ADD_FILTER,
  SAVE_ZMR, SET_COLUMN_BEING_EDITED,
  SET_ADD_COLUMN_STATUS, SET_ADD_FILTER_STATUS, SET_COLUMN_BEING_REMOVED, SET_COLUMNS, SET_DESCRIPTION,
  SET_DRAGGED_COLUMN, SET_FILTER_BEING_REMOVED, SET_FILTERS, SET_IS_ADD_FILTER_MODAL_OPENED,
  SET_OPERATORS,
  SET_SELECTED_REFERENCE_FIELD,
  SET_SHOW_ADD_BUTTON_OPTION,
  SET_TYPE,SET_LABEL,
  SET_ZMR, SET_SELECTED_REFERENCE_FIELD_DETAILS,
  SET_ZMR_SAVE_STATUS, SET_COLUMN_BEING_CREATED, RESET
} from './actionTypes'
import { SaveStatus } from 'src/Components/SaveButton'

export const initialState = {
  loading: true,
  saveStatus: SaveStatus.DISABLED,
  id: '',
  formId: '',
  form: null,
  refForm: null,
  label: '',
  systemName: '',
  description: '',
  type: '',
  showAddButton: false,
  columns: [],
  filters: [],
  selectedReferenceField: null,
  selectedReferenceFieldDetails: null,
  isUpsertColumnModalOpened: false,
  addColumnStatus: '',
  draggedColumn: '',
  columnBeingCreated: null,
  columnBeingEdited: null,
  columnBeingRemoved: null,
  filterBeingRemoved: '',
  isAddFilterModalOpened: false,
  operators: [],
  addFilterStatus: ''
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case RESET:
      return {
        ...initialState
      }
    case SET_ZMR:
      return {
        ...state,
        ...payload,
        loading: false
      }
    case SET_LABEL:
      return {
        ...state,
        label: payload
      }
    case SET_DESCRIPTION:
      return {
        ...state,
        description: payload
      }
    case SET_TYPE:
      return {
        ...state,
        type: payload
      }
    case SAVE_ZMR:
      return {
        ...state,
        saveStatus: SaveStatus.ONGOING
      }
    case SET_ZMR_SAVE_STATUS:
      return {
        ...state,
        saveStatus: payload
      }
    case SET_SELECTED_REFERENCE_FIELD:
      return {
        ...state,
        selectedReferenceField: payload
      }
    case ADD_COLUMN:
      return {
        ...state,
        addColumnStatus: 'pending'
      }
    case ADD_FILTER:
      return {
        ...state,
        addFilterStatus: 'pending'
      }
    case SET_ADD_COLUMN_STATUS:
      return {
        ...state,
        addColumnStatus: payload
      }
    case SET_DRAGGED_COLUMN:
      return {
        ...state,
        draggedColumn: payload
      }
    case SET_SHOW_ADD_BUTTON_OPTION:
      return {
        ...state,
        showAddButton: payload
      }
    case SET_COLUMN_BEING_CREATED:
      return {
        ...state,
        columnBeingCreated: payload
      }
    case SET_COLUMN_BEING_EDITED:
      return {
        ...state,
        columnBeingEdited: payload
      }
    case SET_COLUMN_BEING_REMOVED:
      return {
        ...state,
        columnBeingRemoved: payload
      }
    case SET_IS_ADD_FILTER_MODAL_OPENED:
      return {
        ...state,
        isAddFilterModalOpened: payload
      }
    case SET_OPERATORS:
      return {
        ...state,
        operators: payload
      }
    case SET_ADD_FILTER_STATUS:
      return {
        ...state,
        addFilterStatus: payload
      }
    case SET_FILTERS:
      return {
        ...state,
        filters: payload
      }
    case SET_SELECTED_REFERENCE_FIELD_DETAILS:
      return {
        ...state,
        selectedReferenceFieldDetails: payload
      }
    case SET_COLUMNS:
      return {
        ...state,
        columns: payload
      }
    case SET_FILTER_BEING_REMOVED:
      return {
        ...state,
        filterBeingRemoved: payload
      }
    default:
      return state
  }
}
