
export default {
  english: {
    title: 'About',
    contact: 'Contact',
    version: 'Version',
    apiDeploymentVersion: 'API deployment version',
    apiDeploymentDate: 'API deployment date',
    frontDeploymentVersion: 'Front deployment version',
    frontDeploymentDate: 'Front deployment date',
    buildIdentifier: 'Build Identifier',
    configuration: 'Configuration',
    legalNotices: 'Legal notices',
    gtu: 'General terms of use (GTU)',
    legalNotice: 'Legal notice',
    privacyPolicy: 'Privacy policy',
    userGuide: 'User guide',
    fetchVersionFailed: 'Error while fetching the application version.'
  },
  french: {
    title: 'À propos',
    contact: 'Contact',
    version: 'Version',
    apiDeploymentVersion: 'Version de déploiement API',
    apiDeploymentDate: 'Date de déploiement API',
    frontDeploymentVersion: 'Version de déploiement de l\'interface',
    frontDeploymentDate: 'Date de déploiement de l\'interface',
    buildIdentifier: 'Identifiant de Build',
    configuration: 'Configuration',
    legalNotices: 'Mentions légales',
    gtu: 'Conditions générales d\'utilisation (CGU)',
    legalNotice: 'Avis juridique',
    privacyPolicy: 'Politique de confidentialité',
    userGuide: 'Guide utilisateur',
    fetchVersionFailed: 'Erreur lors de la récupération de la version de l\'application'
  }
}
