import React from 'react'
import { Form as BForm, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import EntitySelect from 'src/Components/EntitySelect'
import { showWarning } from 'src/Services/notifier/actions'
import { setDeletingItem } from '../state/actions'

const Form = ({
  trans, onSubmit = () => {}, item = null
}) => {
  const dispatch = useDispatch()
  const { roles } = useSelector(state => state.Dictionary)

  const onFormSubmit = e => {
    e.preventDefault()

    const item = {
      sortOrder: parseInt(e.target.sortOrder.value, 10) || 0,
      email: e.target.email.value,
      trigger: e.target.trigger.value,
      action: e.target.action.value,
      role: e.target.role.value !== 'null' ? e.target.role.value : null,
      user: e.target.user.value || null
    }

    if (!item.trigger || !item.action)
      return dispatch(showWarning('formIncomplete'))

    onSubmit(item)
  }

  const onDeleteClick = () => dispatch(setDeletingItem(item))

  return <BForm onSubmit={ onFormSubmit }>
    <Row className={ 'mb-2' }>
      <Col>
        <BForm.Group>
          <BForm.Label>{ trans('form.email') }</BForm.Label>
          <BForm.Control defaultValue={ item?.email } name={ 'email' } placeholder={ trans('form.email') } />
          <small className={'text-muted'}>{ trans('form.emailHelp') }</small>
        </BForm.Group>
      </Col>
      <Col>
        <BForm.Group>
          <BForm.Label>{ trans('form.sortOrder') }</BForm.Label>
          <BForm.Control defaultValue={ item?.sortOrder || 0 } name={ 'sortOrder' } type={ 'number' }
                         placeholder={ trans('form.sortOrder') }/>
        </BForm.Group>
      </Col>
    </Row>

    <Row className={ 'mb-2' }>
      <Col>
        <BForm.Group>
          <BForm.Label>{ trans('form.role') }</BForm.Label>
          <BForm.Select defaultValue={ item?.role?.id || '' } name={ 'role' } aria-label={ trans('form.role') }>
            <option value={ 'null' }>{ trans('none') }</option>
            { roles.map(({ id, name }) => <option key={ id } value={ id }>{ name }</option>) }
          </BForm.Select>
        </BForm.Group>
      </Col>
      <Col>
        <BForm.Group>
          <BForm.Label>{ trans('form.user') }</BForm.Label>
          <EntitySelect value={ item?.user || null }
                        name={ 'user' }
                        clearable={ true }
                        id={ 'workflowItemFormUserSelect' }
                        fetchUrl={ '/users' }
                        labelKey={ 'username' }
          />
        </BForm.Group>
      </Col>
    </Row>

    <Row className={ 'mb-2' }>
      <Col>
        <BForm.Group>
          <BForm.Label>{ trans('form.trigger') }</BForm.Label>
          <EntitySelect value={ item?.trigger || null }
                        name={ 'trigger' } required
                        id={ 'workflowItemFormTriggerSelect' }
                        fetchUrl={ '/workflow/triggers' }
          />
        </BForm.Group>
      </Col>
      <Col>
        <BForm.Group>
          <BForm.Label>{ trans('form.action') }</BForm.Label>
          <EntitySelect value={ item?.action || null }
                        name={ 'action' } required
                        id={ 'workflowItemFormActionSelect' }
                        fetchUrl={ '/workflow/actions' }
          />
        </BForm.Group>
      </Col>
    </Row>

    <Row className="mt-2">
      <Col>
        <Button type={'button'} variant={'danger'} onClick={onDeleteClick}>
          { trans('delete') }
          <i className="ms-2 bi bi-trash3"></i>
        </Button>
      </Col>
      <Col>
        <Button variant={ 'success' } style={ { float: 'right' } } type={ 'submit' }>
          { trans('submit') }
          <i className="ms-2 bi bi-check-circle"></i>
        </Button>
      </Col>
    </Row>
  </BForm>
}

export default Form
