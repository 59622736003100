import { pipe, path } from 'ramda'

// getEntityId :: State -> String
export const getEntityId = entityName => pipe(
  path([ 'location', 'pathname' ]),
  pathname => pathname.split('/'),
  pathChunks => [ pathChunks, pathChunks.findIndex(v => v === entityName) ],
  ([ pathChunks, instanceIndex ]) => {
    if (instanceIndex > -1)
      return parseInt(pathChunks[instanceIndex + 1], 10) || ''

    return ''
  }
)

// getIsFormFillerCalendarRoute :: State -> Boolean
export const getIsFormFillerCalendarRoute = pipe(
  path([ 'pathname' ]),
  pathname => (/(\bpatient\b)(.*)(\bcalendar\b)/).test(pathname)
)

export const getFormFillerOpenedPane = location => {
  const pathname = location?.pathname
  const [ nothing, firstPart, firstPartId, secondPart, secondPartId ] = pathname.split(/[/|?]/)

  //return firstPart === 'patient' && firstPartId === secondPartId ? 'patientInstance' : secondPart
  return secondPart
}
