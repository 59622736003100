import { ListGroup } from 'react-bootstrap'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomizationOrderForm from './CustomizationOrderForm'
import { createCustomizationOrder, updateCustomizationOrder } from '../state/actions'
import CustomizationOrderDisplay from './CustomizationOrderDisplay'

const DEFAULT_ORDER = {
  action: null,
  targetFields: [],
  conditions: []
}

const CustomizationConfigurator = ({ field, trans }) => {
  const dispatch = useDispatch()

  const { form, isCustomizationOrderLoading } = useSelector(state => state.FormEditor.formReducer)

  const [ formItem, setFormItem ] = React.useState(null)

  useEffect(() => {
    if (isCustomizationOrderLoading === false)
      setFormItem(null)
  }, [ isCustomizationOrderLoading ])

  const onNewOrderClick = e => {
    setFormItem(DEFAULT_ORDER)
  }

  const onSubmit = item =>
    dispatch(item.id ? updateCustomizationOrder(item) : createCustomizationOrder(item))

  return <>
    { formItem && <CustomizationOrderForm trans={trans} item={formItem} onSubmit={onSubmit} /> }

    <ListGroup as={'ol'} className={'my-2'}>
      { !formItem && <ListGroup.Item as={ 'li' } onClick={ onNewOrderClick }
                      className="d-flex justify-content-between cursor-pointer bg-primary text-white">
        <strong>{ trans('add') }</strong>
        <i className="fas fa-lg fa-plus-circle"/>
      </ListGroup.Item> }
      { form.customizationOrders.map((customizationOrder, i) => (
        <ListGroup.Item as={ 'li' } key={ i }>
          <CustomizationOrderDisplay trans={trans} order={customizationOrder} onUpdateRequest={ setFormItem } />
        </ListGroup.Item>
      )) }
    </ListGroup>
  </>
}

export default CustomizationConfigurator
