import * as React from 'react'
import { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Form,
  OverlayTrigger,
  Table,
  Tooltip,
} from 'react-bootstrap'
import Modal from 'src/Components/Modal'
import { translate } from 'src/Services/translation'
import { getSystemFieldReferenceStartingBySystemName } from 'src/Services/Selectors'
import {
  FIELD_MS_SANTE_RECIPIENTS_PREFIX, FIELD_MS_SANTE_RECIPIENTS_PREFIX_FIRST_NAME,
  FIELD_MS_SANTE_RECIPIENTS_PREFIX_LAST_NAME, FIELD_MS_SANTE_RECIPIENTS_PREFIX_TELECOM,
} from 'src/Services/Constants/MsSante'
import { CloudUpload } from 'src/Types/CloudUpload'
import { StoreState } from 'src/Services/Store/reducers'
import translation from 'src/Views/FormFiller/translations'
import {
  fetchMsSanteContentsToSend, fetchMsSanteRecipients,
  sendMsSanteDocuments, toggleMsSanteContentsFetching,
  toggleMsSanteModal, toggleMsSanteModalLoading, toggleMsSanteRecipientsFetching,
} from '../state/actions'
import { MsSanteRecipient } from '../Types/MsSante'

const MsSanteModal = () => {
  const dispatch = useDispatch()

  const { language } = useSelector((state: StoreState) => state.Root.user)
  const { openedInstance, openedInstanceSystemFields: systemFields }
      = useSelector((state: StoreState) => state.FormFiller)
  const {
    isMsSanteModalOpen,
    msSanteModalLoading,
    isMsSanteModalRecipientsFetching,
    isMsSanteModalContentsFetching,
    recipients,
    contentsToSend,
  } = useSelector((state: StoreState) => state.FormFiller.msSante)

  const [ selectedRecipients, setSelectedRecipients ] = useState<string[]>([])
  const [ selectedFilesToSend, setSelectedFilesToSend ] = useState<string[]>([])

  const trans = translate(translation)(language)

  useEffect(() => {
    if (isMsSanteModalOpen && openedInstance.id) {
      dispatch(toggleMsSanteRecipientsFetching(true))
      dispatch(toggleMsSanteContentsFetching(true))
      dispatch(fetchMsSanteRecipients(openedInstance.id))
      dispatch(fetchMsSanteContentsToSend(openedInstance.id))
    }
    setSelectedRecipients([])
  }, [ isMsSanteModalOpen ])

  useEffect(() => {
    if (contentsToSend) {
      for (const content of contentsToSend) {
        !selectedFilesToSend.includes(content.id) &&
        setSelectedFilesToSend(selectedFilesToSend => [ ...selectedFilesToSend, content.id ])
      }
    }
  }, [ contentsToSend ])

  const onRecipientClick = (recipient: MsSanteRecipient, e: ChangeEvent<HTMLInputElement>) => {
    e.target.checked
    ? setSelectedRecipients(selectedRecipients => [ ...selectedRecipients, recipient.instanceId ])
    : setSelectedRecipients(selectedRecipients.filter(selectedRecipient => selectedRecipient !== recipient.instanceId))
  }

  const onFileClick = (file: CloudUpload, e: ChangeEvent<HTMLInputElement>) => {
    e.target.checked
    ? setSelectedFilesToSend(selectedFilesToSend => [ ...selectedFilesToSend, file.id ])
    : setSelectedFilesToSend(selectedFilesToSend.filter(selectedFileToSend => selectedFileToSend !== file.id))
  }


    const onSendClick = () => {
        dispatch(toggleMsSanteModalLoading(true))
        dispatch(sendMsSanteDocuments(
            openedInstance.id,
            selectedRecipients,
            selectedFilesToSend
        ))
    }

  const isEnabledSendBtn = () => {
    return !!selectedRecipients.length === false ||
        !!selectedFilesToSend.length === false || selectedRecipients.length > 10
  }


  return (
      <Modal isOpen={ isMsSanteModalOpen }
             title={ trans('modal.msSanteModal.title') }
             onClose={ () => dispatch(toggleMsSanteModal(false)) }
             size={ 'lg' }
             modalClass={ 'text-primary border border-primary border-1' }
             animation={ 'false' }
      >
        <Form>
          <h5>{ trans('modal.msSanteModal.recipients.title') }</h5>
          { isMsSanteModalRecipientsFetching
           ? <i className={ 'fa-solid fa-circle-notch fa-spin ms-2' }></i>
           : <Table striped bordered hover>
             <thead>
             <tr>
               <th></th>
               <th>{ trans('modal.msSanteModal.recipients.email') }</th>
               <th>{ trans('modal.msSanteModal.recipients.lastname') }</th>
               <th>{ trans('modal.msSanteModal.recipients.firstname') }</th>
             </tr>
             </thead>
             <tbody>
             { systemFields.length && recipients.map((recipient, index) => (
                 getSystemFieldReferenceStartingBySystemName(
                     systemFields, FIELD_MS_SANTE_RECIPIENTS_PREFIX,
                 ).map(reference => (
                     FIELD_MS_SANTE_RECIPIENTS_PREFIX_TELECOM + '#' + reference.name in recipient &&
                     <tr key={ index }>
                       <td>
                         {
                           null === recipient[FIELD_MS_SANTE_RECIPIENTS_PREFIX_TELECOM + '#' + reference.name]
                           ? <OverlayTrigger
                               key={ 'top' }
                               placement={ 'top' }
                               overlay={
                                 <Tooltip id={ `field_${ index }_tooltip` }>
                                   { trans('modal.msSanteModal.recipients.disabled') }
                                 </Tooltip>
                               }
                           >
                             <div>
                               <Form.Check
                                   id={ `select_${ recipient.id }` }
                                   name={ 'recipient' }
                                   disabled={ true }
                               />
                             </div>

                           </OverlayTrigger>
                           : <Form.Check
                               id={ `select_${ recipient.id }` }
                               disabled={ msSanteModalLoading }
                               defaultValue={ recipient[FIELD_MS_SANTE_RECIPIENTS_PREFIX_TELECOM + '#' + reference.name] }
                               onChange={ e => onRecipientClick(recipient, e) }
                           />
                         }
                       </td>
                       <td>{ recipient[FIELD_MS_SANTE_RECIPIENTS_PREFIX_TELECOM + '#' + reference.name] }</td>
                       <td>{ recipient[FIELD_MS_SANTE_RECIPIENTS_PREFIX_LAST_NAME + '#' + reference.name] }</td>
                       <td>{ recipient[FIELD_MS_SANTE_RECIPIENTS_PREFIX_FIRST_NAME + '#' + reference.name] }</td>
                     </tr>
                 ))
             )) }
             </tbody>
           </Table>
          }
          <h5 className={ 'mt-4' }>{ trans('modal.msSanteModal.files.title') }</h5>
          {
            isMsSanteModalContentsFetching
            ? <i className={ 'fa-solid fa-circle-notch fa-spin ms-2' }></i>
            : <Table striped bordered hover>
              <tbody>
              {
                contentsToSend.map((file: CloudUpload, index: number) => <tr key={ index }>
                                     <td colSpan={ 4 }>
                                       <Form.Check
                                           id={ `select_${ index }` }
                                           name={ 'file' }
                                           label={ file.name }
                                           disabled={ msSanteModalLoading }
                                           onChange={ e => onFileClick(file, e) }
                                           defaultChecked={ true }
                                       />
                                     </td>
                                   </tr>,
                )
              }
              </tbody>
            </Table>
          }
          <div className={ 'd-flex justify-content-end' }>
            <Button
                variant={ 'outline-danger' }
                type={ 'button' }
                onClick={ () => dispatch(toggleMsSanteModal(false)) }
            >
              { trans('modal.msSanteModal.cancelBtn') }
            </Button>
            <Button
                variant={ 'success' }
                className={ 'ms-2' }
                type={ 'button' }
                onClick={ onSendClick }
                disabled={ msSanteModalLoading || isEnabledSendBtn() }
            >
              { trans('modal.msSanteModal.sendBtn') }
              {
                  msSanteModalLoading &&
                  <i className={ 'fa-solid fa-circle-notch fa-spin ms-2' }></i>
              }
            </Button>
          </div>

        </Form>
      </Modal>
  )
}

export default MsSanteModal
