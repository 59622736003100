import React, { FC  } from 'react'
import { Form } from 'react-bootstrap'

interface LimitWrapperProps {
  className?: string
  name?: string
  limit: number
  onLimitChange: (limit: number) => void
  totalMsg: string
}

const LimitWrapper: FC<LimitWrapperProps> = ({
  limit = 10,
  name = 'page-size',
  onLimitChange = () => false,
  totalMsg = ''
}) => (
  <div className="limit-wrapper">
    <Form.Group style={{ maxWidth: '70px' }}>
      <Form.Select name={name} onChange={ e => onLimitChange(Number(e.target.value)) } value={limit} size={'sm'}>
        <option>10</option>
        <option>25</option>
        <option>50</option>
        <option>100</option>
      </Form.Select>
    </Form.Group>
    <div className="total-count">{totalMsg}</div>
  </div>
)

export default LimitWrapper
