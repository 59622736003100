import {
  FETCH_FORM_SYSTEM_FIELDS,
  SET_FORM_SYSTEM_FIELDS,
  TOGGLE_FORM_MODAL,
  FETCH_REMOVE_SYSTEM_FIELD,
  FETCH_ADD_SYSTEM_FIELD,
  REMOVE_DELETED_SYSTEM_FIELD,
  ADD_NEW_SYSTEM_FIELD,
  SYSTEM_FIELD_LIMIT_CHANGE,
  SYSTEM_FIELD_PAGE_CHANGE, SET_FORM_ID, TOGGLE_LOADING
} from './actionTypes'

export const fetchFormSystemFields = id => ({
  type: FETCH_FORM_SYSTEM_FIELDS,
  payload: { id }
})

export const setSystemFields = data => ({
  type: SET_FORM_SYSTEM_FIELDS,
  payload: data
})

export const fetchRemoveSystemField = data => ({
  type: FETCH_REMOVE_SYSTEM_FIELD,
  payload: data
})

export const toggleLoading = data => ({
  type: TOGGLE_LOADING,
  payload: data
})

export const fetchAddSystemField = data => ({
  type: FETCH_ADD_SYSTEM_FIELD,
  payload: data
})

export const toggleFormModal = () => ({
  type: TOGGLE_FORM_MODAL
})

export const removeDeletedSystemField = data => ({
  type: REMOVE_DELETED_SYSTEM_FIELD,
  payload: data
})

export const addNewSystemField = data => ({
  type: ADD_NEW_SYSTEM_FIELD,
  payload: data
})

export const changeLimit = data => ({
  type: SYSTEM_FIELD_LIMIT_CHANGE,
  payload: data
})
export const changePage = data => ({
  type: SYSTEM_FIELD_PAGE_CHANGE,
  payload: data
})
export const setFormId = id => ({
  type: SET_FORM_ID,
  payload: id
})
