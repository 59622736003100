import React, { useState } from 'react'
import { setPage, setQuery, setLimit, fetchItems, sortTasks } from '../../Tasks/state/actions'
import { translate } from '../../../Services/translation'
import translation from '../../Tasks/translations'
import NavTable from '../../../Components/NavTable'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ButtonGroup } from 'react-bootstrap'
import { renderValuesIntoString } from '../../Tasks'

const TasksPageTable = ({ onUpdateClick, onDeleteClick, ...props }) => {
  const dispatch = useDispatch()

  const { sort, items, isLoading, pagination } = useSelector(state => state.Tasks)

  const trans = translate(translation)(props?.language)
  const [ fetchListRequest, setFetchListRequest ] = useState(null)

  const columnHeaders = [
    trans('name'),
    trans('description'),
    trans('roles'),
    trans('users'),
    trans('type'),
    trans('categories'),
    trans('actions')
  ]
  const rows = getRows(items, trans, onDeleteClick, onUpdateClick)

  const applyNewFilters = () => {
    clearTimeout(fetchListRequest)
    setFetchListRequest(dispatch(fetchItems()))
  }

  const onSearchInputChange = e => {
    e.preventDefault()
    dispatch(setQuery(e.target.value))
    applyNewFilters()
  }

  const onPageClick = (currentPage, page) => {
    if (currentPage === page)
      return

    dispatch(setPage(page))
    applyNewFilters()
  }

  const onLimitChange = limit => {
    dispatch(setLimit(limit))
    applyNewFilters()
  }

  // onSearchInputChange :: (Action -> *) -> Event -> *
  const onSortTaskClick = (field, order) => {
    dispatch(sortTasks({
      field,
      order
    }))
  }

  const columnSort = [ 'name', '', '', '', '', '', '' ]

  return <NavTable rows={ rows }
                   pagination={ pagination }
                   onSearchInputChange={ onSearchInputChange }
                   onLimitChange={ onLimitChange }
                   onPageClick={ onPageClick }
                   isLoading={ isLoading }
                   onSortUserClick={ onSortTaskClick }
                   sortParams={ sort }
                   columnHeaders={ columnHeaders }
                   columnSort={ columnSort }
  />
}

export const ActionCell = ({
  onDeleteClick = () => {
  },
  onEditClick = () => {
  },
  trans
}) =>
  <ButtonGroup>
    <Button variant={ 'outline-info' } size={ 'sm' } onClick={ onDeleteClick }>
      <i className="fas fa-trash"/> { trans('delete') }
    </Button>
    <Button variant={ 'info' } size={ 'sm' } onClick={ onEditClick }>
      <i className="fas fa-edit"/> { trans('edit') }
    </Button>
  </ButtonGroup>

const getRows = (
  items,
  trans,
  onDeleteClick,
  onUpdateClick
) =>
  items.map((item, index) => {
    let rolesData = item.roles ? renderValuesIntoString('roles', item.roles) : ''
    let usersData = item.users ? renderValuesIntoString('users', item.users) : ''

    return [
      <>{ item.name }</>,
      <>{ item.description }</>,
      <>{ rolesData ?? 'NC' }</>,
      <>{ usersData ?? 'NC' }</>,
      <>{ item?.category?.name ?? 'NC' }</>,
      <>{ item?.type?.name ?? 'NC' }</>,
      <ActionCell
        onDeleteClick={ e => onDeleteClick(e, item) }
        onEditClick={ e => onUpdateClick(e, item) }
        trans={ trans }
      />
    ]
  })

export default TasksPageTable
