import React, { MouseEvent, useEffect, useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { executeScript } from 'src/Services/ScriptExecutor/state/actions'
import { useDispatch, useSelector } from 'react-redux'
import { translateConf } from 'src/Services/translation'
import { handleError, logMessage } from 'src/Services/Store/Root/actions'
import { useNavigate } from 'react-router-dom'
import apiMethods from 'src/Services/api/apiMethods'
import { SWP_OPEN_MONITOR_FIELD_SYSTEM_NAME, SWP_OPEN_PLAYBACK_FIELD_SYSTEM_NAME, SWP_OPEN_RECORD_FIELD_SYSTEM_NAME } from 'src/Services/Constants/Swp'
import { StoreState } from 'src/Services/Store/reducers'
import { FieldEvent } from 'src/Views/FormFiller/Types/Field'
import { BaseFieldType, Field, FieldOptionAutocompleteField, FieldOptionAutocompleteFieldDynamicValue, FieldOptionAutocompleteFieldType } from 'src/Types/Field'
import { ButtonSize } from 'src/Views/FormFiller/Types/Size'
import { FieldOption } from 'src/Types/FieldOption'
import SystemField from 'src/Types/SystemField'
import { getSpecificFields } from 'src/Views/FormFiller/utils/Fields'
import { getFieldBySystemName } from 'src/Views/FormFiller/state/selectors'
import { SwpFeatureStatus } from 'src/Views/FormFiller/Types/Swp'
import { changeFieldValue, fieldEvent, openSwpMonitor, openSwpPlayback, openSwpRecord, saveInstanceFieldValue } from '../../../state/actions'
import ConsentFieldsModal from '../../ConsentFieldsModal'

export interface SimpleHTMLEventTarget {
  id: string
  className: string
}

export interface SimpleHTMLEvent {
  target?: SimpleHTMLEventTarget
}

interface Props {
  field: Field,
  isLoading?: boolean
  isDisable?: boolean
  size?: ButtonSize
}

const GenericButtonField = ({
                              field = null,
                              isLoading = false,
                              isDisable = false,
                              size = ButtonSize.sm,
                            }: Props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const state = useSelector((state: StoreState) => state.FormFiller)
  const { configTranslations } = useSelector((state: StoreState) => state.Root)

  const { openedInstance, openedInstanceSystemFields, swpFeatureStatus } = state
  const instanceId = openedInstance?.id

  const [ isConsentFieldsSubjected, setIsConsentFieldsSubjected ] = useState(false)
  const [ isModalConsentFieldsOpen, setIsModalConsentFieldsOpen ] = useState(false)
  const [ isClickActionsInProgress, setIsClickActionsInProgress ] = useState(false)
  const [ consentFields, setConsentFields ] = useState([])

  const transConf = translateConf(configTranslations)

  useEffect(() => {
    const buttonConsentFields = field.options.consentFields
    setIsConsentFieldsSubjected(!!buttonConsentFields?.length)

    if (buttonConsentFields?.length) {
      /**
       * Was :
       * .filter(field => buttonConsentFields.includes(field.systemName))
       * Instead of current map
       * May be a potential fix if there is an issue with consent fields
       */
      setConsentFields(
          getSpecificFields(openedInstance.fields, BaseFieldType.CONSENT).map(
              field => {
                buttonConsentFields.includes(field.systemName)
                return {
                  ...field,
                  value: field.value,
                }
              },
          ),
      )
    }
  }, [ openedInstance.fields ])

  const getAssociatedSystemFields = (): SystemField[] => openedInstanceSystemFields.filter(sf => sf.field.id === field.id)
  const isButtonSystemFieldNameStartsWith = (startsWith: string) => getAssociatedSystemFields().some(sf => sf.name.startsWith(startsWith))

  const onButtonClick = async (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()

    setIsClickActionsInProgress(true)

    const target = (e.target as HTMLInputElement)

    // Minimize payload size by picking necessary event data
    const event: SimpleHTMLEvent = {
      target: {
        id: target.id,
        className: target.className,
      },
    }

    dispatch(saveInstanceFieldValue(
        instanceId,
        field.id,
        { clickedAt: new Date() },
        true,
    ))
    dispatch(fieldEvent(field, FieldEvent.CLICK, event))

    await onClickActions(event)

    setIsClickActionsInProgress(false)
  }

  const onClickActions = async (event: SimpleHTMLEvent = null, skipConsent = false) => {

    setIsClickActionsInProgress(true)

    if (!skipConsent && isConsentFieldsSubjected)
      return doConsentProcess()

    if (field.options?.[FieldOption.REDIRECT_HOME_PAGE]) {
      navigate('/')
      return
    }

    if (swpFeatureStatus === SwpFeatureStatus.READY) {
      if (isButtonSystemFieldNameStartsWith(SWP_OPEN_RECORD_FIELD_SYSTEM_NAME))
        dispatch(openSwpRecord())
      else if (isButtonSystemFieldNameStartsWith(SWP_OPEN_PLAYBACK_FIELD_SYSTEM_NAME))
        dispatch(openSwpPlayback())
      else if (isButtonSystemFieldNameStartsWith(SWP_OPEN_MONITOR_FIELD_SYSTEM_NAME))
        dispatch(openSwpMonitor())
    }

    if (event && field.javaScriptCode?.onClick)
      dispatch(executeScript(field.javaScriptCode.onClick, event))

    if (field.options?.[FieldOption.AUTOCOMPLETE_FIELDS])
      await processAutocomplete(field.options[FieldOption.AUTOCOMPLETE_FIELDS])
  }

  const doConsentProcess = () => {
    // compare required consent fields count with required consent fields that are checked but not yet accepted
    if (
      consentFields.filter(item => item.options.necessary).length ===
      consentFields.filter(
        item => item.options.necessary && (
          item.value !== null && item.value?.value !== null && !item.value?.accepted
        ),
      ).length
    ) {
      // if all required are all already checked acceptation is silent
      for (const field of consentFields) {
        dispatch(saveInstanceFieldValue(
          instanceId,
          field.id,
          { accepted: true, value: field.value?.value ?? null },
        ))
        dispatch(fieldEvent(field, FieldEvent.VALUE_CHANGE, {
          value: { accepted: true, value: field.value?.value ?? null },
        }))
      }

      // Retry actions ignoring consent this time
      onClickActions(null, true)

      // filter all required consent fields that are not checked (value is null)
      // or that are checked but not yet accepted
    } else if (
      consentFields.filter(
        item => item.options.necessary && (
          item.value === null || item.value?.value === null || !item.value?.accepted
        ),
      ).length
    ) {
      // if there are required fields not checked, modal is open
      setIsModalConsentFieldsOpen(true)
    } else {
      // Retry actions ignoring consent this time
      onClickActions(null, true)
    }
  }

  const fetchAutocompleteDynamicValues = async (
      fieldId: string,
  ): Promise<FieldOptionAutocompleteFieldDynamicValue[]> => {
    try {
      const { data } = await apiMethods.get(
          `/fields/${ fieldId }/options/autocomplete/dynamic_values`,
          { instanceId },
      )

      return data
    } catch (error) {
      dispatch(handleError(error))
      return []
    }
  }

  const processAutocomplete = async (autocompleteFields: FieldOptionAutocompleteField[]) => {

    const dynamicValues = autocompleteFields.some(af => af.type === FieldOptionAutocompleteFieldType.DYNAMIC)
                          ? await fetchAutocompleteDynamicValues(field.id) : null

    for (const autocompleteField of autocompleteFields) {
      const { field: fieldSystemName, type, value } = autocompleteField

      const field = getFieldBySystemName(state, fieldSystemName, false)

      if (!field?.id) {
        dispatch(logMessage(`Autocomplete field : can't find field with system name '${ fieldSystemName }'`, 'warning'))
        continue
      }

      if (type === FieldOptionAutocompleteFieldType.CONSTANT) {
        dispatch(changeFieldValue(field, value))
        continue
      }

      // Value is a system field in this case
      const systemField = value

      const dynamicValue = dynamicValues.find(dv => dv.id === systemField?.id)

      if (!dynamicValue) {
        dispatch(logMessage(`
        Autocomplete field : can't find target dynamic value with system field id '${ systemField?.id }'`, 'warning',
        ))
        continue
      }

      dispatch(changeFieldValue(field, dynamicValue.value))
    }
  }

  return <>
    <Button size={ size }
            variant={ 'info' }
            id={ `field${ field.id }` }
            onClick={ onButtonClick }
            disabled={ isDisable || isClickActionsInProgress }
    >
      { isLoading || isClickActionsInProgress &&
          <Spinner as={ 'span' }
                   animation={ 'border' }
                   size={ 'sm' }
                   role={ 'status' }
                   aria-hidden={ 'true' }
                   className={ 'me-2' }
          />
      }
      { transConf('FIELD')(field) }
    </Button>
    <ConsentFieldsModal onClose={ () => setIsModalConsentFieldsOpen(false) }
                        isOpen={ isModalConsentFieldsOpen }
                        fields={ consentFields }
                        onValid={ () => onClickActions(null, true) }
    />
  </>
}

export default GenericButtonField
