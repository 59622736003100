import * as type from './actionTypes'

export const load = payload => ({
  type: type.LOAD_LISTS,
  payload
})

export const receive = ({ lists, pagination }) => ({
  type: type.RECEIVE_LISTS,
  payload: { lists, pagination }
})

export const setSelectedListType = payload => ({
  type: type.SET_SELECTED_TYPE,
  payload
})

export const setSelectedListForm = payload => ({
  type: type.SET_SELECTED_LIST_FORM,
  payload
})


export const changeLimit = payload => ({
  type: type.CHANGE_LIMIT,
  payload
})


export const changePage = payload => ({
  type: type.CHANGE_PAGE,
  payload
})

export const toggleAddingList = payload => ({
  type: type.TOGGLE_ADDING_LIST,
  payload
})

export const addNewList = payload => ({
  type: type.ADD_NEW_LIST,
  payload
})

export const newListAdded = payload => ({
  type: type.NEW_LIST_ADDED,
  payload
})

export const deleteList = payload => ({
  type: type.DELETE_LIST,
  payload
})

export const errorDeletingList = payload => ({
  type: type.ERROR_DELETING_LIST,
  payload
})

export const listDeleted = payload => ({
  type: type.LIST_DELETED,
  payload
})

export const searchList = payload => ({
  type: type.SEARCH_LIST,
  payload
})

export const requestCloneList = list => ({
  type: type.REQUEST_CLONE_LIST,
  payload: { list }
})
export const cloneList = (id, data, navigate) => ({
  type: type.CLONE_LIST,
  payload: { id, data, navigate }
})
