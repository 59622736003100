import { Button, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import React, { ChangeEvent, useState } from 'react'
import { useSelector } from 'react-redux'
import { translate } from 'src/Services/translation'
import translation from 'src/Views/FormEditor/translations'
import FileConstraint from 'src/Types/FieldOption/FileConstraint'
import { isEqual } from 'lodash'
import { StoreState } from 'src/Services/Store/reducers'
import Select from 'react-select'

export interface Props {
  value: FileConstraint[] | null
  onChange: (v: FileConstraint[]) => null
}

const FileTypeSettings = ({ value, onChange }: Props) => {

  const { language } = useSelector((state: StoreState) => state.Root.user)
  const { allowedFileFormats } = useSelector((state: StoreState) => state.Dictionary)

  const [ constraints, setConstraints ] = useState<FileConstraint[]>(value || [])

  const trans = translate(translation)(language)

  React.useEffect(() => {
    if (!isEqual(value, constraints)) {
      setConstraints(value || [])
    }
  }, [ value ])

  React.useEffect(() => {
    if (value !== constraints)
      onChange(constraints)
  }, [ constraints ])

  const addConstraint = () =>
    setConstraints([ ...constraints, { min: 1, max: 1, fileType: allowedFileFormats || null } ])
  const removeConstraint = (constraintKey: number) => () =>
    setConstraints(constraints.filter((_, i) => i !== constraintKey))
  const onConstraintChange = (index: number | string, property: string) => (e: ChangeEvent<HTMLElement>) => {
    const newValue = (e as ChangeEvent<HTMLInputElement>).target.value
    setConstraints(constraints => constraints.map((c, i) => i === index ? {
      ...c,
      [property]: newValue,
    } : c))
  }

  const onFileTypeChange = (index: number) => (fileType: FileConstraint['fileType']) =>
    setConstraints(constraints => constraints.map((c, i) => i === index ? { ...c, fileType: fileType || [] } : c))

  return <div>
    { !!constraints.length &&
      <Form.Label className={ 'fw-bold' }>{ trans('editField.fileTypeSetting.title') }:</Form.Label> }
    { constraints.map((constraint, key) => (
      <div className={ (key % 2 === 0 ? 'bg-body' : '') + ' p-2' } key={ key }>
        <Row className={ 'mt-1' }>
          <Col>
            <Form.Group>
              <Form.Label>{ trans('editField.fileTypeSetting.fileType') }</Form.Label>
              <Select isMulti
                      name={ `fileTypes.${ key }.fileType` }
                      options={ allowedFileFormats.map(ft => ({ label: ft, value: ft })) }
                      value={ constraint.fileType.map(ft => ({ label: ft, value: ft })) }
                      onChange={ ft => onFileTypeChange(key)(ft.map(({ value }) => value)) }
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className={ 'mt-1' }>
          <Col>
            <Form.Group>
              <Form.Label>{ trans('editField.fileTypeSetting.min') }</Form.Label>
              <Form.Control name={ `fileTypes.${ key }.min` }
                            size={ 'sm' }
                            type={ 'number' }
                            min={ 0 }
                            value={ constraint.min }
                            onChange={ onConstraintChange(key, 'min') }
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>
                <span className={ 'px-1' }>{ trans('editField.fileTypeSetting.max') }</span>
                <OverlayTrigger
                  key={ 'top' }
                  placement={ 'top' }
                  overlay={
                    <Tooltip id={ 'constraintMax' }>
                      <p>{ trans('tooltipMaxConstraintDesc') }</p>
                    </Tooltip>
                  }
                >
                  <i className={ 'fa-solid fa-circle-info ml-2' }></i>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control name={ `fileTypes.${ key }.max` }
                            size={ 'sm' }
                            type={ 'number' }
                            min={ 0 }
                            value={ constraint.max || '' }
                            onChange={ onConstraintChange(key, 'max') }
              />

            </Form.Group>
          </Col>
        </Row>
        <Row className={ 'mt-1' }>
          <Col>
            <span className={ 'fw-bold text-danger cursor-pointer' } onClick={ removeConstraint(key) }>
              { trans('remove') }
            </span>
          </Col>
        </Row>
      </div>
    )) }
    <Button onClick={ addConstraint } variant={ 'outline-primary' } size={ 'sm' } className={ 'mt-2' }>
      { trans('editField.fileTypeSetting.add') }
    </Button>
  </div>
}

export default FileTypeSettings
