import {
  FETCH_DELETED_FORMS,
  RECIEVE_DELETED_FORMS,
  CHANGE_PAGE,
  CHANGE_LIMIT,
  FETCH_RESTORE_FORM,
  SEARCH_DELETED_FORM
} from './actionTypes'
export const receiveDeletedForms = (data) => ({
  type: RECIEVE_DELETED_FORMS,
  payload: data
})
export const fetchDeletedForms = (data) => ({
  type: FETCH_DELETED_FORMS,
  payload: data
})
export const fetchRestoreForm= (data) => ({
  type: FETCH_RESTORE_FORM,
  payload: data
})
export const changePage = (data) => ({
  type: CHANGE_PAGE,
  payload: data
})
export const searchDeletedForm = (data) => ({
  type: SEARCH_DELETED_FORM,
  payload: data
})
export const changeLimitRestoreDeletedForms = (data) => ({
  type: CHANGE_LIMIT,
  payload: data
})