import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Col, Form, Row } from 'react-bootstrap'
import {
  FIELD_BASE_TYPE_DATE_TIME, FIELD_BASE_TYPE_REFERENCE,
} from 'src/Services/Constants'
import LocalizedDatepicker from 'src/Components/LocalizedDatepicker'
import {
  FormCustomizationConditionOperator, FormCustomizationConditionType
} from 'src/Types/FormCustomizationCondition'
import { getFields } from '../state/formReducer'

const CustomizationConditionForm = ({ trans, condition, onChange, onDelete }) => {
  const { form } = useSelector(state => state.FormEditor.formReducer)
  const [ refField, setRefField ] = useState()
  const [ selectedField, setSelectedField ] = useState(condition.field?.id ?? '')
  const onInputChange = property => value =>
    onChange({ ...condition, [property]: value })
  const onFieldChange = e => {
    const field = getFields(form).find(f => f.id == e.target.value)
    setSelectedField(e.target.value)
    onInputChange('field')(field || null)
  }
  useEffect(() => {
    if (condition?.field?.type?.baseFieldType === FIELD_BASE_TYPE_REFERENCE) {
      setRefField(condition?.field)
    }
    else {
      setListColumn()
      setRefField()
      onInputChange('listColumn')(null)
    }
  },[ selectedField ])
  const getFieldByColumn = () =>  {
    const field = getFields(form).find(f => f.id == condition.field?.id)
    const refField = field?.referenceFields?.find(refField => refField?.listColumn?.id == condition?.listColumn?.id )
    return refField?.id ?? ''
  }
  const [ listColumn, setListColumn ] = useState(getFieldByColumn())
  const onColumnFieldChange = e => {
    const field = refField?.referenceFields.find(f => f.id == e.target.value)
    setListColumn(e.target.value)
    onInputChange('listColumn')(field?.listColumn?.id || null)
  }

  useEffect(() => {
    if (condition.listColumn) {
      const refField = getFields(form).find(f => f.id == condition.field.id)
      setRefField(refField)
    }
  },[])

  const isValueDate = () =>
    condition.field?.type?.baseFieldType === FIELD_BASE_TYPE_DATE_TIME

  return <div className={ 'white-card mb-2' }>
    <Row>
      <Col>
        <Form.Group>
          <Form.Label>{ trans('form.customizationOrder.condition.field') }</Form.Label>
          <Form.Select size={ 'sm' } name={ 'condition.field' } value={ selectedField }
                       onChange={ onFieldChange }
          >
            <option value={ null }>{ trans('none') }</option>
            { getFields(form).filter(f => f.id).map(f =>
              <option key={ f.id } value={ f.id }>{ f.systemName }</option>) }
          </Form.Select>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Label>{ trans('form.customizationOrder.condition.type') }</Form.Label>
          <Form.Select size={ 'sm' } name={ 'condition.type' } value={ condition.type || '' }
                       onChange={ e => onInputChange('type')(e.target.value) }>
            { Object.values(FormCustomizationConditionType).map(t => <option key={ t } value={ t }>{ t }</option>) }
          </Form.Select>
        </Form.Group>
      </Col>
    </Row>
    { refField && refField?.referenceFields?.length > 0 &&
      <Row>
        <Col md={ 6 }>
          <Form.Group>
            <Form.Label className={ 'mt-2 mb-2' }>{ trans('form.customizationOrder.condition.listColumn') }</Form.Label>
            <Form.Select
              size={ 'sm' }
              name={ 'condition.listColumn' }
              onChange={ onColumnFieldChange }
              value={ listColumn }
            >
              <option value={ null }>{ trans('none') }</option>
              { refField?.referenceFields?.filter(f => f.id).map(f =>
                <option key={ f.id } value={ f.id }>{ f.systemName }</option>) }
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
    }
    <Row>
      <Col>
        <Form.Group>
          <Form.Label>{ trans('form.customizationOrder.condition.operator') }</Form.Label>
          <Form.Select size={ 'sm' } name={ 'condition.operator' } value={ condition.operator || '' }
                       onChange={ e => onInputChange('operator')(e.target.value) }
                       disabled={ condition.type !== FormCustomizationConditionType.VALUE }
            >
            <option value={ null }>{ trans('none') }</option>
            { Object.values(FormCustomizationConditionOperator).map(
                o => <option key={ o } value={ o }>{ o }</option>
            ) }
          </Form.Select>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Label>{ trans('form.customizationOrder.condition.value') }</Form.Label>
          { isValueDate()
            ? <LocalizedDatepicker id={ 'customizationConditionForm' }
                     showDateOnly={ condition.field?.options?.showDate && !condition.field?.options?.showTime }
                     showTimeOnly={ !condition.field?.options?.showDate && condition.field?.options?.showTime }
                     selected={ condition.value }
                     onChange={ onInputChange('value') }
                     disabled={ !condition.operator }
                     portalId={ 'root' }
            />
            : <Form.Control size={ 'sm' } name={ 'condition.value' } value={ condition.value || '' }
                            onChange={ e => onInputChange('value')(e.target.value) }
                            disabled={ !condition.operator }
            /> }
          <Form.Text>{ trans('form.customizationOrder.condition.valueInfo') }</Form.Text>
        </Form.Group>
      </Col>
    </Row>
    <Row>
      <Col>
        <span className={ 'text-danger cursor-pointer' } onClick={ e => onDelete() }>
          { trans('remove') }
        </span>
      </Col>
    </Row>
  </div>
}

export default CustomizationConditionForm
