import React, { useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown } from 'react-bootstrap'
import { translate } from 'src/Services/translation'
import translations from '../../translations'
import { setIsAddMenuOpen,  addInstance } from '../../state/actions'

const InstanceCreator = () => {
  const dispatch = useDispatch()

  const { language } = useSelector(state => state.Root.user)
  const { isAddMenuOpen, patientRelatedForms } = useSelector(state => state.Patient)

  const trans = translate(translations)(language)
  const headerRef = useRef()
  const navigate = useNavigate()
  const { id } = useParams()

  const onAddInstanceClick = (dispatch, patientId, formId) => () => {
    dispatch(addInstance(patientId, formId, navigate))
    dispatch(setIsAddMenuOpen(false))
  }

  const onClickOutsideAddMenuEffect = () => {
    const headerElement = headerRef.current

    const onMouseDown = e => {
      if (isAddMenuOpen && !headerElement.contains(e.target))
        dispatch(setIsAddMenuOpen(false))
    }

    document.addEventListener('mousedown', onMouseDown)

    return () => document.removeEventListener('mouseDown', onMouseDown)
  }

  useEffect(onClickOutsideAddMenuEffect, [])

  return (
    <Dropdown>
      <Dropdown.Toggle variant="success">
        {trans('createInstance')}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {patientRelatedForms.length === 0
          ? <Dropdown.Item>{trans('noFormToInstantiate')}</Dropdown.Item>
          : patientRelatedForms.map(form =>
            <Dropdown.Item
              key={form.id}
              onClick={onAddInstanceClick(dispatch, id, form.id)}
              onKeyDown={e => e.key === 'Enter' && onAddInstanceClick(dispatch, id, form.id)(e)}
            >{form.label}</Dropdown.Item>
          )
        }
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default InstanceCreator
