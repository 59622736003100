
export default {
  english: {
    title: 'System Fields',
    unique: 'The field must be unique',
    table: {
      fieldName: 'Name',
      ids: 'Fields ids'
    },
    modal: {
      createTitle: 'Create new system field',
      createField: 'Create new Field',
      searchField: 'Search for field',
      name: 'Name',
    }
  },
  french: {
    title: 'Champs systèmes',
    unique: 'Le champ doit être unique',
    table: {
      fieldName: 'Nom',
      ids: 'Identifiants des champs'
    },
    modal: {
      createTitle: 'Créer un nouveau champ système',
      createField: 'Créer un nouveau champ',
      searchField: 'Rechercher un champ',
      name: 'Nom',
    }
  }
}
