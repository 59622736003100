import React from 'react'
import AsyncSelect from 'react-select/async'
import { FormControl } from 'react-bootstrap'
import ApiMethods from 'src/Services/api/apiMethods'
import translation from 'src/Services/api/translations'
import { useDispatch, useSelector } from 'react-redux'
import { translate } from 'src/Services/translation'
import { handleError } from 'src/Services/Store/Root/actions'

interface EntitySelectProps {
  id?: string
  name?: string
  placeholder?: string
  isMulti?: boolean
  required?: boolean
  disabled?: boolean
  clearable?: boolean
  isDefaultOptions?: boolean
  defaultValue?: object
  value?: object
  onChange: (newValue: any) => void
  getOptionLabel?: (option: any) => string | React.ReactElement
  fetchUrl?: string
  additionalParams?: object,
  valueKey?: string
  labelKey?: string
  isOptionsToHtml?: boolean
  size?: string
}

const EntitySelect = ({
  id = 'entitySelect',
  name = 'entitySelect',
  placeholder = null,
  isMulti = false,
  defaultValue = null,
  value = null,
  required = false,
  disabled = false,
  clearable = false,
  isDefaultOptions = false,
  fetchUrl = null,
  additionalParams = null,
  onChange = () => {},
  getOptionLabel = null,
  valueKey = 'id',
  labelKey = 'name',
  size = 'md',
}: EntitySelectProps) => {

  const dispatch = useDispatch()

  // @ts-ignore
  const { language } = useSelector(state => state.Root.user)
  const trans = translate(translation)(language)

  const [ localValue, setLocalValue ] = React.useState<object|null>(defaultValue || null)
  const [ fetchRequest, setFetchRequest ] = React.useState<ReturnType<typeof setTimeout>>(null)

  React.useEffect(() => {
    if (localValue !== value)
      setLocalValue(value)
  }, [ value ])

  React.useEffect(() => {
    if (localValue !== defaultValue)
      setLocalValue(defaultValue)
  }, [ defaultValue ])

  /** @url https://github.com/JedWatson/react-select/issues/3706#issuecomment-805661163 */
  const parseValues = (values: any[]) => values.map(_ => {
    delete _.options
    return _
  })

  const fetchItems = (inputValue: string, callback: (options: Array<any>) => void) => () =>
    ApiMethods.get(fetchUrl, { query: inputValue, limit: 30, pageLimit: 30, ...additionalParams })
      .then(({ data }) => callback(parseValues(data)))
      .catch(err => {
        dispatch(handleError(err, 'fetchFailed'))
      })

  const loadOptions = (inputValue: string, callback: (options: Array<any>) => void) => {
    clearTimeout(fetchRequest)
    setFetchRequest(setTimeout(fetchItems(inputValue, callback), 1000))
  }

  const handleInputChange = (newValue: string) => newValue.replace(/[^a-zA-Z0-9_ ]+/g, '')
  const handleChange = (newValue?: object) => {
    setLocalValue(newValue || null)
    onChange(newValue)
  }

  return <>
    <AsyncSelect isMulti={ isMulti }
                 id={ id }
                 inputId={ 'async-search' }
                 isDisabled={ disabled }
                 isClearable={ clearable }
                 required={ required }
                 cacheOptions
                 value={ localValue }
                 defaultOptions ={ isDefaultOptions }
                 loadOptions={ loadOptions }
                 onInputChange={ handleInputChange }
                 onChange={ handleChange }
                 placeholder={ placeholder || trans('searchPlaceholder') }
                 /* @ts-ignore */
                 getOptionLabel={ getOptionLabel || (_ => _[labelKey] || _.label || _.name || _.systemName) }
                 /* @ts-ignore */
                 getOptionValue={ _ => _[valueKey] }
                 noOptionsMessage={ () => trans('noResults') }
    />
    <FormControl type={ 'hidden' }
                 /* @ts-ignore */
                 value={ localValue?.[valueKey] || '' }
                 name={ name }
                 required={ required }
    />
  </>
}

export default EntitySelect
