import {
  TRIGGER_CREATED, TRIGGER_DELETED, TRIGGER_UPDATED, TRIGGER_RECEIVED, CREATE_TRIGGER, SET_DELETING_TRIGGER,
  SET_UPDATING_TRIGGER, DELETE_TRIGGER, UPDATE_TRIGGER, TOGGLE_CREATE_TRIGGER_MODAL
} from './actionTypes'

export const createItem = item => ({
  type: CREATE_TRIGGER,
  payload: item
})

export const deleteItem = () => ({
  type: DELETE_TRIGGER
})

export const updateItem = item => ({
  type: UPDATE_TRIGGER,
  payload: item
})

export const itemReceived = item => ({
  type: TRIGGER_RECEIVED,
  payload: item
})

export const itemCreated = item => ({
  type: TRIGGER_CREATED,
  payload: item
})
export const itemDeleted = id => ({
  type: TRIGGER_DELETED,
  payload: id
})

export const itemUpdated = item => ({
  type: TRIGGER_UPDATED,
  payload: item
})

export const setDeletingItem = item => ({
  type: SET_DELETING_TRIGGER,
  payload: item
})

export const setUpdatingItem = item => ({
  type: SET_UPDATING_TRIGGER,
  payload: item
})

export const toggleCreateTriggerModal = () => ({
  type: TOGGLE_CREATE_TRIGGER_MODAL
})
