import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import { DATE_FORMAT, DATE_TIME_FORMAT, DATE_TIME_SERVICE_DAY_JS, TIME_FORMAT } from 'src/Services/Constants/Locales'
import day from 'dayjs'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import duration from 'dayjs/plugin/duration'
import timezone from 'dayjs/plugin/timezone'
import { Locale } from 'src/Types/Locale'
import { isIso8601OffsetString, isIso8601String, Iso8601, Iso8601Offset } from 'src/Types/Date'

day.extend(utc)
day.extend(duration)
day.extend(timezone)

/**
 * @param timezone ex : America/New_York
 * @return string ex : -04:00
 */
export const convertTimezoneToOffset = (timezone: string): Iso8601Offset | null => {
  if (timezone.toUpperCase() === 'UTC')
    return ('+00:00' as Iso8601Offset)

  const utcDate = zonedTimeToUtc(new Date(), timezone)
  const result = format(utcDate, 'X', { timeZone: timezone }) + ':00'

  return isIso8601OffsetString(result) ? result : null
}

// Will update offset without any calculation
export const changeOffsetOfIso8601Date = (dateTime: Iso8601 | string, offset = '+00:00'): string =>
    dateTime.slice(0, -6) + offset

export const formatDateAsUtcToIso8601 = (dateTime: Date | null, offset = '+00:00'): Iso8601 | null => {
  if (dateTime === null) {
    return null
  }

  const year = dateTime.getUTCFullYear()
  const month = String(dateTime.getUTCMonth() + 1).padStart(2, '0')
  const day = String(dateTime.getUTCDate()).padStart(2, '0')
  const hours = String(dateTime.getUTCHours()).padStart(2, '0')
  const minutes = String(dateTime.getUTCMinutes()).padStart(2, '0')
  const seconds = String(dateTime.getUTCSeconds()).padStart(2, '0')

  if (!isIso8601OffsetString(offset)) {
    offset = convertTimezoneToOffset(offset)
  }

  const result = `${ year }-${ month }-${ day }T${ hours }:${ minutes }:${ seconds }${ offset }`

  return isIso8601String(result) ? result : null
}

export const formatDateToIso8601 = (
    dateTime: Date | null,
    offset = '+00:00',
    isDateOnly = false,
): Iso8601 | null =>
{
  if (dateTime === null) {
    return null
  }

  const year = dateTime.getFullYear()
  const month = String(dateTime.getMonth() + 1).padStart(2, '0')
  const day = String(dateTime.getDate()).padStart(2, '0')
  const hours = isDateOnly ? '00' : String(dateTime.getHours()).padStart(2, '0')
  const minutes = isDateOnly ? '00' : String(dateTime.getMinutes()).padStart(2, '0')
  const seconds = isDateOnly ? '00' : String(dateTime.getSeconds()).padStart(2, '0')

  if (!isIso8601OffsetString(offset)) {
    offset = convertTimezoneToOffset(offset)
  }

  const result = `${ year }-${ month }-${ day }T${ hours }:${ minutes }:${ seconds }${ offset }`

  return isIso8601String(result) ? result : null
}

/**
 * @param dateTime a date time which have a UTC value corresponding to the custom timezone
 *                  Ex : If custom Timezone (-04), User Timezone (+02)
 *                        -> 01/01/2000 17:00:00 +02 (here 17 means 15 in -04)
 * @param timezone ex: America/New_York
 */
export const formatDateAsUtcIsCustomTimezoneToUtcIso8601 = (dateTime: Date, timezone: string): Iso8601 => {
  const isoDateOnCustomTimezone = formatDateAsUtcToIso8601(dateTime, convertTimezoneToOffset(timezone))
  return formatDateAsUtcToIso8601(new Date(isoDateOnCustomTimezone))
}

/**
 * There is surely a better way to do that, but why brain won't give it to me.
 */
export const formatDateToUtcIso8601WithCustomTimezone = (dateTime: Date, timezone: string): Iso8601 => {
  // New date and time reflect custom timezone even if the Date object say that it is in user's timezone
  // Ex : If custom Timezone (-04), User Timezone (+02)
  // -> 01/01/2000 17:00:00 +02 (+02 here is actually -04)
  const dateOnFakeTimezone = dateTime

  // Previous date that is set to UTC regarding user's timezone
  // Ex : 2000-01-01T15:00:00+00:00 (it's +06 regarding custom)
  const dateOnFakeTimezoneAsUtc = formatDateAsUtcToIso8601(dateOnFakeTimezone)

  // Previous date but which has been added with custom timezone offset
  // Ex : 01/01/2000 23:00:00 +02
  const dateOnCorrectTimezone = zonedTimeToUtc(dateOnFakeTimezoneAsUtc, timezone)

  // UTC ISO DateTime regarding custom timezone
  // Ex : 2000-01-01T21:00:00+00:00 (00 here is correct)
  return formatDateAsUtcToIso8601(dateOnCorrectTimezone)
}

/**
 * Returned date and time reflect custom timezone even if the Date object say that it is in user's timezone
 */
export const formatUtcIso8601ToDateWithCustomTimezone = (dateTime: Iso8601 | string, timezone: string): Date | null => {
  const selectedDate = utcToZonedTime(dateTime, timezone)

  if (isNaN(selectedDate.getTime()))
    return null

  return selectedDate
}

export const applyCustomTimezoneOnIso8601 = (dateTime: Iso8601 | string, timezone: string): Iso8601 | null => {

  const dayDateTime = dayjs(dateTime)
  const convertedDateTime = dayDateTime.tz(timezone)
  const formattedDateTime = convertedDateTime.format()

  return isIso8601String(formattedDateTime) ? formattedDateTime : null
}

export const applyCustomOffsetOnIso8601 = (dateTime: Iso8601 | string, offset: string): Iso8601 | null => {

  let dayDateTime = dayjs(dateTime)
  const offsetAddedByDayJsInMinutes = dayDateTime.utcOffset()
  dayDateTime = dayDateTime.add(parseOffsetToMinutes(offset) - offsetAddedByDayJsInMinutes, 'minutes')
  let formattedDateTime = dayDateTime.format()
  formattedDateTime = changeOffsetOfIso8601Date(formattedDateTime, offset)

  return isIso8601String(formattedDateTime) ? formattedDateTime : null
}

// Temp fix, isValid() return true for nearly everything that is a number
export const isDate = (value: string): boolean => value.length <= 8 ? false : day(value).isValid()

/**
 * Format UtcIso8601 date time to localized string ready to display
 */
export const formatDateTime = (locale: Locale = Locale.US, timezone: string | null = null) => (
    dateTime: string | null = null,
    showDate = true,
    showTime = true,
): string => {

  let dateTimeObject: Date | null = null

  // If date only return directly date without zone conversion
  if (typeof dateTime === 'string' && showDate && !showTime)
    return day(utcToZonedTime(dateTime, 'UTC'))
        .format(DATE_FORMAT[DATE_TIME_SERVICE_DAY_JS][locale])
  else if (typeof dateTime === 'string') {

    if (!timezone) {
      timezone = getClientTimeZone()
    }

    dateTimeObject = formatUtcIso8601ToDateWithCustomTimezone(dateTime, timezone)
  } else
    return ''

  if (!dateTime) return 'Invalid Date'

  let template

  if (!showDate && showTime)
    template = TIME_FORMAT[DATE_TIME_SERVICE_DAY_JS][locale]
  else
    template = DATE_TIME_FORMAT[DATE_TIME_SERVICE_DAY_JS][locale]

  return day(dateTimeObject).format(template)
}

export const formatTime = (locale: Locale = Locale.US, timezone: string | null = null) => (dateTime: string | null = null) =>
    formatDateTime(locale, timezone)(dateTime, false, true)

/**
 * @param dateTime target (UTC ISO 8601)
 * @param time value (UTC ISO 8601)
 * @param timezone
 * @return (UTC ISO 8601)
 */
export const setTimeOnDate = (dateTime: dayjs.ConfigType, time: Date | string | number, timezone: string): Iso8601 => {
  const zonedTime = utcToZonedTime(time, timezone)
  const updatedDateTime = day(dateTime).set('hours', zonedTime.getHours()).set('minutes', zonedTime.getMinutes())

  return formatDateAsUtcToIso8601(zonedTimeToUtc(updatedDateTime.toDate(), timezone))
}

/** @url https://stackoverflow.com/questions/1091372/getting-the-clients-time-zone-and-offset-in-javascript */
export const getClientTimeZone = (): string => Intl.DateTimeFormat().resolvedOptions().timeZone

export const getClientGMTOffset = (): string => {
  const date = new Date()
  const timeZoneOffset = date.getTimezoneOffset() / 60
  return `GMT${ timeZoneOffset >= 0 ? '-' : '+' }${ Math.abs(timeZoneOffset) }`
}

export const parseOffsetToMinutes = (offset: string): number => {
  const [ hours, minutes ] = offset.split(':').map(Number)
  const totalMinutes = hours * 60 + minutes
  return offset.startsWith('-') ? Math.abs(totalMinutes) * -1 : totalMinutes
}

export const formatMinutesToOffset = (minutes: number): string => {
  const sign = minutes < 0 ? '-' : '+'
  const absMinutes = Math.abs(minutes)
  const hours = Math.floor(absMinutes / 60)
  const paddedHours = String(hours).padStart(2, '0')
  const paddedMinutes = String(absMinutes % 60).padStart(2, '0')
  return `${ sign }${ paddedHours }:${ paddedMinutes }`
}

export const subtractOffsetToAnother = (
    targetOffset = '+00:00',
    subtractOffset = '+00:00',
): Iso8601Offset | null => {
  // Convert offsets to durations
  const targetDuration = parseOffsetToMinutes(targetOffset)
  const subtractDuration = parseOffsetToMinutes(subtractOffset)

  // Subtract the durations
  const resultDuration = targetDuration - subtractDuration

  // Format the result as an offset string
  const resultOffset = formatMinutesToOffset(resultDuration)

  return isIso8601OffsetString(resultOffset) ? resultOffset : null
}
