import { Field } from 'src/Types/Field'
import { FieldOption } from 'src/Types/Field'
import * as action from './actionTypes'

export const loadForm = formId => ({
  type: action.LOAD_FORM,
  payload: { formId }
})

export const receiveForm = form => ({
  type: action.RECEIVE_FORM,
  payload: { form }
})

export const fetchFailed = error => ({
  type: action.FETCH_FORM_FAILED,
  payload: error
})

export const setSystemFields = systemFields => ({
  type: action.SET_SYSTEM_FIELDS,
  payload: { systemFields }
})

export const toggleDetailsModal = () => ({
  type: action.TOGGLE_DETAILS_MODAL
})
export const toggleSwpConfigModal = () => ({
  type: action.TOGGLE_SWP_CONFIG_MODAL
})

export const toggleMsSanteConfigModal = () => ({
  type: action.TOGGLE_MS_SANTE_CONFIG_MODAL
})

export const updateDetails = data => ({
  type: action.UPDATE_DETAILS,
  payload: data
})

export const detailsUpdated = () => ({
  type: action.DETAILS_UPDATED
})

export const detailsNotUpdated = error => ({
  type: action.DETAILS_NOT_UPDATED,
  payload: error
})

export const toggleScriptDialog = () => ({
  type: action.TOGGLE_SCRIPT_DIALOG
})

export const setDraggedFieldType = data => ({
  type: action.SET_DRAGGED_FIELD_TYPE,
  payload: data
})

export const setIsEditingTitleTemplate = data => ({
  type: action.SET_IS_EDITING_TITLE_TEMPLATE,
  payload: data
})

export const setDraggedExistingField = field => ({
  type: action.SET_DRAGGED_EXISTING_FIELD,
  payload: field
})

export const moveFieldToAnotherPlace = (fieldId, rowId = null, rowSize = null, rowColumn = null) => ({
  type: action.MOVE_FIELD_TO_ANOTHER_PLACE,
  payload: { fieldId, rowId, rowSize, rowColumn }
})

export const fieldMoved = () => ({
  type: action.FIELD_MOVED
})

export const draggingStopped = () => ({
  type: action.DRAGGING_STOPPED
})

export const setTargetField = field => ({
  type: action.SET_TARGET_FIELD,
  payload: { field }
})

export const setDraggedRow = (section, row, rowSortOrder) => ({
  type: action.SET_DRAGGED_ROW,
  payload: { section, row, rowSortOrder }
})

export const reorderRow = (row, targetRow, targetSection, targetRowSortOrder = null) => ({
  type: action.REORDER_ROW,
  payload: { row, targetRow, targetSection, targetRowSortOrder }
})

export const rowReordered = () => ({
  type: action.ROW_REORDERED
})

export const requestAddReferenceField = (targetField, newField = {}) => ({
  type: action.REQUEST_ADD_REFERENCE_FIELD,
  payload: { targetField, newField }
})

export const requestAddField = (targetField, newField = {}) => ({
  type: action.REQUEST_ADD_FIELD,
  payload: { targetField, newField }
})

export const addField = newField => ({
  type: action.ADD_FIELD,
  payload: { newField }
})

export const fieldAdded = formId => ({
  type: action.FIELD_ADDED,
  payload: formId
})

export const errorAddingField = () => ({
  type: action.ERROR_ADDING_FIELD
})

export const addRow = (sectionId, formId) => ({
  type: action.ADD_ROW,
  payload: { sectionId, formId }
})

export const errorAddingRow = () => ({
  type: action.ERROR_ADDING_ROW
})

export const rowAdded = data => ({
  type: action.ROW_ADDED,
  payload: data
})

export const deleteRow = (sectionId, id) => ({
  type: action.DELETE_ROW,
  payload: {
    sectionId,
    id
  }
})

export const errorDeletingRow = data => ({
  type: action.ERROR_DELETING_ROW
})

export const rowDeleted = () => ({
  type: action.ROW_DELETED
})

export const deleteField = id => ({
  type: action.DELETE_FIELD,
  payload: id
})

export const fieldDeleted = () => ({
  type: action.FIELD_DELETED
})

export const errorDeletingField = () => ({
  type: action.ERROR_DELETING_FIELD
})

export const openEditField = id => ({
  type: action.OPEN_EDIT_FIELD,
  payload: id
})

export const receiveEditField = data => ({
  type: action.RECEIVE_EDIT_FIELD,
  payload: data
})

export const updateFieldOptionValues = values => ({
  type: action.UPDATE_OPTION_VALUES,
  payload: { values }
})

export const updateFieldOption = (option, value) => ({
  type: action.UPDATE_FIELD_OPTION,
  payload: { option, value }
})

export const closeEditField = () => ({
  type: action.CLOSE_EDIT_FIELD
})

export const updateField = data => ({
  type: action.UPDATE_FIELD,
  payload: data
})

export const fieldUpdated = () => ({
  type: action.FIELD_UPDATED
})

export const errorUpdatingField = () => ({
  type: action.ERROR_UPDATING_FIELD
})

export const deleteSection = id => ({
  type: action.DELETE_SECTION,
  payload: id
})

export const errorDeletingSection = data => ({
  type: action.ERROR_DELETING_SECTION
})

export const sectionDeleted = () => ({
  type: action.SECTION_DELETED
})

export const updateSection = (id, details) => ({
  type: action.UPDATE_SECTION,
  payload: { id, details }
})

export const errorUpdatingSection = data => ({
  type: action.ERROR_UPDATING_SECTION,
  payload: data
})

export const sectionUpdated = () => ({
  type: action.SECTION_UPDATED
})

export const saveForm = (formId, navigate, withExit) => ({
  type: action.SAVE_FORM,
  payload: { formId, navigate, withExit }
})

export const formSaved = () => ({
  type: action.FORM_SAVED
})

export const errorSavingForm = data => ({
  type: action.ERROR_SAVING_FORM,
  payload: data
})

export const resetFormSave = () => ({
  type: action.RESET_FORM_SAVE
})

export const undoChanges = (formId, navigate) => ({
  type: action.UNDO_CHANGES,
  payload: { formId, navigate }
})

export const undoChangesFailed = () => ({
  type: action.UNDO_CHANGES_FAILED
})

export const setRefFieldModalOpened = data => ({
  type: action.SET_REF_FIELD_MODAL_OPENED,
  payload: data
})

export const replaceFieldOptionValues = (field, optionSystemName, replacementOptionSystemName) => ({
  type: action.REPLACE_FIELD_OPTION_VALUES,
  payload: { field, optionSystemName, replacementOptionSystemName }
})

export const toggleFieldDetailsModal = () => ({
  type: action.TOGGLE_FIELD_DETAILS_MODAL
})
export const toggleFieldValuesModal = () => ({
  type: action.TOGGLE_FIELD_VALUES_MODAL
})
export const toggleAutocompleteFieldsModal = () => ({
  type: action.TOGGLE_AUTOCOMPLETE_FIELDS_MODAL
})
export const toggleCalculationFieldModal = () => ({
  type: action.TOGGLE_CALCULATION_FIELD_MODAL
})
export const toggleCustomizationOrdersModal = () => ({
  type: action.TOGGLE_CUSTOMIZATION_ORDERS_MODAL
})

export const addReferenceField = (field, list) => ({
  type: action.ADD_REFERENCE_FIELD,
  payload: { newField: field, newList: list }
})

export const setCurrentDeletedOption = data => ({
  type: action.SET_CURRENT_DELETED_OPTION,
  payload: data
})

export const setSavingRefField = data => ({
  type: action.SET_SAVING_REF_FIELD,
  payload: data
})

export const addFullRowField = (fieldType, afterRow, sectionId, formId) => ({
  type: action.ADD_FULL_ROW_FIELD,
  payload: { fieldType, afterRow, sectionId, formId }
})

export const addFieldInExtensible = newField => ({
  type: action.ADD_FIELD_IN_EXTENSIBLE,
  payload: { newField }
})

export const scrollExtensibleField = data => ({
  type: action.SCROLL_EXTENSIBLE_FIELD,
  payload: data
})

export const setExtensibleScrollButtons = (id, showLeft, showRight) => ({
  type: action.SET_EXTENSIBLE_SCROLL_BUTTONS,
  payload: {
    id,
    showLeft,
    showRight
  }
})

export const initExtensibleScrollButtons = data => ({
  type: action.INIT_EXTENSIBLE_SCROLL_BUTTONS,
  payload: data
})

export const setNewFullRowFieldDetails = data => ({
  type: action.SET_NEW_FULL_ROW_FIELD_DETAILS,
  payload: data
})

export const openFullRowModal = (fieldType, sectionId, afterRow) => ({
  type: action.OPEN_FULL_ROW_MODAL,
  payload: {
    fieldType,
    sectionId,
    afterRow
  }
})

export const closeFullRowModal = () => ({
  type: action.CLOSE_FULL_ROW_MODAL
})

export const sortInsideAnExtensible = (formId,extensibleId, fields) => ({
  type: action.SORT_INSIDE_AN_EXTENSIBLE,
  payload: {
    formId,
    extensibleId,
    fields
  }
})

export const updateExtensibleFieldsOrder = data => ({
  type: action.UPDATE_EXTENSIBLE_FIELDS_ORDER,
  payload: data
})

export const setIsCustomizationOrderLoading = isLoading => ({
  type: action.SET_IS_CUSTOMIZATION_ORDER_LOADING,
  payload: isLoading
})
export const createCustomizationOrder = item => ({
  type: action.CREATE_CUSTOMIZATION_ORDER,
  payload: { item }
})
export const updateCustomizationOrder = item => ({
  type: action.UPDATE_CUSTOMIZATION_ORDER,
  payload: { item }
})
export const deleteCustomizationOrder = item => ({
  type: action.DELETE_CUSTOMIZATION_ORDER,
  payload: { item }
})

