import React from 'react'
import { Form as BForm, Button, Row, Col } from 'react-bootstrap'

const TasksTypesForm = ({
  trans,
  onSubmit = () => {},
  item = null
}) => {
  const onFormSubmit = e => {
    e.preventDefault()
    let edited = { name: e.target.name.value }
    if (item)
      edited = { ...edited, id: item.id }

    onSubmit(edited)
  }

  return (
    <BForm name={'field-type'} onSubmit={onFormSubmit}>
      <Row className={'mb-2'}>
        <Col>
          <BForm.Group>
            <BForm.Label>{trans('form.name')}</BForm.Label>
            <BForm.Control defaultValue={item?.name} name={'name'} required type="text"
                          placeholder={trans('form.name')}/>
          </BForm.Group>
        </Col>
      </Row>

      { /*<Row className={'mb-2'}>
        <Col>
          <Form.Group>
            <Form.Label>{trans('form.baseFieldType')}</Form.Label>
            <Form.Select defaultValue={fieldType?.baseFieldType} name={'baseFieldType'} required
                         aria-label={trans('form.baseFieldType')}>
              <option value={''}>{trans('form.defaultBaseFieldTypeOption')}</option>
              {Object.keys(baseFieldTypes)
                .map(key =>
                  <option value={key} key={key}>{trans(`baseFieldTypes.${key}`)}</option>)
              }
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>*/}

      <Row>
        <Col>
          <Button variant={'success'} style={{ float: 'right' }} type={'submit'}>{trans('submit')}</Button>
        </Col>
      </Row>
    </BForm>
  )
}

export default TasksTypesForm
