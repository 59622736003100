import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ButtonGroup, Spinner, Table } from 'react-bootstrap'
import translation from './translations'
import { translate, translateConf } from 'src/Services/translation'
import FormFillerColumn from 'src/Views/FormFiller/Components/Column'
import { FieldOption } from 'src/Types/FieldOption'
import { FieldPermissionAccessLevel } from 'src/Types/FieldPermission'
import { getExtensibleRows, getExtensibleHeaders, } from '../../../selectors'
import { deleteExtensibleRow, editExtensibleRow, createExtensibleRow } from '../../../state/actions'

const ExtensibleField = ({ field, isDisabled }) => {
  const dispatch = useDispatch()

  const { language } = useSelector(state => state.Root.user)
  const { configTranslations } = useSelector(state => state.Root)
  const { extensiblesLoading } = useSelector(state => state.FormFiller)
  const getExtensibleRowsById = useSelector(state => getExtensibleRows(state.FormFiller))
  const getExtensibleHeadersById = useSelector(state => getExtensibleHeaders(state.FormFiller))

  const transConf = translateConf(configTranslations)
  const trans = translate(translation)(language)

  const [ editingRow, setEditingRow ] = useState(null)

  const getRows = () => getExtensibleRowsById(field.id) || []
  const getHeaders = () => getExtensibleHeadersById(field.id) || []
  const isRowLoading = rowId => extensiblesLoading.some(_ => _.extensibleId === field.id && _.rowId === rowId)

  const onSaveNewRowClick = () => dispatch(createExtensibleRow(field.id))
  const onRowSaveClick = rowId => e => {
    dispatch(editExtensibleRow(field.id, rowId))
    setEditingRow(null)
  }
  const onRowDeleteClick = rowId => e => dispatch(deleteExtensibleRow(field.id, rowId))

  const isAddRow = rowIndex => rowIndex + 1 === getRows().length
  const isRowBeingEdit = row => editingRow === row.id
  const canUpdateRow = (row, index) =>
    !isAddRow(index) && !isRowBeingEdit(row) && field.accessLevel >= FieldPermissionAccessLevel.UPDATE
  const canDeleteRow = (row, index) =>
    !isAddRow(index) && !isRowBeingEdit(row) && field.accessLevel >= FieldPermissionAccessLevel.DELETE

  return <div id={ `field${ field.id }` } style={ { maxHeight: '400px' } }>
    <Table size="sm" striped bordered>
      <thead style={ { position: 'sticky', top: 0, zIndex: 1 } } className="bg-white">
      <tr>
        { getHeaders().map(header =>
          <th key={ header.id }>
            <span className="fw-bold">
              { transConf('FIELD')(header) || trans('noLabelFound') }
              { header.options[FieldOption.REQUIRED] && <span className="text-danger">*</span> }
            </span>
          </th>
        ) }
        <th>{ trans('actions') }</th>
      </tr>
      </thead>

      <tbody>
      { getRows().map((row, rowIndex) =>
        <tr key={ `row${ rowIndex }` }
            style={ isAddRow(rowIndex) ? { position: 'sticky', bottom: 0, zIndex: 1 } : {} }
            className={ isAddRow(rowIndex) ? 'bg-white' : '' }
        >
          { row.cells.map((cell, cellIndex) =>
            <td key={ `cell${ rowIndex }${ cellIndex }` }>
              <FormFillerColumn cell={ cell }
                                forceDisable={ !isAddRow(rowIndex) && !isRowBeingEdit(row) || isDisabled }
                                forceRequired={ cell.options[FieldOption.REQUIRED] === true }
                                fieldParent={ field }
              />
            </td>
          ) }
          <td>
            { isRowLoading(row.id) ?
              <Spinner variant="primary" animation="border" role="main">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              :
              <ButtonGroup>
                { canUpdateRow(row, rowIndex) &&
                  <Button className="me-1" variant="secondary" size="sm" onClick={ e => setEditingRow(row.id) }>
                    <i className="fas fa-pen"/>
                  </Button>
                }
                { isRowBeingEdit(row) &&
                  <Button className="me-1" variant="primary" size="sm"
                          onClick={ onRowSaveClick(row.id) }
                  >
                    <i className="fas fa-circle-check"/>
                  </Button>
                }
                { isAddRow(rowIndex) &&
                  <Button className="me-1" variant="primary" size="sm"
                          onClick={ onSaveNewRowClick }
                  >
                    <i className="fas fa-save"/>
                  </Button>
                }
                { canDeleteRow(row, rowIndex) &&
                  <Button className="me-1" variant="danger" size="sm" onClick={ onRowDeleteClick(row.id) }>
                    <i className="fas fa-trash"/>
                  </Button> }
                { isRowBeingEdit(row) &&
                  <Button variant="warning"
                          size="sm" onClick={ () => setEditingRow(null) }>
                    <i className="fas fa-rotate-left"/>
                  </Button> }
              </ButtonGroup>
            }
          </td>
        </tr>
      ) }
      </tbody>

    </Table>
  </div>
}

export default ExtensibleField
