import { useState } from 'react'
import { Options } from 'src/Components/Calendar/Types/Calendar'

export const useCalendarOptions = (initialState: Options) => {
  const saveOptionsToLocalStorage = (options: Options) =>
      localStorage.setItem('calendarOptions', JSON.stringify(options))

  const [ calendarOptions, setCalendarOptions ] = useState(() => {
    const options = JSON.parse(localStorage.getItem('calendarOptions'))

    return options || initialState
  })

  const handleSetOptions = (options: Options) => {
    saveOptionsToLocalStorage(options)
    setCalendarOptions(options)
  }

  return [ calendarOptions, handleSetOptions ]
}
