import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Dropdown, Button, Form, InputGroup } from 'react-bootstrap'
import { requestPatientCreation } from 'src/Components/PatientCreator/state/actions'
import { FIRST_NAME, LAST_NAME, NAME as formName } from 'src/Services/Constants/Config/Patient'
import { hasUserFormPermission } from 'src/Utils'
import { translate } from 'src/Services/translation'
import { FormPermissionAccessLevel } from 'src/Types/FormPermission'
import { Form as FormState } from 'src/Types/Form'
import { StoreState } from 'src/Services/Store/reducers'
import PatientSearchResultRow from './Components/PatientSearchResultRow'
import { launchFetchInputValue, resetPatientSearch,
  search,searchInstance, setIsSearchInputAction,
  setSearchInputValueAction } from './state/actions'
import translations from './translations'

const HeaderSearch = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    searchInputValue,
    searchResults,
    instanceData,
    isSearching
  } = useSelector((state: StoreState) => state.Header)
  const user = useSelector((state: StoreState) => state.Root.user)
  const { forms } = useSelector((state: StoreState) => state.Dictionary)

  const trans = translate(translations)(user.language)
  const isEmpty = !searchResults || searchResults.length === 0
  const formPatient = forms?.find((f: FormState) => f.systemName === formName)

  const [ showDropdown, setShowDropdown ] = useState(false)
  const [ isIdSearch, setIsIdSearch ] = useState(false)

  useEffect(() => {
    setIsIdSearch(searchInputValue?.startsWith(':'))

    if (searchInputValue?.startsWith(':'))
      setShowDropdown(searchInputValue.length > 1)
    else {
      if (searchInputValue?.length > 1) {
        dispatch(search(searchInputValue))
        setShowDropdown(true)
      }
    }
  }, [ searchInputValue ])

  useEffect(() => {
    if (instanceData?.id && isIdSearch) {
      if (instanceData.patient?.id) {
        navigate(`/patient/${ instanceData.patient?.id }/instance/${ instanceData.id }`)
      } else {
        navigate(`/instance/${ instanceData.id }`)
      }
    }
  }, [ instanceData ])

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement> ) => {
    if (e.target.value.length === 0)
      dispatch(resetPatientSearch())
    else
      dispatch(setSearchInputValueAction(e.target.value))
  }

  const onKeyUpChange = (e: React.KeyboardEvent<HTMLInputElement> ) => {
    if ((e.target as HTMLInputElement).value.length === 0)
      dispatch(resetPatientSearch())
    else
      dispatch(launchFetchInputValue())
  }

  const onInputFocus = () => {
    setShowDropdown(true)
    dispatch(setIsSearchInputAction(true))
  }

  const onInputBlur = () => {
    setTimeout(() => {
      setShowDropdown(false)
    }, 200)
  }

  const onPatientClick = () => {
    setShowDropdown(false)
    dispatch(setIsSearchInputAction(false))
    dispatch(resetPatientSearch())
  }

  const onSearchClose = () => {
    setShowDropdown(false)
    dispatch(resetPatientSearch())
  }

  const onInputEnterDown = () => {
    if (!isIdSearch)
      return

    const id = searchInputValue.split(':')[1]

    if (id)
      dispatch(searchInstance(id))

    setShowDropdown(false)
  }

  const onAddPatientClick = (searchInputValue: string) => () => {
    setShowDropdown(false)
    const patientSearchedValues = searchInputValue.split(',').map(s => s.trim())
    const patient: { [key: string]: string | null } = {
      [LAST_NAME]: patientSearchedValues[0] || null,
      [FIRST_NAME]: patientSearchedValues[1] || null
    }

    dispatch(requestPatientCreation(patient))
    dispatch(setIsSearchInputAction(false))
    dispatch(setSearchInputValueAction(''))
    dispatch(resetPatientSearch())
  }

  return (
    <Dropdown className={ `search-dropdown ${ showDropdown ? 'expanded' : '' }` } navbar show={ showDropdown }>
      <Dropdown.Toggle className={ 'is-transparent' }>
        <Form.Group>
          <InputGroup className={ 'search light' }>
            <InputGroup.Text className={ 'input-container' }>
              <i className={ 'fas fa-search' }/>
            </InputGroup.Text>
            <Form.Control onChange={ onInputChange }
                          onKeyUp={ onKeyUpChange }
                          onFocus={ onInputFocus }
                          onBlur={ onInputBlur }
                          onKeyDown={ e => e.key === 'Enter' && onInputEnterDown() }
                          name={ 'patient-search' }
                          placeholder={ trans('searchPatient') }
                          value={ searchInputValue }
                          size={ 'sm' }
                          autoComplete={ 'off' }
            />
            { showDropdown &&
              <InputGroup.Text className={ 'input-container' } onClick={ onSearchClose }>
                <i className={ 'fas fa-times' }/>
              </InputGroup.Text>
            }
          </InputGroup>
        </Form.Group>
      </Dropdown.Toggle>
      <Dropdown.Menu className={ 'search-results' }>
        { !isIdSearch && (
          <Dropdown.Item as={ 'span' }>
            { hasUserFormPermission(user, formPatient, FormPermissionAccessLevel.CREATE) &&
              <Button size={ 'sm' } variant={ 'outline-primary' }
                      onClick={ onAddPatientClick(searchInputValue) }
                      data-is={ 'resultItem' }
              >
                <i className={ 'me-1 fas fa-plus-circle' }/>
                <span>{ trans('addPatient') }</span>
              </Button> }
          </Dropdown.Item>
        ) }
        { isIdSearch && searchInputValue.length > 1 && (
          <Dropdown.Item as={ 'span' }>
            <Button size={ 'sm' } variant={ 'primary' } onClick={ () => onInputEnterDown() }>
              <span>{ trans('search') }</span>
            </Button>
          </Dropdown.Item>
        ) }
        { isSearching && !isIdSearch && searchInputValue.length > 1 &&
          <Dropdown.Item disabled>Loading...</Dropdown.Item> }
        { !isIdSearch && isEmpty && searchInputValue.length < 2 &&
          <Dropdown.Item disabled>{ trans('typeAtLeast') }</Dropdown.Item> }
        { !isSearching && !isIdSearch && isEmpty && searchInputValue.length > 1 &&
          <Dropdown.Item disabled>{ trans('noResults') }</Dropdown.Item> }
        { searchInputValue.length > 0 && !isEmpty && !isIdSearch && searchResults.map((patient: { id: number }) =>
          <PatientSearchResultRow key={ patient.id } patient={ patient } onClick={ onPatientClick }/>) }
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default HeaderSearch
