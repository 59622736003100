import React from 'react'
import { useDispatch } from 'react-redux'
import Select from 'react-select'
import { FieldEvent } from 'src/Views/FormFiller/Types/Field'
import { FieldOption } from 'src/Types/FieldOption'
import { fieldEvent } from '../../state/actions'

const MultiSelectField = ({ field, disabled = false, isRequired = false, }) => {
  const dispatch = useDispatch()

  const onChange = e => dispatch(fieldEvent(field, FieldEvent.VALUE_CHANGE, { value: e }))

  const getValue = () => {
    return field.value && field.value.length
      ? field.value.map(v =>
        field.options[FieldOption.VALUES].find(o => v.systemName === o.systemName || v === o.systemName)
      )
      : []
  }

  const getOptions = () => {
    const options = field.options[FieldOption.VALUES]

    return field.options[FieldOption.SORT_ALPHA] === true
      ? options.sort((a, b) => a.label > b.label ? 1 : -1)
      : options
  }

  return <>
    {
      field.options &&
      <Select
        id={`field${field.id}`}
        isClearable
        isMulti
        options={ getOptions() }
        value={ getValue() }
        getOptionValue={ o => o.systemName }
        getOptionLabel={ o => o.label }
        isDisabled={ disabled }
        onChange={ selected => onChange(selected.map(_ => _.systemName)) }
      />
    }
  </>
}

export default MultiSelectField
