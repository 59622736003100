import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AddRowButton } from './index'
import { addRow, reorderRow } from '../../state/actions'

const SectionFooter = ({ section, trans, formId }) => {
  const dispatch = useDispatch()

  const { draggedRow } = useSelector(state => state.FormEditor.formReducer)

  const onDragOver = e => draggedRow ? e.preventDefault() : null
  const addNewRow = () => dispatch(addRow(section.id, formId))
  const onDrop = e => draggedRow && dispatch(reorderRow(draggedRow, null, section))

  return <footer className="card-footer"
                 onDragOver={onDragOver}
                 onDrop={onDrop}
  >
    <AddRowButton onClick={ addNewRow }>
      <span>{ trans('addRow') }</span>
      <span className="icon">
          <i className="fas fa-lg fa-plus-circle"/>
        </span>
    </AddRowButton>
  </footer>
}

export default SectionFooter
