export default {
  english: {
    on: 'On',
    off: 'Off',
    activate: 'Activate',
    deactivate: 'Deactivate',
    empty: 'Empty',
    redo: 'Redo',
    undo: 'Undo',
    of: 'of',
    changelog: 'Changelog',
    unknown: 'Unknown',
    default: 'Default',
    logout: 'Logout',
    yes: 'Yes',
    no: 'No',
    none: 'None',
    id: 'ID',
    name: 'Name',
    rename: 'Rename',
    duration: 'Duration',
    type: 'Type',
    actions: 'Actions',
    options: 'Options',
    add: 'Add',
    edit: 'Edit',
    color: 'Color',
    test: 'Test',
    open: 'Open',
    save: 'Save',
    saveExit : 'Save & Exit',
    cancel: 'Cancel',
    delete: 'Delete',
    remove: 'Remove',
    clear: 'Clear',
    search: 'Search',
    clone: 'Clone',
    run: 'Run',
    about: 'About',
    listManager: 'List Manager',
    adminToolsManager: 'Admin Tools manager',
    submit: 'Submit',
    confirm: 'Confirm',
    restore: 'Restore',
    failed: 'Failed',
    saved: 'Saved',
    select: 'Select',
    reset: 'Reset',
    label: 'Label',
    status: 'Status',
    form: 'Form',
    dashboard: 'Dashboard',
    firstName: 'First name',
    lastName: 'Last name',
    username: 'Username',
    email: 'Email',
    events: 'Events',
    password: 'Password',
    field: 'Field',
    alerts: 'Alerts',
    columns: 'Columns',
    sections: 'Sections',
    documents: 'Documents',
    calendar: 'Calendar',
    informations: 'Informations',
    description: 'Description',
    roles: 'Roles',
    categories: 'Categories',
    users: 'Users',
    loading: 'Loading',
    time: 'Time',
    date: 'Date',
    fieldTypes: 'Field Types',
    week: 'Week',
    createdBy: 'Created by',
    create: 'Create',
    created: 'Created',
    modified: 'Modified',
    results: 'results',
    noResults: 'No results...',
    selectPlaceholder: 'Select...',
    searchPlaceholder: 'Search...',
    upload: 'Upload',
    download: 'Download',
    required: 'Required',
    help: 'Help',
    undefined: 'Undefined',
    after: 'After',
    today: 'Today',
    before: 'Before',
    month: {
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'October',
      november: 'November',
      december: 'December',
      abbr: {
        jan: 'Jan',
        feb: 'Feb',
        mar: 'Mar',
        apr: 'Apr',
        may: 'May',
        jun: 'Jun',
        jul: 'Jul',
        aug: 'Aug',
        sep: 'Sep',
        oct: 'Oct',
        nov: 'Nov',
        dec: 'Dec'
      }
    },
    weekday: {
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday',
      abbr: {
        mon: 'Mon',
        tue: 'Tue',
        wed: 'Wed',
        thu: 'Thu',
        fri: 'Fri',
        sat: 'Sat',
        sun: 'Sun'
      }
    },
    weekdayTemplate: {
      all: 'All',
      weekdays: 'Weekdays',
      weekends: 'Weekends',
      monWedFri: 'Mon/Wed/Fri',
      tueThu: 'Tue/Thu',
      none: 'None',
    },
    baseFieldTypes: {
      TEXT: 'Text',
      ESSAY: 'Essay',
      JSON: 'JSON',
      CHECKBOX: 'Checkbox',
      RADIO: 'Radio',
      DROPDOWN: 'Dropdown',
      HIDDEN: 'Hidden',
      REFERENCE: 'Reference',
      DATE_TIME: 'DateTime',
      SECTION_TEXT: 'Section text',
      HEADING: 'Heading',
      TIMEZONE: 'Timezone',
      INSTANCE_ID: 'InstanceId',
      FILE: 'File',
      EXTENSIBLE: 'Extensible',
      PSG_DATA: 'PSG Data',
      BUTTON: 'Button',
      SIGNATURE: 'Signature',
      EMAIL: 'Email',
      PHONE: 'Phone',
      ACCOUNT: 'Account',
      DOCUMENT: 'Document',
      FORMULA: 'Formula',
      SHIPPING_LABEL: 'Shipping label',
      ADDRESS: 'Address',
    },
    noModificationFound: 'No modification was found',
    fileType: {
      officeDocument: 'Office document',
      image: 'Image',
      xml: 'XML',
    },
    systemName: 'System name',
    formInvalid: 'Form invalid',
  },
  french: {
    on: 'Activé',
    off: 'Désactivé',
    activate: 'Activer',
    deactivate: 'Désactiver',
    of: 'sur',
    changelog: 'Changelog',
    empty: 'Vide',
    redo: 'Refaire',
    undo: 'Annuler',
    unknown: 'Inconnu',
    default: 'Défaut',
    logout: 'Déconnexion',
    yes: 'Oui',
    no: 'Non',
    none: 'Aucun',
    id: 'ID',
    add: 'ajouter',
    name: 'Nom',
    rename: 'Renommer',
    type: 'Type',
    actions: 'Actions',
    options: 'Choix',
    edit: 'Modifier',
    open: 'Ouvrir',
    save: 'Enregistrer',
    saveExit : 'Enregistrer & Quitter',
    cancel: 'Annuler',
    delete: 'Supprimer',
    remove: 'Supprimer',
    clear: 'Vider',
    search: 'Rechercher',
    clone: 'Cloner',
    run: 'Exécuter',
    submit: 'Valider',
    confirm: 'Confirmer',
    restore: 'Restaurer',
    failed: 'Échoué',
    saved: 'Sauvegardé',
    select: 'Sélectionner',
    reset: 'Réinitialiser',
    label: 'Libellé',
    form: 'Formulaire',
    status: 'Statut',
    firstName: 'Prénom',
    about: 'Sur',
    events: 'Événements',
    lastName: 'Nom',
    listManager: 'Gestionnaire de listes',
    adminToolsManager: 'Gestionnaire d\'outil admin',
    username: 'Nom d\'utilisateur',
    email: 'Email',
    password: 'Mot de passe',
    field: 'Champ',
    alerts: 'Alertes',
    columns: 'Colonnes',
    sections: 'Sections',
    documents: 'Documents',
    calendar: 'Calendrier',
    informations: 'Informations',
    description: 'Description',
    roles: 'Rôles',
    categories: 'Catégories',
    users: 'Utilisateurs',
    dashboard: 'Tableau de bord',
    loading: 'Chargement',
    time: 'Heure',
    date: 'Date',
    week: 'Semaine',
    createdBy: 'Créé par',
    created: 'Créé',
    create: 'Créer',
    fieldTypes: 'Types de champs',
    modified: 'Modifié',
    results: 'résultats',
    noResults: 'Aucun résultat...',
    selectPlaceholder: 'Sélectionner...',
    searchPlaceholder: 'Rechercher...',
    undefined: 'Non définis',
    upload: 'Envoyer',
    download: 'Télécharger',
    required: 'Requis',
    color: 'Couleur',
    test: 'Test',
    help: 'Aide',
    after: 'Après',
    today: 'Aujourd\'hui',
    before: 'Avant',
    duration: 'Durée',
    month: {
      january: 'Janvier',
      february: 'Février',
      march: 'Mars',
      april: 'Avril',
      may: 'Mai',
      june: 'Juin',
      july: 'Juillet',
      august: 'Août',
      september: 'Septembre',
      october: 'Octobre',
      november: 'Novembre',
      december: 'Décembre',
      abbr: {
        jan: 'Jan',
        feb: 'Fév',
        mar: 'Mar',
        apr: 'Avr',
        may: 'Mai',
        jun: 'Juin',
        jul: 'Juil',
        aug: 'Août',
        sep: 'Sep',
        oct: 'Oct',
        nov: 'Nov',
        dec: 'Déc'
      }
    },
    weekday: {
      monday: 'Lundi',
      tuesday: 'Mardi',
      wednesday: 'Mercredi',
      thursday: 'Jeudi',
      friday: 'Vendredi',
      saturday: 'Samedi',
      sunday: 'Dimanche',
      abbr: {
        mon: 'Lun',
        tue: 'Mar',
        wed: 'Mer',
        thu: 'Jeu',
        fri: 'Ven',
        sat: 'Sam',
        sun: 'Dim'
      }
    },
    weekdayTemplate: {
      all: 'Tous',
      weekdays: 'Semaine',
      weekends: 'Weekends',
      monWedFri: 'Lun/Mer/Ven',
      tueThu: 'Mar/Jeu',
      none: 'Aucun',
    },
    baseFieldTypes: {
      TEXT: 'Texte',
      ESSAY: 'Texte long',
      JSON: 'JSON',
      CHECKBOX: 'Case à cocher multiple',
      RADIO: 'Case à cocher unique',
      DROPDOWN: 'Sélecteur',
      HIDDEN: 'Caché',
      REFERENCE: 'Reférence',
      DATE_TIME: 'Date et heure',
      SECTION_TEXT: 'Texte de section',
      HEADING: 'Titre',
      TIMEZONE: 'Fuseau horaire',
      INSTANCE_ID: 'ID d\'instance',
      FILE: 'Fichier',
      EXTENSIBLE: 'Extensible',
      PSG_DATA: 'Données PSG',
      BUTTON: 'Bouton',
      SIGNATURE: 'Signature',
      EMAIL: 'Email',
      PHONE: 'Téléphone',
      ACCOUNT: 'Compte',
      DOCUMENT: 'Document',
      FORMULA: 'Formule',
      SHIPPING_LABEL: 'Etiquette de livraison',
      ADDRESS: 'Adresse',
    },
    noModificationFound: 'Le formulaire comporte aucune modification',
    fileType: {
      officeDocument: 'Document',
      image: 'Image',
      xml: 'XML',
    },
    systemName: 'Nom système',
    formInvalid: 'Formulaire invalide',
  }
}
