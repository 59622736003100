import {
  CREATE_FIELD_TYPE,
  FIELD_TYPE_CREATED,
  ERROR_CREATING_FIELD_TYPE,
  DELETE_FIELD_TYPE,
  ERROR_DELETING_FIELD_TYPE,
  CHANGE_LIMIT,
  CHANGE_PAGE,
  FETCH_FIELD_TYPES,
  RECEIVE,
  SEARCH_FIELD_TYPE,
  TOGGLE_CREATE_FIELD_TYPE,
  RECEIVE_FIELD_TYPES,
  FETCH_BASE_FIELD_TYPES,
  RECEIVE_BASE_FIELD_TYPES,
  FIELD_TYPE_DELETED,
  FIELD_TYPE_UPDATED,
  UPDATE_FIELD_TYPE,
  TOGGLE_UPDATE_FIELD_TYPE, TOGGLE_DELETE_FIELD_TYPE
} from './actionTypes'

export const fetchFieldTypes = () => ({
  type: FETCH_FIELD_TYPES
})

export const fetchBaseFieldTypes = () => ({
  type: FETCH_BASE_FIELD_TYPES
})

export const receive = (data) => ({
  type: RECEIVE,
  payload: data
})

export const receiveFieldTypes = (data) => ({
  type: RECEIVE_FIELD_TYPES,
  payload: data
})

export const receiveBaseFieldTypes = (data) => ({
  type: RECEIVE_BASE_FIELD_TYPES,
  payload: data
})

export const searchFieldType = (data) => ({
  type: SEARCH_FIELD_TYPE,
  payload: data
})

export const changeLimit = (data) => ({
  type: CHANGE_LIMIT,
  payload: data
})

export const changePage = (data) => ({
  type: CHANGE_PAGE,
  payload: data
})

export const toggleCreateFieldType = () => ({
  type: TOGGLE_CREATE_FIELD_TYPE
})

export const toggleUpdateFieldType = fieldType => ({
  type: TOGGLE_UPDATE_FIELD_TYPE,
  payload: fieldType
})

export const toggleDeleteFieldType = fieldType => ({
  type: TOGGLE_DELETE_FIELD_TYPE,
  payload: fieldType
})

export const createFieldType = (data) => ({
  type: CREATE_FIELD_TYPE,
  payload: data
})

export const fieldTypeCreated = (data) => ({
  type: FIELD_TYPE_CREATED,
  payload: data
})

export const updateFieldType = (data) => ({
  type: UPDATE_FIELD_TYPE,
  payload: data
})

export const fieldTypeUpdated = (data) => ({
  type: FIELD_TYPE_UPDATED,
  payload: data
})

export const deleteFieldType = () => ({
  type: DELETE_FIELD_TYPE
})

export const fieldTypeDeleted = id => ({
  type: FIELD_TYPE_DELETED,
  payload: id
})
