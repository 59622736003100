export type Iso8601 = string & { __Iso8601StringBrand: never }
export type Iso8601Offset = string & { __Iso8601OffsetStringBrand: never }

export function isIso8601String(value: string): value is Iso8601 {
  const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{3})?(?:Z|[+-]\d{2}:\d{2})$/
  return regex.test(value)
}

export function isIso8601OffsetString(value: string): value is Iso8601Offset {
  const regex = /^([+-])(\d{2}):(\d{2})$/
  return regex.test(value)
}
