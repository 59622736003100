import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { translate } from '../../../Services/translation/index'
import translation from '../translations'
import { getSectionPermissions } from '../selectors'
import SectionPermissionForm from './SectionPermissionForm'

const SectionContent = ({ form }) => {
  const dispatch = useDispatch()

  const { language } = useSelector(state => state.Root.user)
  const sectionPermissions = useSelector(state => getSectionPermissions(state.Permissions))

  const trans = translate(translation)(language)

  const getPermissionBySection = section => sectionPermissions.find(p => section.id === p.section.id) || null

  return <>
    { form.sections.map(section =>
      <SectionPermissionForm key={ `form${ form.id }section${ section.id }` }
                             permission={ getPermissionBySection(section) } section={ section }
      />) }
  </>
}

export default SectionContent
