import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import _ from 'lodash'
import { translate } from 'src/Services/translation'
import WorkflowFilter from './Filter.tsx'
import translation from './translations'
import { setActionFilter, setFormFilter, setRoleFilter,  setTypeFilter, resetFilters } from '../state/actions'

const WorkflowFilters = () => {
  const dispatch = useDispatch()

  const { language } = useSelector(state => state.Root.user)
  const { actionFilter, formFilter, roleFilter } = useSelector(state => state.WorkflowManager)
  const {
    eventStatuses, forms, roles, workflowActionTypes, workflowTriggerFormEvents
  } = useSelector(state => state.Dictionary)

  const trans = translate(translation)(language)

  const formEventList = [
    { label: trans('plainFormEvent'), value: 'formEvent' },
    ...workflowTriggerFormEvents.map(event => ({
      label: trans('formEvent', { event: _.capitalize(event) }),
      value: event
    }))
  ]

  const eventStatusList = [
    { label: trans('plainEventStatus'), value: 'eventStatus' },
    ...eventStatuses.map(({ label, systemName }) => ({
      label: trans('eventStatus', { status: label }),
      value: systemName
    }))
  ]

  const actionOptions = workflowActionTypes.map(action => ({ label: action, value: action }))
  const formOptions = forms.map(form => ({ label: form.label, value: form.systemName }))
  const roleOptions = roles.map(role => ({ label: role.label, value: role.systemName }))
  const typeOptions = [
    { label: trans('fieldEvent'), value: 'fieldEvent' },
    ...formEventList,
    ...eventStatusList
  ]

  return (
    <div className="workflow-filters">
      <Row>
        <Col>
          <WorkflowFilter
            label={trans('action')}
            options={actionOptions}
            value={actionFilter}
            onChange={option => option ? dispatch(setActionFilter(option)) : dispatch(resetFilters())}
          />
        </Col>

        <Col>
          <WorkflowFilter
            label={trans('form')}
            options={formOptions}
            value={formFilter}
            onChange={option => option ? dispatch(setFormFilter(option)) : dispatch(resetFilters())}
          />
        </Col>

        <Col>
          <WorkflowFilter
            label={trans('type')}
            options={typeOptions}
            onChange={option => option ? dispatch(setTypeFilter(option)) : dispatch(resetFilters())}
          />
        </Col>

        <Col>
          <WorkflowFilter
            label={trans('role')}
            options={roleOptions}
            value={roleFilter}
            onChange={option => option ? dispatch(setRoleFilter(option)) : dispatch(resetFilters())}
          />
        </Col>
      </Row>
    </div>
  )
}

export default WorkflowFilters
