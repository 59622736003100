import React, { ChangeEvent } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { FieldEvent } from 'src/Views/FormFiller/Types/Field'
import { Field, FieldOption } from 'src/Types/Field'
import { fieldEvent } from '../../state/actions'

export interface Props {
  field: Field
  isDisabled: boolean
  isRequired: boolean
}

const SelectField = ({ field, isDisabled = false, isRequired = false }: Props) => {
  const dispatch = useDispatch()

  const getValue = (): string | null => (field.value as FieldOption)?.systemName || (field.value as string) || null
  const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    dispatch(fieldEvent(field, FieldEvent.VALUE_CHANGE, { value: e.target.value || null }))
  }

  const getOptions = () => field.options.sortAlpha === true
    ? field.options.values.sort((a, b) => a.label > b.label ? 1 : -1)
    : field.options.values

  return <Form.Select id={ `field${ field.id }` }
                      className={ 'select-field' }
                      value={ getValue() || '' }
                      onChange={ onChange }
                      disabled={ isDisabled }
                      size={ 'sm' }
                      required={ isRequired }
  >
    <option value={ '' }></option>
    { getOptions().map((option, i) =>
      <option value={ option.systemName } key={ option.systemName }>{ option.label }</option>) }
  </Form.Select>
}

export default SelectField
