import React, { useEffect, useState } from 'react'
import { withState } from 'src/Components/Util'
import { translate, translateConf } from 'src/Services/translation'
import { path } from 'ramda'
import { Button, ButtonGroup } from 'react-bootstrap'
import translation from '../../translations'
import NavTable from '../../../../Components/NavTable'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeLimitRestoreDeletedFields,
  changePage,
  fetchDeletedFields,
  fetchRestoreField,
  searchDeletedField
} from './state/actions'
import { toggleConfirmModal } from 'src/Services/Store/GlobalControls/actions'

const propsFromState = {
  language: path([ 'Root', 'user', 'language' ]),
  pagination: path([ 'RestoreDeletedFields', 'pagination' ]),
  loading: path([ 'RestoreDeletedFields', 'loading' ]),
  deletedFields: path([ 'RestoreDeletedFields', 'deletedFields' ])
}

const ActionCell = ({ onClick = () => {}, trans }) =>
  <ButtonGroup>
    <Button variant={ 'outline-info' } size={ 'sm' } onClick={ onClick }>
      <i className="bi bi-arrow-counterclockwise"></i> { trans('restore') }
    </Button>
  </ButtonGroup>

// View :: Props -> React.Element
const RestoreDeletedFields = ({
  language,
  pagination,
  loading,
  deletedFields
}) => {
  const trans = translate(translation)(language)
  const columnHeaders = [ trans('label'), trans('form'), trans('systemName'), trans('actions') ]
  const dispatch = useDispatch()
  const [ fetchDeletedFieldsRequest, setDeletedFieldsRequest ] = useState(null)
  const { configTranslations } = useSelector(state => state.Root)

  const transConf = translateConf(configTranslations)

  useEffect(() => {
    dispatch(fetchDeletedFields())
  }, [ dispatch ])

  const onLimitChange = dispatch => limit => {
    dispatch(changeLimitRestoreDeletedFields(limit))
    dispatch(fetchDeletedFields())
  }

  const onRestoreClick = item => {
    dispatch(toggleConfirmModal(() => {
      dispatch(fetchRestoreField(item))
      applyNewFilters()
    }))
  }

  const applyNewFilters = () => {
    clearTimeout(fetchDeletedFieldsRequest)
    setDeletedFieldsRequest(setTimeout(() => dispatch(fetchDeletedFields()), 1000))
  }

  const onSearchInputChange = e => {
    e.preventDefault()
    dispatch(searchDeletedField(e.target.value))
    applyNewFilters()
  }

  const onPageClick = (currentPage, page) => {
    if (currentPage === page)
      return
    dispatch(changePage(page))
    dispatch(fetchDeletedFields())
  }

  const getRows = () =>
    deletedFields.map(item => [
      <>{ item.label }</>,
      <>{ transConf('FORM')(item.form) }</>,
      <>{ item.systemName }</>,
      <ActionCell onClick={ () => onRestoreClick(item) } trans={ trans }/>
    ])

  return (
    <NavTable pagination={ pagination }
      rows={ getRows() }
      columnHeaders={ columnHeaders }
      onSearchInputChange={ onSearchInputChange }
      onLimitChange={ onLimitChange(dispatch) }
      onPageClick={ onPageClick }
      isLoading={ loading }
    />
  )
}

export default withState(propsFromState)(RestoreDeletedFields)
