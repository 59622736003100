import React from 'react'
import styled from 'styled-components'
import { commonFormFieldStyle, specificFormFieldStyle, TargetBody } from './shared'

interface EmptyFieldProps {
  isDragging?: boolean
  trans: (key: string) => string
}

const EmptyField: React.FC<EmptyFieldProps> = ({ isDragging = false, trans }) =>
    <StyledEmptyField className="droppable">
        <TargetBody className={isDragging ? 'undroppable-target' : ''}>
            <span>{trans('emptyPlaceholder.dragDropOr')}</span>
        </TargetBody>
    </StyledEmptyField>

const StyledEmptyField = styled.div`
  ${commonFormFieldStyle}
  ${specificFormFieldStyle}

  &:hover {
    box-shadow: 0 0 5px 0 ${props => props.theme.colors.grey.alpha.dark(0.18)};
  }

  span {
    color: ${props => props.theme.colors.grey.light};
    font-style: italic;
    margin: auto 0.5rem;

    strong {
      color: ${props => props.theme.colors.secondary.dark};
      font-weight: 400;
      text-decoration: underline;
    }
  }
`

export default EmptyField
