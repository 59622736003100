import React, { ChangeEvent, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import NavTable from 'src/Components/NavTable'
import { formatDateTime } from 'src/Utils/Date'
import translation from '../translations'
import { changePage, changeLimit, searchUser, sortUsers } from '../state/actions'
import { translate } from 'src/Services/translation'
import { getLocationNameById } from 'src/Services/UserHelpers'
import { SortItem } from 'src/Types/Pagination'
import { Instance } from 'src/Types/Instance'
import ActionButtons from './ActionButtons'
import { StoreState } from 'src/Services/Store/reducers'

const UserListTable = () => {
  const dispatch = useDispatch()
  const searchRequestTimeout = useRef()

  const { language, locale, portalTimezone } = useSelector((state: StoreState) => state.Root.user)
  const { loading, users, pagination, sortItem } = useSelector((state: StoreState) => state.UserList)
  const { locations } = useSelector((state: StoreState) => state.Dictionary)

  const trans = translate(translation)(language)
  const formatLocaleDateTime = formatDateTime(locale, portalTimezone)

  const onSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value

    e.preventDefault()
    clearTimeout(searchRequestTimeout.current)
    // @ts-ignore
    searchRequestTimeout.current = setTimeout(() => dispatch(searchUser(searchTerm)), 1000)
  }

  const formatUserLocationNames = (locations: Instance[]|string[], locationTypes: Instance[]) =>
      locations
          .map(location => getLocationNameById(
              typeof location === 'string' ? location : location.id, locationTypes
          ))
          .join('; ')


  const onPageClick = (currentPage: number, page: number) => {
    if (currentPage !== page) {
      dispatch(changePage(page))
    }
  }

  const onLimitChange = (limit: number) => dispatch(changeLimit(limit))

  const onSortUserClick = (sortItem: SortItem) => dispatch(sortUsers(sortItem))

  const columnHeaders = [
    { isSortable: true, label: trans('name'), id: 'name' },
    { isSortable: true, label: trans('username'), id: 'username' },
    { isSortable: false, label: trans('th.activeRoles'), id: 'activeRoles' },
    { isSortable: false, label: trans('th.locations'), id: 'locations' },
    { isSortable: true, label: trans('email'), id: 'email' },
    { isSortable: true, label: trans('th.lastLogin'), id: 'lastLogin' },
    { isSortable: false, label: trans('actions'), id: 'actions' }
  ]

  const getUsersAsRows = () =>
    users.map(user => [
      `${ user.firstName } ${ user.lastName.toUpperCase() }`,
      user.username,
      user.roles.map(({ name }) => name).join(', '),
      formatUserLocationNames(user.locations, locations),
      user.email,
      user.lastLogin ? formatLocaleDateTime(user.lastLogin.createdAt) : trans('none'),
      <ActionButtons key={user.id} user={ user } />
    ])

  return (
    <NavTable pagination={ pagination }
              rows={ getUsersAsRows() }
              columnHeaders={ columnHeaders }
              sortItem={ sortItem }
              onLimitChange={ onLimitChange }
              onPageClick={ onPageClick }
              onSearchInputChange={ onSearchInputChange }
              onSortUserClick={ onSortUserClick }
              isLoading={ loading }
    />
  )
}

export default UserListTable
