export default {
  english: {
    customAction: {
      text: 'Add dynamic value',
      pasteSignaturePlaceholderTooltipLabel: 'Add signature placeholder to allow users to sign the document.',
      pasteSignaturePlaceholderLabel: 'Add signature placeholder',
      pasteSignaturePlaceholderTemplate: 'SIGNATURE HERE',
    },
    modal: {
      systemField: {
        title: 'Add dynamic value',
        placeholder: 'Please enter a system field name',
        label: 'System Field Name',
        button: 'Insert'
      },
    },
    signByAndAt: (firstName, lastName, credentials, at) =>
      credentials
        ? `Electronically signed by ${firstName} ${lastName}, ${credentials || ''} on ${at}.`
        : `Electronically signed by ${firstName} ${lastName} on ${at}.`,
    addAPlaceholderToSign: 'Please add a placeholder in the template to sign.',
  },
  french: {
    customAction: {
      text: 'Ajouter une valeur dynamique',
      pasteSignaturePlaceholderTooltipLabel: 'Ajouter une zone de signature pour permettre aux utilisateurs de signer le document.',
      pasteSignaturePlaceholderLabel: 'Ajouter une zone de signature',
      pasteSignaturePlaceholderTemplate: 'SIGNATURE ICI',
    },
    modal: {
      systemField: {
        title: 'Ajouter une valeur dynamique',
        placeholder: 'Veuillez entrer un system field name',
        label: 'System Field Name',
        button: 'Insérer'
      },
    },
    signByAndAt: (firstName, lastName, credentials, at) =>
      credentials
        ? `Signé électroniquement par ${firstName} ${lastName}, ${credentials || ''} le ${at}.`
        : `Signé électroniquement par ${firstName} ${lastName} le ${at}.`,
    addAPlaceholderToSign: 'Veuillez ajouter le placeholder dans la template pour signer.',
  }
}
