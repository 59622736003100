import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { translate } from 'src/Services/translation'
import Modal from 'src/Components/Modal'
import { FieldOptionAutocompleteFieldType } from 'src/Types/Field'
import { FieldOption } from 'src/Types/FieldOption'
import AutocompleteField from './AutocompleteField'
import { toggleAutocompleteFieldsModal, updateFieldOption } from '../state/actions'
import translations from '../translations'

const AutocompleteFieldsModal = () => {
  const dispatch = useDispatch()
  const { language } = useSelector(state => state.Root.user)
  const { isAutocompleteFieldsModalOpen, editedField } = useSelector(state => state.FormEditor.formReducer)

  const trans = translate(translations)(language)

  const [ fields, setFields ] = useState([])

  useEffect(() => {
    setFields(editedField?.options?.[FieldOption.AUTOCOMPLETE_FIELDS] || [])
  }, [ editedField ])

  const addField = e =>
    setFields(fields => [ ...fields, { field: null, value: null, type: FieldOptionAutocompleteFieldType.CONSTANT } ])
  const onFieldChange = i => field =>
    setFields(fields => fields.map((f, j) => i === j ? field : f))

  const closeModal = () => {
    dispatch(toggleAutocompleteFieldsModal())
  }

  const onSubmit = e => {
    e.preventDefault()

    dispatch(updateFieldOption(FieldOption.AUTOCOMPLETE_FIELDS, fields))
    closeModal()
  }

  const onFieldDelete = i => e =>
    setFields(fields => fields.filter((f, j) => i !== j))

  return <Modal title={ trans('modal.autocompleteFields.title') }
                onClose={ closeModal }
                isOpen={ isAutocompleteFieldsModalOpen }
                size="lg"
                id="autocomplete-fields-modal"
  >
    <Form onSubmit={ onSubmit }>
      <Row>
        <Col sm={5}>
          <Form.Label className="fw-bold">{ trans('modal.autocompleteFields.targetField') }</Form.Label>
        </Col>
        <Col sm={6}>
          <Form.Label className="fw-bold">{ trans('modal.autocompleteFields.value') }</Form.Label>
        </Col>
        <Col sm={1}></Col>
      </Row>
      { fields.map((field, i) =>
        <AutocompleteField key={i} item={field} onChange={onFieldChange(i)} onDelete={onFieldDelete(i)} />) }
      <Button variant="outline-primary" onClick={ addField } size="sm">
        { trans('editField.addValue') }
      </Button>
      <Button className="float-end" onClick={ onSubmit }>{ trans('submit') }</Button>
    </Form>
  </Modal>
}

export default AutocompleteFieldsModal
