import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Badge, Form, Button, Container, Row, Col, Card, ButtonGroup } from 'react-bootstrap'
import PhoneInput from 'react-phone-input-2'
import { translate } from 'src/Services/translation'
import { setOptions } from 'src/Layouts/View/state/actions'
import translation from 'src/Views/UserForm/translations'
import Select from 'react-select'
import { AuditTrailLogFields } from 'src/Types/AuditTrailLog'
import { ColumnValueType, FilterOperator } from 'src/Types/RequestFilter'
import { SortItemDirection } from 'src/Types/Pagination'
import { hasUserModulePermission } from 'src/Utils'
import { MODULE_AUDIT_TRAILS } from 'src/Services/Constants/Config/Modules'
import { PermissionAccessLevel } from 'src/Types/Permission'
import { fetchUser, createUser, updateUser, deleteUser, setNewUser,
        reset, blockUser, unblockUser } from 'src/Views/UserForm/state/actions'
import AuditTrailsModal from '../../Components/AuditTrailsModal'
import 'react-phone-input-2/lib/style.css'
import { Instance } from 'src/Types/Instance'
import { StoreState } from 'src/Services/Store/reducers'

const UserForm = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  const { user: appUser } = useSelector((state: StoreState) => state.Root)
  const { user, newUser } = useSelector((state: StoreState) => state.UserForm)
  const { roles, locations } = useSelector((state: StoreState) => state.Dictionary)

  const trans = translate(translation)(appUser.language)

  const getAvailableRoles = () => {
      return newUser
        ? roles.filter(
          r => r.id !== newUser.primaryRole?.id &&
          !newUser?.secondaryRoles?.some(s => s.id === r.id)
        )
        : roles
  }

  const [ formErrors, setFormErrors ] = useState<any>({})
  const [ hasFormChange, setHasFormChange ] = useState(false)
  const [ isChangelogModalOpen, setIsChangelogModalOpen ] = useState(false)
  const [ invalid, setIsInvalid ] = useState(false)

  const onChangePhoneMobile = (value: string) => {
    if (value !== '' && !value.match(/^[0-9]{11,14}$/)) {
      setIsInvalid(true)
    } else {
      setIsInvalid(false)
      dispatch(setNewUser({ ...newUser, mobilePhone: value }))
    }
  }
  const changeLogFilter = useMemo(() => ({
    offset: 0,
    limit: 100,
    sortItems: [ { column: AuditTrailLogFields.TIMESTAMP, direction: SortItemDirection.DESC } ],
    filters: [
      {
        column: AuditTrailLogFields.CONTEXT,
        type: ColumnValueType.STRING,
        operator: FilterOperator.EQUAL,
        value: 'USER'
      },
      {
        column: AuditTrailLogFields.CONTEXT_IDENTIFIER,
        type: ColumnValueType.STRING,
        operator: FilterOperator.EQUAL,
        value: id
      },
    ]
  }), [ id ])

  useEffect(() => {
    if (!id && !newUser)
      dispatch(setNewUser({
        id: null,
        email: '',
        firstName: '',
        lastName: '',
        username: '',
      }))
    else if (id && !user)
      dispatch(fetchUser(id))
    else if (user && !newUser)
      dispatch(setNewUser(user))
  }, [ id, dispatch, user, newUser ])

  // Component unmount
  useEffect(() =>
      () => {
        dispatch(reset())
      }
    , [])

  const validateForm = () => {
    let errors = {}
    setFormErrors(errors)

    if (!id && !newUser.password)
      errors = { ...errors, password: 'Password is required' }
    if (newUser.password !== newUser.confirmPassword)
      errors = { ...errors, confirmPassword: 'Field does not match password' }
    if (!newUser.firstName)
      errors = { ...errors, firstName: 'First name is required' }
    if (!newUser.lastName)
      errors = { ...errors, lastName: 'Last name is required' }
    if (!newUser.username)
      errors = { ...errors, username: 'Username is required' }
    if (!newUser.email)
      errors = { ...errors, email: 'Email is required' }
    if (!newUser.primaryRole?.id)
      errors = { ...errors, primaryRole: 'Primary role is required' }

    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  const saveUser = () =>
    dispatch(id ? updateUser(id, newUser, navigate) : createUser(newUser, navigate))

  const onClickSaveUser = () => validateForm() && saveUser()
  const onClickBlockUser = () => dispatch(blockUser(id))
  const onClickUnblockUser = () => dispatch(unblockUser(id))
  const onClickDeleteUser = () => dispatch(deleteUser(id, navigate))
  const onClickEditPermissions = () => navigate(`/permissions/user/${ id }`)

  const onChangelogClick = () => setIsChangelogModalOpen(true)
  const onModalChangelogClose = () => setIsChangelogModalOpen(false)

  const renderButtons = () =>
    <>
      <Button className="me-1" size="sm" variant="danger" disabled={ !id } onClick={ onClickDeleteUser }>
        <i className="me-1 fas fa-trash-alt"/>
        <span>{ trans('delete') }</span>
      </Button>
      <ButtonGroup className="me-1">
        <Button className="text-white" size="sm" variant="warning" disabled={ !id }
                onClick={ onClickBlockUser }>
          <i className="me-1 fas fa-lock"/>
          <span>{ trans('block') }</span>
        </Button>
        <Button size="sm" variant="outline-warning" disabled={ !id } onClick={ onClickUnblockUser }>
          <i className="me-1 fas fa-lock-open"/>
          <span>{ trans('unblock') }</span>
        </Button>
      </ButtonGroup>
      <ButtonGroup className="me-1">
        { hasUserModulePermission(appUser, MODULE_AUDIT_TRAILS, PermissionAccessLevel.READ) &&
          <Button variant="secondary" size="sm" onClick={ onChangelogClick }>
            <i className="me-1 fas fa-history"/>
            <span>{ trans('btnAuditLog') }</span>
          </Button> }
        <Button size="sm" disabled={ !id } variant={ 'outline-secondary' }
                onClick={ onClickEditPermissions }>
          <i className="me-1 far fa-check-square"/>
          <span>{ trans('editPermissions') }</span>
        </Button>
      </ButtonGroup>
      <Button disabled={ !hasFormChange } onClick={ onClickSaveUser }>
        <i className="me-1 far fa-save"></i>
        <span>{ trans('save') }</span>
      </Button>
    </>

  useEffect(() => {
    dispatch(setOptions({
      title: id
        ? <>
          <h1>{ user?.username || '' }</h1>
          { user?.authInformations?.isBlocked && <Badge bg="danger">{ trans('blocked') }</Badge> }
        </> : trans('title.createUser'),
      hasPrimaryButton: false,
      rightHeaderTemplate: renderButtons()
    }))
  }, [ dispatch, id, user, hasFormChange, newUser ])


  const onFieldChange = (field: string, value: any) => {
    dispatch(setNewUser({ ...newUser, [field]: value }))
  }

  useEffect( () => {
    setHasFormChange(JSON.stringify(newUser) !==  JSON.stringify(user))
  }, [ newUser ])

  return <Container>
    <Card>
      <Card.Body>
      <Row className={ 'mb-2' }>
        <Col>
          <Form.Group>
            <Form.Label aria-required={ true }>{ trans('firstName') } <span>*</span></Form.Label>
            <Form.Control name="firstName" required value={ newUser?.firstName || '' }
                          onChange={ e => onFieldChange('firstName', e.target.value) }/>
            <Form.Text className="text-danger">{ formErrors.firstName }</Form.Text>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>{ trans('lastName') } <span>*</span></Form.Label>
            <Form.Control name="lastName" required value={ newUser?.lastName || '' }
                          onChange={ e => onFieldChange('lastName', e.target.value) }/>
            <Form.Text className="text-danger">{ formErrors.lastName }</Form.Text>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>{ trans('username') } { id ? '' : <span> *</span> }</Form.Label>
            <Form.Control name="username" required={ !id } disabled={ !!id } value={ newUser?.username || '' }
                          onChange={ e => onFieldChange('username', e.target.value) }/>
            <Form.Text className="text-danger">{ formErrors.username }</Form.Text>
          </Form.Group>
        </Col>
      </Row>
      <Row className={ 'mb-2' }>
        <Col md={4}>
          <Form.Group>
            <Form.Label>{ trans('fields.pin') }</Form.Label>
            <Form.Control name="pin" value={ newUser?.pin || '' } type={ 'password' } maxLength={4}
                          onChange={ e => onFieldChange('pin', e.target.value) } />
            <Form.Text className="text-danger">{ formErrors.pin }</Form.Text>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group>
            <Form.Label>{ trans('fields.mobilePhone') }</Form.Label>
            <PhoneInput
              country={ appUser.locale.toLowerCase() }
              preferredCountries={ appUser.locale.toLowerCase() === 'us'
                                   ? [ 'us' ] : [ 'fr', 'es', 'de', 'pt', 'it', 'be', 'ch', 'li',' lu', 'mc' ] }
              enableSearch={ true }
              value={ newUser?.mobilePhone || ''}
              onChange={ (value, data) => onChangePhoneMobile(value) }
              inputClass="form-control form-control-sm w-100"
              isValid={ value => !invalid }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col >
          <Form.Group>
            <Form.Label>{ trans('fields.password') } { user?.id ? '' : <span> *</span> }</Form.Label>
            <Form.Control name="password" required={ !id } type={ 'password' }
                          value={ newUser?.password || '' }
                          onChange={ e => onFieldChange('password', e.target.value) }/>
            <Form.Text className="text-danger">{ formErrors.password }</Form.Text>
          </Form.Group>
        </Col>
        <Col >
          <Form.Group>
            <Form.Label>{ trans('fields.confirmPassword') } { user?.id ? '' : <span> *</span> }</Form.Label>
            <Form.Control name="confirmPassword" required={ !id } type={ 'password' }
                          value={ newUser?.confirmPassword || '' }
                          onChange={ e => onFieldChange('confirmPassword', e.target.value) }/>
            <Form.Text className={ 'text-danger' }>{ formErrors.confirmPassword }</Form.Text>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <Form.Group>
            <Form.Switch label={ trans('fields.useTwoFactorAuth') } name="useTwoFactor" disabled
                         checked={ newUser?.useTwoFactor || false }
                         onChange={ e => onFieldChange('useTwoFactor', e.target.checked) }/>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Switch label={ trans('fields.isProvider') } name="isProvider"
                         checked={ newUser?.isProvider || false }
                         onChange={ e => onFieldChange('isProvider', e.target.checked) }/>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <Form.Group>
            <Form.Label>{ trans('email') } <span>*</span></Form.Label>
            <Form.Control name="email" required type="email" value={ newUser?.email || '' }
                          onChange={ e => onFieldChange('email', e.target.value) }/>
            <Form.Text className="text-danger">{ formErrors.email }</Form.Text>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>{ trans('fields.locations') }</Form.Label>
            <Select isMulti
                    value={ (newUser?.locations as Instance[]) || [] }
                    name={ 'userLocations' }
                    getOptionLabel={ _ => _.identityString }
                    getOptionValue={ _ => _.id }
                    // Not very good to get deleted fields in frontend, done it to avoid side effect
                    //TODO filter it in backend after checking that it dos not cause any side effect
                    options={ locations.filter(_ => null === _.deletedAt) }
                    onChange={ value => onFieldChange('locations', value) }
            />
            <Form.Text className="text-danger">{ formErrors.locations }</Form.Text>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <Form.Group>
            <Form.Label>{ trans('fields.primaryRole') } <span>*</span></Form.Label>
            <Select
                    value={ newUser?.primaryRole || null }
                    name={ 'role' }
                    getOptionLabel={ _ => _.name }
                    getOptionValue={ _ => _.id }
                    options={ getAvailableRoles() }
                    onChange={ value => onFieldChange('primaryRole', value) }
            />
            <Form.Text className={ 'text-danger' }>{ formErrors.primaryRole }</Form.Text>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>{ trans('fields.secondaryRoles') }</Form.Label>
            <Select isMulti
                    value={ newUser?.secondaryRoles || [] }
                    name={ 'userSecondaryRoles' }
                    getOptionLabel={ _ => _.name }
                    getOptionValue={ _ => _.id }
                    options={ getAvailableRoles() }
                    onChange={ value => onFieldChange('secondaryRoles', value) }
            />
          </Form.Group>
        </Col>
      </Row>
{/*
      <Row className={ 'mb-2' }>
        <Col>
          <Form.Group>
            <Form.Switch label={ trans('fields.createRDPAccount') } name="createRDPAccount" disabled
                         checked={ newUser?.createRDPAccount || false }
                         onChange={ e => onFieldChange('createRDPAccount', e.target.checked) }/>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Switch label={ trans('fields.isRdpUser') } name="isRdpUser"
                         checked={ newUser?.isRDPUser || false }
                         onChange={ e => onFieldChange('isRDPUser', e.target.checked) }/>
          </Form.Group>
        </Col>
      </Row>
*/}
    </Card.Body>
    </Card>
    { id && newUser && <Row className="mt-1">
      <Col>
        <span className="fw-bold">{ trans('linkTo') } :</span>
        { newUser.personInstance && <Link to={ `/instance/${ newUser.personInstance.id }` } className="ms-1">
          <Badge bg="success">{ trans('linkToPerson') }</Badge>
        </Link> }
        { newUser.primaryRoleInstance &&
          <Link to={ `/instance/${ newUser.primaryRoleInstance.id }` } className="ms-1">
            <Badge bg="primary">
              { trans('linkToPrimaryRoleInstance') } : <i>{ newUser.primaryRoleInstance.identityString }</i>
            </Badge>
          </Link> }
        { newUser.secondaryRolesInstances?.filter(i => i.id !== newUser.primaryRoleInstance?.id).map(instance =>
          <Link key={ instance.id } to={ `/instance/${ instance.id }` } className="ms-1">
            <Badge bg="secondary">
              { trans('linkToSecondaryRoleInstance') } : <i>{ instance.identityString }</i>
            </Badge>
          </Link>) }
      </Col>
    </Row> }
    { id && <AuditTrailsModal isOpen={ isChangelogModalOpen }
                              onClose={ onModalChangelogClose }
                              filter={ changeLogFilter }
    /> }
  </Container>
}

export default UserForm
