import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
  recentListsFetchedAction,
  recentListsFetchErrorAction,
  recentViewedFetchErrorAction,
  recentViewedFetchedAction,
  listsFetchedAction,
  listsFetchErrorAction
} from './actions'
import {
  FETCH_RECENT_LISTS,
  FETCH_RECENT_VIEWED,
  FETCH_LISTS, CHANGE_LISTS_SEARCH, CHANGE_LISTS_PAGE_SIZE
} from './actionTypes'
import apiMethods from '../../../Services/api/apiMethods'
import {
  LIST_TYPE_EXPANDED_SIDEBAR, LIST_TYPE_HOMEPAGE, LIST_TYPE_PATIENT_SIDEBAR, LIST_TYPE_REFERENCE, LIST_TYPE_SYSTEM,
  LIST_TYPE_TREND
} from 'src/Services/Constants'
import { contentRangeToPagination } from 'src/Utils/EffectsUtil'

function* fetchRecentListsHandler(props) {
  try {
    const { data } = yield call(apiMethods.get, '/lists/recent')
    yield put(recentListsFetchedAction(data))
  } catch (error) {
    yield put(recentListsFetchErrorAction())
    yield put(props.globalActions.handleError(error,  error.toString()))
  }
}

function* fetchRecentViewedHandler(props) {
  try {
    const { data } = yield call(apiMethods.get, '/instances/recent')
    yield put(recentViewedFetchedAction(data))
  } catch (error) {
    yield put(recentViewedFetchErrorAction())
    yield put(props.globalActions.handleError(error,  error.toString()))
  }
}

function* fetchListsHandler(props) {
  const state = yield select()
  const { listsPagination, listsQuery } = state.Homepage

  try {
    const { data, headers } = yield call(
      apiMethods.get,
      '/lists',
      {
        ...listsPagination,
        query: listsQuery ? encodeURIComponent(listsQuery) : '',
        excludeTypes: [
          LIST_TYPE_REFERENCE, LIST_TYPE_PATIENT_SIDEBAR, LIST_TYPE_EXPANDED_SIDEBAR, LIST_TYPE_SYSTEM, LIST_TYPE_TREND,
          LIST_TYPE_HOMEPAGE,
        ]
      }
    )
    yield put(listsFetchedAction(data, contentRangeToPagination(headers['content-range'], listsPagination)))
  } catch (error) {
    yield put(listsFetchErrorAction())
    yield put(props.globalActions.handleError(error,  error.toString()))
  }
}

export default function* homePageSagaWatcher(props) {
  yield takeLatest(FETCH_RECENT_LISTS, fetchRecentListsHandler, props)
  yield takeLatest(FETCH_RECENT_VIEWED, fetchRecentViewedHandler, props)
  yield takeLatest(FETCH_LISTS, fetchListsHandler, props)
  yield takeLatest(CHANGE_LISTS_SEARCH, fetchListsHandler, props)
  yield takeLatest(CHANGE_LISTS_PAGE_SIZE, fetchListsHandler, props)
}
