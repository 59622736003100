import { FETCH_DICTIONARIES, FETCH_LISTS_DICTIONARY, SET_DICTIONARIES, SET_LISTS_DICTIONARY } from './actionTypes'

export const fetchDictionaries = data => ({
  type: FETCH_DICTIONARIES,
  payload: data
})

export const fetchListsDictionary = () => ({
  type: FETCH_LISTS_DICTIONARY
})

export const setDictionaries = data => ({
  type: SET_DICTIONARIES,
  payload: data
})

export const setListsDictionary = data => ({
  type: SET_LISTS_DICTIONARY,
  payload: data
})
