
export default {
  english: {
    save: 'Save the new row',
    onEdit_success: 'Extensible Field successfully updated',
    onDelete_success: 'Extensible Field successfully deleted'
  },
  french: {
    save: 'Sauver la nouvelle ligne',
    onEdit_success: 'Extensible Field est bien mis à jour',
    onDelete_success: 'Extensible Field est correctement supprimé'
  }
}
