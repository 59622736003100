import { Form } from 'react-bootstrap'
import React from 'react'

const MIN_WIDTH = 3

const AutoResizeInput = props => {

  const span = React.useRef()

  const [ value, setValue ] = React.useState(props.value)
  const [ width, setWidth ] = React.useState(MIN_WIDTH)

  React.useEffect(() => {
    setValue(props.value)
  }, [ props.value ])

  React.useEffect(() => {
    const actualWidth = span.current.innerText.length * 1.3
    setWidth(actualWidth < MIN_WIDTH ? MIN_WIDTH : actualWidth)
  }, [ value ])

  const onChange = e => props.onChange(e.target.value)

  return <>
    <span className={ 'opacity-0 position-absolute' } style={ { zIndex: -1 } } ref={ span }>{ value }</span>
    <Form.Control { ...props }
                  onChange={ onChange }
                  value={ value }
                  style={ { width: `${ width }ch` } }
    />
  </>
}

export default AutoResizeInput
