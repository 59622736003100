import { Field } from 'src/Types/Field'

export default interface FieldPermission {
    id: string | null
    accessLevel: FieldPermissionAccessLevel | null
    field: Field | null
}

export enum FieldPermissionAccessLevel {
    DEFAULT = null,
    NONE = 0,
    READ = 1,
    UPDATE = 2,
    CREATE = 3,
    DELETE = 4,
}
