export default {
  english: {
    title: 'An error has occurred',
    body: {
      try: 'You can try to ',
      reloadThePage: 'reload the page',
      or: ' or ',
      goToHomepage: 'navigate to the homepage',
      otherWays: '. If the problem persists please contact us.'
    },
  },
  french: {
    title: 'Une erreur est survenue',
    body: {
      try: 'Vous pouvez essayer de ',
      reloadThePage: 'recharger la page',
      or: ' ou ',
      goToHomepage: 'aller à la page d\'accueil',
      otherWays: '. Si le problème persiste, veuillez nous contacter.'
    },
  }
}
