import React, { useState } from 'react'
import {
  changePage,
  changeLimit,
  searchAdminTool,
  toggleDeleteAdminTool, toggleUpdateAdminTool, fetchAdminTools,
} from '../state/actions'
import { translate } from 'src/Services/translation'
import translation from '../translations'
import Table from '../../../Components/NavTable'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ButtonGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { AdminTool } from 'src/Types/AdminTool'
import { StoreState } from 'src/Services/Store/reducers'

const AdminToolsTable = () => {
  const dispatch = useDispatch()

  const {
    adminToolsList,
    pagination,
    loading,
  } = useSelector((state: StoreState) => state.AdminTools)

  const { language } = useSelector((state: StoreState) => state.Root.user)

  const trans = translate(translation)(language)
  const [ fetchListRequest, setFetchListRequest ] = useState(null)

  const onDeleteClick = (adminTool: AdminTool) => dispatch(toggleDeleteAdminTool(adminTool))
  const onEditClick = (adminTool: AdminTool) => dispatch(toggleUpdateAdminTool(adminTool))

  const columnHeaders = [
    trans('name'), trans('form.internalLink'), trans('form.externalLink'), trans('actions'),
  ]
  const rows = getRows(adminToolsList, trans, onDeleteClick, onEditClick)

  const applyNewFilters = (withDelay = false) => {

    if (!withDelay)
      return dispatch(fetchAdminTools())

    clearTimeout(fetchListRequest)
    setFetchListRequest(setTimeout(() => dispatch(fetchAdminTools()), 1000))
  }

  const onSearchInputChange = (e: any) => {
    e.preventDefault()
    dispatch(searchAdminTool(e.target.value))
    applyNewFilters(true)
  }

  const onPageClick = (currentPage: number, page: number) => {
    if (currentPage === page)
      return

    dispatch(changePage(page))
    dispatch(fetchAdminTools())
  }

  const onLimitChange = (limit: number) => {
    dispatch(changeLimit(limit))
    dispatch(fetchAdminTools())
  }

  return <Table pagination={pagination}
                rows={rows}
                columnHeaders={columnHeaders}
                onSearchInputChange={onSearchInputChange}
                onLimitChange={onLimitChange}
                onPageClick={onPageClick}
                isLoading={loading}
  />
}

interface ActionCellProps {
  onDeleteClick: () => void
  onEditClick: () => void
  trans: (data: string) => string
}

export const ActionCell = ({ onDeleteClick, onEditClick, trans }: ActionCellProps) =>
    <ButtonGroup>
      <Button variant="outline-info" size="sm" onClick={onDeleteClick}>
        <i className="fas fa-trash"/> {trans('delete')}
      </Button>
      <Button variant="info" size="sm" onClick={onEditClick}>
        <i className="fas fa-edit"/> {trans('edit')}
      </Button>
    </ButtonGroup>

const getRows = (
    adminTools: AdminTool[],
    trans: (data: string) => string,
    onDeleteClick: (data: AdminTool) => void,
    onEditClick: (data: AdminTool) => void,
) =>
    adminTools.map(adminTool => {
      const internalLink = adminTool.internalLink || ''
      const externalLink = adminTool.externalLink || ''
      const mainLink = internalLink || externalLink

      return [
        <Link key={adminTool.id} to={mainLink}><strong>{adminTool.name}</strong></Link>,
        <Link key={adminTool.id} to={internalLink}>{internalLink}</Link>,
        <Link key={adminTool.id} to={externalLink}>{externalLink}</Link>,
        <ActionCell key={adminTool.id}
                    onDeleteClick={() => onDeleteClick(adminTool)}
                    onEditClick={() => onEditClick(adminTool)}
                    trans={trans}
        />,
      ]
    })

export default AdminToolsTable
