import axios from 'axios'
import { getClientTimeZone } from 'src/Utils/Date.ts'

export const HTTP_CLIENT_DEFAULT_HEADERS = {
  'Time-Zone': getClientTimeZone(),
}

const HttpClient = axios.create({
  headers: HTTP_CLIENT_DEFAULT_HEADERS,
  ContentRange: true,
  crossDomain: true,
  withCredentials: true,
  responseType: 'json'
})

export const setupInterceptors = (
  onRequest = req => req,
  onRequestFailure = err => Promise.reject(err),
  onSuccess = res => res,
  onError = err => Promise.reject(err)
) => {
  HttpClient.interceptors.request.use(onRequest, onRequestFailure)
  HttpClient.interceptors.response.use(onSuccess, onError)
}

export const setBaseUrl = baseUrl => HttpClient.defaults.baseURL = baseUrl

export default HttpClient
