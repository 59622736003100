import { call, put, select, takeLatest } from 'redux-saga/effects'
import { FETCH_DELETED_FIELDS, FETCH_RESTORE_FIELD } from './actionTypes'
import {
  receiveDeletedFields
} from './actions'
import apiMethods from '../../../../../Services/api/apiMethods'
import { contentRangeToPagination } from 'src/Utils/EffectsUtil'

function* fetchDeletedField(props) {
  try {
    const state = yield select()
    const { pagination, searchQuery } = state.RestoreDeletedFields

    const { data, headers } = yield call(apiMethods.get, '/fields', {
      onlyDeleted: true,
      query: searchQuery,
      ...pagination
    })
    yield put(receiveDeletedFields({
      slotTypes: data,
      pagination: contentRangeToPagination(headers['content-range'], pagination)
    }))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'fetchFailed'))
  }
}

function* fetchRestoreField(props, { payload }) {
  try {
    const data = yield call(apiMethods.update, `/fields/${payload.id}/restore`, {})
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'fetchFailed'))
  }
}


export default function* deletedFieldsSagaWatcher(props) {
  yield takeLatest(FETCH_DELETED_FIELDS, fetchDeletedField, props)
  yield takeLatest(FETCH_RESTORE_FIELD, fetchRestoreField, props)
}
