import * as React from 'react'
import Alert from 'react-bootstrap/Alert'

interface AlertProps {
    message: string
    type: string
    classes?: string
}
export const AlertMessage = ({ message, type, classes }: AlertProps) =>
  <Alert className={ classes } variant={ type } key={ type }>
    { message }
  </Alert>
