export default {
  english: {
    field: {
      search: 'Search',
      number: 'Number',
      street: 'Street',
      postalCode: 'Postal code',
      city: 'City',
      country: 'Country',
      details: 'Details',
    },
    fieldRequired: 'Please fill out this field'
  },
  french: {
    field: {
      search: 'Chercher',
      number: 'Numéro',
      street: 'Rue',
      postalCode: 'Code postal',
      city: 'Ville',
      country: 'Pays',
      details: 'Détails',
    },
    fieldRequired: 'S\'il vous plaît remplissez ce champ'
  }
}
