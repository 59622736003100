import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { Button, Form, OverlayTrigger, Popover } from 'react-bootstrap'
import LocalizedDatepicker from 'src/Components/LocalizedDatepicker'
import { NAME } from 'src/Services/Constants/Config/DocumentTemplate'
import { COLORS, SIZES, FIELD_BASE_TYPE_DATE_TIME, FIELD_BASE_TYPE_CONSENT } from 'src/Services/Constants'
import { getAllFields } from 'src/Views/FormFiller/utils/Fields.ts'
import { FieldOption, FieldOptionSaveTypes } from 'src/Types/FieldOption'
import SystemFieldSelect from 'src/Components/SystemFieldSelect'
import {
  toggleAutocompleteFieldsModal, toggleFieldValuesModal, toggleCalculationFieldModal
} from 'src/Views/FormEditor/state/actions'
import FileTypeSettings from './FileTypeSettings.tsx'

const FieldSetting = ({ trans, field, option, onChange }) => {
  const dispatch = useDispatch()

  const { form } = useSelector(state => state.FormEditor.formReducer)
  const { documentTypes, documentTemplates: templatesList  } = useSelector(state => state.Dictionary)

  const getValue = () => field.options[option]

  const getConsentFields = () => getAllFields(form.sections)
      .filter(field => field.type?.baseFieldType === FIELD_BASE_TYPE_CONSENT)
      .map(({ label, systemName }) => ({ label, systemName }))

  switch (option) {
    case 'position':
      return <></>
    case FieldOption.READ_ONLY:
      return <Form.Check defaultChecked={ getValue() }
                         name={ 'readOnly' }
                         label={ trans('editField.readOnly') }
                         onClick={ e => onChange(FieldOption.READ_ONLY, e.target.checked) }
      />
    case FieldOption.REQUIRED:
      return <Form.Check defaultChecked={ getValue() }
                         name={ 'required' }
                         label={ trans('required') }
                         onClick={ e => onChange(FieldOption.REQUIRED, e.target.checked) }
      />
    case FieldOption.NECESSARY:
      return <Form.Check defaultChecked={ getValue() }
                         name={ 'necessary' }
                         label={ trans('editField.necessary') }
                         onClick={ e => onChange(FieldOption.NECESSARY, e.target.checked) }
      />
    case FieldOption.EXCLUDE_FROM_CLONE:
      return <Form.Check defaultChecked={ getValue() }
                         name={ 'excludeFromClone' }
                         label={ trans('editField.excludeFromClone') }
                         onClick={ e => onChange(FieldOption.EXCLUDE_FROM_CLONE, e.target.checked) }
      />
    case FieldOption.VALIDATE_USER_IDENTITY:
      return <Form.Check defaultChecked={ getValue() }
                         name={ 'validateUserIdentity' }
                         label={ trans('editField.validateUserIdentity') }
                         onClick={ e => onChange(FieldOption.VALIDATE_USER_IDENTITY, e.target.checked) }
      />
    case FieldOption.SHOW_DATE:
      return <Form.Check defaultChecked={ getValue() }
                         name={ 'showDate' }
                         label={ trans('editField.showDate') }
                         onClick={ e => onChange(FieldOption.SHOW_DATE, e.target.checked) }
      />
    case FieldOption.SHOW_TIME:
      return <Form.Check defaultChecked={ getValue() }
                         name={ 'showTime' }
                         label={ trans('editField.showTime') }
                         onClick={ e => onChange(FieldOption.SHOW_TIME, e.target.checked) }
      />
    case FieldOption.IS_SENSITIVE:
      return <Form.Check defaultChecked={ getValue() }
                         name={ 'isSensitive' }
                         label={ trans('editField.isSensitive') }
                         onClick={ e => onChange(FieldOption.IS_SENSITIVE, e.target.checked) }
      />
    case FieldOption.IS_VALIDATOR:
      return <Form.Check defaultChecked={ getValue() }
                         name={ 'isValidator' }
                         label={ trans('editField.isValidator') }
                         onClick={ e => onChange(FieldOption.IS_VALIDATOR, e.target.checked) }
      />
    case FieldOption.SORT_ALPHA:
      return <Form.Check defaultChecked={ getValue() }
                         name={ 'sortAlpha' }
                         label={ trans('editField.sortAlpha') }
                         onClick={ e => onChange(FieldOption.SORT_ALPHA, e.target.checked) }
      />
    case FieldOption.SELF_POPULATION:
      return <Form.Check defaultChecked={ getValue() }
                         name={ 'selfPopulation' }
                         label={ trans('editField.selfPopulation') }
                         onClick={ e => onChange(FieldOption.SELF_POPULATION, e.target.checked) }
      />
    case FieldOption.USER_TEXT_RESPONSE:
      return <Form.Check defaultChecked={ getValue() }
                         name={ 'userTextResponse' }
                         label={ trans('editField.userTextResponse') }
                         onClick={ e => onChange(FieldOption.USER_TEXT_RESPONSE, e.target.checked) }
      />
    case FieldOption.SET_NOW_AT_CREATION:
      return <Form.Check defaultChecked={ getValue() }
                         name={ 'setNowAtCreation' }
                         label={ trans('editField.setNowAtCreation') }
                         onClick={ e => onChange(FieldOption.SET_NOW_AT_CREATION, e.target.checked) }
      />
    case FieldOption.SET_NOW_AT_MODIFICATION:
      return <Form.Check defaultChecked={ getValue() }
                         name={ 'setNowAtModification' }
                         label={ trans('editField.setNowAtModification') }
                         onClick={ e => onChange(FieldOption.SET_NOW_AT_MODIFICATION, e.target.checked) }
      />
    case FieldOption.SET_AT_CREATION:
      return <Form.Check defaultChecked={ getValue() }
                         name={ 'setAtCreation' }
                         label={ trans('editField.setAtCreation') }
                         onClick={ e => onChange(FieldOption.SET_AT_CREATION, e.target.checked) }
      />
    case FieldOption.SET_AT_MODIFICATION:
      return <Form.Check defaultChecked={ getValue() }
                         name={ 'setAtModification' }
                         label={ trans('editField.setAtModification') }
                         onClick={ e => onChange(FieldOption.SET_AT_MODIFICATION, e.target.checked) }
      />
    case FieldOption.ALIGN_OPTIONS_VERTICALLY:
      return <Form.Check defaultChecked={ getValue() }
                         name={ 'alignOptionsVertically' }
                         label={ trans('editField.alignOptionsVertically') }
                         onClick={ e => onChange(FieldOption.ALIGN_OPTIONS_VERTICALLY, e.target.checked) }
      />
    case FieldOption.SHOW_CLEAR_BUTTON:
      return <Form.Check defaultChecked={ getValue() }
                         name={ 'showClearButton' }
                         label={ trans('editField.showClearButton') }
                         onClick={ e => onChange(FieldOption.SHOW_CLEAR_BUTTON, e.target.checked) }
      />
    case FieldOption.SHOW_ADD_BUTTON:
      return <Form.Check defaultChecked={ getValue() }
                         name={ 'showAddButton' }
                         label={ trans('editField.showAddButton') }
                         onClick={ e => onChange(FieldOption.SHOW_ADD_BUTTON, e.target.checked) }
      />
    case FieldOption.SHOW_EDIT_BUTTON:
      return <Form.Check defaultChecked={ getValue() }
                         name={ 'showEditButton' }
                         label={ trans('editField.showEditButton') }
                         onClick={ e => onChange(FieldOption.SHOW_EDIT_BUTTON, e.target.checked) }
      />
    case FieldOption.AUTOCOMPLETE_AT_CREATION:
      return <div>
        <Form.Check defaultChecked={ getValue() }
                    name={ 'autocompleteAtCreation' }
                    label={ trans('editField.autocompleteAtCreation') }
                    onClick={ e => onChange(FieldOption.AUTOCOMPLETE_AT_CREATION, e.target.checked) }
        />
        <small className={ 'text-muted' }>{ trans('editField.autocompleteAtCreationInfo') }</small>
      </div>
    case FieldOption.SUPPORT_ON_CLICK_CODE:
      return <Form.Group className={ 'mb-1' }>
        <Form.Label>{ trans('editField.onClickCode') }</Form.Label>
        <Form.Control
          as={ 'textarea' }
          name={ 'onClickCode' }
          size={ 'sm' }
          defaultValue={ field.javaScriptCode && field.javaScriptCode.onClick ? field.javaScriptCode.onClick : '' }
          onChange={ e => onChange('onClick', e.target.value, 'javascript') }
        />
        <Form.Text className={ 'text-muted' }>{ trans('editField.onClickCodeInfo') }</Form.Text>
      </Form.Group>
    case FieldOption.SUPPORT_ON_CHANGE_CODE:
      return <Form.Group className={ 'mb-1' }>
        <Form.Label>{ trans('editField.onChangeCode') }</Form.Label>
        <Form.Control
          as={ 'textarea' }
          name={ 'onChangeCode' }
          size={ 'sm' }
          defaultValue={ field.javaScriptCode?.onChange || '' }
          onChange={ e => onChange('onChange', e.target.value, 'javascript') }
        />
        <Form.Text className={ 'text-muted' }>{ trans('editField.supportOnChangeCode') }</Form.Text>
      </Form.Group>
    case FieldOption.SUPPORT_ON_BLUR_CODE:
      return <Form.Group className={ 'mb-1' }>
        <Form.Label>{ trans('editField.onBlurCode') }</Form.Label>
        <Form.Control
          as={ 'textarea' }
          name={ 'onBlurCode' } size={ 'sm' }
          defaultValue={ field.javaScriptCode?.onBlur || '' }
          onChange={ e => onChange('onBlur', e.target.value, 'javascript') }
        />
        <Form.Text className={ 'text-muted' }>{ trans('editField.onBlurCodeInfo') }</Form.Text>
      </Form.Group>
    case FieldOption.FILE_TYPES:
      return <FileTypeSettings value={ getValue() } onChange={ v => onChange(FieldOption.FILE_TYPES, v) }/>
    case FieldOption.LABEL_HOVER:
      return <Form.Group className={ 'mb-1' }>
        <Form.Label>{ trans('editField.labelHover') }</Form.Label>
        <Form.Control
          type={ 'text' }
          size={ 'sm' }
          id={ 'label-hover' }
          defaultValue={ getValue() }
          name={ 'labelHover' }
          onChange={ e => onChange(FieldOption.LABEL_HOVER, e.target.value) }
        />
      </Form.Group>
    case FieldOption.DEFAULT_TEMPLATE:
      return <Form.Group className={ 'mb-1' }>
        <Form.Label>{ trans('editField.defaultTemplate') } { getValue() }</Form.Label>
        { templatesList &&
          <Select
            id={ 'default-template' }
            name={ 'defaultTemplate' }
            isClearable={ true }
            options={ templatesList }
            getOptionValue={ o => o.values[NAME] }
            getOptionLabel={ o => o.values[NAME] }
            value={ templatesList.find(row => row.values[NAME] === getValue()) }
            placeholder={ trans('editField.defaultTemplatePlaceholder') }
            onChange={ selected => onChange(FieldOption.DEFAULT_TEMPLATE, selected?.values[NAME]) }
          /> }
      </Form.Group>
    case FieldOption.CONSENT_FIELDS:
      return <Form.Group className={ 'mb-1' }>
        <Form.Label>{ trans('editField.consentFields') }</Form.Label>
        { getValue() && getConsentFields().length > 0 &&
          <Select
            id={ 'consent-fields' }
            name={ 'consentFields' }
            isClearable={ true }
            isMulti
            options={ getConsentFields() }
            getOptionValue={ o => o.systemName }
            getOptionLabel={ o => o.label }
            value={ getConsentFields().filter(consentField => getValue().includes(consentField.systemName)) }
            onChange={ selected => onChange(FieldOption.CONSENT_FIELDS, selected?.map(item => item.systemName) || []) }
          />
        }

      </Form.Group>
    case FieldOption.FILE_FIELD:
      return <Form.Group className={ 'mb-1' }>
        <Form.Label>{ trans('editField.fileField') }</Form.Label>
        <SystemFieldSelect
          placeholder={ trans('editField.fileFieldPlaceholder') }
          id={ 'file-field' }
          defaultValue={ getValue() ? { id: null, name: getValue() } : null }
          name={ 'fileField' }
          clearable
          onChange={ systemField => onChange(FieldOption.FILE_FIELD, systemField?.name || null) }
        />
      </Form.Group>
    case FieldOption.DOCUMENT_TYPE:
      return <Form.Group className={ 'mb-1' }>
        <Form.Label>{ trans('editField.documentType') }</Form.Label>
        <Form.Select
          size={ 'sm' }
          placeholder={ trans('editField.documentTypePlaceholder') }
          id={ 'documentType' }
          defaultValue={ getValue() || '' }
          name={ 'fontSize' }
          onChange={ e => onChange(FieldOption.DOCUMENT_TYPE, e.target.value || null) }
        >
          <option value={ null }>{ trans('none') }</option>
          { documentTypes.map(type => <option key={ type.code } value={ type.code }>{ type.label }</option>) }
        </Form.Select>
      </Form.Group>
    // Use src/Components/FieldValueSetter/index.tsx instead
    case FieldOption.DEFAULT_VALUE:
      return <Form.Group className={ 'mb-1' }>
        <Form.Label>{ trans('editField.defaultValue') }</Form.Label>
        { field?.type?.baseFieldType === FIELD_BASE_TYPE_DATE_TIME
          ? <LocalizedDatepicker
            id={ 'defaultValue' }
            name={ 'defaultValue' }
            showDateOnly={ field.options[FieldOption.SHOW_DATE] && !field.options[FieldOption.SHOW_TIME] }
            showTimeOnly={ !field.options[FieldOption.SHOW_DATE] && field.options[FieldOption.SHOW_TIME] }
            size={ 'sm' }
            selected={ getValue() }
            isClearable
            onChange={ e => onChange(FieldOption.DEFAULT_VALUE, e) }
          />
          : <Form.Control
            type={ 'text' }
            size={ 'sm' }
            placeholder={ trans('editField.defaultValuePlaceholder') }
            id={ 'defaultValue' }
            defaultValue={ getValue() }
            name={ 'defaultValue' }
            onChange={ e => onChange(FieldOption.DEFAULT_VALUE, e.target.value) }
          /> }
      </Form.Group>
    case 'defaultOption':
      return <></>
    case FieldOption.IS_TEMPLATE_CREATOR:
      return <Form.Check
        defaultChecked={ getValue() }
        name={ 'isTemplateCreator' }
        label={ trans('editField.isTemplateCreator') }
        onClick={ e => onChange(FieldOption.IS_TEMPLATE_CREATOR, e.target.checked) }
      />
    case FieldOption.VALUES:
      return <>
        { /*<Form.Label>{ trans('editField.values') }</Form.Label>*/ }
        <div>
          <Button
            variant={ 'outline-primary' }
            size={ 'sm' }
            onClick={ e => dispatch(toggleFieldValuesModal()) }
          >
            { trans('editField.openValuesModalButton') }
          </Button>
        </div>
      </>
    case FieldOption.AUTOCOMPLETE_FIELDS:
      return <>
        <div>
          <Button
            variant={ 'outline-primary' }
            size={ 'sm' }
            onClick={ e => dispatch(toggleAutocompleteFieldsModal()) }
          >
            { trans('editField.autocompleteFields') }
          </Button>
        </div>
      </>
    case FieldOption.CONTENT:
      return <Form.Group className={ 'mb-1' }>
        <Form.Label>{ trans('editField.content') }</Form.Label>
        <Form.Control
          type={ 'text' } size={ 'sm' }
          placeholder={ trans('editField.contentPlaceholder') }
          id={ 'content' }
          defaultValue={ getValue() }
          name={ 'content' }
          onChange={ e => onChange(FieldOption.CONTENT, e.target.value) }
        />
      </Form.Group>
    case FieldOption.CSS_CLASS:
      return <Form.Group className={ 'mb-1' }>
        <Form.Label>{ trans('editField.cssClass') }</Form.Label>
        <Form.Control
          type={ 'text' }
          size={ 'sm' }
          placeholder={ trans('editField.cssClassPlaceholder') }
          id={ 'cssClass' }
          defaultValue={ getValue() }
          name={ 'cssClass' }
          className={ 'mb-1' }
          onChange={ e => onChange(FieldOption.CSS_CLASS, e.target.value) }
        />
        <OverlayTrigger trigger={ 'click' }
                        placement={ 'auto' }
                        overlay={ <Popover>
                          <Popover.Header>{ trans('editField.cssClassInfoHeader') }</Popover.Header>
                          <Popover.Body>{ trans('editField.cssClassInfoBody') }</Popover.Body>
                        </Popover> }
        >
          <Button size={ 'sm' }>{ trans('editField.cssClassInfoButton') }</Button>
        </OverlayTrigger>
      </Form.Group>
    case FieldOption.FONT_SIZE:
      return <Form.Group className={ 'mb-1' }>
        <Form.Label>{ trans('editField.fontSize') }</Form.Label>
        <Form.Select
          size={ 'sm' }
          placeholder={ trans('editField.fontSizePlaceholder') }
          id={ 'fontSize' }
          defaultValue={ getValue() || '' }
          name={ 'fontSize' }
          onChange={ e => onChange(FieldOption.FONT_SIZE, e.target.value || null) }
        >
          <option value={ null }>{ trans('none') }</option>
          { SIZES.map(size => <option key={ size } value={ size }>{ size }</option>) }
        </Form.Select>
      </Form.Group>
    case FieldOption.FONT_COLOR:
      return <Form.Group className={ 'mb-1' }>
        <Form.Label>{ trans('editField.fontColor') }</Form.Label>
        <Form.Select
          size={ 'sm' }
          placeholder={ trans('editField.fontColorPlaceholder') }
          id={ 'fontColor' }
          defaultValue={ getValue() || '' }
          name={ 'fontColor' }
          onChange={ e => onChange(FieldOption.FONT_COLOR, e.target.value || null) }
        >
          <option value={ null }>{ trans('none') }</option>
          { COLORS.map(color => <option key={ color } value={ color }>{ color }</option>) }
        </Form.Select>
      </Form.Group>
    case FieldOption.REDIRECT_HOME_PAGE:
      return <Form.Check
        defaultChecked={ getValue() }
        name={ 'redirectToHomePage' }
        label={ trans('editField.redirectToHomePage') }
        onClick={ e => onChange(FieldOption.REDIRECT_HOME_PAGE, e.target.checked) }
      />
    case FieldOption.CALCULATION:
      return <>
        <div>
          <Button variant={ 'outline-primary' } size={ 'sm' }
                  onClick={ e => dispatch(toggleCalculationFieldModal()) }>
            { trans('editField.calculationField') }
          </Button>
        </div>
      </>
    case FieldOption.CONSENT_DESCRIPTION:
      return <Form.Group className={ 'mb-1' }>
        <Form.Label>{ trans('editField.description') }</Form.Label>
        <Form.Control
          as={ 'textarea' }
          name={ 'consentDescription' }
          size={ 'sm' }
          defaultValue={ getValue() }
          rows={ 10 }
          onChange={ e => onChange(FieldOption.CONSENT_DESCRIPTION, e.target.value) }
        />
      </Form.Group>
    case FieldOption.SAVE_TYPE:
      return <Form.Group className={ 'mb-1' }>
        <Form.Label>{ trans('editField.saveTypeLabel') }</Form.Label>
        <Form.Select
          size={ 'sm' }
          placeholder={ trans('editField.saveTypePlaceholder') }
          id={ 'saveType' }
          defaultValue={ getValue() || '' }
          name={ 'saveType' }
          onChange={ e => onChange(FieldOption.SAVE_TYPE, e.target.value || null) }
        >
          <option value={ '' }>{ trans('none') }</option>
          { Object.values(FieldOptionSaveTypes).map(saveType =>
            <option key={ saveType } value={ saveType }>{ trans(`editField.saveType.${ saveType }`) }</option>) }
        </Form.Select>
      </Form.Group>
    default:
      return <></>
  }
}

export default FieldSetting
