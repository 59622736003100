import React from 'react'
import { FormControl, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { FieldEvent } from 'src/Views/FormFiller/Types/Field'
import translation from 'src/Views/FormFiller/translations.ts'
import { translate } from 'src/Services/translation'
import { useParams } from 'react-router-dom'
import { fieldEvent, sendEmailPreview } from '../../state/actions'

const HtmlContentField = ({ id = null, field = null, value }) => {
  const dispatch = useDispatch()
  const { id: firstId, instanceId: secondId } = useParams()

  const patientId = secondId ? firstId : null
  const instanceId = secondId ? secondId : firstId

  const { language } = useSelector(state => state.Root.user)

  const trans = translate(translation)(language)

  const onClick = () => {
    dispatch(sendEmailPreview(instanceId))
  }

  const onChange = e => {
    dispatch(fieldEvent(field, FieldEvent.VALUE_CHANGE, { value: e.target.value }))
  }

  return (
    <>
      <FormControl
        id={ `field${ id }` }
        as={ 'textarea' }
        rows={ 3 }
        value={ value || '' }
        onChange={ onChange }
      />
      <div className={ 'mt-2 d-flex justify-content-end' }>
        <Button
          variant={ 'outline-info' }
          onClick={ onClick }
        >
          { trans('sendTestMailButton') }
        </Button>
      </div>

    </>
  )
}
export default HtmlContentField
