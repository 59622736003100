import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { translate } from '../../../Services/translation/index'
import translation from '../translations'
import { Card, Col, Form, Row } from 'react-bootstrap'
import { addPermission, getSectionPermissions } from '../selectors'
import Select from 'react-select'
import { setNewSubjectData } from '../state/actions.ts'
import FieldContent from './FieldContent'
import { PermissionSubject } from 'src/Views/Permissions/Types/Subject'
import { SectionPermissionAccessLevel } from 'src/Types/SectionPermission'

const sectionPermissionForm = ({ permission = null, section }) => {
  const dispatch = useDispatch()

  const { language } = useSelector(state => state.Root.user)
  const state = useSelector(state => state.Permissions)

  const sectionPermissions = getSectionPermissions(state)
  const { user } = state

  const trans = translate(translation)(language)

  const [ isClose, setIsClose ] = React.useState(true)

  const getPermission = () => permission || { section, accessLevel: SectionPermissionAccessLevel.DEFAULT }

  const onCreate = accessLevel =>
    dispatch(setNewSubjectData(
      addPermission(state, PermissionSubject.SECTION, { section, accessLevel }),
      PermissionSubject.SECTION)
    )
  const savePermission = permission =>
    dispatch(setNewSubjectData(
      [ ...sectionPermissions.map(p => p.section.id === section.id ? permission : p) ], PermissionSubject.SECTION))
  const onDelete = () =>
    dispatch(setNewSubjectData(sectionPermissions.filter(p => p.id !== permission.id), PermissionSubject.SECTION))

  const onAccessLevelSelect = accessLevel => {

    if (accessLevel === SectionPermissionAccessLevel.DEFAULT && getPermission().id)
      return onDelete()
    if (accessLevel !== SectionPermissionAccessLevel.DEFAULT && !getPermission().id)
      return onCreate(accessLevel)

    savePermission({ ...permission, accessLevel })
  }

  const getAccessLevelLabelByValue = value =>
    Object.keys(SectionPermissionAccessLevel).find(key => SectionPermissionAccessLevel[key] === value)

  return <Card className={ 'mb-1' }>
    <Card.Header className={ 'py-1' }>

        <Row>
          <Col sm={ 1 } className={ 'd-flex align-items-center justify-content-start cursor-pointer' }
               onClick={ e => setIsClose(!isClose) }>
            <i className={ `fas fa-chevron-${ isClose ? 'right' : 'down' }` }/>
          </Col>
          <Col sm={ 6 } className={ 'd-flex align-items-center justify-content-start cursor-pointer' }
               onClick={ e => setIsClose(!isClose) }>
            <h5 className={ 'm-0' }>{ section.name }</h5>
          </Col>
          <Col sm={ 4 }>
            <Form.Group>
              <Select
                options={
                  Object.values(SectionPermissionAccessLevel)
                    .filter(o => o === null || !isNaN(Number(o)))
                    .map(o => ({ value: o }))
                }
                value={ ({ value: getPermission().accessLevel }) }
                onChange={ o => onAccessLevelSelect(o.value) }
                getOptionLabel={ o => trans(`accessLevels.${getAccessLevelLabelByValue(o.value)}`) }
                title={ trans('accessLevel') }
                isDisabled={user}
              />
            </Form.Group>
          </Col>
        </Row>

    </Card.Header>

    { !isClose && <Card.Body>
      <FieldContent section={ section }/>
    </Card.Body> }
  </Card>
}

export default sectionPermissionForm
