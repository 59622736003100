import { PdfBitmap, PdfDocument, PdfPageOrientation, PdfPageSettings, SizeF } from '@syncfusion/ej2-pdf-export'
import AppSentry from 'src/Services/Sentry/index.ts'

export const getAsPdf = async (documentEditor, shouldDownload = false) => {
  //https://ej2.syncfusion.com/react/documentation/document-editor/how-to/export-document-as-pdf/
  const pdfDocumentName = (documentEditor.documentName || 'sample') + '.pdf'

  const pdfDocument = new PdfDocument()
  const imageFormat = 'image/jpeg'
  const pageCount = documentEditor.pageCount

  documentEditor.documentEditorSettings.printDevicePixelRatio = 2

  let loadedPage = 0
  return new Promise(resolve => {
    for (let i = 1; i <= pageCount; i++)
      setTimeout( () => {
        // Getting pages as image
        try {
          const image = documentEditor.exportAsImage(i, imageFormat)

          image.onload = async () => {
            const imageHeight = parseInt(image.style.height.toString().replace('px', ''))
            const imageWidth = parseInt(image.style.width.toString().replace('px', ''))
            const section = pdfDocument.sections.add()
            const settings = new PdfPageSettings(0)

            if (imageWidth > imageHeight)
              settings.orientation = PdfPageOrientation.Landscape

            settings.size = new SizeF(imageWidth, imageHeight)
            section.setPageSettings(settings)

            const page = section.pages.add()
            const graphics = page.graphics
            const imageStr = image.src.replace('data:image/jpeg;base64,', '')
            const pdfImage = new PdfBitmap(imageStr)

            graphics.drawImage(pdfImage, 0, 0, imageWidth, imageHeight)
            loadedPage++

            // Generation done
            if (loadedPage === pageCount) {
              if (shouldDownload)
                resolve(pdfDocument.save(pdfDocumentName))
              else {
                const blob = (await pdfDocument.save())?.blobData
                const file = new File([ blob ], pdfDocumentName)
                resolve(file)
              }
            }
          }
        } catch (e) {
          AppSentry.captureError(e, `getAsPdf | page ${ loadedPage }/${ pageCount }`)
        }
      }, 500)
  })
}
