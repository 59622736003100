import { useDispatch, useSelector } from 'react-redux'
import { translate, translateConf } from 'src/Services/translation'
import translation from '../translations'
import React, { useEffect, useRef } from 'react'
import { Button, Card, Col, Row, Form } from 'react-bootstrap'
import FormPermissionForm from './FormPermissionForm.tsx'
import { setNewSubjectData, setPermissionsFormSubmit, setPermissionsFormSubmitState } from '../state/actions.ts'
import { getFormPermissions, removeFormPermissions } from '../selectors'
import { PermissionSubject } from 'src/Views/Permissions/Types/Subject'
import { FormPermissionAccessLevel } from 'src/Types/FormPermission'

const PermissionsFormContent = () => {
  const dispatch = useDispatch()

  const { language } = useSelector(state => state.Root.user)
  const { configTranslations } = useSelector(state => state.Root)
  const state = useSelector(state => state.Permissions)

  const formPermissions = getFormPermissions(state)
  const { user, forms } = state

  const form = useRef(null)

  const transConf = translateConf(configTranslations)
  const trans = translate(translation)(language)

  const getRemainingForms = () =>
    forms?.filter(f => !formPermissions.some(p => p.form?.id === f.id)) || []

  const onAddPermissionClick = () => dispatch(setNewSubjectData([ ...formPermissions, {
    form: {},
    accessLevel: FormPermissionAccessLevel.READ
  } ], PermissionSubject.FORM))


  const onDelete = permission => e => {
    dispatch(setNewSubjectData(removeFormPermissions(state, permission)))
  }

  const triggerFormSubmit = () => {
    form.current.requestSubmit()
  }
  const onSubmit = e => {
    e.preventDefault()

    if (!form.current.checkValidity()) {
      form.current.reportValidity()
      return
    }
    dispatch(setPermissionsFormSubmitState(true))
  }

  useEffect(() => {
    dispatch(setPermissionsFormSubmit(triggerFormSubmit))
  }, [ form ])

  return <>
    <Form ref={ form } onSubmit={ onSubmit }>
      { formPermissions.map((permission, i) =>
        <Card key={ i } className={ 'mt-3' }>
          <Card.Header>
            { permission.form?.systemName && transConf('FORM')(permission.form) }
            { !user &&
              <i className="fas fa-trash cursor-pointer float-right text-danger" onClick={ onDelete(permission) }/> }
          </Card.Header>
          <Card.Body>
            <FormPermissionForm permission={ permission } remainingForms={ getRemainingForms() } index={ i }/>
          </Card.Body>
        </Card>
      )
      }
    </Form>
    { !!getRemainingForms().length &&
      <Row className={ 'mt-3' }>
        <Col sm={ 'auto' } className={ 'pr-2' }>
          <Button onClick={ onAddPermissionClick } className={ 'me-2' } disabled={ Boolean(user) }>
            <i className="me-1 fas fa-plus-circle"/>{ trans('add') }
          </Button>
        </Col>
      </Row>
    }
  </>
}

export default PermissionsFormContent
