import { combineReducers } from 'redux'
import formReducer from './formReducer'
import sectionReducer from '../Components/Section/state/reducer'

const formEditor = combineReducers({
  formReducer,
  sectionReducer
})

export default formEditor
