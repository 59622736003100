import Section from 'src/Types/Section'

export default interface SectionPermission {
    id: string | null
    accessLevel: SectionPermissionAccessLevel | null
    section: Section | null
}

export enum SectionPermissionAccessLevel {
    DEFAULT = null,
    NONE = 0,
    READ = 1,
    UPDATE = 2,
    CREATE = 3,
    DELETE = 4,
}
