import React, { ChangeEvent, useState } from 'react'
import { setPage, setQuery, setLimit, fetchItems } from '../state/actions'
import { translate } from '../../../Services/translation'
import translation from '../translations'
import NavTable from '../../../Components/NavTable'
import { StoreState } from 'src/Services/Store/reducers'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ButtonGroup } from 'react-bootstrap'
import { EventCancellationReason } from '../../../Types/EventCancellationReason'
import { EventCancellationStoreState } from '../state/reducer'

interface Props {
    onDeleteClick: (item: EventCancellationReason) => void
    onUpdateClick: (item: EventCancellationReason) => void
}

const Table = ({ onDeleteClick, onUpdateClick }: Props) => {

    const dispatch = useDispatch()

    const { items, pagination, isLoading } =
        useSelector((state: EventCancellationStoreState) => state.EventCancellationReasons)
    const { language } = useSelector((state: StoreState) => state.Root.user)

    const trans = translate(translation)(language)
    const [ fetchListRequest, setFetchListRequest ] = useState(null)

    const columnHeaders = [
        trans('form.label'), trans('form.systemName'), trans('actions')
    ]

    const getRows = () =>
        items.map((item: EventCancellationReason) => [
            <>{item.label}</>,
            <>{item.systemName}</>,
            <ButtonGroup key={item.id}>
                {!item.isSystem && <Button variant={'outline-info'} size={'sm'} onClick={() => onDeleteClick(item)}>
                    <i className="fas fa-trash"/> {trans('delete')}
                </Button>}
                <Button variant={'info'} size={'sm'} onClick={() => onUpdateClick(item)}>
                    <i className="fas fa-edit"/> {trans('edit')}
                </Button>
            </ButtonGroup>
        ])

    const rows = getRows()

    const applyNewFilters = () => {
        clearTimeout(fetchListRequest)
        setFetchListRequest(setTimeout(() => dispatch(fetchItems()), 1000))
    }

    const onSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        dispatch(setQuery(e.target.value))
        applyNewFilters()
    }

    const onPageClick = (currentPage: number, page: number) => {
        if (currentPage === page)
            return

        dispatch(setPage(page))
        applyNewFilters()
    }

    const onLimitChange = (limit: number) => {
        dispatch(setLimit(limit))
        applyNewFilters()
    }

    return <NavTable pagination={pagination}
                     rows={rows}
                     columnHeaders={columnHeaders}
                     onSearchInputChange={onSearchInputChange}
                     onLimitChange={onLimitChange}
                     onPageClick={onPageClick}
                     isLoading={isLoading}
    />
}

export default Table
