import React from 'react'
import { Form } from 'react-bootstrap'
import Select from 'react-select'
import CalendarFilter, { CalendarFilterOption } from 'src/Types/CalendarFilter'
import { useSelector } from 'src/Services/Store'
import { StoreState } from 'src/Services/Store/reducers'
import { translate } from 'src/Services/translation'
import translation from 'src/Components/Calendar/translations'

interface Props {
  filter: CalendarFilter
  onFilterChange: (filterOption: CalendarFilterOption[]) => void
}

export default function Filter({ filter, onFilterChange }: Props) {
  const { language } = useSelector((state: StoreState) => state.Root.user)

  const trans = translate(translation)(language)

  const customStyles = {
    option: (provided: any, { data }: any) => ({
      ...provided,
      color: data.color || provided.color
    }),
    multiValue: (provided: any, { data }: any) => ({
      ...provided,
      backgroundColor: data.color || provided.backgroundColor
    }),
    multiValueLabel: (provided: any, { data }: any) => ({
      ...provided,
      color: data.color ? 'white' : provided.color
    }),
    multiValueRemove: (provided: any, { data }: any) => ({
      ...provided,
      color: data.color ? 'white' : provided.color
    })
  }

  return <Form.Group className={ 'mt-2' }>
    <Form.Label>{ trans(`filter.${ filter.label }`) || filter.label }</Form.Label>
    <Select isMulti
            name={ `${ filter.name }FilterSelect` }
            options={ filter.options }
            value={ filter.values }
            onChange={ o => onFilterChange(o as unknown as CalendarFilterOption[]) }
            styles={ customStyles }
            getOptionLabel={ o => o.label || o.systemName }
    />
  </Form.Group>
}
