import {
  CREATE_SLOT_TYPE,
  SLOT_TYPE_CREATED,
  DELETE_SLOT_TYPE,
  CHANGE_LIMIT,
  CHANGE_PAGE,
  FETCH_SLOT_TYPES,
  RECEIVE,
  SEARCH_SLOT_TYPE,
  TOGGLE_CREATE_SLOT_TYPE,
  RECEIVE_SLOT_TYPES,
  SLOT_TYPE_DELETED,
  SLOT_TYPE_UPDATED,
  UPDATE_SLOT_TYPE,
  TOGGLE_UPDATE_SLOT_TYPE,
  TOGGLE_DELETE_SLOT_TYPE,
  SET_FORM_ERRORS
} from './slotTypes'

export const fetchSlotTypes = () => ({
  type: FETCH_SLOT_TYPES
})

export const receive = (data) => ({
  type: RECEIVE,
  payload: data
})

export const receiveSlotTypes = (data) => ({
  type: RECEIVE_SLOT_TYPES,
  payload: data
})

export const searchSlotType = (data) => ({
  type: SEARCH_SLOT_TYPE,
  payload: data
})

export const changeLimit = (data) => ({
  type: CHANGE_LIMIT,
  payload: data
})

export const changePage = (data) => ({
  type: CHANGE_PAGE,
  payload: data
})

export const toggleCreateSlotType = () => ({
  type: TOGGLE_CREATE_SLOT_TYPE
})

export const toggleUpdateSlotType = slotType => ({
  type: TOGGLE_UPDATE_SLOT_TYPE,
  payload: slotType
})

export const toggleDeleteSlotType = slotType => ({
  type: TOGGLE_DELETE_SLOT_TYPE,
  payload: slotType
})

export const createSlotType = (data) => ({
  type: CREATE_SLOT_TYPE,
  payload: data
})

export const slotTypeCreated = (data) => ({
  type: SLOT_TYPE_CREATED,
  payload: data
})

export const updateSlotType = (data) => ({
  type: UPDATE_SLOT_TYPE,
  payload: data
})

export const slotTypeUpdated = (data) => ({
  type: SLOT_TYPE_UPDATED,
  payload: data
})

export const deleteSlotType = () => ({
  type: DELETE_SLOT_TYPE
})

export const slotTypeDeleted = id => ({
  type: SLOT_TYPE_DELETED,
  payload: id
})
export const setFormErrors = id => ({
  type: SET_FORM_ERRORS,
  payload: id
})
