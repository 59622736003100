import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { formatColumnBody } from 'src/Utils/index.ts'
import { LIST_TYPE_CALENDAR } from 'src/Services/Constants'
import SystemNameInput from 'src/Components/SystemNameInput/index'
import { translateConf } from 'src/Services/translation'
import ReferenceFieldSelect from 'src/Components/ReferenceFieldSelect/index.tsx'
import { setSelectedReferenceField } from '../state/actions'

const ColumnForm = ({ trans, defaultColumn, onSubmit, list }) => {
  const dispatch = useDispatch()

  const { icons } = useSelector(state => state.Dictionary)
  const { addColumnStatus, selectedReferenceField, selectedReferenceFieldDetails, form, refForm, type }
    = useSelector(state => state.ListEdit)
  const { configTranslations } = useSelector(state => state.Root)

  const [ label, setLabel ] = React.useState(null)
  const [ isAvailable, setIsAvailable ] = useState(null)

  const transConf = translateConf(configTranslations)

  const reset = () => {
    setLabel(null)
    setIsAvailable(null)
  }

  const onReferenceFieldSelect = field => dispatch(setSelectedReferenceField(field))

  const getOptions = () => selectedReferenceFieldDetails?.options?.values || []

  const getDefaultMapping = option => defaultColumn?.mapping.find(_ => _.value === option.systemName) || null

  const handleSubmit = e => {
    e.preventDefault()

    const column = { label: e.target.label.value }

    if (!defaultColumn)
      column.systemName = e.target.systemName.value

    const columnBodyFormatted = formatColumnBody(selectedReferenceField, column)

    const data = {
      label: columnBodyFormatted.label,
      systemName: columnBodyFormatted.systemName,
      fieldId: columnBodyFormatted.fieldId,
      referenceFieldId: columnBodyFormatted.referenceFieldId,
      referenceFieldLabel: columnBodyFormatted.referenceFieldLabel
    }

    if (type === LIST_TYPE_CALENDAR) {
      data.cssClasses = e.target.cssClasses.value || null
      data.isEventTitle = e.target.isEventTitle.checked || false
    }

    if (defaultColumn?.id)
      data.id = defaultColumn.id

    if (type === LIST_TYPE_CALENDAR && getOptions().length)
      data.mapping = getOptions().map((option, i) => ({
        value: option.systemName,
        icon: type === LIST_TYPE_CALENDAR
          ? e.target[`optionIcon${ i }`].value === trans('none')
            ? null
            : parseInt(e.target[`optionIcon${ i }`].value, 10)
          : null,
        color: type === LIST_TYPE_CALENDAR
          ? e.target[`optionColor${ i }`].value === '#ffffff' ? null : e.target[`optionColor${ i }`].value
          : null
      }))

    onSubmit(data)
    reset()
  }

  const onSystemNameChange = (systemName, isAvailable) => {
    setIsAvailable(isAvailable)
  }

  return <Form onSubmit={ handleSubmit }>
    { defaultColumn && <Row>
      <Col>
        <small className={ 'text-muted' }>{ trans('systemName') } : { defaultColumn.systemName }</small>
      </Col>
    </Row> }
    <Row className={ 'mb-2' }>
      <Col>
        <Form.Group>
          <Form.Label>{ trans('formField') }</Form.Label>
          <ReferenceFieldSelect
            type={ type }
            refForm={ refForm }
            form={ form }
            value={ selectedReferenceField }
            onChange={ onReferenceFieldSelect }
            elementId={ 'columnFieldSelector' }
            required
          />
        </Form.Group>
      </Col>
    </Row>
    <Row className={ 'mb-2' }>
      <Col>
        <Form.Group className={ 'mb-2' }>
          <Form.Label>{ trans('label') }</Form.Label>
          <Form.Control size={ 'sm' } name={ 'label' } defaultValue={ defaultColumn?.label }
                        onChange={ e => setLabel(e.target.value) } required />
        </Form.Group>
      </Col>
      { !defaultColumn && <Col>
        <Form.Group className={ 'mb-2' }>
          <Form.Label>{ trans('systemName') }</Form.Label>
          <SystemNameInput size={ 'sm' } label={ label } parent={ list.systemName } model={ 'ListColumn' }
                           required onChange={ onSystemNameChange } name={ 'systemName' } />
        </Form.Group>
      </Col> }
    </Row>
    { type === LIST_TYPE_CALENDAR && <Row className={ 'mb-2' }>
      <Col>
        <Form.Group>
          <Form.Label>{ trans('columnForm.class') }</Form.Label>
          <Form.Control name={ 'cssClasses' } type={ 'text' } defaultValue={ defaultColumn?.cssClasses } size={ 'sm' }/>
        </Form.Group>
      </Col>
      <Col className={ 'd-flex' }>
        <Form.Group className={ 'mt-auto' }>
          <Form.Switch defaultChecked={ defaultColumn?.isEventTitle }
                       label={ trans('columnForm.isPartOfEventTitle') } name={ 'isEventTitle' }/>
        </Form.Group>
      </Col>
    </Row> }
    { type === LIST_TYPE_CALENDAR && getOptions().map((option, i) =>
      <Row key={ i } className={ 'mb-2 d-flex' }>
        <Col xs={ '1' } className={ 'align-self-center' }>
          <Form.Label className={ 'm-0' }>{ transConf('LIST_COLUMN')(option) }</Form.Label>
        </Col>
        <Col xs={ '3' } className={ 'align-self-center' }>
          <Form.Select size={ 'sm' } name={ `optionIcon${ i }` } defaultValue={ getDefaultMapping(option)?.icon?.id }>
            <option value={ null }>{ trans('none') }</option>
            { icons.map(icon => <option key={ icon.id } value={ icon.id }>{ transConf('ICON')(icon) }</option>) }
          </Form.Select>
        </Col>
        <Col>
          <Form.Control type={ 'color' }
                        defaultValue={ getDefaultMapping(option)?.color || '#FFFFFF' }
                        name={ `optionColor${ i }` }
                        size={ 'sm' }
          />
        </Col>
      </Row>) }
    <Row>
      <Col>
        <Button variant={ 'primary' } type={ 'submit' } disabled={ !defaultColumn && !isAvailable }
                className={ `float-end ${ addColumnStatus === 'pending' ? 'is-loading' : '' }` }>
          { trans('confirm') }
        </Button>
      </Col>
    </Row>
  </Form>
}

export default ColumnForm
