
export default {
  english: {
    title: 'Field types manager',
    addNewFieldType: 'Add a field type',
    entries: 'of {total} entries',
    form: {
      name: 'Name',
      icon: 'Icon',
      baseFieldType: 'Base field type',
      defaultBaseFieldTypeOption: 'Select a base field type'
    },
    modal: {
      create: {
        title: 'Create a new field type'
      },
      update: {
        title: 'Update a field type'
      },
      confirmDeleteTitle: 'Are you sure you want to delete this field type ?'
    }
  },
  french: {
    title: 'Gestionnaire de type de champ',
    addNewFieldType: 'Créer un type de champ',
    entries: 'sur {total} entrées',
    form: {
      name: 'Nom',
      icon: 'Icône',
      baseFieldType: 'Type de champ de base',
      defaultBaseFieldTypeOption: 'Sélectionnez un type de champ de base'
    },
    modal: {
      create: {
        title: 'Créer un nouveau type de champ'
      },
      update: {
        title: 'Modifier un type de champ'
      },
      confirmDeleteTitle: 'Êtes-vous sûr de vouloir supprimer ce type de champ ?'
    }
  }
}
