export default {
  english: {
    title: 'Validate identity',
    details: 'We need to ensure that nobody impersonate your identity',
    pleaseEnterPin: 'Enter you PIN.',
  },
  french: {
      title: 'Valider l\'identité',
      pleaseEnterPin: 'Entrez votre PIN.',
      details: 'Nous avons besoin de nous assurez que personne se fait passer pour vous.',
  }
}
