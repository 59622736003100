import React from 'react'
import { useDispatch } from 'react-redux'
import { Dropdown } from 'react-bootstrap'
import { deleteSection } from '../../state/actions'

const SectionHeader = ({
  sectionId,
  section: {
    name,
  },
  trans,
  onEditClick,
}) => {
  const dispatch = useDispatch()

  const handleSectionDeleteButtonClick = () => dispatch(deleteSection(sectionId))

  return <header className="card-header level">
    <div className="level-left">
      <Dropdown>
        <Dropdown.Toggle variant={ 'transparent' } id={ `sectionOptions${ sectionId }` } size={ 'sm' }
                         className={ 'p-0' }>
          <i className="fas fa-cog"/> { name }
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={ onEditClick }>
            { trans('edit') }
          </Dropdown.Item>
          <Dropdown.Item onClick={ handleSectionDeleteButtonClick }>
            { trans('delete') }
          </Dropdown.Item>
          <Dropdown.Item>
            { trans('setting.exportAllFields') }
          </Dropdown.Item>
          <Dropdown.Item>
            { trans('setting.importFields') }
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  </header>
}

export default SectionHeader
