import React from 'react'
import { useSelector } from 'react-redux'
import { Row, Col, Button, Container, Spinner } from 'react-bootstrap'
import { translate } from 'src/Services/translation'
import { getNavbarHeight } from 'src/Utils'
import { StoreState } from 'src/Services/Store/reducers'
import AnnouncementDisplay from './AnnouncementDisplay'
import translation from '../translations'

interface Props {
  title: string
}

const ViewHeader: React.FC<Props> = ({ title }) => {
  const { language } = useSelector((state: StoreState) => state.Root.user)
  const announcements = useSelector((state: StoreState) => state.Root.announcements)
  const trans = translate(translation)(language)

  const {
    showTitle,
    title: customTitle,
    hasPrimaryButton,
    isLoading,
    isHeaderSticky,
    rightHeaderTemplate,
    leftHeaderTemplate,
    onPrimaryButtonClick,
    primaryButtonType,
    isPrimaryButtonDisable
  } = useSelector((state: StoreState) => state.View)

  const getPrimaryButtonLabel = () => trans(primaryButtonType === 'SAVE' ? 'save' : 'add')
  const getPrimaryButtonIcon = () => primaryButtonType === 'SAVE' ? 'fa-save' : 'fa-plus-circle'

  const renderRightSide = () => (
    <div className={ 'd-flex align-items-center' }>
      { hasPrimaryButton && (
        <Button
          onClick={ onPrimaryButtonClick }
          disabled={ isPrimaryButtonDisable }
        >
          <i className={ `me-1 fas ${ getPrimaryButtonIcon() }` } />
          <span>{ getPrimaryButtonLabel() }</span>
        </Button>
      ) }

      { isLoading && <Spinner as={ 'span' }
                              animation={ 'border' }
                              size={ 'sm' }
                              aria-hidden={ 'true' }
                              className={ rightHeaderTemplate ? 'mx-2' : 'ms-2' }
      /> }

      { rightHeaderTemplate }
    </div>
  )

  const getTitle = () => (customTitle === undefined ? title : customTitle)

  return (
    <div
      id={ 'viewHeader' }
      className={ `py-2 bg-body ${ isHeaderSticky ? 'sticky-top' : '' }` }
      style={ { top: `${ getNavbarHeight() }px` } }
    >
      <Container>
        { !!announcements.length && (
          <Row className={ 'mb-2' }>
            <Col>
              <AnnouncementDisplay announcements={ announcements } />
            </Col>
          </Row>
        ) }
        <Row className={ leftHeaderTemplate ? 'mb-1' : '' }>
          <Col className={ 'd-flex align-items-center' }>
            { showTitle && (
              <span style={ { minHeight: '2.8em' } }>
                { typeof getTitle() === 'string' ? (
                  <h2 className={ 'd-inline mb-0 me-2' }>{ getTitle() }</h2>
                ) : (
                  getTitle()
                ) }
              </span>
            ) }
          </Col>
          { !leftHeaderTemplate && (
            <Col sm={ 'auto' }>
              { renderRightSide() }
            </Col>
          ) }
        </Row>
        { leftHeaderTemplate && <Row>
          <Col>
            { leftHeaderTemplate }
          </Col>
          <Col sm={ 'auto' }>
            { renderRightSide() }
          </Col>
        </Row> }
      </Container>
    </div>
  )
}

export default ViewHeader
