import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ListGroup } from 'react-bootstrap'
import { translateConf } from 'src/Services/translation'

const List = () => {
  const { configTranslations } = useSelector(state => state.Root)
  const { lists } = useSelector(state => state.Homepage)

  const transConf = translateConf(configTranslations)

  return <ListGroup variant="flush">
      {lists.map(list =>
        <ListGroup.Item key={list.id}>
          <Link to={`/list/${list.id}/run`}>{ transConf('LIST')(list) }</Link>
        </ListGroup.Item>
      )}
    </ListGroup>
}

export default List
