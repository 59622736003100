import React from 'react'
import { translate } from 'src/Services/translation'
import { Container, Tab, Tabs } from 'react-bootstrap'
import translation from './translations'
import RestoreDeletedFields from './Components/restoreDeletedFields/restoreDeletedFields'
import RestoreForms from './Components/restoreForms/restoreForms'
import RestoreInstances from './Components/restoreInstances/restoreInstances'
import RestoreSections from './Components/restoreSections/restoreSections'
import { setOptions } from 'src/Layouts/View/state/actions'
import { fetchAdminTools } from '../AdminTools/state/actions'
import { useDispatch, useSelector } from 'react-redux'

const RestoreDeleted = () => {
  const dispatch = useDispatch()

  const { language } = useSelector(state => state.Root.user)

  const trans = translate(translation)(language)

  React.useEffect(() => {
    dispatch(setOptions({
      hasPrimaryButton: false
    }))
    dispatch(fetchAdminTools())
  }, [ dispatch ])

  return (
    <Container>
      <Tabs defaultActiveKey="restoreDeletedFields" id="uncontrolled-tab-example" className="mb-0">
        <Tab eventKey="restoreDeletedFields" title={ trans('restoreDeletedFields') }>
          <RestoreDeletedFields/>
        </Tab>
        <Tab eventKey="restoreForms" title={ trans('restoreForms') }>
          <RestoreForms/>
        </Tab>
        <Tab eventKey="restoreInstances" title={ trans('restoreInstances') }>
          <RestoreInstances/>
        </Tab>
        <Tab eventKey="restoreSections" title={ trans('restoreSections') }>
          <RestoreSections/>
        </Tab>
      </Tabs>
    </Container>
  )
}

export default RestoreDeleted
