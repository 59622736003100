export default {
  english: {
    rolePermissions: 'Role permissions',
    title: 'Users Manager',
    addNewUser: 'Add user',
    entries: 'of {total} entries',
    logAs: 'Log as',
    logAsTitle: 'Log as this user.',
    logAsDisabledTitle: 'Only one level of « log as » allowed. Please go back to your account to do this.',
    duplicateUsernameError: 'Username is already used for another user',
    duplicateEmailError: 'Email is already used for another user',
    th: {
      activeRoles: 'Roles',
      locations: 'Locations',
      lastLogin: 'Last Login'
    }
  },
  french: {
    rolePermissions: 'Autorisations de rôle',
    title: 'Users Manager',
    addNewUser: 'Créer un utilisateur',
    entries: 'sur {total} entrées',
    logAs: 'S\'identifier en tant que',
    logAsTitle: 'S\'identifier en tant que cet utilisateur.',
    logAsDisabledTitle: 'Un seul niveau de connexion « en tant que » est permis. Merci de vous reconnecter avec votre compte.',
    duplicateUsernameError: 'Le nom d\'utilisateur est déjà utilisé',
    duplicateEmailError: 'L\'email est déjà utilisé pour un autre utilisateur',
    th: {
      activeRoles: 'Rôles',
      locations: 'Lieux',
      lastLogin: 'Dernière connexion'
    }
  }
}
