import { parseFields } from 'src/Views/FormFiller/utils/Fields'
import {
  FIELD_ACTION_OPTION_VALUE,
  FIELD_BASE_TYPE_DATE_TIME,
  FIELD_BASE_TYPE_DOCUMENT,
  FIELD_BASE_TYPE_EXTENSIBLE, FIELD_BASE_TYPE_REFERENCE,
  FIELD_BASE_TYPES_WITH_MULTIPLE_VALUES, ID_SEPARATOR
} from 'src/Services/Constants'
import moment from 'moment'
import AppSentry, { Levels } from 'src/Services/Sentry'
import FormCustomizationCondition, {
  FormCustomizationConditionOperator,
  FormCustomizationConditionType, FormCustomizationConditionTypeDate
} from 'src/Types/FormCustomizationCondition'
import { FieldEvent, FieldEventData } from 'src/Views/FormFiller/Types/Field'
import { FieldOptionSaveTypes } from 'src/Types/FieldOption'
import { Form } from 'src/Types/Form'
import { Instance } from 'src/Types/Instance'
import Section from 'src/Types/Section'
import FormCustomizationOrder from 'src/Types/FormCustomizationOrder'
import Row from 'src/Types/Row'
import { Field } from 'src/Types/Field'
import { StoreState } from 'src/Services/Store/reducers'
import { isUuidV4String } from 'src/Types/Uuid'
import OpenedInstance from 'src/Views/FormFiller/Types/OpenedInstance'
import FormFillerSection from 'src/Views/FormFiller/Types/FormFillerSection'
import FormFillerRow from 'src/Views/FormFiller/Types/FormFillerRow'

const parseRows = (rows: Row[], fields: Field[]): FormFillerSection['rows'] =>
  Object.values(fields.reduce<{ [rowId: string]: FormFillerRow }>((results, field) => {
    const { row, id } = field

    if (results === null)
      return { [row.id]: { ...rows.find(r => r.id === row.id), fields: [ id ] } }
    else if (!(row.id in results))
      return { ...results, [row.id]: { ...rows.find(r => r.id === row.id), fields: [ id ] } }

    return {
      ...results,
      [row.id]: { ...results[row.id], fields: [ ...results[row.id].fields, id ] }
    }
  }, null))

const parseSections = (sections: Section[]): FormFillerSection[] =>
  sections.map(section => {
    const fields = section.rows.reduce((fields, row) => [ ...fields, ...row.fields ], [])

    return {
      ...section,
      rows: section.rows.length > 0 ? parseRows(section.rows, fields) : [],
      fields
    }
  })

export const parseInstance = (instance: Instance, form: Form): OpenedInstance => {
  return {
    ...instance,
    fields: parseFields(instance, form.sections),
    sections: parseSections(form.sections),
  }
}

export const isValidateUserIdentityNeeded = (
    formFillerState: StoreState['FormFiller'],
    fieldId: string = null,
    $context: FieldOptionSaveTypes = null,
    ) => {
  const { userCode, fieldsToSave } = formFillerState
  const { fields } = formFillerState.openedInstance

  if (userCode) return false

  // Document fields needs validation only on save as final (or sign)
  const isFieldNeedsValidation = (field: Field | null) =>
      (field?.options?.validateUserIdentity && field?.type?.baseFieldType !== FIELD_BASE_TYPE_DOCUMENT) ||
       [ FieldOptionSaveTypes.SAVE_AS_FINAL_WITH_PIN_CODE ,
         FieldOptionSaveTypes.SAVE_AND_SIGN_WITH_PIN_CODE ].includes($context)

  return fieldId
    ? isFieldNeedsValidation(fields[fieldId])
    : fieldsToSave.some(id => isFieldNeedsValidation(fields[id]))
}

export const getInstanceDataToSave = ({ fieldsToSave, userCode, openedInstance }: StoreState['FormFiller']) => {

  if (!fieldsToSave.length)
    return null

  const { fields } = openedInstance

  const fieldsToSaveFormatted = fieldsToSave.reduce((results, fieldId) => {

    const field = fields[fieldId]

    if (!field?.value)
      AppSentry.captureMessage(`getFieldsToSave: field without value : ${ fieldId }`, Levels.LEVEL_WARN)

    let value = null

    if (field.type.baseFieldType === FIELD_BASE_TYPE_EXTENSIBLE || field.extensibleFieldId)
      return results
    else
      value = field.value

    return {
      data: { ...results.data, [field.systemName]: value },
      files: results.files,
    }
  }, { data: {}, files: [] })

  if (!fieldsToSaveFormatted.files.length && !Object.keys(fieldsToSaveFormatted.data).length)
    return null

  return {
    form: {
      data: fieldsToSaveFormatted.data,
      userCode,
      useSystemNameAsKey: true,
    },
    files: fieldsToSaveFormatted.files
  }
}

const isFormCustomizationConditionValueFulfilledForMultipleValuesField = (
    value: any,
    conditionValue: any,
    operator: FormCustomizationConditionOperator,
  ) => {

  if (conditionValue === null)
    return !value
  if (value === null)
    return false

  conditionValue = (Array.isArray(conditionValue) ? conditionValue : conditionValue.split(',')) || []

  if (!Array.isArray(value))
    return AppSentry.captureError(new Error('Field typed as multiple values but value is not an array'), { value })

  value = value.map(v => v?.systemName || v)

  switch (operator) {
    case FormCustomizationConditionOperator.EQUALS:
      return conditionValue.every((cv: string) => value.includes(cv)) && value.every((v: string) => conditionValue.includes(v))
    case FormCustomizationConditionOperator.CONTAINS:
      return conditionValue.some((cv: string) => value.includes(cv))
    default:
      return false
  }
}

const isFormCustomizationConditionValueFulfilledForDateField = (
    value: any,
    conditionValue: any,
    operator: FormCustomizationConditionOperator,
    field: Field,
  ) => {

  if (conditionValue === null)
    return !value
  if (value === null)
    return false

  try {
    conditionValue = moment(conditionValue)
    value = moment(value)
  } catch (e) {
    return AppSentry.captureError(new Error('Field typed as date but a value is not an date'),
      { conditionValue, value })
  }

  if (field.options?.showTime && !field.options?.showDate) {
    conditionValue = moment(conditionValue.format('HH:mm'), 'HH:mm')
    value = moment(value.format('HH:mm'), 'HH:mm')
  } else if (!field.options?.showTime && field.options?.showDate) {
    conditionValue = moment(conditionValue.format('DD/MM/YYYY'), 'DD/MM/YYYY')
    value = moment(value.format('DD/MM/YYYY'), 'DD/MM/YYYY')
  }

  switch (operator) {
    case FormCustomizationConditionOperator.EQUALS:
      return value.isSame(conditionValue)
    case FormCustomizationConditionOperator.SUPERIOR:
      return value.isAfter(conditionValue)
    case FormCustomizationConditionOperator.SUPERIOR_OR_EQUAL:
      return value.isSameOrAfter(conditionValue)
    case FormCustomizationConditionOperator.INFERIOR:
      return value.isBefore(conditionValue)
    case FormCustomizationConditionOperator.INFERIOR_OR_EQUAL:
      return value.isSameOrBefore(conditionValue)
    default:
      return false
  }
}

const isFormCustomizationConditionValueFulfilled = (condition: FormCustomizationCondition, value: any) => {

  const operator = condition.operator || null
  const conditionValue = condition.value || null

  // If the value can be anything then it is automatically fulfilled
  if (conditionValue === '*')
    return true

  if (value?.systemName) {
    value = value.systemName
  }

  const isMultipleValuesField = FIELD_BASE_TYPES_WITH_MULTIPLE_VALUES.includes(condition.field?.type?.baseFieldType)
  if (isMultipleValuesField)
    return isFormCustomizationConditionValueFulfilledForMultipleValuesField(value, conditionValue, operator)

  const isDateField = FIELD_BASE_TYPE_DATE_TIME === condition.field?.type?.baseFieldType
  if (isDateField)
    return isFormCustomizationConditionValueFulfilledForDateField(value, conditionValue, operator, condition.field)

  const valueAsInt = parseInt(value, 10)
  const conditionValueAsInt = parseInt(conditionValue, 10)

  switch (operator) {
    case FormCustomizationConditionOperator.EQUALS:
      return conditionValue === null && !value || conditionValue == value
    case FormCustomizationConditionOperator.NOT_EQUALS:
      return conditionValue === null && !value || conditionValue != value
    case FormCustomizationConditionOperator.CONTAINS:
      return conditionValue === null && !value || value?.includes(conditionValue)
    case FormCustomizationConditionOperator.SUPERIOR:
      return isNaN(valueAsInt) ? false : valueAsInt > conditionValueAsInt
    case FormCustomizationConditionOperator.INFERIOR:
      return isNaN(valueAsInt) ? false : valueAsInt < conditionValueAsInt
    case FormCustomizationConditionOperator.SUPERIOR_OR_EQUAL:
      return isNaN(valueAsInt) ? false : valueAsInt >= conditionValueAsInt
    case FormCustomizationConditionOperator.INFERIOR_OR_EQUAL:
      return isNaN(valueAsInt) ? false : valueAsInt <= conditionValueAsInt
    case FormCustomizationConditionOperator.BEGIN_WITH:
      return value?.startsWith(conditionValue)
    case FormCustomizationConditionOperator.ENDS_WITH:
      return value?.endsWith(conditionValue)
    default:
      return false
  }
}

const isFormCustomizationConditionDateFulfilled = (condition: FormCustomizationCondition, value: any) => {
  value = new Date(value)

  if (!moment.isDate(value))
    return AppSentry.captureError(new Error('Field typed as date but value is not a date (nor empty)'),
      { field: condition.field, value })

  value = moment(value)

  switch (condition.type) {
    case FormCustomizationConditionType.DATE_TODAY:
      return value.isSame(moment(), 'day')
    case FormCustomizationConditionType.DATE_CURRENT_WEEK:
      return value.isSame(moment(), 'week')
    case FormCustomizationConditionType.DATE_CURRENT_MONTH:
      return value.isSame(moment(), 'month')
    case FormCustomizationConditionType.DATE_CURRENT_YEAR:
      return value.isSame(moment(), 'year')
    default:
      return false
  }
}

export const getFormCustomizationConditionsTriggeredByEvent = (
    formCustomizationOrders: FormCustomizationOrder[],
    event: FieldEvent,
    field: Field,
    options: FieldEventData,
  ) => {
  const conditionsTriggered = []

  const value = options[FIELD_ACTION_OPTION_VALUE] || null

  for (const order of formCustomizationOrders) {
    for (const condition of order.conditions) {

      if (condition.field.id !== field.id)
        continue

      const isClickTypeConditionFulfilled =
        event === FieldEvent.CLICK
        && condition.type === FormCustomizationConditionType.CLICK
      const isValueChangeTypeConditionFulfilled =
        event === FieldEvent.VALUE_CHANGE
        && condition.type === FormCustomizationConditionType.VALUE
        && isFormCustomizationConditionValueFulfilled(condition, value)
      const isDateTypeConditionFulfilled =
        event === FieldEvent.VALUE_CHANGE
        && Object.values(FormCustomizationConditionType).includes(condition.type as FormCustomizationConditionType)
        && isFormCustomizationConditionDateFulfilled(condition, value)

      if (isClickTypeConditionFulfilled || isValueChangeTypeConditionFulfilled || isDateTypeConditionFulfilled)
        conditionsTriggered.push(condition)
    }
  }

  return conditionsTriggered
}

export const getFormCustomizationConditionsTriggered = (
    formCustomizationOrders: FormCustomizationOrder[],
    instance: OpenedInstance,
  ) => {
  const conditionsTriggered = []

  const fields = instance.fields || {}

  for (const order of formCustomizationOrders) {
    for (const condition of order.conditions) {

      let field = fields[condition.field.id]

      if (!field)
        continue

      if (field.type.baseFieldType === FIELD_BASE_TYPE_REFERENCE && condition.listColumn  ) {
        const listFields = Object.values(fields)
        field = listFields?.find(field => field?.listColumn?.id === condition.listColumn?.id)
      }
      const value = field.value

      const isValueChangeTypeConditionFulfilled =
        condition.type === FormCustomizationConditionType.VALUE
        && isFormCustomizationConditionValueFulfilled(condition, value)
      const isDateTypeConditionFulfilled =
        Object.values(FormCustomizationConditionTypeDate).includes(condition.type as FormCustomizationConditionTypeDate)
        && isFormCustomizationConditionDateFulfilled(condition, value)

      if (isValueChangeTypeConditionFulfilled || isDateTypeConditionFulfilled)
        conditionsTriggered.push(condition)
    }
  }

  return conditionsTriggered
}

export const getDocumentFieldTypeValue = (value: any) => {
  return value && 'content' in value ? value.content : null

  /*try {
    fieldValue = JSON.parse(value)
    return JSON.stringify(fieldValue?.content, (key, value) => {
      if (null !== value) return value
    })
  } catch (error) {
    return value?.content || value || ''
  }*/
}

export const isFieldMatchExtensibleRow = (field: Field, extensibleId: string, rowId: string) => {
  if (field.extensibleFieldId !== extensibleId || !isUuidV4String(field.id))
    return false

  const splitFieldId = field.id.split(ID_SEPARATOR)
  const fieldId = splitFieldId[0]
  const fieldRowId = splitFieldId[1]

  return fieldRowId === rowId.toString()
}
