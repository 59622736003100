
export default {
  english: {
    title: 'Announcements',
    addNew: 'Add',
    entries: 'of {total} entries',
    form: {
      startAt: 'Start at',
      endAt: 'End at',
      text: 'Text',
      user: 'User',
      role: 'Role',
    },
    column: {
      startAt: 'Start at',
      endAt: 'End at',
      text: 'Text',
      user: 'User',
      role: 'Role',
    },
    modal: {
      create: {
        title: 'Create a new announcement',
      },
      update: {
        title: 'Update a announcement',
      },
      confirmDelete: {
        title: 'Are you sure you want to delete this announcement ?'
      }
    }
  },
  french: {
    title: 'Annonces',
    addNew: 'Créer',
    entries: 'sur {total} entrées',
    form: {
      startAt: 'Commence à',
      endAt: 'Termine à',
      text: 'Texte',
      user: 'Utilisateur',
      role: 'Role',
    },
    column: {
      startAt: 'Commence à',
      endAt: 'Termine à',
      text: 'Texte',
      user: 'Utilisateur',
      role: 'Role',
    },
    modal: {
      create: {
        title: 'Créer une nouvelle annonce'
      },
      update: {
        title: 'Modifier une annonce'
      },
      confirmDelete: {
        title: 'Êtes-vous sûr de vouloir supprimer cette annonce ?'
      }
    }
  }
}
