import React from 'react'
import styled from 'styled-components'
import { path } from 'ramda'
import { translate } from '../../../../../Services/translation'
import { withState } from '../../../../../Components/Util'
import { fieldEvent } from '../../../state/actions'
import translation from './translations'
import { isAdmin } from '../../../../../Utils/Security'
import { Form } from 'react-bootstrap'
import { FieldEvent } from 'src/Views/FormFiller/Types/Field'

// propsFromState :: Map String (State -> a)
const propsFromState = {
  language: path([ 'Root', 'user', 'language' ]),
  roles: path([ 'Root', 'user', 'roles' ])
}

// buildDesktopAppLink :: String -> String
const buildDesktopAppLink = path => `labretriever://${path}`

const isWindowsComputer = () =>
  window.navigator.appVersion.indexOf('Win') !== -1

const buttons = trans => (
  <React.Fragment>
    <DesktopAppButton
      className="button"
      target="_blank"
      href={buildDesktopAppLink('/upload')}
    >
      {trans('desktopAppUploadData')}
    </DesktopAppButton>

    <DesktopAppButton
      className="button"
      target="_blank"
      href={buildDesktopAppLink('/view-online')}
    >
      {trans('desktopAppViewOnline')}
    </DesktopAppButton>

    <DesktopAppButton
      className="button"
      target="_blank"
      href={buildDesktopAppLink('/download')}
    >
      {trans('desktopAppDownloadData')}
    </DesktopAppButton>
  </React.Fragment>
)

// View :: Props -> React.Component
const PsgField = ({
  field,
  language,
  dispatch,
  value,
  id = null,
  roles = []
}) => {
  const trans = translate(translation)(language)

  const onChange = e => {
    dispatch(fieldEvent(field, FieldEvent.VALUE_CHANGE, { value: e.target.value }))
  }

  return (
    <React.Fragment>
      <Form.Label htmlFor={`serverPath${id}`}>&nbsp;{trans('serverPath')}</Form.Label>

      <InputsWrapper>
        <Form.Control
          id={`serverPath${id}`}
          size={'sm'}
          name={`serverPath${id}`}
          defaultValue={value || ''}
          onChange={ onChange }
          disabled={!isAdmin(roles)}
        />

        {isWindowsComputer() && buttons(trans)}
      </InputsWrapper>
    </React.Fragment>
  )
}

export default withState(propsFromState)(PsgField)

const InputsWrapper = styled.div`
  display: flex;
  align-items: center;
`

const DesktopAppButton = styled.a`
  margin-left: 1em;
  color: white;
  background-color: rgb(113, 200, 241);
`
