export const FETCH_ITEMS = '[NAVIGATION_ITEMS] FETCH_ITEMS'
export const FETCH_ITEM = '[NAVIGATION_ITEMS] FETCH_ITEM'
export const CREATE_ITEM = '[NAVIGATION_ITEMS] CREATE_ITEM'
export const DELETE_ITEM = '[NAVIGATION_ITEMS] DELETE_ITEM'
export const UPDATE_ITEM = '[NAVIGATION_ITEMS] UPDATE_ITEM'

export const ITEMS_RECEIVED = '[NAVIGATION_ITEMS] ITEMS_RECEIVED'
export const ITEM_RECEIVED = '[NAVIGATION_ITEMS] ITEM_RECEIVED'
export const ITEM_CREATED = '[NAVIGATION_ITEMS] ITEM_CREATED'
export const ITEM_DELETED = '[NAVIGATION_ITEMS] ITEM_DELETED'
export const ITEM_UPDATED = '[NAVIGATION_ITEMS] ITEM_UPDATED'

export const SET_DELETING_ITEM = '[NAVIGATION_ITEMS] SET_DELETING_ITEM'
export const SET_UPDATING_ITEM = '[NAVIGATION_ITEMS] SET_UPDATING_ITEM'
export const SET_CREATING_ITEM = '[NAVIGATION_ITEMS] SET_CREATING_ITEM'

export const SET_QUERY = '[NAVIGATION_ITEMS] SET_QUERY'
export const SET_LIMIT = '[NAVIGATION_ITEMS] SET_LIMIT'
export const SET_PAGE = '[NAVIGATION_ITEMS] SET_PAGE'
