import {
  WORKFLOW_CREATED, WORKFLOW_DELETED, WORKFLOW_RECEIVED, CREATE_WORKFLOW, DELETE_WORKFLOW, UPDATE_WORKFLOW,
  WORKFLOW_UPDATED, SET_DELETING_WORKFLOW, SET_UPDATING_WORKFLOW, TOGGLE_CREATE_WORKFLOW_MODAL
} from './actionTypes'

const initialState = {
  isLoading: false,
  creatingItem: null,
  updatingItem: null,
  deletingItem: null,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CREATE_WORKFLOW:
      return {
        ...state,
        isLoading: true
      }
    case UPDATE_WORKFLOW:
      return {
        ...state,
        isLoading: true
      }
    case DELETE_WORKFLOW:
      return {
        ...state,
        isLoading: true
      }
    case WORKFLOW_RECEIVED:
      return {
        ...state
      }
    case WORKFLOW_CREATED:
      return {
        ...state,
        creatingItem: null,
      }
    case WORKFLOW_UPDATED:
      return {
        ...state,
        updatingItem: null,
      }
    case WORKFLOW_DELETED:
      return {
        ...state,
        deletingItem: null,
        updatingItem: null,
      }
    case SET_DELETING_WORKFLOW:
      return {
        ...state,
        deletingItem: payload
      }
    case SET_UPDATING_WORKFLOW:
      return {
        ...state,
        updatingItem: payload
      }
    case TOGGLE_CREATE_WORKFLOW_MODAL:
      return {
        ...state,
        creatingItem: !state.creatingItem
      }
    default:
      return state
    }
}
