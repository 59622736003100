
export default {
  english: {
    backToHome: 'Back Home',
    bulkImport: {
      title: 'Bulk import',
      instructionsPrefix: 'Instructions',
      instructions: 'Download CSV files with comma delimited data. Use the column headers corresponding to the actual system fields. Columns not associated with a system field will be ignored during import.',
      parsingError: 'Error while parsing file',
    },
    search: {
      newSearch: 'New search',
      manageSearch: 'Manage searches',
      useSavedSearch: 'Use a saved search',
      searchTitle: 'Search title',
    }
  },
  french: {
    backToHome: 'Back Home',
    bulkImport: {
      title: 'Import en masse',
      instructionsPrefix: 'Instructions',
      instructions: 'Télécharger les fichiers CSV avec des données délimitées par des virgules. Utiliser les en-têtes de colonne correspondants aux champs système actuels. Les colonnes non associées à un champ système ne seront pas prises en compte lors de l\'import.',
      parsingError: 'Error while parsing file',
    },
    search: {
      newSearch: 'Nouvelle recherche',
      manageSearch: 'Gérer les recherches',
      useSavedSearch: 'Utiliser une recherche sauvegardée',
      searchTitle: 'Titre de la recherche',
    }
  }
}
