import { Column } from './Types'
import { ColumnValueType } from 'src/Types/RequestFilter'

const columns: Column[] = [
  {
    name: 'timestamp',
    type: ColumnValueType.DATE,
  },
  {
    name: 'context',
    type: ColumnValueType.STRING,
  },
  {
    name: 'code',
    type: ColumnValueType.STRING,
  },
  {
    name: 'user',
    type: ColumnValueType.STRING,
  },
  {
    name: 'ip',
    type: ColumnValueType.STRING,
  },
  {
    name: 'impersonator',
    type: ColumnValueType.STRING,
  },
  {
    name: 'workflowId',
    type: ColumnValueType.NUMBER,
  },
  {
    name: 'data',
    type: ColumnValueType.STRING,
  },
]

export default columns
