import React, { FormEvent, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Col, Dropdown, Form, Row } from 'react-bootstrap'
import { getAsPdf } from './utils'
import { L10n } from '@syncfusion/ej2-base'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { DocumentEditorContainerComponent, Toolbar } from '@syncfusion/ej2-react-documenteditor'
import { ClickEventArgs } from '@syncfusion/ej2-navigations'
import { CustomToolbarItemModel } from '@syncfusion/ej2-documenteditor/src/document-editor/base/events-helper'
import { ToolbarItem } from '@syncfusion/ej2-documenteditor/src/document-editor/base/types'
import { XmlHttpRequestEventArgs } from '@syncfusion/ej2-documenteditor/src/document-editor/base'
import { translate } from 'src/Services/translation'
import { Form as FormType } from 'src/Types/Form'
import SystemField from 'src/Types/SystemField'
import defaultConfig from './config'
import translation from './translations'
import SystemFieldSelect from '../SystemFieldSelect'
import EntitySelect from '../EntitySelect'
import {
  DocumentEditorContainer
} from '@syncfusion/ej2-documenteditor/src/document-editor-container/document-editor-container'
import { TEMPLATE_VARIABLE_SEPARATOR } from 'src/Services/Constants'
import { formatDateTime, getClientGMTOffset } from 'src/Utils/Date'
import { CREDENTIALS, CREDENTIALS_OPTIONS } from 'src/Services/Constants/Config/Person'
import { getFullApiBaseUrl } from 'src/Services/Firewall/utils'
import { StoreState } from 'src/Services/Store/reducers'

// https://www.syncfusion.com/forums/148859/set-html-content
DocumentEditorContainerComponent.Inject(Toolbar)

interface Props {
  showPrintButton: boolean
  showDownloadButton: boolean
  isTemplateCreator: boolean
  contentData: any
  onChange: (documentContent: any) => null
  id: string
}

const SIGNATURE_BOOKMARK = `${TEMPLATE_VARIABLE_SEPARATOR}SIGNATURE${TEMPLATE_VARIABLE_SEPARATOR}`

const DocumentEditor = forwardRef(({
  showPrintButton = false,
  showDownloadButton = false,
  contentData, onChange,
  isTemplateCreator = false,
  id = 'documentEditor'
}: Props, ref) => {
  const container = useRef<DocumentEditorContainerComponent>()

  const { language, username, firstName, lastName, personInstance, signature, locale, portalTimezone }
      = useSelector((state: StoreState) => state.Root.user)
  const appConfig = useSelector((state: StoreState) => state.Root.config)

  const [ isShowInsertSystemField, setIsShowInsertSystemField ] = useState<boolean>(false)
  const [ systemField, setSystemField ] = useState<SystemField>()
  const [ form, setForm ] = useState<FormType>()

  const formatLocaleDateTime = formatDateTime(locale, portalTimezone)
  const trans = translate(translation)(language)

  const onFormsDropdownItemClick = (selected: FormType) => setForm(selected)
  const onSystemFieldDropdownItemClick = (selected: SystemField) => setSystemField(selected)

  const pasteToolItem: CustomToolbarItemModel = {
    prefixIcon: 'fa-regular fa-hashtag',
    tooltipText: trans('customAction.tooltipText') ,
    text: trans('customAction.text') ,
    id: 'CustomPaste',
  }

  const pasteSignaturePlaceholder: CustomToolbarItemModel = {
    prefixIcon: 'fas fa-signature',
    tooltipText: trans('customAction.pasteSignaturePlaceholderTooltipLabel') ,
    text: trans('customAction.pasteSignaturePlaceholderLabel'),
    id: 'PasteSignaturePlaceholder',
  }

  L10n.load(defaultConfig.traductions)

  const templateCreatorToolbarItems: (CustomToolbarItemModel | ToolbarItem)[] = [
    'New', 'Open', 'Separator', 'Undo', 'Redo', pasteToolItem,'Separator', 'Image', 'Table', 'Hyperlink', 'Separator',
    'Header', 'Footer', 'PageSetup', 'PageNumber', 'Break', 'InsertFootnote', 'InsertEndnote', 'Separator', 'Find',
    'Separator', 'LocalClipboard', 'Separator', pasteSignaturePlaceholder,
  ]

  const toolbarItems: (CustomToolbarItemModel | ToolbarItem)[] = [
    'Undo',
    'Redo',
     pasteToolItem,
    'Separator',
    'Image',
    'Table',
    'Separator',
    'Break',
    'Separator',
    'Find',
    pasteToolItem,
  ]
  const config = {
    ...defaultConfig.component,
    height: '600px',
    locale: language || 'en',
    toolbarItems: isTemplateCreator ? templateCreatorToolbarItems : toolbarItems,
    enableToolbar: true,
    serviceUrl: `${getFullApiBaseUrl(appConfig)}${appConfig.SYNCFUSION_WORDPROCESSOR_SERVER_URL}`
  }

  const getEditorInstance = (): DocumentEditorContainer | null => container.current || null

  const onDocumentChange = () => {
    const content = getEditorInstance()?.documentEditor.serialize()

    onChange(content)
  }

  const handleBeforeXmlHttpRequestSend = (args: XmlHttpRequestEventArgs) => {
    args.withCredentials = true
  }

  const onContentChange = () => {
    const content = getEditorInstance().documentEditor.serialize()

    onChange(content)
  }

  useEffect(() => {

    if (!getEditorInstance().documentEditor)
      return

    getEditorInstance().documentEditor.documentName = 'Document title'

    if (contentData)
      setTimeout(() => {

        if (typeof contentData !== 'object') {
          contentData = JSON.parse(contentData)
        }

        getEditorInstance().documentEditor.open(contentData)
        getEditorInstance().documentEditor.focusIn()
      })
  }, [ container ])

  useImperativeHandle(ref, () => ({
    getDocumentAsPdf() {
      return getAsPdf(getEditorInstance().documentEditor)
    },
    signAndGetDocumentAsPdf() {
      return signAndGetDocumentAsPdf()
    }
  }))

  const signAndGetDocumentAsPdf = async () => {
    let currentData = null
    const bookmarks = getEditorInstance().documentEditor.getBookmarks()

    if (bookmarks.includes(SIGNATURE_BOOKMARK)) {
      /** Save data before temporary modifications */
      currentData = getEditorInstance().documentEditor.serialize()

      const selection = getEditorInstance().documentEditor.selection
      getEditorInstance().documentEditor.selection.selectAll()

      selection.selectBookmark(SIGNATURE_BOOKMARK)

      getEditorInstance().documentEditor.editor.deleteTable()
      if (signature) {
            await getEditorInstance().documentEditor.editor.insertImageAsync(signature)
      }
      getEditorInstance().documentEditor.editor.insertText('\v')
      getEditorInstance().documentEditor.editor.insertText(
          trans('signByAndAt')(
              firstName,
              lastName,
              // @ts-ignore
              personInstance.values[CREDENTIALS] ? CREDENTIALS_OPTIONS[personInstance.values[CREDENTIALS]] : null,
              `${formatLocaleDateTime((new Date()).toISOString()) }  (${getClientGMTOffset()})`
          ),
      )



    } else {
      alert(trans('addAPlaceholderToSign'))
      return null
    }

    const pdf = await getAsPdf(getEditorInstance().documentEditor)

    /** Load back data */
    getEditorInstance().documentEditor.open(currentData)

    return pdf
  }

  const insertSignaturePlaceholder = () => {
    const editor = getEditorInstance().documentEditor.editor
    const selection = getEditorInstance().documentEditor.selection

    editor.insertTable(1, 1)

    editor.insertText(SIGNATURE_BOOKMARK)

    selection.paragraphFormat.textAlignment = 'Center'

    selection.paragraphFormat.afterSpacing = 10
    selection.paragraphFormat.beforeSpacing = 10

    editor.insertBookmark(SIGNATURE_BOOKMARK)
  }

  const onToolbarClick = (args: ClickEventArgs) => {
    switch (args?.item?.id) {
      case pasteToolItem.id :
        setIsShowInsertSystemField(true)
        break
      case pasteSignaturePlaceholder.id :
        insertSignaturePlaceholder()
        break
    }
  }

  const onModalClose = () => {
    setIsShowInsertSystemField(false)
  }

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    //avoid submitting parent form
    e.stopPropagation()
    const data = formatDynamicValue(systemField.name.toUpperCase())
    getEditorInstance().documentEditor.editor.insertField(data, data)
    setIsShowInsertSystemField(false)
    setSystemField(null)
  }

  const onPrintClick = () => getEditorInstance().documentEditor.print()
  const onDownloadSourceClick = () => getEditorInstance().documentEditor.save('sample', 'Sfdt')
  const onDownloadPdfClick = () => getAsPdf(getEditorInstance().documentEditor, true)
  const onDownloadWordClick = () => {
    let currentData = null
    const bookmarks = getEditorInstance().documentEditor.getBookmarks()

    if (bookmarks.includes(SIGNATURE_BOOKMARK)) {
      /** Save data before temporary modifications */
      currentData = getEditorInstance().documentEditor.serialize()

      const selection = getEditorInstance().documentEditor.selection
      getEditorInstance().documentEditor.selection.selectAll()

      selection.selectBookmark(SIGNATURE_BOOKMARK)
      getEditorInstance().documentEditor.editor.deleteTable()
    }

    getEditorInstance().documentEditor.save('sample', 'Docx')

    /** Load back data */
    if (currentData)
      getEditorInstance().documentEditor.open(currentData)
  }
  const formatDynamicValue = (systemFieldName: string) =>
      `${TEMPLATE_VARIABLE_SEPARATOR}${systemFieldName}${TEMPLATE_VARIABLE_SEPARATOR}`
  return <>
    <div className="d-flex flex-row-reverse mb-2">
      { showDownloadButton && <Dropdown className="ms-2">
        <Dropdown.Toggle size="sm" variant="outline-primary">
          <i className="me-1 fas fa-download"/>Download
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={ onDownloadSourceClick }>Source</Dropdown.Item>
          <Dropdown.Item onClick={ onDownloadPdfClick }>PDF</Dropdown.Item>
          <Dropdown.Item onClick={ onDownloadWordClick }>Word</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown> }
      { showPrintButton && <Button size={ 'sm' } variant={ 'outline-primary' } onClick={ onPrintClick }>
        <i className="me-1 fas fa-print"/> Print
      </Button> }
    </div>
    <DocumentEditorContainerComponent
      id={ `${ id }Container` }
      ref={ container }
      contentChange={ onContentChange }
      documentChange={ onDocumentChange }
      beforeXmlHttpRequestSend={ handleBeforeXmlHttpRequestSend }
      toolbarClick={ onToolbarClick }
      { ...config }
    />
    <Modal isOpen={ isShowInsertSystemField } onClosed={ onModalClose } centered={ true }>
        <ModalHeader toggle={ onModalClose }>
          { trans('modal.systemField.title') }
        </ModalHeader>
        <ModalBody>
            <Row>
              <Col>
                <Form onSubmit={onSubmit}>
                  <Form.Group>
                    <Form.Label>{ trans('form') }</Form.Label>
                    <EntitySelect fetchUrl={ '/forms' } name={ 'form' } labelKey={ 'label' }
                                  onChange={ onFormsDropdownItemClick }  required id={ 'formSelect' }
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{ trans('modal.systemField.label') }</Form.Label>

                    <SystemFieldSelect formId={form?.id} name={'field'}
                                       onChange={onSystemFieldDropdownItemClick}
                                       placeholder={ trans('modal.systemField.placeholder') }
                                       required
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Button type="submit" style={ { float: 'right' } }>
                      { trans('modal.systemField.button') }
                    </Button>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
        </ModalBody>
    </Modal>
  </>
})
DocumentEditor.displayName = 'DocumentEditor'

export default DocumentEditor
