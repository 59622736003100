import React from 'react'
import { useSelector } from 'react-redux'
import { translateConf } from '../../../../Services/translation/index'
import {
  COLOR_DARK,
  FONT_SIZES,
  SIZE_MD
} from '../../../../Services/Constants'
import { FieldOption } from 'src/Types/FieldOption'

const SentenceField = ({ field }) => {

  const { configTranslations } = useSelector(state => state.Root)
  const transConf = translateConf(configTranslations)

  const getClass = () =>
    `${ field.options[FieldOption.CSS_CLASS] || '' } text-${ field.options[FieldOption.FONT_COLOR] || COLOR_DARK }`

  return <div className={ getClass() }>
    <span style={ { fontSize: FONT_SIZES[field.options[FieldOption.FONT_SIZE]] || SIZE_MD } }>
      { transConf('FIELD')(field) }
    </span>
  </div>
}

export default SentenceField
