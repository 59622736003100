import React from 'react'
import { translate } from '../../Services/translation'
import translation from './translations'
import { useDispatch, useSelector } from 'react-redux'
import { confirmModalOk, toggleConfirmModal } from '../../Services/Store/GlobalControls/actions'
import ConfirmModal from '../ConfirmModal'

/**
 * ConfirmModal but managed by State and usable by every component
 */
const SharedConfirmModal = () => {
  const dispatch = useDispatch()

  const { language } = useSelector(state => state?.Root?.user)
  const { isConfirmModalOpen } = useSelector(state => state?.GlobalControls)

  const trans = translate(translation)(language)

  const onConfirm = () => dispatch(confirmModalOk())
  const onCancel = () => dispatch(toggleConfirmModal())


  return (
    <ConfirmModal isOpen={isConfirmModalOpen}
                  onConfirm={onConfirm}
                  onClose={onCancel}
                  title={trans('areYouSure')}
    />
  )
}

export default SharedConfirmModal
