export default {
  english: {
    refForm: 'Form',
    addColumn: 'Add column',
    formField: 'Form field',
    submitColumn: 'Submit column'
  },
  french: {
    refForm: 'Formulaire',
    addColumn: 'Ajouter une colonne',
    formField: 'Formulaire de champ',
    submitColumn: 'Submit column'
  }
}
