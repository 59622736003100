import { useDispatch, useSelector } from 'react-redux'
import React, { ChangeEvent, useEffect } from 'react'
import { Card, Form } from 'react-bootstrap'
import { fetchLists, fetchLogAsRoles, fetchNumericReports, setNewSubjectData } from '../state/actions'
import { PermissionSubject } from 'src/Views/Permissions/Types/Subject'
import Permission from 'src/Types/Permission'
import GenericEntity from 'src/Types/GenericEntity'
import { StoreState } from 'src/Services/Store/reducers'

const PermissionsEntityContent = () => {
  const dispatch = useDispatch()

  const { newSubjectData, lists, numericReports, subject, logAsRoles, user, role } =
      useSelector((state: StoreState) => state.Permissions)

  const getSubject = () =>
      (subject as PermissionSubject.LIST | PermissionSubject.NUMERIC_REPORT | PermissionSubject.LOG_AS_ROLE)

  const getNewSubjectData = () => (newSubjectData as Permission[])

  const getEntities = (): GenericEntity[] => {
    if (subject === PermissionSubject.LIST)
      return lists
    else if (subject === PermissionSubject.NUMERIC_REPORT)
      return numericReports
    else if (subject === PermissionSubject.LOG_AS_ROLE)
      return logAsRoles
    else return []
  }

  useEffect(() => {
    if (subject === PermissionSubject.LIST && !lists.length)
      dispatch(fetchLists())
    else if (subject === PermissionSubject.NUMERIC_REPORT && !numericReports.length)
      dispatch(fetchNumericReports())
    else if (subject === PermissionSubject.LOG_AS_ROLE && !logAsRoles.length)
      dispatch(fetchLogAsRoles())
  }, [ dispatch ])

  const getSubjectData = (): Permission[] => {
    const entities = getEntities()
    const entityArray = Array.isArray(entities) ? entities : []


    return [
      ...getNewSubjectData(),
      ...entityArray
          ?.filter(entity => !getNewSubjectData().some(sd => sd[getSubject()]?.id === entity.id))
          ?.map((entity): Permission => ({ id: `_${entity.id}`, [getSubject()]: entity, accessLevel: null })),
    ]?.sort((a, b) => a[getSubject()]?.systemName?.localeCompare(b[getSubject()]?.systemName))
  }
  const authorizeEntity = (entity: Permission) => (e: ChangeEvent<HTMLInputElement>) => {
    const accessLevel = e.target.checked ? 1 : null
    const subjectData = !getNewSubjectData().some(_ => _.id === entity.id)
                        ? [ ...getNewSubjectData(), { ...entity, accessLevel } ]
                        : getNewSubjectData().map(_ => _.id === entity.id ? { ..._, accessLevel } : _)

    dispatch(setNewSubjectData(subjectData))
  }

  const isItemDisabled = (subjectData: Permission): boolean =>
      Boolean(user) || (
          subject === PermissionSubject.LOG_AS_ROLE && role.systemName === subjectData[getSubject()].systemName
      )

  return <Card className={'mt-3'}>
    <Card.Body>
      <Form>
        {getSubjectData().map(data =>
          <Form.Group key={data.id}>
            <Form.Check type="checkbox"
                        label={data[getSubject()]?.label}
                        title={(data[getSubject()])?.label}
                        checked={data?.accessLevel === 1}
                        onChange={authorizeEntity(data)}
                        disabled={ isItemDisabled(data) }
            />
          </Form.Group>)}
      </Form>
    </Card.Body>
  </Card>
}

export default PermissionsEntityContent
