import { CLOSE_EVENT_SOURCE, EVENT_SOURCE_MESSAGE, SUBSCRIBE_EVENT_SOURCE } from './actionTypes'

export const subscribeEventSource = (topic) => ({
  type: SUBSCRIBE_EVENT_SOURCE,
  payload: { topic }
})
export const closeEventSource = (topic) => ({
  type: CLOSE_EVENT_SOURCE,
  payload: { topic }
})

export const eventSourceMessage = (topic, message = null) => ({
  type: EVENT_SOURCE_MESSAGE,
  payload: { topic, message }
})
