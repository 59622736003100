export const CREATE_WORKFLOW = '[WORKFLOW_MANAGER] CREATE_ITEM'
export const DELETE_WORKFLOW = '[WORKFLOW_MANAGER] DELETE_ITEM'
export const UPDATE_WORKFLOW = '[WORKFLOW_MANAGER] UPDATE_ITEM'

export const WORKFLOW_RECEIVED = '[WORKFLOW_MANAGER] ITEM_RECEIVED'
export const WORKFLOW_CREATED = '[WORKFLOW_MANAGER] ITEM_CREATED'
export const WORKFLOW_DELETED = '[WORKFLOW_MANAGER] ITEM_DELETED'
export const WORKFLOW_UPDATED = '[WORKFLOW_MANAGER] ITEM_UPDATED'

export const SET_DELETING_WORKFLOW = '[WORKFLOW_MANAGER] SET_DELETING_ITEM'
export const SET_UPDATING_WORKFLOW = '[WORKFLOW_MANAGER] SET_UPDATING_ITEM'
export const TOGGLE_CREATE_WORKFLOW_MODAL = '[WORKFLOW_MANAGER] TOGGLE_CREATE_WORKFLOW_MODAL'
