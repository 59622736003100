export const LOAD_APPOINTMENT_DICTIONARY = 'LOAD_APPOINTMENT_DICTIONARY'
export const DICTIONARY_SLOT_TYPES_RECEIVED = 'DICTIONARY_SLOT_TYPES_RECEIVED'
export const DICTIONARY_PROVIDERS_RECEIVED = 'DICTIONARY_PROVIDERS_RECEIVED'
export const SEARCH_EVENTS = 'SEARCH_EVENTS'
export const EVENTS_RECEIVED = 'EVENTS_RECEIVED'
export const SEARCH_SLOTS = 'SEARCH_SLOTS'
export const SLOTS_RECEIVED = 'SLOTS_RECEIVED'
export const APPOINTMENT_CHANGE_TYPE = 'APPOINTMENT_CHANGE_TYPE'
export const APPOINTMENT_CHANGE_PROVIDER = 'APPOINTMENT_CHANGE_PROVIDER'
export const TOGGLE_LOCATIONS_SELECT = 'TOGGLE_LOCATIONS_SELECT'
export const APPOINTMENT_ADD_LOCATION = 'APPOINTMENT_ADD_LOCATION'
export const APPOINTMENT_DELETE_LOCATION = 'APPOINTMENT_DELETE_LOCATION'
export const APPOINTMENT_CHANGE_DAYS = 'APPOINTMENT_CHANGE_DAYS'
export const APPOINTMENT_CHANGE_DATE_BEFORE = 'APPOINTMENT_CHANGE_DATE_BEFORE'
export const APPOINTMENT_CHANGE_TIME_BEFORE = 'APPOINTMENT_CHANGE_TIME_BEFORE'
export const APPOINTMENT_CHANGE_DATE_AFTER = 'APPOINTMENT_CHANGE_DATE_AFTER'
export const APPOINTMENT_CHANGE_TIME_AFTER = 'APPOINTMENT_CHANGE_TIME_AFTER'
export const APPOINTMENT_SET_SLOTS = 'APPOINTMENT_SET_SLOTS'
export const APPOINTMENT_SELECT_SLOT = 'APPOINTMENT_SELECT_SLOT'
