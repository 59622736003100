export default {
  english: {
    title: 'Audit trails',
    column: {
      code: 'Code',
      ip: 'Ip',
      timestamp: 'Timestamp',
      context: 'Context',
      contextIdentifier: 'Context identifier',
      workflowId: 'Workflow id',
      service: 'Service (Message)',
      configId: 'Config id (Message)',
      data: 'Data',
      user: 'User',
      impersonator: 'Impersonator',
    }
  },
  french: {
    title: 'Audit trails',
    column: {
      code: 'Code',
      ip: 'Ip',
      timestamp: 'Timestamp',
      context: 'Context',
      contextIdentifier: 'Context identifier',
      workflowId: 'Workflow id',
      service: 'Service (Message)',
      configId: 'Config id (Message)',
      data: 'Data',
      user: 'User',
      impersonator: 'Impersonator',
    }
  }
}
