import React, { MutableRefObject } from 'react'
import { Card, Dropdown, Tab, Tabs } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import LegendSidebar from 'src/Components/Calendar/Components/Sidebar/Tabs/Legend'
import { useSelector } from 'src/Services/Store'
import { translate } from 'src/Services/translation'
import { EventsTab } from 'src/Components/Calendar/Components/Sidebar/Tabs/Events'
import CalendarOption from 'src/Components/Calendar/Components/Sidebar/Tabs/Options'
import { fetchResources, setActiveTab, setSlotFormModal } from 'src/Components/Calendar/state/actions'
import translation from 'src/Components/Calendar/translations'
import { Options, SidebarTab } from 'src/Components/Calendar/Types/Calendar'
import { StoreState } from 'src/Services/Store/reducers'
import CalendarEvent from 'src/Components/Calendar/Types/CalendarEvent'
import { SlotType } from 'src/Types/Slot'

interface Props {
  activeTabs: SidebarTab[]
  events: CalendarEvent[]
  calendarOptions: Options
  setCalendarOptions: (options: Options) => void
  mainCalendarRef: MutableRefObject<any>
}

const Sidebar = (({
  activeTabs = [],
  calendarOptions,
  setCalendarOptions,
  events,
  mainCalendarRef
}: Props) => {
  const dispatch = useDispatch()

  const { language } = useSelector((state: StoreState) => state.Root.user)
  const { activeTab } = useSelector((state: StoreState) => state.Calendar)
  const slotTypes = useSelector((state: StoreState) => state.Dictionary.slotTypes)

  const trans = translate(translation)(language)

  const handleTabChange = (tab: SidebarTab) => dispatch(setActiveTab(tab))

  const onSlotTypeSelect = (slotType: SlotType) => {
    if (slotType?.eventType?.id)
      dispatch(fetchResources('slot', slotType.eventType.id))
    dispatch(setSlotFormModal(true, slotType))
  }

  const tabs = {
    [SidebarTab.EVENTS]:
        <EventsTab events={ events }
                   mainCalendarRef={ mainCalendarRef }
                   calendarOptions={ calendarOptions }
                   activeTab= {activeTab}
        />,

    [SidebarTab.SLOTS]: <Dropdown>
      <Dropdown.Toggle variant="outline-primary">Create</Dropdown.Toggle>
      <Dropdown.Menu>
        { slotTypes.map(_ =>
            <Dropdown.Item key={ _.id } onClick={ () => onSlotTypeSelect(_) }>{ _.label }</Dropdown.Item>) }
      </Dropdown.Menu>
    </Dropdown>,

    [SidebarTab.OPTIONS]:
        <CalendarOption calendarOptions={ calendarOptions } setCalendarOptions={ setCalendarOptions }/>,

    [SidebarTab.LEGEND]: <LegendSidebar/>
  }

  return <Card className="calendar-sidebar me-3"><Card.Body className="p-0">
    <Tabs activeKey={ activeTab } onSelect={ handleTabChange }>
      { activeTabs.map(tab =>
          <Tab key={ tab }
               eventKey={ tab }
               title={ trans(`sidebar.tabs.${ tab }.title`) }
               tabClassName="calendar-sidebar-tab"
          >
            <div className="p-2">
              { tabs[tab] }
            </div>
          </Tab>
      ) }
    </Tabs>
  </Card.Body>
  </Card>
})

export default Sidebar
