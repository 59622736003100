
export default {
  english: {
    title: 'Navigation items manager',
    addNew: 'Add a navigation item',
    entries: 'of {total} entries',
    isActive: 'Show in navbar',
    form: {
      label: 'Label',
      systemName: 'System name',
      systemNameInfo: 'Use only caps and underscore for spacing.',
      internalLink: 'Internal link',
      externalLink: 'External link',
      order: 'Order',
      parent: 'Parent',
      parentDefaultValue: 'None',
      parentInfo: 'Use the navigation item as a subnavigation item',
      isActive: 'Show in navbar',
    },
    modal: {
      create: {
        title: 'Create a new navigation item'
      },
      update: {
        title: 'Update a navigation item'
      },
      confirmDelete: {
        title: 'Are you sure you want to delete this navigation item ?'
      }
    }
  },
  french: {
    title: 'Gestionnaire d\'élément de navigation',
    addNew: 'Créer un élément de navigation',
    entries: 'sur {total} entrées',
    isActive: 'Afficher dans la barre de navigation',
    form: {
      label: 'Label',
      systemName: 'Nom système',
      systemNameInfo: 'Utilisez seulement majuscules et tiret bas pour les espaces.',
      internalLink: 'Lien interne',
      externalLink: 'Lien externe',
      order: 'Ordre',
      parent: 'Parent',
      parentDefaultValue: 'Aucun',
      parentInfo: 'Utiliser l\'élément de navigation en tant qu\'élément de sous navigation',
      isActive: 'Afficher dans la barre de navigation',
    },
    modal: {
      create: {
        title: 'Créer un nouveau élément de navigation'
      },
      update: {
        title: 'Modifier un élément de navigation'
      },
      confirmDelete: {
        title: 'Êtes-vous sûr de vouloir supprimer cet élément de navigation ?'
      }
    }
  }
}
