import React from 'react'
import ValidationTrigger from 'src/Components/ValidationTrigger/index'
import fieldGetter from 'src/Views/FormFiller/utils/getField.ts'
import ReferenceFieldActions from './ReferenceFieldActions'
import FormFillerColumn from '../../Column'

const ReferenceField = ({ field, isDisabled, isRequired = false }) => {
  const { getField } = fieldGetter()

  const { id, value, referenceFields } = field

  const isValid = () => !isRequired || Boolean(value)

  return <div id={ `field${ id }` } className={ `blue-card ${ isValid() ? '' : 'is-invalid' }` }>
    { referenceFields?.map(referencedField =>
      <div key={ `${ id }.${ referencedField }` } className={ 'mb-1' }>
        <FormFillerColumn cell={ getField(referencedField) }
                          forceDisable={ (isDisabled || !!value) }
                          fieldParent={ getField(id) }
                          fieldParentDisabled={ isDisabled }
        />
      </div>
    ) }

    <ReferenceFieldActions field={ field } disabled={ isDisabled } />
    <ValidationTrigger isRequired={ isRequired } isValid={ isValid() } />
  </div>
}

export default ReferenceField
