import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import DocumentEditor from '../../Components/DocumentEditor/index.tsx'

const PdfEdit = () => {
  const dispatch = useDispatch()

  return <>
    <DocumentEditor/>
  </>
}

export default PdfEdit
