import React  from 'react'
import { Button } from 'react-bootstrap'
import RowContent from './RowContent'
import { useDispatch, useSelector } from 'react-redux'
import { addRow } from '../state/actions'

const LandingPagePageContent = ({ trans }) => {
  const dispatch = useDispatch()

  const { rows } = useSelector(state => state.LandingPages.form)

  const onAddRowButtonClick = () => dispatch(addRow({ contents: [] }))
  const isLastRowHasContent = () => !!rows.length && !!rows[rows.length-1].contents.length
  const shouldDisplayAddRowButton = () => !rows.length || isLastRowHasContent()

  return <>
    { rows.map((row, i) => <RowContent key={i} trans={trans} row={row} />) }
    { shouldDisplayAddRowButton() &&
      <Button onClick={ onAddRowButtonClick } size="sm">
        { trans('form.editor.addRowButton') }
      </Button> }
  </>
}

export default LandingPagePageContent
