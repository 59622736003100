import { useDispatch, useSelector } from 'react-redux'
import { translate } from 'src/Services/translation'
import translation from '../translations'
import React, { useEffect } from 'react'
import { Card, Form } from 'react-bootstrap'
import { fetchLandingPages, setNewSubjectData } from '../state/actions.ts'

const PermissionsLandingPageContent = () => {
  const dispatch = useDispatch()
  const { language } = useSelector(state => state.Root?.user)
  const { newSubjectData, landingPages, subject, user } = useSelector(state => state.Permissions)

  const trans = translate(translation)(language)

  useEffect(() => {
    if (!landingPages.length)
      dispatch(fetchLandingPages())
  }, [ dispatch ])

  const getSubjectData = () =>
    [
      ...newSubjectData,
      ...landingPages
        .filter(entity => !newSubjectData.some(sd => sd[subject]?.id === entity.id))
        .map(entity => ({ id: `_${ entity.id }`, [subject]: entity, accessLevel: null }))
    ].sort((a,b) => a[subject]?.systemName?.localeCompare(b[subject]?.systemName))

  const authorizeEntity = entity => e => {
    const accessLevel = e.target.checked ? 1 : null
    const subjectData = !newSubjectData.some(_ => _.id === entity.id)
      ? [ ...newSubjectData, { ...entity, accessLevel } ]
      : newSubjectData.map(_ => _.id === entity.id ? { ..._, accessLevel } : _)

    dispatch(setNewSubjectData(subjectData))
  }

  return <Card className={ 'mt-3' }>
    <Card.Body>
      <Form>
        { getSubjectData().map(data =>
          <Form.Group key={data.id}>
            <Form.Check type="checkbox"
                        label={ data[subject]?.label }
                        checked={ data.accessLevel === 1 }
                        onChange={ authorizeEntity(data) }
                        disabled={Boolean(user)}
            />
          </Form.Group>) }
      </Form>
    </Card.Body>
  </Card>
}

export default PermissionsLandingPageContent
