import { FIELD_BASE_TYPE_EXTENSIBLE, FIELD_BASE_TYPE_REFERENCE, ID_SEPARATOR } from 'src/Services/Constants'
import { FieldOption } from 'src/Types/FieldOption'
import { BaseFieldType, ExtensibleRow, Field } from 'src/Types/Field'
import FormFillerField from 'src/Views/FormFiller/Types/FormFillerField'
import Row from 'src/Types/Row'
import Section from 'src/Types/Section'
import OpenedInstance from 'src/Views/FormFiller/Types/OpenedInstance'
import { Instance } from 'src/Types/Instance'

export const sortFieldsByOrder = (fields: Field[]) => {
  return fields.map((field, index) => {
    field.options[FieldOption.SORT_ORDER] = index + 1
    return field
  })
}

// Create children fields with their own values
const parseExtensibleChildren = (extensible: FormFillerField) => {

  if (!(extensible.value as ExtensibleRow[])?.length || !extensible.fields?.length)
    return extensible.fields

  const findChild = (systemName: string) => (extensible.fields as Field[]).find(f => f.systemName === systemName)

  const filledChildren = (extensible.value as ExtensibleRow[])
      .map(extensibleRow =>
         Object.keys(extensibleRow.values)
          .filter(key => key.includes('#') && (!extensible.fields?.length || findChild(key)))
          .map(key => {
            if (extensible.fields?.length) {
              const child = findChild(key)

              return {
                ...child,
                id: child.id + ID_SEPARATOR + extensibleRow.id,
                value: extensibleRow.values[key],
              }
            }
          }))
      .reduce((acc, val) => acc.concat(val), [])

  const emptyChildren = (extensible.fields as Field[]).map(field => ({
    ...field,
    value: null,
  }))

  return [ ...filledChildren, ...emptyChildren ]
}

const flattenAndParseExtensibles = (fields: FormFillerField[]): FormFillerField[] =>
    fields.reduce((results, field) => {
      if (field.type?.baseFieldType !== FIELD_BASE_TYPE_EXTENSIBLE)
        return [ ...results, field ]

      const parsedExtensible: FormFillerField = {
        ...field,
        fields: (field.fields as Field[]).map(f => f.id),
        // Real value is split across all fields
        value: null,
      }

      return [ ...results, parsedExtensible, ...parseExtensibleChildren(field) ]
    }, [])

const flattenAndParseReferences = (fields: FormFillerField[]): FormFillerField[] =>
    fields.reduce((results, field) => {

      if (field.type?.baseFieldType !== FIELD_BASE_TYPE_REFERENCE || !field.referenceFields)
        return [ ...results, field ]

      const parsedReference = {
        ...field,
        referenceFields: field.referenceFields.map(f => f.listColumn.id),
      }

      return [ ...results, parsedReference, ...parseReferencedFields(field) ]
    }, [])

const parseReferencedFields = (field: FormFillerField): Field[] =>
    field.referenceFields.map(f => ({
      ...f,
      // Field id is not unique, so we use associated listColumn id here
      id: f.listColumn.id,
      referenceField: field.id,
      value: getReferenceChildFieldValue(field, f)
    }))

const getReferenceChildFieldValue = (referenceField: FormFillerField, childField: Field) => {
  if (!(referenceField.value as Instance)?.values)
    return null

  return (referenceField.value as Instance).values[childField.listColumn.systemName]
}

export const getAllFields = (sections: Section[], instance: Instance = null): FormFillerField[] => {
  const rows: Row[] = sections.reduce((rows, section) => [ ...rows, ...section.rows ], [])

  if (instance)
    return rows.reduce((fields, row) =>
                         [ ...fields, ...row.fields.map(f => ({ ...f, value: instance.values[f.systemName] })) ],
                     [])
  else
    return rows.reduce((fields, row) => [ ...fields, ...row.fields ], [])
}

export const parseFields = (instance: Instance, sections: Section[]) => {

  let fields = getAllFields(sections, instance)

  fields = flattenAndParseExtensibles(fields)
  fields = flattenAndParseReferences(fields)

  return fields.reduce((acc, field) => ({ ...acc, [field.id]: field }), {})
}


export const getSpecificFields = (fields: OpenedInstance['fields'], fieldType: BaseFieldType) => {
  const specificFields = []
  for (const [ key, field ] of Object.entries(fields)) {
    if (field?.type?.baseFieldType === fieldType) {
      specificFields.push(field)
    }
  }
  return specificFields
}
