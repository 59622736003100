import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Collapse } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import { toMoment } from '@fullcalendar/moment'
import { DatePointApi, DatesSetArg, EventClickArg, EventContentArg, EventDropArg } from '@fullcalendar/core'
import { EventImpl } from '@fullcalendar/core/internal'
import interactionPlugin, { Draggable, EventReceiveArg } from '@fullcalendar/interaction'
import momentPlugin from '@fullcalendar/moment'
import bootstrap5Plugin from '@fullcalendar/bootstrap5'
import Sidebar from 'src/Components/Calendar/Components/Sidebar'
import EventComponent from 'src/Components/Calendar/Components/EventComponent'
import SlotSerieFormModal from 'src/Components/Calendar/Components/SlotSerieFormModal'
import SlotForm from 'src/Components/Calendar/Components/SlotForm'
import translation from 'src/Components/Calendar/translations'
import { translate } from 'src/Services/translation'
import EventForm from 'src/Components/Calendar/Components/EventForm'
import Modal from 'src/Components/Modal'
import ConfirmModal from 'src/Components/ConfirmModal'
import EventTypeForm from 'src/Components/Calendar/Components/EventTypeForm'
import { useCalendarOptions } from 'src/Components/Calendar/state/hooks'
import { formatDateAsUtcToIso8601 } from 'src/Utils/Date'
import {
  setCreatingEvent, setEventResources, setEditingEvent, setEditingSlot, deleteSlot, setDeletingSlot,
  setCurrentEndDate, setCurrentStartDate, getEvents, deleteEvent, setDeletingEvent, setSlotResources, editEvent,
} from 'src/Components/Calendar/state/actions'
import { SidebarTab, View } from 'src/Components/Calendar/Types/Calendar'
import { StoreState } from 'src/Services/Store/reducers'
import CalendarEvent, { CalendarEventExtended, CalendarEventType } from 'src/Components/Calendar/Types/CalendarEvent'
import { EventForApi, EventStatus } from 'src/Types/Event'
import { getUUID } from 'rc-select/es/utils/commonUtil'
import { Event } from 'src/Types/Event'
import { HexColor } from 'src/Types/Color'
import { UuidV4 } from 'src/Types/Uuid'
import { Iso8601 } from 'src/Types/Date'
import { calendarEventToEvent, calendarEventToSlot, fullCalendarEventToCalendarEvent, getEventsOnRange } from './Utils'

export const DEFAULT_START_AT_TIME = '12:00'

const defaultCalendarOptions = {
  startWeek: 1,
  startDay: '07:00',
  endDay: '22:00',
  weekends: true,
  initialView: View.MONTH,
}

export const DEFAULT_EVENT_STATUS: EventStatus = {
  id: (getUUID() as UuidV4),
  label: 'none',
  systemName: 'NONE',
  color: ('#0D6189' as HexColor),
}

interface Props {
  tabs: SidebarTab[]
}

const Calendar = ({ tabs = [ SidebarTab.EVENTS, SidebarTab.SLOTS, SidebarTab.OPTIONS, SidebarTab.LEGEND ] }: Props) => {
  const dispatch = useDispatch()
  const { id: patientId } = useParams()
  const navigate = useNavigate()

  const { language, locale, portalTimezone } = useSelector((state: StoreState) => state.Root.user)
  const { eventTypes } = useSelector((state: StoreState) => state.Dictionary)
  const { isSidebarOpen, events, creatingEvent, editingEvent, editingSlot, deletingSlot, deletingEvent }
      = useSelector((state: StoreState) => state.Calendar)

  const trans = translate(translation)(language)

  const calendarRef = useRef()

  const [ calendarOptions, setCalendarOptions ] = useCalendarOptions(defaultCalendarOptions)

  const handleEventDrag = (event: CalendarEvent) => {
    const cells = document.getElementsByClassName('fc-day')
    if (event.extendedProps.eventType.isSlotRequired) {
      // @ts-ignore
      for (const cell of cells) {
        cell.classList.add('bg-secondary')
        cell.classList.add('bg-opacity-25')
      }
    }
  }

  const resetCreateEventForm = () => {
    dispatch(setCreatingEvent(null))
    dispatch(setEventResources([]))
  }

  const resetEditEventForm = () => {
    dispatch(setEditingEvent(null))
    dispatch(setEventResources([]))
  }

  const resetEditSlotForm = () => {
    dispatch(setEditingSlot(null))
    dispatch(setSlotResources([]))
  }

  useEffect(() => {
    // load patient sidebar external events https://fullcalendar.io/docs/external-dragging
    const patientSidebarFormList = document.getElementById('patientSidebarFormList')
    if (patientSidebarFormList)
      new Draggable(patientSidebarFormList, {
        itemSelector: '.patient-sidebar-form-item',
        eventData: eventEl => {
          const { id, patientId, locationId, eventTypeId } = eventEl.dataset

          const eventType = eventTypes.find(et => et.id === eventTypeId)

          const dayDateTime = dayjs(eventType.defaultStartTime)
          const convertedDateTime = dayDateTime.tz(portalTimezone)

          const event: CalendarEvent = {
            id: null,
            title: trans('newEvent'),
            startTime: {
              hours: convertedDateTime.get('hours'),
              minutes: convertedDateTime.get('minutes'),
            },
            duration: { minutes: eventType.defaultDuration },
            allDay: false,
            start: null,
            end: null,
            extendedProps: {
              id: null,
              type: CalendarEventType.EVENT,
              start: null,
              end: null,
              duration: eventType.defaultDuration,
              note: null,
              eventType,
              status: DEFAULT_EVENT_STATUS,
              instance: {
                id: (id as UuidV4), displayId: null, identityString: null,
              },
              location: locationId ? {
                id: (locationId as UuidV4), displayId: null, identityString: null,
              } : null,
              patient: {
                id: (patientId as UuidV4), displayId: null, identityString: null,
              },
              icons: [],
              additionalData: null,
              color: DEFAULT_EVENT_STATUS.color,
            },
          }

          handleEventDrag(event)

          return event
        },
      })
  }, [])

  useEffect(() => () => {
    resetEditEventForm()
    resetEditSlotForm()
  }, [])

  // @ts-ignore
  const handleEventTypeFormSubmit = () => undefined
  const handleEventTypeFormClose = () => dispatch(setCreatingEvent(null))
  const handleEditEventSubmit = () => dispatch(setEventResources([]))
  const handleEditSlotSubmit = () => dispatch(setEditingSlot(null))
  const handleEditSlotClose = () => dispatch(setEditingSlot(null))
  const handleConfirmDeleteSlotModalClose = () => dispatch(setDeletingSlot(null))
  const handleConfirmDeleteSlotModalConfirm = () => dispatch(deleteSlot())

  const handleConfirmDeleteEventModalClose = () => dispatch(setDeletingEvent(null))
  const handleConfirmDeleteEventModalConfirm = () => dispatch(deleteEvent())

  const handleDatesChange = (e: DatesSetArg) => {
    dispatch(setCurrentStartDate(formatDateAsUtcToIso8601(e.start, portalTimezone)))
    dispatch(setCurrentEndDate(formatDateAsUtcToIso8601(e.end, portalTimezone)))
    dispatch(getEvents(
                 formatDateAsUtcToIso8601(e.start, portalTimezone),
                 formatDateAsUtcToIso8601(e.end, portalTimezone),
             ),
    )
  }

  const handleEventClick = ({ event }: EventClickArg) => {
    const newEvent = (event.extendedProps as CalendarEventExtended)

    if (newEvent.type === CalendarEventType.EVENT)
      navigate(`/patient/${ newEvent.patient.id }/instance/${ newEvent.instance.id }`)
    else if (newEvent.type === CalendarEventType.SLOT && newEvent.event) {
      dispatch(setEditingEvent(calendarEventToEvent(newEvent.event)))
    }
    else if (newEvent.type === CalendarEventType.SLOT)
    {
    dispatch(setEditingSlot(calendarEventToSlot(newEvent)))

    }
  }

  const onEditClick = (event: CalendarEvent) => {
    dispatch(setEditingEvent(calendarEventToEvent(event)))
  }

  const isSlotAvailable = (slot: CalendarEventExtended, event: CalendarEventExtended) =>
      event.eventType.id === slot.slotType.eventType?.id
      && !slot.event && slot.isAvailability

  const handleEventOverlap = (stillEvent: EventImpl, movingEvent: EventImpl | null) => {
    const event = (stillEvent.extendedProps as CalendarEventExtended)
    const newEvent = (movingEvent.extendedProps as CalendarEventExtended)
    return event.type === CalendarEventType.SLOT && isSlotAvailable(event, newEvent)
  }

  const handleEventReceive = ({ event, revert, view }: EventReceiveArg) => {
    const newEvent = (event.extendedProps as CalendarEventExtended)

    if (newEvent.eventType?.isSlotRequired) {
      revert()
      alert(`Events of type ${ newEvent.eventType.label || newEvent.eventType.systemName } are not allowed to be
      created outside slots`)
    }

    const fullCalendarEventStartAt: Iso8601 = (toMoment(event.start, view.calendar).format() as Iso8601)
    const fullCalendarEventEndAt: Iso8601 = (toMoment(event.start, view.calendar).format() as Iso8601)

    let creatingEvent: Event = {
      type: newEvent.eventType,
      instance: newEvent.instance,
      location: newEvent.location,
      start: null,
      duration: null,
    }

    const slot: CalendarEvent =
        getEventsOnRange(fullCalendarEventStartAt, fullCalendarEventEndAt, events)
            .find(e =>
                      e.extendedProps.type === CalendarEventType.SLOT && isSlotAvailable(e.extendedProps, newEvent))

    if (slot)
      creatingEvent = {
        ...creatingEvent,
        start: slot.extendedProps.start,
        duration: slot.extendedProps.slotType.duration,
        location: slot.extendedProps.location,
        resource: slot.extendedProps.resource,
        slot: {
          id: slot.extendedProps.id,
          start: slot.extendedProps.start,
          end: slot.extendedProps.end,
          type: slot.extendedProps.slotType,
          isAvailability: slot.extendedProps.isAvailability,
          location: slot.extendedProps.location,
          event: calendarEventToEvent(slot.extendedProps.event),
          resource: slot.extendedProps.resource,
        },
      }
    else {
      creatingEvent = {
        ...creatingEvent,
        start: fullCalendarEventStartAt,
        duration: newEvent.eventType?.defaultDuration,
      }
    }

    dispatch(setCreatingEvent(creatingEvent))

    revert()
  }

  /** Calendar events render */
  const eventRenderer = ({ event }: EventContentArg) => {
    const calendarEvent = fullCalendarEventToCalendarEvent(event, portalTimezone)
    return <EventComponent key={`mainCalendar${ calendarEvent.extendedProps.type }${ calendarEvent.id }`}
                           event={calendarEvent}
                           locale={locale}
                           portalTimezone={portalTimezone}
                           onEditClick={onEditClick}
    />
  }

  const handleDateClick = ({ allDay, date, dateStr }: DatePointApi) => {
    // @ts-ignore
    const startAt = toMoment(date, calendarRef.current.calendar).format() as Iso8601

    // TODO : handle all day
    // const startAt = allDay ? moment(`${dateStr} ${DEFAULT_START_AT_TIME}`).toDate() : date

    dispatch(setCreatingEvent({ start: startAt, duration: null, type: null }))
  }

  const updateEventOnDrop = (eventDropInfo: EventDropArg) => {
    const { id: eventId,start, extendedProps: { duration }  } =  eventDropInfo.event
    const date = formatDateAsUtcToIso8601(start)
    const eventData: EventForApi = { date, duration }

    dispatch(editEvent(eventData, (eventId as UuidV4 | null), (patientId as UuidV4 | null)))
  }

  return <div className="d-flex">
    <Collapse in={isSidebarOpen} dimension="width">
      <div>
        <Sidebar events={events}
                 calendarOptions={calendarOptions}
                 setCalendarOptions={setCalendarOptions}
                 activeTabs={tabs}
                 mainCalendarRef={calendarRef}
        />
      </div>
    </Collapse>
    <div className="w-100">
      <FullCalendar
          timeZone={portalTimezone}
          ref={calendarRef}
          initialView={defaultCalendarOptions.initialView}
          dateClick={handleDateClick}
          eventContent={eventRenderer}
          fixedWeekCount={false}
          navLinks={true}
          datesSet={handleDatesChange}
          eventReceive={handleEventReceive}
          eventOverlap={handleEventOverlap}
          events={events}
          droppable={true}
          selectable={false}
          editable={true}
          eventDrop={updateEventOnDrop}
          moreLinkClick={'popover'}
          weekends={calendarOptions.weekends}
          firstDay={calendarOptions.startWeek}
          slotMinTime={calendarOptions.startDay}
          slotMaxTime={calendarOptions.endDay}
          themeSystem="bootstrap5"
          dayMaxEventRows={true}
          dayMaxEvents={true}
          eventMaxStack={2}
          eventClick={handleEventClick}
          views={{
            timeGrid: {
              dayMaxEventRows: 4,
            },
            dayGrid: {
              dayMaxEventRows: 4,
            },
            dayGridMonth: {
              dayMaxEventRows: 4,
            },
          }}
          plugins={[
            dayGridPlugin,
            interactionPlugin,
            timeGridPlugin,
            momentPlugin,
            momentTimezonePlugin,
            bootstrap5Plugin,
          ]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: [ View.MONTH, View.WEEK, View.DAY ].join(','),
          }}
      />
    </div>
    <Modal isOpen={creatingEvent && !creatingEvent.type?.id} title={trans('modal.selectEventType.title')}
           onClose={handleEventTypeFormClose} size={'md'}>
      <EventTypeForm onSubmit={handleEventTypeFormSubmit}/>
    </Modal>
    <Modal isOpen={!!creatingEvent?.type?.id} title={trans('modal.createEvent.title')}
           onClose={resetCreateEventForm} size={'xl'}>
      <EventForm onSubmit={resetCreateEventForm}/>
    </Modal>
    <Modal isOpen={!!editingEvent} title={trans('modal.editEvent.title')} onClose={resetEditEventForm}
           size={'xl'}>
      <EventForm onSubmit={handleEditEventSubmit}/>
    </Modal>
    <Modal isOpen={!!editingSlot} title={trans('modal.editSlot.title')} onClose={handleEditSlotClose}
           size={'lg'}>
      <SlotForm onClose={handleEditSlotClose} onSubmit={handleEditSlotSubmit}/>
    </Modal>
    <ConfirmModal title={trans('modal.confirmDeleteSlotModal.title')}
                  isOpen={!!deletingSlot}
                  onClose={handleConfirmDeleteSlotModalClose}
                  onConfirm={handleConfirmDeleteSlotModalConfirm}
    />
    <ConfirmModal title={trans('modal.confirmDeleteEventModal.title')}
                  isOpen={!!deletingEvent}
                  onClose={handleConfirmDeleteEventModalClose}
                  onConfirm={handleConfirmDeleteEventModalConfirm}
    />
    <SlotSerieFormModal/>
  </div>
}

export default Calendar
