import { User } from 'src/Types/User'

export default {
  english: {
    search: 'Search',
    searchPatient: 'Search patients...',
    addPatient: 'Add patient',
    goBackAsYourself: (name: User) => `Go back as ${name}`,
    noLastName: 'No last name',
    noFirstName: 'No first name',
    noDateOfBirth: 'No date of birth',
    born: 'born',
    invalidInstanceId: 'Invalid patient ID',
    typeAtLeast: 'Please type at least 2 character to start searching',
    manageMyAccount: 'Manage account',
    myAccount: 'My account',
  },
  french: {
    search: 'Rechercher',
    searchPatient: 'Rechercher des patients...',
    addPatient: 'Ajouter un patient',
    goBackAsYourself: (name: User) => `Revenir en tant que ${name}`,
    noLastName: 'Pas de nom de famille',
    noFirstName: 'Pas de prénom',
    born: 'né(e)',
    invalidInstanceId: 'ID de patient invalide',
    typeAtLeast: 'Veuillez saisir au moins 2 caractères pour commencer la recherche',
    manageMyAccount: 'Gérer mon compte',
    myAccount: 'Mon compte',
  }
}
