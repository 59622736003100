import * as type from './actionTypes'

export const init = id => ({
  type: type.INIT,
  payload: { id }
})

export const setPatient = patient => ({
  type: type.SET_PATIENT,
  payload: { patient }
})

export const fetchDashboardData = patientId => ({
  type: type.FETCH_DASHBOARD_DATA,
  payload: { patientId }
})

export const fetchSidebarEvents = patientId => ({
  type: type.FETCH_SIDEBAR_EVENTS,
  payload: { patientId }
})

export const fetchInstanciatedForms = patientId => ({
  type: type.FETCH_INSTANCIATED_FORMS,
  payload: { patientId }
})

export const setInstanciatedForms = data => ({
  type: type.SET_INSTANCIATED_FORMS,
  payload: data
})

export const fetchFailed = httpCode => ({
  type: type.FETCH_FAILED,
  payload: { httpCode }
})

export const setIsAddMenuOpen = data => ({
  type: type.SET_IS_ADD_MENU_OPEN,
  payload: data
})

export const toggleAddMenu = () => ({
  type: type.TOGGLE_ADD_MENU
})

export const setSidebarEvents = data => ({
  type: type.SET_SIDEBAR_EVENTS,
  payload: data
})

export const setIsSectionNav = data => ({
  type: type.SET_IS_SECTION_NAV,
  payload: data
})

export const fetchDetailsOverview = patientId => ({
  type: type.FETCH_DETAILS_OVERVIEW,
  payload: { patientId }
})

export const fetchDetailsOverviewSucceeded = data => ({
  type: type.FETCH_DETAILS_OVERVIEW_SUCCEEDED,
  payload: data
})

export const fetchDetailsOverviewFailed = () => ({
  type: type.FETCH_DETAILS_OVERVIEW_FAILED
})

export const fetchPatientRelatedForms = () => ({
  type: type.FETCH_PATIENT_RELATED_FORMS
})

export const setPatientRelatedForms = data => ({
  type: type.SET_PATIENT_RELATED_FORMS,
  payload: data
})

export const addInstance = (patientId, formId, navigate) => ({
  type: type.ADD_INSTANCE,
  payload: { patientId, formId, navigate }
})

export const fetchEncounters = id => ({
  type: type.FETCH_ENCOUNTERS,
  payload: id
})

export const setEncounters = data => ({
  type: type.SET_ENCOUNTERS,
  payload: data
})

export const fetchDocuments = id => ({
  type: type.FETCH_DOCUMENTS,
  payload: id
})

export const documentsFetched = data => ({
  type: type.DOCUMENTS_FETCHED,
  payload: data
})

export const documentsFetchError = () => ({
  type: type.DOCUMENTS_FETCH_ERROR
})

export const deleteDocument = id => ({
  type: type.DELETE_DOCUMENT,
  payload: id
})

export const documentDeleted = id => ({
  type: type.DOCUMENT_DELETED,
  payload: id
})

export const documentDeleteError = id => ({
  type: type.DOCUMENT_DELETE_ERROR,
  payload: id
})

export const downloadDocument = document => ({
  type: type.DOWNLOAD_DOCUMENT,
  payload: document
})

export const editDocumentName = (documentId, documentName) => ({
  type: type.EDIT_DOCUMENT_NAME,
  payload: {
    documentId,
    documentName
  }
})

export const changeDocumentName = (documentId, documentName) => ({
  type: type.CHANGE_DOCUMENT_NAME,
  payload: {
    documentId,
    documentName
  }
})

export const saveDocumentName = (documentId, originalDocumentName) => ({
  type: type.SAVE_DOCUMENT_NAME,
  payload: {
    documentId,
    originalDocumentName
  }
})

export const documentNameSaveError = id => ({
  type: type.DOCUMENT_NAME_SAVE_ERROR,
  payload: id
})

export const documentNameSaved = (documentId, documentName) => ({
  type: type.DOCUMENT_NAME_SAVED,
  payload: {
    documentId,
    documentName
  }
})

export const reset = () => ({
  type: type.RESET
})
