import React, { useEffect } from 'react'
import { translate } from 'src/Services/translation'
import { useDispatch, useSelector } from 'react-redux'
import translation from './translations'
import AdvancedTable from 'src/Components/AdvancedTable'
import { Card, Spinner } from 'react-bootstrap'
import { fetchData, paginate, filter, sort } from './state/actions'
import { AuditTrailLog, AuditTrailLogFields } from 'src/Types/AuditTrailLog'
import { Column } from 'src/Views/AuditTrails/Types'
import { Filter } from 'src/Types/RequestFilter'
import { SortItem } from 'src/Types/Pagination'
import columns from './columns'
import AuditTrailLogCell from 'src/Components/AuditTrailLogCell'
import { DisplayColumnDef, RowData } from '@tanstack/table-core/build/lib/types'
import { setOptions } from 'src/Layouts/View/state/actions'
import { StoreState } from 'src/Services/Store/reducers'

const AuditTrails = () => {
  const dispatch = useDispatch()

  const { user, autoRefresh } = useSelector((state: StoreState) => state.Root)
  const { rows, isLoading, pagination, paginationDetails, filters, sortItems }
      = useSelector((state: StoreState) => state.AuditTrails)

  const trans = translate(translation)(user.language)

  useEffect(() => {
    if (autoRefresh && rows.length)
      dispatch(fetchData())
  }, [ autoRefresh ])

  useEffect(() => {
    dispatch(setOptions({
      title: trans('title'),
      hasPrimaryButton: false,
    }))

    if (!rows.length && !isLoading)
      dispatch(fetchData())
  }, [])

  // Indicator defined only if the table details as been fetched
  if (isLoading && !rows.length)
    return <div className="d-flex"><Spinner animation="border" className="m-auto" size="sm"/></div>

  const handleRowClick = (row: AuditTrailLog): any => null

  const getPagination = () => ({ ...pagination, ...paginationDetails })

  const onPaginationChange = (currentPage: number, pageSize: number, forceUpdate = false) => {
    const offset = (currentPage - 1) * pageSize
    const limit = currentPage * pageSize

    const hasOffsetChanged = offset !== getPagination().offset
    const hasLimitChanged = limit !== getPagination().limit &&
        (limit < getPagination().totalItemsCount || getPagination().limit < getPagination().totalItemsCount)

    if (hasOffsetChanged || hasLimitChanged || forceUpdate)
      dispatch(paginate(offset, limit))
  }

  const onSortingChange = (newSortItems: SortItem[]) => {
    if (JSON.stringify(newSortItems) === JSON.stringify(sortItems))
      return

    dispatch(sort(newSortItems))
  }

  const onFilteringChange = (newFilters: Filter[]) => {
    if (JSON.stringify(newFilters) === JSON.stringify(filters))
      return

    dispatch(filter(newFilters))
  }

  const getColumn = (column: Column): DisplayColumnDef<RowData, any> => ({
    id: column.name,
    // @ts-ignore
    accessorKey: column.name,
    enableColumnFilter: column.enableColumnFilter,
    enableSorting: column.enableSorting,
    showTimeOnly: column.showTimeOnly,
    showDateOnly: column.showDateOnly,
    options: null,
    valueType: column.type,
    header: () => trans(`column.${column.name}`),
    cell: ({ column: { columnDef }, getValue }) =>
        <AuditTrailLogCell column={(column.name as AuditTrailLogFields)} value={getValue()} />,
    data: null
  })

  return <Card>
    <Card.Body>
      <AdvancedTable data={rows}
                     columns={columns}
                     filters={filters}
                     defaultSortItems={sortItems}
                     totalRowsCount={getPagination().totalItemsCount}
                     onSort={onSortingChange}
                     onFilter={onFilteringChange}
                     onPaginationChange={onPaginationChange}
                     isFilterable
                     onRowClick={handleRowClick}
                     getColumn={getColumn}
                     showIdColumn={false}
                     shwoCreatedAtColumn={false}
      />
    </Card.Body>
  </Card>
}

export default AuditTrails
