import React from 'react'
import { ICON_TYPE_CLASS, ICON_TYPE_SVG, IMG_SIZES, SIZES, SIZE_SM } from '../Services/Constants'

/**
 *
 * @param content
 * @param type
 * @param size 'sm' OR { height: 10px, width: 10px }
 */
const Icon = ({ content, type, size = SIZE_SM }) => {

  let sizeType = 'unknown'

  if (SIZES.includes(size))
    sizeType = 'standard'
  else if (typeof size !== 'string' && size.height && size.width)
    sizeType = 'image'

  const SvgIcon = (size) => {
    size = sizeType === 'standard' ? IMG_SIZES[size] : size

    return <img src={`data:image/svg+xml;utf8,${encodeURIComponent(content)}`}
                width={size?.width}
                height={size?.height}
                alt={'svg-icon'}
    />
  }

  const ClassIcon = () => <i className={`${content} fa-${size}`}/>

  return type === ICON_TYPE_SVG ? SvgIcon(size)
    : type === ICON_TYPE_CLASS ? ClassIcon()
      : (<></>)
}

export default Icon
