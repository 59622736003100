import {
  ACTION_CREATED,
  ACTION_DELETED,
  ACTION_UPDATED,
  ACTION_RECEIVED,
  CREATE_ACTION,
  SET_DELETING_ACTION,
  SET_UPDATING_ACTION,
  DELETE_ACTION,
  UPDATE_ACTION,
  TOGGLE_CREATE_ACTION_MODAL
} from './actionTypes'

export const createItem = item => ({
  type: CREATE_ACTION,
  payload: item
})

export const deleteItem = () => ({
  type: DELETE_ACTION
})

export const updateItem = item => ({
  type: UPDATE_ACTION,
  payload: item
})

export const itemReceived = item => ({
  type: ACTION_RECEIVED,
  payload: item
})

export const itemCreated = item => ({
  type: ACTION_CREATED,
  payload: item
})

export const itemDeleted = id => ({
  type: ACTION_DELETED,
  payload: id
})

export const itemUpdated = item => ({
  type: ACTION_UPDATED,
  payload: item
})

export const setDeletingItem = item => ({
  type: SET_DELETING_ACTION,
  payload: item
})

export const setUpdatingItem = item => ({
  type: SET_UPDATING_ACTION,
  payload: item
})

export const toggleCreateActionModal = () => ({
  type: TOGGLE_CREATE_ACTION_MODAL
})
