import React from 'react'
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { translate, translateConf } from 'src/Services/translation'
import { FieldOption } from 'src/Types/FieldOption'
import { FieldPermissionAccessLevel } from 'src/Types/FieldPermission'
import {
  FIELD_BASE_TYPE_HIDDEN, FIELD_BASE_TYPE_REFERENCE,
  FIELD_BASE_TYPES_WITH_NO_LABEL,
  FORM_ELEMENT_FIELD
} from 'src/Services/Constants'
import { getElementStatusFromCustomizations } from 'src/Views/FormFiller/state/selectors'
import { SwpFeatureStatus } from 'src/Views/FormFiller/Types/Swp'
import FieldComponentResolver from './FieldComponentResolver'
import { changeFieldValue } from '../state/actions'
import translation from '../../Patient/translations'

const FormFillerColumn = ({
  cell,
  forceDisable = false,
  forceRequired = false,
  fieldParent = null,
  fieldParentDisabled = false
}) => {
  const dispatch = useDispatch()

  const { configTranslations } = useSelector(state => state.Root)
  const { language, isAdmin } = useSelector(state => state.Root.user)
  const { referenceFieldOnEdit, isOpenedInstanceReady, swpFeatureStatus } = useSelector(state => state.FormFiller)
  const field = useSelector(state => state.FormFiller.openedInstance.fields?.[cell?.id || cell] || null)

  if (!field?.id)
    return <h6 className={ 'text-danger' }>Field not found for id : &quot;{ cell?.id || cell }&quot;</h6>

  const fieldStatus = useSelector(state =>
    getElementStatusFromCustomizations(state.FormFiller, FORM_ELEMENT_FIELD, field.id))

  const trans = translate(translation)(language)
  const transConf = translateConf(configTranslations)

  React.useEffect(() => {
    if (fieldStatus?.value !== undefined && fieldStatus?.value !== field.value)
      dispatch(changeFieldValue({ id: field.id }, fieldStatus.value))
  }, [ fieldStatus?.value ])

  const shouldDisplayFieldName = () => {
    if (field.type.baseFieldType === FIELD_BASE_TYPE_HIDDEN && isAdmin)
      return true

    return FIELD_BASE_TYPES_WITH_NO_LABEL.every(_ => _ !== field.type.baseFieldType)
  }

  const isFieldHidden = () => fieldStatus.isHidden === true

  const isFieldDisabled = () => {
    if (!isOpenedInstanceReady || (isOpenedInstanceReady && swpFeatureStatus === SwpFeatureStatus.LOADING))
      return true

    if (
      fieldParent &&
      fieldParent?.type?.baseFieldType === FIELD_BASE_TYPE_REFERENCE &&
      !fieldParentDisabled &&
      !fieldParent.value
    ) {
      return false
    } else if (referenceFieldOnEdit && referenceFieldOnEdit.id === fieldParent?.id) {
      return false
    } else {
      return forceDisable
        || field.accessLevel < FieldPermissionAccessLevel.UPDATE
        || fieldStatus.isDisable === true
        || field.options[FieldOption.READ_ONLY] === true
    }
  }

  const isFieldRequired = () => forceRequired || field.options[FieldOption.REQUIRED] === true

  const isFieldLoading = () => fieldStatus.isLoading === true

  return <Form.Group title={ field.options[FieldOption.LABEL_HOVER] || '' }
                     className={ `align-self-center ${ isFieldHidden() ? 'd-none' : '' }` }>
    { shouldDisplayFieldName() && <>
      <Form.Label className={ 'fw-semibold' }>
        { transConf('FIELD')(field) || trans('noLabelFound') }
        { isFieldRequired() && <span className={ 'text-danger' }>*</span> }
        {
          field.options[FieldOption.LABEL_HOVER] &&
          <OverlayTrigger
            key={ 'top' }
            placement={ 'top' }
            overlay={
              <Tooltip
                id={ `field_${ field.id }_tooltip` }
              >
                { field.options[FieldOption.LABEL_HOVER] }
              </Tooltip>
            }
          >
            <i className={ 'fa-solid fa-circle-info ms-1' }></i>
          </OverlayTrigger>
        }
      </Form.Label>
      { isFieldRequired() && !field.value && <Form.Text className={ 'text-danger' }>{ trans('required') }</Form.Text> }
    </> }
    <FieldComponentResolver field={ field }
                            isDisabled={ isFieldDisabled() }
                            isLoading={ isFieldLoading() }
                            isRequired={ isFieldRequired() }
    />
    { fieldStatus.info !== undefined &&
      <>
        <br/>
        <Form.Text className={ `text-${ fieldStatus.info.variant }` }>{ fieldStatus.info.value }</Form.Text>
      </>
    }
  </Form.Group>
}

export default FormFillerColumn
