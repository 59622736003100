import React from 'react'
import translation from './translations'
import { translate } from 'src/Services/translation'
import { Col, Form, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Dashboard } from '@uppy/react'
import AwsS3 from '@uppy/aws-s3'
import { AwsS3Part } from '@uppy/aws-s3-multipart'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import Uppy, { UppyFile } from '@uppy/core'
// @ts-ignore
import UppyLocaleFr from '@uppy/locales/lib/fr_FR.js'
// @ts-ignore
import UppyLocaleEn from '@uppy/locales/lib/en_US.js'
import File from 'src/Types/File'
import FileConstraint from 'src/Types/FieldOption/FileConstraint'
import { Locale } from 'src/Types/Locale'
import apiMethods from 'src/Services/api/apiMethods'
import { StoreState } from 'src/Services/Store/reducers'

// @url https://uppy.io/docs/dashboard/#Options
const dashboardOptions = {
  proudlyDisplayPoweredByUppy: false,
  showProgressDetails: true,
  height: 300,
  online: true,
  metaFields: [
    { id: 'name', name: 'Name', placeholder: 'Name' },
    { id: 'description', name: 'Description', placeholder: 'Description' },
  ],
}

interface Props {
  constraints: FileConstraint[]
  defaultFiles: File[]
  isRequired: boolean
  onFilesAdded: (files: File[]) => any
  instanceId?: string
  fieldId?: string
  userCode?: string
}

const FilesUploader = ({
                         constraints = [],
                         onFilesAdded,
                         defaultFiles,
                         isRequired = false,
                         instanceId = null,
                         fieldId = null,
                         userCode = null,
                       }: Props) => {
  const { allowedFileFormats } = useSelector((state: StoreState) => state.Dictionary)
  const { config, user } = useSelector((state: StoreState) => state.Root)

  const trans = translate(translation)(user.language)

  const [ uppy, setUppy ] = React.useState<Uppy|null>(null)

  const getNbOfFilesOfFiletype = (files: File[], types: string | string[]) => {
    const typesArray = Array.isArray(types) ? types : [ types ]
    return files.filter(f => typesArray.includes(f.fileType) || typesArray.includes(f.extension)).length
  }
  const getConstraintStatus = (constraint: FileConstraint) => {
    const filesCount = getNbOfFilesOfFiletype(defaultFiles, constraint?.fileType)
    if (filesCount < constraint.min)
      return isRequired ? 'danger' : 'warning'
    else if (constraint.max && filesCount > constraint.max)
      return 'danger'
    else
      return 'success'
  }

  const uppyFileToAppFile = (file: UppyFile): File => ({
    id: (file.response ? file.response.body.fileId as string : file.id) || null,
    name: file.meta?.name || file.name,
    description: file.meta?.description as string || null,
    isLocked: false,
    fileType: file.type,
    extension: `.${file.extension}`,
    size: file.size,
    data: file.data,
  })

  const onBeforeFileAdded = (uppy: Uppy) => (currentFile: UppyFile, files: any) => {
    if (!constraints.length)
      return

    files = files ? Object.values(files) : []

    const newFile = uppyFileToAppFile(currentFile)

    const nbRecordedFilesOfType = getNbOfFilesOfFiletype(
        [ ...files, ...defaultFiles, newFile ],
        currentFile.type,
    )
    const currentTypeRestrictions =
        constraints.find(c => c.fileType.includes(newFile.fileType) || c.fileType.includes(newFile.extension))

    if (currentTypeRestrictions?.max && nbRecordedFilesOfType > currentTypeRestrictions?.max) {
      uppy.info(trans('fileMaxReach'), 'error', 1000)
      return false
    }
  }

  const getNewUppy = () => {
    const localUppy = new Uppy()

    const options = {
      debug: config.DEBUG_STATE === 1,
      autoProceed: false,
      restrictions: {
        // mb to b
        maxFileSize: parseInt(config.UPLOAD_MAX_FILESIZE, 10) * 1000000,
        allowedFileTypes: constraints?.length ? constraints.map(c => c.fileType).flat()
                                              : allowedFileFormats,
      },
      onBeforeFileAdded: onBeforeFileAdded(localUppy),
    }

    localUppy.use(AwsS3, {
      // Use multipart only for files larger than 20MiB
      shouldUseMultipart: true,//file => file.size > 20 * 2 ** 20,
      retryDelays: [ 0, 1000, 3000, 5000 ],
      prepareUploadParts: async (
          file: UppyFile,
          partData: { uploadId: string; key: string; parts: [ { number: number; chunk: Blob; } ] },
      ): Promise<{ presignedUrls: Record<number, string>; headers?: Record<number, Record<string, string>>; }> => {

        const { data } = await apiMethods.get(
            `/multipart/s3-multipart/${partData.uploadId}`,
            { parts: partData.parts.map(p => p.number) },
        )

        return { presignedUrls: data.presignedUrls }
      },
      createMultipartUpload: async (file: UppyFile) => {

        const { data } = await apiMethods.create(
            '/multipart/s3-multipart',
            {
              data: {
                instance: instanceId,
                field: fieldId,
                userCode,
                file: {
                  name: file.meta?.name || file.name,
                  description: file.meta?.description || null,
                  type: file.type,
                  extension: file.extension,
                  size: file.size,
                },
              },
            },
        )

        return { uploadId: data.uploadId, key: data.uploadKey }
      },
      listParts: async (
          file: UppyFile,
          opts: { uploadId: string; key: string; signal: AbortSignal },
      ): Promise<AwsS3Part[]> => {

        console.log('listParts')
        return []
        /*const res = await apiMethods.get(`/uppy/s3-multipart/${file.id}`)

        return [ { PartNumber: 0, Size: 0, ETag: 'string' } ]*/
      },
      completeMultipartUpload: async (
          file: UppyFile,
          opts: { uploadId: string; key: string; parts: AwsS3Part[]; signal: AbortSignal },
      ): Promise<{ location?: string }> => {
        const { data } = await apiMethods.create(
            `/multipart/s3-multipart/${opts.uploadId}/complete`,
            { data: { parts: opts.parts } },
        )

        /** @ts-ignore uppy is ok with extra data, TS is not */
        return { fileId: data.cloudUploadId, location: data.locationUrl }
      },
      abortMultipartUpload: (
          file: UppyFile,
          opts: { uploadId: string; key: string; signal: AbortSignal },
      ) => {
        apiMethods.delete(`/multipart/s3-multipart/${opts.uploadId}`)
      },
    })

    localUppy.setOptions(options)
    localUppy.on('complete', () => {
      onFilesAdded(localUppy.getFiles().map(uppyFileToAppFile))
      setUppy(getNewUppy())
    })

    return localUppy
  }

  React.useEffect(() => {
    return () => {
      uppy?.close({ reason: 'unmount' })
    }
  }, [])

  React.useEffect(() => {
    setUppy(getNewUppy())
  }, [])

  React.useEffect(() => {
    uppy?.setOptions({ onBeforeFileAdded: onBeforeFileAdded(uppy) })
  }, [ defaultFiles ])

  const renderConstraints = React.useCallback(() =>
    constraints.map((constraint, i) => <div key={i}>
      <Form.Text className={`text-${getConstraintStatus(constraint)}`}>
        <span className="fw-bold">{constraint.fileType.join(', ')} : </span>
        {`${constraint.min} ${trans('minimum')} / ${constraint.max !== null
                                                    ? `${constraint.max} ${trans(
                'maximum')} /` : ''}  ${getNbOfFilesOfFiletype(
            defaultFiles,
            constraint.fileType,
        )} ${trans('added')}`}
      </Form.Text></div>)
  , [ constraints, defaultFiles ])

  return <>
    {uppy &&
        <Dashboard uppy={uppy}
                   height="100px"
                   width="100%"
                   locale={user.locale === Locale.FR ? UppyLocaleFr : UppyLocaleEn} {...dashboardOptions}
        />}
    <Row>
      <Col>
        {renderConstraints()}
      </Col>
    </Row>
    <Row>
      <Col>
        <Form.Text className="fw-bold text-danger">
          {trans('maxFileSize')} : {config.UPLOAD_MAX_FILESIZE} mb
        </Form.Text>
      </Col>
    </Row>
  </>
}

export default FilesUploader
