import React, { ChangeEvent, FC, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Form as BForm } from 'react-bootstrap'
import { translate, translateConf } from 'src/Services/translation'
import { formatDateTime } from 'src/Utils/Date'
import { getIsFormFillerCalendarRoute } from 'src/Services/Store/Router/selectors'
import ListCell from 'src/Components/ListCell'
import { Form } from 'src/Types/Form'
import { Instance } from 'src/Types/Instance'
import { ListColumn } from 'src/Types/ListColumn'
import { EventType } from 'src/Types/Event'
import translations from '../../translations'
import { setIsSectionNav } from '../../state/actions'
import { StoreState } from 'src/Services/Store/reducers'

interface Props {
  instance: Instance
  headers: ListColumn[]
  form: Form
}

const InstanceCard: FC<Props> = ({
  instance,
  headers = [],
  form
}) => {
  const dispatch = useDispatch()
  const reactLocation = useLocation()
  const navigate = useNavigate()
  const { id: patientId } = useParams()

  const { configTranslations, user } = useSelector((state: StoreState) => state.Root)
  const { openedInstance } = useSelector((state: StoreState) => state.FormFiller)
  const { eventTypes } = useSelector((state: StoreState) => state.Dictionary)

  const trans = translate(translations)(user.language)
  const transConf = translateConf(configTranslations)
  const formatLocaleDateTime = formatDateTime(user.locale, user.portalTimezone)
  const isCalendarOpen = getIsFormFillerCalendarRoute(reactLocation)
  const getFormEventTypes = () => eventTypes.filter((_: EventType) => _.form?.id === form?.id)

  const [ eventType, setEventType ] = useState(getFormEventTypes().length ? getFormEventTypes()[0] : null)

  const isDraggable = () => !!(isCalendarOpen && eventType)
  const isActive = openedInstance.id === instance.id

  const getData = () => eventType ? {
    'data-id': instance.id,
    'data-patient-id': instance.patient?.id,
    'data-location-id': instance.location?.id,
    'data-event-type-id': eventType.id
  } : {}

  const onInstanceClick = () => {
    if (isActive) {
      dispatch(setIsSectionNav(true))

      return
    }

    navigate(`/patient/${ patientId }/instance/${ instance.id }`)
  }

  const handleEventTypeSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault()
    setEventType(eventTypes.find((_: EventType) => _.id === e.target.value))
  }

  return <div key={ instance.id }
              data-id={ instance.id }
              className={ `instance-card ${isActive ? 'active' : ''}` }
              onKeyDown={ e => e.key === 'Enter' && onInstanceClick() }
  >
    <div className={ `${ isDraggable() ? 'cursor-drag patient-sidebar-form-item' : '' }` }
         { ...getData() }
    >
      <div onClick={ onInstanceClick }>
        { headers.length ? headers.map(header =>
         (<div key={ header.id }>
            <span>{ transConf('LIST_COLUMN')(header) } :&nbsp;</span>
            <span className="fw-bold">
              <ListCell value={ instance.values[header.systemName] || null } field={header.field} />
            </span>
          </div>))
         : (
            <div>
              <div>{trans('created')} :&nbsp;
              <span className="value">
                {formatLocaleDateTime(instance.createdAt)}
              </span>
            </div>
              <div>{trans('modified')} :&nbsp;
              <span className="value">{formatLocaleDateTime(instance.updatedAt)}</span>
            </div>
          </div>
          )
        }
      </div>
    </div>

    { isCalendarOpen && <>
      { !getFormEventTypes().length && <small className="text-muted">{ trans('noEventTypeAvailable') }</small> }
      { getFormEventTypes().length === 1 && transConf('EVENT_TYPE')(getFormEventTypes()[0]) }
      { getFormEventTypes().length > 1 &&
        <BForm.Select size={ 'sm' } onChange={ handleEventTypeSelect } value={ eventType?.id }>
          { getFormEventTypes()
            .map((_: EventType) => <option key={ _.id } value={ _.id }>{ transConf('EVENT_TYPE')(_) }</option>) }
        </BForm.Select> }
    </> }
  </div>
}

export default InstanceCard
