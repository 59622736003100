import { call, put, takeLatest } from 'redux-saga/effects'
import * as type from './actionTypes'
import apiMethods from '../../../Services/api/apiMethods'
import { resetPatientCreation, createPatient, setSimilarPatients } from './actions'

function* searchSimilarPatients(props, { payload }) {
  try {
    const { data } = yield call(apiMethods.create, '/patients/search_duplicates',{ data: payload.patient })
    yield put(setSimilarPatients(data, payload.fullPatient, payload.navigate))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'fetchFailed'))
  }
}

function* onSimilarPatientsFound(props, { payload }) {
  if (!payload.patients?.length)
    yield put(createPatient(payload.targetPatient, payload.navigate))
}

function* doCreatePatient(props, { payload: { patient, navigate } }) {
  try {
    const { data } = yield call(apiMethods.create, '/patients', { data: patient })
    if (navigate)
      yield call(navigate, `/patient/${ data.id }/instance/${ data.id }`)
    yield put(resetPatientCreation())
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'creationFailed'))
  }
}

export default function* patientCreatorSagaWatcher(props) {
  yield takeLatest(type.SEARCH_SIMILAR_PATIENTS, searchSimilarPatients, props)
  yield takeLatest(type.SET_SIMILAR_PATIENTS, onSimilarPatientsFound, props)
  yield takeLatest(type.CREATE_PATIENT, doCreatePatient, props)
}
