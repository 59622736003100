import React from 'react'
import { fieldEvent } from '../../state/actions'
import { FormControl } from 'react-bootstrap'
import { FieldEvent } from 'src/Views/FormFiller/Types/Field'
import { useDispatch } from 'react-redux'

const UsernameField = ({
  id = null,
  field = null,
  value,
  disabled = false,
  isRequired = false,
}) => {
  const dispatch = useDispatch()

  const onChange = e => {
    dispatch(fieldEvent(field, FieldEvent.VALUE_CHANGE, { value: e.target.value }))
  }

  return <FormControl id={ `field${ id }` }
                      value={ value || '' }
                      onChange={ onChange }
                      size={ 'sm' }
                      disabled={ disabled }
                      required={ isRequired }
  />
}

export default UsernameField
