import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, Spinner, Card } from 'react-bootstrap'
import { formatDateTime as formatDateTimeService } from 'src/Utils/Date.ts'
import SaveButton from 'src/Components/SaveButton'
import translation from 'src/Views/FormFiller/translations.ts'
import { translate } from 'src/Services/translation'
import { toggleConfirmModal } from 'src/Services/Store/GlobalControls/actions'
import AuditTrailsModal from 'src/Components/AuditTrailsModal/index.tsx'
import ValidateUserIdentityModal from 'src/Components/ValidateUserIdentityModal'
import { setOptions, setRightHeaderTemplate } from 'src/Layouts/View/state/actions'
import { setSections } from 'src/Components/SectionNavigator/state/actions'
import { hasUserInstancePermission, hasUserModulePermission } from 'src/Utils/index.ts'
import { MODULE_AUDIT_TRAILS } from 'src/Services/Constants/Config/Modules'
import { AuditTrailLogFields } from 'src/Types/AuditTrailLog'
import { ColumnValueType, FilterOperator } from 'src/Types/RequestFilter'
import { SortItemDirection } from 'src/Types/Pagination'
import { FetchStatus } from 'src/Types/FetchStatus'
import { PermissionAccessLevel } from 'src/Types/Permission'
import { FormPermissionAccessLevel } from 'src/Types/FormPermission'
import { getSectionsWithFields } from 'src/Views/FormFiller/state/selectors'
import ReferenceResultsModal from './ReferenceResultsModal'
import MissingFieldsModal from './MissingFieldsModal'
import FormFillerRow from './Row'
import MsSanteModal from './MsSanteModal'
import MsSanteTrackingModal from './MsSanteTrackingModal'
import { setIsSectionNav } from '../../Patient/state/actions'

import {
  fetchInstance,
  saveInstance,
  deleteInstance,
  restoreInstance,
  printContent, toggleValidateUserIdentityModal, setUserCode, setPendingProtectedAction, fetchMsSanteTrackingData
} from '../state/actions'
import { hasTrackingId } from '../utils/MSSante'

const FormFiller = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { id, instanceId: secondId } = useParams()
  const [ searchParams ] = useSearchParams()

  const patientId = secondId ? id : null
  const instanceId = secondId || id

  const { user } = useSelector(state => state.Root)
  const { FormFiller } = useSelector(state => state)
  const { title } = useSelector(state => state.View)
  const { isValidateUserIdentityModalOpen, pendingProtectedAction } = useSelector(state => state.FormFiller)

  const [ isChangelogModalOpen, setIsChangelogModalOpen ] = useState(false)
  const [ isMissingFieldsModalOpen, setIsMissingFieldsModalOpen ] = useState(false)
  const [ isFormWasValidated, setIsFormWasValidated ] = useState(false)

  const { openedInstance, openedInstanceForm, saveInstanceStatus, fetchInstanceStatus, fieldsToSave } = FormFiller
  const { sections, fields = {} } = openedInstance

  const formatDateTime = formatDateTimeService(user.locale, user.portalTimezone)
  const trans = translate(translation)(user.language)

  const instanceForm = React.useRef()

  const getTitle = () => searchParams.has('customTitle')
    ? searchParams.get('customTitle') : openedInstance?.identityString

  useEffect(() => {
    dispatch(fetchInstance(instanceId || patientId))
    dispatch(setIsSectionNav(true))
  }, [ dispatch, patientId, instanceId ])

  useEffect(() => {
    if (openedInstanceForm && openedInstanceForm.isMsSanteEnabled ) {
      dispatch(fetchMsSanteTrackingData(instanceId))
    }
  }, [ openedInstanceForm ])

  useEffect(() => {
    if (sections)
      dispatch(setSections(sections.map(_ => _.name)))
  }, [ dispatch, sections ])

  useEffect(() => {
    if (title !== openedInstance?.identityString) {
      dispatch(setOptions({
        title: getTitle(),
        hasPrimaryButton: false,
        isLoading: true,
        isHeaderSticky: true,
        rightHeaderTemplate: renderRightHeaderTemplate()
      }))
    }
  }, [ openedInstance ])

  useEffect(() => {
    dispatch(setRightHeaderTemplate(renderRightHeaderTemplate()))
  }, [ saveInstanceStatus, fieldsToSave ])

  const getMissingFields = () =>
    Object.values(fields).filter(field =>
      field.options?.required === true && (!field.value || field.value?.length === 0)
    )

  const onSubmitButtonClick = () => {
    instanceForm.current.requestSubmit()
    setIsFormWasValidated(true)
  }

  const onSubmit = e => {
    e?.preventDefault()

    if (getMissingFields().length)
      showMissingFieldsModal()
    else {
      dispatch(saveInstance(instanceId || patientId))
      setIsFormWasValidated(false)
    }
  }

  const showMissingFieldsModal = () => setIsMissingFieldsModalOpen(true)
  const onChangelogClick = () => {
    setIsChangelogModalOpen(true)
  }
  const onModalChangelogClose = () => setIsChangelogModalOpen(false)
  const onValidateUserIdentityModalClose = () => {
    dispatch(toggleValidateUserIdentityModal(false))
    dispatch(setPendingProtectedAction(null))
  }
    const onValidateUserIdentityModalSubmit = pin => {
        dispatch(setUserCode(pin))
        onSubmit()
        dispatch(toggleValidateUserIdentityModal())
        if (pendingProtectedAction) {
            dispatch(pendingProtectedAction)
            dispatch(setPendingProtectedAction(null))
        }
    }

  const onDeleteClick = () =>
    dispatch(toggleConfirmModal(() => {
      dispatch(deleteInstance(instanceId || patientId, navigate))
    }))

  const onPrintClick = () => dispatch(printContent())
  const onRestoreClick = () => dispatch(restoreInstance(instanceId || patientId))

  const getRows = section => section.rows.sort((a, b) => a.sortOrder - b.sortOrder)

  const renderRightHeaderTemplate = () => <>
    { hasUserModulePermission(user, MODULE_AUDIT_TRAILS, PermissionAccessLevel.READ) &&
      <Button size={ 'sm' }
              title={ 'Audit trails' }
              className={ 'me-2' }
              onClick={ onChangelogClick }
              variant={ 'secondary' }
      >
        <i className={ 'icon fas fa-history' }/>
      </Button> }
    <Button size={ 'sm' } title={ 'Print' } className={ 'me-2' } onClick={ onPrintClick } variant={ 'secondary' }>
      <i className={ 'icon fas fa-print' }/>
    </Button>
    { hasUserInstancePermission(user, openedInstance, FormPermissionAccessLevel.DELETE) &&
      <Button size={ 'sm' } title={ 'Delete' } className={ 'me-2' } onClick={ onDeleteClick } variant={ 'danger' }>
        <i className={ 'icon fas fa-trash' }/>
      </Button> }
    { hasUserInstancePermission(user, openedInstance, FormPermissionAccessLevel.UPDATE) &&
      <SaveButton onClick={ onSubmitButtonClick }
                  saveStatus={ saveInstanceStatus }
                  id={ 'saveInstanceButton' }
      /> }
  </>

  if (fetchInstanceStatus === FetchStatus.IDLE || fetchInstanceStatus === FetchStatus.LOADING)
    return <Spinner variant={ 'primary' } animation={ 'border' } role={ 'main' } className={ 'd-block m-auto' }>
      <span className={ 'visually-hidden' }>Loading...</span>
    </Spinner>
  else if (fetchInstanceStatus === FetchStatus.FAIL)
    return <>
      <ErrorWrapper>
        { trans('fetchInstanceError') }
      </ErrorWrapper>
    </>
  else if (fetchInstanceStatus === FetchStatus.FORBIDDEN)
    return <>
      <ErrorWrapper>
        { trans('instanceForbidden') }
      </ErrorWrapper>
    </>
  else if (fetchInstanceStatus === FetchStatus.NOT_FOUND)
    return <>
      <ErrorWrapper>
        { trans('instanceDeleted') }
        <Button variant={ 'info' } onClick={ onRestoreClick }>
          { trans('restore') }
        </Button>
      </ErrorWrapper>
    </>

  return <>
    <AuditTrailsModal isOpen={ isChangelogModalOpen }
                      onClose={ onModalChangelogClose }
                      instance={ openedInstance }
    />
    <ValidateUserIdentityModal isOpen={ isValidateUserIdentityModalOpen }
                               onClose={ onValidateUserIdentityModalClose }
                               onSubmit={ onValidateUserIdentityModalSubmit }
                               trans={ trans }
    />
    <MissingFieldsModal isOpen={ isMissingFieldsModalOpen }
                        missingFields={ getMissingFields() }
                        onClose={ () => setIsMissingFieldsModalOpen(false) }
                        trans={ trans }
    />
    <div>
      <small className={ 'text-primary' }>
        { trans('lastUpdatedAt') } : <b>{ formatDateTime(openedInstance?.updatedAt) }</b>
      </small>
    </div>
    <Form ref={ instanceForm } onSubmit={ onSubmit } className={ `${ isFormWasValidated ? 'was-validated' : '' }` }>
      { getSectionsWithFields(sections, fields).map((section, i) =>
        <Card key={ i } data-is={ 'section' } className={ 'mb-2' }>
          <Card.Header>
            <Card.Title className={ 'm-0 py-2' }>
              { section.name }
            </Card.Title>
          </Card.Header>
          <Card.Body>
            { getRows(section).map((row, i) => <FormFillerRow key={ i } row={ row }/>) }
          </Card.Body>
        </Card>
      ) }
    </Form>
    <ReferenceResultsModal/>
    <MsSanteModal />
    {
      openedInstance.form.isMsSanteEnabled && hasTrackingId(openedInstance) &&
        <MsSanteTrackingModal/>
    }
  </>
}

export default FormFiller

export const ErrorWrapper = styled.div`
  width: 60%;
  height: 100%;
  margin: auto;
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  button {
    margin: 1em auto 0 auto;
  }
`
