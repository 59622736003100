import * as types from 'src/Components/Calendar/state/actionTypes'
import { CALENDAR_SIDEBAR_DEFAULT_TAB } from 'src/Services/Constants'
import { getDefaultActiveFilters, setDefaultActiveFilters } from 'src/Components/Calendar/Utils/filters'
import CalendarFilter from 'src/Types/CalendarFilter'
import { Event } from 'src/Types/Event'
import Slot, { SlotType } from 'src/Types/Slot'
import { Instance } from 'src/Types/Instance'
import { SidebarTab } from 'src/Components/Calendar/Types/Calendar'
import { Action } from 'src/Services/Store/reducers'
import { Iso8601 } from 'src/Types/Date'
import CalendarEvent, { CalendarEventType } from 'src/Components/Calendar/Types/CalendarEvent'

export interface CalendarState {
  isSidebarOpen: boolean,
  filters: CalendarFilter[],
  events: CalendarEvent[],
  creatingEvent: Event|null,
  editingEvent: Event|null,
  deletingEvent: Event|null,
  editingSlot: Slot|null,
  deletingSlot: Slot|null,
  slotForm: {
    resources: Instance[],
    isModalOpen: boolean,
    slotType: SlotType|null
  },
  eventForm: {
    resources: Instance[]
  },
  currentStartDate: Iso8601|null,
  currentEndDate: Iso8601|null,
  fetchedEventsStartDate: Date|null,
  fetchedEventsEndDate: Date|null,
  activeTab: SidebarTab
}

const initialState: CalendarState = {
  isSidebarOpen: true,
  filters: [],
  events: [],
  creatingEvent: null,
  editingEvent: null,
  deletingEvent: null,
  editingSlot: null,
  deletingSlot: null,
  slotForm: {
    resources: [],
    isModalOpen: false,
    slotType: null
  },
  eventForm: {
    resources: []
  },
  currentStartDate: null,
  currentEndDate: null,
  fetchedEventsStartDate: null,
  fetchedEventsEndDate: null,
  activeTab: CALENDAR_SIDEBAR_DEFAULT_TAB
}

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: payload.tab
      }
    case types.SET_CURRENT_START_DATE:
      return {
        ...state,
        currentStartDate: payload.startDate
      }
    case types.SET_CURRENT_END_DATE:
      return {
        ...state,
        currentEndDate: payload.endDate
      }
    case types.SET_CREATING_EVENT:
      return {
        ...state,
        creatingEvent: payload.event
      }
    case types.SET_EDITING_EVENT:
      return {
        ...state,
        editingEvent: payload.event
      }
    case types.SET_DELETING_EVENT:
      return {
        ...state,
        deletingEvent: payload.event
      }
    case types.EVENT_DELETED:
      return {
        ...state,
        events: state.events.filter(e => e.extendedProps?.type !== CalendarEventType.EVENT || e.id !== payload.event.id)
      }
    case types.SET_EDITING_SLOT:
      return {
        ...state,
        editingSlot: payload.slot
      }
    case types.SET_DELETING_SLOT:
      return {
        ...state,
        deletingSlot: payload.slot
      }
    case types.SLOT_DELETED:
      return {
        ...state,
        events: state.events.filter(e => e.extendedProps?.type !== CalendarEventType.SLOT || e.id !== payload.slot.id)
      }
    case types.SET_SLOT_FORM_MODAL: {
      return {
        ...state,
        slotForm: {
          ...state.slotForm,
          slotType: payload.slotType || null,
          isModalOpen: payload.isOpen
        }
      }
    }
    case types.SET_EVENTS: {
      const fetchedEventsStartDate = new Date(payload.start)
      const fetchedEventsEndDate = new Date(payload.end)
      const isEventOutsideFetch = (event: CalendarEvent): boolean =>
          !(new Date(event.start) >= fetchedEventsStartDate && new Date(event.end) <= fetchedEventsEndDate)

      const isNotEventInPayload = (event: CalendarEvent): boolean =>
          !payload.events.some((e: Event) => e.id === event.id)

      return {
        ...state,
        events: [
          ...state.events.filter(isEventOutsideFetch).filter(isNotEventInPayload),
          ...payload.events
        ],
        fetchedEventsStartDate: !state.fetchedEventsStartDate || fetchedEventsStartDate < state.fetchedEventsStartDate
            ? fetchedEventsStartDate : state.fetchedEventsStartDate,
        fetchedEventsEndDate: fetchedEventsEndDate > state.fetchedEventsEndDate
            ? fetchedEventsEndDate : state.fetchedEventsEndDate
      }
    }

    case types.SET_SLOT_RESOURCES:
      return {
        ...state,
        slotForm: {
          ...state.slotForm,
          resources: payload.resources
        }
      }
    case types.SET_EVENT_RESOURCES:
      return {
        ...state,
        eventForm: {
          ...state.eventForm,
          resources: payload.resources
        }
      }
    case types.SET_FILTERS: {

      const defaultActiveFilters = getDefaultActiveFilters()

      for (const filter of payload.filters) {
        const hasFilterDefaultValue = defaultActiveFilters.some(({ name }) => name === filter.name)

        if (!hasFilterDefaultValue) {
          defaultActiveFilters.push({ ...filter, values: [ filter.options[0] ] })
        }
      }

      setDefaultActiveFilters(defaultActiveFilters)

      return { ...state, filters: defaultActiveFilters }
    }
    case types.SET_FILTER: {

      const defaultActiveFilters = getDefaultActiveFilters()
      const hasFilterDefaultValue
          = defaultActiveFilters.some(({ name }) => name === payload.filter.name)

      if (!hasFilterDefaultValue) {
        defaultActiveFilters.push({ ...payload.filter, values: [ payload.filter.options[0] ] })
        setDefaultActiveFilters(defaultActiveFilters)
      }

      return { ...state, filters: defaultActiveFilters }
    }
    case types.UPDATE_FILTER_VALUES: {
      let defaultActiveFilters = getDefaultActiveFilters()

      defaultActiveFilters
            = defaultActiveFilters.map(f => f.name === payload.filter.name ? { ...f, values: payload.values } : f)

      setDefaultActiveFilters(defaultActiveFilters)

      return { ...state, filters: defaultActiveFilters }
    }
    default:
      return state
  }
}
