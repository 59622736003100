import * as types from './actionTypes'

const initialState = {
  sections: [],
  activeSection: 0
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SET_ACTIVE_SECTION:
      return {
        ...state,
        activeSection: payload
      }
    case types.SET_SECTIONS:
      return {
        ...state,
        sections: payload
      }
    default:
      return state
  }
}
