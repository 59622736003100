import React from 'react'
import styled from 'styled-components'
import { translate } from 'src/Services/translation'
import Modal from '../../../../Components/Modal'
import translation from './translations'
import Row from '../Row'
import SectionHeader from './Header'
import SectionForm from './Form'
import SectionFooter from './Footer'
import { useDispatch, useSelector } from 'react-redux'

const Section = ({ section }) => {
  const dispatch = useDispatch()

  const { updateSection: isUpdating, dragging: isDragging, draggedRowTarget, form }
    = useSelector(state => state.FormEditor.formReducer)
  const { language } = useSelector(state => state.Root.user)

  const { id, name, rows } = section
  const [ isEditModelOpen, setIsEditModalOpen ] = React.useState(false)
  const trans = translate(translation)(language)
  const getId = () => id

  const getRows = () => rows.sort((a, b) => a.sortOrder - b.sortOrder)

  return (
    <FormSection id={ `section${ getId() }` } className="mb-2 is-child card">

      <Modal title={ `${ trans('edit') } ${ name }` }
             onClose={ () => setIsEditModalOpen(false) }
             isOpen={ isEditModelOpen }
      >
        <SectionForm sectionId={ getId() }
                     section={ section }
                     trans={ trans }
                     onSubmit={ () => setIsEditModalOpen(false) }
        />
      </Modal>

      <SectionHeader sectionId={ getId() }
                     section={ section }
                     trans={ trans }
                     onEditClick={ () => setIsEditModalOpen(true) }
      />

      { rows.length > 0 && <div className="card-content">
        { getRows().map((row, i) => (
          <Row row={ row }
               draggedRowTarget={ draggedRowTarget }
               trans={ trans }
               key={ `row_${ i }` }
               isDragging={ isDragging }
               sectionId={ getId() }
               section={ section }
               i={ i }
          />
        )) }
      </div> }
      <SectionFooter section={ section }
                     trans={ trans }
                     formId={ form?.id }
      />
    </FormSection>
  )
}

export const AddRowButton = styled.button.attrs({ type: 'button' })`
  cursor: pointer;
  outline: none;

  background-color: ${ props => props.theme.colors.primary.alpha.light(0.08) };
  border: 1px dashed ${ props => props.theme.colors.primary.light };
  box-shadow: inset 4px 3px 15px 0
    ${ props => props.theme.colors.grey.alpha.darkest(0.12) };
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  max-height: 48px;
  padding: 0.2em 0.8em;
  width: 100%;

  span {
    color: ${ props => props.theme.colors.primary.median };
    margin: auto 0;
    text-transform: uppercase;
  }

  :focus {
    outline: 1px solid orange;
  }

  :hover {
    background-color: ${ props => props.theme.colors.primary.alpha.light(0.12) };
  }
`

const FormSection = styled.section`
  header.card-header.level {
    padding: 0.3em 10px;
    margin-bottom: 0;
    box-shadow: none;

    input {
      border: none;
      box-shadow: none;
      color: ${ props => props.theme.colors.grey.darkest };
      font-size: 1.3em;
      font-weight: 900;
    }

    .field label:not(:last-child) {
      color: ${ props => props.theme.colors.grey.median };
      font-size: 0.875em;
      font-weight: 400;
      margin-bottom: 0;
    }

    .level-item:last-child .field label {
      color: ${ props => props.theme.colors.grey.light };
      font-style: italic;
    }
  }

  .card-content {
    padding: 0.75em;

    header {

      .icon {
        cursor: pointer;
      }

      span {
        height: 1.5em;
        width: 1.5em;
      }
    }
  }
`

export default Section
