import { TOGGLE_CONFIRM_MODAL, CONFIRM_MODAL_OK } from './action-types'

export const toggleConfirmModal = (payload) => ({
  type: TOGGLE_CONFIRM_MODAL,
  payload: payload
})

export const confirmModalOk = (payload) => ({
  type: CONFIRM_MODAL_OK,
  payload: payload
})
