import React, {useEffect} from 'react'
import {
  setDeletingItem, deleteItem, setUpdatingItem, updateItem, setCreatingItem, createItem, fetchItems
} from './state/actions'
import { translate } from '../../Services/translation'
import translation from './translations'
import {useDispatch, useSelector} from 'react-redux'
import TasksStatusTable from './Components/TasksStatusTable'
import Form from './Components/TasksStatusForm'
import Modal from '../../Components/Modal'
import ConfirmModal from '../../Components/ConfirmModal'
import { setOptions } from '../../Layouts/View/state/actions'
import { Container } from 'react-bootstrap'

const TasksStatus = () => {
  const dispatch = useDispatch()
  const {language} = useSelector((state) => state.Root?.user)
  const trans = translate(translation)(language)
  const {creatingItem, updatingItem, deletingItem} = useSelector((state) => state.TasksStatus)

  useEffect(() => {
    dispatch(setOptions({
      hasPrimaryButton: true,
      onPrimaryButtonClick: onCreateClick
    }))
    dispatch(fetchItems())
  }, [dispatch])

  const onCreateClick = () => dispatch(setCreatingItem({}))
  const onModalCreateClose = () => dispatch(setCreatingItem(null))
  const onModalCreateFormSubmit = data => {
    dispatch(setCreatingItem(data))
    dispatch(createItem())
  }

  const onUpdateClick = (item) => dispatch(setUpdatingItem(item))
  const onModalUpdateClose = () => dispatch(setUpdatingItem(null))
  const onModalUpdateFormSubmit = data => {
    dispatch(setUpdatingItem(data))
    dispatch(updateItem())
  }

  const onDeleteClick = item => dispatch(setDeletingItem(item))
  const onConfirmDeleteModalClose = () => dispatch(setDeletingItem(null))
  const onConfirmDeleteModalConfirm = () => dispatch(deleteItem())

  return (
    <Container>
      <TasksStatusTable onDeleteClick={onDeleteClick} onUpdateClick={onUpdateClick}/>

      <Modal isOpen={!!creatingItem} title={trans('modal.create.title')} onClose={onModalCreateClose}>
        <Form trans={trans} item={creatingItem}
              onSubmit={onModalCreateFormSubmit}
        />
      </Modal>

      <Modal isOpen={!!updatingItem} title={trans('modal.update.title')} onClose={onModalUpdateClose}>
        <Form trans={trans}
              onSubmit={onModalUpdateFormSubmit}
              item={updatingItem}
        />
      </Modal>

      <ConfirmModal title={trans('modal.confirmDelete.title')}
                    isOpen={!!deletingItem}
                    onClose={onConfirmDeleteModalClose}
                    onConfirm={onConfirmDeleteModalConfirm}
      />
    </Container>
  )
}

export default TasksStatus
