export default {
  english: {
    login: 'Login',
    error: {
      // https://auth0.com/docs/libraries/common-auth0-library-authentication-errors#log-in
      unauthorized: 'Your account is blocked',
      too_many_attempts: 'Your account is blocked due to too many attempts to log in',
      password_leaked: 'Your password has been leaked, you have to setup a new one',
      access_denied: 'Access denied'
    },
    nonProdLogin: {
      form: {
        username: {
          label: 'Username',
          title: 'username',
          placeholder: 'Username'
        },
        password: {
          label: 'Password',
          title: 'password',
          placeholder: 'Password'
        }
      }
    }
  },
  french: {
    login: 'Identifiant',
    error: {
      // https://auth0.com/docs/libraries/common-auth0-library-authentication-errors#log-in
      unauthorized: 'Votre compte est bloqué',
      too_many_attempts: 'Votre compte est bloqué à cause de trop de tentatives de connexion',
      password_leaked: 'Votre mot de passe a été fuité, vous devez en choisir un nouveau',
      access_denied: 'Accès refusé'
    },
    nonProdLogin: {
      form: {
        username: {
          label: 'Username',
          title: 'username',
          placeholder: 'Username'
        },
        password: {
          label: 'Mot de passe',
          title: 'mot de passe',
          placeholder: 'Mot de passe'
        }
      }
    }
  }
}
