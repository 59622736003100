import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Dropdown, ButtonGroup } from 'react-bootstrap'
import styled from 'styled-components'
import { translate } from 'src/Services/translation'
import NavTable from 'src/Components/NavTable'
import translation from '../translations'
import { formatDateTime } from 'src/Utils/Date.ts'
import { changeLimitFormList, changePageFormList, setFormToDelete, searchFormList, unlockForm } from '../state/actions'
import { useSelector } from 'src/Services/Store'

const FormListTable = () => {
  const dispatch = useDispatch()

  const { forms, pagination, loading } = useSelector(state => state.FormList)
  const { language, locale, portalTimezone } = useSelector(state => state.Root.user)

  const trans = translate(translation)(language)
  const formatLocaleDateTime = formatDateTime(locale, portalTimezone)

  const onSearchInputChange = e => {
    e.preventDefault()

    dispatch(searchFormList(e.target.value))
  }

  const onPageClick = (currentPage, page) => {
    if (currentPage !== page) {
      dispatch(changePageFormList(page))
    }
  }

  const onLimitChange = limit => {
    dispatch(changeLimitFormList(limit))
  }

  const onDeleteClick = formId => () => {
    dispatch(setFormToDelete(formId))
  }

  const getFormsAsRows = () =>
    forms.map((form, i) => [
      <Link key={i} to={ `/form/${ form.id }` } className={'text-bold'}>{ form.label }</Link>,
      <Link key={i} to={ `/form/${ form.id }` } className={'text-bold'}>{ form.systemName }</Link>,
      formatLocaleDateTime(form.lastModifiedAt),
      <ActionCell key={ i }
                  id={ form.id }
                  isLocked={ form.locked }
                  lockedBy={ form.lockedBy }
                  onDeleteClick={ onDeleteClick(form.id) }
                  trans={ trans }
      />
    ])

  const columnHeaders = [
    trans('label'),
    trans('systemName'),
    trans('th.lastModifiedAt'),
    trans('actions')
  ]

  return <NavTable pagination={ pagination }
                   rows={ getFormsAsRows() }
                   columnHeaders={ columnHeaders }
                   onSearchInputChange={ onSearchInputChange }
                   onLimitChange={ onLimitChange }
                   onPageClick={ onPageClick }
                   isLoading={ loading }
  />
}

export const ActionCell = ({
  id,
  onDeleteClick = () => null,
  isLocked = false,
  lockedBy = 'unknown',
  trans
}) => {
  const dispatch = useDispatch()

  const [ isLockedByCurrentUser, setIsLockedByCurrentUser ] = useState(false)

  const { username } = useSelector(state => state.Root.user)

  const onLockIndicatorClick = () => {
    if (!isLocked) return

    if (confirm(trans('modal.confirmForm.title'))) {
      dispatch(unlockForm({ id, isLocked }))
      setIsLockedByCurrentUser(false)
    }
  }

  const isSameUserLockedBy = () => {
    if (isLocked && username !== lockedBy)
      setIsLockedByCurrentUser(true)
  }

  const lockForLinkByAttr = e => {
    if (!isLockedByCurrentUser)
      return

    e.preventDefault()
    e.stopPropagation()
    e.target.href = 'javascript:void(0)'
  }

  useEffect(() => {
    isSameUserLockedBy()
  }, [])

  return (
    <>
      <LockIndicator onClick={ onLockIndicatorClick }
                     className={ `${ isLocked ? '' : 'unlock' }` }
                     title={ `Form ${ isLocked ? 'locked by ' + lockedBy : 'unlock' }` }
      >
        <i className={ `me-1 fas fa-${ isLocked ? 'lock locked' : 'unlock' }` }/>
      </LockIndicator>

      <ButtonGroup>
        <Button size="sm">
          <Link to={ isLockedByCurrentUser ? '' : `/form/${ id }` }
                onClick={lockForLinkByAttr}
                className={'text-white'}
          >
            <i className="me-1 fas fa-edit"/>
            <span>{ trans('edit') }</span>
          </Link>
        </Button>

        <Button size="sm" variant="danger"
                onClick={ onDeleteClick }
                disabled={ isLockedByCurrentUser }
        >
          <i className="me-1 fas fa-trash"/>
          <span>{ trans('delete') }</span>
        </Button>

        <Dropdown as={ ButtonGroup }>
          <Dropdown.Toggle disabled={ isLockedByCurrentUser }>{ trans('options') }</Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item as={ Link } to={ `/system_fields/manage_system_fields/${ id }` }>
              { trans('systemFields') }
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </ButtonGroup>
    </>
  )
}

export default FormListTable

const LockIndicator = styled.span`
  font-size: 1.5em;
  float: right;
  color: ${ props => props.theme.colors.secondary.dark };
  opacity: 0.35;
  cursor: pointer;

  &.unlock {
    cursor: auto;
  }

  i {
    &.locked {
      color: red;
    }
  }
`
