import {
  ITEMS_RECEIVED,
  ITEM_CREATED,
  ITEM_DELETED,
  ITEM_UPDATED,
  ITEM_RECEIVED,
  FETCH_ITEMS,
  FETCH_ITEM,
  CREATE_ITEM, SET_DELETING_ITEM, SET_UPDATING_ITEM, SET_CREATING_ITEM,
  DELETE_ITEM, UPDATE_ITEM, SET_LIMIT, SET_PAGE, SET_QUERY, SEND_TEST_EMAIL
} from './actionTypes'
import MessageConfig from 'src/Types/MessageConfig'
import Pagination from 'src/Types/Pagination'

export const fetchItems = () => ({
  type: FETCH_ITEMS
})
export const fetchItem = (id: string) => ({
  type: FETCH_ITEM,
  payload: id
})
export const createItem = () => ({
  type: CREATE_ITEM
})
export const deleteItem = () => ({
  type: DELETE_ITEM
})
export const updateItem = () => ({
  type: UPDATE_ITEM
})

export const itemsReceived = (items: MessageConfig[], pagination: Pagination) => ({
  type: ITEMS_RECEIVED,
  payload: { items, pagination }
})
export const itemReceived = (item: MessageConfig) => ({
  type: ITEM_RECEIVED,
  payload: item
})
export const itemCreated = (item: MessageConfig) => ({
  type: ITEM_CREATED,
  payload: item
})
export const itemDeleted = (id: string) => ({
  type: ITEM_DELETED,
  payload: id
})
export const itemUpdated = (item: MessageConfig) => ({
  type: ITEM_UPDATED,
  payload: item
})

export const setDeletingItem = (item: MessageConfig) => ({
  type: SET_DELETING_ITEM,
  payload: item
})
export const setUpdatingItem = (item: MessageConfig) => ({
  type: SET_UPDATING_ITEM,
  payload: item
})
export const setCreatingItem = (item: MessageConfig) => ({
  type: SET_CREATING_ITEM,
  payload: item
})

export const setQuery = (query: string) => ({
  type: SET_QUERY,
  payload: query
})
export const setLimit = (limit: number) => ({
  type: SET_LIMIT,
  payload: limit
})
export const setPage = (page: number) => ({
  type: SET_PAGE,
  payload: page
})

export const sendTestEmail = (item: MessageConfig) => ({
  type: SEND_TEST_EMAIL,
  payload: item
})
