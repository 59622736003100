import React from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { setFilterBeingRemoved } from '../state/actions'
import ActionsWrapper from 'src/Components/StyledComponents/ActionsWrapper'
import BlocItem from 'src/Components/StyledComponents/BlocItem'
import BlocItemContent from 'src/Components/StyledComponents/BlocItemContent'
import FieldType from 'src/Components/StyledComponents/FieldType'
import ListCell from 'src/Components/ListCell/index.tsx'

const Filter = ({ filter }) => {
  const dispatch = useDispatch()

  const onDeleteClick = filterId => () => dispatch(setFilterBeingRemoved(filterId))

  return <BlocItemWOCursor>
    <FieldTypeWithoutMargin>
      <i className={ filter.field?.type?.icon }/>
    </FieldTypeWithoutMargin>
    <FlexBlocItemContent>
      <FilterInfo>{ filter.alias }</FilterInfo>
      <FilterOperatorWrapper>
        <FilterInfoOperator>{ filter.operator }</FilterInfoOperator>
      </FilterOperatorWrapper>
      <FilterInfo><ListCell field={filter.field} value={ filter.value } /></FilterInfo>
    </FlexBlocItemContent>
    <ActionsWrapper>
      <i className="icon fas fa-trash"
         title="Delete"
         tabIndex="0"
         onClick={ onDeleteClick(filter.id) }
         onKeyDown={ e => e.key === 'Enter' && onDeleteClick(filter.id)(e) }
      />
    </ActionsWrapper>
  </BlocItemWOCursor>
}

export default Filter

const BlocItemWOCursor = styled(BlocItem)`
  cursor: inherit;

  &:hover {
    box-shadow: 0 0 5px 0 rgba(25, 25, 25, 0.18);
  }
`
const FlexBlocItemContent = styled(BlocItemContent)`
  display: flex;
  flex-flow: row nowrap;
`
const FieldTypeWithoutMargin = styled(FieldType)`
  margin-right: 0;
`
const FilterInfo = styled.span`
  display: inline-block;
  margin: 0 2em;
  width: 10em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const FilterInfoOperator = styled.span`
  background-color: ${ props => props.theme.colors.secondary.light };
  color: ${ props => props.theme.colors.secondary.median };
  border-radius: 50px;
  padding: 0.5em 1em;
  font-weight: bold;
`
const FilterOperatorWrapper = styled.span`
  display: inline-block;
  width: 10em;
`
