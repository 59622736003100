export const CREATE_TRIGGER ='[WORKFLOW_MANAGER] CREATE_TRIGGER'
export const DELETE_TRIGGER ='[WORKFLOW_MANAGER] DELETE_TRIGGER'
export const UPDATE_TRIGGER ='[WORKFLOW_MANAGER] UPDATE_TRIGGER'

export const TRIGGER_RECEIVED ='[WORKFLOW_MANAGER] TRIGGER_RECEIVED'
export const TRIGGER_CREATED ='[WORKFLOW_MANAGER] TRIGGER_CREATED'
export const TRIGGER_DELETED ='[WORKFLOW_MANAGER] TRIGGER_DELETED'
export const TRIGGER_UPDATED ='[WORKFLOW_MANAGER] TRIGGER_UPDATED'

export const SET_DELETING_TRIGGER ='[WORKFLOW_MANAGER] SET_DELETING_TRIGGER'
export const SET_UPDATING_TRIGGER ='[WORKFLOW_MANAGER] SET_UPDATING_TRIGGER'
export const TOGGLE_CREATE_TRIGGER_MODAL ='[WORKFLOW_MANAGER] TOGGLE_CREATE_TRIGGER_MODAL'
