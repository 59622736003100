
export const NAME = 'PERSON'
export const FIRST_NAME = `FIRST_NAME#${NAME}`
export const LAST_NAME = `LAST_NAME#${NAME}`
// Not in starter
export const CREDENTIALS = `CREDENTIALS#${NAME}`

// Temp TODO: return label and system name from api
export const CREDENTIALS_OPTIONS = {
    [`MD#${CREDENTIALS}`]: 'MD',
    [`MD_PHD#${CREDENTIALS}`]: 'MD, PhD',
    [`DO#${CREDENTIALS}`]: 'DO',
    [`MBBS#${CREDENTIALS}`]: 'MBBS',
    [`PA#${CREDENTIALS}`]: 'PA',
    [`CNP#${CREDENTIALS}`]: 'CNP',
    [`R_EEG_T#${CREDENTIALS}`]: 'R. EEG. T.',
    [`R_EEG_T_CLTM#${CREDENTIALS}`]: 'R. EEG T., CLTM',
    [`RPSGT#${CREDENTIALS}`]: 'RPSGT',
    [`RRT#${CREDENTIALS}`]: 'RRT',
    [`CRT#${CREDENTIALS}`]: 'CRT',
}
