import React  from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'src/Components/Modal'
import SlotSerieForm from 'src/Components/Calendar/Components/SlotSerieForm'
import { setSlotFormModal } from 'src/Components/Calendar/state/actions'
import { StoreState } from 'src/Services/Store/reducers'

const SlotSerieFormModal = () => {
  const dispatch = useDispatch()

  const { language } = useSelector((state: StoreState) => state.Root.user)
  const { slotType, isModalOpen: isOpen } = useSelector((state: StoreState) => state.Calendar.slotForm)

  const handleClose = () => dispatch(setSlotFormModal(false, slotType))

  return <Modal title={`Create ${slotType?.label || slotType?.systemName} slot`}
                isOpen={isOpen}
                onClose={handleClose}
                size="lg"
  >
      <SlotSerieForm />
    </Modal>
}

export default SlotSerieFormModal
