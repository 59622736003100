import * as types from './actionTypes'

const initialState = {
  patient: null,
  isOpen: false,
  similarPatients: [],
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.REQUEST_PATIENT_CREATION:
      return {
        ...state,
        isOpen: true,
        patient: payload.patient
      }
    case types.RESET_PATIENT_CREATION:
      return {
        ...state,
        isOpen: false,
        patient: initialState.patient,
        similarPatients: initialState.similarPatients,
      }
    case types.SET_SIMILAR_PATIENTS:
      return {
        ...state,
        similarPatients: payload.patients
      }
    default:
      return state
  }
}
