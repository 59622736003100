import {
  LOAD_LISTS,
  RECEIVE_LISTS,
  SET_SELECTED_TYPE,
  SET_SELECTED_LIST_FORM,
  CHANGE_LIMIT,
  CHANGE_PAGE,
  TOGGLE_ADDING_LIST,
  NEW_LIST_ADDED,
  DELETE_LIST,
  ERROR_DELETING_LIST,
  LIST_DELETED,
  SEARCH_LIST, REQUEST_CLONE_LIST
} from './actionTypes'

export const initialState = {
  loading: false,
  addingList: false,
  errorsDeletingList: {},
  selectedListType: null,
  selectedListForm: null,
  lists: [],
  cloningList: null,
  pagination: {
    offset: 0,
    limit: 10,
    itemsCount: 10,
    totalItemsCount: 10,
    pageLimit: 10,
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPagesCount: 1,
  },
  searchQuery: '',
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_LISTS:
      return {
        ...state,
        loading: true
      }
    case RECEIVE_LISTS: {
      const { lists, pagination } = payload

      return {
        ...state,
        loading: false,
        lists,
        pagination
      }
    }
    case CHANGE_LIMIT:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          pageLimit: payload,
          currentPage: 1
        }
      }
    case CHANGE_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: payload
        }
      }
    case TOGGLE_ADDING_LIST:
      return {
        ...state,
        addingList: payload
      }
    case SET_SELECTED_TYPE:
      return {
        ...state,
        selectedListType: payload
      }
    case SET_SELECTED_LIST_FORM:
      return {
        ...state,
        selectedListForm: payload
      }
    case NEW_LIST_ADDED:
      return {
        ...state,
        selectedListForm: null,
        selectedListType: null,
        cloningList: null,
      }
    case SEARCH_LIST:
      return {
        ...state,
        loading: true,
        searchQuery: payload,
        pagination: {
          ...state.pagination,
          currentPage: initialState.pagination.currentPage,
          offset: initialState.pagination.offset,
        }
      }
    case DELETE_LIST:
      return {
        ...state,
        errorsDeletingList: {}
      }
    case ERROR_DELETING_LIST:
      return {
        ...state,
        errorsDeletingList: payload
      }
    case LIST_DELETED:
      return {
        ...state,
        errorsDeletingList: {}
      }
    case REQUEST_CLONE_LIST:
      return {
        ...state,
        selectedListType: payload.list?.type,
        selectedListForm: payload.list?.form,
        cloningList: payload.list ?
          { ...payload.list, label: payload.list.label + ' Cloned', systemName: null } : null
      }
    default:
      return state
  }
}
