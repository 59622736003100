export const FETCH_ITEMS = '[TRANSLATIONS] FETCH_ITEMS'
export const FETCH_ITEM = '[TRANSLATIONS] FETCH_ITEM'
export const CREATE_ITEM = '[TRANSLATIONS] CREATE_ITEM'
export const DELETE_ITEM = '[TRANSLATIONS] DELETE_ITEM'
export const UPDATE_ITEM = '[TRANSLATIONS] UPDATE_ITEM'

export const ITEMS_RECEIVED = '[TRANSLATIONS] ITEMS_RECEIVED'
export const ITEM_RECEIVED = '[TRANSLATIONS] ITEM_RECEIVED'
export const ITEM_CREATED = '[TRANSLATIONS] ITEM_CREATED'
export const ITEM_DELETED = '[TRANSLATIONS] ITEM_DELETED'
export const ITEM_UPDATED = '[TRANSLATIONS] ITEM_UPDATED'

export const SET_DELETING_ITEM = '[TRANSLATIONS] SET_DELETING_ITEM'
export const SET_UPDATING_ITEM = '[TRANSLATIONS] SET_UPDATING_ITEM'
export const SET_CREATING_ITEM = '[TRANSLATIONS] SET_CREATING_ITEM'

export const SET_QUERY = '[TRANSLATIONS] SET_QUERY'
export const SET_LIMIT = '[TRANSLATIONS] SET_LIMIT'
export const SET_PAGE = '[TRANSLATIONS] SET_PAGE'
