// todo : Will probably needs refacto + translation support
export const months = [
  {
    value: 0,
    title: 'Jan',
    translation: 'month.abbr.jan'
  },
  {
    value: 1,
    title: 'Feb',
    translation: 'month.abbr.feb'
  },
  {
    value: 2,
    title: 'Mar',
    translation: 'month.abbr.mar'
  },
  {
    value: 3,
    title: 'Apr',
    translation: 'month.abbr.apr'
  },
  {
    value: 4,
    title: 'May',
    translation: 'month.abbr.may'
  },
  {
    value: 5,
    title: 'Jun',
    translation: 'month.abbr.jun'
  },
  {
    value: 6,
    title: 'Jul',
    translation: 'month.abbr.jul'
  },
  {
    value: 7,
    title: 'Aug',
    translation: 'month.abbr.aug'
  },
  {
    value: 8,
    title: 'Sep',
    translation: 'month.abbr.sep'
  },
  {
    value: 9,
    title: 'Oct',
    translation: 'month.abbr.oct'
  },
  {
    value: 10,
    title: 'Nov',
    translation: 'month.abbr.nov'
  },
  {
    value: 11,
    title: 'Dec',
    translation: 'month.abbr.dec'
  }
]

export const years = [
  2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016,
  2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026
]
