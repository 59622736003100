import { put, takeLatest, takeEvery, select, call } from 'redux-saga/effects'
import apiMethods from 'src/Services/api/apiMethods'
import { contentRangeToPagination } from 'src/Utils/EffectsUtil.ts'
import * as types from './actionTypes'
import { fetchData, setDetails, setData, setIsTableLoading, exportDone } from './actions'

const formatPagination = (pagination, rows) => {
  const { totalItemsCount } = pagination

  return {
    // temporarily disabled to prevent bug on search reset
    // offset: from === 0 ? 0 : from - 1,
    // limit: to,
    itemsCount: rows.length,
    totalItemsCount,
  }
}

function* fetchRows(props, { payload: { tableId } }) {
  const state = yield select()
  const table = state.ListResultTables.tables.find(t => t.id === tableId)

  if (!table?.listId)
    return

  yield put(setIsTableLoading(tableId, true))

  try {
    const params = {
      offset: table.pagination.offset,
      limit: table.pagination.limit,
      sortItems: table.sortItems,
      filters: table.filters
    }

    const { data, headers } = yield call(apiMethods.create, `/lists/${ table.listId }/run`, { data: params })
    const pagination = contentRangeToPagination(headers['content-range'])

    yield put(setData(tableId, data, formatPagination(pagination, data)))
    yield put(setIsTableLoading(tableId, false))
  } catch (error) {
    yield put(setIsTableLoading(tableId, false))
    yield put(props.globalActions.handleError(error))
  }
}

function* initializeTable(props, { payload: { tableId, listId } }) {
  const state = yield select()
  const table = state.ListResultTables.tables.find(t => t.id === tableId)
  const { autoRefresh } = state.Root

  if (table?.isLoading || ((!table?.listId || table?.columns?.length) && !autoRefresh)) {
    return
  }

  yield put(setIsTableLoading(tableId, true))

  try {
    const { data } = yield call(apiMethods.get, `/lists/${ listId }`)
    const columns = data.listColumns

    delete data.listColumns

    yield put(setDetails(tableId, data, columns))

    yield put(fetchData(tableId))
  } catch (error) {
    yield put(setIsTableLoading(tableId, false))
    yield put(props.globalActions.handleError(error))
  }
}


function* requestExport(props, { payload: { tableId } }) {
  const state = yield select()
  const table = state.ListResultTables.tables.find(t => t.id === tableId)

  try {
    // 100 000 : safe limit to avoid excesses. Value determined without much investigation.
    const params = {
      offset: 0,
      limit: 100000,
      sortItems: table.sortItems,
      filters: table.filters
    }

    yield call(
      apiMethods.downloadBlobFile,
      'post',
      `/lists/${ table.listId }/convert`,
      { data: params },
      {},
      `${ table.list.systemName }.csv`
    )
    yield put(exportDone())
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'actionFailed'))
  }
}

export default function* listResultTableSaga(props) {
  yield takeEvery(types.INITIALIZE_TABLE, initializeTable, props)
  yield takeEvery(types.FETCH_DATA, fetchRows, props)
  yield takeLatest(types.CHANGE_PAGINATION, fetchRows, props)
  yield takeLatest(types.SORT, fetchRows, props)
  yield takeLatest(types.REQUEST_EXPORT, requestExport, props)
}
