import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tabs, Tab } from 'react-bootstrap'
import Tasks from '../Tasks'
import { setOptions } from 'src/Layouts/View/state/actions'
import { translateConf } from '../../Services/translation'
import { MODULE_MY_TASKS } from 'src/Services/Constants/Config/Modules'
import LandingPage from './Components/LandingPage'

const Homepage = () => {
  const { landingPages, modules } = useSelector((state) => state.Root.user)
  const { configTranslations } = useSelector((state) => state.Root)

  const transConf = translateConf(configTranslations)

  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(setOptions({
      title: null,
      hasPrimaryButton: false
    }))
  }, [ dispatch ])

  return <React.Fragment>
    { modules.some(m => m.systemName === MODULE_MY_TASKS) && <Tasks/> }

    { !landingPages || landingPages.length === 0
      ? <></>
      : landingPages.length === 1
        ? <LandingPage landingPage={ landingPages[0] } />
        : <Tabs id={'landingPageTabs'} defaultActiveKey={ landingPages[0].id } variant={'pills'} fill>
          { landingPages.map(landingPage =>
            <Tab key={ landingPage.id } eventKey={ landingPage.id } title={ transConf('LANDING_PAGE')(landingPage) }>
              <div className={'mt-3'}>
                <LandingPage landingPage={ landingPage }/>
              </div>
            </Tab>) }
        </Tabs> }
  </React.Fragment>
}

export default Homepage
