
export default {
  english: {
    title: 'Icons manager',
    addNew: 'Add',
    entries: 'of {total} entries',
    form: {
      label: 'Label',
      systemName: 'System name',
      type: 'Type',
      content: 'Content',
      hostName: 'Host name'
    },
    column: {
      label: 'Label',
      systemName: 'System name',
      type: 'Type',
      content: 'Content',
      hostName: 'Host name'
    },
    modal: {
      create: {
        title: 'Create a new icon'
      },
      update: {
        title: 'Update a icon'
      },
      confirmDelete: {
        title: 'Are you sure you want to delete this icon ?'
      }
    }
  },
  french: {
    title: 'Gestionnaire d\'icônes',
    addNew: 'Créer',
    entries: 'sur {total} entrées',
    form: {
      label: 'Label',
      systemName: 'Nom système',
      type: 'Type',
      content: 'Contenu',
      hostName: 'Nom d\'hébergeur'
    },
    column: {
      label: 'Label',
      systemName: 'Nom système',
      type: 'Type',
      content: 'Contenu',
      hostName: 'Nom d\'hébergeur'
    },
    modal: {
      create: {
        title: 'Créer une nouvelle icône'
      },
      update: {
        title: 'Modifier une icône'
      },
      confirmDelete: {
        title: 'Êtes-vous sûr de vouloir supprimer cette icône ?'
      }
    }
  }
}
