import React from 'react'
import { Pagination } from 'react-bootstrap'
import { range } from 'ramda'

const getPagesToDisplay = (pageCount, currentPage) => {
  if (pageCount < 11)
    return range(1, pageCount + 1)

  const aroundCurrentPage = [
    currentPage - 2,
    currentPage - 1,
    currentPage,
    currentPage + 1,
    currentPage + 2
  ]

  const withoutOutOfRange = aroundCurrentPage.filter(page => page > 0 && page <= pageCount)

  let result = [ ...withoutOutOfRange ]

  if (withoutOutOfRange[0] > 1)
    result = [ 1, '...', ...result ]

  if (withoutOutOfRange[withoutOutOfRange.length - 1] < pageCount)
    result = [ ...result, '...', pageCount ]

  return result
}

const AdvancedPagination = ({ table }) => {
  const getCurrentPage = () => table.getState().pagination.pageIndex

  return <Pagination className={'m-0'}>
    <Pagination.First disabled={ !table.getCanPreviousPage() } onClick={ e => table.setPageIndex(0) }/>
    <Pagination.Prev disabled={ !table.getCanPreviousPage() } onClick={ e => table.previousPage() }/>

    { getPagesToDisplay(table.getPageCount(), getCurrentPage() + 1).map((page, index) => {
      if (page === '...')
        return <Pagination.Ellipsis key={ index }>...</Pagination.Ellipsis>

      return <Pagination.Item className={ `${ getCurrentPage() + 1 === page ? 'active' : '' }` }
                              key={ index }
                              onClick={ e => table.setPageIndex(page - 1) }
      >{ page }</Pagination.Item>
    }) }

    <Pagination.Next disabled={ !table.getCanNextPage() } onClick={ e => table.nextPage() }/>
    <Pagination.Last disabled={ !table.getCanNextPage() }
                     onClick={ e => table.setPageIndex(table.getPageCount()) }/>
  </Pagination>
}

export default AdvancedPagination
