import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { translate, translateConf } from 'src/Services/translation'
import translation from 'src/Views/Lists/translations'
import ListCell from 'src/Components/ListCell'
import { FIELD_BASE_TYPE_DATE_TIME, FIELD_BASE_TYPES_WITH_OPTIONS } from 'src/Services/Constants'
import { ColumnValueType } from 'src/Types/RequestFilter'
import { FieldOption } from 'src/Types/FieldOption'
import { SortItemDirection } from 'src/Types/Pagination'
import { changePagination, initializeTable, filter, sort, requestExport } from './state/actions'
import AdvancedTable from '../AdvancedTable/index'

const ListResultTable = React.forwardRef(({ id, listId }, ref) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { user, autoRefresh, configTranslations } = useSelector(state => state.Root)
  const table = useSelector(state => state.ListResultTables.tables.find(t => t.id === id))

  const trans = translate(translation)(user.language)
  const transConf = translateConf(configTranslations)

  React.useImperativeHandle(ref, () => ({
    requestExport() {
      dispatch(requestExport(id))
    }
  }))

  const getRows = () => table?.rows
    ? table.rows.map(row => ({ ...row, ...row.values }))
    : []

  useEffect(() => {
    // Do refresh only if table is already fetch (else second useEffect will do it)
    if (autoRefresh && table)
      dispatch(initializeTable(id, listId))
  }, [ autoRefresh ])

  useEffect(() => {
    if (!table && !table?.isLoading)
      dispatch(initializeTable(id, listId))
  }, [ id, listId ])

  // Indicator defined only if the table details as been fetched
  if (table?.isLoading && !table?.list?.form?.id)
    return <div className={ 'd-flex' }><Spinner animation={ 'border' } className={ 'm-auto' } size={ 'md' }/></div>
  else if (!table?.list)
    return <>{ trans('notFound') }</>

  const getColumnHeaders = () => table.columns.sort((a, b) => a.sortOrder > b.sortOrder)

  const handleRowClick = row => {
    if (row.patient?.id && row.id) {
      navigate(`/patient/${ row.patient.id }/instance/${ row.id }`)
      return
    }
    row.id && navigate(`/instance/${ row.id }`)
  }

  const getPagination = () => ({ ...table.pagination, ...table.paginationDetails })

  const onPaginationChange = (currentPage, pageSize, forceUpdate = false) => {
    const offset = (currentPage - 1) * pageSize
    const limit = currentPage * pageSize

    const hasOffsetChanged = offset !== getPagination().offset
    const hasLimitChanged = limit !== getPagination().limit &&
      (limit < getPagination().totalItemsCount || getPagination().limit < getPagination().totalItemsCount)

    if (hasOffsetChanged || hasLimitChanged || forceUpdate)
      dispatch(changePagination(id, offset, limit))
  }

  const onSortingChange = sortItems => {
    if (JSON.stringify(table.sortItems) === JSON.stringify(sortItems))
      return

    dispatch(sort(id, sortItems))
  }

  const getColumnValueType = column => {
    if (FIELD_BASE_TYPES_WITH_OPTIONS.includes(column.field.type.baseFieldType))
      return ColumnValueType.OPTION
    else if (column.field.type.baseFieldType === FIELD_BASE_TYPE_DATE_TIME)
      return ColumnValueType.DATE
    else
      return ColumnValueType.STRING
  }

  const getColumn = column => ({
    id: column.systemName,
    accessorKey: column.systemName,
    enableColumnFilter: column.field?.isFilterable || false,
    enableSorting: column.field?.isSortable || false,
    header: () => transConf('LIST_COLUMN')(column),
    showTimeOnly: !column.field.options[FieldOption.SHOW_DATE] && column.field.options[FieldOption.SHOW_TIME],
    showDateOnly: column.field.options[FieldOption.SHOW_DATE] && !column.field.options[FieldOption.SHOW_TIME],
    options: column.field.options[FieldOption.VALUES]
      ?.map(v => ({ value: v.systemName, label: v.label || v.systemName })) || null,
    valueType: getColumnValueType(column),
    cell: ({ column: { columnDef }, getValue }) => <ListCell value={ getValue() } field={ columnDef.data.field }/>,
    data: { field: column.field }
  })

  return <>
    <AdvancedTable data={ getRows() }
                   columns={ getColumnHeaders() }
                   filters={ table.filters }
                   totalRowsCount={ getPagination().totalItemsCount }
                   onSort={ onSortingChange }
                   onFilter={ filters => dispatch(filter(id, filters)) }
                   onPaginationChange={ onPaginationChange }
                   isFilterable
                   onRowClick={ handleRowClick }
                   getColumn={ getColumn }
                   defaultSortItems={ [ { column: 'createdAt', direction: SortItemDirection.DESC } ] }
    />
  </>
})
ListResultTable.displayName = 'ListResultTable'

export default ListResultTable
