import { Link } from 'react-router-dom'
import styled from 'styled-components'

export default styled.div`
  i {
    cursor: pointer;
    color: #b3b3b3;

    :hover, :focus {
      color: black;
    }
  }
`

export const BoldLink = styled(Link)`
  color: inherit;
  font-weight: 900;
  font-size: 1em;

  &:hover {
    color: inherit;
  }
`
