
export default {
  english: {
    title: 'Tasks',
    listing_title: 'All Tasks remaining',
    subPart: {
      Everybody: {
        title: 'Everybody Tasks',
      },
      MyTasks: {
        title: 'My Tasks',
      },
    },
    addNew: ' Add a new task',
    seeAll: ' See all tasks',
    seeMy: ' See my tasks',
    modal: {
      create: {
        title: 'Add a new Task'
      },
      update: {
        title: 'Edit this task'
      },
      delete: {
        title: 'Delete this task'
      }
    },
    form: {
      name: 'Name',
      description: 'Description',
      note: 'Notes',
      taskType: 'Task type',
      defaultTaskType: 'None type selected',
      taskCategory: 'Task Category',
      defaultTaskCategory: 'None Category selected',
      taskStatus: 'Task Status',
      defaultTaskStatus: 'None Status selected',
      dueAt: 'Due at',
      dateCompleted: 'Date Completed',
      region: 'Region',
      defaultRegion: 'None region selected',
      assignTo: {
        label: 'Assign To',
        ROLES: 'Roles',
        USERS: 'Users'
      },
      defaultAssignTo: 'No one assigned',
      roles: 'Roles',
      defaultRoles: 'No role selected',
      submit: 'Save'
    },
    call: {
      createFailed: 'Error with the form fields sent'
    },
    taskTypes: {
      1: 'Phone Call',
      2: 'Robocall',
      3: 'PaperWork',
      4: 'Fax Document',
    }
  },
  french: {
    title: 'Catégories de Tâche',
    listing_title: 'Toutes les tâches restantes',
    subPart: {
      Everybody: {
        title: 'Tâches globales',
      },
      MyTasks: {
        title: 'Mes tâches',
      },
    },
    addNew: 'Ajouter un nouveau statut',
    seeAll: ' Voir toutes les tâches',
    seeMy: ' Voir toutes mes tâches',
    modal: {
      create: {
        title: 'Ajouter une nouvelle tâche'
      },
      update: {
        title: 'Editer cette tâche'
      },
      delete: {
        title: 'Supprimer cette tâche'
      }
    },
    form: {
      name: 'Nom',
      description: 'Description',
      note: 'Remarques',
      taskType: 'Type de la tâche',
      defaultTaskType: 'Aucun type sélectionné',
      taskCategory: 'Catégorie de la tâche',
      defaultTaskCategory: 'Aucune catégorie sélectionnée',
      taskStatus: 'Statut de la tâche',
      defaultTaskStatus: 'Aucune statut sélectionné',
      dueAt: 'Due pour le',
      dateCompleted: 'Complétée le',
      region: 'Région',
      defaultRegion: 'Aucune région sélectionnée',
      assignTo: {
        label: 'Assigner à',
        ROLES: 'Rôles',
        USERS: 'Utilisateurs'
      },
      defaultAssignTo: 'Aucune assignation',
      roles: 'Rôles',
      defaultRoles: 'Aucun rôle défini',
      submit: 'Valider'
    }
  }
}
