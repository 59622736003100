import {
  FETCH_DELETED_FIELDS,
  RECIEVE_DELETED_FIELDS,
  CHANGE_PAGE,
  CHANGE_LIMIT,
  FETCH_RESTORE_FIELD,
  SEARCH_DELETED_FIELD
} from './actionTypes'
export const receiveDeletedFields = (data) => ({
  type: RECIEVE_DELETED_FIELDS,
  payload: data
})
export const fetchDeletedFields = (data) => ({
  type: FETCH_DELETED_FIELDS,
  payload: data
})
export const fetchRestoreField = (data) => ({
  type: FETCH_RESTORE_FIELD,
  payload: data
})
export const changePage = (data) => ({
  type: CHANGE_PAGE,
  payload: data
})
export const searchDeletedField = (data) => ({
  type: SEARCH_DELETED_FIELD,
  payload: data
})
export const changeLimitRestoreDeletedFields = (data) => ({
  type: CHANGE_LIMIT,
  payload: data
})