import React, { useEffect } from 'react'
import {
  fetchFieldTypes,
  fetchBaseFieldTypes,
  toggleCreateFieldType,
  createFieldType,
  toggleDeleteFieldType, updateFieldType, deleteFieldType, toggleUpdateFieldType
} from './state/actions'
import { translate } from '../../Services/translation'
import translation from './translations'
import { useDispatch, useSelector } from 'react-redux'
import FieldTypesTable from './Components/FieldTypesTable'
import FieldTypeForm from './Components/FieldTypeForm'
import Modal from '../../Components/Modal'
import ConfirmModal from '../../Components/ConfirmModal'
import { setOptions } from '../../Layouts/View/state/actions'
import { Container } from 'react-bootstrap'

const FieldTypes = () => {
  const dispatch = useDispatch()
  const { language } = useSelector((state) => state.Root?.user)
  const {
    isCreatingFieldType,
    isDeletingFieldType,
    baseFieldTypes,
    isUpdatingFieldType,
    fieldTypeOnUpdate,
    fieldTypes
  } = useSelector((state) => state.FieldTypes)

  const onCreateClick = () => dispatch(toggleCreateFieldType())

  const onModalCreateClose = () => dispatch(toggleCreateFieldType())
  const onModalCreateFormSubmit = data => dispatch(createFieldType(data))

  const onModalUpdateClose = () => dispatch(toggleUpdateFieldType(null))
  const onModalUpdateFormSubmit = data => dispatch(updateFieldType(data))

  const onConfirmDeleteModalClose = () => dispatch(toggleDeleteFieldType(null))
  const onConfirmDeleteModalConfirm = () => dispatch(deleteFieldType())

  useEffect(() => {
    dispatch(setOptions({
      hasPrimaryButton: true,
      onPrimaryButtonClick: onCreateClick
    }))
    if (!fieldTypes?.length)
      dispatch(fetchFieldTypes())

    if (!baseFieldTypes?.length)
      dispatch(fetchBaseFieldTypes())
  }, [ dispatch ])

  const trans = translate(translation)(language)

  return (
    <Container>
      <FieldTypesTable/>
      <Modal isOpen={isCreatingFieldType} title={trans('modal.create.title')} onClose={onModalCreateClose}>
        <FieldTypeForm trans={trans}
                       baseFieldTypes={baseFieldTypes}
                       onSubmit={onModalCreateFormSubmit}
        />
      </Modal>
      <Modal isOpen={isUpdatingFieldType} title={trans('modal.update.title')} onClose={onModalUpdateClose}>
        <FieldTypeForm trans={trans}
                       baseFieldTypes={baseFieldTypes}
                       onSubmit={onModalUpdateFormSubmit}
                       fieldType={fieldTypeOnUpdate}
        />
      </Modal>
      <ConfirmModal title={trans('modal.confirmDeleteTitle')}
                    isOpen={isDeletingFieldType}
                    onClose={onConfirmDeleteModalClose}
                    onConfirm={onConfirmDeleteModalConfirm}
      />
    </Container>
  )
}

export default FieldTypes
