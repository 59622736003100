import {
  FETCH_DELETED_SECTIONS,
  RECIEVE_DELETED_SECTIONS,
  CHANGE_PAGE,
  CHANGE_LIMIT,
  FETCH_RESTORE_SECTION,
  SEARCH_DELETED_SECTION
} from './actionTypes'
export const receiveDeletedSections = (data) => ({
  type: RECIEVE_DELETED_SECTIONS,
  payload: data
})
export const fetchDeletedSections = (data) => ({
  type: FETCH_DELETED_SECTIONS,
  payload: data
})
export const fetchRestoreSection= (data) => ({
  type: FETCH_RESTORE_SECTION,
  payload: data
})
export const changePage = (data) => ({
  type: CHANGE_PAGE,
  payload: data
})
export const searchDeletedSection = (data) => ({
  type: SEARCH_DELETED_SECTION,
  payload: data
})
export const changeLimitRestoreDeletedSections = (data) => ({
  type: CHANGE_LIMIT,
  payload: data
})