import { call, put, select, takeLatest } from 'redux-saga/effects'
import * as types from './actionTypes'
import {
  itemUpdated, itemDeleted, itemCreated, itemsReceived, itemReceived
} from 'src/Views/MessageConfigs/state/actions'
import apiMethods from '../../../Services/api/apiMethods'
import { showSuccess } from 'src/Services/notifier/actions'
import { contentRangeToPagination } from 'src/Utils/EffectsUtil'
import { GlobalActions } from 'src/Types/GlobalActions'
import { Action, StoreState } from 'src/Services/Store/reducers'

function* sendTestEmail(props: GlobalActions, { payload }: Action) {
  try {
    const { id } = payload

    yield call(apiMethods.get, `/message_configs/${id}/test`)
    yield put(showSuccess('fetchSucceeded'))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'fetchFailed'))
  }
}

function* updateItem(props: GlobalActions, { payload }: Action) {
  try {
    const store: StoreState = (yield select())
    const { updatingItem: item } = store.MessageConfigs

    const id = item.id
    delete item.id

    const { data } = yield call(apiMethods.update, `/message_configs/${id}`, { data: item })
    yield put(itemUpdated(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'updateFailed'))
  }
}

function* deleteItem(props: GlobalActions, { payload }: Action) {
  try {
    const store: StoreState = (yield select())
    const { deletingItem: item } = store.MessageConfigs

    yield call(apiMethods.delete, `/message_configs/${item.id}`)
    yield put(itemDeleted(item.id))
  } catch (error) {
     yield put(props.globalActions.handleError(error, 'deleteFailed'))
  }
}

function* createItem(props: GlobalActions, { payload }: Action) {
  try {
    const store: StoreState = (yield select())
    const { creatingItem: item } = store.MessageConfigs

    const { data } = yield call(apiMethods.create, '/message_configs', { data: item })
    yield put(itemCreated(data))
  } catch (error) {
        yield put(props.globalActions.handleError(error, 'createFailed'))
  }
}

function* fetchItems(props: GlobalActions, { payload }: Action) {
  try {
    const store: StoreState = (yield select())
    const { query, pagination } = store.MessageConfigs

    const { data, headers } = yield call(apiMethods.get, '/message_configs', {
      query, ...pagination
    })
    yield put(itemsReceived(
      data,
      contentRangeToPagination(headers['content-range'], pagination)
    ))
  } catch (error) {
     yield put(props.globalActions.handleError(error, 'loadListFailed'))
  }
}

function* fetchItem(props: GlobalActions, { payload }: Action) {
  try {
    const { data } = yield call(apiMethods.get, `/message_configs/${payload}`)
    yield put(itemReceived(data))
  } catch (error) {
      yield put(props.globalActions.handleError(error, 'fetchFailed'))
  }
}

export default function* sagaWatcher(props: GlobalActions) {
  yield takeLatest(types.FETCH_ITEMS, fetchItems, props)
  yield takeLatest(types.FETCH_ITEM, fetchItem, props)
  yield takeLatest(types.CREATE_ITEM, createItem, props)
  yield takeLatest(types.DELETE_ITEM, deleteItem, props)
  yield takeLatest(types.UPDATE_ITEM, updateItem, props)
  yield takeLatest(types.SEND_TEST_EMAIL, sendTestEmail, props)
}
