import React from 'react'
import styled from 'styled-components'
import { Row as BRow } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getId } from 'src/Services/Selectors'
import RowHeader from './Header'
import Field from '../Field'
import { reorderRow, setDraggedRow } from '../../state/actions'

export const COLUMN_MAX = 3

const Row = ({
  trans,
  row,
  i,
  isDragging,
  sectionId,
  section
}) => {
  const dispatch = useDispatch()

  const { draggedRow } = useSelector(state => state.FormEditor.formReducer)

  const beingDragged = getId(draggedRow) === getId(row) && draggedRow?.section === sectionId
  const isEmpty = !row.fields.filter(f => f?.type !== null).length

  const getRowClasses =
    `p-2
    ${ beingDragged ? 'being-dragged' : '' }
    ${ isEmpty ? 'is-empty' : '' }
   `

  const getFields = () => {
    const getEmptyField = col => ({
      type: null,
      rowColumn: col,
      rowSize: 1,
      row: row,
      section: sectionId,
      sectionId: sectionId,
      options: {}
    })

    const results = []
    let col = 1

    while (col <= COLUMN_MAX) {

      let currentField = row.fields.find(f => f.rowColumn == col)

      if (!currentField)
        currentField = getEmptyField(col)

      results.push(currentField)
      col += currentField.rowSize
    }

    return results
  }

  // Using index because current sortOrder value is not trustable
  const onDragStart = e => dispatch(setDraggedRow(section, row, i + 1))
  const onDragOver = e => draggedRow && e.preventDefault()
  const onDrop = e => {
    if (draggedRow && getId(draggedRow) !== getId(row))
      dispatch(reorderRow(draggedRow, row, section, i + 1))
  }

  return (
    <FormRow className={ getRowClasses }
             draggable
             onDragStart={ onDragStart }
             onDragOver={ onDragOver }
             onDrop={ onDrop }
    >
      <RowHeader sectionId={ sectionId } row={ row } trans={ trans } />
      <BRow className={ 'px-1' }>
        { getFields().map((field, index) =>
          <Field key={ `field_placeholder_${ field.id || index }_${ index }` }
                 field={ field }
                 isDragging={ isDragging }
                 trans={ trans }
          />
        ) }
      </BRow>
    </FormRow>
  )
}

export const FormRow = styled.div`
  background-color: ${ props => props.theme.colors.white.darkest };
  border: 1px dashed ${ props => props.theme.colors.grey.alpha.lightest(0.35) };

  &.being-dragged {
    opacity: 0.3;
  }

  &.disable-children-pointer-events {
    & * {
      pointer-events: none;
    }
  }

  &.is-empty {
    border: 0;
    background-color: white;

    header {
      background-color: white;
    }
  }

  & + & {
    margin-top: 0.75em;
  }

  header .icon {
    color: ${ props => props.theme.colors.grey.lightest };

    &.is-active {
      color: ${ props => props.theme.colors.grey.dark };
    }
  }

  .columns {
    margin: 0;
    position: relative;
  }
`

export default Row
