export const CREATE_ACTION ='[WORKFLOW_MANAGER] CREATE_ACTION'
export const DELETE_ACTION ='[WORKFLOW_MANAGER] DELETE_ACTION'
export const UPDATE_ACTION ='[WORKFLOW_MANAGER] UPDATE_ACTION'
export const ACTION_RECEIVED ='[WORKFLOW_MANAGER] ACTION_RECEIVED'
export const ACTION_CREATED ='[WORKFLOW_MANAGER] ACTION_CREATED'
export const ACTION_DELETED ='[WORKFLOW_MANAGER] ACTION_DELETED'
export const ACTION_UPDATED ='[WORKFLOW_MANAGER] ACTION_UPDATED'

export const SET_DELETING_ACTION ='[WORKFLOW_MANAGER] SET_DELETING_ACTION'
export const SET_UPDATING_ACTION ='[WORKFLOW_MANAGER] SET_UPDATING_ACTION'
export const TOGGLE_CREATE_ACTION_MODAL ='[WORKFLOW_MANAGER] TOGGLE_CREATE_ACTION_MODAL'
