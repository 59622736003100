import React from 'react'
import { Dropdown, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { translateConf } from 'src/Services/translation'
import { NavigationItem } from 'src/Types/NavigationItem'
import { StoreState } from 'src/Services/Store/reducers'

const NavbarStart = () => {
  const { configTranslations } = useSelector((state: StoreState) => state.Root)
  const { navigationItems } = useSelector((state: StoreState) => state.Root.user)
  const transConf = translateConf(configTranslations)

  const sortNavigationItems = () =>
    navigationItems.filter(m => m.isActive).sort((a, b) => a.order > b.order ? 1 : -1)
  const getLink = (item: NavigationItem) => item.internalLink || item.externalLink || null
  const getChildren = (item: NavigationItem) => sortNavigationItems().filter(_ => _.parent?.id === item.id)

  const getNavbar = (isMobile = false) => sortNavigationItems()
    .filter(_ => !_.parent).map(item => getLink(item)
        ? <Nav.Link
            key={ item.id }
            className={ `${ !isMobile ? 'd-none d-xl-block' : '' }` }
            as={ Link }
            to={ getLink(item) }
          >
            { transConf('NAVIGATION_ITEM')(item) }
          </Nav.Link>
        : <Dropdown
            className={ `${ !isMobile ? 'd-none d-xl-block' : '' }` }
            id={ JSON.stringify(item.id) }
            key={ item.id }
          >
            <Dropdown.Toggle id={ `navbar-dropdown${ item.id }` } className="is-transparent" as={ Nav.Link }>
              { transConf('NAVIGATION_ITEM')(item) }
            </Dropdown.Toggle>
            <Dropdown.Menu className="bg-primary">
              { getChildren(item).map(item =>
                <Dropdown.Item key={ item.id } as="span">
                  <Nav.Link className={ 'p-0' } as={ Link } to={ getLink(item) }>
                    { transConf('NAVIGATION_ITEM')(item) }
                  </Nav.Link>
                </Dropdown.Item>
              ) }
            </Dropdown.Menu>
          </Dropdown>
    )

  return (
    <Nav className="me-auto navbar-start align-items-center justify-content-center">
      { getNavbar() }
      <Dropdown id="wrapper-navbar-dropdown" className="d-xl-none">
        <Dropdown.Toggle variant="out" id="navbar-dropdown" as={ Nav.Link }>Navigate to</Dropdown.Toggle>
        <Dropdown.Menu className="bg-primary">
          { getNavbar(true) }
        </Dropdown.Menu>
      </Dropdown>
    </Nav>
  )
}

export default NavbarStart
