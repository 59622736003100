import React from 'react'
import { useDispatch } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { PAGE_CONTENT_TYPES_ICONS } from 'src/Services/Constants'
import { deleteRow, requestEditContent, requestNewContent } from '../state/actions'
import { getFilledColsCount } from '../utils/index'

const LandingPageRowContent = ({ trans, row = null }) => {
  const dispatch = useDispatch()

  const onEmptyColClick = () => dispatch(requestNewContent(row, getFilledColsCount(row) + 1))
  const onContentClick = content => () => dispatch(requestEditContent(row, content))
  const onRowDeleteClick = () => dispatch(deleteRow(row))

  return <Row className="mb-2 dashed-card g-1">
    { row.contents.map((content, i) =>
      <Col key={ i } sm={ content.size } onClick={ onContentClick(content) }>
        <div className="round-card cursor-pointer h-100">
          <div>
            <small className="text-muted me-1">
              <i className={ `${PAGE_CONTENT_TYPES_ICONS[content.type]} me-1` }/>
              { content.type }
            </small>
            { content.list?.label }
          </div>
          { content.label }
        </div>
      </Col>
    ) }
    { getFilledColsCount(row) < 12 &&
      <Col onClick={ onEmptyColClick }>
        <div className="round-card cursor-pointer h-100">
          <i className="fas fa-plus me-1"/>
          { trans('form.editor.addNewContent') }
        </div>
      </Col> }
    <div className="mt-1">
      <span className="text-danger cursor-pointer" onClick={ onRowDeleteClick }>
        { trans('delete') }
      </span>
    </div>
  </Row>
}

export default LandingPageRowContent
