import React, { useState } from 'react'
import { setPage, setQuery, setLimit, fetchItems } from '../state/actions'
import { translate } from '../../../Services/translation'
import translation from '../translations'
import NavTable from '../../../Components/NavTable'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ButtonGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Table = ({ onDeleteClick, onUpdateClick }) => {

  const dispatch = useDispatch()
  const { items, pagination, isLoading } = useSelector(state => state.Reports)
  const { language } = useSelector(state => state.Root?.user)
  const trans = translate(translation)(language)
  const [ fetchListRequest, setFetchListRequest ] = useState(null)

  const columnHeaders = [
    trans('name'),
    trans('label'),
    trans('description'),
    trans('actions')
  ]
  const rows = getRows(items, trans, onDeleteClick, onUpdateClick)

  const applyNewFilters = () => {
    clearTimeout(fetchListRequest)
    setFetchListRequest(setTimeout(() => dispatch(fetchItems()), 1000))
  }

  const onSearchInputChange = e => {
    e.preventDefault()
    dispatch(setQuery(e.target.value))
    applyNewFilters()
  }

  const onPageClick = (currentPage, page) => {
    if (currentPage === page)
      return

    dispatch(setPage(page))
    applyNewFilters()
  }

  const onLimitChange = limit => {
    dispatch(setLimit(limit))
    applyNewFilters()
  }

  return <NavTable pagination={ pagination }
                   rows={ rows }
                   columnHeaders={ columnHeaders }
                   onSearchInputChange={ onSearchInputChange }
                   onLimitChange={ onLimitChange }
                   onPageClick={ onPageClick }
                   isisLoading={ isLoading }
  />
}

const getRows = (
  items,
  trans,
  onDeleteClick,
  onUpdateClick
) => {

  const ActionCell = ({ onDeleteClick, onUpdateClick, item }) =>
    <ButtonGroup>
      <Button variant={ 'outline-info' } size={ 'sm' } onClick={ onDeleteClick }>
        <i className="fas fa-trash"/> { trans('delete') }
      </Button>
      <Button variant={ 'info' } size={ 'sm' } onClick={ onUpdateClick }>
        <i className="fas fa-edit"/> { trans('edit') }
      </Button>
      <Button as={ Link } variant={ 'success' } size={ 'sm' } to={ `/reports/run/${ item.id }` }>
        <i className="fas fa-play"/> { trans('run') }
      </Button>
    </ButtonGroup>

  return items.map(item => [
    <>{ item.name }</>,
    <>{ item.label }</>,
    <>{ item.description }</>,
    <ActionCell onDeleteClick={ () => onDeleteClick(item) }
                onUpdateClick={ () => onUpdateClick(item) }
                item={ item }
    />
  ])
}

export default Table
