
export default {
  english: {
    title: 'Tasks',
    addNew: 'add a new task',
    form: {
      name: 'Name',
      form: 'Form',
    },
    modal: {
      create: {
        title: 'Create a new task'
      },
      update: {
        title: 'Update a task'
      },
      confirmDelete: {
        title: 'Are you sure you want to delete this task ?'
      }
    }
  },
  french: {
    title: 'Catégories de Tâche',
    addNew: 'Ajouter un nouveau statut',
    form: {
      name: 'Nom',
      form: 'Formulaire',
    },
    modal: {
      create: {
        title: 'Créer un nouveau statut'
      },
      update: {
        title: 'Éditer un statut'
      },
      confirmDelete: {
        title: 'Êtes-vous sûr de supprimer ce statut ?'
      }
    }
  }
}
