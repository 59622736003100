import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form as BForm, Button, Row, Col } from 'react-bootstrap'
import EntitySelect from 'src/Components/EntitySelect'
import { setDeletingItem } from '../state/actions'

const Form = ({
  trans,
  onSubmit = () => {},
  item = null
}) => {
  const dispatch = useDispatch()

  const { forms, workflowTriggerFormEvents, workflowTriggerTypes, eventStatuses }
    = useSelector(state => state.Dictionary)

  const [ formId, setFormId ] = useState(item?.form?.id || '')
  const [ field, setField ] = useState(item?.field || null)
  const [ type, setType ] = useState(item?.type || workflowTriggerTypes[0])
  const [ formEvent, setFormEvent ] = useState(item?.formEvent || null)

  useEffect(() => {
    if (type !== workflowTriggerTypes[0]) {
      setFormEvent(null)
    }
  }, [ type ])

  const onFormSubmit = e => {
    e.preventDefault()
    onSubmit({
      name: e.target.name.value,
      type: e.target.type.value,
      form: e.target.form?.value ? e.target.form.value : null,
      formEvent: e.target.formEvent?.value?.toUpperCase() || null,
      field: e.target.field?.value ? e.target.field.value : null,
      oldValue: e.target.oldValue?.value || null,
      newValue: e.target.newValue?.value || null
    })
  }

  const onDeleteClick = () => dispatch(setDeletingItem(item))

  return (
    <BForm onSubmit={ onFormSubmit }>
      <Row className={ 'mb-2' }>
        <Col>
          <BForm.Group>
            <BForm.Label>{ trans('form.name') }</BForm.Label>
            <BForm.Control defaultValue={ item?.name } name={ 'name' } required placeholder={ trans('form.name') }/>
          </BForm.Group>
        </Col>
        <Col>
          <BForm.Group>
            <BForm.Label>{ trans('form.type') }</BForm.Label>
            <BForm.Select value={ type || '' } name={ 'type' } required aria-label={ trans('form.type') }
                          onChange={ e => setType(e.target.value) }>
              { workflowTriggerTypes.map(_ => <option key={ _ } value={ _ }>{ _ }</option>) }
            </BForm.Select>
          </BForm.Group>
        </Col>
      </Row>

      { (type === 'FORM_EVENT' || type === 'FIELD_VALUE_CHANGE') && <Row className={ 'mb-2' }>
        <Col>
          <BForm.Group>
            <BForm.Label>{ trans('form.form') }</BForm.Label>
            <BForm.Select name={ 'form' } value={ formId }
                          onChange={ e => setFormId(e.target.value) } required aria-label={ trans('form.form') }
            >
              <option value={ '' }>{ trans('none') }</option>
              { forms.map(({ id, systemName }) => <option key={ id } value={ id }>{ systemName }</option>) }
            </BForm.Select>
          </BForm.Group>
        </Col>
        { type === 'FORM_EVENT' && <Col>
          <BForm.Group>
            <BForm.Label>{ trans('form.formEvent') }</BForm.Label>
            <BForm.Select defaultValue={ formEvent || '' } name={ 'formEvent' } aria-label={ trans('form.formEvent') }
                          disabled={ field } onChange={ e => setFormEvent(e.target.value?.toUpperCase()) }>
              <option value={ '' }>{ trans('none') }</option>
              { workflowTriggerFormEvents.map(_ => <option key={ _ } value={ _ }>{ _ }</option>) }
            </BForm.Select>
          </BForm.Group>
        </Col> }
        { type === 'FIELD_VALUE_CHANGE' && <Col>
          <BForm.Group>
            <BForm.Label>{ trans('form.field') }</BForm.Label>
            <EntitySelect value={ field }
                          disabled={ !formId || formEvent }
                          name={ 'field' }
                          clearable={ true }
                          id={ 'workflowTriggerFormFieldSelect' }
                          fetchUrl={ `/forms/${ formId }/fields` }
                          labelKey={ 'label' }
                          onChange={ setField }
            />
          </BForm.Group>
        </Col> }
      </Row> }

      { type === 'EVENT_STATUS_CHANGE' && <>
        <Row className={ 'mb-2' }>
          <Col>
            <BForm.Group>
              <BForm.Label>{ trans('form.oldValue') }</BForm.Label>
              <BForm.Select defaultValue={ item?.oldValue } name={ 'oldValue' }
                            aria-label={ trans('form.oldValue') }>
                <option value={'*'}>*</option>
                { eventStatuses.map(({ systemName }) => <option key={ systemName } value={ systemName }>{ systemName }</option>) }
              </BForm.Select>
            </BForm.Group>
          </Col>
          <Col>
            <BForm.Group>
              <BForm.Label>{ trans('form.newValue') }</BForm.Label>
              <BForm.Select defaultValue={ item?.newValue } name={ 'newValue' }
                            aria-label={ trans('form.newValue') }>
                <option value={'*'}>*</option>
                { eventStatuses.map(({ systemName }) => <option key={ systemName } value={ systemName }>{ systemName }</option>) }
              </BForm.Select>
            </BForm.Group>
          </Col>
        </Row>
        <small className={ 'text-muted' }>{ trans('form.valueInfo') }</small>
      </> }

      { type === 'FIELD_VALUE_CHANGE' && <>
        <Row className={ 'mb-2' }>
          <Col>
            <BForm.Group>
              <BForm.Label>{ trans('form.oldValue') }</BForm.Label>
              <BForm.Control defaultValue={ item?.oldValue } name={ 'oldValue' }
                             placeholder={ trans('form.oldValue') }
              />
            </BForm.Group>
          </Col>
          <Col>
            <BForm.Group>
              <BForm.Label>{ trans('form.newValue') }</BForm.Label>
              <BForm.Control defaultValue={ item?.newValue } name={ 'newValue' }
                             placeholder={ trans('form.newValue') }
              />
            </BForm.Group>
          </Col>
        </Row>
        <small className={ 'text-muted' }>
          { trans('form.valueInfo') }<br/>
          { trans('form.valueInfo2') } : <code>{ trans('form.valueInfo2Exemple') }</code>
        </small>
      </> }

      <Row className="mt-2">
        <Col>
          <Button type={'button'} variant={'danger'} onClick={onDeleteClick}>
            { trans('delete') }
            <i className="ms-2 bi bi-trash3"></i>
          </Button>
        </Col>
        <Col>
          <Button variant={ 'success' } style={ { float: 'right' } } type={ 'submit' }>
            { trans('submit') }
            <i className="ms-2 bi bi-check-circle"></i>
          </Button>
        </Col>
      </Row>
    </BForm>
  )
}

export default Form
