import React, { useEffect } from 'react'
import { ListGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getHeadersHeight, scrollToView } from 'src/Utils/index.ts'
import { setActiveSection } from './state/actions'

const SectionNavigator = ({ isFloatingAlone = true }) => {
  const dispatch = useDispatch()
  const { sections, activeSection } = useSelector(state => state.SectionNavigator)
  const { isHeaderSticky } = useSelector(state => state.View)

  const changeActiveSectionOnScrollEffect = () => {
    const onScroll = e => {
      const scrollTop = e.target.scrollingElement.scrollTop + getHeadersHeight(true)
      const sections = Array.from(document.querySelectorAll('[data-is=section]'))

      sections.forEach((section, idx) => {
        const sectionTop = section.offsetTop
        if (scrollTop >= sectionTop )
          dispatch(setActiveSection(idx))
      })
    }
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }

  useEffect(changeActiveSectionOnScrollEffect, [ activeSection ])

  const onSectionClick = sectionIndex => {
    const section = document.querySelectorAll('[data-is=section]')[sectionIndex]

    if (section) scrollToView(section, isHeaderSticky,  true )
  }

  const onSectionClickHandler = sectionIndex => e => {
    onSectionClick(sectionIndex)

    // set active index after we scroll, so scroll event listener do not erase active section
    setTimeout(() => dispatch(setActiveSection(sectionIndex)), 1000)
  }

  const getStyle = () => isFloatingAlone ? { top: `${ getHeadersHeight(isHeaderSticky) + 10 }px` } : {}

  return !!sections.length && (
    <div className={ `${ isFloatingAlone ? 'mb-2 sticky-top' : '' }` }
         style={ getStyle() }
    >
      <ListGroup variant={ isFloatingAlone ? null : 'flush' }>
        { sections.map((section, i) => (
          <ListGroup.Item key={ i }
                          active={ activeSection === i }
                          className={ 'cursor-pointer' }
                          tabIndex={ '0' }
                          onClick={ onSectionClickHandler(i) }
          >
            { section }
          </ListGroup.Item>
        )) }
      </ListGroup>
    </div>
  )
}

export default SectionNavigator
