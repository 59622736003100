import {
  ACTION_CREATED, ACTION_DELETED, CREATE_ACTION, DELETE_ACTION, UPDATE_ACTION, ACTION_UPDATED,
  SET_DELETING_ACTION, SET_UPDATING_ACTION, TOGGLE_CREATE_ACTION_MODAL
} from './actionTypes'

const initialState = {
  isLoading: false,
  creatingItem: null,
  updatingItem: null,
  deletingItem: null,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CREATE_ACTION:
      return {
        ...state,
        isLoading: true
      }
    case UPDATE_ACTION:
      return {
        ...state,
        isLoading: true
      }
    case DELETE_ACTION:
      return {
        ...state,
        isLoading: true
      }
    case ACTION_CREATED:
      return {
        ...state,
        creatingItem: null,
      }
    case ACTION_UPDATED:
      return {
        ...state,
        updatingItem: null,
      }
    case ACTION_DELETED:
      return {
        ...state,
        deletingItem: null,
        updatingItem: null,
      }
    case SET_DELETING_ACTION:
      return {
        ...state,
        deletingItem: payload
      }
    case SET_UPDATING_ACTION:
      return {
        ...state,
        updatingItem: payload
      }
    case TOGGLE_CREATE_ACTION_MODAL:
      return {
        ...state,
        creatingItem: !state.creatingItem
      }
    default:
      return state
  }
}
