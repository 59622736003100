import {
  CHANGE_LIMIT,
  RECEIVE_FIELD_TYPES,
  TOGGLE_CREATE_FIELD_TYPE,
  RECEIVE_BASE_FIELD_TYPES,
  FIELD_TYPE_CREATED,
  TOGGLE_UPDATE_FIELD_TYPE,
  TOGGLE_DELETE_FIELD_TYPE,
  FIELD_TYPE_DELETED,
  FIELD_TYPE_UPDATED, CHANGE_PAGE, SEARCH_FIELD_TYPE
} from './actionTypes'

const initialState = {
  isCreatingFieldType: false,
  isUpdatingFieldType: false,
  isDeletingFieldType: false,
  fieldTypeOnUpdate: null,
  fieldTypeOnDelete: null,
  fieldTypes: [],
  baseFieldTypes: {},
  searchQuery: null,
  pagination: {
    offset: 0,
    limit: 10,
    itemsCount: 10,
    totalItemsCount: 10,
    pageLimit: 10,
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPagesCount: 1,
  },
}

export default (state = initialState, {
  type,
  payload
}) => {
  switch (type) {
    case RECEIVE_FIELD_TYPES:
      return {
        ...state,
        fieldTypes: payload.fieldTypes,
        pagination: payload.pagination
      }
    case FIELD_TYPE_CREATED:
      return {
        ...state,
        fieldTypes: [ ...state.fieldTypes, payload ]
      }
    case FIELD_TYPE_UPDATED:
      return {
        ...state,
        fieldTypes: state.fieldTypes.map(_ => _.id === payload.id ? payload : _)
      }
    case FIELD_TYPE_DELETED:
      return {
        ...state,
        fieldTypeOnDelete: null,
        fieldTypes: state.fieldTypes.filter(_ => _.id !== payload)
      }
    case RECEIVE_BASE_FIELD_TYPES:
      return {
        ...state,
        baseFieldTypes: payload
      }
    case TOGGLE_CREATE_FIELD_TYPE:
      return {
        ...state,
        isCreatingFieldType: !state.isCreatingFieldType
      }
    case TOGGLE_UPDATE_FIELD_TYPE:
      return {
        ...state,
        isUpdatingFieldType: !!payload,
        fieldTypeOnUpdate: payload
      }
    case TOGGLE_DELETE_FIELD_TYPE:
      return {
        ...state,
        isDeletingFieldType: !!payload,
        fieldTypeOnDelete: payload
      }
    case CHANGE_LIMIT:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          pageLimit: +payload,
          currentPage: 1
        }
      }
    case CHANGE_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: payload
        }
      }
    case SEARCH_FIELD_TYPE:
      return {
        ...state,
        searchQuery: payload,
        pagination: {
          ...state.pagination,
          currentPage: initialState.pagination.currentPage,
          offset: initialState.pagination.offset,
        }
      }
    default:
      return state
  }
}
