import styled from 'styled-components'

export default styled.span`
  background-color: ${props => props.theme.colors.secondary.light};
  color: ${props => props.theme.colors.secondary.median};
  border-radius: 50px;
  width: 2em;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1em;

  i {
    margin: 0 1em;
  }
`
