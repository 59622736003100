import {
  ITEMS_RECEIVED,
  ITEM_CREATED,
  ITEM_DELETED,
  ITEM_UPDATED,
  ITEM_RECEIVED,
  FETCH_ITEMS,
  FETCH_ITEM,
  CREATE_ITEM,
  SET_DELETING_ITEM,
  SET_UPDATING_ITEM,
  SET_CREATING_ITEM,
  DELETE_ITEM,
  UPDATE_ITEM,
  SET_LIMIT,
  SET_PAGE,
  SET_QUERY,
  ADD_ROW,
  REQUEST_NEW_CONTENT,
  ADD_CONTENT,
  UPDATE_CONTENT,
  DELETE_ROW,
  DELETE_CONTENT,
  REQUEST_EDIT_CONTENT,
} from './actionTypes'

export const fetchItems = () => ({
  type: FETCH_ITEMS
})
export const fetchItem = (id) => ({
  type: FETCH_ITEM,
  payload: id
})
export const createItem = () => ({
  type: CREATE_ITEM
})
export const deleteItem = () => ({
  type: DELETE_ITEM
})
export const updateItem = () => ({
  type: UPDATE_ITEM
})

export const itemsReceived = (items) => ({
  type: ITEMS_RECEIVED,
  payload: items
})
export const itemReceived = (item) => ({
  type: ITEM_RECEIVED,
  payload: item
})
export const itemCreated = (item) => ({
  type: ITEM_CREATED,
  payload: item
})
export const itemDeleted = (id) => ({
  type: ITEM_DELETED,
  payload: id
})
export const itemUpdated = (item) => ({
  type: ITEM_UPDATED,
  payload: item
})

export const setDeletingItem = (item) => ({
  type: SET_DELETING_ITEM,
  payload: item
})
export const setUpdatingItem = (item) => ({
  type: SET_UPDATING_ITEM,
  payload: item
})
export const setCreatingItem = (item) => ({
  type: SET_CREATING_ITEM,
  payload: item
})

export const setQuery = (query) => ({
  type: SET_QUERY,
  payload: query
})
export const setLimit = (limit) => ({
  type: SET_LIMIT,
  payload: limit
})
export const setPage = (page) => ({
  type: SET_PAGE,
  payload: page
})

export const addRow = row => ({
  type: ADD_ROW,
  payload: { row }
})
export const requestNewContent = (row, col) => ({
  type: REQUEST_NEW_CONTENT,
  payload: { row, col }
})
export const requestEditContent = (row, content) => ({
  type: REQUEST_EDIT_CONTENT,
  payload: { row, content }
})
export const addContent = (row, content) => ({
  type: ADD_CONTENT,
  payload: { row, content }
})
export const updateContent = (row, content) => ({
  type: UPDATE_CONTENT,
  payload: { row, content }
})
export const deleteRow = row => ({
  type: DELETE_ROW,
  payload: { row }
})
export const deleteContent = (row, content) => ({
  type: DELETE_CONTENT,
  payload: { row, content }
})
