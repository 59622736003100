import React from 'react'
import { useSelector } from 'react-redux'
import { Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { translate } from 'src/Services/translation'
import { formatDateTime } from 'src/Utils/Date'
import * as constPatient from 'src/Services/Constants/Config/Patient'
import { boldifySearchResults } from 'src/Services/UserHelpers'
import translations from '../translations'
import { StoreState } from 'src/Services/Store/reducers'


const PatientSearchResultRow = ({ patient, onClick } : { patient: any, onClick: (patient: any) => void }) => {
  const { language, locale, portalTimezone } = useSelector((state: StoreState )=> state.Root.user)
  const { searchInputValue } = useSelector((state: StoreState) => state.Header)
  const trans = translate(translations)(language)

  const formatSearchedItem = (item: string) => {
    if (searchInputValue) {
      const formattedItem = boldifySearchResults(searchInputValue, item)
      return <span dangerouslySetInnerHTML={{ __html: formattedItem }}/>
    }
  }

  const isNotEmptyString = (content: string) => content !== null && content !== '' && content !== ' '

  return (
    <Dropdown.Item as={Link}
                   className="text-primary text-wrap"
                   to={`/patient/${patient[constPatient.ID]}/instance/${patient[constPatient.ID]}`}
                   onClick={() => onClick(patient)}
                    // @ts-ignore
                   tabIndex="0"
                   data-is="resultItem"
    >
      {
        isNotEmptyString(patient[constPatient.LAST_NAME])
          ? formatSearchedItem(patient[constPatient.LAST_NAME])
          : <i>{trans('noLastName')}</i>
      },&nbsp;
      {
        isNotEmptyString(patient[constPatient.BIRTH_NAME])
          ? <>{trans('born')} {formatSearchedItem(patient[constPatient.BIRTH_NAME])}, </>
          : ''
      }
      {
        isNotEmptyString(patient[constPatient.FIRST_NAME])
          ? formatSearchedItem(patient[constPatient.FIRST_NAME])
          : <i>{trans('noFirstName')}</i>
      } -&nbsp;
      {
        isNotEmptyString(patient[constPatient.DATE_OF_BIRTH])
          ? formatDateTime(locale, portalTimezone)(patient[constPatient.DATE_OF_BIRTH], true, false)
          : <i>{trans('noDateOfBirth')}</i>
      }
      { isNotEmptyString(patient[constPatient.MOBILE_PHONE])
          ? <> - {formatSearchedItem(patient[constPatient.MOBILE_PHONE])}</>
          : ''
      }
      {
        isNotEmptyString(patient[constPatient.HOME_PHONE])
          ? <> - {formatSearchedItem(patient[constPatient.HOME_PHONE])}</>
          : ''
      }
      {
        isNotEmptyString(patient[constPatient.EXTERNAL_ID])
          ? <> - {formatSearchedItem(patient[constPatient.EXTERNAL_ID])}</>
          : ''
      }
    </Dropdown.Item>
  )
}

export default PatientSearchResultRow
