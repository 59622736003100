export default {
  english: {
    action: 'Action',
    form: 'Form',
    type: 'Type',
    role: 'Role',
    fieldEvent: 'Field event',
    plainFormEvent: 'Form event',
    formEvent: 'Form event ({event})',
    plainEventStatus: 'Event status',
    eventStatus: 'Event status ({status})'
  },
  french: {
    action: 'Action',
    form: 'Form',
    type: 'Type',
    role: 'Role',
    fieldEvent: 'Field event',
    plainFormEvent: 'Form event',
    formEvent: 'Form event ({event})',
    plainEventStatus: 'Event status',
    eventStatus: 'Event status ({status})'
  }
}
