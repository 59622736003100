import React  from 'react'
import { useDispatch } from 'react-redux'
import { path } from 'ramda'
import Calendar from 'src/Components/Calendar'
import { withState } from 'src/Components/Util'
import { setOptions } from 'src/Layouts/View/state/actions'
import { translate } from 'src/Services/translation'
import translations from '../translations'
import { SidebarTab } from 'src/Components/Calendar/Types/Calendar'

const propsFromState = {
  isSidebarOpen: path([ 'Calendar', 'isSidebarOpen' ]),
  language: path([ 'Root', 'user', 'language' ])
}

const PatientCalendar = ({ isSidebarOpen, language }) => {

  const trans = translate(translations)(language)
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(setOptions({
      title: trans('calendar'),
      hasPrimaryButton: false
    }))
  }, [ dispatch ])

  const tabs = [ SidebarTab.EVENTS, SidebarTab.OPTIONS, SidebarTab.LEGEND ]

  return <div className="m-0 my-3">
    <Calendar isDroppable openSidebar={isSidebarOpen} tabs={tabs} />
  </div>
}

export default withState(propsFromState)(PatientCalendar)
