import React from 'react'
import { useSelector } from 'react-redux'
import { ListGroup } from 'react-bootstrap'
import { FIELD_BASE_TYPE_TEXT } from 'src/Services/Constants'

const Column = ({ column, onDelete }) => {
  const { fieldTypes } = useSelector(state => state.Dictionary)

  return <ListGroup.Item as="li" className="d-flex justify-content-between">
      <i className={ fieldTypes.find(_ => _.baseFieldType === FIELD_BASE_TYPE_TEXT)?.icon }/>
      <span>{ column.label }</span>
      <i className="fas fa-trash cursor-pointer"
         title="Delete"
         onClick={ onDelete }
      />
    </ListGroup.Item>
}

export default Column
