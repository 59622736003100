import * as types from 'src/Views/UserForm/state/actionTypes'
import { User } from 'src/Types/User'
import { NavigateFunction } from 'react-router/dist/lib/hooks'

export const reset = () => ({
  type: types.RESET
})

export const fetchUser = (id: string) => ({
  type: types.FETCH_USER,
  payload: { id }
})

export const setUser = (user: User|null) => ({
  type: types.SET_USER,
  payload: { user }
})
export const setNewUser = (user: User|null) => ({
  type: types.SET_NEW_USER,
  payload: { user }
})

export const createUser = (user: User, navigate: NavigateFunction) => ({
  type: types.CREATE_USER,
  payload: { user, navigate }
})
export const updateUser = (id: string, user: User, navigate: NavigateFunction) => ({
  type: types.UPDATE_USER,
  payload: { id, user, navigate }
})
export const deleteUser = (id: string, navigate: NavigateFunction) => ({
  type: types.DELETE_USER,
  payload: { id, navigate }
})
export const blockUser = (id: string) => ({
  type: types.BLOCK_USER,
  payload: { id }
})
export const unblockUser = (id: string) => ({
  type: types.UNBLOCK_USER,
  payload: { id }
})

export const createPatientUser = (
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    roleId: string,
    mobilePhone?: string|null,
) => ({
  type: types.CREATE_PATIENT_USER,
  payload: { id, firstName, lastName, email, roleId, mobilePhone }
})
