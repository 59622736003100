
export default {
  english: {
    title: 'Slot Types',
    entries: 'of {total} entries',
    eventType: 'Event type',
    slotForm: {
      label: 'Label',
      systemName: 'System name',
      icon: 'Icon',
      defaultForm: 'Select a form'
    },
    modal: {
      create: {
        title: 'Create a new slot type'
      },
      update: {
        title: 'Update a slot type'
      },
      confirmDeleteTitle: 'Are you sure you want to delete this slot type ?'
    }
  },
  french: {
    title: 'Types de créneaux',
    entries: 'sur {total} entrées',
    eventType: 'Type d\'évènement',
    slotForm: {
      label: 'Label',
      systemName: 'System name',
      icon: 'Icône',
      defaultForm: 'Sélectionnez un formulaire'
    },
    modal: {
      create: {
        title: 'Créer un nouveau type de créneau'
      },
      update: {
        title: 'Mettre à jour un type de créneau'
      },
      confirmDeleteTitle: 'Voulez-vous vraiment supprimer ce type de créneau ?'
    }
  }
}
