import { FIELD_MS_SANTE_PREFIX_TRACKING_LIST, MS_SANTE_TRACKING_STATUS } from 'src/Services/Constants/MsSante'
import { getOpenedInstanceFieldsStartingBySystemName } from 'src/Services/Selectors'
import { MsSanteTracking, MsSanteTrackingRecipient } from '../Types/MsSante'
import OpenedInstance from '../Types/OpenedInstance'

interface detailedStatus {
    label: string
    value: string
    color: string
}
export const computeTrackingGlobalDetailedStatus = (tracking: MsSanteTracking): detailedStatus | null => {
    for (const status of MS_SANTE_TRACKING_STATUS) {
        if (tracking?.recipients.filter(recipient => recipient.trackingStatus === status.value).length) {
            return  status
        }
    }

    return null
}

export const getRecipientDetailedStatus = (recipient: MsSanteTrackingRecipient): detailedStatus | null => {
    return MS_SANTE_TRACKING_STATUS.find(status => status.value === recipient.trackingStatus)
}

export const hasTrackingId = (openedInstance: OpenedInstance) => {
    return getOpenedInstanceFieldsStartingBySystemName(openedInstance, FIELD_MS_SANTE_PREFIX_TRACKING_LIST).filter(field => field.value !== null).length > 0
}

export const hasTrackingStatus = (openedInstance: OpenedInstance, tracking: MsSanteTracking) => {
    if (!hasTrackingId(openedInstance)) {
        return false
    }

    return tracking?.recipients?.filter(recipient => recipient.trackingStatus !== null).length > 0
}
