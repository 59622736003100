import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, ButtonGroup } from 'react-bootstrap'
import { translate } from 'src/Services/translation'
import NavTable from 'src/Components/NavTable'
import { BoldLink } from 'src/Components/StyledComponents/ActionsWrapper'
import { toggleConfirmModal } from 'src/Services/Store/GlobalControls/actions'
import translation from '../translations'
import { deleteList, changePage, changeLimit, searchList, requestCloneList } from '../state/actions'

const ZmrListTable = () => {
  const dispatch = useDispatch()

  const { lists, pagination, loading } = useSelector(state => state.Lists)
  const { language } = useSelector(state => state.Root)

  const trans = translate(translation)(language)

  const onDeleteClick = list => () => dispatch(toggleConfirmModal(() => dispatch(deleteList(list.id))))
  const onCloneClick = list => () => dispatch(requestCloneList(list))

  const getListsAsRows = () => lists
    .map(list => [
      <BoldLink key={ list.id } to={ `/list/${ list.id }/run` }>{ list.label }</BoldLink>,
      <BoldLink key={ list.id } to={ `/list/${ list.id }/run` }>{ list.systemName }</BoldLink>,
      list.form.label,
      list.type,
      <ButtonGroup key={ list.id }>
        <Link to={ `/list/${ list.id }/run` }>
          <Button variant={ 'outline-info' } size={ 'sm' }>
            <i className={ 'fas fa-play' }/> { trans('run') }
          </Button>
        </Link>
        <Button variant={ 'outline-info' } size={ 'sm' } onClick={ onCloneClick(list) }>
          <i className={ 'fas fa-clone' }/> { trans('clone') }
        </Button>
        <Button variant={ 'outline-info' } size={ 'sm' } onClick={ onDeleteClick(list) }>
          <i className={ 'fas fa-trash' }/> { trans('delete') }
        </Button>
        <Link to={ `/list/${ list.id }` }>
          <Button variant={ 'info' } size={ 'sm' }>
            <i className={ 'fas fa-edit' }/> { trans('edit') }
          </Button>
        </Link>
      </ButtonGroup>
    ])

  const columnHeaders = [
    trans('label'), trans('systemName'), trans('baseForm'), trans('type'), trans('actions')
  ]

  const onSearchInputChange = e => {
    e.preventDefault()

    dispatch(searchList(e.target.value))
  }

  const onPageClick = (currentPage, page) => {
    if (currentPage !== page)
      dispatch(changePage(page))
  }

  const onLimitChange = limit => {
    dispatch(changeLimit(limit))
  }

  return <NavTable pagination={ pagination }
                   rows={ getListsAsRows() }
                   columnHeaders={ columnHeaders }
                   onSearchInputChange={ onSearchInputChange }
                   onLimitChange={ onLimitChange }
                   onPageClick={ onPageClick }
                   isLoading={ loading }
  />
}

export default ZmrListTable
