import { FIELD_BASE_TYPE_DATE_TIME, ID_SEPARATOR } from 'src/Services/Constants'
import { ColumnValueType, DateFilterOperator, StringFilterOperator } from 'src/Types/RequestFilter'
import { FieldPermissionAccessLevel } from 'src/Types/FieldPermission'
import { isUuidV4String } from 'src/Types/Uuid'

export const getExtensibleRows = state => extensibleId => {

  const fields = state.openedInstance.fields

  if (!fields)
    return []

  const extensible = fields[extensibleId]

  if (!extensible.fields?.length)
    return []

  let rows = []

  for (const fieldKey of Object.keys(fields)) {
    const field = fields[fieldKey]

    if (field.extensibleFieldId !== extensibleId || isUuidV4String(fieldKey))
      continue

    const splitFieldId = fieldKey.split(ID_SEPARATOR)
    const fieldId = splitFieldId[0]
    const fieldRowId = splitFieldId[1]

    const row = rows.find(row => row.id === fieldRowId)

    if (row) {
      row.cells.push(field)
    } else {
      rows.push({ id: fieldRowId, cells: [ field ] })
    }
  }

  if (extensible.accessLevel >= FieldPermissionAccessLevel.CREATE)
    rows.push({
      id: 0,
      cells: extensible.fields.map(fieldId => ({ ...fields[fieldId], value: null }))
    })

  return rows
}

export const getExtensibleHeaders = state => extensibleId => {
  const fields = state.openedInstance.fields

  if (!fields)
    return []

  const extensible = fields[extensibleId]

  if (!extensible?.fields?.length)
    return []

  return extensible.fields.map(fieldId => fields[fieldId])
}

export const getReferenceSearchQuery = state => refId => {
  const fieldsById = state.openedInstance.fields || {}
  const fields = Object.values(fieldsById)
  const referencedFields = fields.filter(field => field.referenceFieldId === refId)

  if (referencedFields.length === 0)
    return {}

  return referencedFields.reduce((acc, referencedField) => {
    if (!referencedField.value || !referencedField.value.length)
      return acc

    const isDateTime = referencedField.type?.baseFieldType === FIELD_BASE_TYPE_DATE_TIME

    return [
      ...acc,
      {
        column: referencedField.listColumn.systemName,
        type: isDateTime ? ColumnValueType.DATE : ColumnValueType.STRING,
        operator: isDateTime ? DateFilterOperator.EQUAL : StringFilterOperator.CONTAIN,
        value: referencedField.value,
        nextFilterOperator: null
      }
    ]
  }, [])
}

export const getIsReferenceSearchModalOpen = state => Boolean(state.searchForRefField)
