export default {
  english: {
    lock: 'Lock',
    unlock: 'Unlock',
    filename: 'Filename',
    minimum: 'minimum',
    maximum: 'maximum',
    added: 'added',
    nameRequired: 'Name required',
    size: 'Size',
    addFiles: 'Add files',
  },
  french: {
    lock: 'Verrouiller',
    unlock: 'Déverouiller',
    filename: 'Nom du fichier',
    minimum: 'minimum',
    maximum: 'maximum',
    added: 'ajouté(s)',
    nameRequired: 'Nom nécessaire',
    size: 'Size',
    addFiles: 'Ajouter des fichiers',
  }
}
