import React, { useState } from 'react'
import { setPage, setQuery, setLimit, fetchItems } from '../state/actions'
import { translate } from 'src/Services/translation'
import translation from '../translations'
import NavTable from '../../../Components/NavTable'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ButtonGroup } from 'react-bootstrap'
import { formatTime } from 'src/Utils/Date.ts'

const Table = ({ onDeleteClick, onUpdateClick }) => {

  const dispatch = useDispatch()
  const { items, pagination, isLoading } = useSelector(state => state.EventTypes)
  const { language, locale, portalTimezone } = useSelector(state => state.Root.user)
  const trans = translate(translation)(language)
  const [ fetchListRequest, setFetchListRequest ] = useState(null)

  const columnHeaders = [ trans('label'), trans('systemName'), trans('form.defaultStartTime'),
    trans('form.defaultDuration'), trans('form.form'), trans('form.list'), trans('form.isSlotRequired'),
    trans('actions') ]

  const getRows = () =>
    items.map(item => [
      <>{ item.label }</>,
      <>{ item.systemName }</>,
      <>{ formatTime(locale, portalTimezone)(item.defaultStartTime) }</>,
      <>{ item.defaultDuration }</>,
      <>{ item.form?.label }</>,
      <>{ item.list?.label }</>,
      <>{ item.isSlotRequired ? trans('yes') : trans('no') }</>,
      <ActionCell onDeleteClick={ () => onDeleteClick(item) }
                  onUpdateClick={ () => onUpdateClick(item) }
                  trans={ trans }
      />
    ])

  const applyNewFilters = () => {
    clearTimeout(fetchListRequest)
    setFetchListRequest(setTimeout(() => dispatch(fetchItems()), 1000))
  }

  const onSearchInputChange = e => {
    e.preventDefault()
    dispatch(setQuery(e.target.value))
    applyNewFilters()
  }

  const onPageClick = (currentPage, page) => {
    if (currentPage === page)
      return

    dispatch(setPage(page))
    applyNewFilters()
  }

  const onLimitChange = limit => {
    dispatch(setLimit(limit))
    applyNewFilters()
  }

  return <NavTable pagination={ pagination }
                   rows={ getRows() }
                   columnHeaders={ columnHeaders }
                   onSearchInputChange={ onSearchInputChange }
                   onLimitChange={ onLimitChange }
                   onPageClick={ onPageClick }
                   isLoading={ isLoading }
  />
}

export const ActionCell = ({ onDeleteClick, onUpdateClick, trans }) =>
  <ButtonGroup>
    <Button variant={ 'outline-info' } size={ 'sm' } onClick={ onDeleteClick }>
      <i className="fas fa-trash"/> { trans('delete') }
    </Button>
    <Button variant={ 'info' } size={ 'sm' } onClick={ onUpdateClick }>
      <i className="fas fa-edit"/> { trans('edit') }
    </Button>
  </ButtonGroup>

export default Table
