import { call, put, select, takeLatest } from 'redux-saga/effects'
import apiMethods from 'src/Services/api/apiMethods'
import { contentRangeToPagination } from 'src/Utils/EffectsUtil.ts'
import { LIST_TYPE_REFERENCE } from 'src/Services/Constants'
import { listDeleted, load, newListAdded, receive, toggleAddingList } from './actions'
import {
  ADD_NEW_LIST, CHANGE_LIMIT, CHANGE_PAGE, CLONE_LIST, DELETE_LIST,
  LIST_DELETED, LOAD_LISTS, NEW_LIST_ADDED, SEARCH_LIST
} from './actionTypes'

export function* loadZmrList(props) {
  const state = yield select()
  const { pagination, searchQuery } = state.Lists
  try {
    const { data, headers } = yield call(apiMethods.get, '/lists',
      { query: searchQuery, ...pagination, excludeTypes: [ LIST_TYPE_REFERENCE ] })

    yield put(receive({
      lists: data,
      pagination: contentRangeToPagination(headers['content-range'], pagination)
    }))
  } catch (error) {
    yield put(props.globalActions.handleError(error))
  }
}

export function* searchList(props, { payload }) {
  const state = yield select()
  const { pagination } = state.Lists

  try {
    const { data, headers } = yield call(apiMethods.get, '/lists',
      { query: payload, ...pagination, excludeTypes: [ LIST_TYPE_REFERENCE ] })

    yield put(receive({
      lists: data,
      pagination: contentRangeToPagination(headers['content-range'], pagination)
    }))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'searchListFailed'))
  }
}

export function* addList(props, { payload }) {
  const data = payload
  data.form = data.form.id

  try {
    yield call(apiMethods.create, '/lists', { data })
    yield put(newListAdded())
  } catch (error) {
    yield put(props.globalActions.handleError(error, error.toString()))
  }
}

export function* reloadList(props) {
  try {
    if (window.document.querySelector('[data-is=addListModal] form')) {
      window.document.querySelector('[data-is=addListModal] form')
        .reset()
    }
    yield put(toggleAddingList(false))
    yield put(load())
  } catch (error) {
    yield put(props.globalActions.handleError(error, error.toString()))
  }
}

export function* deleteList(props, { payload }) {
  try {
    yield call(apiMethods.delete, `/lists/${ payload }`)
    yield put(listDeleted())
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'deleteListFailed'))
  }
}

function* cloneList(props, { payload }) {
  try {
    const { label, systemName, description } = payload.data
    const { data: { id } } = yield call(apiMethods.create, `/lists/${ payload.id }/clone`,
      { data: { label, systemName, description } })
    yield put(newListAdded())

    yield call(payload.navigate, `/list/${ id }`)
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'cloneListFailed'))
  }
}

export default function* formFillerSagaWatcher(props) {
  yield takeLatest(NEW_LIST_ADDED, reloadList, props)
  yield takeLatest(LIST_DELETED, reloadList, props)
  yield takeLatest(LOAD_LISTS, loadZmrList, props)
  yield takeLatest(CHANGE_LIMIT, loadZmrList, props)
  yield takeLatest(CHANGE_PAGE, loadZmrList, props)
  yield takeLatest(SEARCH_LIST, searchList, props)
  yield takeLatest(ADD_NEW_LIST, addList, props)
  yield takeLatest(DELETE_LIST, deleteList, props)
  yield takeLatest(CLONE_LIST, cloneList, props)
}
