import { Instance, InstanceListValues } from 'src/Types/Instance'
import { Field, FieldValue } from 'src/Types/Field'
import { Form } from 'src/Types/Form'
import FormCustomizationCondition from 'src/Types/FormCustomizationCondition'
import { Action, ActionOptions, FieldEvent, FieldEventData } from 'src/Views/FormFiller/Types/Field'
import { FormElementType } from 'src/Views/FormFiller/Types/ScriptFormCustomizationOrder'
import { SaveStatus } from 'src/Components/SaveButton'
import { NavigateFunction } from 'react-router/dist/lib/hooks'
import OpenedInstance from 'src/Views/FormFiller/Types/OpenedInstance'
import { Filter } from 'src/Types/RequestFilter'
import SystemField from 'src/Types/SystemField'
import { ExtensibleRowValues } from 'src/Views/FormFiller/state/selectors'
import * as type from './actionTypes'
import { MsSanteTracking } from '../Types/MsSante'
import { SwpFeatureStatus } from 'src/Views/FormFiller/Types/Swp'

export const fetchInstance = (id: string) => ({
    type: type.FETCH_INSTANCE,
    payload: id,
})

export const fetchInstanceField = (instanceId: string, fieldId: string, onSuccess = (): any => null) => ({
    type: type.FETCH_INSTANCE_FIELD,
    payload: { instanceId, fieldId, onSuccess },
})

export const fetchInstanceFailed = (httpCode: number) => ({
    type: type.FETCH_INSTANCE_FAILED,
    payload: { httpCode },
})

export const setInstance = (instance: OpenedInstance, form: Form) => ({
    type: type.SET_INSTANCE,
    payload: { instance, form },
})
export const setInstanceField = (fieldId: string | number, value: any) => ({
    type: type.SET_INSTANCE_FIELD,
    payload: { fieldId, value },
})
export const setInstanceSystemFields = (systemFields: SystemField[]) => ({
    type: type.SET_INSTANCE_SYSTEM_FIELDS,
    payload: { systemFields },
})

export const saveInstance = (
    id: string,
    isSilent = false,
    onSuccess = (): any => null,
    onFailure = (): any => null,
) => ({
    type: type.SAVE_INSTANCE,
    payload: { id, isSilent, onSuccess, onFailure },
})

export const saveInstanceFieldValue = (
    instanceId: string,
    fieldId: string | number,
    value: any,
    isSilent = false,
) => ({
    type: type.SAVE_INSTANCE_FIELD_VALUE,
    payload: { instanceId, fieldId, value, isSilent },
})

export const saveInstanceSucceeded = () => ({
    type: type.SAVE_INSTANCE_SUCCEEDED,
})

export const saveInstanceFailed = () => ({
    type: type.SAVE_INSTANCE_FAILED,
})

export const restoreInstance = (instanceId: string) => ({
    type: type.RESTORE_INSTANCE,
    payload: { instanceId },
})

export const deleteInstance = (id: string, navigate: NavigateFunction) => ({
    type: type.DELETE_INSTANCE,
    payload: { id, navigate },
})

export const setIsEditing = (isEditing: boolean, referenceField: Field) => ({
    type: type.IS_EDITING_REFERENCE,
    payload: { isEditing, referenceField },
})

export const changeReference = (refId: string, instanceId: string, values: InstanceListValues, isFromExternalSource: boolean) => ({
    type: type.CHANGE_REFERENCE,
    payload: { refId, instanceId, values, isFromExternalSource },
})

export const changeReferenceToSelf = (refId: string, instanceId: string, listId: string) => ({
    type: type.CHANGE_REFERENCE_TO_SELF,
    payload: { refId, instanceId, listId },
})

export const clearReference = (field: Field) => ({
    type: type.CLEAR_REFERENCE,
    payload: { field },
})

export const printContent = () => ({
    type: type.PRINT_CONTENT,
})

export const searchReference = (
    listId: string,
    fieldId: string | number,
    filters: Filter[],
    showAddButton: boolean,
    externalProvider?: string
) => ({
    type: type.SEARCH_REFERENCE,
    payload: { listId, fieldId, filters, showAddButton, externalProvider },
})

export const referenceSearchSucceeded = (results: Instance[]) => ({
    type: type.REFERENCE_SEARCH_SUCCEEDED,
    payload: { results },
})
export const setReferenceSearchFilters = (filters: object[]) => ({
    type: type.SET_REFERENCE_SEARCH_FILTERS,
    payload: { filters },
})

export const referenceSearchFailed = () => ({
    type: type.REFERENCE_SEARCH_FAILED,
})

export const hideReferenceSearchModal = () => ({
    type: type.HIDE_REFERENCE_SEARCH_MODAL,
})

export const requestInstanceCreationForReference = (referenceField: Field) => ({
    type: type.REQUEST_INSTANCE_CREATION_FOR_REFERENCE,
    payload: { referenceField },
})

export const createInstanceForReference = (referenceField: Field, values: InstanceListValues) => ({
    type: type.CREATE_INSTANCE_FOR_REFERENCE,
    payload: { referenceField, values },
})

export const requestInstanceEditForReference = (referenceField: Field) => ({
    type: type.REQUEST_INSTANCE_EDIT_FOR_REFERENCE,
    payload: { referenceField },
})

export const editInstanceForReference = (referenceField: Field, values: InstanceListValues, instanceId: string) => ({
    type: type.EDIT_INSTANCE_FOR_REFERENCE,
    payload: { referenceField, values, instanceId },
})

export const referenceInstanceCreated = () => ({
    type: type.REFERENCE_INSTANCE_CREATED,
})

export const referenceInstanceCreationFailed = () => ({
    type: type.REFERENCE_INSTANCE_CREATION_FAILED,
})

export const addExtensibleRow = (extensibleId: string, rowId: string, data: ExtensibleRowValues) => ({
    type: type.ADD_EXTENSIBLE_ROW,
    payload: { extensibleId, rowId, data },
})

export const removeExtensibleRow = (extensibleId: string, rowId: string) => ({
    type: type.REMOVE_EXTENSIBLE_ROW,
    payload: { extensibleId, rowId },
})

export const createExtensibleRow = (fieldId: string | number) => ({
    type: type.CREATE_EXTENSIBLE_ROW,
    payload: { fieldId },
})

export const editExtensibleRow = (extensibleFieldId: string, rowId: string) => ({
    type: type.EDIT_EXTENSIBLE_ROW,
    payload: { extensibleFieldId, rowId },
})
export const addExtensibleRowLoading = (extensibleFieldId: string, rowId: string) => ({
    type: type.ADD_EXTENSIBLE_ROW_LOADING,
    payload: { extensibleFieldId, rowId },
})
export const removeExtensibleRowLoading = (extensibleFieldId: string, rowId: string) => ({
    type: type.REMOVE_EXTENSIBLE_ROW_LOADING,
    payload: { extensibleFieldId, rowId },
})

export const toggleValidateUserIdentityModal = (isOpen: boolean = null) => ({
    type: type.TOGGLE_VALIDATE_USER_AUTHENTICITY_MODAL,
    payload: { isOpen }
})

export const toggleMsSanteModal = (data: boolean) => ({
    type: type.TOGGLE_MS_SANTE_MODAL,
    payload: data
})

export const toggleMsSanteRecipientsFetching = (data: boolean) => ({
    type: type.TOGGLE_MS_SANTE_RECIPIENTS_FETCHING,
    payload: data
})

export const toggleMsSanteContentsFetching = (data: boolean) => ({
    type: type.TOGGLE_MS_SANTE_CONTENTS_FETCHING,
    payload: data
})

export const sendMsSanteTrackingRequest = (instanceId: string) => ({
    type: type.SEND_MS_SANTE_TRACKING_REQUEST,
    payload: { instanceId }
})

export const fetchMsSanteTrackingData = (instanceId: string) => ({
    type: type.FETCH_MS_SANTE_TRACKING_DATA,
    payload: { instanceId }
})

export const setMsSanteTrackingData = (tracking: MsSanteTracking) => ({
    type: type.SET_MS_SANTE_TRACKING_DATA,
    payload: { tracking }
})

export const toggleMsSanteTrackingModal = (data: boolean) => ({
    type: type.TOGGLE_MS_SANTE_TRACKING_MODAL,
    payload: data
})


export const setUserCode = (userCode: string | null) => ({
    type: type.SET_USER_PIN,
    payload: { userCode },
})

export const deleteExtensibleRow = (extensibleFieldId: string, rowId: string) => ({
    type: type.DELETE_EXTENSIBLE_ROW,
    payload: { extensibleFieldId, rowId },
})

// Files
export const downloadFile = (fieldId: string | number, fileId: string) => ({
    type: type.DOWNLOAD_FILE,
    payload: { fieldId, fileId },
})
export const downloadFileFromUrl = (fieldId: string | number, fileId: string) => ({
  type: type.DOWNLOAD_FILE_FROM_URL,
  payload: { fieldId, fileId },
})

export const deleteFile = (field: Field, fileId: string) => ({
    type: type.DELETE_FILE,
    payload: { field, fileId },
})
export const deleteFileSucceed = (fieldId: string | number, fileId: string) => ({
    type: type.DELETE_FILE_SUCCEED,
    payload: { fieldId, fileId },
})

export const lockFile = (fieldId: string | number, fileId: string) => ({
    type: type.LOCK_FILE,
    payload: { fieldId, fileId },
})
export const lockFileSucceed = (fieldId: string | number, fileId: string) => ({
    type: type.LOCK_FILE_SUCCEED,
    payload: { fieldId, fileId },
})
export const unlockFile = (fieldId: string | number, fileId: string) => ({
    type: type.UNLOCK_FILE,
    payload: { fieldId, fileId },
})
export const unlockFileSucceed = (fieldId: string | number, fileId: string) => ({
    type: type.UNLOCK_FILE_SUCCEED,
    payload: { fieldId, fileId },
})

export const editFile = (fieldId: string | number, fileId: string, name: string, description: string) => ({
    type: type.EDIT_FILE,
    payload: { fieldId, fileId, name, description },
})

export const editFileSucceed = (fieldId: string | number, fileId: string, name: string, description: string) => ({
    type: type.EDIT_FILE_SUCCEED,
    payload: { fieldId, fileId, name, description },
})
export const setSaveInstanceStatus = (status: SaveStatus) => ({
    type: type.SET_SAVE_INSTANCE_STATUS,
    payload: { status },
})

export const pushCustomizationOrder = (
    elementType: FormElementType,
    elementId: string | number,
    action: Action,
    options?: ActionOptions,
) => ({
    type: type.PUSH_CUSTOMIZATION_ORDER,
    payload: { elementType, elementId, action, options },
})
export const resetCustomizations = () => ({
    type: type.RESET_CUSTOMIZATIONS,
})
export const setFormCustomizationsConditionsTriggered = (conditions: FormCustomizationCondition[]) => ({
    type: type.SET_FORM_CUSTOMIZATIONS_CONDITIONS_TRIGGERED,
    payload: { conditions },
})

export const fieldEvent = (field: Field, event: FieldEvent, data: FieldEventData) => ({
    type: type.FIELD_EVENT,
    payload: { field, event, data },
})
export const changeFieldValue = (field: Field, value: FieldValue) => ({
    type: type.CHANGE_FIELD_VALUE,
    payload: { field, value },
})

export const generateDocumentFile = () => ({
    type: type.GENERATE_DOCUMENT_FILE,
})

export const setIsGenerateTemplateLoading = (isGenerateTemplateLoading: boolean) => ({
    type: type.IS_GENERATE_TEMPLATE_LOADING,
    payload: { isGenerateTemplateLoading },
})

export const setDocumentData = (data: any) => ({
    type: type.SET_DOCUMENT_DATA,
    payload: { data },
})

export const sendEmailPreview = (instanceId: string) => ({
    type: type.SEND_EMAIl_PREVIEW,
    payload: { instanceId },
})

/** SWP */
export const initSwp = () => ({
    type: type.INIT_SWP,
})
export const openSwpRecord = () => ({
    type: type.OPEN_SWP_RECORD,
})
export const openSwpPlayback = () => ({
    type: type.OPEN_SWP_PLAYBACK,
})
export const openSwpMonitor = () => ({
    type: type.OPEN_SWP_MONITOR,
})
export const initiateSwpAcquisition = () => ({
    type: type.INITIATE_SWP_ACQUISITION,
})
export const redirectToSwpDynamicLink = () => ({
    type: type.REDIRECT_TO_SWP_DYNAMIC_LINK,
})

export const fillPatientToInstance = (refId: string, rowId: string, values: any, refPatientIds: any[] = []) => ({
    type: type.FILL_PATIENT_INSTANCE,
    payload: { refId, rowId, values, refPatientIds },
})

export const setCalculationFields = (data: any) => ({
    type: type.SET_CALCULATION_FIELDS,
    payload: data,
})

export const toggleSaveLoading = (isLoading: boolean) => ({
    type: type.TOGGLE_SAVE_LOADING,
    payload: { isLoading },
})

export const setIsOpenedInstanceReady = (isReady: boolean) => ({
    type: type.SET_IS_OPENED_INSTANCE_READY,
    payload: { isReady },
})

export const setSwpFeatureStatus = (status: SwpFeatureStatus) => ({
    type: type.SET_SWP_FEATURE_STATUS,
    payload: { status },
})

export const setDocumentAsFinal = (instanceId: string, field: Field, file: File) => ({
    type: type.SET_DOCUMENT_AS_FINAL,
    payload: { instanceId, field, file },
})
export const setPendingProtectedAction = (action: () => null) => ({
    type: type.SET_PENDING_PROTECTED_ACTION,
    payload: { action },
})

export const sendMsSanteDocuments = (
    instanceId: string,
    recipients: string[],
    files: string[]
) => ({
    type: type.SEND_MS_SANTE_DOCUMENTS,
    payload: { instanceId, recipients, files }
})

export const toggleMsSanteModalLoading = (isLoading: boolean) => ({
    type: type.TOGGLE_MS_SANTE_MODAL_LOADING,
    payload: { isLoading },
})

export const fetchMsSanteRecipients = (instanceId: string) => ({
    type: type.FETCH_MS_SANTE_RECIPIENTS,
    payload: { instanceId }
})

export const setMsSanteRecipients = (recipients: object[]) => ({
    type: type.SET_MS_SANTE_RECIPIENTS,
    payload: { recipients }
})

export const fetchMsSanteContentsToSend = (instanceId: string) => ({
    type: type.FETCH_MS_SANTE_CONTENTS_TO_SEND,
    payload: { instanceId }
})

export const setMsSanteContentsToSend = (contentsToSend: object[]) => ({
    type: type.SET_MS_SANTE_CONTENTS_TO_SEND,
    payload: { contentsToSend }
})
