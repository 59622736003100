export const INITIALIZE_TABLE = '[LIST_RESULT_TABLE] INITIALIZE_TABLE'

export const FETCH_DATA = '[LIST_RESULT_TABLE] FETCH_DATA'
export const SET_DATA = '[LIST_RESULT_TABLE] SET_DATA'
export const SET_DETAILS = '[LIST_RESULT_TABLE] SET_DETAILS'

export const CHANGE_PAGINATION = '[LIST_RESULT_TABLE] CHANGE_PAGINATION'
export const FILTER = '[LIST_RESULT_TABLE] FILTER'
export const SORT = '[LIST_RESULT_TABLE] SORT'

export const REQUEST_EXPORT = '[LIST_RUN] REQUEST_EXPORT'
export const EXPORT_DONE = '[LIST_RUN] EXPORT_DONE'

export const SET_IS_TABLE_LOADING = '[LIST_RESULT_TABLE] SET_IS_TABLE_LOADING'
