import * as types from './actionTypes'
import {Instance} from 'src/Types/Instance'

export const toggleUserDropdownAction = () => ({
  type: types.TOGGLE_USER_DROPDOWN
})

export const setIsSearchInputAction = (data: boolean) => ({
  type: types.SET_IS_SEARCH_INPUT,
  payload: data
})

export const setSearchInputValueAction = (data: string) => ({
  type: types.SET_SEARCH_INPUT_VALUE,
  payload: data
})

export const launchFetchInputValue = () => ({
  type: types.LAUNCH_FETCH_INPUT_VALUE
})

export const search = (query: string) => ({
  type: types.SEARCH,
  payload: { query }
})

export const clearAction = () => ({
  type: types.CLEAR
})

export const resetPatientSearch = () => ({
  type: types.RESET_PATIENT_SEARCH
})

export const toggleBurgerAction = () => ({
  type: types.TOGGLE_BURGER
})

export const setSearchResults = (payload: object | []) => ({
  type: types.SET_SEARCH_RESULTS,
  payload
})

export const searchInstance  = (query: string) => ( {
  type: types.SEARCH_INSTANCE,
  payload: { query }
})

export const setInstanceData = (instance: Instance) => ({
  type: types.SET_INSTANCE_DATA,
  payload: { instance }
})
