import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PhoneInput from 'react-phone-input-2'
import ValidationTrigger from 'src/Components/ValidationTrigger'
import { FieldEvent } from 'src/Views/FormFiller/Types/Field'
import { fieldEvent } from '../../state/actions'
import 'react-phone-input-2/lib/style.css'

const PhoneField = ({
  id = null,
  field = null,
  value,
  disabled = false,
  isRequired = false,
}) => {
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.Root)
  const [ invalid, setIsInvalid ] = useState(false)

  const onChange = value => {
    if (value !== '' && !value.match(/^[0-9]{11,14}$/)) {
      setIsInvalid(true)
    } else {
      setIsInvalid(false)
      dispatch(fieldEvent(field, FieldEvent.VALUE_CHANGE, { value }))
    }
  }

  useEffect(() => {
    value &&
      setIsInvalid(value !== '' && !value.match(/^[0-9]{11,14}$/))
  },[])


  return <>
    <ValidationTrigger
      isRequired={isRequired || invalid}
      isValid={!invalid}
    />
    <PhoneInput
      country={ user.locale.toLowerCase() }
      preferredCountries={
        user.locale.toLowerCase() === 'us'
          ? [ 'us' ]
          : [ 'fr', 'es', 'de', 'pt', 'it', 'be', 'ch', 'li',' lu', 'mc' ]
      }
      enableSearch={ true }
      value={ value || null }
      disabled={ disabled }
      onChange={ (value, data) => onChange(value, data) }
      inputClass="form-control form-control-sm w-100"
      isValid={ () => !invalid }
    />

  </>

}

export default PhoneField
