import React, { ChangeEvent, useState } from 'react'
import { setPage, setQuery, setLimit, fetchItems } from 'src/Views/MessageConfigs/state/actions'
import { translate } from 'src/Services/translation'
import translation from '../translations'
import NavTable from '../../../Components/NavTable'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ButtonGroup } from 'react-bootstrap'
import { STORE } from 'src/Views/MessageConfigs/index'
import MessageConfig from 'src/Types/MessageConfig'
import { StoreState } from 'src/Services/Store/reducers'

interface MessageConfigTableProps {
  onDeleteClick: (item: MessageConfig) => any,
  onUpdateClick: (item: MessageConfig) => any,
  onTestClick: (item: MessageConfig) => any,
}

const Table = ({ onDeleteClick, onUpdateClick, onTestClick }: MessageConfigTableProps) => {
  const dispatch = useDispatch()

  const { items, pagination, isLoading } = useSelector((state: StoreState) => state[STORE])
  const { language } = useSelector((state: StoreState) => state.Root.user)

  const trans = translate(translation)(language)

  const [ fetchListRequest, setFetchListRequest ] = useState(null)

  const columnHeaders = [
      trans('column.server'), trans('column.port'), trans('column.fromEmail'), trans('column.fromName')
  ]
  const getRows = () => items.map(item => [
    <>{ item.server }</>,
    <>{ item.port }</>,
    <>{ item.fromAddress }</>,
    <>{ item.fromName }</>,
    <ButtonGroup key={ item.id }>
      <Button variant={ 'outline-info' } size={ 'sm' } onClick={ () => onDeleteClick(item) }>
        <i className="fas fa-trash"/> { trans('delete') }
      </Button>
      <Button variant={ 'info' } size={ 'sm' } onClick={ () => onUpdateClick(item) }>
        <i className="fas fa-edit"/> { trans('edit') }
      </Button>
      <Button variant={ 'outline-secondary' } size={ 'sm' } onClick={ () => onTestClick(item) }>
        <i className="fas fa-vial"/> { trans('test') }
      </Button>
    </ButtonGroup>
  ])

  const applyNewFilters = (withDelay = false) => {

    if (!withDelay)
      return dispatch(fetchItems())

    clearTimeout(fetchListRequest)
    setFetchListRequest(setTimeout(() => dispatch(fetchItems()), 1000))
  }

  const onSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    dispatch(setQuery(e.target.value))
    applyNewFilters(true)
  }

  const onPageClick = (currentPage: number, page: number) => {
    if (currentPage === page)
      return

    dispatch(setPage(page))
    applyNewFilters()
  }

  const onLimitChange = (limit: number) => {
    dispatch(setLimit(limit))
    applyNewFilters()
  }

  return <NavTable
    pagination={ pagination }
    rows={ getRows() }
    columnHeaders={ columnHeaders }
    onSearchInputChange={ onSearchInputChange }
    onLimitChange={ onLimitChange }
    onPageClick={ onPageClick }
    isLoading={ isLoading }
  />
}

export default Table
