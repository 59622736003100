import { User } from 'src/Types/User'
import { LogLevel } from 'src/Types/Log'

export interface AuditTrailLog {
    code: string
    level: LogLevel
    ip: string | null
    timestamp: Date
    context?: string | null
    contextIdentifier?: string | null
    workflowId?: string | null
    service?: string | null
    configId?: string | null
    data: any[] | null
    user: User | null
    impersonator: User | null
}

export enum AuditTrailLogFields {
    CODE = 'code',
    LEVEL = 'level',
    IP = 'ip',
    TIMESTAMP = 'timestamp',
    CONTEXT = 'context',
    CONTEXT_IDENTIFIER = 'contextIdentifier',
    WORKFLOW_ID = 'workflowId',
    SERVICE = 'service',
    CONFIG_ID = 'configId',
    DATA = 'data',
    USER = 'user',
    IMPERSONATOR = 'impersonator',
}
