export default {
  english: {
    lock: 'Lock',
    unlock: 'Unlock',
    filename: 'Filename',
    minimum: 'minimum',
    maximum: 'maximum',
    added: 'added',
    nameRequired: 'Name required',
    size: 'Size',
    addFiles: 'Add files',
    maxFileSize: 'Max size',
    fileMaxReach: 'No more file allowed for this file type.',
    sizeError: (maxSize: number) => `This file is too big ! The max file size is ${maxSize}MB.`
  },
  french: {
    lock: 'Verrouiller',
    unlock: 'Déverouiller',
    filename: 'Nom du fichier',
    minimum: 'minimum',
    maximum: 'maximum',
    added: 'ajouté(s)',
    nameRequired: 'Nom nécessaire',
    size: 'Size',
    addFiles: 'Ajouter des fichiers',
    maxFileSize: 'Taille maximum',
    fileMaxReach: 'Limite atteint pour ce type de fichier.',
    sizeError: (maxSize: number) => `Ce fichier est trop volumineux ! La taille maximum est ${maxSize}Mo.`
  }
}
