import {
  FETCH_FORM, SEND_IMPORT, SET_FORM, UPDATE_IMPORT_TASK
} from './actionTypes'

export const fetchForm = id => ({
  type: FETCH_FORM,
  payload: { id }
})
export const setForm = form => ({
  type: SET_FORM,
  payload: { form }
})
export const sendImport = (formId, data, userPin = null) => ({
  type: SEND_IMPORT,
  payload: { formId, data, userPin }
})

export const updateImportTask = (id, status, data = null) => ({
  type: UPDATE_IMPORT_TASK,
  payload: { id, status, data }
})
