import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Nav, Row, Tab } from 'react-bootstrap'
import { Link, useParams, useLocation } from 'react-router-dom'
import { translate } from 'src/Services/translation'
import { MODULE_SCHEDULING } from 'src/Services/Constants/Config/Modules'
import { getHeadersHeight, hasUserModulePermission } from 'src/Utils/index.ts'
import { getFormFillerOpenedPane } from 'src/Services/Store/Router/selectors'
import { PermissionAccessLevel } from 'src/Types/Permission'
import { init, reset } from './state/actions'
import PatientSidebar from './Components/PatientSidebar'
import PatientCalendar from './Components/PatientCalendar'
import PatientDocuments from './Components/PatientDocuments'
import PatientOverview from './Components/PatientOverview.tsx'
import translations from './translations'
import InstanceCreator from './Components/PatientSidebar/InstancesCreator'
import FormFiller from '../FormFiller/Components/FormFiller'
import { fetchInstance } from '../FormFiller/state/actions.ts'

const Patient = ({ children }) => {
  const { id: patientId, instanceId } = useParams()
  const dispatch = useDispatch()
  const location = useLocation()

  const { user, autoRefresh } = useSelector(state => state.Root)
  const { patient } = useSelector(state => state.Patient)
  const { isHeaderSticky } = useSelector(state => state.View)

  // TODO: replace with useParams, once we understand what `getFormFillerOpenedPane` does exactly
  const openedPane = getFormFillerOpenedPane(location)

  const trans = translate(translations)(user.language)
  const onPatientDetailsClick = () => dispatch(fetchInstance(patientId))

  const isPatientCurrentlyOpen = () => patientId === instanceId
  const headersHeight = getHeadersHeight(isHeaderSticky)

  useEffect(() => {
    // Do refresh only if patient ids are same (else second useEffect will do it)
    if (autoRefresh && patientId === patient?.id) {
      dispatch(init(patientId))
    }
  }, [ autoRefresh ])

  useEffect(() => {
    if (patientId && patientId !== patient?.id)
      dispatch(init(patientId))
  }, [ patientId ])

  // On unmount
  useEffect(() => {
    return () => {
      dispatch(reset())
    }
  }, [])

  return (
    <>
      { children }

      <Tab.Container activeKey={ openedPane }>
        <Row
          id={ 'patientToolbar' }
          className={ 'bg-body' }
          style={ {
            top: `${ headersHeight }px`,
          } }
        >
          <Col sm={ 3 } xl={ 2 } className={ 'align-items-center justify-content-center' }>
            <InstanceCreator />
          </Col>
          <Col className={ 'sm-auto' }>
            <Nav variant={ 'pills' } className={ 'justify-content-center' }>
              { openedPane === 'instance' && !isPatientCurrentlyOpen() && <Nav.Item className={ 'me-1' }>
                <Nav.Link eventKey={ 'instance' }>
                  <i className={ 'me-1 fas fa-scroll' }/>
                  { trans('form') }
                </Nav.Link>
              </Nav.Item> }
              <Nav.Item>
                <Nav.Link eventKey={ 'instance' } as={ Link }
                          to={ `/patient/${ patientId }/instance/${ patientId }` }
                          onClick={ onPatientDetailsClick }
                          active={ isPatientCurrentlyOpen() }
                >
                  <i className={ 'me-1 fas fa-address-card' }/>
                  { trans('patientDetails') }
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={ 'overview' } as={ Link } to={ `/patient/${ patientId }/overview` }>
                  <i className={ 'me-1 fas fa-search-plus' }/>
                  { trans('detailedOverview') }
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={ 'documents' } as={ Link } to={ `/patient/${ patientId }/documents` }>
                  <i className={ 'me-1 fas fa-file-alt' }/>
                  { trans('documents') }
                </Nav.Link>
              </Nav.Item>
              {
                hasUserModulePermission(user, MODULE_SCHEDULING, PermissionAccessLevel.READ) &&
                <Nav.Item>
                  <Nav.Link eventKey={ 'calendar' } as={ Link } to={ `/patient/${ patientId }/calendar` }>
                    <i className={ 'me-1 fas fa-calendar' }/>
                    { trans('calendar') }
                  </Nav.Link>
                </Nav.Item>
              }
            </Nav>
          </Col>
        </Row>

        <Row>
          <Col sm={ 3 } xl={ 2 }>
            <PatientSidebar/>
          </Col>
          <Col className={ 'sm-auto' }>
            <Tab.Content className={ 'justify-content-center' }>
              <Tab.Pane eventKey={ 'instance' }>
                <FormFiller/>
              </Tab.Pane>
              <Tab.Pane unmountOnExit mountOnEnter eventKey={ 'overview' }>
                <PatientOverview/>
              </Tab.Pane>
              { hasUserModulePermission(user, MODULE_SCHEDULING, PermissionAccessLevel.READ) &&
                <Tab.Pane unmountOnExit mountOnEnter eventKey={ 'calendar' }>
                  <PatientCalendar/>
                </Tab.Pane> }
              <Tab.Pane unmountOnExit mountOnEnter eventKey={ 'documents' }>
                <PatientDocuments/>
              </Tab.Pane>
              { /*<Tab.Pane unmountOnExit mountOnEnter eventKey={ 'find' }>
                <FindAppointment/>
              </Tab.Pane> */ }
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  )
}

export default Patient
