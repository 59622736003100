import { call, put, select, takeLatest, cancel } from 'redux-saga/effects'
import {
  DELETE_DOCUMENT,
  DOWNLOAD_DOCUMENT, FETCH_DASHBOARD_DATA,
  FETCH_DETAILS_OVERVIEW,
  FETCH_DOCUMENTS,
  FETCH_ENCOUNTERS,
  FETCH_INSTANCIATED_FORMS,
  FETCH_PATIENT_RELATED_FORMS,
  FETCH_SIDEBAR_EVENTS, INIT,
  SAVE_DOCUMENT_NAME
} from './actionTypes'
import apiMethods from 'src/Services/api/apiMethods'
import {
  documentNameSaved,
  documentsFetched,
  fetchDetailsOverviewSucceeded,
  setEncounters,
  setInstanciatedForms,
  setPatientRelatedForms,
  documentDeleted,
  setSidebarEvents,
  fetchInstanciatedForms,
  fetchPatientRelatedForms,
  fetchDashboardData, setPatient
} from './actions'
import { SAVE_INSTANCE_SUCCEEDED } from '../../FormFiller/state/actionTypes.ts'

function* init(props, { payload: { id } }) {
  try {
    yield put(fetchDashboardData(id))

    yield put(fetchPatientRelatedForms())
  } catch (error) {
    yield put(props.globalActions.handleError(error))
  }
}

function* doFetchDashboardData(props, { payload: { patientId } }) {
  try {
    if (patientId) {
      const { data } = yield call(apiMethods.get, `/patients/${ patientId }/dashboard_data`)

      if (data?.instances)
        yield put(setInstanciatedForms(data.instances))

      if (data?.overview)
        yield put(fetchDetailsOverviewSucceeded(data.overview))

      if (data?.events)
        yield put(setSidebarEvents(data.events))

      if (data?.patient)
        yield put(setPatient(data.patient))
    }
  } catch (error) {
    yield put(props.globalActions.handleError(error, error.toString()))
  }
}

function* deleteDocument(props, { payload }) {
  try {
    yield call(apiMethods.delete, `/files/${ payload }`)
    yield put(documentDeleted(payload))
  } catch (error) {
    yield put(props.globalActions.handleError(error, error.toString()))
  }
}

function* downloadDocument(props, { payload }) {
  try {
    yield call(apiMethods.downloadBlobFile, 'get', `/download/${ payload.id }`, {}, {}, payload.name)
  } catch (error) {
    yield put(props.globalActions.handleError(error))
  }
}

function* doFetchDetailsOverview(props, { payload: { patientId } }) {
  try {
    const { data } = yield call(apiMethods.get, `/patients/${ patientId }/sidebar/overview`)
    yield put(fetchDetailsOverviewSucceeded(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error))
  }
}

function* fetchDocuments(props, { payload }) {
  try {
    const { data } = yield call(apiMethods.get, `/patients/${ payload }/sidebar/documents`)
    yield put(documentsFetched(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error))
  }
}

function* fetchEncounters(props, { payload }) {
  try {
    const { data } = yield call(apiMethods.get, `/patients/${ payload }/sidebar/encounters`)
    yield put(setEncounters(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'fetchEncountersFailed'))
  }
}

function* doFetchInstanciatedForms(props, { payload: { patientId } }) {
  try {
    if (!patientId)
      yield cancel()

    const { data } = yield call(apiMethods.get, `/patients/${ patientId }/sidebar/instances`)
    yield put(setInstanciatedForms(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error))
  }
}

function* doFetchPatientRelatedForms(props, { payload }) {
  try {
    const { data } = yield call(apiMethods.get, '/forms/patient_related')
    yield put(setPatientRelatedForms(data.map(form => ({
      id: form.id,
      label: form.label
    }))))
  } catch (error) {
    yield put(props.globalActions.handleError(error))
  }
}

function* doFetchSidebarEvents(props, { payload: { patientId } }) {
  try {
    const { data } = yield call(apiMethods.get, `/patients/${ patientId }/sidebar/events`)
    yield put(setSidebarEvents(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error))
  }
}

function* saveDocumentName(props, { payload }) {
  const getEditedDocument = (state, documentId) =>
    state.editedDocumentsName.reduce(
      (acc, current) => current.documentId === documentId
        ? current
        : acc,
      null
    )
  try {
    const state = (yield select()).Patient
    const { documentId, originalDocumentName } = state

    const { documentName } = getEditedDocument(state, documentId)

    if (documentName === originalDocumentName) {
      put(documentNameSaved(documentId, documentName))
    } else {
      yield call(apiMethods.get, `/files/${ documentId }`)
      yield put(documentNameSaved(documentId))
    }
  } catch (error) {
    yield put(props.globalActions.handleError(error, error.toString()))
  }
}

function* onSaveInstanceSucceeded(props, { payload }) {
  try {
    const { id } = (yield select()).Patient
    yield put(fetchInstanciatedForms(id))
  } catch (error) {
    yield put(props.globalActions.handleError(error))
  }
}

export default function* FormListSagaWatcher(props) {
  yield takeLatest(INIT, init, props)
  yield takeLatest(FETCH_DASHBOARD_DATA, doFetchDashboardData, props)
  yield takeLatest(DELETE_DOCUMENT, deleteDocument, props)
  yield takeLatest(DOWNLOAD_DOCUMENT, downloadDocument, props)
  yield takeLatest(FETCH_DETAILS_OVERVIEW, doFetchDetailsOverview, props)
  yield takeLatest(FETCH_DOCUMENTS, fetchDocuments, props)
  yield takeLatest(FETCH_ENCOUNTERS, fetchEncounters, props)
  yield takeLatest(FETCH_INSTANCIATED_FORMS, doFetchInstanciatedForms, props)
  yield takeLatest(FETCH_PATIENT_RELATED_FORMS, doFetchPatientRelatedForms, props)
  yield takeLatest(FETCH_SIDEBAR_EVENTS, doFetchSidebarEvents, props)
  yield takeLatest(SAVE_DOCUMENT_NAME, saveDocumentName, props)
  yield takeLatest(SAVE_INSTANCE_SUCCEEDED, onSaveInstanceSucceeded, props)
}
