import {
  ITEMS_RECEIVED,
  ITEM_CREATED,
  ITEM_DELETED,
  FETCH_ITEMS,
  CREATE_ITEM, SET_QUERY,
  DELETE_ITEM, UPDATE_ITEM, ITEM_UPDATED, SET_LIMIT, SET_PAGE, SET_DELETING_ITEM, SET_UPDATING_ITEM, SET_CREATING_ITEM
} from './actionTypes'
import { Action } from 'src/Services/Store/reducers'
import MessageConfig from 'src/Types/MessageConfig'
import Pagination from 'src/Types/Pagination'

export interface MessageConfigsState {
  isLoading: boolean
  items: MessageConfig[]
  creatingItem: MessageConfig | null
  updatingItem: MessageConfig | null
  deletingItem: MessageConfig | null
  query: string | null
  pagination: Pagination
}

const initialState: MessageConfigsState = {
  isLoading: false,
  creatingItem: null,
  updatingItem: null,
  deletingItem: null,
  items: [],
  query: null,
  pagination: {
    offset: 0,
    limit: 10,
    itemsCount: 10,
    totalItemsCount: 10,
    pageLimit: 10,
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPagesCount: 1,
  },
}

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case FETCH_ITEMS:
      return {
        ...state,
        isLoading: true
      }
    case CREATE_ITEM:
      return {
        ...state,
        isLoading: true
      }
    case UPDATE_ITEM:
      return {
        ...state,
        isLoading: true
      }
    case DELETE_ITEM:
      return {
        ...state,
        isLoading: true
      }
    case ITEMS_RECEIVED:
      return {
        ...state,
        isLoading: false,
        items: payload.items,
        pagination: payload.pagination
      }
    case ITEM_CREATED:
      return {
        ...state,
        isLoading: false,
        creatingItem: null,
        items: [ ...state.items, payload ]
      }
    case ITEM_UPDATED:
      return {
        ...state,
        isLoading: false,
        updatingItem: null,
        items: state.items.map(_ => _.id === payload.id ? payload : _)
      }
    case ITEM_DELETED:
      return {
        ...state,
        isLoading: false,
        deletingItem: null,
        items: state.items.filter(_ => _.id !== payload)
      }
    case SET_DELETING_ITEM:
      return {
        ...state,
        deletingItem: payload
      }
    case SET_UPDATING_ITEM:
      return {
        ...state,
        updatingItem: payload
      }
    case SET_CREATING_ITEM:
      return {
        ...state,
        creatingItem: payload
      }
    case SET_QUERY:
      return {
        ...state,
        query: payload
      }
    case SET_LIMIT:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          pageLimit: +payload,
          currentPage: 1
        }
      }
    case SET_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: payload
        }
      }
    default:
      return state
  }
}
