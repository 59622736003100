export const LOAD_LISTS = '[LISTS] LOAD_LISTS'
export const RECEIVE_LISTS = '[LISTS] RECEIVE_LISTS'
export const SET_SELECTED_TYPE = '[LISTS] SET_SELECTED_TYPE'
export const SET_SELECTED_LIST_FORM = '[LISTS] SET_SELECTED_LIST_FORM'
export const CHANGE_LIMIT = '[LISTS] CHANGE_LIMIT'
export const CHANGE_PAGE = '[LISTS] CHANGE_PAGE'
export const TOGGLE_ADDING_LIST = '[LISTS] TOGGLE_ADDING_LIST'
export const ADD_NEW_LIST = '[LISTS] ADD_NEW_LIST'
export const NEW_LIST_ADDED = '[LISTS] NEW_LIST_ADDED'
export const DELETE_LIST = '[LISTS] DELETE_LIST'
export const ERROR_DELETING_LIST = '[LISTS] ERROR_DELETING_LIST'
export const LIST_DELETED = '[LISTS] LIST_DELETED'
export const SEARCH_LIST = '[LISTS] SEARCH_LIST'

export const REQUEST_CLONE_LIST = '[LISTS] REQUEST_CLONE_LIST'
export const CLONE_LIST = '[LISTS] CLONE_LIST'
