import {
  FETCH_DELETED_FORMS,
  RECIEVE_DELETED_FORMS,
  CHANGE_PAGE,
  CHANGE_LIMIT,
  SEARCH_DELETED_FORM
} from './actionTypes'

const initialState = {
  loading: false,
  deletedForms: [],
  searchQuery: null,
  pagination: {
    offset: 0,
    limit: 10,
    itemsCount: 10,
    totalItemsCount: 10,
    pageLimit: 10,
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPagesCount: 1,
  },
}

export default (state = initialState, {
  type,
  payload
}) => {
  switch (type) {
    case FETCH_DELETED_FORMS:
      return {
        ...state,
        loading: true
      }
    case RECIEVE_DELETED_FORMS:
      return {
        ...state,
        deletedForms: payload.slotTypes,
        loading: false,
        pagination: payload.pagination
      }
    case CHANGE_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: payload
        }
      }
    case CHANGE_LIMIT:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          pageLimit: Number(payload),
          currentPage: 1
        }
      }
    case SEARCH_DELETED_FORM:
      return {
        ...state,
        searchQuery: payload,
        pagination: {
          ...state.pagination,
          currentPage: initialState.pagination.currentPage,
          offset: initialState.pagination.offset,
        }
      }
    default:
      return state
  }
}
