import React from 'react'
import { Form } from 'react-bootstrap'

const PageSizeSelect = ({ table, trans, totalRowsCount }) =>
  <div className="table-size-select">
    <Form.Group>
      <Form.Select size="sm" value={ table.getState().pagination.pageSize }
                   onChange={ e => table.setPageSize(Number(e.target.value)) }
                   style={ { maxWidth: '70px' } }
      >
        { [ 10, 20, 30, 40, 50 ].map(pageSize =>
          <option key={ pageSize } value={ pageSize }>{ pageSize }</option>) }
      </Form.Select>
    </Form.Group>
    <div className="total-items-count">
      { trans('of') } { totalRowsCount || 0 } { trans('results') }
    </div>
  </div>

export default PageSizeSelect
