import {
  ITEMS_RECEIVED,
  ITEM_CREATED,
  ITEM_DELETED,
  ITEM_UPDATED,
  ITEM_RECEIVED,
  FETCH_ITEMS,
  FETCH_ITEM,
  CREATE_ITEM,
  SET_DELETING_ITEM,
  SET_UPDATING_ITEM,
  SET_CREATING_ITEM,
  DELETE_ITEM,
  UPDATE_ITEM,
  SET_LIMIT,
  SET_PAGE,
  SET_QUERY,
  ON_SUCCESS_RESPONSE,
  ON_FAILED_RESPONSE,
  FETCH_ITEMS_EVERYBODY_TASKS,
  FETCH_ITEMS_MY_TASKS, SORT_TASKS
} from './actionTypes'

export const fetchItems = () => ({
  type: FETCH_ITEMS
})
export const fetchItemsEverybodyTasks = () => ({
  type: FETCH_ITEMS_EVERYBODY_TASKS,
  payload: { url: '/everybody_tasks'}
})
export const fetchItemsMyTasks = () => ({
  type: FETCH_ITEMS_MY_TASKS,
  payload: { url: '/my_tasks'}
})

export const fetchItem = (id) => ({
  type: FETCH_ITEM,
  payload: id
})
export const createItem = () => ({
  type: CREATE_ITEM
})
export const deleteItem = () => ({
  type: DELETE_ITEM
})
export const updateItem = () => ({
  type: UPDATE_ITEM
})

export const onSuccessResponse = () => ({
  type: ON_SUCCESS_RESPONSE
})
export const onErrorResponse = (errors) => ({
  type: ON_FAILED_RESPONSE,
  payload: errors
})

export const itemsReceived = (items) => ({
  type: ITEMS_RECEIVED,
  payload: items
})
export const itemReceived = (item) => ({
  type: ITEM_RECEIVED,
  payload: item
})
export const itemCreated = (item) => ({
  type: ITEM_CREATED,
  payload: item
})
export const itemDeleted = (id) => ({
  type: ITEM_DELETED,
  payload: id
})
export const itemUpdated = (item) => ({
  type: ITEM_UPDATED,
  payload: item
})

export const setDeletingItem = (item) => ({
  type: SET_DELETING_ITEM,
  payload: item
})
export const setUpdatingItem = (item) => ({
  type: SET_UPDATING_ITEM,
  payload: item
})
export const setCreatingItem = (item) => ({
  type: SET_CREATING_ITEM,
  payload: item
})

export const setQuery = (query) => ({
  type: SET_QUERY,
  payload: query
})
export const setLimit = (limit) => ({
  type: SET_LIMIT,
  payload: limit
})
export const setPage = (page) => ({
  type: SET_PAGE,
  payload: page
})

export const sortTasks = (payload) => ({
  type: SORT_TASKS,
  payload
})
