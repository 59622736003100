
export default {
  english: {
    title: 'Event types',
    form: {
      label: 'Label',
      systemName: 'System name',
      systemNameInfo: 'Must be in uppercase with underscore as spaces',
      defaultStartTime: 'Default start time',
      defaultDuration: 'Default duration (minutes)',
      form: 'Form',
      list: 'Customization list',
      isSlotRequired: 'Is slot required',
      resources: 'Resource types',
    },
    modal: {
      create: {
        title: 'Create a new type'
      },
      update: {
        title: 'Update a type'
      },
      confirmDelete: {
        title: 'Are you sure you want to delete this type ?'
      }
    }
  },
  french: {
    title: 'Type d\'évènements',
    form: {
      label: 'Label',
      systemName: 'Nom système',
      systemNameInfo: 'Doit être en majuscule avec des tirets à la place des espaces.',
      defaultStartTime: 'Horaire de début par defaut',
      defaultDuration: 'Durée par defaut (minutes)',
      form: 'Formulaire',
      list: 'Liste de personnalisation',
      isSlotRequired: 'Créneau nécessaire',
      resources: 'Types de ressources',
    },
    modal: {
      create: {
        title: 'Créer un nouveau type'
      },
      update: {
        title: 'Éditer un type'
      },
      confirmDelete: {
        title: 'Êtes-vous sûr de supprimer ce type ?'
      }
    }
  }
}
