import {
  WORKFLOW_CREATED,
  WORKFLOW_DELETED,
  WORKFLOW_UPDATED,
  WORKFLOW_RECEIVED,
  CREATE_WORKFLOW,
  SET_DELETING_WORKFLOW,
  SET_UPDATING_WORKFLOW,
  DELETE_WORKFLOW,
  UPDATE_WORKFLOW,
  TOGGLE_CREATE_WORKFLOW_MODAL
} from './actionTypes'

export const createItem = item => ({
  type: CREATE_WORKFLOW,
  payload: item
})

export const deleteItem = () => ({
  type: DELETE_WORKFLOW
})

export const updateItem = item => ({
  type: UPDATE_WORKFLOW,
  payload: item
})

export const itemReceived = item => ({
  type: WORKFLOW_RECEIVED,
  payload: item
})

export const itemCreated = item => ({
  type: WORKFLOW_CREATED,
  payload: item
})

export const itemDeleted = id => ({
  type: WORKFLOW_DELETED,
  payload: id
})

export const itemUpdated = item => ({
  type: WORKFLOW_UPDATED,
  payload: item
})

export const setDeletingItem = item => ({
  type: SET_DELETING_WORKFLOW,
  payload: item
})

export const setUpdatingItem = item => ({
  type: SET_UPDATING_WORKFLOW,
  payload: item
})

export const toggleCreateWorkflowModal = () => ({
  type: TOGGLE_CREATE_WORKFLOW_MODAL
})
