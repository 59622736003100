export default {
  english: {
    title: 'Translation configurator',
    addNew: 'Add',
    entries: 'of {total} entries',
    form: {
      code: 'Code',
      language: 'Language',
      content: 'Content',
    },
    column: {
      code: 'Code',
      language: 'Language'
    },
    modal: {
      create: {
        title: 'Create a new translation',
      },
      update: {
        title: 'Update a translation',
      },
      confirmDelete: {
        title: 'Are you sure you want to delete this translation ?'
      }
    }
  },
  french: {
    title: 'Configurateur de traduction',
    addNew: 'Créer',
    entries: 'sur {total} entrées',
    form: {
      code: 'Code',
      language: 'Langage',
      content: 'Contenu',
    },
    column: {
      code: 'Code',
      language: 'Langage'
    },
    modal: {
      create: {
        title: 'Créer une nouvelle traduction'
      },
      update: {
        title: 'Modifier une traduction'
      },
      confirmDelete: {
        title: 'Êtes-vous sûr de vouloir supprimer cette traduction ?'
      }
    }
  }
}
