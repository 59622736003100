import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logMessage } from '../Store/Root/actions'
import { eventSourceMessage, subscribeEventSource, closeEventSource } from './state/actions'
import { isLocalhost } from 'src/Utils/index.ts'
import { GLOBAL_PREFIX } from 'src/Services/EventSourceManager/topics'

const eventSources = {}

const EventSourceManager = () => {
  const dispatch = useDispatch()
  const { config } = useSelector(state => state.Root)

  const { eventSourceTopicWaitingForSubscribe, eventSourceTopicWaitingForClose } =
    useSelector(state => state.EventSourceManager)

  React.useEffect(() => {
    if (eventSourceTopicWaitingForSubscribe) {
      initEventSource(eventSourceTopicWaitingForSubscribe)
      dispatch(subscribeEventSource(null))
    }
    if (eventSourceTopicWaitingForClose) {
      closeEventSources(eventSourceTopicWaitingForClose)
      dispatch(closeEventSource(null))
    }
  }, [ eventSourceTopicWaitingForSubscribe, eventSourceTopicWaitingForClose ])

  const initEventSource = topic => {

    if (!config.MERCURE_HUB_PUBLIC_URL)
      return dispatch(logMessage('MERCURE_HUB_PUBLIC_URL not found, skipping event source creation.', 'warning'))

    const eventSourceUrl = new URL(config.MERCURE_HUB_PUBLIC_URL)
    eventSourceUrl.searchParams.append('topic', GLOBAL_PREFIX + config.PORTAL_NAME + '/' + topic)

    const eventSourceConfig = {}

    if (!isLocalhost(config.MERCURE_HUB_PUBLIC_URL))
      eventSourceConfig.withCredentials = true

    const eventSource = new EventSource(eventSourceUrl, eventSourceConfig)

    eventSource.onmessage = event => {
      const message = JSON.parse(event.data) || null
      dispatch(eventSourceMessage(topic, message))
    }

    eventSources[topic] = eventSource
  }

  const closeEventSources = topic => {
    if (eventSources[topic])
      eventSources[topic].close()
  }

  return <></>
}

export default EventSourceManager
