export const formatPlaceResponse = place => {
  const finder = findAddressComponentByNames(place)
  return {
    label: place.formatted_address,
    number: finder([ 'street_number' ]),
    street: finder([ 'street_address', 'route', 'colloquial_area' ]),
    postalCode: finder([ 'postal_code' ]),
    city: finder([ 'locality' ]),
    country: finder([ 'country' ]),
    details: finder([ 'premise', 'subpremise' ]),
    lat: place.geometry.location.lat(),
    lng: place.geometry.location.lng(),
  }}

const findAddressComponentByNames = place => names =>
  place.address_components.find(({ types }) =>
    names.some(name => types.includes(name))
  )?.long_name || null
