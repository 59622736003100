import { call, select, put, takeLatest } from 'redux-saga/effects'
import { hasUserModulePermission, isLocalhost } from 'src/Utils/index.ts'
import AppSentry from 'src/Services/Sentry/index.ts'
import { setFilters } from 'src/Components/Calendar/state/actions'
import { PermissionAccessLevel } from 'src/Types/Permission'
import {
  configTranslationsFetched, getAnnouncements,
  getConfigTranslations, setAnnouncements,
  setCurrentUser
} from './actions'
import * as types from './actionTypes'
import apiMethods from '../../api/apiMethods'
import { MODULE_ANNOUNCEMENTS } from '../../Constants/Config/Modules'
import { showError } from '../../notifier/actions/index'
import { censorData } from 'src/Utils/Security'
import { authGranted } from 'src/Views/Auth/state/actions.ts'

function* getConfig(props) {
  // todo do http call for /config.json here
}

function* fetchCurrentUser(props) {
  try {
    const { config } = (yield select()).Root

    const params = {}

    if (config.MERCURE_HUB_PUBLIC_URL && !isLocalhost(config.MERCURE_HUB_PUBLIC_URL))
      params.requestMercureAccess = true

    const { data } = yield call(apiMethods.get, '/me', params)

    // This is the first request, if its successes, user is surely authenticated
    yield put(authGranted())

    yield put(setFilters(data.calendarFilters))
    yield put(setCurrentUser(data))
  } catch (error) {
    yield put(setCurrentUser(null))
    yield put(props.globalActions.handleError(error.message, null, error.stack))
  }
}

function* fetchAnnouncements(props) {
  try {
    const { data } = yield call(apiMethods.get, '/announcements/display')
    yield put(setAnnouncements(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error))
  }
}

function* fetchConfigTranslations(props) {
  try {
    const { user } = (yield select()).Root
    const language = `${ user.locale }_${ user.language }`
    const { data } = yield call(apiMethods.get, `/translations/language/${ language }`)
    yield put(configTranslationsFetched(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error))
  }
}

function* onCurrentUserSet(props) {
  try {
    const { configTranslationsFetchRequested, user } = (yield select()).Root

    if (configTranslationsFetchRequested)
      yield put(getConfigTranslations())

    if (hasUserModulePermission(user, MODULE_ANNOUNCEMENTS, PermissionAccessLevel.READ))
      yield put(getAnnouncements())
  } catch (error) {
    yield put(props.globalActions.handleError(error))
  }
}

function* doHandleError(props, { payload: { error, translationKey, data } }) {
  try {
    const { config } = (yield select()).Root
    const { sensitiveFields } = (yield select()).Dictionary

    if (data)
      data = censorData(data, sensitiveFields)

    AppSentry.captureError(error, data, config.DEBUG_STATE || 0)

    if (translationKey && error.isMoreInformationNeeded !== false)
      yield put(showError(translationKey))
  } catch (error) {
    AppSentry.captureError(error)
  }
}

function* doLogMessage(props, { payload: { message, level, data } }) {
  try {
    const { config } = (yield select()).Root
    const { sensitiveFields } = (yield select()).Dictionary

    if (data)
      data = censorData(data, sensitiveFields)

    AppSentry.captureMessage(message, level, data, config.DEBUG_STATE || 0)
  } catch (error) {
    yield put(props.globalActions.handleError(error))
  }
}

export default function* userListSagaWatcher(props) {
  yield takeLatest(types.GET_CURRENT_USER, fetchCurrentUser, props)
  yield takeLatest(types.GET_ANNOUNCEMENTS, fetchAnnouncements, props)
  yield takeLatest(types.GET_CONFIG_TRANSLATIONS, fetchConfigTranslations, props)
  yield takeLatest(types.SET_CURRENT_USER, onCurrentUserSet, props)
  yield takeLatest(types.HANDLE_ERROR, doHandleError, props)
  yield takeLatest(types.LOG_MESSAGE, doLogMessage, props)
}
