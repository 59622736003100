import React, { useState } from 'react'
import { FormControl } from 'react-bootstrap'

const PinInput = ({ charCount = 4, id = 'pinInput', onChange }) => {
  const [ value, setValue ] = useState(new Array(charCount).fill(''))

  const getInputId = i => `${id}${i}`

  const onInputChange = i => e => {
    if (i >= charCount) return

    let newValue = value
    newValue[i] = e.currentTarget.value?.trim()
      ? e.currentTarget.value.slice(0,1).toUpperCase() : ''

    setValue(newValue)

    document.getElementById(getInputId(++i))?.focus()

    return onChange(newValue.join(''))
  }

  return <div id={id} className={'pin-input-container d-flex align-items-center justify-content-center'}>
    { value.map((val, i) =>
      <FormControl key={i}
             data-i={i}
             type="text"
             autoComplete="off"
             name={getInputId(i)}
             id={getInputId(i)}
             onChange={onInputChange(i)}
             value={val ? '•' : ''}
             className={`pin-input ${val ? 'pin-input-filled' : ''} me-2`}
      />) }
  </div>
}

export default PinInput
