import { Form } from 'react-bootstrap'
import React from 'react'

/*this field allow to verify that react-select is required because react select html5 valididty not work*/
/* https://github.com/JedWatson/react-select/issues/3140*/
const ValidationTrigger = ({ isRequired = true, isValid = false }) => {

  return <>
    { isRequired && !isValid &&
      <div style={ { position: 'relative', opacity: 0, width: 0, height: 0 } }>
        <Form.Control tabIndex={ -1 }
                      autoComplete="off"
                      style={ { top: '-13px', left: 0, height: 0, position: 'absolute' } }
                      required
        />
      </div>
    }
  </>
}

export default ValidationTrigger
