import {
  DELETE_FORM_PERMISSION,
  FETCH_FORMS,
  FETCH_LANDING_PAGES,
  FETCH_LISTS, FETCH_LOG_AS_ROLES,
  FETCH_NUMERIC_REPORTS,
  FETCH_USER,
  FORM_PERMISSION_DELETED,
  FORM_PERMISSIONS_SUBMIT, FORM_PERMISSIONS_SUBMIT_SET_STATE,
  NEW_PERMISSIONS_SAVED,
  RESET,
  SAVE_NEW_SUBJECT_DATA,
  SET_FORMS,
  SET_HAS_DATA_CHANGED,
  SET_LANDING_PAGES,
  SET_LISTS, SET_LOG_AS_ROLES,
  SET_NEW_SUBJECT_DATA,
  SET_NUMERIC_REPORTS,
  SET_ROLE,
  SET_SUBJECT,
  SET_SUBJECT_DATA,
  SET_USER,
} from './actionTypes'
import { User } from 'src/Types/User'
import Role from 'src/Types/Role'
import NumericReport from 'src/Types/NumericReport'
import { List } from 'src/Types/List'
import { Form } from 'src/Types/Form'
import LandingPage from 'src/Types/LandingPage'
import { PermissionSubject } from 'src/Views/Permissions/Types/Subject'
import Permission from 'src/Types/Permission'
import CompleteFormPermissions from 'src/Views/Permissions/Types/CompleteFormPermissions'

/*export const fetchAccessLevels = () => ({
  type: FETCH_ACCESS_LEVELS
})*/
export const fetchLandingPages = () => ({
  type: FETCH_LANDING_PAGES
})
export const fetchLists = () => ({
  type: FETCH_LISTS
})
export const fetchForms = () => ({
  type: FETCH_FORMS
})
export const fetchNumericReports = () => ({
  type: FETCH_NUMERIC_REPORTS
})
export const fetchLogAsRoles = () => ({
  type: FETCH_LOG_AS_ROLES
})
export const fetchUser = (id: string) => ({
  type: FETCH_USER,
  payload: id
})

export const saveNewSubjectData = () => ({
  type: SAVE_NEW_SUBJECT_DATA
})
export const newPermissionsSaved = (subject: PermissionSubject) => ({
  type: NEW_PERMISSIONS_SAVED,
  payload: { subject }
})

/*export const setAccessLevels = payload => ({
  type: SET_ACCESS_LEVELS,
  payload
})*/
export const setLandingPages = (payload: LandingPage[]) => ({
  type: SET_LANDING_PAGES,
  payload
})
export const setLists = (payload: List[]) => ({
  type: SET_LISTS,
  payload
})
export const setForms = (forms: Form[]) => ({
  type: SET_FORMS,
  payload: { forms }
})
export const setNumericReports = (payload: NumericReport[]) => ({
  type: SET_NUMERIC_REPORTS,
  payload
})
export const setLogAsRoles = (payload: Role[]) => ({
  type: SET_LOG_AS_ROLES,
  payload
})

export const setHasDataChanged = (payload: boolean) => ({
  type: SET_HAS_DATA_CHANGED,
  payload
})
export const setRole = (payload: Role) => ({
  type: SET_ROLE,
  payload
})
export const setUser = (payload: User) => ({
  type: SET_USER,
  payload
})
export const setSubject = (payload: PermissionSubject) => ({
  type: SET_SUBJECT,
  payload
})
export const setSubjectData = (
    payload: Permission[] | CompleteFormPermissions
) => ({
  type: SET_SUBJECT_DATA,
  payload
})
export const setNewSubjectData = (
    data: Permission[] | CompleteFormPermissions,
    subject: PermissionSubject = null
) => ({
  type: SET_NEW_SUBJECT_DATA,
  payload: { data, subject }
})

export const reset = () => ({
  type: RESET
})

export const deleteFormPermission = (id: string) => ({
  type: DELETE_FORM_PERMISSION,
  payload: { id }
})
export const formPermissionDeleted = () => ({
  type: FORM_PERMISSION_DELETED
})

export const setPermissionsFormSubmit = (payload: () => null) => ({
  type: FORM_PERMISSIONS_SUBMIT,
  payload
})

export const setPermissionsFormSubmitState = (payload: boolean) => ({
  type: FORM_PERMISSIONS_SUBMIT_SET_STATE,
  payload
})
