import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { translate } from 'src/Services/translation'
import Modal from 'src/Components/Modal'
import { useSelector } from 'src/Services/Store'
import SystemNameInput from 'src/Components/SystemNameInput'
import { TYPES_ALLOWED_FOR_CREATION } from 'src/Services/Constants/Form'
import translation from '../translations'
import { toggleAddForm, addForm } from '../state/actions'

const AddModal = () => {
  const dispatch = useDispatch()
  const { addFormDialogOpened, addFormError, addingForm } = useSelector(state => state.FormList)
  const { language } = useSelector(state => state.Root.user)
  const trans = translate(translation)(language)

  const [ label, setLabel ] = React.useState(null)
  const [ isAvailable, setIsAvailable ] = useState(null)

  const onClose = () => dispatch(toggleAddForm())

  const onSubmit = e => {
    e.preventDefault()

    const data = {
      systemName: e.target.systemName.value || null,
      label: e.target.label.value || null,
      type: e.target.type.value
    }

    dispatch(addForm(data))

    setLabel(null)
    setIsAvailable(null)
  }

  const onSystemNameChange = (systemName, isAvailable) => {
    setIsAvailable(isAvailable)
  }

  return <Modal title={ trans('modal.newForm.title') }
                onClose={ onClose }
                isOpen={ addFormDialogOpened }
  >
    <Form onSubmit={ onSubmit }>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>{ trans('label') }</Form.Label>
            <Form.Control id="label" name="label" required placeholder={trans('label')}
                          onChange={ e => setLabel(e.target.value) }/>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>{ trans('modal.newForm.formName') }</Form.Label>
            <SystemNameInput required label={ label } model={ 'Form' } placeholder={trans('modal.newForm.formName')}
                             onChange={ onSystemNameChange }/>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <Form.Group>
            <Form.Label>{ trans('modal.newForm.type') }</Form.Label>
            <Form.Select
              id="type"
              name="type"
              required
            >
              { TYPES_ALLOWED_FOR_CREATION.map(type => <option key={ type } value={ type }>{ type }</option>) }
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Form.Text className="text-danger">{ addFormError.global }</Form.Text>
      <Button variant="primary" type={ 'submit' } disabled={!isAvailable}
              className={ `float-right mt-2 ${ addingForm ? ' is-loading' : '' }` }>
        { trans('save') }
      </Button>
    </Form>
  </Modal>
}

export default AddModal
