import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { fetchRecentViewedaction } from '../state/actions'
import translation from '../translations'
import { translate } from '../../../Services/translation'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../../Services/Store'

const RecentViewed = ({ className = '' }) => {
  const dispatch = useDispatch()

  const { language } = useSelector((state) => state.Root?.user)
  const {
    recentViewed,
    recentViewedFetchError
  } = useSelector((state) => state.Homepage)

  useEffect(() => {
    dispatch(fetchRecentViewedaction())
  }, [ dispatch ])

  const trans = translate(translation)(language)

  return recentViewedFetchError
      ? trans('recentViewedFetchError')
      : renderList(recentViewed)
}

const renderList = (recentViewed) => (
  <ul>
    { recentViewed
      .filter((viewed) => viewed && viewed.form && viewed.id)
      .map((viewed, idx) => (
        <li key={ `${ viewed.id }_${ idx }` }>
          <Link to={ generateLink(viewed) }>{ viewed.identityString || viewed.title }</Link>
        </li>
      )) }
  </ul>
)

const generateLink = (instance) =>
  instance.patientId
    ? `/patient/${ instance.patientId }/instance/${ instance.id }`
    : `/instance/${ instance.id }`

export default RecentViewed
