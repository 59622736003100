import React  from 'react'
import { Form as BForm, Button, Row, Col } from 'react-bootstrap'
import EntitySelect from '../../../Components/EntitySelect'

const Form = ({
  trans,
  onSubmit = () => {},
  item = null
}) => {
  const onFormSubmit = e => {
    e.preventDefault()
    onSubmit({
      ...item,
      name: e.target.name.value,
      label: e.target.label.value,
      description: e.target.description.value,
      form: e.target.form.value
    })
  }

  return (
    <BForm onSubmit={onFormSubmit}>
      <Row className={'mb-2'}>
        <Col>
          <BForm.Group>
            <BForm.Label>{trans('form.name')}</BForm.Label>
            <BForm.Control defaultValue={item?.name} name={'name'} required type="text"
                          placeholder={trans('form.name')}/>
          </BForm.Group>
        </Col>
        <Col>
          <BForm.Group>
            <BForm.Label>{trans('form.label')}</BForm.Label>
            <BForm.Control defaultValue={item?.label} name={'label'} required type="text"
                          placeholder={trans('form.label')}/>
          </BForm.Group>
        </Col>
      </Row>
      <Row className={'mb-2'}>
        <Col>
          <BForm.Group>
            <BForm.Label>{trans('form.description')}</BForm.Label>
            <BForm.Control as={'textarea'} defaultValue={item?.description} required name={'description'}
                           placeholder={trans('form.description')}/>
          </BForm.Group>
        </Col>
      </Row>

      <Row className={'mb-2'}>
        <Col>
          <BForm.Group>
            <BForm.Label>{trans('form.form')}</BForm.Label>
            <EntitySelect defaultValue={item?.form} fetchUrl={'/forms'} name={'form'} required labelKey={ 'label' } />
          </BForm.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Button variant={'success'} style={{ float: 'right' }} type={'submit'}>{trans('submit')}</Button>
        </Col>
      </Row>
    </BForm>
  )
}

export default Form
