import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleError } from 'src/Services/Store/Root/actions'
import * as consts from 'src/Services/Constants'
import { translate } from 'src/Services/translation'
import translation from 'src/Views/FormFiller/translations.ts'
import TextField from 'src/Views/FormFiller/Components/Fields/TextField'
import EssayField from 'src/Views/FormFiller/Components/Fields/EssayField'
import SelectField from 'src/Views/FormFiller/Components/Fields/SelectField'
import CheckboxesField from './Fields/CheckboxesField'
import DateTimeField from './Fields/DateTimeField'
import ExtensibleField from './Fields/ExtensibleField'
import SentenceField from './Fields/SentenceField'
import PsgField from './Fields/PsgField'
import RadioField from './Fields/RadioField.tsx'
import ReferenceField from './Fields/ReferenceField/ReferenceField'
import FileField from './Fields/FileField/index.tsx'
import ButtonField from './Fields/ButtonField/ButtonField'
import PhoneField from './Fields/PhoneField'
import HiddenField from './Fields/HiddenField'
import UsernameField from './Fields/UsernameField'
import InstanceId from './Fields/InstanceId'
import EmailField from './Fields/EmailField'
import DocumentField from './Fields/DocumentField'
import SignatureField from './Fields/SignatureField'
import CalculationField from './Fields/CalculationField.tsx'
import AddressField from './Fields/AddressField'
import HtmlContentField from './Fields/HtmlContentField'
import ConsentField from './Fields/ConsentField'
import MultiSelectField from './Fields/MultiSelectField'
import JsonField from './Fields/JsonField'

const FieldComponentResolver = ({ field, isDisabled = false, isLoading = false, isRequired = false }) => {
  const dispatch = useDispatch()
  const { language } = useSelector(state => state.Root.user)
  const trans = translate(translation)(language)

  if (!field?.id) {
    dispatch(handleError(new Error('FieldComponentResolver: Field incorrect'), null, { field }))
    return <div>{ trans('unknownField') }</div>
  }

  switch (field?.type?.baseFieldType) {
    case consts.FIELD_BASE_TYPE_CHECKBOX:
      return <CheckboxesField id={ field.id }
                              field={ field }
                              label={ field.label }
                              value={ field.value }
                              disabled={ isDisabled }
                              isRequired={ isRequired }
      />
    case consts.FIELD_BASE_TYPE_DATE_TIME:
      return <DateTimeField id={ field.id }
                            field={ field }
                            options={ field.options }
                            value={ field.value }
                            disabled={ isDisabled }
                            isRequired={ isRequired }
      />
    case consts.FIELD_BASE_TYPE_DROPDOWN:
      return <SelectField field={ field }
                          isDisabled={ isDisabled }
                          isRequired={ isRequired }
      />
    case consts.FIELD_BASE_TYPE_MULTISELECT:
      return <MultiSelectField field={ field }
                          disabled={ isDisabled }
                          isRequired={ isRequired }
      />
    case consts.FIELD_BASE_TYPE_EXTENSIBLE:
      return <ExtensibleField field={ field }
                              isDisabled={ isDisabled }
      />
    case consts.FIELD_BASE_TYPE_SENTENCE:
      return <SentenceField field={ field } />
    case consts.FIELD_BASE_TYPE_PSG_DATA:
      return <PsgField id={ field.id }
                       field={ field }
                       value={ field.value }
                       isRequired={ isRequired }
      />
    case consts.FIELD_BASE_TYPE_FILE:
      return <FileField field={ field } isRequired={ isRequired } isDisabled={ isDisabled } />
    case consts.FIELD_BASE_TYPE_RADIO:
      return <RadioField field={ field } isDisabled={ isDisabled } isRequired={ isRequired } />
    case consts.FIELD_BASE_TYPE_REFERENCE:
      return <ReferenceField field={ field } isDisabled={ isDisabled } isRequired={ isRequired } />
    case consts.FIELD_BASE_TYPE_TEXT:
      return <TextField field={ field } isDisabled={ isDisabled } isRequired={ isRequired } />
    case consts.FIELD_BASE_TYPE_SIGNATURE:
      return <SignatureField id={ field.id }
                             field={ field }
                             trans={ trans }
                             value={ field.value }
                             disabled={ isDisabled }
                             isRequired={ isRequired }
      />
    case consts.FIELD_BASE_TYPE_INSTANCE_ID:
      return <InstanceId id={ field.id }
                         field={ field }
                         value={ field.value }
                         disabled={ isDisabled }
      />
    case consts.FIELD_BASE_TYPE_PHONE:
      return <PhoneField id={ field.id }
                         field={ field }
                         value={ field.value }
                         disabled={ isDisabled }
                         isRequired={ isRequired }
      />
    case consts.FIELD_BASE_TYPE_HIDDEN:
      return <HiddenField id={ field.id }
                          field={ field }
                          value={ field.value }
                          disabled={ isDisabled }
      />
    case consts.FIELD_BASE_TYPE_USERNAME:
      return <UsernameField id={ field.id }
                            field={ field }
                            value={ field.value }
                            disabled={ isDisabled }
                            isRequired={ isRequired }
      />
    case consts.FIELD_BASE_TYPE_EMAIL:
      return <EmailField id={ field.id }
                         field={ field }
                         value={ field.value }
                         disabled={ isDisabled }
                         isRequired={ isRequired }
      />
    case consts.FIELD_BASE_TYPE_ESSAY:
      return <EssayField field={ field } isDisabled={ isDisabled } isRequired={ isRequired } />
    case consts.FIELD_BASE_TYPE_JSON:
      return <JsonField field={ field } isDisabled={ isDisabled } isRequired={ isRequired } />
    case consts.FIELD_BASE_TYPE_DOCUMENT:
      return <DocumentField field={ field }
                            isDisable={ isDisabled }
                            isRequired={ isRequired }
      />
    case consts.FIELD_BASE_TYPE_BUTTON:
      return <ButtonField field={ field }
                          isLoading={ isLoading }
                          isDisable={ isDisabled }
      />
    case consts.FIELD_BASE_TYPE_ADDRESS:
      return <AddressField field={ field }
                           dispatch={ dispatch }
                           isDisable={ isDisabled }
                           isRequired={ isRequired }
      />
    case consts.FIELD_BASE_TYPE_CALCULATION:
      return <CalculationField field={ field }
                           isLoading={ isLoading }
                           dispatch={ dispatch }
                           isDisable={ isDisabled }
                           isRequired={ isRequired }
      />
    case consts.FIELD_BASE_TYPE_HTML_CONTENT:
      return <HtmlContentField id={ field.id }
                               field={ field }
                               value={ field.value }
      />
    case consts.FIELD_BASE_TYPE_CONSENT:
      return <ConsentField
                field={ field }
                id={ field.id }
                value={ field.value }
                disabled={ isDisabled }
                isRequired={ field.options?.necessary }
            />
    default:
      return <div>{ trans('unknownField') }</div>
  }
}

export default FieldComponentResolver
