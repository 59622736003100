export enum FormElementType {
  FIELD = 'FIELD',
  ROW = 'ROW',
  SECTION = 'SECTION',
}

export interface FormElementStatusInfo {
  variant: string
  value: any
}

export interface FormElementStatus {
  isDisable?: boolean
  isHidden?: boolean
  isLoading?: boolean
  value?: any
  info?: FormElementStatusInfo | null
}

export default interface ScriptFormCustomizationOrder {
  elementId: string | number
  elementType: FormElementType
  elementStatus: FormElementStatus
}
