import React, { useState } from 'react'
import { setPage, setQuery, setLimit, fetchItems } from '../state/actions'
import { translate } from '../../../Services/translation'
import translation from '../translations'
import NavTable from '../../../Components/NavTable'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ButtonGroup } from 'react-bootstrap'

const Table = ({ onDeleteClick, onUpdateClick }) => {

  const dispatch = useDispatch()
  const { items, pagination, isLoading } = useSelector(state => state.NavigationItems)
  const { language } = useSelector(state => state.Root.user)
  const trans = translate(translation)(language)
  const [ fetchListRequest, setFetchListRequest ] = useState(null)

  const columnHeaders = [ trans('label'),  trans('systemName'), trans('isActive'), trans('actions') ]
  const rows = getRows(items, trans, onDeleteClick, onUpdateClick)

  const applyNewFilters = () => {
    clearTimeout(fetchListRequest)
    setFetchListRequest(setTimeout(() => dispatch(fetchItems()), 1000))
  }

  const onSearchInputChange = e => {
    e.preventDefault()
    dispatch(setQuery(e.target.value))
    applyNewFilters()
  }

  const onPageClick = (currentPage, page) => {
    if (currentPage === page)
      return

    dispatch(setPage(page))
    applyNewFilters()
  }

  const onLimitChange = limit => {
    dispatch(setLimit(limit))
    applyNewFilters()
  }

  return <NavTable pagination={ pagination }
                   rows={ rows }
                   columnHeaders={ columnHeaders }
                   onSearchInputChange={ onSearchInputChange }
                   onLimitChange={ onLimitChange }
                   onPageClick={ onPageClick }
                   isisLoading={ isLoading }
  />
}

export const ActionCell = ({ onDeleteClick, onUpdateClick, trans }) =>
  <ButtonGroup>
    <Button variant={ 'outline-info' } size={ 'sm' } onClick={ onDeleteClick }>
      <i className="fas fa-trash"/> { trans('delete') }
    </Button>
    <Button variant={ 'info' } size={ 'sm' } onClick={ onUpdateClick }>
      <i className="fas fa-edit"/> { trans('edit') }
    </Button>
  </ButtonGroup>

const getRows = (
  items,
  trans,
  onDeleteClick,
  onUpdateClick
) =>
  items.map(item => [
    <>{ item.label }</>,
    <>{ item.systemName }</>,
    <>{ trans(item.isActive ? trans('yes') : trans('no')) }</>,
    <ActionCell onDeleteClick={ () => onDeleteClick(item) }
                onUpdateClick={ () => onUpdateClick(item) }
                trans={ trans }
    />
  ])

export default Table
