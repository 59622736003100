export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR'
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR'
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR'

export const VARIANT_INFO = 'info'
export const VARIANT_SUCCESS = 'success'
export const VARIANT_ERROR = 'error'
export const VARIANT_WARNING = 'warning'

export const enqueueSnackbar = (notification) => {
  const key = notification.options?.key

  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random()
    }
  }
}

export const showError = (translationKey, key = null) => pushNotification(VARIANT_ERROR, translationKey, key)
export const showSuccess = (translationKey, key = null) => pushNotification(VARIANT_SUCCESS, translationKey, key)
export const showWarning = (translationKey, key = null) => pushNotification(VARIANT_WARNING, translationKey, key)
export const showInfo = (translationKey, key = null) => pushNotification(VARIANT_INFO, translationKey, key)

export const pushNotification = (variant, translationKey, key = null, position = null, autoHideDuration = 3000) => {

  const options = {
    variant,
    style: { whiteSpace: 'pre-line' },
    anchorOrigin: position || {
      vertical: 'top',
      horizontal: 'right'
    },
  }

  if (autoHideDuration === 0)
    options.persist = true
  else
    options.autoHideDuration = autoHideDuration

  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      translationKey, options,
      key: key || new Date().getTime() + Math.random()
    }
  }
}

export const closeSnackbar = (key) => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key
})

export const removeSnackbar = (key) => ({
  type: REMOVE_SNACKBAR,
  key
})
