import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Button } from 'react-bootstrap'
import { translate } from 'src/Services/translation'
import translation from 'src/Views/FormEditor/translations'
import Modal from 'src/Components/Modal'
import {
    getSystemFieldReferenceStartingBySystemName,
    getSystemFieldsStartingByName
} from 'src/Services/Selectors'
import { StoreState } from 'src/Services/Store/reducers'
import {
    MS_SANTE_NEEDED_SYSTEM_FIELDS
} from './Constants'
import { SystemFieldCondition } from './Types'
import SystemField from '../../../../Types/SystemField'
import { toggleMsSanteConfigModal, updateDetails } from '../../state/actions'

const MsSanteConfigModal = () => {
    const dispatch = useDispatch()

    const { language } = useSelector((state: StoreState) => state.Root.user)
    const { systemFields, form, isMsSanteConfigModalOpen }
        = useSelector((state: StoreState) => state.FormEditor.formReducer)

    const trans = translate(translation)(language)

    const getNeededReferenceSystemFields = () => {
        return MS_SANTE_NEEDED_SYSTEM_FIELDS
            .filter((field: SystemFieldCondition) => field.isReference)
            .map(field => ({
                    ...field,
                    systemFields: getSystemFieldReferenceStartingBySystemName(systemFields, field.prefix).map(systemField => ({
                        ...systemField,
                        // TODO : fix this as it may be dangerous
                        isComplete: !isReferenceSystemFieldChildMissing((systemField as unknown as SystemFieldCondition), field.childs)
                    }))
                })
            )
    }

    const isReferenceSystemFieldChildMissing = (
        parentSystemField: SystemFieldCondition,
        requiredChilds: SystemFieldCondition[]
    ) => {
        return requiredChilds.some((requiredField: SystemFieldCondition) =>
            !getSystemFieldsStartingByName(systemFields, requiredField.prefix, parentSystemField.name).length)
    }


    const getNeededSystemFields = () => {
        return MS_SANTE_NEEDED_SYSTEM_FIELDS
            .filter((field: SystemFieldCondition) => !field.isReference)
            .map((systemField, index) => ({
                ...systemField,
                matchingFields: getSystemFieldsStartingByName(systemFields, systemField.prefix)
            }))
    }

    const isPrimaryButtonDisabled = () => {
        return getNeededReferenceSystemFields().some(referenceSystemField => !referenceSystemField.systemFields.length) ||
            getNeededReferenceSystemFields().some(
                referenceSystemField =>
                    referenceSystemField.systemFields.filter(systemField => !systemField.isComplete).length > 0
            ) ||
            getNeededSystemFields().some(systemField => !systemField.matchingFields.length)
    }

    const onPrimaryButtonClick = () => {
        dispatch(updateDetails({ isMsSanteEnabled: !form.isMsSanteEnabled }))
        dispatch(toggleMsSanteConfigModal())
    }

    const DetectedFields = (referenceField:SystemFieldCondition, systemField: SystemField, parentKey: string) => <div>
        {
            referenceField.childs.map((child: SystemFieldCondition, index: number) =>
                getSystemFieldsStartingByName(systemFields, child.prefix, systemField.name).length
                    ? <div className={ 'text-success' } key={ `${ parentKey }-${ index }` }>
                        { trans('msSanteConfig.fieldDetected') } :
                        <span>{ getSystemFieldsStartingByName(systemFields, child.prefix, systemField.name)[0].name }<br/></span>
                    </div>
                    : <div className={ 'text-danger' } key={ `${ parentKey }-${ index }` }>
                        <div>{ trans('msSanteConfig.mustContainAReferenceChildSystemField') }</div>
                        { child.prefix.split('#')[0] + '#' + systemField.name }
                    </div>
            )

        }
    </div>

    const UnDetectedFields = (referenceField: SystemFieldCondition, parentKey:string) => <div>
        {
            referenceField.childs.map((child: any, index: number) =>
                <div className={ 'text-danger' } key={ `${ parentKey }-${ child.prefix }` }>
                    { child.prefix.split('#')[0] + '#' + referenceField.prefix }
                </div>
            )

        }
    </div>


    return (
        <Modal title={ trans('msSanteConfig.title') }
               onClose={ () => dispatch(toggleMsSanteConfigModal()) }
               isOpen={ isMsSanteConfigModalOpen }
               size={ 'xl' }
        >
            <>
                {
                    getNeededReferenceSystemFields()
                        .map((referenceSystemField, index) => <Row className={ 'mb-2' } key={ referenceSystemField.name }>
                                <Col sm={ 12 }>
                                    <strong>{ trans('msSanteConfig.field.' + referenceSystemField.name) }&nbsp; *
                                    </strong>
                                </Col>
                                {
                                    !referenceSystemField.systemFields.length && <Col sm={ 6 }>
                                        <div className={ 'text-danger' }>
                                            { trans('msSanteConfig.mustContainAReferenceFields') } : { referenceSystemField.prefix }
                                        </div>
                                        <div className={ 'text-danger' }>
                                            { trans('msSanteConfig.mustContainReferenceFieldChildsFields') } :
                                            { UnDetectedFields(referenceSystemField, referenceSystemField.name) }
                                        </div>
                                    </Col>
                                }
                                {
                                    referenceSystemField.systemFields.map((systemField, id) => <Col
                                            key={ `${ systemField.name }` } sm={ 6 }>
                                            <div className={ 'text-success' }>
                                                { trans('msSanteConfig.fieldDetected') } :<br/>
                                                <span>{ systemField.name }<br/></span>
                                                { DetectedFields(referenceSystemField, systemField, systemField.name) }
                                            </div>
                                        </Col>
                                    )
                                }
                            </Row>
                        )
                }
                <Row className={ 'mb-2' }>
                    {
                        getNeededSystemFields()
                            .map(systemField => <Col key={ systemField.name } sm={ 6 } className={ 'mb-2' }>
                                <strong>{ trans('msSanteConfig.field.' + systemField.name) }&nbsp; *</strong>
                                { systemField.matchingFields.length > 0
                                    ? <div className={ 'text-success' }>
                                        { trans('msSanteConfig.fieldDetected') } :<br/>
                                        { systemField.matchingFields.map((matchingField: SystemField, i: number) =>
                                            <span key={ matchingField.name }>{ matchingField.name }<br/></span>) }
                                    </div>
                                    : <div className={ systemField.isRequired ? 'text-danger' : 'text-warning' }>
                                        { trans('msSanteConfig.mustContainASystemField') } : { systemField.prefix }
                                    </div> }
                            </Col>)
                    }
                </Row>
            </>
            <Button variant={ form.isMsSanteEnabled ? 'danger' : 'primary' }
                    className={ 'd-block ms-auto' }
                    disabled={ isPrimaryButtonDisabled() }
                    onClick={ onPrimaryButtonClick }
            >
                { trans(form.isMsSanteEnabled ? 'deactivate' : 'activate') }
            </Button>
        </Modal>
    )
}

export default MsSanteConfigModal
