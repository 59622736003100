
export default {
  english: {
    title: 'Patient creator',
    gender: 'Gender',
    gender_male: 'Male',
    gender_female: 'Female',
    firstName: 'First name',
    lastName: 'Last name',
    dateOfBirth: 'Date of birth',
    formIncomplete: 'Please fill out all fields',
    similarPatientFoundLabel: 'Similar patient(s) found',
    mobilePhone: 'Mobile phone',
    homePhone: 'Home phone',
    externalId: 'External ID',
    createForSure: 'It\'s not one of those, create a new patient',
  },
  french: {
    title: 'Créateur de patient',
    gender: 'Civilité',
    gender_male: 'Homme',
    gender_female: 'Femme',
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    dateOfBirth: 'Date de naissance',
    formIncomplete: 'Veuillez remplir tous les champs',
    similarPatientFoundLabel: 'Patient(s) similaire(s) trouvé(s)',
    mobilePhone: 'Mobile',
    homePhone: 'Fixe',
    externalId: 'ID externe',
    createForSure: 'Ce n\'est pas l\'un d\'entre eux, créer un nouveau patient',
  }
}
