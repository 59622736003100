import { call, put, select, takeLatest } from 'redux-saga/effects'
import { FETCH_SLOT_TYPES, CREATE_SLOT_TYPE, DELETE_SLOT_TYPE, UPDATE_SLOT_TYPE } from './slotTypes'
import {
  toggleUpdateSlotType,
  slotTypeUpdated,
  toggleDeleteSlotType,
  slotTypeDeleted,
  toggleCreateSlotType,
  slotTypeCreated,
  receiveSlotTypes
} from './actions'
import apiMethods from '../../../Services/api/apiMethods'
import { contentRangeToPagination } from '../../../Utils/EffectsUtil.ts'

function* updateSlotType(props, { payload }) {
  try {
    const state = yield select()
    const { id } = state.SlotTypes.slotTypeOnUpdate

    const { data } = yield call(apiMethods.update, `/slot_types/${id}`, { data: payload })
    yield put(toggleUpdateSlotType(null))
    yield put(slotTypeUpdated(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'updateSlotTypeFailed'))
  }
}

function* deleteSlotType(props, { payload }) {
  try {
    const state = yield select()
    const { id } = state.SlotTypes.slotTypeOnDelete

    yield call(apiMethods.delete, `/slot_types/${id}`)
    yield put(toggleDeleteSlotType(null))
    yield put(slotTypeDeleted(id))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'deleteSlotTypeFailed'))
  }
}

function* createSlotType(props, { payload }) {
  try {
    const { data } = yield call(apiMethods.create, '/slot_types', { data: payload })
    yield put(toggleCreateSlotType())
    yield put(slotTypeCreated(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'createSlotTypeFailed'))
  }
}

function* fetchSlotTypes(props) {
  try {
    const state = yield select()
    const { pagination, searchQuery } = state.SlotTypes

    const { data, headers } = yield call(apiMethods.get, '/slot_types', {
      query: searchQuery, ...pagination
    })
    yield put(receiveSlotTypes({
      slotTypes: data,
      pagination: contentRangeToPagination(headers['content-range'], pagination)
    }))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'loadSlotTypeListFailed'))
  }
}

export default function* slotTypesSagaWatcher(props) {
  yield takeLatest(FETCH_SLOT_TYPES, fetchSlotTypes, props)
  yield takeLatest(CREATE_SLOT_TYPE, createSlotType, props)
  yield takeLatest(DELETE_SLOT_TYPE, deleteSlotType, props)
  yield takeLatest(UPDATE_SLOT_TYPE, updateSlotType, props)
}
