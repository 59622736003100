import * as types from './actionTypes'
import * as actions from 'src/Views/ListRun/state/actionTypes'

export const initializeTable = (tableId, listId) => ({
  type: types.INITIALIZE_TABLE,
  payload: { tableId, listId }
})

export const fetchData = tableId => ({
  type: types.FETCH_DATA,
  payload: { tableId }
})
export const setData = (tableId, rows, pagination) => ({
  type: types.SET_DATA,
  payload: { tableId, rows, pagination }
})

export const setDetails = (tableId, list, columns) => ({
  type: types.SET_DETAILS,
  payload: { tableId, list, columns }
})

export const changePagination = (tableId, offset, limit) => ({
  type: types.CHANGE_PAGINATION,
  payload: { tableId, offset, limit }
})

export const filter = (tableId, filters) => ({
  type: types.FILTER,
  payload: { tableId, filters }
})
export const sort = (tableId, sortItems) => ({
  type: types.SORT,
  payload: { tableId, sortItems }
})

export const requestExport = tableId => ({
  type: types.REQUEST_EXPORT,
  payload: { tableId }
})
export const exportDone = tableId => ({
  type: types.EXPORT_DONE,
  payload: { tableId }
})

export const setIsTableLoading = (tableId, isLoading) => ({
  type: types.SET_IS_TABLE_LOADING,
  payload: { tableId, isLoading }
})
