import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import * as Sentry from '@sentry/react'
import { setIsLoading } from 'src/Layouts/View/state/actions'
import { requestLogout } from 'src/Views/Auth/state/actions'
import { getFullApiBaseUrl } from 'src/Services/Firewall/utils'
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { StoreState } from 'src/Services/Store/reducers'
import { API_ERROR_IGNORED_URLS } from 'src/Services/HttpLibraryProvider/Constants'
import { showError, showWarning } from '../notifier/actions/index'
import { setConfigTranslations, setIsApiReady } from '../Store/Root/actions'
import { setBaseUrl, setupInterceptors } from '../api/httpClient'
import { useSelector } from '../Store'

const HttpLibraryProvider = () => {
  const dispatch = useDispatch()

  const { isAuthenticated, isApiReady, config, configTranslations } = useSelector((state: StoreState) => state.Root)
  const { isLoading } = useSelector((state: StoreState) => state.View)

  const [ requestsPendingCount, setRequestsPendingCount ] = React.useState(0)

  /** API Axios setup */
  const onApiRequest = (req: AxiosRequestConfig) => {
    setRequestsPendingCount(count => count + 1)
    return req
  }

  const onApiRequestFailure = (err: AxiosError) => {
    setRequestsPendingCount(count => count - 1)
    return Promise.reject(err)
  }

  const onApiSuccess = (res: AxiosResponse) => {
    setRequestsPendingCount(count => count - 1)
    return res
  }

  const onApiError = (err: AxiosError) => {

    const { response: res } = err

    // Unlike the axios documentation says, res.config may be undefined (In case the browser block the request because of CORS or so)
    if (res.config && API_ERROR_IGNORED_URLS.some(url => res.config.url.includes(url)))
      return Promise.reject(err)

    const isApiRequest =
      res.config
        ? res.config.url.startsWith('/') && res.config.baseURL === getFullApiBaseUrl(config) || res.config.url.startsWith(getFullApiBaseUrl(config))
        : true

    if (res?.status === 401 && isApiRequest) {
      if (isAuthenticated !== null) {
        dispatch(showWarning('notAuthenticated', 'notAuthenticated'))
        localStorage.removeItem('impersonatedUser')
        Sentry.setUser(null)
        dispatch(requestLogout())
      }
      // @ts-ignore
      err.isMoreInformationNeeded = false
    } else if (res?.status === 403 && res?.data?.error?.code === 'userPinIncorrect') {
      dispatch(showWarning('userPinIncorrectError', 'userPinIncorrectError'))
      // @ts-ignore
      err.isMoreInformationNeeded = false
    } else if (res?.status === 403) {
      dispatch(showError('noPermission', 'noPermission'))
      // @ts-ignore
      err.isMoreInformationNeeded = false
    } else if (res?.status === 404) {
      dispatch(showWarning('notFound', 'notFound'))
      // @ts-ignore
      err.isMoreInformationNeeded = false
    }

    setRequestsPendingCount(count => count - 1)

    return Promise.reject(err)
  }

  useEffect(() => {
    if (requestsPendingCount === 0 && isLoading)
      dispatch(setIsLoading(false))
    else if (requestsPendingCount > 0 && !isLoading)
      dispatch(setIsLoading(true))
  }, [ requestsPendingCount, isLoading ])

  useEffect(() => {
    if (!configTranslations) {
      const storedConfigTranslations = localStorage.getItem('configTranslations')
      if (storedConfigTranslations)
        dispatch(setConfigTranslations(JSON.parse(storedConfigTranslations)))
    }
  }, [])

  useEffect(() => {
    if (config) {
      setBaseUrl(getFullApiBaseUrl(config))
      setupInterceptors(onApiRequest, onApiRequestFailure, onApiSuccess, onApiError)
      if (!isApiReady)
        dispatch(setIsApiReady(true))
    }
  }, [ config, isApiReady, isAuthenticated ])
}

export default HttpLibraryProvider
