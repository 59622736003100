export const FETCH_RECENT_LISTS = 'FETCH_RECENT_LISTS'
export const RECENT_LISTS_FETCHED = 'RECENT_LISTS_FETCHED'
export const RECENT_LISTS_FETCH_ERROR = 'RECENT_LISTS_FETCH_ERROR'
export const CLEAR_RECENT_LISTS = 'CLEAR_RECENT_LISTS'

export const FETCH_RECENT_VIEWED = 'FETCH_RECENT_VIEWED'
export const RECENT_VIEWED_FETCHED = 'RECENT_VIEWED_FETCHED'
export const RECENT_VIEWED_FETCH_ERROR = 'RECENT_VIEWED_FETCH_ERROR'
export const CLEAR_RECENT_VIEWED = 'CLEAR_RECENT_VIEWED'

export const FETCH_LISTS = 'FETCH_LISTS'
export const LISTS_FETCHED = 'LISTS_FETCHED'
export const CHANGE_LISTS_CURRENT_PAGE = 'CHANGE_LISTS_CURRENT_PAGE'
export const CHANGE_LISTS_PAGE_SIZE = 'CHANGE_LISTS_PAGE_SIZE'
export const CHANGE_LISTS_SEARCH = 'CHANGE_LISTS_SEARCH'
export const LISTS_FETCH_ERROR = 'LISTS_FETCH_ERROR'
export const CLEAR_LISTS = 'CLEAR_LISTS'
