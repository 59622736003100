import React, { useState, useEffect } from 'react'
import { translate } from 'src/Services/translation'
import { Button, Form } from 'react-bootstrap'
import { showWarning } from 'src/Services/notifier/actions'
import { useDispatch, useSelector } from 'react-redux'
import ReferenceFieldSelect from 'src/Components/ReferenceFieldSelect/index.tsx'
import Modal from '../../../Components/Modal'
import { fetchAddSystemField, toggleFormModal } from '../state/actions'
import translation from '../translations'

const FormModal = () => {
  const dispatch = useDispatch()

  const { isFormModalOpen: isOpen, formId } = useSelector(state => state.SystemFields)
  const { language } = useSelector(state => state.Root.user)

  const trans = translate(translation)(language)

  const [ selectedField, setSelectedField ] = useState(null)
  const [ name, setName ] = useState(null)

  const reset = () => {
    setSelectedField(null)
    setName(null)
  }

  useEffect(() => {
    reset()
  }, [ dispatch, isOpen ])

  const onClose = () => dispatch(toggleFormModal())

  const onFieldSelectChange = value => {
    setSelectedField(value)
    setName(value?.value || null)
  }

  const onNameChange = e => setName(e.target.value)

  const submitForm = e => {
    e.preventDefault()

    if (!selectedField || !name)
      return dispatch(showWarning('formIncomplete'))

    dispatch(fetchAddSystemField({
      formId,
      fieldBody: {
        field: selectedField.id,
        name
      }
    }))
  }

  return <Modal title={ trans('modal.createTitle') }
                isOpen={ isOpen }
                onClose={ onClose }
  >
    <Form onSubmit={ submitForm }>
      <Form.Group className={ 'mb-2' }>
        <Form.Label>{ trans('modal.searchField') }</Form.Label>
        <ReferenceFieldSelect
          form={ { id: formId } }
          value={ selectedField }
          onChange={ onFieldSelectChange }
          required
          name={ 'fieldSelect' }
          elementId={ 'fieldSelect' }
        />

      </Form.Group>
      <Form.Group className={ 'mb-2' }>
        <Form.Label>{ trans('modal.name') }</Form.Label>
        <Form.Control value={ name || '' } name={ 'name' } required onChange={ onNameChange }/>
      </Form.Group>
      <Button type={ 'submit' }>{ trans('save') }</Button>
    </Form>
  </Modal>
}

export default FormModal
