
export default {
  english: {
    searchList: 'Search',
    addNewList: 'Add new list',
    entries: 'of {total} entries',
    exportFields: 'Configure Export Fields',
    systemFields: 'Manage System Fields',
    baseForm: 'Base form',
    modal: {
      newList: {
        title: 'Create a new list',
        systemName: 'System name',
        listType: 'List type',
        formMissing: 'Form missing',
        typeMissing: 'Type missing',
      }
    },
    notFound: 'The list you added was not found and might have been deleted',
  },
  french: {
    searchList: 'Chercher une liste',
    addNewList: 'Créer une liste',
    entries: 'sur {total} entrées',
    exportFields: 'Configure Export Fields',
    systemFields: 'Manage System Fields',
    baseForm: 'Formulaire de base',
    modal: {
      newList: {
        title: 'Créer une nouvelle liste',
        systemName: 'Nom système',
        listType: 'Type de liste',
        formMissing: 'Formulaire manquant',
        typeMissing: 'Type manquant',
      }
    },
    notFound: 'La liste que vous avez ajouté n\'a pas été trouvé et a peut être été supprimée',
  }
}
