import React, { useState, useEffect } from 'react'
import translation from 'src/Views/FormFiller/translations.ts'
import { translate } from 'src/Services/translation'
import { NAME as PATIENT_REFERENCE } from 'src/Services/Constants/Config/Patient'
import { useDispatch, useSelector } from 'react-redux'
import { FetchStatus } from 'src/Types/FetchStatus'
import ListCell from 'src/Components/ListCell'
import { Button } from 'react-bootstrap'
import { EXTERNAL_PROVIDER_MS_SANTE } from 'src/Services/Constants/MsSante'
import { FieldOption } from 'src/Types/FieldOption'
import Modal from '../../../Components/Modal'
import {
    hideReferenceSearchModal,
    changeReference,
    fillPatientToInstance, searchReference
} from '../state/actions'
import {
    getIsReferenceSearchModalOpen,
    getReferenceSearchQuery
} from '../selectors'
import Table from '../../../Components/NavTable/Table'

const ReferenceResultsModal = () => {
    const dispatch = useDispatch()

    const { user } = useSelector(state => state.Root)
    const isOpen = useSelector(state => getIsReferenceSearchModalOpen(state.FormFiller))
    const getSearchQuery = useSelector(state => getReferenceSearchQuery(state.FormFiller))
    const {
        searchReferenceStatus,
        searchForRefField,
        referenceSearchResults,
        openedInstance,
        openedInstanceForm,
        referenceSearchFilters,
        openedInstanceSystemFields,
    } = useSelector(state => state.FormFiller)

    const [ patientRefFields, setPatientRefFields ] = useState([])
    const [ referenceField, setReferenceField ] = useState({})

    const trans = translate(translation)(user.language)

    useEffect(() => {
        setPatientRefFields(getPatientRefFieldsIds())
    }, [ searchForRefField ])

    const getColumnHeaders = () => {
        if (!openedInstance.fields)
            return []

        return Object.values(openedInstance.fields)
            .filter(field => field?.referenceFieldId === searchForRefField)
            .map(field => ({
                label: field.listColumn.label,
                id: field.id,
                isSortable: true,
                field
            }))
    }

    const getRows = () => referenceSearchResults.map(row => getColumnHeaders().map(h => {
        return <div key={ `${ row.id }${ h.field.listColumn.systemName }` }
                    onClick={ () => handleChangeReference(row.id, row.values) }
                    className={ 'cursor-pointer' }
        >
            <ListCell value={ row.values[h.field.listColumn.systemName] } field={ h.field }/>
        </div>
    }))

    const handleChangeReference = (rowId, restRow) => {
        if (patientRefFields?.length > 0) {
            dispatch(fillPatientToInstance(searchForRefField, rowId, restRow, patientRefFields))

        } else {
          console.log(setReferenceField, rowId, restRow)
            const row = referenceSearchResults.find(result => result.id === rowId)
            dispatch(changeReference(searchForRefField, rowId, restRow, row?.isFromExternalSource))
        }
    }
    const searchQuery = getSearchQuery(searchForRefField)

    useEffect(() => {
        searchForRefField &&
        setReferenceField(Object.values(openedInstance.fields).find(field => field.id === searchForRefField))
    }, [ searchForRefField ])


    const getPatientRefFieldsIds = () => {
        if (!openedInstance.fields)
            return []

        if (
            !searchForRefField ||
            openedInstance.fields[searchForRefField].list?.refForm?.systemName !== PATIENT_REFERENCE
        )
            return []

        const patientRefFieldsIds = Object.values(openedInstance.fields)
            .filter(field => field?.list?.refForm?.systemName === PATIENT_REFERENCE && field.id !== searchForRefField)
            .map(field => {
                return { id: field.id, listId: field.list?.id }
            })

        return patientRefFieldsIds?.length > 0 ? patientRefFieldsIds : []
    }

    const isLifenAssociate = () =>
        referenceField.id && openedInstanceSystemFields.some(systemField => systemField.field.id === referenceField.id)

    const getEmptyBodyComponent = () => {
        if (openedInstanceForm?.isMsSanteEnabled && isLifenAssociate()) {
            return (
                <Button
                    onClick={
                        () => dispatch(
                            searchReference(
                                referenceField?.list?.id,
                                referenceField.id,
                                referenceSearchFilters,
                                referenceField?.options[FieldOption.SHOW_ADD_BUTTON],
                                EXTERNAL_PROVIDER_MS_SANTE
                            )
                        )
                    }
                    className={ 'mt-2' }
                >
                    { trans('msSanteSearchButton') }
                </Button>
            )
        }
        return null
    }

    return <Modal title={ trans('selectAReference') }
                  size={ 'xl' }
                  onClose={ () => dispatch(hideReferenceSearchModal()) }
                  isOpen={ isOpen }
    >
        <Table columnHeaders={ getColumnHeaders() }
               rows={ getRows() }
               isLoading={ searchReferenceStatus === FetchStatus.LOADING }
               onEmptyBodyComponent={ getEmptyBodyComponent() }
        />
    </Modal>
}

export default ReferenceResultsModal
