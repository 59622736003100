import * as Sentry from '@sentry/react'
import { BrowserOptions } from '@sentry/browser'

export enum Levels {
  LEVEL_DEBUG = 'debug',
  LEVEL_INFO = 'info',
  LEVEL_WARN = 'warn',
  LEVEL_ERROR = 'error',
}

export const LEVELS = [
  Levels.LEVEL_DEBUG,
  Levels.LEVEL_INFO,
  Levels.LEVEL_WARN,
  Levels.LEVEL_ERROR,
]

export const DEBUG_ONLY_LEVELS = [
  Levels.LEVEL_DEBUG,
  Levels.LEVEL_WARN,
]

/**
 *
 * @param message
 * @param level info | warning | error | log
 * @param data
 */
const localLog = (message: string, level: Levels = Levels.LEVEL_ERROR, data: any = null) => {
  const args = data ? [ message, data ] : [ message ]

  if (LEVELS.includes(level))
    console[level](...args)
  else
    console.log(...args)
}

const init = (
    dsn: string,
    release: string,
    environment: string,
    debugState = 0,
    withPerformanceMonitoring = false
) => {

  const options: BrowserOptions = {
    debug: debugState > 0,
    integrations: withPerformanceMonitoring ? [
      new Sentry.BrowserProfilingIntegration(),
      new Sentry.BrowserTracing(),
    ] : [],
    /**
     * This means that every action performed in the browser will be sent as a transaction to Sentry.
     *
     * In production, this value should be lowered to collect a uniform sample data size without reaching
     * Sentry’s transaction quota. Alternatively, to collect sample data dynamically,
     * tracesSampler can be used to filter these transactions.
     *
     * @see https://docs.sentry.io/platforms/javascript/guides/react/configuration/sampling/#sampling-error-events
     */
    tracesSampleRate: debugState > 0 ? 1.0 : 0.1,
    tracePropagationTargets: [ window.location.origin ],
    enableTracing: withPerformanceMonitoring,
  }

  if (environment)
    options.environment = environment

  /**
   * @see https://docs.sentry.io/product/releases/
   */
  if (release)
    options.release = release

  if (dsn) {
    options.dsn = dsn
    Sentry.init(options)
  }
}

const captureError = (error: any, data: any = null, debugState = 0) => {
  try {
    const client = Sentry.getCurrentHub().getClient()

    /** @ts-ignore it does exist */
    if (client?._integrationsInitialized) {
      const context = data ? { extra: data } : {}
      Sentry.captureException(error, context)
    }

    if (debugState === 1)
      localLog(error, Levels.LEVEL_ERROR, data)
  } catch (e) {
    localLog('captureError', Levels.LEVEL_ERROR, e)
  }
}

/**
 *
 * @param message
 * @param level info | warning | error | log
 * @param data
 * @param debugState 0 | 1
 */
const captureMessage = (message: string, level: Levels = Levels.LEVEL_INFO, data: any = null, debugState = 0) => {
  try {
    if (debugState === 0 && DEBUG_ONLY_LEVELS.includes(level))
      return

    const client = Sentry.getCurrentHub().getClient()
    /** @ts-ignore it does exist */
    if (client?._integrationsInitialized) {
      const context = data ? { extra: data } : {}
      Sentry.captureMessage(message, context)
    }

    if (debugState === 1)
      localLog(message, level, data)
  } catch (e) {
    localLog('captureMessage', Levels.LEVEL_ERROR, e)
  }
}

export default { init, captureError, captureMessage }
