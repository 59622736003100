import { call, put, select, takeLatest } from 'redux-saga/effects'
import { FETCH_DELETED_FORMS, FETCH_RESTORE_FORM } from './actionTypes'
import {
  receiveDeletedForms
} from './actions'
import apiMethods from '../../../../../Services/api/apiMethods'
import { contentRangeToPagination } from '../../../../../Utils/EffectsUtil.ts'

function* fetchDeletedForm(props) {
  try {
    const state = yield select()
    const { pagination, searchQuery } = state.RestoreDeletedForms

    const { data, headers } = yield call(apiMethods.get, '/forms', {
      query: searchQuery, onlyDeleted: true, ...pagination
    })
    yield put(receiveDeletedForms({
      slotTypes: data,
      pagination: contentRangeToPagination(headers['content-range'], pagination)
    }))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'fetchFailed'))
  }
}

function* fetchRestoreForm(props, { payload }) {
  try {
    const data = yield call(apiMethods.update, `/forms/${payload.id}/restore`, {})
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'fetchFailed'))
  }
}


export default function* deletedFormsSagaWatcher(props) {
  yield takeLatest(FETCH_DELETED_FORMS, fetchDeletedForm, props)
  yield takeLatest(FETCH_RESTORE_FORM, fetchRestoreForm, props)
}
