import * as types from './actionTypes'
import { SortItemDirection } from 'src/Types/Pagination'

const initialState = {
  // id: mixed | listId: int | list: {} | columns: [] | pagination: {} | paginationDetails: {} | rows: [] |
  // isLoading: bool | sortItems: [] | filters: []
  tables: []
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.INITIALIZE_TABLE:

      const tableFound = state.tables.some(t => t.id === payload.tableId)
      const newTable = {
        id: payload.tableId,
        listId: payload.listId,
        list: null,
        columns: [],
        rows: [],
        isLoading: false,
        pagination: {
          offset: 0,
          limit: 10,
        },
        paginationDetails: {
          totalItemsCount: 0,
          itemsCount: 0,
        },
        sortItems: [
          { column: 'createdAt', direction: SortItemDirection.DESC }
        ],
        filters: [],
        isExportInProgress: false
      }

      return {
        ...state,
        tables: tableFound ? state.tables : [ ...state.tables, newTable ]
      }
    case types.SET_DATA:

      const tableData = {
        rows: payload.rows,
        // temporarily disabled to prevent bug on search reset
        // pagination: {
        //   offset: payload.pagination.offset,
        //   limit: payload.pagination.limit,
        // },
        paginationDetails: {
          itemsCount: payload.pagination.itemsCount,
          totalItemsCount: payload.pagination.totalItemsCount,
        },
      }

      return {
        ...state,
        tables: state.tables.map(t => t.id === payload.tableId ? ({ ...t, ...tableData }) : t)
      }
    case types.SET_DETAILS:

      const tableDetails = { list: payload.list, columns: payload.columns }

      return {
        ...state,
        tables: state.tables.map(t => t.id === payload.tableId ? ({ ...t, ...tableDetails }) : t)
      }
    case types.SET_IS_TABLE_LOADING:
      return {
        ...state,
        tables: state.tables.map(t => t.id === payload.tableId
          ? ({ ...t, isLoading: payload.isLoading }) : t)
      }
    case types.CHANGE_PAGINATION:
      return {
        ...state,
        tables: state.tables.map(t => t.id === payload.tableId
          ? ({ ...t,
            pagination: {
              offset: payload.offset,
              limit: payload.limit,
            }
          }) : t)
      }
    case types.FILTER:
      return {
        ...state,
        tables: state.tables.map(t => t.id === payload.tableId
          ? {
            ...t,
            filters: payload.filters,
          }
          : t)
      }
    case types.SORT:
      return {
        ...state,
        tables: state.tables.map(t => t.id === payload.tableId
          ? ({ ...t, sortItems: payload.sortItems }) : t)
      }
    case types.REQUEST_EXPORT:
      return {
        ...state,
        tables: state.tables.map(t => t.id === payload.tableId
          ? ({ ...t, isExportInProgress: true }) : t)
      }
    case types.EXPORT_DONE:
      return {
        ...state,
        tables: state.tables.map(t => t.id === payload.tableId
          ? ({ ...t, isExportInProgress: false }) : t)
      }
    default:
      return state
  }
}
