import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, Form } from 'react-bootstrap'
import { updateSection } from '../../state/actions'

const SectionForm = ({
  sectionId,
  section,
  trans,
  onSubmit
}) => {
  const dispatch = useDispatch()
  const { name } = section

  const handleSectionEditSubmit = e => {
    e.preventDefault()
    dispatch(updateSection(sectionId, { name: e.target.name.value }))
    onSubmit()
  }

  return <Form name={ `editSection${ sectionId }` } onSubmit={ handleSectionEditSubmit }>
    <Form.Group className={ 'mb-2' }>
      <Form.Label>{ trans('name') }</Form.Label>
      <Form.Control type={ 'text' } defaultValue={ name } name={ 'name' }/>
    </Form.Group>
    <Button variant="primary" type={ 'submit' } className={ 'float-end' }>{ trans('submit') }</Button>
  </Form>
}

export default SectionForm
