import React, { Suspense } from 'react'
import Dictionary from '../Dictionary'
import TopNavbar from 'src/Layouts/Header'
import { useSelector } from '../Store'
import RoutesMapper from 'src/Services/Router/Routes'
import { AppLoader } from 'src/Components/AppLoader'

const AppRouter = () => {
  const { isApiReady } = useSelector(state => state.Root)

  if (!isApiReady)
    return <AppLoader/>

  return <Dictionary>
    <TopNavbar/>
    <Suspense fallback={ <AppLoader/> }>
      <RoutesMapper/>
    </Suspense>
  </Dictionary>
}

export default AppRouter
