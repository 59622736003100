export default {
  english: {
    noFilters: 'No filters',
    idColumnHeader: 'Id',
    createdAtColumnHeader: 'Created at',
    columnFilterValuePlaceholder: 'Value',
  },
  french: {
    noFilters: 'Pas de filtres',
    idColumnHeader: 'Id',
    createdAtColumnHeader: 'Créé le',
    columnFilterValuePlaceholder: 'Valeur',
  }
}
