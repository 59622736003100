import React from 'react'
import { Pagination } from 'react-bootstrap'
import { range, map, addIndex } from 'ramda'

// getPagesToDisplay :: (Number, Number) -> Array
export const getPagesToDisplay = (pageCount, currentPage) => {
  if (pageCount < 11) {
    return range(1, pageCount + 1)
  }

  const aroundCurrentPage = [
    currentPage - 2,
    currentPage - 1,
    currentPage,
    currentPage + 1,
    currentPage + 2
  ]

  const withoutOutOfRange = aroundCurrentPage.filter(page => page > 0 && page <= pageCount)

  let result = [ ...withoutOutOfRange ]

  if (withoutOutOfRange[0] > 1) {
    result = [ 1, '...', ...result ]
  }

  if (withoutOutOfRange[withoutOutOfRange.length - 1] < pageCount) {
    result = [ ...result, '...', pageCount ]
  }

  return result
}

const TablePagination = ({
  currentPage = 1,
  pageCount = 1,
  onPageClick
}) => {
  const nextPage = currentPage + 1 <= pageCount ? currentPage + 1 : null
  const previousPage = currentPage - 1 > 0 ? currentPage - 1 : null
  const pages = getPagesToDisplay(pageCount, currentPage)

  return <Pagination className={ 'mt-auto' }>
    <Pagination.First disabled={ !previousPage } onClick={ () => onPageClick(currentPage, 1) }/>
    <Pagination.Prev disabled={ !previousPage } onClick={ () => onPageClick(currentPage, previousPage) }/>

    { addIndex(map)((page, index) => {
      if (page === '...')
        return <Pagination.Ellipsis key={ index }>...</Pagination.Ellipsis>

      return <Pagination.Item className={ `${ currentPage === page ? 'active' : '' }` }
                              key={ index }
                              onClick={ () => onPageClick(currentPage, page) }
      >{ page }</Pagination.Item>
    })(pages) }

    <Pagination.Next disabled={ !nextPage } onClick={ () => onPageClick(currentPage, nextPage) }/>
    <Pagination.Last disabled={ !nextPage } onClick={ () => onPageClick(currentPage, pageCount) }/>
  </Pagination>
}

export default TablePagination
