import React, { FormEvent } from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { AdminTool } from 'src/Types/AdminTool'

interface AdminToolsFormProps {
  trans: (data: string) => string
  onSubmit: (data: AdminTool) => void
  adminToolUpdate?: AdminTool | null
}

interface AdminToolFormElements extends HTMLFormControlsCollection {
  name: HTMLInputElement
  internalLink: HTMLInputElement
  externalLink: HTMLInputElement
}

interface AdminToolForm extends HTMLFormElement {
  readonly elements: AdminToolFormElements
}

const AdminToolsForm = ({
                          trans,
                          onSubmit = (item: AdminTool) => null,
                          adminToolUpdate = null,
                        }: AdminToolsFormProps) => {
  const onFormSubmit = (e: FormEvent<AdminToolForm>) => {
    e.preventDefault()

    onSubmit({
               name: e.currentTarget.elements.name.value,
               internalLink: e.currentTarget.elements.internalLink.value,
               externalLink: e.currentTarget.elements.externalLink.value,
             })
  }

  return (
      <Form
          name={'field-type'}
          onSubmit={onFormSubmit}
      >
        <Row className={'mb-2'}>
          <Col>
            <Form.Group>
              <Form.Label>{trans('form.name')}</Form.Label>
              <Form.Control
                  defaultValue={adminToolUpdate?.name}
                  name={'name'}
                  required
                  type="text"
                  placeholder={trans('form.name')}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className={'mb-2'}>
          <Col>
            <Form.Group>
              <Form.Label>
                {trans('form.internalLink')}
              </Form.Label>
              <Form.Control
                  defaultValue={adminToolUpdate?.internalLink}
                  name={'internalLink'}
                  type="text"
                  placeholder={trans('form.internalLink')}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className={'mb-2'}>
          <Col>
            <Form.Group>
              <Form.Label>
                {trans('form.externalLink')}
              </Form.Label>
              <Form.Control
                  defaultValue={adminToolUpdate?.externalLink}
                  name={'externalLink'}
                  type="text"
                  placeholder={trans('form.externalLink')}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Button
                variant={'success'}
                style={{ float: 'right' }}
                type={'submit'}
            >
              {trans('submit')}
            </Button>
          </Col>
        </Row>
      </Form>
  )
}

export default AdminToolsForm
