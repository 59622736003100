import React, { useEffect } from 'react'
import { translate } from 'src/Services/translation'
import translation from './translations'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchFormSystemFields, setFormId, toggleFormModal } from './state/actions'
import SystemFieldsTable from './Components/SystemFieldsTable'
import FormModal from './Components/FormModal'
import { setOptions } from 'src/Layouts/View/state/actions'
import { Container } from 'react-bootstrap'

const ManageSystemFields = () => {
  const params = useParams()
  const dispatch = useDispatch()

  const { language } = useSelector(state => state.Root.user)
  const { pagination } = useSelector(state => state.SystemFields)

  const trans = translate(translation)(language)

  React.useEffect(() => {
    if (params?.id)
      dispatch(setFormId(params.id))
  }, [ params ])

  React.useEffect(() => {
    dispatch(setOptions({
      title: trans('title'),
      hasPrimaryButton: true,
      onPrimaryButtonClick: e => dispatch(toggleFormModal())
    }))
  }, [ dispatch ])

  useEffect(() => {
    dispatch(fetchFormSystemFields(params.id))
  }, [ dispatch, params.id, pagination.pageLimit, pagination.currentPage ])

  return <Container>
    <SystemFieldsTable/>
    <FormModal/>
  </Container>
}

export default ManageSystemFields
