import * as types from './actionTypes'
import {
  IS_GENERATE_TEMPLATE_LOADING, SET_CALCULATION_FIELDS, SET_MS_SANTE_CONTENTS_TO_SEND, SET_MS_SANTE_RECIPIENTS, SET_MS_SANTE_TRACKING_DATA, TOGGLE_MS_SANTE_CONTENTS_FETCHING,
  TOGGLE_MS_SANTE_MODAL_LOADING, TOGGLE_MS_SANTE_RECIPIENTS_FETCHING, TOGGLE_SAVE_LOADING,
} from './actionTypes'
import { SaveStatus } from 'src/Components/SaveButton'
import { FIELD_BASE_TYPE_REFERENCE, ID_SEPARATOR } from 'src/Services/Constants'
import { FetchStatus } from 'src/Types/FetchStatus'
import OpenedInstance from 'src/Views/FormFiller/Types/OpenedInstance'
import { Form } from 'src/Types/Form'
import { Instance } from 'src/Types/Instance'
import File from 'src/Types/File'
import ExtensibleLoading from 'src/Views/FormFiller/Types/ExtensibleLoading'
import SavingFiles from 'src/Views/FormFiller/Types/SavingFiles'
import FormCustomizationCondition from 'src/Types/FormCustomizationCondition'
import ScriptFormCustomizationOrder from 'src/Views/FormFiller/Types/ScriptFormCustomizationOrder'
import { Action, ActionOptions } from 'src/Views/FormFiller/Types/Field'
import { Action as ActionState } from 'src/Services/Store/reducers'
import { Field } from 'src/Types/Field'
import { MsSante } from '../Types/MsSante'
import SystemField from 'src/Types/SystemField'
import { NO_SAVE_FIELD_TYPES } from 'src/Views/FormFiller/Constants'
import { parseInstance } from 'src/Views/FormFiller/utils'
import { SwpFeatureStatus } from '../Types/Swp'

export interface FormFillerState {
  openedInstance: OpenedInstance | null,
  openedInstanceForm: Form | null,
  openedInstanceSystemFields: SystemField[],
  fieldsToSave: string[],
  editedFields: string[],
  saveInstanceStatus: SaveStatus,
  fetchInstanceStatus: FetchStatus,
  referenceSearchResults: Instance[],
  referenceSearchFilters: [],
  searchReferenceStatus: FetchStatus,
  // Field id
  searchForRefField: string | null,
  showAddButton: boolean,
  isOpenedInstanceReady: boolean,
  swpFeatureStatus: SwpFeatureStatus,
  isAddButtonDisabled: boolean,
  extensiblesLoading: ExtensibleLoading[],
  isCreatingInstanceForReference: boolean,
  referenceFieldOnEdit: Field | null,
  savingFiles: SavingFiles,
  // No usage found
  // showFileSizeError: {},
  // Default form customizations conditions triggered
  formCustomizationConditionsTriggered: FormCustomizationCondition[],
  // Customization orders emitted from custom scripts
  scriptCustomizationOrders: ScriptFormCustomizationOrder[],
  isValidateUserIdentityModalOpen: boolean,
  msSante: MsSante,
  userCode: string | null,
  isGenerateTemplateLoading: boolean,
  documentData: any,
  calculationFields: any,
  saveLoading: boolean
  pendingProtectedAction: null | {
    type: string,
    payload?: object
  }
}

export const initialState: FormFillerState = {
  openedInstance: { id: null, displayId: null, fields: {}, sections: [], identityString: null },
  openedInstanceForm: null,
  openedInstanceSystemFields: [],
  isOpenedInstanceReady: false,
  swpFeatureStatus: SwpFeatureStatus.LOADING,
  fieldsToSave: [],
  editedFields: [],
  saveInstanceStatus: SaveStatus.VALID,
  fetchInstanceStatus: FetchStatus.IDLE,
  referenceSearchResults: [],
  referenceSearchFilters: [],
  searchReferenceStatus: FetchStatus.IDLE,
  searchForRefField: null,
  showAddButton: false,
  isAddButtonDisabled: true,
  extensiblesLoading: [],
  isCreatingInstanceForReference: false,
  referenceFieldOnEdit: null,
  savingFiles: {},
  // showFileSizeError: {},
  // Default form customizations conditions triggered
  formCustomizationConditionsTriggered: [],
  // Customization orders emitted from custom scripts
  scriptCustomizationOrders: [],
  isValidateUserIdentityModalOpen: false,
  msSante: {
    isMsSanteModalOpen: false,
    isMsSanteTrackingModalOpen: false,
    msSanteModalLoading: false,
    isMsSanteModalContentsFetching: false,
    isMsSanteModalRecipientsFetching: false,
    recipients: [],
    contentsToSend: [],
    tracking: null,
  },
  userCode: null,
  isGenerateTemplateLoading: false,
  documentData: null,
  saveLoading: false,
  calculationFields: null,
  pendingProtectedAction: null,
}

export default (state = initialState, { type, payload }: ActionState) => {
  switch (type) {
  case types.SET_DOCUMENT_DATA:
    return {
      ...state,
      documentData: payload.data,
    }
  case types.SET_INSTANCE_FIELD:
    return {
      ...state,
      openedInstance: {
        ...state.openedInstance,
        fields: {
          ...state.openedInstance.fields,
          [payload.fieldId]: {
            ...state.openedInstance.fields[payload.fieldId],
            value: payload.value,
          },
        },
      },
    }
  case types.SET_INSTANCE:
    return {
      ...state,
      openedInstance: parseInstance(payload.instance, payload.form),
      openedInstanceForm: payload.form,
      openedInstanceSystemFields: initialState.openedInstanceSystemFields,
      isOpenedInstanceReady: false,
      swpFeatureStatus: initialState.swpFeatureStatus,
      fetchInstanceStatus: FetchStatus.SUCCESS,
      saveInstanceStatus: SaveStatus.VALID,
      fieldsToSave: initialState.fieldsToSave,
      editedFields: initialState.editedFields,
      formCustomizationConditionsTriggered: initialState.formCustomizationConditionsTriggered,
      scriptCustomizationOrders: initialState.scriptCustomizationOrders,
      savingFiles: initialState.savingFiles,
      documentData: initialState.documentData,
    }
  case types.SET_INSTANCE_SYSTEM_FIELDS:
    return {
      ...state,
      openedInstanceSystemFields: payload.systemFields,
    }
  case types.FETCH_INSTANCE:
    return {
      ...state,
      openedInstance: {},
      fetchInstanceStatus: FetchStatus.LOADING,
      saveInstanceStatus: SaveStatus.DISABLED,
    }
  case types.FETCH_INSTANCE_FAILED:
    return {
      ...state,
      fetchInstanceStatus: payload?.httpCode == 404
        ? FetchStatus.NOT_FOUND :
        payload?.httpCode == 403 ? FetchStatus.FORBIDDEN : FetchStatus.FAIL,
    }

  case types.CHANGE_FIELD_VALUE:
    const { field, value } = payload

    /** Record field value modification */
    const isChild = Boolean(field?.referenceFieldId) || Boolean(field?.extensibleFieldId)

    const editedFields = [ ...state.editedFields, field.id ]
    let fieldsToSave = state.fieldsToSave

    if (!NO_SAVE_FIELD_TYPES.includes(field.type.baseFieldType))
      fieldsToSave = state.fieldsToSave.includes(field.id) || isChild
        ? state.fieldsToSave
        : [ ...state.fieldsToSave, field.id ]
    /** */

    /** Update field value */
    const openedInstance = {
      ...state.openedInstance,
      fields: {
        ...state.openedInstance.fields,
        [field.id]: {
          ...state.openedInstance.fields[field.id],
          value,
        },
      },
    }
    /** */

    return {
      ...state,
      fieldsToSave,
      editedFields,
      openedInstance,
      saveInstanceStatus: SaveStatus.VALID,
    }
  case types.TOGGLE_VALIDATE_USER_AUTHENTICITY_MODAL:
    return {
      ...state,
      isValidateUserIdentityModalOpen: payload.isOpen !== null
        ? payload.isOpen : !state.isValidateUserIdentityModalOpen,
    }
  case types.TOGGLE_MS_SANTE_MODAL:
    return {
      ...state,
      msSante: {
        ...state.msSante,
        isMsSanteModalOpen: payload,
      },
    }
  case types.TOGGLE_MS_SANTE_TRACKING_MODAL:
    return {
      ...state,
      msSante: {
        ...state.msSante,
        isMsSanteTrackingModalOpen: payload,
      },
    }
  case TOGGLE_MS_SANTE_MODAL_LOADING:
    return {
      ...state,
      msSante: {
        ...state.msSante,
        msSanteModalLoading: payload.isLoading,
      },
    }
  case TOGGLE_MS_SANTE_RECIPIENTS_FETCHING:
    return {
      ...state,
      msSante: {
        ...state.msSante,
        isMsSanteModalRecipientsFetching: payload,
      },
    }
  case TOGGLE_MS_SANTE_CONTENTS_FETCHING:
    return {
      ...state,
      msSante: {
        ...state.msSante,
        isMsSanteModalContentsFetching: payload,
      },
    }
  case SET_MS_SANTE_TRACKING_DATA:
    return {
      ...state,
      msSante: {
        ...state.msSante,
        tracking: payload.tracking,
      },
    }
  case SET_MS_SANTE_RECIPIENTS:
    return {
      ...state,
      msSante: {
        ...state.msSante,
        recipients: payload.recipients,
      },
    }
  case SET_MS_SANTE_CONTENTS_TO_SEND:
    return {
      ...state,
      msSante: {
        ...state.msSante,
        contentsToSend: payload.contentsToSend,
      },
    }
  case types.SET_USER_PIN:
    return {
      ...state,
      userCode: payload.userCode,
    }
  case types.SAVE_INSTANCE:
    return {
      ...state,
      saveInstanceStatus: SaveStatus.LOADING,
    }
  case types.SAVE_INSTANCE_SUCCEEDED:
    return {
      ...state,
      saveInstanceStatus: SaveStatus.SUCCESS,
      fieldsToSave: initialState.fieldsToSave,
    }
  case types.SAVE_INSTANCE_FAILED:
    return {
      ...state,
      saveInstanceStatus: SaveStatus.FAIL,
    }
  case types.SET_SAVE_INSTANCE_STATUS:
    return {
      ...state,
      saveInstanceStatus: payload.status,
    }
  case types.IS_EDITING_REFERENCE: {

    let referenceFieldOnEdit = state.referenceFieldOnEdit

    if (referenceFieldOnEdit?.id === payload.referenceField.id && !payload.isEditing)
      referenceFieldOnEdit = null
    else if (referenceFieldOnEdit?.id !== payload.referenceField.id && payload.isEditing)
      referenceFieldOnEdit = payload.referenceField

    return { ...state, referenceFieldOnEdit }
  }
  case types.CHANGE_REFERENCE: {
    const { fields } = state.openedInstance
    const field = fields[payload.refId]

    if (!field || field.type?.baseFieldType !== FIELD_BASE_TYPE_REFERENCE)
      return state

    // Get all reference field children and set them a value
    const referencedFields = Object
      .keys(fields)
      .filter(id => fields[id]?.referenceFieldId === payload.refId)
      .reduce((acc, id) => ({
        ...acc,
        [id]: {
          ...fields[id],
          value: payload.values[fields[id].listColumn.systemName],
        },
      }), {})

    const openedInstance = {
      ...state.openedInstance,
      fields: { ...state.openedInstance.fields, ...referencedFields },
    }

    return {
      ...state,
      openedInstance,
      saveInstanceStatus: SaveStatus.VALID,
      searchForRefField: null,
    }
  }
  case types.CLEAR_REFERENCE:
    const fields = state.openedInstance.fields

    // Clear all reference field children values
    const clearedFields = Object.keys(fields)
      .reduce((res, id) => {
        const field = fields[id]
        const isReferenced = field?.referenceFieldId == payload.field.id

        if (isReferenced)
          field.value = null

        return { ...res, [id]: field }
      }, {})

    return {
      ...state,
      saveInstanceStatus: SaveStatus.VALID,
      openedInstance: {
        ...state.openedInstance,
        fields: clearedFields,
      },
    }
  case types.SEARCH_REFERENCE: {
    return {
      ...state,
      searchReferenceStatus: FetchStatus.LOADING,
      searchForRefField: payload.fieldId,
      showAddButton: payload.showAddButton,
    }
  }
  case types.SET_REFERENCE_SEARCH_FILTERS:
    return {
      ...state,
      referenceSearchFilters: payload.filters,
    }
  case types.REFERENCE_SEARCH_SUCCEEDED: {
    return {
      ...state,
      referenceSearchResults: payload.results,
      searchReferenceStatus: FetchStatus.IDLE,
    }
  }
  case types.REFERENCE_SEARCH_FAILED: {
    return {
      ...state,
      searchReferenceStatus: FetchStatus.FAIL,
    }
  }
  case types.HIDE_REFERENCE_SEARCH_MODAL: {
    return {
      ...state,
      searchForRefField: null,
    }
  }
  case types.CREATE_INSTANCE_FOR_REFERENCE: {
    return {
      ...state,
      isCreatingInstanceForReference: true,
    }
  }
  case types.REFERENCE_INSTANCE_CREATED: {
    return {
      ...state,
      isCreatingInstanceForReference: false,
    }
  }
  case types.REFERENCE_INSTANCE_CREATION_FAILED: {
    return {
      ...state,
      isCreatingInstanceForReference: false,
    }
  }
  case types.SET_PENDING_PROTECTED_ACTION: {
    return {
      ...state,
      pendingProtectedAction: payload.action,
    }
  }
  case types.ADD_EXTENSIBLE_ROW_LOADING: {
    return {
      ...state,
      extensiblesLoading: [
        ...state.extensiblesLoading,
        { extensibleId: payload.extensibleFieldId, rowId: payload.rowId },
      ],
    }
  }
  case types.REMOVE_EXTENSIBLE_ROW_LOADING: {
    return {
      ...state,
      extensiblesLoading:
        state.extensiblesLoading
          .filter(_ => _.extensibleId !== payload.extensibleFieldId && _.rowId !== payload.rowId),
    }
  }
  case types.ADD_EXTENSIBLE_ROW: {
    const { extensibleId, rowId, data } = payload

    const allFields = state.openedInstance.fields
    const extensible = allFields[extensibleId]

    // Add new row by adding new fields
    for (let fieldId of (extensible.fields as string[])) {

      const id = fieldId + ID_SEPARATOR + rowId
      const field = allFields[fieldId]

      allFields[id] = {
        ...field,
        id,
        value: data[field.systemName] || null,
      }
    }

    // Empty creation row
    for (let fieldId of (extensible.fields as string[]))
      allFields[fieldId].value = null

    return {
      ...state,
      openedInstance: {
        ...state.openedInstance,
        fields: allFields,
      },
    }
  }
  case types.REMOVE_EXTENSIBLE_ROW: {

    const { extensibleId, rowId } = payload

    const allFields = state.openedInstance.fields
    const extensible = allFields[extensibleId]

    for (const fieldId of extensible.fields) {
      const id = fieldId + ID_SEPARATOR + rowId
      delete allFields[id]
    }

    return {
      ...state,
      openedInstance: {
        ...state.openedInstance,
        fields: allFields,
      },
    }
  }
  case types.DELETE_FILE_SUCCEED: {
    let files = state.openedInstance.fields?.[payload.fieldId]?.value || []
    files = (files as File[])

    if (!files.length)
      return state

    return {
      ...state,
      openedInstance: {
        ...state.openedInstance,
        fields: {
          ...state.openedInstance.fields,
          [payload.fieldId]: {
            ...state.openedInstance.fields[payload.fieldId],
            value: files.filter((f: File) => f.id !== payload.fileId),
          },
        },
      },
    }
  }
  case types.LOCK_FILE_SUCCEED: {
    let files = state.openedInstance.fields?.[payload.fieldId]?.value || []
    files = (files as File[])

    if (!files.length)
      return state

    return {
      ...state,
      openedInstance: {
        ...state.openedInstance,
        fields: {
          ...state.openedInstance.fields,
          [payload.fieldId]: {
            ...state.openedInstance.fields[payload.fieldId],
            value: files.map((f: File) => f.id === payload.fileId ? { ...f, isLocked: true } : f),
          },
        },
      },
    }
  }
  case types.UNLOCK_FILE_SUCCEED: {
    let files = state.openedInstance.fields?.[payload.fieldId]?.value || []
    files = (files as File[])

    if (!files.length)
      return state

    return {
      ...state,
      openedInstance: {
        ...state.openedInstance,
        fields: {
          ...state.openedInstance.fields,
          [payload.fieldId]: {
            ...state.openedInstance.fields[payload.fieldId],
            value: files.map((f: File) => f.id === payload.fileId ? { ...f, isLocked: false } : f),
          },
        },
      },
    }
  }
  case types.EDIT_FILE: {
    return {
      ...state,
      savingFiles: {
        ...state.savingFiles,
        [payload.fieldId]: true,
      },
    }
  }
  case types.EDIT_FILE_SUCCEED: {
    const { [payload.fieldId]: omit, ...savingFiles } = state.savingFiles

    let files = state.openedInstance.fields?.[payload.fieldId]?.value || []
    files = (files as File[])

    if (!files.length)
      return state

    return {
      ...state,
      savingFiles,
      openedInstance: {
        ...state.openedInstance,
        fields: {
          ...state.openedInstance.fields,
          [payload.fieldId]: {
            ...state.openedInstance.fields[payload.fieldId],
            value: files.map((f: File) => f.id === payload.fileId
              ? { ...f, name: payload.name, description: payload.description } : f),
          },
        },
      },
    }
  }
  case types.PUSH_CUSTOMIZATION_ORDER: {

    const scriptCustomizationOrders = state.scriptCustomizationOrders
    const targetIndex = scriptCustomizationOrders
      .findIndex(_ => _.elementType === payload.elementType && _.elementId === payload.elementId)

    const elementTargetAlreadyCustomized = targetIndex !== -1

    if (elementTargetAlreadyCustomized) {
      const existingCustomization = scriptCustomizationOrders[targetIndex]

      scriptCustomizationOrders[targetIndex] = {
        ...existingCustomization,
        elementStatus: {
          ...existingCustomization.elementStatus,
          ...getCustomizationElementStatus(payload.action, payload.options),
        },
      }
    } else {
      scriptCustomizationOrders.push({
        elementType: payload.elementType,
        elementId: payload.elementId,
        elementStatus: getCustomizationElementStatus(payload.action, payload.options),
      })
    }

    return { ...state, scriptCustomizationOrders }
  }
  case types.RESET_CUSTOMIZATIONS: {
    return {
      ...state,
      scriptCustomizationOrders: [],
      formCustomizationConditionsTriggered: [],
    }
  }
  case types.SET_FORM_CUSTOMIZATIONS_CONDITIONS_TRIGGERED: {
    return { ...state, formCustomizationConditionsTriggered: payload.conditions || [] }
  }
  case IS_GENERATE_TEMPLATE_LOADING:
    return {
      ...state,
      isGenerateTemplateLoading: payload.isGenerateTemplateLoading,
    }
  case TOGGLE_SAVE_LOADING:
    return {
      ...state,
      saveLoading: payload.isLoading,
    }
  case SET_CALCULATION_FIELDS:
    return {
      ...state,
      calculationFields: payload,
    }
  case types.SET_IS_OPENED_INSTANCE_READY:
    return {
      ...state,
      isOpenedInstanceReady: payload.isReady,
    }
  case types.SET_SWP_FEATURE_STATUS:
    return {
      ...state,
      swpFeatureStatus: payload.status,
    }
  default:
    return state
  }
}

export const getCustomizationElementStatus = (action: Action, options: ActionOptions) => {
  switch (action) {
  case Action.DISABLE:
    return { isDisable: true }
  case Action.ENABLE:
    return { isDisable: false }
  case Action.HIDE:
    return { isHidden: true }
  case Action.SHOW:
    return { isHidden: false }
  case Action.START_LOADING:
    return { isLoading: true }
  case Action.STOP_LOADING:
    return { isLoading: false }
  case Action.SHOW_INFO:
    return {
      info: {
        value: options.value,
        variant: options.variant,
      },
    }
  case Action.HIDE_INFO:
    return { info: null }
  case Action.CHANGE_VALUE:
    return { value: options.value }
  case Action.RESET_VALUE:
    return { value: null }
  default:
    return {}
  }
}
