import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { translate } from 'src/Services/translation'
import translation from './translations'
import React, { useEffect } from 'react'
import { setOptions } from 'src/Layouts/View/state/actions'
import PermissionsMenu from './Components/Menu'
import PermissionsFormContent from './Components/FormContent'
import { Button, Container } from 'react-bootstrap'
import { fetchUser, reset, saveNewSubjectData, setNewSubjectData, setRole } from './state/actions.ts'
import PermissionsEntityContent from './Components/EntityContent.tsx'
import PermissionsModuleContent from './Components/ModuleContent'
import PermissionsLandingPageContent from './Components/LandingPageContent'
import PermissionsNavigationItemContent from './Components/NavigationItemContent'
import { PermissionSubject } from 'src/Views/Permissions/Types/Subject'

const Permissions = () => {
  const { entity, id } = useParams()

  const dispatch = useDispatch()
  const { language } = useSelector(state => state.Root.user)
  const { roles } = useSelector(state => state.Dictionary)
  const { onPermissionsFormSubmit, permissionFormSubmitState } = useSelector(state => state.Permissions)
  const { role, user, hasDataChanged, subjectData, newSubjectData, subject }
    = useSelector(state => state.Permissions)

  const trans = translate(translation)(language)

  const onSaveClick = () => {
    if (subject === PermissionSubject.FORM && onPermissionsFormSubmit) {
      onPermissionsFormSubmit()
    } else {
      dispatch(saveNewSubjectData(newSubjectData))
    }
  }

  useEffect(() => {
    permissionFormSubmitState &&
    dispatch(saveNewSubjectData(newSubjectData))
  },[ permissionFormSubmitState ])
  const onResetClick = () => dispatch(setNewSubjectData(subjectData))

  useEffect(() => {
    if (!entity)
      dispatch(reset())
    else if (entity === 'role' && !role)
      dispatch(setRole(roles.find(_ => _.name == id)))
    else if (entity === 'user' && !user)
      dispatch(fetchUser(id))
  }, [ dispatch, entity, id, roles ])

  useEffect(() => {
    dispatch(setOptions({
      isHeaderSticky: true,
      hasPrimaryButton: true,
      primaryButtonType: 'SAVE',
      isPrimaryButtonDisable: !hasDataChanged,
      onPrimaryButtonClick: onSaveClick,
      rightHeaderTemplate: <Button size={'sm'} variant={'secondary'} disabled={!hasDataChanged} onClick={onResetClick}>
        <i className="me-1 fas fa-undo-alt"/>
        {trans('undo')}
      </Button>
    }))
  }, [ dispatch, subjectData, hasDataChanged ])

  return <Container>
    <PermissionsMenu />
    { subject === PermissionSubject.LANDING_PAGE && <PermissionsLandingPageContent /> }
    { subject === PermissionSubject.NAVIGATION_ITEM && <PermissionsNavigationItemContent /> }
    { subject === PermissionSubject.MODULE && <PermissionsModuleContent /> }
    { subject === PermissionSubject.FORM && <PermissionsFormContent /> }
    { subject === PermissionSubject.LIST && <PermissionsEntityContent /> }
    { subject === PermissionSubject.NUMERIC_REPORT && <PermissionsEntityContent /> }
    { subject === PermissionSubject.LOG_AS_ROLE && <PermissionsEntityContent /> }
  </Container>
}

export default Permissions
