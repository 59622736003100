import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { translate } from 'src/Services/translation'
import FieldValueSetter from 'src/Components/FieldValueSetter/index'
import ReferenceFieldSelect from 'src/Components/ReferenceFieldSelect/index.tsx'
import translation from '../translations'
import {
  addFilter, setSelectedReferenceField, setSelectedReferenceFieldDetails
} from '../state/actions'

const FilterForm = () => {
  const dispatch = useDispatch()

  const { language } = useSelector(state => state.Root.user)
  const {
    id: listId, form, refForm, type: listType, operators, addFilterStatus, selectedReferenceField,
    selectedReferenceFieldDetails
  } = useSelector(state => state.ListEdit)

  const trans = translate(translation)(language)

  const [ value, setValue ] = React.useState(null)

  const onAddFilterSubmit = e => {
    e.preventDefault()

    dispatch(addFilter({
      listId,
      fieldId: selectedReferenceField?.id,
      fieldDetails: selectedReferenceFieldDetails,
      operator: e.target.querySelector('select[name=operator]')
        ? e.target.querySelector('select[name=operator]').value
        : '',
      alias: e.target.querySelector('input[name=alias]')
        ? e.target.querySelector('input[name=alias]').value
        : '',
      value
    }))
  }

  const resetForm = () => {
    dispatch(setSelectedReferenceField(null))
    dispatch(setSelectedReferenceFieldDetails(null))
    setValue(null)
  }

  const onReferenceFieldSelect = field => dispatch(setSelectedReferenceField(field))

  return <Form onSubmit={ onAddFilterSubmit }>
    <Form.Group className={ 'mb-2' }>
      <Form.Label>{ trans('label') }</Form.Label>
      <Form.Control type={ 'text' } name={ 'alias' } maxLength={ '255' } required/>
    </Form.Group>
    <Form.Group className={ 'mb-2' }>
      <Form.Label>{ trans('formField') }</Form.Label>
      <ReferenceFieldSelect
        type={ listType }
        refForm={ refForm }
        form={ form }
        value={ selectedReferenceField }
        onChange={ onReferenceFieldSelect }
        elementId={ 'fixedFilterFieldSelector' }
      />
    </Form.Group>
    { selectedReferenceFieldDetails && <>
      <Form.Group className={ 'mb-2' }>
        <Form.Label>{ trans('operator') }</Form.Label>
        <Form.Select name={ 'operator' } required disabled={ operators.length < 1 }>
          { operators.length < 1 && <option value={ '' }>{ trans('operatorPlaceholder') }</option> }
          { operators.map(operator => <option key={ operator } value={ operator }>{ operator }</option>) }
        </Form.Select>
      </Form.Group>
      <Form.Group className={ 'mb-2' }>
        <Form.Label>
          { trans('value') }
          <OverlayTrigger
            key={ 'top' }
            placement={ 'top' }
            overlay={
              <Tooltip
                id={ 'setFilterValue' }
              >
                <p>{ trans('fieldTooltipTitle') }</p>
                <ul>
                  <li>{ trans('fieldTooltipItem1') }</li>
                </ul>
              </Tooltip>
            }
          >
            <i className={ 'fa-solid fa-circle-info ml-2' }></i>
          </OverlayTrigger>
        </Form.Label>
        <FieldValueSetter field={ selectedReferenceFieldDetails } value={ value } onChange={ setValue }/>
      </Form.Group>
    </> }
    <Button variant={ 'primary' } type={ 'submit' } disabled={ !selectedReferenceFieldDetails }
            className={ `${ addFilterStatus === 'pending' ? 'is-loading' : '' }` }>
      { trans('confirm') }
    </Button>
  </Form>
}

export default FilterForm
