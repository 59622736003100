import React, { useEffect, useState } from 'react'
import { Table, Row, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import {
  createColumnHelper, getCoreRowModel, getFilteredRowModel,
  getPaginationRowModel, getSortedRowModel, useReactTable
} from '@tanstack/react-table'
import { translate } from 'src/Services/translation'
import translation from './translations'
import ColumnHeader from './Components/ColumnHeader'
import AdvancedRow from './Components/AdvancedRow'
import AdvancedPagination from './Components/AdvancedPagination'
import PageSizeSelect from './Components/PageSizeSelect'
import { SortItemDirection } from 'src/Types/Pagination'
import './Style/index.scss'
import { ColumnValueType } from 'src/Types/RequestFilter'
import { formatDateTime } from 'src/Utils/Date'

const reactTableSortItemConvert =
  ({ id, desc }) => ({ column: id, direction: desc ? SortItemDirection.DESC : SortItemDirection.ASC })

const appTableSortItemConvert =
  ({ column, direction }) => ({ id: column, desc: direction === SortItemDirection.DESC })

const AdvancedTable = ({
  data = [],
  columns = [],
  filters = [],
  defaultSortItems = [],
  totalRowsCount = 0,
  defaultLimit = 10,
  isMultiSort = false,
  isFilterable = false,
  onSort = sortItems => {},
  onFilter = filters => {},
  onPaginationChange = (currentPage, pageSize, forceUpdate) => {},
  onRowClick = row => {},
  getColumn = column => {},
  showIdColumn = true,
  shwoCreatedAtColumn = true,
}) => {
  const { language, locale, portalTimezone } = useSelector(state => state.Root.user)

  const trans = translate(translation)(language)
  const formatLocaleDateTime = formatDateTime(locale, portalTimezone)

  const columnHelper = createColumnHelper()

  const [ sorting, setSorting ] = useState(defaultSortItems.map(appTableSortItemConvert))
  const [ { pageIndex, pageSize }, setPagination ] = useState({ pageIndex: 0, pageSize: defaultLimit })
  const pagination = React.useMemo(() => ({ pageIndex, pageSize }), [ pageIndex, pageSize ])

  useEffect(() => {
    onSort(sorting.map(reactTableSortItemConvert))
  }, [ sorting ])

  useEffect(() => {
    onPaginationChange(pagination.pageIndex + 1, pagination.pageSize)
  }, [ pagination ])

  const getColumns = () => {

    const results = []

    if (shwoCreatedAtColumn)
      results.push(columnHelper.accessor('createdAt', {
          id: 'createdAt',
          header: trans('createdAtColumnHeader'),
          enableSorting: true,
          enableColumnFilter: false,
          valueType: ColumnValueType.DATE,
          cell: ({ column, getValue }) => formatLocaleDateTime(getValue())
        }))

    if (showIdColumn)
      results.push(columnHelper.accessor('displayId', {
        id: 'id',
        header: trans('idColumnHeader'),
        enableSorting: false,
        enableColumnFilter: false,
        valueType: ColumnValueType.STRING,
      }))

    return [ ...results, ...columns.map(c => columnHelper.display(getColumn(c))) ]
  }
  const getPageCount = () => totalRowsCount === 0 ? 1 : Math.ceil(totalRowsCount / pageSize)

  const table = useReactTable({
    data,
    columns: getColumns(),
    manualPagination: true,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    pageCount: getPageCount(),
    state: {
      pagination,
      sorting
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    debugTable: true,
    autoResetPage: false,
    enableMultiSort: isMultiSort,
    enableFilters: isFilterable,
    isMultiSortEvent: () => true
  })

  const onColumnFiltersChange = column => columnFilters => {
    const otherFilters = filters.filter(f => f.column !== column)
    onFilter([ ...otherFilters, ...columnFilters ])

    if (pagination.pageIndex !== 0) {
      table.setPageIndex(0)
    }

    onPaginationChange(1, 10, true)
  }

  return <>
    <Row className="justify-content-end mb-3">
      <Col sm="auto">
        <AdvancedPagination table={ table }/>
      </Col>
    </Row>

    <Table striped borderless responsive>
      <thead>
        { table.getHeaderGroups().map(headerGroup => <tr key={ headerGroup.id }>
          { headerGroup.headers.map(header =>
            <ColumnHeader key={ header.id } header={ header } filters={ filters } trans={ trans }
                          onFiltersChange={ onColumnFiltersChange(header.column.columnDef.id) }
            />) }
        </tr>) }
      </thead>
      <tbody>
        { table.getRowModel().rows.map(row => <AdvancedRow key={ row.id } row={ row } onRowClick={ onRowClick }/>) }
      </tbody>
    </Table>

    <Row className="justify-content-between mt-2">
      <Col sm="auto">
        <PageSizeSelect
          table={table}
          trans={trans}
          totalRowsCount={totalRowsCount}
        />
      </Col>
      <Col sm="auto">
        <AdvancedPagination table={ table }/>
      </Col>
    </Row>
  </>
}

export default AdvancedTable
