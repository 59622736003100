import React, { useEffect } from 'react'
import {
  fetchSlotTypes,
  toggleCreateSlotType,
  createSlotType,
  toggleDeleteSlotType, updateSlotType, deleteSlotType, toggleUpdateSlotType, setFormErrors
} from './state/actions'
import { translate } from '../../Services/translation'
import translation from './translations'
import { useDispatch, useSelector } from 'react-redux'
import SlotTypesTable from './Components/SlotTypesTable'
import SlotTypeForm from './Components/SlotTypesForm'
import Modal from '../../Components/Modal'
import ConfirmModal from '../../Components/ConfirmModal'
import { setOptions } from '../../Layouts/View/state/actions'
import { Container } from 'react-bootstrap'

const SlotTypes = () => {
  const dispatch = useDispatch()
  const { language } = useSelector((state) => state.Root?.user)
  const {
    isCreatingSlotType,
    isDeletingSlotType,
    isUpdatingSlotType,
    slotTypeOnUpdate
  } = useSelector((state) => state.SlotTypes)

  const onCreateClick = () => dispatch(toggleCreateSlotType())

  const onModalCreateClose = () => {
    dispatch(setFormErrors({}))
    dispatch(toggleCreateSlotType())
  }
  const onModalCreateFormSubmit = data => dispatch(createSlotType(data))

  const onModalUpdateClose = () => {
    dispatch(setFormErrors({}))
    dispatch(toggleUpdateSlotType(null))
  }
  const onModalUpdateFormSubmit = data => dispatch(updateSlotType(data))

  const onConfirmDeleteModalClose = () => dispatch(toggleDeleteSlotType(null))
  const onConfirmDeleteModalConfirm = () => dispatch(deleteSlotType())

  useEffect(() => {
    dispatch(setOptions({
      hasPrimaryButton: true,
      onPrimaryButtonClick: onCreateClick
    }))
    dispatch(fetchSlotTypes())
  }, [ dispatch ])

  const trans = translate(translation)(language)

  return (
    <Container>
      <SlotTypesTable/>
      <Modal isOpen={isCreatingSlotType} title={trans('modal.create.title')} onClose={onModalCreateClose}>
        <SlotTypeForm trans={trans}
                      onSubmit={onModalCreateFormSubmit}
        />
      </Modal>
      <Modal isOpen={isUpdatingSlotType} title={trans('modal.update.title')} onClose={onModalUpdateClose}>
        <SlotTypeForm trans={trans}
                      onSubmit={onModalUpdateFormSubmit}
                      slotType={slotTypeOnUpdate}
        />
      </Modal>
      <ConfirmModal title={trans('modal.confirmDeleteTitle')}
                    isOpen={isDeletingSlotType}
                    onClose={onConfirmDeleteModalClose}
                    onConfirm={onConfirmDeleteModalConfirm}
      />
    </Container>
  )
}

export default SlotTypes
