import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Form } from 'react-bootstrap'
import styled from 'styled-components'
import Modal from 'src/Components/Modal'
import { translate } from 'src/Services/translation'
import { AppLoader } from 'src/Components/AppLoader'
import { FIELD_BASE_TYPE_REFERENCE } from 'src/Services/Constants'
import { getRowFieldByColumn } from 'src/Services/Selectors'
import ValueSettings from 'src/Views/FormEditor/Components/FieldSettings/Components/ValueSettings'
import FieldSetting from './Components/FieldSetting'
import translation from '../../translations'
import {
    updateField, deleteField,
    toggleFieldValuesModal
} from '../../state/actions'

const FieldSettings = () => {
    const dispatch = useDispatch()
    const formElement = useRef()

    const { language } = useSelector(state => state.Root.user)
    const state = useSelector(state => state.FormEditor)
    const { form, editedField, isFieldValuesModalOpen } = useSelector(state => state.FormEditor.formReducer)

    const [ localEditedField, setLocalEditedField ] = useState([])

    const trans = translate(translation)(language)
    const isReference = editedField && editedField.type?.baseFieldType === FIELD_BASE_TYPE_REFERENCE

    useEffect(() => {
        editedField &&
        setLocalEditedField(editedField)
    }, [ editedField ])

    const removeField = (dispatch, id) => e => {
        e.preventDefault()
        dispatch(deleteField(id))
    }

    const triggerFormSubmit = () => {
        formElement.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
    }

    const getMaxRowSize = () => {
        let maxRowSize = 1

        // Check next cols if there is empty space
        for (let col = editedField.rowColumn + 1; col < 4; col++) {
            const field = getRowFieldByColumn(state, editedField.row, col)

            if (field && field.id !== editedField.id)
                return maxRowSize
            else maxRowSize++
        }

        return maxRowSize
    }

    const editField = e => {

        e.preventDefault()


        dispatch(updateField(localEditedField))
    }

    const onEditedFieldChange = (fieldName, value, type = 'options') => {
        let newField = structuredClone(localEditedField)
        if (!newField.javaScriptCode) {
            newField.javaScriptCode = {}
        }

        switch (type) {
            case 'field' :
                newField[fieldName] = value
                break
            case 'options' :
                newField.options[fieldName] = value
                break
            case 'javascript' :
                newField.javaScriptCode[fieldName] = value
                break
        }

        setLocalEditedField(newField)
    }

    return <>
        <FieldEditForm onSubmit={ editField } ref={ formElement }>
            { editedField.loading ? <AppLoader/> :
                <React.Fragment>
                    <small className={ 'text-muted' }>
                        { trans('editField.fieldId') }: { editedField.id } |&nbsp;
                        { trans('editField.rowId') }: { editedField.row?.id }<br/>
                        { trans('editField.systemName') }: { editedField.systemName }
                    </small>

                    <hr className={ 'my-2' }/>

                    <Form.Group className={ 'mb-2' }>
                        <Form.Label className={ 'fw-bold' }>{ trans('editField.headingField') }</Form.Label>
                        <Form.Control
                            size={ 'sm' }
                            name={ 'label' }
                            defaultValue={ editedField.label }
                            onChange={ e => onEditedFieldChange('label', e.target.value, 'field') }
                        />
                    </Form.Group>
                    {
                        !editedField.extensibleFieldId &&
                        <Form.Group className={ 'mb-2' }>
                            <Form.Label className={ 'fw-bold' } htmlFor={ 'rowSize' }>{ trans('editField.rowSize') }</Form.Label>
                            <Form.Control type={ 'number' }
                                          size={ 'sm' }
                                          id={ 'rowSize' }
                                          name={ 'rowSize' }
                                          defaultValue={ editedField.rowSize }
                                          onChange={ e => onEditedFieldChange(
                                              'rowSize',
                                              parseInt(e.target.value, 10) > getMaxRowSize() ? getMaxRowSize() : e.target.value,
                                              'field'
                                          ) }
                                          min={ 1 }
                                          max={ getMaxRowSize() }
                                          step={ 1 }
                            />
                        </Form.Group>
                    }
                    <Form.Group className={ 'mb-2' }>
                        <Form.Label className={ 'fw-bold' }>Options :</Form.Label>
                        {
                            localEditedField.options && Object.keys(localEditedField.options).map((option, i) =>
                                <FieldSetting
                                    key={ i }
                                    field={ localEditedField }
                                    option={ option }
                                    trans={ trans }
                                    onChange={ onEditedFieldChange }
                                />)
                        }
                    </Form.Group>

                    { isReference &&
                        <Link to={ `/list/${ editedField.list.id }?fromForm=${ form.id }` } className={ 'button' }>
                            <i className={ 'me-1 fa fa-edit' }/>
                            <span>{ trans('editField.editList') }</span>
                        </Link>
                    }
                </React.Fragment>
            }

            <hr className={ 'my-2' }/>

            <div className={ '' }>
                <Button type={ 'submit' }>
                    <i className={ 'me-1 fas fa-save' }/>{ trans('save') }
                </Button>
                <Button variant={ 'danger' } size={ 'sm' }
                        onClick={ removeField(dispatch, editedField.id) }
                >
                    <i className={ 'me-1 fas fa-trash-alt' }/>{ trans('delete') }
                </Button>
            </div>
        </FieldEditForm>
        <Modal title={ trans('modal.fieldValues.title') }
               show={ isFieldValuesModalOpen }
               onClose={ () => dispatch(toggleFieldValuesModal()) }
               size={ 'xl' }
        >
            <ValueSettings field={ editedField } onChange={ triggerFormSubmit }/>
        </Modal>
    </>
}

export default FieldSettings

const FieldEditForm = styled.form.attrs({ className: 'card-content' })`
  label[data-is="checkbox"] {
    margin: 0.3em 0;
  }

  button {
    margin-bottom: 0.7em;
  }

  label[data-is="checkbox"] {
    display: block;
  }
`
