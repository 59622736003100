import React, { useState } from 'react'
import { Button, Card, Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { doImport } from '../state/actions'
import Papa from 'papaparse'
import { showError } from '../../../Services/notifier/actions/index'

const BulkImport = ({ trans }) => {
  const dispatch = useDispatch()

  const [ items, setItems ] = useState([])

  // Removes empty columns and rows
  const parseImportItems = items =>
    items.reduce((results, item) => {
      const keys = Object.keys(item).filter(k => k)

      if (!keys.length)
        return results

      const result = {}
      for (const key of keys)
        result[key] = item[key]

      return [ ...results, result ]
    }, [])

  const onFileInputChange = async e => {
    const file = e.target.files[0]
    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      complete: ({ data }) => {
        setItems(parseImportItems(data))
      },
      error: err => {
        //console.error(err)
        dispatch(showError(trans('bulkImport.parsingError')))
      }
    })
  }

  return <>
    <Card body className={ 'bg-info' }>
      <p className={ 'm-0' }>
        <strong>{ trans('bulkImport.instructionsPrefix') } :</strong>
        &nbsp;{ trans('bulkImport.instructions') }
      </p>
    </Card>
    <Form.Control type={ 'file' } className={ 'mt-2' } accept={ '.csv' } onChange={ onFileInputChange }/>
    <Button className={ 'float-end mt-2' }
            onClick={ e => dispatch(doImport(items)) }
            disabled={ !items.length }
    >
      { trans('submit') }
    </Button>
  </>
}

export default BulkImport
