import {
  REQUEST_IMPORT, CANCEL_IMPORT, DO_IMPORT, SET_LIST_SEARCHES
} from './actionTypes'

const initialState = {
  importRequested: null,
  searches: [],
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LIST_SEARCHES:
      return {
        ...state,
        searches: payload
      }
    case REQUEST_IMPORT:
      return {
        ...state,
        importRequested: state.id
      }
    case DO_IMPORT:
      return {
        ...state,
      }
    case CANCEL_IMPORT:
      return {
        ...state,
        importRequested: null
      }
    default:
      return state
  }
}
