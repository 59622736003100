export interface Session {
  sessionId: string
  canReadDataAsDeferred: boolean
  // https://bioserenity.atlassian.net/wiki/spaces/CLOUD/pages/1718157373/Integration+of+front+applications#Monitor
  canReadDataAsLive: false
  status: SessionStatus
  productContext: ProductContext
  accessToken?: string | null
}

export enum SessionStatus {
  INITIATED = 'INITIATED',
  PAIRED = 'PAIRED',
  RUNNING = 'RUNNING',
  ENDED = 'ENDED',
}

export enum SwpFeatureStatus {
  LOADING = 'LOADING',
  READY = 'READY',
  DOWN = 'DOWN',
  DISABLE = 'DISABLE',
}

export interface ProductContext {
  product: ProductContextProduct
  examType: ProductContextExamType
}

export enum ProductContextProduct {
  WEMU = 'WEMU',
  CCW = 'CCW',
  SOM = 'SOM',
  CPF = 'CPF',
}

export enum ProductContextExamType {
  ROUTINE_EEG = 'ROUTINE_EEG',
  REST_ECG = 'REST_ECG',
  HOLTER_ECG = 'HOLTER_ECG',
  POLYSOMNOGRAPHY = 'POLYSOMNOGRAPHY',
  HEART_FAILURE_TELEMONITORING = 'HEART_FAILURE_TELEMONITORING',
}
