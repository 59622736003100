import ASCIIFolder from '@pexxi/fold-to-ascii-ts'
import { Instance } from 'src/Types/Instance'

export const sortNumbersAscending = (a: number, b: number): number => a - b
export const sortStringsAscending = (a: string, b: string): number => a.localeCompare(b)

export const checkAndExtractDiffBtwTwoObj = (a: any, b: any): object => {
  let values = {}
  for (const key in b) {
      if (JSON.stringify(a[key]) !== JSON.stringify(b[key]))
        values = { ...values, [key]: b[key] }
  }

  return values
}

export const boldifySearchResults = (searchValue: string, item: string): string => {

  const searchTerms = searchValue
    // split search string by terms by taking comma or space
    .split(/[\s,]+/)
    // ignore in returned array all value that are not string
    .filter(String)
  const formattedResults: string[] = []

  searchTerms.forEach( searchTerm => {
    const asciiItem = ASCIIFolder.foldReplacing(item.toLowerCase())
    const asciiSearchValue = ASCIIFolder.foldReplacing(searchTerm.toLowerCase())
    const pattern = new RegExp(asciiSearchValue, 'i')
    if (asciiItem?.match(pattern)) {
      formattedResults.push(boldifySearchResult(searchTerm, item))
    }
  })

  return formattedResults.length
  ?  formattedResults.join(' ')
  : item
}

export const boldifySearchResult = (searchValue: string, item: string): string => {
    const nbChars = searchValue.length
    return '<strong>' + item.slice(0,nbChars) + '</strong>' + item.slice(nbChars)
}

export const getLocationNameById = (locationId: string, locationTypes: Instance[]): string => {
  const locationItem = locationTypes
    .find(location => location.id === locationId)

  return locationItem ? locationItem.identityString : ''
}
