import React from 'react'
import { useSelector } from 'react-redux'
import { FIELD_BASE_TYPE_DATE_TIME } from '../../../Services/Constants'
import { formatDateTime as formatDateTimeService } from '../../../Utils/Date.ts'

const CustomizationConditionDisplay = ({ trans, condition }) => {

  const { locale, portalTimezone } = useSelector((state) => state.Root.user)
  const { form } = useSelector(state => state.FormEditor.formReducer)

  const formatDateTime = formatDateTimeService(locale, portalTimezone)

  const getValue = () =>
    condition.field?.type?.baseFieldType === FIELD_BASE_TYPE_DATE_TIME
      ? formatDateTime(condition.value, condition.field.options?.showDate, condition.field.options?.showTime)
      : condition.value

  return <>
    <div>
      { trans('form.customizationOrder.condition.type') } :&nbsp;
      <b className={ 'text-primary' }>{ condition.type }</b>&nbsp;
      { !condition.operator &&
        <span>{ trans('form.customizationOrder.display.on') }&nbsp;
          <b className={ 'text-primary' }>{ condition.field.systemName }</b>
        </span>
      }
    </div>
    { condition.operator && <div>
      { trans('form.customizationOrder.display.if') }&nbsp;
      <b className={ 'text-primary' }>{ condition.field.systemName }</b>&nbsp;
      <b>{ condition.operator }</b> <span className={ 'text-primary' }> {`->`} </span> <b>{ getValue() }</b>
    </div> }
  </>
}

export default CustomizationConditionDisplay
