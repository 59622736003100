import {
  ITEMS_RECEIVED,
  ITEM_CREATED,
  ITEM_DELETED,
  FETCH_ITEMS,
  CREATE_ITEM,
  SET_QUERY,
  DELETE_ITEM,
  UPDATE_ITEM,
  ITEM_UPDATED,
  SET_LIMIT,
  SET_PAGE,
  SET_DELETING_ITEM,
  SET_UPDATING_ITEM,
  SET_CREATING_ITEM,
  ON_SUCCESS_RESPONSE,
  ON_FAILED_RESPONSE,
  FETCH_ITEMS_EVERYBODY_TASKS,
  FETCH_ITEMS_MY_TASKS, SORT_TASKS
} from './actionTypes'

const initialState = {
  isLoading: false,
  creatingItem: null,
  updatingItem: null,
  deletingItem: null,
  items: [],
  form: {
    isFormResponseOk: false,
    formResponseErrors: null,
  },
  query: null,
  pagination: {
    offset: 0,
    limit: 10,
    itemsCount: 10,
    totalItemsCount: 10,
    pageLimit: 10,
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPagesCount: 1,
  },
  sort: {
    column: 'createdAt',
    direction: 'desc'
  }
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ITEMS:
    case FETCH_ITEMS_EVERYBODY_TASKS:
    case FETCH_ITEMS_MY_TASKS:
      return {
        ...state,
        isLoading: true
      }
    case CREATE_ITEM:
      return {
        ...state,
        isLoading: true
      }
    case UPDATE_ITEM:
      return {
        ...state,
        isLoading: true
      }
    case DELETE_ITEM:
      return {
        ...state,
        isLoading: true
      }
    case ON_SUCCESS_RESPONSE:
      return {
        ...state,
        form: {
          ...state.form,
          isFormResponseOk: true
        }
      }
    case ON_FAILED_RESPONSE:
      return {
        ...state,
        form: {
          ...state.form,
          isFormResponseOk: false,
          formResponseErrors: payload.errors
        }
      }

    case ITEMS_RECEIVED:
      return {
        ...state,
        isLoading: false,
        items: payload.items,
        pagination: payload.pagination
      }
    case ITEM_CREATED:
      return {
        ...state,
        isLoading: false,
        creatingItem: null
      }
    case ITEM_UPDATED:
      return {
        ...state,
        updatingItem: null,
        isLoading: false,
        items: state.items.map(_ => _.id === payload.id ? payload : _)
      }
    case ITEM_DELETED:
      return {
        ...state,
        deletingItem: null,
        isLoading: false,
        items: state.items.filter(_ => _.id !== payload)
      }
    case SET_DELETING_ITEM:
      return {
        ...state,
        deletingItem: payload
      }
    case SET_UPDATING_ITEM:
      return {
        ...state,
        updatingItem: payload
      }
    case SET_CREATING_ITEM:
      return {
        ...state,
        creatingItem: payload
      }
    case SET_QUERY:
      return {
        ...state,
        query: payload
      }
    case SET_LIMIT:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          pageLimit: +payload,
          currentPage: 1
        }
      }
    case SET_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: payload
        }
      }
    case SORT_TASKS:
      return {
        ...state,
        isLoading: true,
        sort: payload
      }
    default:
      return state
  }
}
