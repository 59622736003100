export default {
  english: {
    trigger: 'Trigger',
    workflow: 'Workflow',
    action: 'Action'
  },
  french: {
    trigger: 'Trigger',
    workflow: 'Workflow',
    action: 'Action'
  }
}
