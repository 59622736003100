import {
  ADD_FORM_FORM_LIST,
  CHANGE_LIMIT_FORM_LIST,
  CHANGE_PAGE_FORM_LIST,
  DELETE_FORM_LIST,
  ERROR_FORM_LIST_ADD,
  FORM_LIST_ADDED,
  LOAD_FORM_LIST,
  RECEIVE_FORM_LIST,
  SEARCH_FORM_LIST,
  SET_FORM_LOCKED,
  SET_FORM_UNLOCKED,
  SET_FORM_UNLOCKED_SUCCESS,
  SET_TO_DELETE_FORM_LIST,
  TOGGLE_ADD_FORM_FORM_LIST
} from './actionTypes'

export const loadFormList = () => ({
  type: LOAD_FORM_LIST
})

export const receiveFormList = (data, pagination) => ({
  type: RECEIVE_FORM_LIST,
  payload: { data, pagination }
})

export const changeLimitFormList = data => ({
  type: CHANGE_LIMIT_FORM_LIST,
  payload: data
})

export const unlockForm = data => ({
  type: SET_FORM_UNLOCKED,
  payload: data
})

export const changePageFormList = data => ({
  type: CHANGE_PAGE_FORM_LIST,
  payload: data
})

export const toggleAddForm = () => ({
  type: TOGGLE_ADD_FORM_FORM_LIST
})

export const addForm = data => ({
  type: ADD_FORM_FORM_LIST,
  payload: data
})

export const formAddedFormList = id => ({
  type: FORM_LIST_ADDED,
  payload: id
})

export const errorAddingFormList = error => ({
  type: ERROR_FORM_LIST_ADD,
  payload: error
})

export const searchFormList = data => ({
  type: SEARCH_FORM_LIST,
  payload: data
})

export const deleteFormList = id => ({
  type: DELETE_FORM_LIST,
  payload: id
})

export const setFormToDelete = data => ({
  type: SET_TO_DELETE_FORM_LIST,
  payload: data
})

export const setFormLockedList = (formId, locked) => ({
  type: SET_FORM_LOCKED,
  payload: {
    formId,
    locked
  }
})
export const setFormUnlocked = formId => ({
  type: SET_FORM_UNLOCKED_SUCCESS,
  payload: { formId }
})
