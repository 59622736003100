export default {
  english: {
    assignMe: 'Set to me',
    instanceDeleted: 'This instance may have been deleted. You can try to restore it by clicking this button :',
    instanceForbidden: 'You do not have access to this instance',
    selectAForm: 'Please select a form to display.',
    noFormToInstantiate: 'No form to instantiate.',
    deleteInstance: 'Delete this instance ?',
    createInstanceFromSearchData: 'Create instance from search data',
    lastUpdatedAt: 'Last updated at ',
    unknownField: 'Unknown field',
    noLabelFound: 'No label found',
    documentModalTitle: 'Document',
    selectAReference: 'Select a reference',
    msSanteSearchButton: 'Search in health directory',
    search: 'Search',
    sign: 'Sign',
    clear: 'Clear',
    add: 'Add',
    confirm: 'Confirm',
    edit: 'Edit',
    signedBy: 'Signed by',
    signInTheBox: 'Sign in the box above',
    checkboxesFieldRequiredErrorMessage: 'You must select at least one option',
    modal: {
      sign: {
        title: 'Sign'
      },
      addFiles: {
        title: 'Add files'
      },
      consentFields: {
        title: 'Consents',
        cancel: 'Cancel',
        accept: 'Accept selection',
        acceptAll: 'Accept all',
      },
      missingFields: {
        title: 'Missing fields',
      },
      msSanteModal: {
        title: 'Pre-shipment summary',
        recipients: {
          title: 'Recipients list',
          email: 'Email',
          lastname: 'Lastname',
          firstname: 'Firstname',
          disabled: 'Recipients wihtout Mssante email cannot be selected'
        },
        cancelBtn: 'Cancel',
        sendBtn: 'Send',
        sendBtnInfos: 'Sending may take a few minutes, the status will be available on the instance',
        files: {
          title: 'File(s) to send'
        }
      },
      msSanteTrackingModal: {
        title: 'Shipment summary',
        refresh: 'refresh',
        files: {
          title: 'File(s) sended'
        }
      },
      msSanteStatus: {
        enteredInError: 'Error',
        unknown: 'Error',
        preparation: 'In progress',
        inProgress: 'In progress',
        completed: 'Sended'
      }
    },
    templateCreatorInfo: 'Use specific button to "add dynamic value".',
    maxFileSize: 'Max file size',
    fileDoNotMatchConstraints: 'File do not match constraints',
    fileMaxReach: 'You can\'t add more files of this type',
    fileDuplicate: 'You already added this file',
    generate: 'Generate',
    generateConfirmMessage: 'Generating document will replace your draft document. Do you want to continue ?',
    invalidPhone: 'Invalid phone number',
    sendTestMailButton: 'Send Test',
    invalidField: 'Invalid field.',
    saveAsFinalLabel: 'Save as final',
    signLabel: 'Sign',
    documentFinalMayAlreadyExists: 'A document may already exist, you will replace it by this one. Are you sure ?',
    invalidJSON: 'JSON code is invalid please check formatting',
    documentSignMayAlreadyExists: 'You will sign and replace the current final document, are you sure ?',
    swp: {
      errorOnSessionFetch: 'Error while fetching acquisition informations.',
      sessionAvailableForRecording: 'Session available for recording.',
      sessionAvailableForPlayback: 'Session available for playback.',
      sessionAvailableForMonitoring: 'Session available for monitoring.',
      sessionUnavailableForPlayback: 'Session unavailable for playback.',
      sessionUnavailableForMonitoring: 'Session unavailable for monitoring.',
    },
  },
  french: {
    assignMe: "M'assigner",
    instanceDeleted: 'Cette ressource à peut-être été supprimée. Vous pouvez essayer de la restaurer en cliquant sur ce bouton :',
    instanceForbidden: 'Vous n\'avez pas accès à cette ressource',
    selectAForm: 'Merci de sélectionner un formulaire à afficher.',
    noFormToInstantiate: 'Aucun formulaire à instancier.',
    deleteInstance: 'Supprimer cette instance ?',
    createInstanceFromSearchData: 'Créer une instance à partir des données de recherche',
    unknownField: 'Champ inconnu',
    noLabelFound: 'Aucun label trouvé',
    lastUpdatedAt: 'Dernière mise à jour le ',
    selectAReference: 'Sélectionnez une référence',
    msSanteSearchButton: 'Rechercher dans l\'annuaire de santé',
    documentModalTitle: 'Compte-rendu',
    search: 'Rechercher',
    sign: 'Signer',
    clear: 'Enlever',
    add: 'Ajouter',
    confirm: 'Confirmer',
    edit: 'Editer',
    signedBy: 'Signé par',
    signInTheBox: 'Signer dans la boite ci-dessus',
    checkboxesFieldRequiredErrorMessage: 'Vous devez sélectionner au moins une option',
    modal: {
      sign: {
        title: 'Signer'
      },
      addFiles: {
        title: 'Ajouter fichiers'
      },
      consentFields: {
        title: 'Consentements',
        cancel: 'Annuler',
        accept: 'Accepter la sélection',
        acceptAll: 'Tout accepter'
      },
      missingFields: {
        title: 'Champs manquants',
      },
      msSanteModal: {
        title: 'Résumé avant l\'envoi',
        recipients: {
          title: 'Liste des destinataires',
          email: 'MSSanté',
          lastname: 'Nom',
          firstname: 'Prénom',
          disabled: 'Les destinataires sans adresse MSSanté ne peuvent pas être sélectionnés'
        },
        cancelBtn: 'Annuler',
        sendBtn: 'Envoyer',
        sendBtnInfos: 'L\'envoi peut prendre quelques minutes, le statut sera disponible sur l\'instance',
        files: {
          title: 'Fichier(s) à envoyer'
        }
      },
      msSanteTrackingModal: {
        title: 'Résumé de l\'envoi',
        refresh: 'Actualiser',
        files: {
          title: 'Fichier(s) envoyé(s)'
        }
      },
      msSanteStatus: {
        enteredInError: 'Erreur',
        unknown: 'Erreur',
        preparation: 'En cours',
        inProgress: 'En cours',
        completed: 'Envoyé'
      }
    },
    templateCreatorInfo: 'Utilisez le bouton spécifique pour "ajouter une valeur dynamique"',
    maxFileSize: 'Taille de fichier maximum',
    fileDoNotMatchConstraints: 'Le fichier n\'est pas conforme aux contraintes',
    fileMaxReach: 'Vous ne pouvez pas mettre plus de fichier de ce type',
    fileDuplicate: 'Vous avez déjà ajouté ce fichier',
    generate: 'Générer',
    generateConfirmMessage: 'La génération du document va remplacer votre brouillon. Souhaitez vous continuer ?',
    invalidPhone: 'Numéro de téléphone invalide',
    endTestMailButton: 'Envoyer un mail Test',
    invalidField: 'Champ invalide.',
    saveAsFinalLabel: 'Sauvegarder en final',
    signLabel: 'Signer',
    documentFinalMayAlreadyExists: 'Si un autre document a été généré vous allez le remplacer par celui-ci. Êtes-vous sûr de continuer ?',
    documentSignMayAlreadyExists: 'Vous allez signer et remplacer le document final, êtes-vous sûr ?',
    invalidJSON: 'Format JSON invalide',
    swp: {
      errorOnSessionFetch: 'Erreur lors de la récupération des informations de l\'acquisition.',
      sessionAvailableForRecording: 'Session disponible pour l\'enregistrement.',
      sessionAvailableForPlayback: 'Session disponible pour le playback.',
      sessionAvailableForMonitoring: 'Session disponible pour le monitoring.',
      sessionUnavailableForPlayback: 'Session indisponible pour le playback.',
      sessionUnavailableForMonitoring: 'Session indisponible pour le monitoring.',
    },
  }
}
