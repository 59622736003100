import {
  FORM_PERMISSIONS_SUBMIT, FORM_PERMISSIONS_SUBMIT_SET_STATE,
  NEW_PERMISSIONS_SAVED,
  RESET,
  SET_FORMS,
  SET_HAS_DATA_CHANGED,
  SET_LANDING_PAGES,
  SET_LISTS, SET_LOG_AS_ROLES,
  SET_NEW_SUBJECT_DATA,
  SET_NUMERIC_REPORTS,
  SET_ROLE,
  SET_SUBJECT,
  SET_SUBJECT_DATA,
  SET_USER,
} from './actionTypes'
import { User } from 'src/Types/User'
import Role from 'src/Types/Role'
import { Action } from 'src/Services/Store/reducers'
import { PermissionSubject } from 'src/Views/Permissions/Types/Subject'
import { Form } from 'src/Types/Form'
import { List } from 'src/Types/List'
import NumericReport from 'src/Types/NumericReport'
import LandingPage from 'src/Types/LandingPage'
import Permission from 'src/Types/Permission'
import CompleteFormPermissions from 'src/Views/Permissions/Types/CompleteFormPermissions'

export interface PermissionsState {
  landingPages: LandingPage[],
  forms: Form[],
  lists: List[],
  numericReports: NumericReport[],
  logAsRoles: Role[],
  role: Role | null,
  user: User | null,
  subject: PermissionSubject | null,
  subjects: PermissionSubject[],
  hasDataChanged: boolean,
  subjectData: Permission[] | CompleteFormPermissions,
  newSubjectData: Permission[] | CompleteFormPermissions,
  onPermissionsFormSubmit: () => null,
  permissionFormSubmitState: boolean
}

const initialState: PermissionsState = {
  landingPages: [],
  forms: [],
  lists: [],
  numericReports: [],
  logAsRoles: [],
  role: null,
  user: null,
  subject: null,
  subjects: Object.values(PermissionSubject),
  hasDataChanged: false,
  subjectData: [],
  newSubjectData: [],
  onPermissionsFormSubmit: null,
  permissionFormSubmitState: false
}

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case FORM_PERMISSIONS_SUBMIT_SET_STATE:
      return {
        ...state,
        permissionFormSubmitState: payload
      }
    case FORM_PERMISSIONS_SUBMIT:
      return {
        ...state,
        onPermissionsFormSubmit: payload
      }
    case SET_ROLE:
      return {
        ...state,
        role: payload,
        user: initialState.user,
        hasDataChanged: initialState.hasDataChanged,
        subjectData: initialState.subjectData,
        newSubjectData: initialState.newSubjectData,
      }
    case SET_USER:
      return {
        ...state,
        user: payload,
        role: initialState.role,
        hasDataChanged: initialState.hasDataChanged,
        subjectData: initialState.subjectData,
        newSubjectData: initialState.newSubjectData,
      }
    case SET_SUBJECT:
      return {
        ...state,
        subject: payload,
        hasDataChanged: initialState.hasDataChanged,
        subjectData: initialState.subjectData,
        newSubjectData: initialState.newSubjectData,
      }
    case SET_LANDING_PAGES:
      return {
        ...state,
        landingPages: payload
      }
    case SET_FORMS:
      return {
        ...state,
        forms: payload?.forms || []
      }
    case SET_LISTS:
      return {
        ...state,
        lists: payload
      }
    case SET_NUMERIC_REPORTS:
      return {
        ...state,
        numericReports: payload
      }
    case SET_LOG_AS_ROLES:
      return {
        ...state,
        logAsRoles: payload
      }
    case SET_SUBJECT_DATA:
      return {
        ...state,
        subjectData: payload
      }
    case SET_HAS_DATA_CHANGED:
      return {
        ...state,
        hasDataChanged: payload
      }
    case NEW_PERMISSIONS_SAVED:
      return {
        ...state,
        newSubjectData: [],
        hasDataChanged: false,
        permissionFormSubmitState: false
      }
    case SET_NEW_SUBJECT_DATA:

      let newSubjectData

      if (payload.subject === PermissionSubject.FORM)
        newSubjectData = { ...state.newSubjectData, [payload.subject]: payload.data }
      else if (payload.subject === PermissionSubject.SECTION)
        newSubjectData = { ...state.newSubjectData, [payload.subject]: payload.data }
      else if (payload.subject === PermissionSubject.FIELD)
        newSubjectData = { ...state.newSubjectData, [payload.subject]: payload.data }
      else
        newSubjectData = payload.data

      return {
        ...state,
        hasDataChanged: JSON.stringify(state.subjectData) !== JSON.stringify(newSubjectData),
        newSubjectData
      }
    case RESET:
      return {
        ...state,
        role: initialState.role,
        user: initialState.user,
        subject: initialState.subject
      }
    default:
      return state
  }
}
