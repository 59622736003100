
export default {
  english: {
    title: 'Navigation items manager',
    addNew: 'Add a event status',
    entries: 'of {total} entries',
    isActive: 'Show in navbar',
    form: {
      label: 'Label',
      systemName: 'System name',
      systemNameInfo: 'Use only caps and underscore for spacing.',
      color: 'Color',
      isSystem: 'Is system',
    },
    modal: {
      create: {
        title: 'Create a new event status'
      },
      update: {
        title: 'Update a event status'
      },
      confirmDelete: {
        title: 'Are you sure you want to delete this event status ?'
      }
    }
  },
  french: {
    title: 'Gestionnaire d\'élément de navigation',
    addNew: 'Créer un élément de navigation',
    entries: 'sur {total} entrées',
    isActive: 'Afficher dans la barre de navigation',
    form: {
      label: 'Label',
      systemName: 'Nom système',
      systemNameInfo: 'Utilisez seulement majuscules et tiret bas pour les espaces.',
      color: 'Couleur',
      isSystem: 'Est système',
    },
    modal: {
      create: {
        title: 'Créer un nouveau élément de navigation'
      },
      update: {
        title: 'Modifier un élément de navigation'
      },
      confirmDelete: {
        title: 'Êtes-vous sûr de vouloir supprimer cet élément de navigation ?'
      }
    }
  }
}
