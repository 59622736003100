import React from 'react'
import { useParams, Link } from 'react-router-dom'
import { useActions } from 'src/Components/Util'
import { translate, translateConf } from 'src/Services/translation'
import translations from '../translations'
import { formatDateTime } from 'src/Utils/Date'
import { Table, Container, Spinner } from 'react-bootstrap'
import { setOptions } from 'src/Layouts/View/state/actions'
import { useDispatch, useSelector } from 'react-redux'
import ListCell from 'src/Components/ListCell'
import { fetchEncounters } from 'src/Views/Patient/state/actions'
import { Instance } from 'src/Types/Instance'
import { Form } from 'src/Types/Form'
import { ListColumn } from 'src/Types/ListColumn'

export interface PatientInstance {
  headers: ListColumn[]
  instances: Instance[]
  form: Form
}

const PatientOverview = () => {
  const dispatch = useDispatch()
  const { id } = useParams()

  // @ts-ignore
  const { fetchEncountersStatus, encounters } = useSelector(state => state.Patient)
  // @ts-ignore
  const { isLoading } = useSelector(state => state.View)
  // @ts-ignore
  const { configTranslations, user } = useSelector(state => state.Root)

  const { locale, language, portalTimezone } = user

  const trans = translate(translations)(language)
  const transConf = translateConf(configTranslations)
  const formatLocaleDateTime = formatDateTime(locale, portalTimezone)

  useActions([ fetchEncounters(id) ])([])([])

  React.useEffect(() => {
    dispatch(setOptions({
                          title: trans('patientEncounters'),
                          hasPrimaryButton: false,
                          isLoading: true,
                          isHeaderSticky: false,
                        }))
  }, [ dispatch ])

  if (fetchEncountersStatus === 'ongoing')
    return <Spinner animation={'border'}/>

  return <Container>
    <Table bordered hover striped>
      <thead>
      <tr>
        <th>{trans('recordInfo')}</th>
        <th>{trans('created')}</th>
        <th>{trans('modified')}</th>
        <th>{trans('actions')}</th>
      </tr>
      </thead>

      <tbody>

      {encounters.map((encounter: PatientInstance) =>
        encounter.instances.map((instance: Instance) => (<tr key={instance.id}>
          <td>
            {encounter.headers.length ? encounter.headers.map(header =>
               (<div key={header.id}>
                 <span>{transConf('LIST_COLUMN')(header)} :&nbsp;</span>
                     <span className="fw-bold text-primary">
                      <ListCell value={instance.values[header.systemName] || null} field={header.field}/>
                    </span>
               </div>))
            : (<div>
              <div>{trans('created')} :&nbsp;
                <span className="fw-bold text-primary">{formatLocaleDateTime(instance.createdAt)}</span>
              </div>
                <div>{trans('modified')} :&nbsp;
                  <span className="fw-bold text-primary">{formatLocaleDateTime(instance.updatedAt)}</span>
                </div>
              </div>)
            }
          </td>
          <td>{formatLocaleDateTime(instance.createdAt)}</td>
          <td>{formatLocaleDateTime(instance.updatedAt)}</td>
          <td><Link to={`/patient/${id}/instance/${instance.id}`}>{trans('open')}</Link></td>
        </tr>
        ))
      )}
      </tbody>
    </Table>
  </Container>
}

export default PatientOverview
