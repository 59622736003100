export const FETCH_SLOT_TYPES = '[SLOT_TYPES] FETCH_SLOT_TYPES'
export const RECEIVE = '[SLOT_TYPES] RECEIVE'
export const RECEIVE_SLOT_TYPES = '[SLOT_TYPES] RECEIVE_SLOT_TYPES'
export const CHANGE_LIMIT = '[SLOT_TYPES] CHANGE_LIMIT'
export const CHANGE_PAGE = '[SLOT_TYPES] CHANGE_PAGE'
export const TOGGLE_CREATE_SLOT_TYPE = '[SLOT_TYPES] TOGGLE_CREATE_SLOT_TYPE'
export const TOGGLE_UPDATE_SLOT_TYPE = '[SLOT_TYPES] TOGGLE_UPDATE_SLOT_TYPE'
export const TOGGLE_DELETE_SLOT_TYPE = '[SLOT_TYPES] TOGGLE_DELETE_SLOT_TYPE'
export const CREATE_SLOT_TYPE = '[SLOT_TYPES] CREATE_SLOT_TYPE'
export const SLOT_TYPE_CREATED = '[SLOT_TYPES] SLOT_TYPE_CREATED'
export const UPDATE_SLOT_TYPE = '[SLOT_TYPES] UPDATE_SLOT_TYPE'
export const SLOT_TYPE_UPDATED = '[SLOT_TYPES] SLOT_TYPE_UPDATED'
export const DELETE_SLOT_TYPE = '[SLOT_TYPES] DELETE_SLOT_TYPE'
export const SLOT_TYPE_DELETED = '[SLOT_TYPES] SLOT_TYPE_DELETED'
export const SEARCH_SLOT_TYPE = '[SLOT_TYPES] SEARCH_SLOT_TYPE'
export const SET_FORM_ERRORS = '[SLOT_TYPES] SET_FORM_ERRORS'
