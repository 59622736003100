import React, { ChangeEvent } from 'react'
import { Col, Row } from 'react-bootstrap'
import Pagination from 'src/Components/Pagination'
import PaginationType from 'src/Types/Pagination'
import SearchInput from 'src/Components/SearchInput'

interface TableNavProps {
  pagination?: PaginationType
  onSearchInputChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onPageClick: (currentPage: number, newPage: number) => void
  isSearchable: boolean,
}

const TableNav: React.FC<TableNavProps> = ({
  pagination = null,
  onSearchInputChange,
  onPageClick = () => null,
  isSearchable = true,
}) =>
  <Row className="justify-content-between">
    <Col xs="auto">
      {isSearchable && onSearchInputChange && (
        <SearchInput onChange={onSearchInputChange} />
      )}
    </Col>
    { pagination && <Col xs="auto">
      <Pagination currentPage={pagination.currentPage}
                  onPageClick={onPageClick}
                  pageCount={pagination.totalPagesCount}
      />
    </Col> }
  </Row>

export default TableNav
