import { TOGGLE_CONFIRM_MODAL } from './action-types'

const initialState = {
  isConfirmModalOpen: false,
  confirmModalCallback: null
}

export default (state = initialState, {
  type,
  payload
}) => {
  switch (type) {
    case TOGGLE_CONFIRM_MODAL:
      return {
        ...state,
        isConfirmModalOpen: !state.isConfirmModalOpen,
        confirmModalCallback: payload
      }
    default:
      return state
  }
}
