import { call, put, takeLatest } from 'redux-saga/effects'
import { DO_IMPORT, FETCH_LIST_SEARCHES, POST_LIST_SEARCH, DELETE_LIST_SEARCH } from './actionTypes'
import apiMethods from '../../../Services/api/apiMethods'
import { setListSearchesAction, fetchListSearchesAction } from './actions'

function* importCsv(props, { payload: items }) {
  try {
    // TODO: import process
    console.log('import', items)
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'actionFailed'))
  }
}

function* fetchListSearches(props, { payload: id }) {
  try {
    const { data } = yield call(apiMethods.get, `/list_searches/${ id }`)
    yield put(setListSearchesAction(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'actionFailed'))
  }
}

function* postListSearch(props, { payload: search }) {
  try {
    yield call(apiMethods.create, `/list_searches/${ search.listId }`,
      { data: { filters: JSON.stringify(search.data), name: search.title } })
    yield put(fetchListSearchesAction(search.listId))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'actionFailed'))
  }
}

function* deleteListSearch(props, { payload: { listId, id } }) {
  try {
    yield call(apiMethods.delete, `/list_searches/${ id }`)
    yield put(fetchListSearchesAction(listId))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'actionFailed'))
  }
}

export default function* zmrSagaWatcher(props) {
  yield takeLatest(FETCH_LIST_SEARCHES, fetchListSearches, props)
  yield takeLatest(POST_LIST_SEARCH, postListSearch, props)
  yield takeLatest(DELETE_LIST_SEARCH, deleteListSearch, props)
  yield takeLatest(DO_IMPORT, importCsv, props)
}
