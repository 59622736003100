import React, { useEffect, useState } from 'react'
import {
  setDeletingItem,
  deleteItem,
  setUpdatingItem,
  updateItem,
  setCreatingItem,
  createItem,
  fetchItemsMyTasks
} from './state/actions'
import { translate } from 'src/Services/translation'
import translation from './translations'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import Modal from '../../Components/Modal'
import { default as TaskForm } from './Components/TaskForm'
import './index.css'

export const renderValuesIntoString = (subject, data) => {
  let name = null
  if (subject === 'roles') {
    name = 'name'
  }
  if (subject === 'users') {
    name = 'username'
  }
  let values = []
  for (const item in data) {
    values.push(data[item][name])
  }

  return values.join(', ')
}

const Tasks = props => {
  const [ innerState, setInnerState ] = useState({
    isTasks: false,
    isHover: false,
    isDisplayed: false,
    listTasks: null,
    tasksCounter: 0,
    isFormOk: null
  })

  const dispatch = useDispatch()
  const { form, items, isLoading, pagination } = useSelector(state => state.Tasks)

  const [ isModalContentOpened, setIsModalContentOpened ] = useState(false)
  const [ isModalOpened, setIsModalOpened ] = useState(false)
  const [ stateUpdatingItem, setStateUpdatingItem ] = useState(null)

  const { language } = useSelector(state => state.Root.user)
  const trans = translate(translation)(language)

  useEffect(() => {
    //Fetch the all tasks data
    dispatch(fetchItemsMyTasks())

    setInnerState({
      ...innerState,
      listTasks: items,
      tasksCounter: pagination.totalItemsCount,
      isFormOk: form.isFormResponseOk
    })
  }, [ form ])

//TODO - Essai: useEffect to get back the data of formResponse to find the bool
// modal behavior - if ok close, if not display errors

  const addTask = () => {
    setIsModalOpened(!isModalOpened)
  }

  const onModalUpdateFormSubmit = data => {
    if (data.isSetItem) {
      delete data.isSetItem
      delete data.isSetItem
      dispatch(setUpdatingItem(data))
      dispatch(updateItem())
    } else {
      dispatch(setCreatingItem(data))
      dispatch(createItem())
    }

    setIsModalOpened(!isModalOpened)
  }

  const resetUpdate = () => {
    setStateUpdatingItem(null)
    setUpdatingItem(null)
  }

  const onModalUpdateClose = () => {
    setIsModalOpened(false)
  }

  const goToUpdate = (e, item) => {
    e.preventDefault()
    setStateUpdatingItem(item)
    addTask()
  }
  const deleteItemTask = (e, item) => {
    e.preventDefault()
    dispatch(setDeletingItem(item))
    dispatch(deleteItem())
    setIsModalOpened(!isModalOpened)
  }

  return (
    <>
      { items ?
        <div className={ 'row' } id={ 'container-tasks' }>
          <div className={ 'col-sm-6 mb-3' }>
            <ul className={ 'list-group list-group-horizontal' }>
              <li className={ 'list-group-item' }>
                <a href="/"
                   onClick={ e => {
                     e.preventDefault()
                     setIsModalContentOpened(!isModalContentOpened)
                   }
                   }
                >
                  <svg xmlns="http://www.w3.org/2000/svg"
                       version="1.1"
                       viewBox="0 0 200 100"
                       width="120"
                  >
                    <g id={ 'circle-tasks-nb' } transform="translate(50, 50)">
                      <circle r="50" fill="#51BAC3"/>
                      <text style={ { fontSize: '3rem', color: '#FFF' } }
                            textAnchor="middle"
                            dominantBaseline="central"
                      >{ items.length }</text>
                    </g>
                  </svg>
                </a>

              </li>
              <li className={ 'list-group-item' }>
                <a href="/"
                   onClick={ e => {
                     e.preventDefault()
                     setIsModalContentOpened(!isModalContentOpened)
                   }
                   }
                >
                  <p style={ { position: 'relative', fontSize: '1rem', top: '18px', right: '85px' } }>Tasks
                                                                                                      remaining</p>
                </a>
              </li>
              <li className={ 'list-group-item' }
              >
                <Button className={ 'btn btn-sm' }
                        style={ {
                          color: '#FFF', backgroundColor: '#51bac3', border: '#fff solid 1px',
                          position: 'relative', top: '15px', right: '100px', pointer: 'cursor'
                        } }
                        onClick={ () => setIsModalContentOpened(!isModalContentOpened) }
                >
                  Display Tasks
                </Button>
              </li>
            </ul>
          </div>
          { isLoading ? <h4>...LOADING</h4>
            :
            <Modal size={ 'xl' }
                   isOpen={ isModalContentOpened }
                   title={ trans('listing_title') }
                   onClose={ () => setIsModalContentOpened(!isModalContentOpened) }>

              <div className="container text-break">
                <table className="table table-sm table-hover table-striped table-bordered">
                  <thead className={ 'text-center align-middle' }>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Description</th>
                    <th scope="col">Roles</th>
                    <th scope="col">Users</th>
                    <th scope="col">Type</th>
                    <th scope="col">Category</th>
                  </tr>
                  </thead>
                  <tbody>
                  { items.map((item, i) => {
                      let rolesData = item.roles ? renderValuesIntoString('roles', item.roles) : ''
                      let usersData = item.users ? renderValuesIntoString('users', item.users) : ''

                      return (
                        <tr key={ i }>
                          <th scope="row">{ i + 1 }</th>
                          <td><Link to={ '/' } onClick={ e => goToUpdate(e, item) }>{ item.name?.toUpperCase() }</Link>
                          </td>
                          <td>{ item.description }</td>
                          <td>{ rolesData ?? 'NC' }</td>
                          <td>{ usersData ?? 'NC' }</td>
                          <td>{ item?.category?.name ?? 'NC' }</td>
                          <td>{ item?.type?.name ?? 'NC' }</td>
                        </tr>
                      )
                    }
                  )
                  }
                  <tr></tr>
                  <tr>
                    <td colSpan={ 3 } style={ { paddingTop: '10px' } }>
                      <Button className={ 'btn btn-sm btn-success' } onClick={ addTask }>
                        { trans('addNew') }
                      </Button>
                    </td>
                    <td colSpan={ 4 } style={ { paddingTop: '10px' } }>
                      <div className={ 'container' } style={ {
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexDirection: 'row',
                        padding: '0'
                      } }>
                        <Link to={ '/tasks' }>
                          <Button className={ 'btn btn-sm btn-primary' }>{ trans('seeAll') }</Button>
                        </Link>
                        <Link to={ '/tasks_my_tasks' }>
                          <Button className={ 'btn btn-sm btn-primary' }
                                  style={ {
                                    color: '#FFF', backgroundColor: '#51bac3', border: '#fff solid 1px'
                                  } }
                          >{ trans('seeMy') }</Button>
                        </Link>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </Modal>
          }

          <Modal size={ 'lg' } isOpen={ isModalOpened } title={ trans('modal.create.title') } onExited={resetUpdate}
                 onClose={ onModalUpdateClose }>
            { !stateUpdatingItem &&
              <TaskForm onSubmit={ onModalUpdateFormSubmit }
                        deleteTask={ deleteItemTask }
              />
            }
            { stateUpdatingItem &&
              <TaskForm onSubmit={ onModalUpdateFormSubmit }
                        deleteTask={ deleteItemTask }
                        item={ stateUpdatingItem }
              /> }
          </Modal>
        </div>
        :
        <div className={ 'row' }>
          <div className="col-3 mb-3">
            <p>No task saved</p>
            <Button className={ 'btn-sm' } onClick={ addTask }>Add a new task</Button>
          </div>
        </div>
      }
    </>
  )
}

export default Tasks
