import React, { useEffect, useState } from 'react'
import {
  setDeletingItem,
  deleteItem,
  setUpdatingItem,
  updateItem,
  setCreatingItem,
  createItem,
  fetchItems,
  fetchItemsMyTasks
} from '../Tasks/state/actions'
import { translate } from 'src/Services/translation'
import translation from '../Tasks/translations'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../../Components/Modal'
import { default as TaskForm } from '../Tasks/Components/TaskForm'
import TasksPageTable from './Components/TasksPageTable'
import { Container } from 'react-bootstrap'
import { setOptions } from 'src/Layouts/View/state/actions'

const TasksMyTasks = () => {
  const [ innerState, setInnerState ] = useState({
    isTasks: false,
    isHover: false,
    isDisplayed: false,
    listTasks: null,
    tasksCounter: 0,
    isFormOk: null
  })

  const dispatch = useDispatch()
  const { form, items } = useSelector(state => state.Tasks)

  const [ isModalOpened, setIsModalOpened ] = useState(false)
  const [ stateUpdatingItem, setStateUpdatingItem ] = useState(null)

  const { language } = useSelector(state => state.Root.user)
  const trans = translate(translation)(language)

  useEffect(() => {
    dispatch(fetchItemsMyTasks())

    setInnerState({
      ...innerState,
      listTasks: items,
      tasksCounter: items.length,
      isFormOk: form.isFormResponseOk
    })
  }, [ form ])

//Essai: useEffect to get back the data of formResponse to find the bool
  // modal behavior - if ok close, if not display errors

  const addTask = e => {
    setIsModalOpened(!isModalOpened)
  }

  const onModalUpdateFormSubmit = data => {
    if (data.isSetItem) {
      delete data.isSetItem
      delete data.isSetItem
      dispatch(setUpdatingItem(data))
      dispatch(updateItem())
    } else {
      dispatch(setCreatingItem(data))
      dispatch(createItem())
    }

    setIsModalOpened(!isModalOpened)
  }

  const resetUpdate = () => {
    setStateUpdatingItem(null)
    setUpdatingItem(null)
  }

  const onModalUpdateClose = () => {
    setIsModalOpened(false)
  }

  const goToUpdate = (e, item) => {
    e.preventDefault()
    setStateUpdatingItem(item)
    addTask()
  }
  const deleteItemTask = (e, item) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(setDeletingItem(item))
    dispatch(deleteItem())
    setIsModalOpened(false)
  }

  useEffect(() => {
    dispatch(setOptions({
      hasPrimaryButton: true,
      onPrimaryButtonClick: addTask
    }))
    dispatch(fetchItems())
  }, [ dispatch ])

  return (
    <Container>
      <TasksPageTable onUpdateClick={ goToUpdate }
                      onDeleteClick={ deleteItemTask }
      />

      <Modal size={ 'lg' } isOpen={ isModalOpened } title={ trans('modal.create.title') } onExited={resetUpdate}
             onClose={ onModalUpdateClose }>
        { !stateUpdatingItem && <TaskForm onSubmit={ onModalUpdateFormSubmit }
                                          deleteTask={ deleteItemTask }
        /> }
        { stateUpdatingItem &&
          <TaskForm onSubmit={ onModalUpdateFormSubmit }
                    deleteTask={ deleteItemTask }
                    item={ stateUpdatingItem }
          /> }
      </Modal>
    </Container>
  )
}

export default TasksMyTasks

