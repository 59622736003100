export default {
  sizes: {
    header: {
      height: '55px',
      heightInPixel: 45
    },
    formFillerSidebar: {
      width: '20em'
    },
    forms: {
      defaultInputWidth: '270px'
    },
    sidebar: {
      width: '14em'
    },
    body: {
      maxWidth: '90%',
      margin: 'auto',
      padding: '0 1em',
      height: '100%'
    },
    stickyMenus: {
      top: '90px'
    }
  },
  colors: {
    grey: {
      alpha: {
        lightest: transparency => `rgba(179, 179, 179, ${transparency})`,
        light: transparency => `rgba(153, 153, 153, ${transparency})`,
        median: transparency => `rgba(77, 77, 77, ${transparency})`,
        dark: transparency => `rgba(25, 25, 25, ${transparency})`,
        darkest: transparency => `rgba(0, 0, 0, ${transparency})`
      },
      extralight: '#E5E5E5',
      lightest: '#B3B3B3',
      light: '#999999',
      median: '#4D4D4D',
      dark: '#191919',
      darkest: '#000000',
      disabledInputsBackground: '#e5e5e55c'
    },
    primary: {
      alpha: {
        light: transparency => `rgba(0, 61, 191, ${transparency})`
      },
      lightest: '#0051FF',
      light: '#1e4dad',
      median: '#003DBF',
      dark: '#002880',
      darkest: '#001440'
    },
    secondary: {
      alpha: {
        greyish: transparency => `rgba(74, 83, 97, ${transparency})`,
        light: transparency => `rgba(229, 245, 252, ${transparency})`,
        median: transparency => `rgba(113, 200, 241, ${transparency})`,
        dark: transparency => `rgba(13, 97, 137, ${transparency})`
      },
      greyish: '#4A5361',
      lightest: '#C2D7E1',
      light: '#E5F5FC',
      median: '#71C8F1',
      dark: '#0D6189'
    },
    midnightBlue: {
      alpha: {
        median: transparency => `rgba(84, 105, 141, ${transparency})`
      },
      light: '#444C63',
      median: '#54698D'
    },
    green: {
      median: '#00C322'
    },
    white: {
      alpha: {
        median: transparency => `rgba(255, 255, 255, ${transparency})`
      },
      median: '#FFFFFF',
      dark: '#FBFBFB',
      darkest: '#F7F7F7'
    }
  },
  styleguideColors: {
    black: '#000000',
    darkBlue: '#71c8f1',
    mainOrange: '#ff8533',
    mainGrey: '#999999',
    lightGrey: '#b3b3b3',
    background: '#e5f5fc',
    mainHoverOrange: '#ff6600',
    darkOrange: '#cc5200',
    statusRescheduled: '#ea9e17',
    statusConfirmed: '#a70c0c',
    statusScheduled: '#10ce4d',
    statusCancelled: '#295a9d',
    statusNoShow: '#474747',
    middleGrey: '#4d4d4d',
    statusComplete: '#9660b9',
    nightBlue: '#061c3f',
    darkSlateBlue: '#16325c',
    marineBlue: '#00396b',
    oceanBlue: '#005fb2',
    cerulean: '#0070d2',
    deepSkyBlue: '#1589ee',
    darkGrey: '#191919',
    paleBlue: '#e0e5ee',
    white: '#ffffff',
    darkSkyBlue: '#0d6189',
    lightBlueGrey: '#54698d',
    blueGrey: '#8c97b2',
    lightGreyBlue: '#a8b7c7',
    lightPeriwinkle: '#dadfea',
    paleGrey: '#f4f6f9',
    jungleGreen: '#04844b',
    success: '#4bca81',
    greenFluo: '#00c322',
    lightRed: '#a60c00',
    dullRed: '#c23934',
    error: '#e64c66',
    paleYellow: '#ffad0d',
    paleOrange: '#ffb75d',
    coolGrey: 'rgba(159, 170, 181, 0.8)'
  },
  states: {
    lightButtons: `
      :hover {
        color: black;
      }

      :focus {
        outline: 0;
        box-shadow: 0 3px 6px 0 rgba(77, 77, 77, 0.12), 0 3px 6px 0 rgba(77, 77, 77, 0.4);
      }

      :active {
        background-color: ${props => props.theme.colors.grey.alpha.lightest(0.2)};
      }
    `
  },
  breakpoints: {
    ipad: 'max-width: 768px'
  }
}
