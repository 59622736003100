import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { translate } from 'src/Services/translation'
import Modal from 'src/Components/Modal'
import LocalizedDatepicker from 'src/Components/LocalizedDatepicker'
import { showWarning } from 'src/Services/notifier/actions'
import { formatDateTime } from 'src/Utils/Date.ts'
import {
  FIRST_NAME, LAST_NAME, BIRTH_NAME, DATE_OF_BIRTH, MOBILE_PHONE, HOME_PHONE,
  EXTERNAL_ID, GENDER, GENDER_MALE, GENDER_FEMALE
} from 'src/Services/Constants/Config/Patient'
import translations from './translations'
import { resetPatientCreation, createPatient, searchSimilarPatients } from './state/actions'

const PatientCreator = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { isLoading } = useSelector(state => state.View)
  const { language, locale, portalTimezone } = useSelector(state => state.Root.user)
  const { isOpen, patient, similarPatients } = useSelector(state => state.PatientCreator)

  const trans = translate(translations)(language)
  const formatDate = formatDateTime(locale, portalTimezone)

  const [ newPatient, setNewPatient ] = React.useState(patient)
  const [ hasSearchOnce, setHasSearchOnce ] = React.useState(false)

  const reset = () => {
    setNewPatient(patient)
    setHasSearchOnce(false)
  }

  React.useEffect(() => {
    if (patient)
      setNewPatient(patient)
    else reset()
  }, [ patient ])

  const onInput = name => value => {
    setNewPatient(old => ({ ...old, [name]: value }))
  }

  const onCreateClick = () => {
    if (!newPatient?.[FIRST_NAME] || !newPatient?.[LAST_NAME] || !newPatient?.[DATE_OF_BIRTH] || !newPatient?.[GENDER])
      return dispatch(showWarning(trans('formIncomplete')))

    const fullPatient = { ...patient, ...newPatient }

    const searchPatient = { ...newPatient }
    delete searchPatient?.[GENDER]

    if (!hasSearchOnce) {
      dispatch(searchSimilarPatients(searchPatient, fullPatient, navigate))
      setHasSearchOnce(true)
    } else {
      dispatch(createPatient(fullPatient, navigate))
    }
  }

  return (
      <Modal
        isOpen={isOpen}
        onClose={() => dispatch(resetPatientCreation())}
      >
        <>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>{trans('lastName')}</Form.Label>
              <Form.Control type="text" value={newPatient?.[LAST_NAME] || ''}
                            onChange={e => onInput(LAST_NAME)(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{trans('firstName')}</Form.Label>
              <Form.Control type="text" value={newPatient?.[FIRST_NAME] || ''}
                            onChange={e => onInput(FIRST_NAME)(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{trans('dateOfBirth')}</Form.Label>
              <LocalizedDatepicker selected={newPatient?.[DATE_OF_BIRTH]}
                                   size="md"
                                   showDateOnly
                                   onChange={onInput(DATE_OF_BIRTH)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{trans('gender')}</Form.Label>
              <div key="inline-radio" className="mb-3">
                <Form.Check
                  inline
                  label={ trans('gender_male') }
                  name="gender"
                  type="radio"
                  id="gender-0"
                  value={GENDER_MALE}
                  onChange={() => onInput(GENDER)(GENDER_MALE)}
                  checked={GENDER_MALE === newPatient?.[GENDER]}
                  required={true}
                />
                <Form.Check
                  inline
                  label={ trans('gender_female') }
                  name="gender"
                  type="radio"
                  id="gender-1"
                  value={GENDER_FEMALE}
                  onChange={() => onInput(GENDER)(GENDER_FEMALE)}
                  checked={GENDER_FEMALE === newPatient?.[GENDER]}
                  required={true}
                />
              </div>
            </Form.Group>
          </Form>

          <Row>
            <Col>
              {!!similarPatients.length && <div className="blue-card">
                <div>
                  <b>{trans('similarPatientFoundLabel')} :</b>
                </div>
                {
                  similarPatients.map(patient =>
                    <div className="py-2" key={patient.id}>
                      <Link to={`/patient/${patient.patientId}/instance/${patient.id}`}
                            onClick={() => dispatch(resetPatientCreation())}>
                        <span>
                          <b>{patient[LAST_NAME]} {patient[FIRST_NAME]} </b>
                          {patient[BIRTH_NAME] && <span>{ trans('born')} <b>{patient[BIRTH_NAME]} </b></span>}
                          {formatDate(patient[DATE_OF_BIRTH], true, false)}.
                        </span>
                        {patient[MOBILE_PHONE] && <span><b>{trans('mobilePhone')} : </b>{patient[MOBILE_PHONE]} </span>}
                        {patient[HOME_PHONE] && <span><b>{trans('homePhone')} : </b>{patient[HOME_PHONE]} </span>}
                        {patient[EXTERNAL_ID] && <span><b>{trans('externalId')} : </b>{patient[EXTERNAL_ID]}</span>}
                      </Link>
                    </div>)
                }
              </div>}
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Button className="d-block m-auto" variant="primary"
                      disabled={isLoading}
                      onClick={onCreateClick}
              >
                {similarPatients.length ? trans('createForSure') : trans('create')}
              </Button>
            </Col>
          </Row>
        </>
      </Modal>
    )

}

export default PatientCreator
