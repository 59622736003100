import * as types from './actionTypes'

const initialState = {
  isListsOpen: false
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SET_IS_LISTS_OPEN:
      return {
        ...state,
        isListsOpen: !!payload
      }
    default:
      return state
  }
}
