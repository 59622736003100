import * as types from './actionTypes'
import { Action } from 'src/Services/Store/reducers'
import { AdminTool } from 'src/Types/AdminTool'
import Pagination from 'src/Types/Pagination'

export interface AdminToolsState {
  loading: boolean
  isAddingAdminTool: boolean
  isUpdatingAdminTool: boolean
  isDeletingAdminTool: boolean
  adminToolOnUpdate: AdminTool | null
  adminToolOnDelete: AdminTool | null
  errorsAddingNewAdminTool: object
  errorsDeletingAdminTool: object
  roleAnnouncement: number
  adminToolForms: []
  adminToolsList: AdminTool[]
  searchQuery: string
  pagination: Pagination
}

const initialState: AdminToolsState = {
  loading: false,
  isAddingAdminTool: false,
  isUpdatingAdminTool: false,
  isDeletingAdminTool: false,
  adminToolOnUpdate: null,
  adminToolOnDelete: null,
  errorsAddingNewAdminTool: {},
  errorsDeletingAdminTool: {},
  roleAnnouncement: 1,
  adminToolForms: [],
  adminToolsList: [],
  searchQuery: null,
  pagination: {
    offset: 0,
    limit: 10,
    itemsCount: 10,
    totalItemsCount: 10,
    pageLimit: 10,
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPagesCount: 1,
  },
}

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.FETCH_ADMIN_TOOLS:
      return {
        ...state,
        loading: true,
      }

    case types.RECEIVE_ADMIN_TOOLS: {
      return {
        ...state,
        loading: false,
        adminToolsList: payload.adminToolsList,
        pagination: payload.pagination,
      }
    }

    case types.ADMIN_TOOL_ADDED:
      return {
        ...state,
        loading: false,
        adminToolsList: [ ...state.adminToolsList, payload ],
      }
    case types.ADMIN_TOOL_UPDATED:
      return {
        ...state,
        loading: false,
        adminToolsList: state.adminToolsList.map(_ => _.id === payload.id ? payload : _),
      }
    case types.ADMIN_TOOL_DELETED:
      return {
        ...state,
        loading: false,
        adminToolOnDelete: null,
        adminToolsList: state.adminToolsList.filter(_ => _.id !== payload),
      }

    case types.TOGGLE_ADD_ADMIN_TOOL:
      return {
        ...state,
        isAddingAdminTool: !state.isAddingAdminTool,
      }
    case types.TOGGLE_UPDATE_TOOL:
      return {
        ...state,
        isUpdatingAdminTool: !!payload,
        adminToolOnUpdate: payload,
      }
    case types.TOGGLE_DELETE_ADMIN_TOOL:
      return {
        ...state,
        isDeletingAdminTool: !!payload,
        adminToolOnDelete: payload,
      }

    case types.SEARCH_ADMIN_TOOL:
      return {
        ...state,
        searchQuery: payload,
        pagination: {
          ...state.pagination,
          currentPage: initialState.pagination.currentPage,
          offset: initialState.pagination.offset,
        }
      }

    case types.CHANGE_LIMIT:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: 1,
          pageLimit: payload,
        },
      }

    case types.CHANGE_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: payload,
        },
      }

    default:
      return state
  }
}
