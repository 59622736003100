import React from 'react'
import { Col, Row } from 'react-bootstrap'
import FormFiller from './Components/FormFiller'
import SectionNavigator from '../../Components/SectionNavigator/index'

const FormFillerContainer = () => {
  return <Row>
    <Col sm={ 2 }>
      <SectionNavigator/>
    </Col>
    <Col sm={ 10 }>
      <FormFiller/>
    </Col>
  </Row>
}

export default FormFillerContainer
