import {
  FETCH_RECENT_LISTS,
  RECENT_LISTS_FETCHED,
  RECENT_LISTS_FETCH_ERROR,
  CLEAR_RECENT_LISTS,
  FETCH_RECENT_VIEWED,
  RECENT_VIEWED_FETCHED,
  RECENT_VIEWED_FETCH_ERROR,
  CLEAR_RECENT_VIEWED,
  FETCH_LISTS,
  LISTS_FETCHED,
  CHANGE_LISTS_CURRENT_PAGE,
  CHANGE_LISTS_PAGE_SIZE,
  CHANGE_LISTS_SEARCH,
  LISTS_FETCH_ERROR,
  CLEAR_LISTS
} from './actionTypes'

export const fetchRecentListsAction = () => ({
  type: FETCH_RECENT_LISTS
})

export const recentListsFetchedAction = lists => ({
  type: RECENT_LISTS_FETCHED,
  payload: { lists }
})

export const recentListsFetchErrorAction = () => ({
  type: RECENT_LISTS_FETCH_ERROR
})

export const clearRecentListsAction = () => ({
  type: CLEAR_RECENT_LISTS
})

export const fetchRecentViewedaction = () => ({
  type: FETCH_RECENT_VIEWED
})

export const recentViewedFetchedAction = data => ({
  type: RECENT_VIEWED_FETCHED,
  payload: data
})

export const recentViewedFetchErrorAction = () => ({
  type: RECENT_VIEWED_FETCH_ERROR
})

export const clearRecentViewedAction = () => ({
  type: CLEAR_RECENT_VIEWED
})

export const fetchListsAction = data => ({
  type: FETCH_LISTS,
  payload: data
})

export const listsFetchedAction = (lists, pagination) => ({
  type: LISTS_FETCHED,
  payload: { lists, pagination }
})

export const changeListsCurrentPageAction = currentPage => ({
  type: CHANGE_LISTS_CURRENT_PAGE,
  payload: { currentPage }
})

export const changeListsPageSizeAction = pageLimit => ({
  type: CHANGE_LISTS_PAGE_SIZE,
  payload: { pageLimit }
})

export const changeListsSearchAction = query => ({
  type: CHANGE_LISTS_SEARCH,
  payload: { query }
})

export const listsFetchErrorAction = () => ({
  type: LISTS_FETCH_ERROR
})

export const clearListsAction = () => ({
  type: CLEAR_LISTS
})
