import { Action } from 'src/Services/Store/reducers'
import { User } from 'src/Types/User'
import Pagination, { SortItem, SortItemDirection } from 'src/Types/Pagination'
import * as types from './actionTypes'

export interface UserListState {
  loading: boolean
  users: User[]
  pagination: Pagination
  searchQuery: string | null
  sortItem: SortItem
}

export const initialState: UserListState = {
  loading: false,
  users: [],
  pagination: {
    offset: 0,
    limit: 10,
    itemsCount: 10,
    totalItemsCount: 10,
    pageLimit: 10,
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPagesCount: 1,
  },
  searchQuery: '',
  sortItem: {
    column: 'createdAt',
    direction: SortItemDirection.DESC
  }
}

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.ERROR_HANDLER:
      return {
        ...state,
        loading: false
      }
    case types.LOAD:
      return {
        ...state,
        loading: true
      }
    case types.RECEIVE:
      return {
        ...state,
        loading: false,
        users: payload.users,
        pagination: payload.pagination
      }
    case types.CHANGE_LIMIT:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          pageLimit: payload.limit,
          current: 1
        }
      }
    case types.CHANGE_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: payload.page
        }
      }
    case types.SEARCH_USER:
      return {
        ...state,
        loading: true,
        searchQuery: payload.query,
        sortItem: {
          column: payload.query ? state.sortItem.column : initialState.sortItem.column,
          direction: payload.query ? state.sortItem.direction : initialState.sortItem.direction
        },
        pagination: {
          ...state.pagination,
          currentPage: initialState.pagination.currentPage,
          offset: initialState.pagination.offset,
        }
      }
    case types.SORT_USERS:
      return {
        ...state,
        loading: true,
        sortItem: payload.sortItem
      }
    case types.USER_LOADED:
      return {
        ...state,
        loading: false,
        users: [ ...state.users, payload ]
      }
    case types.DELETE_USER:
      return {
        ...state,
        errorsDeletingUser: {}
      }
    case types.USER_DELETED:
      return {
        ...state,
        errorsDeletingUser: {}
      }
    default:
      return state
  }
}
