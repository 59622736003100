import { call, put, select, takeLatest } from 'redux-saga/effects'
import apiMethods from 'src/Services/api/apiMethods'
import { showSuccess } from 'src/Services/notifier/actions'
import { EMAIL_HTML_CONTENT_FILENAME } from 'src/Services/Constants/Config/EmailTemplate'
import { saveInstanceFieldValue } from 'src/Views/FormFiller/state/actions.ts'
import { getFullApiBaseUrl } from 'src/Services/Firewall/utils'
import { SAVE_HTML_DATA_FROM_SFDT } from './actionTypes'

function* saveHtmlExport(props, { payload: { instanceId, fieldId, value } }) {
  try {
    const state = yield select()

    const { data } = yield call(
      apiMethods.create,
      getFullApiBaseUrl(state.Root.config) + state.Root.config.SYNCFUSION_WORDPROCESSOR_SERVER_URL + 'ExportSFDT',
      { data: { content: value?.content || value, fileName: EMAIL_HTML_CONTENT_FILENAME } },
    )

    yield put(saveInstanceFieldValue(instanceId, fieldId, data))
    yield put(showSuccess('updateSucceeded'))

  } catch (error) {
    yield put(props.globalActions.handleError(error, 'updateFailed'))
  }
}


export default function* documentEditorSagaWatcher(props) {
  yield takeLatest(SAVE_HTML_DATA_FROM_SFDT, saveHtmlExport, props)
}
