export const SWP_SESSION_ID_FIELD_SYSTEM_NAME = 'SWP_SESSION_ID'
export const SWP_ACCESS_TOKENS_FIELD_SYSTEM_NAME = 'SWP_ACCESS_TOKENS'
export const SWP_OPEN_RECORD_FIELD_SYSTEM_NAME = 'SWP_OPEN_RECORD_BUTTON'
export const SWP_OPEN_PLAYBACK_FIELD_SYSTEM_NAME = 'SWP_OPEN_PLAYBACK_BUTTON'
export const SWP_OPEN_MONITOR_FIELD_SYSTEM_NAME = 'SWP_OPEN_MONITOR_BUTTON'
export const SWP_RECORDER_SERIAL_FIELD_SYSTEM_NAME = 'SWP_RECORDER_SERIAL_NUMBER'
export const SWP_START_RECORD_AT_FIELD_SYSTEM_NAME = 'SWP_START_RECORD_AT'
export const SWP_END_RECORD_AT_FIELD_SYSTEM_NAME = 'SWP_END_RECORD_AT'
export const SWP_RECORD_DURATION_FIELD_SYSTEM_NAME = 'SWP_RECORD_DURATION'
export const SWP_SESSION_STATUS_FIELD_SYSTEM_NAME = 'SWP_SESSION_STATUS'
