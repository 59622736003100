import styled from 'styled-components'

export const TargetBody = styled.span`
  display: flex;
  width: 100%;

  &.undroppable-target {
    pointer-events: none;
    cursor: no-drop;
  }
`
export const specificFormFieldStyle = props => `
  display: flex;
  height: 3em;
  max-height: 55px;
  position: relative;
`

export const commonFormFieldStyle = props => `
  background-color: ${props.theme.colors.white.median};
  border-radius: 8px;
  box-shadow: 0 0 4px 0 ${props.theme.colors.grey.alpha.light(0.18)};

  &.current-drag-and-drop-target {
    box-shadow: 0 0 5px 0 ${props.theme.colors.primary.alpha.light(0.58)};
  }

  &:hover {
    box-shadow: 0 10px 20px 0 ${props.theme.colors.grey.alpha.light(0.16)},
                0 0 8px 0 ${props.theme.colors.grey.alpha.dark(0.18)};

    .hover-controls {
      display: block;
    }
  }

  span.fa-stack {
    margin: auto 0.5em;

    .fa-stack-1x {
      color: ${props.theme.colors.secondary.median};
    }

    .fa-stack-2x {
      color: ${props.theme.colors.secondary.light};
    }
  }

  .hover-controls {
    background-color: ${props.theme.colors.grey.dark};
    border-radius: 1.5rem;
    display: none;
    position: absolute;
    right: -1px;
    top: -1rem;

    .icon {
      color: ${props.theme.colors.white.median};
      font-size: 0.875rem;
      height: 0.875rem;
      margin: 0.625rem 0;
      opacity: 0.6;
      width: 0.875rem;

      &:first-child {
        margin-left: 0.625rem;
      }

      &:last-child {
        margin-right: 0.625rem;
      }
    }

    .icon + .icon {
      margin-left: 0.625rem;
    }
  }
`

export const Name = styled.span`
  border-bottom: 1px solid ${props => props.theme.colors.secondary.median};
  padding-bottom: 0.3em;
  font-size: 0.875em;
  color: ${props => props.theme.colors.grey.darkest};
  flex-grow: 1;
  font-size: 0.875em;
  margin: auto 1rem auto 0;
`

export const FieldWrapper = styled.div`
  ${commonFormFieldStyle}
  ${specificFormFieldStyle}
  span.empty {
    border-color: ${props => props.theme.colors.grey.lightest};
    color: ${props => props.theme.colors.grey.median};
    font-style: italic;
  }
`
