export default {
  english: {
    title: 'Landing pages configurator',
    addNew: 'Add',
    entries: 'of {total} entries',
    form: {
      systemName: 'System name',
      label: 'Label',
      list: 'List',
      type: 'Type',
      size: 'Size',
      numericReport: 'Numeric report',
      editor: {
        addTabButton: 'Add tab',
        addRowButton: 'Add row',
        addColButton: 'Add column',
        addNewContent: 'Add content',
        editingTab: 'Editing tab',
        editingContent: 'Editing content'
      },
      content: {
        label: 'Label',
        type: 'Type',
        sizeRemaining: 'Space remaining',
      }
    },
    column: {
      systemName: 'System name',
      label: 'Label'
    },
    modal: {
      create: {
        title: 'Create a new landing page',
      },
      update: {
        title: 'Update a landing page',
      },
      confirmDelete: {
        title: 'Are you sure you want to delete this landing page ?'
      }
    }
  },
  french: {
    title: 'Configurateur de pages d\'accueil',
    addNew: 'Créer',
    entries: 'sur {total} entrées',
    form: {
      systemName: 'Nom system',
      label: 'Label',
      list: 'List',
      type: 'Type',
      size: 'Size',
      numericReport: 'Numeric report',
      nameMustBeUnique: 'Le nom doit être unique',
      editor: {
        addTabButton: 'Add tab',
        addRowButton: 'Add row',
        addColButton: 'Add column',
        addNewContent: 'Add content',
        editingTab: 'Editing tab',
        editingContent: 'Editing content'
      },
      content: {
        label: 'Label',
        type: 'Type',
        sizeRemaining: 'Espace restant',
      }
    },
    column: {
      systemName: 'Nom système',
      label: 'Label'
    },
    modal: {
      create: {
        title: 'Créer une nouvelle page d\'accueil'
      },
      update: {
        title: 'Modifier une page d\'accueil'
      },
      confirmDelete: {
        title: 'Êtes-vous sûr de vouloir supprimer cette page d\'accueil ?'
      }
    }
  }
}
