import { useDispatch, useSelector } from 'react-redux'
import { translate, translateConf } from 'src/Services/translation'
import React, { useState } from 'react'
import { Badge, Card, Col, Row } from 'react-bootstrap'
import { PermissionAccessLevel } from 'src/Types/Permission'
import { setNewSubjectData } from '../state/actions.ts'
import translation from '../translations'

const PermissionsModuleContent = () => {
  const dispatch = useDispatch()
  const { language } = useSelector(state => state.Root.user)
  const { newSubjectData, user } = useSelector(state => state.Permissions)
  const { configTranslations } = useSelector(state => state.Root)
  const { modules } = useSelector(state => state.Dictionary)

  const transConf = translateConf(configTranslations)
  const trans = translate(translation)(language)

  const [ draggingItem, setDraggingItem ] = useState(null)

  const onDragOverAccessLevel = e => e.preventDefault()
  const onAccessLevelDrop = accessLevel => () =>
      dispatch(setNewSubjectData(newSubjectData.some(_ => _.id === draggingItem.id)
          ? newSubjectData.map(_ => _.id === draggingItem.id ? { ..._, accessLevel } : _)
          : [ ...newSubjectData, { ...draggingItem, accessLevel } ]))

  const getSubjectDataBadge = data =>
      <Badge key={ data?.id }
             className={ `me-1 mb-1 px-2 py-2 ${ user ? '' : 'cursor-pointer' }` }
             draggable={ !user }
             onDragStart={ () => setDraggingItem(data) }
             onDragEnd={ () => setDraggingItem(null) }
             bg={ data?.module?.isActive ? 'primary' : 'danger' }
             title={ data?.module?.isActive ? '' : trans('moduleDeactivated') }
      >{ transConf('MODULE')(data?.module) }</Badge>

  const getModules = () => {
    // Vérifier si newSubjectData est défini et est un tableau
    if (!Array.isArray(newSubjectData)) {
      console.error('newSubjectData n\'est pas un tableau valide.')
      return []
    }

    return modules?.map(module => {

      const subjectData = newSubjectData.find(s => s.module.id === module.id)

      return {
        id: subjectData ? subjectData.id : `_${ module?.id }`,
        module,
        accessLevel: subjectData?.accessLevel || null
      }
    }) || []
  }

  const getSubjectDataByAccessLevel = targetAccessLevel =>
      getModules().sort(
          (a, b) => transConf('MODULE')(a.module).localeCompare(transConf('MODULE')(b.module)))
          .filter(({ accessLevel }) => targetAccessLevel === accessLevel)

  const getAccessLevelLabelByValue = value =>
      Object.keys(PermissionAccessLevel).find(key => PermissionAccessLevel[key] === value)

  return <>
    <Card className={ 'mt-3' } onDragOver={ onDragOverAccessLevel } onDrop={ onAccessLevelDrop(null) }>
      <Card.Body>
        { getSubjectDataByAccessLevel(null).length
            ? getSubjectDataByAccessLevel(null).map(getSubjectDataBadge) : trans('noMoreModule') }
      </Card.Body>
    </Card>
    <Row>
      { Object.values(PermissionAccessLevel)
          .filter(o => !isNaN(Number(o)) && o !== PermissionAccessLevel.READ && o !== PermissionAccessLevel.NONE)
          .map(accessLevel =>
              <Col key={ accessLevel }
                   onDragOver={ onDragOverAccessLevel }
                   onDrop={ onAccessLevelDrop(accessLevel) }
              >
                <Card className={ 'mt-3' }>
                  <Card.Header>{ trans(`accessLevels.${ getAccessLevelLabelByValue(accessLevel) }`) }</Card.Header>
                  { !!getSubjectDataByAccessLevel(accessLevel).length &&
                      <Card.Body className={ 'p-2' }>
                        { getSubjectDataByAccessLevel(accessLevel).map(getSubjectDataBadge) }
                      </Card.Body> }
                </Card>
              </Col>) }
    </Row>
  </>
}

export default PermissionsModuleContent
