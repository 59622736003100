
export default {
  english: {
    desktopAppDownloadData: 'Download data',
    desktopAppUploadData: 'Upload data',
    desktopAppViewOnline: 'View online',
    serverPath: 'Server path'
  },
  french: {
    desktopAppDownloadData: 'Télécharger fichiers',
    desktopAppUploadData: 'Envoyer fichiers',
    desktopAppViewOnline: 'Voir en ligne',
    serverPath: 'Chemin serveur'
  }
}
