export default {
  english: {
    valuePlaceholder: 'Value',
    referenceValuePlaceholder: 'Id of the reference',
  },
  french: {
    valuePlaceholder: 'Valeur',
    referenceValuePlaceholder: 'Id de la référence',
  }
}
