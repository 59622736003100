import React from 'react'
import { path } from 'ramda'
import { Button } from 'react-bootstrap'
import { deleteFormList, setFormToDelete } from '../state/actions'
import { translate } from 'src/Services/translation'
import { withState } from 'src/Components/Util'
import Modal from 'src/Components/Modal'
import translation from '../translations'

const onConfirm = (dispatch, formId) => () => {
  dispatch(deleteFormList(formId))
}

const onCancel = dispatch => () => {
  dispatch(setFormToDelete(''))
}

// propsFromState :: Map String (State -> a)
const propsFromState = {
  formToDelete: path([ 'FormList', 'formToDelete' ]),
  language: path([ 'Root', 'user', 'language' ])
}

const DeleteModal = ({
  formToDelete,
  language,
  dispatch
}) => {
  const trans = translate(translation)(language)

  return (
    <Modal isOpen={Boolean(formToDelete)}
      title={trans('modal.deleteForm.title')}
      onClose={onCancel(dispatch)}
    >
      <div className="d-flex justify-content-center">
        <Button variant="warning" className="me-2"
          onClick={onConfirm(dispatch, formToDelete)}
        >
          {trans('yes')}
        </Button>
        <Button className="button" onClick={onCancel(dispatch)}>
          {trans('no')}
        </Button>
      </div>
    </Modal>
  )
}

export default withState(propsFromState)(DeleteModal)
