import { ID_SEPARATOR } from 'src/Services/Constants'

export const parseFieldId = id => {
  if (!id || !id.includes(ID_SEPARATOR))
    return id

  const ids = id.split(ID_SEPARATOR)

  return ids[ids.length - 1]
}

export const parseReferenceId = id => {
  if (!id || !id.includes(ID_SEPARATOR))
    return id

  const ids = id.split(ID_SEPARATOR)
  ids.pop()

  return ids.join(ID_SEPARATOR)
}
