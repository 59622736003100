export const NAVIGATE_TO = 'NAVIGATE_TO'
export const LOCATION_CHANGED = 'LOCATION_CHANGED'
export const SET_APP_CONFIG = 'SET_APP_CONFIG'
export const SET_IS_API_READY = 'SET_IS_API_READY'

export const SET_AUTO_REFRESH = 'SET_AUTO_REFRESH'

export const GET_CURRENT_USER = 'GET_CURRENT_USER'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'

export const GET_ANNOUNCEMENTS = 'GET_ANNOUNCEMENTS'
export const SET_ANNOUNCEMENTS = 'SET_ANNOUNCEMENTS'

export const REQUEST_CONFIG_TRANSLATIONS_FETCH = 'REQUEST_CONFIG_TRANSLATIONS_FETCH'
export const GET_CONFIG_TRANSLATIONS = 'GET_CONFIG_TRANSLATIONS'
export const CONFIG_TRANSLATIONS_FETCHED = 'CONFIG_TRANSLATIONS_FETCHED'
export const SET_CONFIG_TRANSLATIONS = 'SET_CONFIG_TRANSLATIONS'

export const HANDLE_ERROR = 'HANDLE_ERROR'
export const LOG_MESSAGE = 'LOG_MESSAGE'

export const TOGGLE_OVERLAY_LOADER = 'TOGGLE_OVERLAY_LOADER'

export const SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED'
