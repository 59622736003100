import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import { useActions } from 'src/Components/Util'
import { translate } from 'src/Services/translation'
import { AppLoader } from 'src/Components/AppLoader'
import { setOptions } from 'src/Layouts/View/state/actions'
import SectionNavigator from 'src/Components/SectionNavigator/index'
import { setSections } from 'src/Components/SectionNavigator/state/actions'
import AuditTrailsModal from 'src/Components/AuditTrailsModal/index.tsx'
import SaveButton from 'src/Components/SaveButton'
import { AuditTrailLogFields } from 'src/Types/AuditTrailLog'
import { ColumnValueType, FilterOperator } from 'src/Types/RequestFilter'
import { SortItemDirection } from 'src/Types/Pagination'
import { hasUserModulePermission } from 'src/Utils/index.ts'
import { MODULE_AUDIT_TRAILS } from 'src/Services/Constants/Config/Modules'
import { PermissionAccessLevel } from 'src/Types/Permission'
import translation from './translations'
import Filters from './Components/Filters'
import ZmrEditColumns from './Components/ZmrEditColumns'
import ZmrEditInfos from './Components/ZmrEditInfos'
import { fetchZmr, setSaveStatus, fetchFilters, reset, saveZmr } from './state/actions'

const ListEdit = () => {
  const { id = '' } = useParams()
  const dispatch = useDispatch()

  const { user } = useSelector(state => state.Root)
  const { label, systemName, loading, form, refForm, saveStatus }
    = useSelector(state => state.ListEdit)

  const trans = translate(translation)(user.language)

  const [ isChangelogModalOpen, setIsChangelogModalOpen ] = useState(false)

  const changeLogFilter = React.useMemo(() => ({
    offset: 0,
    limit: 100,
    sortItems: [ { column: AuditTrailLogFields.TIMESTAMP, direction: SortItemDirection.DESC } ],
    filters: [
      {
        column: AuditTrailLogFields.CONTEXT,
        type: ColumnValueType.STRING,
        operator: FilterOperator.EQUAL,
        value: 'LIST'
      },
      {
        column: AuditTrailLogFields.CONTEXT_IDENTIFIER,
        type: ColumnValueType.STRING,
        operator: FilterOperator.EQUAL,
        value: id
      },
    ]
  }), [ id ])

  React.useEffect(() => {
    dispatch(setOptions({
      title: label,
      hasPrimaryButton: false
    }))
    dispatch(setSections([ trans('informations'), trans('columns'), trans('fixedFilters') ]))
  }, [ dispatch, label ])

  useActions([ fetchZmr(id), fetchFilters(id) ])([ setSaveStatus('') ])([])

  // Component unmount
  useEffect(() => () => dispatch(reset()), [])

  const onChangelogClick = e => setIsChangelogModalOpen(true)
  const onModalChangelogClose = () => setIsChangelogModalOpen(false)
  const onSubmit = e => dispatch(saveZmr())

  if (loading)
    return <AppLoader/>

  return <Container className={ 'mb-3' }>
    <Row>
      <Col sm={ 2 }>
        <SectionNavigator/>
      </Col>
      <Col sm={ 10 }>
        <Row className={ 'justify-content-evenly' }>
          <Col sm={ 10 }>
            <small className={ 'text-muted font-size' }>
              { trans('basedOff') } : { form.label }
              { refForm ? ` | ${ trans('referencing') } : ${ refForm.label }` : '' }
              <br/>
              { trans('systemName') } : { systemName }
            </small>
          </Col>
          <Col sm={ 2 } className={ 'd-flex justify-content-end' }>
            <div>
              { hasUserModulePermission(user, MODULE_AUDIT_TRAILS, PermissionAccessLevel.READ) &&
                <Button onClick={ onChangelogClick } variant={ 'outline-primary' } size={ 'sm' } className={ 'me-1' }>
                  <i className={ 'fas fa-history' }/>
                </Button> }
              <SaveButton onClick={ onSubmit } saveStatus={ saveStatus }/>
            </div>
          </Col>
        </Row>
        <Card data-is={ 'section' } className={ 'mt-2' }>
          <Card.Header>{ trans('informations') }</Card.Header>
          <Card.Body><ZmrEditInfos/></Card.Body>
        </Card>
        <Card data-is={ 'section' } className={ 'mt-2' }>
          <Card.Header>{ trans('columns') }</Card.Header>
          <Card.Body><ZmrEditColumns/></Card.Body>
        </Card>
        <Card data-is={ 'section' } className={ 'mt-2' }>
          <Card.Header>{ trans('fixedFilters') }</Card.Header>
          <Card.Body><Filters/></Card.Body>
        </Card>
      </Col>
    </Row>

    <AuditTrailsModal isOpen={ isChangelogModalOpen }
                      onClose={ onModalChangelogClose }
                      filter={ changeLogFilter }
    />
  </Container>
}

export default ListEdit
