import React from 'react'
import { useDispatch } from 'react-redux'
import { FormControl } from 'react-bootstrap'
import { FieldEvent } from 'src/Views/FormFiller/Types/Field'
import { fieldEvent } from '../../state/actions'

const EmailField = ({
  field = null,
  id = null,
  value,
  disabled = false,
  isRequired = false,
}) => {
  const dispatch = useDispatch()

  const [ changeTimeout, setChangeTimeout ] = React.useState(null)
  const [ localValue, setLocalValue ] = React.useState(value)

  React.useEffect(() => {
    setLocalValue(value)
  }, [ value ])

  const onChange = e => {
    setLocalValue(e.target.value)
    clearTimeout(changeTimeout)
    setChangeTimeout(setTimeout(() => {
      dispatch(fieldEvent(field, FieldEvent.VALUE_CHANGE, { value: e.target.value }))
    }, 500))
  }

  return <>
    <FormControl id={ `field${ id }` }
                      type="email"
                      value={ localValue || '' }
                      onChange={ onChange }
                      onKeyDown={ e => {
                        e.target.reportValidity()
                      } }
                      size="sm"
                      disabled={ disabled }
                      required={ isRequired }
  />
    <FormControl type={'hidden'} value="" required />
    </>
}

export default EmailField
