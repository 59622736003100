import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Form } from 'react-bootstrap'
import { translate } from 'src/Services/translation'
import translation from '../translations'
import { setRole, setSubject } from '../state/actions.ts'
import { PermissionSubject } from 'src/Views/Permissions/Types/Subject'

const PermissionsMenu = () => {
  const dispatch = useDispatch()
  const { language } = useSelector(state => state.Root.user)
  const { roles } = useSelector(state => state.Dictionary)
  const { role, user, subject, subjects } = useSelector(state => state.Permissions)
  const trans = translate(translation)(language)

  const onRoleChange = e => dispatch(setRole(roles.find(r => r.id == e.target.value)))
  const onSubjectChange = e => dispatch(setSubject(e.target.value))

  return <Card>
    <Card.Body>
      <h4 className="d-flex align-items-center mb-0 flex-column flex-md-row" style={ { fontSize: '1.5rem' } }>
        { trans('changePermissionsFor') }
        { !user ? <Form.Select value={ role?.id || '' }
                               onChange={ onRoleChange }
                               className="mx-2"
                               placeholder={ trans('role') }
                               style={ { height: '37px', width: 'auto' } }
                               size="sm"
          >
            <option>-</option>
            { roles.map(role => <option key={ role.id } value={ role.id }>{ role.name }</option>) }
          </Form.Select>
          : <span className="fw-bold text-primary mx-2">{ user.username }</span> }
        { trans('regarding') }
        <Form.Select value={ subject || '' }
                     onChange={ onSubjectChange }
                     className="mx-2"
                     placeholder={ trans('subject') }
                     style={ { height: '37px', width: 'auto' } }
                     size="sm"
                     disabled={!role?.id && !user?.id}
        >
          <option>-</option>
          { subjects
            .filter(s => ![PermissionSubject.SECTION, PermissionSubject.FIELD].includes(s))
            .map(s => <option key={ s } value={ s }>{ trans(`permission.${ s }`) }</option>) }
        </Form.Select>
      </h4>
      { role === 'ADMIN' && <small className="text-muted">{ trans('adminHaveAllRights') }</small> }
    </Card.Body>
  </Card>
}

export default PermissionsMenu
