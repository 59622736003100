import {
  CHANGE_LIMIT,
  CHANGE_PAGE,
  FETCH_ADMIN_TOOLS,
  RECEIVE_ADMIN_TOOLS,
  SEARCH_ADMIN_TOOL,
  ADMIN_TOOL_ADDED,
  ADMIN_TOOL_UPDATED,
  ADMIN_TOOL_DELETED,
  ADD_ADMIN_TOOL,
  UPDATE_ADMIN_TOOL,
  DELETE_ADMIN_TOOL,
  TOGGLE_ADD_ADMIN_TOOL,
  TOGGLE_UPDATE_TOOL,
  TOGGLE_DELETE_ADMIN_TOOL
} from './actionTypes'
import { AdminTool } from 'src/Types/AdminTool'

export const fetchAdminTools = () => ({
  type: FETCH_ADMIN_TOOLS
})

export const changeLimit = (data: number | null) => ({
  type: CHANGE_LIMIT,
  payload: data
})

export const changePage = (page: number | null) => ({
  type: CHANGE_PAGE,
  payload: page
})

export const searchAdminTool = (data: string) => ({
  type: SEARCH_ADMIN_TOOL,
  payload: data
})

export const receiveAdminTools = (data: object) => ({
  type: RECEIVE_ADMIN_TOOLS,
  payload: data
})

export const addAdminTool = (data: AdminTool) => ({
  type: ADD_ADMIN_TOOL,
  payload: data
})

export const adminToolAdded = (data: object) => ({
  type: ADMIN_TOOL_ADDED,
  payload: data
})

export const toggleAddAdminTool = () => ({
  type: TOGGLE_ADD_ADMIN_TOOL
})

export const updateAdminTool = (data: AdminTool) => ({
  type: UPDATE_ADMIN_TOOL,
  payload: data
})

export const adminToolUpdated = (data: object) => ({
  type: ADMIN_TOOL_UPDATED,
  payload: data
})

export const toggleUpdateAdminTool = (adminTool: AdminTool) => ({
  type: TOGGLE_UPDATE_TOOL,
  payload: adminTool
})

export const deleteAdminTool = () => ({
  type: DELETE_ADMIN_TOOL
})

export const adminToolDeleted = (id: string) => ({
  type: ADMIN_TOOL_DELETED,
  payload: id
})

export const toggleDeleteAdminTool = (adminTool: AdminTool) => ({
  type: TOGGLE_DELETE_ADMIN_TOOL,
  payload: adminTool
})
