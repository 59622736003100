import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
  FETCH_ITEMS, FETCH_ITEM,
  CREATE_ITEM,
  DELETE_ITEM,
  UPDATE_ITEM
} from './actionTypes'
import {
  itemUpdated, itemDeleted, itemCreated, itemsReceived, itemReceived
} from './actions'
import apiMethods from '../../../Services/api/apiMethods'
import { contentRangeToPagination } from 'src/Utils/EffectsUtil'
import { GlobalActions } from 'src/Types/GlobalActions'
import { Action } from 'src/Services/Store/reducers'
import { EventCancellationState, EventCancellationStoreState } from './reducer'

const STORE = 'EventCancellationReasons'

function* updateItem(props: GlobalActions, { payload }: Action) {
  try {
    const state = (yield select()) as EventCancellationStoreState
    const item = state.EventCancellationReasons.updatingItem

    delete item.systemName
    delete item.isSystem
    delete item.id

    const { data } = yield call(apiMethods.update, `/event_cancellation_reasons/${payload.id}`, { data: item })
    yield put(itemUpdated(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'updateFailed'))
  }
}

function* deleteItem(props: GlobalActions, { payload }:Action) {
  try {
    const state = (yield select()) as EventCancellationStoreState
    const item = state.EventCancellationReasons.deletingItem
    yield call(apiMethods.delete, `/event_cancellation_reasons/${item.id}`)
    yield put(itemDeleted(item.id))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'deleteFailed'))
  }
}

function* createItem(props: GlobalActions, { payload }:Action) {
  try {
    const state = (yield select()) as EventCancellationStoreState
    const item = state.EventCancellationReasons.creatingItem
    const { data } = yield call(apiMethods.create, '/event_cancellation_reasons', { data: item })
    yield put(itemCreated(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'createFailed'))
  }
}

function* fetchItems(props: GlobalActions, { payload }:Action) {
  try {
    const state = (yield select()) as EventCancellationStoreState
    const { query, pagination } = state.EventCancellationReasons

    const { data, headers } = yield call(apiMethods.get, '/event_cancellation_reasons', {
      query, ...pagination
    })
    yield put(itemsReceived({
      items: data,
      pagination: contentRangeToPagination(headers['content-range'], pagination)
    }))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'loadListFailed'))
  }
}

function* fetchItem(props: GlobalActions, { payload }: Action) {
  try {
    const { data } = yield call(apiMethods.get, `/event_cancellation_reasons/${payload}`)
    yield put(itemReceived(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'fetchFailed'))
  }
}

export default function* eventCancellationReasonsSagaWatcher(props: GlobalActions) {
  yield takeLatest(FETCH_ITEMS, fetchItems, props)
  yield takeLatest(FETCH_ITEM, fetchItem, props)
  yield takeLatest(CREATE_ITEM, createItem, props)
  yield takeLatest(DELETE_ITEM, deleteItem, props)
  yield takeLatest(UPDATE_ITEM, updateItem, props)
}
