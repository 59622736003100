import { EventReason, EventStatus, EventType } from 'src/Types/Event'
import { SlotType } from 'src/Types/Slot'
import { Iso8601 } from 'src/Types/Date'
import { Instance } from 'src/Types/Instance'
import Icon from 'src/Types/Icon'
import { HexColor } from 'src/Types/Color'
import { UuidV4 } from 'src/Types/Uuid'
import { User } from 'src/Types/User'
import { DurationInput } from '@fullcalendar/core'

/** A calendar event can be Event or Slot and is design for FullCalendar */
interface CalendarEvent {
  id: UuidV4
  title: string
  // Date dedicated for FullCalendar, may not be accurate
  start: Iso8601
  // Time dedicated for FullCalendar, when we are not sure of the date yet
  startTime?: DurationInput
  duration?: DurationInput
  // Date dedicated for FUllCalendar, may not be accurate
  end: Iso8601
  allDay: boolean
  extendedProps: CalendarEventExtended
}

export interface CalendarEventExtended {
  id: UuidV4
  start: Iso8601 | null
  end: Iso8601 | null
  duration: number
  note: string | null
  type: CalendarEventType
  color: HexColor
  status: EventStatus | null
  event?: CalendarEvent | null
  eventType?: EventType | null
  slotType?: SlotType | null
  instance?: Instance | null
  resource?: Instance | null
  location: Instance | null
  patient?: Instance | null
  reason?: EventReason | null
  resources?: Instance[] | null
  icons?: Icon[]
  isAvailability?: boolean
  additionalData?: CalendarAdditionalDatum[]
  createdAt?: Iso8601 | null
  createdBy?: User | null
}

export interface CalendarAdditionalDatum {
  id: string
  label: string | null
  systemName: string
  value: any
}

export enum CalendarEventType {
  EVENT = 'EVENT',
  SLOT = 'SLOT',
}

export default CalendarEvent
