export const FETCH_ITEMS = '[TASKS_TYPES] FETCH_ITEMS'
export const FETCH_ITEM = '[TASKS_TYPES] FETCH_ITEM'
export const CREATE_ITEM = '[TASKS_TYPES] CREATE_ITEM'
export const DELETE_ITEM = '[TASKS_TYPES] DELETE_ITEM'
export const UPDATE_ITEM = '[TASKS_TYPES] UPDATE_ITEM'

export const ITEMS_RECEIVED = '[TASKS_TYPES] ITEMS_RECEIVED'
export const ITEM_RECEIVED = '[TASKS_TYPES] ITEM_RECEIVED'
export const ITEM_CREATED = '[TASKS_TYPES] ITEM_CREATED'
export const ITEM_DELETED = '[TASKS_TYPES] ITEM_DELETED'
export const ITEM_UPDATED = '[TASKS_TYPES] ITEM_UPDATED'

export const SET_DELETING_ITEM = '[TASKS_TYPES] SET_DELETING_ITEM'
export const SET_UPDATING_ITEM = '[TASKS_TYPES] SET_UPDATING_ITEM'
export const SET_CREATING_ITEM = '[TASKS_TYPES] SET_CREATING_ITEM'

export const SET_QUERY = '[TASKS_TYPES] SET_QUERY'
export const SET_LIMIT = '[TASKS_TYPES] SET_LIMIT'
export const SET_PAGE = '[TASKS_TYPES] SET_PAGE'
