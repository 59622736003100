import {
  FETCH_DELETED_INSTANCES,
  RECIEVE_DELETED_INSTANCES,
  CHANGE_PAGE,
  CHANGE_LIMIT,
  FETCH_RESTORE_INSTANCE,
  SEARCH_DELETED_INSTANCE
} from './actionTypes'
export const receiveDeletedInstances = (data) => ({
  type: RECIEVE_DELETED_INSTANCES,
  payload: data
})
export const fetchDeletedInstances = (data) => ({
  type: FETCH_DELETED_INSTANCES,
  payload: data
})
export const fetchRestoreInstance= (data) => ({
  type: FETCH_RESTORE_INSTANCE,
  payload: data
})
export const changePage = (data) => ({
  type: CHANGE_PAGE,
  payload: data
})
export const searchDeletedInstance = (data) => ({
  type: SEARCH_DELETED_INSTANCE,
  payload: data
})
export const changeLimitRestoreDeletedInstances = (data) => ({
  type: CHANGE_LIMIT,
  payload: data
})