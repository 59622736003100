import * as types from './actionTypes'
import { Action } from 'src/Services/Store/reducers'
import { AuditTrailLog, AuditTrailLogFields } from 'src/Types/AuditTrailLog'
import { Filter } from 'src/Types/RequestFilter'
import { SortItemDirection, SortItem } from 'src/Types/Pagination'

export interface AuditTrailPagination {
  offset: number
  limit: number
}

export interface AuditTrailPaginationDetails {
  totalItemsCount: number
  itemsCount: number
}

export interface AuditTrailsState {
  rows: AuditTrailLog[],
  isLoading: boolean,
  pagination: AuditTrailPagination,
  paginationDetails: AuditTrailPaginationDetails,
  sortItems: SortItem[],
  filters: Filter[]
}

const initialState: AuditTrailsState = {
  rows: [],
  isLoading: false,
  pagination: {
    offset: 0,
    limit: 10,
  },
  paginationDetails: {
    totalItemsCount: 0,
    itemsCount: 0,
  },
  sortItems: [
    {
      column: AuditTrailLogFields.TIMESTAMP,
      direction: SortItemDirection.DESC,
    },
  ],
  filters: [],
}

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.FETCH_DATA:
      return {
        ...state,
        isLoading: true,
      }
    case types.SET_DATA:
      return {
        ...state,
        rows: payload.rows,
        paginationDetails: {
          itemsCount: payload.paginationDetails.itemsCount,
          totalItemsCount: payload.paginationDetails.totalItemsCount,
        },
      }
    case types.PAGINATE:
      return {
        ...state,
        pagination: {
          offset: payload.offset,
          limit: payload.limit,
        },
      }
    case types.SORT:
      return {
        ...state,
        sortItems: payload.sortItems,
      }
    case types.FILTER:
      return {
        ...state,
        filters: payload.filters,
      }
    case types.SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload.isLoading,
      }
    default:
      return state
  }
}
