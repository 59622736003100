import React from 'react'
import { translate } from 'src/Services/translation'
import translation from './translations'
import styled from 'styled-components'
import { setOptions } from 'src/Layouts/View/state/actions'
import { useDispatch, useSelector } from 'react-redux'

const Page404 = () => {
  const dispatch = useDispatch()

  const { language } = useSelector(state => state.Root.user)

  const trans = translate(translation)(language)

  React.useEffect(() => {
    dispatch(setOptions({
      title: trans('title'),
      hasPrimaryButton: false
    }))
  }, [ dispatch ])

  return (
    <Section>
      <div className="text-404">404</div>
      <h2>What are you doing here?</h2>
      <p>Did you make a left at that last URL instead of a right? No problem. Unless you want us to deal you in, here
        are some tips to get you back on your way:</p>
      <ul>
        <li>If you typed in the address, check your spelling. Could just be a typo.</li>
        <li>If you followed a link, it’s probably broken. Please contact us and we’ll fix it</li>
        <li>If you’re not sure what you’re looking for, start <a href="/">Here</a>.</li>
      </ul>
    </Section>
  )
}

export default Page404

const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .text-404{
    font-size:54px;
  }
  h2{
    font-size:32px;
  }
`
