import React  from 'react'
import {
  setDeletingItem, deleteItem, setUpdatingItem, updateItem, setCreatingItem, createItem, fetchItems
} from './state/actions'
import { translate } from 'src/Services/translation'
import translation from './translations'
import { useDispatch, useSelector } from 'react-redux'
import Table from './Components/Table'
import Form from './Components/Form'
import Modal from '../../Components/Modal'
import ConfirmModal from '../../Components/ConfirmModal'
import { StoreState } from 'src/Services/Store/reducers'
import { useEffect } from 'react'
import { setOptions } from 'src/Layouts/View/state/actions'
import { Container } from 'react-bootstrap'
import { EventCancellationStoreState } from 'src/Views/EventCancellationReasons/state/reducer'
import { EventCancellationReason } from '../../Types/EventCancellationReason'

const EventCancellationReasons = () => {

  const dispatch = useDispatch()

  const { language } = useSelector((state: StoreState) => state.Root.user)
  const trans = translate(translation)(language)

  useEffect(() => {
    dispatch(setOptions({
      hasPrimaryButton: true,
      onPrimaryButtonClick: onCreateClick
    }))
    dispatch(fetchItems())
  }, [])

  const { creatingItem, updatingItem, deletingItem  } = useSelector(
      (state: EventCancellationStoreState) => state.EventCancellationReasons
  )

  const onCreateClick = () => dispatch(setCreatingItem({ id: null, label: null, systemName: '' }))
  const onModalCreateClose = () => dispatch(setCreatingItem(null))
  const onModalCreateFormSubmit = (data:EventCancellationReason) => {
    dispatch(setCreatingItem(data))
    dispatch(createItem())
  }

  const onUpdateClick = (item:EventCancellationReason) => dispatch(setUpdatingItem(item))
  const onModalUpdateClose = () => dispatch(setUpdatingItem(null))
  const onModalUpdateFormSubmit = (data:EventCancellationReason) => {
    dispatch(setUpdatingItem(data))
    dispatch(updateItem(data.id))
  }

  const onDeleteClick = (item:EventCancellationReason) => dispatch(setDeletingItem(item))
  const onConfirmDeleteModalClose = () => dispatch(setDeletingItem(null))
  const onConfirmDeleteModalConfirm = () => dispatch(deleteItem())

  return (
    <Container>
      <Table onDeleteClick={onDeleteClick} onUpdateClick={onUpdateClick} />
      <Modal isOpen={!!creatingItem} title={trans('modal.create.title')} onClose={onModalCreateClose} size={'md'}>
        <Form onSubmit={onModalCreateFormSubmit} />
      </Modal>
      <Modal isOpen={!!updatingItem} title={trans('modal.update.title')} onClose={onModalUpdateClose} size={'md'}>
        <Form onSubmit={onModalUpdateFormSubmit} item={updatingItem} />
      </Modal>
      <ConfirmModal title={trans('modal.confirmDelete.title')}
                    isOpen={!!deletingItem}
                    onClose={onConfirmDeleteModalClose}
                    onConfirm={onConfirmDeleteModalConfirm}
      />
    </Container>
  )
}

export default EventCancellationReasons
