export const FETCH_ITEMS = '[MODULES] FETCH_ITEMS'
export const FETCH_ITEM = '[MODULES] FETCH_ITEM'
export const CREATE_ITEM = '[MODULES] CREATE_ITEM'
export const DELETE_ITEM = '[MODULES] DELETE_ITEM'
export const UPDATE_ITEM = '[MODULES] UPDATE_ITEM'

export const ITEMS_RECEIVED = '[MODULES] ITEMS_RECEIVED'
export const ITEM_RECEIVED = '[MODULES] ITEM_RECEIVED'
export const ITEM_CREATED = '[MODULES] ITEM_CREATED'
export const ITEM_DELETED = '[MODULES] ITEM_DELETED'
export const ITEM_UPDATED = '[MODULES] ITEM_UPDATED'

export const SET_DELETING_ITEM = '[MODULES] SET_DELETING_ITEM'
export const SET_UPDATING_ITEM = '[MODULES] SET_UPDATING_ITEM'
export const SET_CREATING_ITEM = '[MODULES] SET_CREATING_ITEM'

export const SET_QUERY = '[MODULES] SET_QUERY'
export const SET_LIMIT = '[MODULES] SET_LIMIT'
export const SET_PAGE = '[MODULES] SET_PAGE'
