import React from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { Form } from 'react-bootstrap'
import LocalizedDatepicker from 'src/Components/LocalizedDatepicker'
import { ColumnValueType, NextFilterOperator, OptionFilterOperator } from 'src/Types/RequestFilter'
import { DateFilterOperator, NumberFilterOperator, StringFilterOperator } from 'src/Types/RequestFilter'
import { translate } from 'src/Services/translation'
import translations from '../translations'

const ColumnFilter = ({ column, filter, onChange, onRemove, filterIndex }) => {
  const { language } = useSelector(state => state.Root.user)

  const trans = translate(translations)(language)

  const getOperators = () => {
    switch (column.columnDef.valueType) {
      case ColumnValueType.DATE:
        return Object.values(DateFilterOperator)
      case ColumnValueType.NUMBER:
        return Object.values(NumberFilterOperator)
      case ColumnValueType.OPTION:
        return Object.values(OptionFilterOperator)
      default:
        return Object.values(StringFilterOperator)
    }
  }

  const getDateTimePickerProps = () => ({
    showTimeOnly: column.columnDef.showTimeOnly,
    showDateOnly: column.columnDef.showDateOnly,
  })

  const getValueInput = () => {
    switch (column.columnDef.valueType) {
      case ColumnValueType.DATE:
        return <LocalizedDatepicker selected={ filter.value }
                                    onChange={ val => onChange(filterIndex, 'value', val) }
                                    placeholder={ trans('columnFilterValuePlaceholder') }
                                    id={ `datePicker${ column.columnDef.id }` }
                                    portalId={ 'root' }
                                    isClearable
                                    { ...getDateTimePickerProps() }
        />
      case ColumnValueType.OPTION:
        return <Select size={ 'sm' }
                       id={ `datePicker${ column.columnDef.id }` }
                       name={ `datePicker${ column.columnDef.id }` }
                       onChange={ val => onChange(filterIndex, 'value', val?.value) }
                       value={ filter.value
                         ? ({
                           value: filter.value,
                           label: column.columnDef.options
                             ?.find(fieldOption => fieldOption?.value === filter.value)?.label
                         }) : null }
                       placeholder={ trans('columnFilterValuePlaceholder') }
                       options={ column.columnDef.options || [] }
                       isClearable
        />
      default:
        return <Form.Control size={ 'sm' }
                             placeholder={ trans('columnFilterValuePlaceholder') }
                             type={ column.columnDef.valueType === ColumnValueType.NUMBER ? 'number' : 'text' }
                             onChange={ e => onChange(filterIndex, 'value', e.target.value) }
                             value={ filter.value }
        />
    }
  }

  return <div className={ 'filter-row' }>
    { !!filterIndex && <Form.Select className={ 'next-filter-select' }
                                     size={ 'sm' }
                                     value={ filter.filterOperator || '' }
                                     onChange={ e => onChange(filterIndex, 'nextFilterOperator', e.target.value) }>
      { Object.values(NextFilterOperator).map(operator =>
        <option key={ operator } value={ operator }>{ operator }</option>) }
    </Form.Select> }

    <Form.Select size={ 'sm' } value={ filter.operator }
                 onChange={ e => onChange(filterIndex, 'operator', e.target.value) }>
      { getOperators().map(operator =>
        <option key={ operator } value={ operator }>{ operator }</option>) }
    </Form.Select>

      <div className={ 'd-flex justify-content-between' }>
        { getValueInput() }

        <i className={ 'ms-2 bi bi-trash3 cursor-pointer remove-filter' } onClick={ () => onRemove(filterIndex) } />
      </div>
  </div>
}

export default ColumnFilter
