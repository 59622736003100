import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { StoreState } from 'src/Services/Store/reducers'

const Badge = styled.span`
  padding: 1px 15px 2px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
  line-height: 14px;
  color: #fff;
  margin-right: 10px;
`

const Icon = styled.i`
  color: #fff;
  font-size: 11px;
`

const SlotTypesList = () => {
  const { slotTypes } = useSelector((state: StoreState) => state.Dictionary)

  return <>
    { slotTypes.map(slotType => (
        <div style={{ marginBottom: '2px' }} key={slotType.systemName}>
          <>
            <Badge style={{ backgroundColor: slotType.color }}>
              <Icon className="fas fa-pencil-alt" />
            </Badge>
            {slotType.label || slotType.systemName}
          </>
        </div>
    )) }
  </>
}

export default SlotTypesList
