import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Card, Col, FormControl, FormSelect, Row } from 'react-bootstrap'
import { translate, translateConf } from 'src/Services/translation'
import { filter } from 'src/Components/ListResultTable/state/actions'
import { setOptions } from 'src/Layouts/View/state/actions'
import Modal from 'src/Components/Modal'
import { hasUserModulePermission } from 'src/Utils/index.ts'
import { MODULE_LISTS } from 'src/Services/Constants/Config/Modules'
import ListResultTable from 'src/Components/ListResultTable/index'
import { PermissionAccessLevel } from 'src/Types/Permission'
import translation from './translations'
import BulkImport from './Components/BulkImport'
import {
  cancelImport, fetchListSearchesAction, postListSearchAction,
  deleteListSearchAction
} from './state/actions'
import { addInstance } from '../Patient/state/actions'

const ListRun = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const tableComponent = useRef()

  const { configTranslations, user } = useSelector(state => state.Root)
  const { importRequested, searches, isExportInProgress } = useSelector(state => state.ListRun)
  const { tables } = useSelector(state => state.ListResultTables)

  const [ newSearchTitle, setNewSearchTitle ] = useState('')
  const [ addingListSearch, setAddingListSearch ] = useState(false)
  const [ showListSearchManager, setShowListSearchManager ] = useState(false)

  const trans = translate(translation)(user.language)
  const transConf = translateConf(configTranslations)

  const searchSelect = useRef()

  const getList = () => tables.find(t => t.list?.id === id)?.list || null

  //dispatch(resetFilters(gridName))
  const dispatchResetFilters = () => null

  const renderLeftHeaderTemplate = () => <Row>
    { /*<Col md="auto">
      <Button onClick={ dispatchResetFilters } disabled>
        <i className="me-1 fas fa-redo-alt"/>
        <span>{ trans('reset') }</span>
      </Button>
    </Col>*/ }
    <Col md={ 'auto' } className={ 'd-flex p-1 bg-info bg-opacity-10 rounded-2' }>
      <FormSelect className={ 'w-auto me-1' } ref={ searchSelect } onChange={ useSavedSearch }>
        <option value={ '' }>{ trans('search.useSavedSearch') }</option>
        { Array.isArray(searches) &&
          searches.map((el, i) => <option key={ i } value={ JSON.stringify(el.filters) }>{ el.name }</option>) }
      </FormSelect>
      <Button className={ 'me-1' } onClick={ () => setShowListSearchManager(true) }>
        <i className={ 'fas fa-cog' }/>
      </Button>
      <Button onClick={ () => setAddingListSearch(true) }><i className={ 'me-1 fas fa-save' }/></Button>
    </Col>
    { /* isSelectedRow && (
      <Col>
        <Button disabled>
          <i className="me-1 fas fa-trash"/>
          <span>{ trans('delete') }</span>
        </Button>
      </Col>
    )*/ }
  </Row>

  const renderRightHeaderTemplate = () => <>
    <Button className={ 'me-1' }
            variant={ 'outline-primary' }
            disabled={ isExportInProgress }
            onClick={ () => tableComponent.current.requestExport() }
    >
      <i className={ 'fas fa-file-export' } title={ 'CSV Export' } />
    </Button>
    { /*<Button className={ 'me-1' } variant="outline-primary" onClick={e => dispatch(requestImport())}>
      <i className="fas fa-file-import" title={'CSV import'}/>
    </Button>*/ }

    { hasUserModulePermission(user, MODULE_LISTS, PermissionAccessLevel.UPDATE) &&
      <Button className={ 'me-1' } variant={ 'outline-primary' } onClick={ () => navigate(`/list/${ id }`) }>
        <i className={ 'fas fa-pen' }/>
      </Button> }
  </>

  const useSavedSearch = el => {
    if (el.target.value.length > 0)
      dispatch(filter(`list${ id }`, JSON.parse(el.target.value)))
    else
      dispatchResetFilters()
  }

  const dispatchDeleteSearch = searchId => {
    dispatch(deleteListSearchAction(id, searchId))
    // setShowListSearchManager(false)
  }

  const dispatchSaveSearch = () => {
    const table = tables.find(t => t.id === `list${ id }`)
    const filters = table.filters

    if (filters && newSearchTitle.length > 0) {
      dispatch(postListSearchAction(id, filters, newSearchTitle))
      setNewSearchTitle('')
      searchSelect.current.value = ''
      setAddingListSearch(false)
    }
  }

  useEffect(() => {
    dispatch(setOptions({
      title: transConf('LIST')(getList()),
      hasPrimaryButton: getList()?.showAddButton || false,
      onPrimaryButtonClick: () => dispatch(addInstance('', getList()?.form?.id, navigate)),
      rightHeaderTemplate: renderRightHeaderTemplate(),
      leftHeaderTemplate: renderLeftHeaderTemplate()
    }))
  }, [ dispatch, getList(), searches, newSearchTitle, addingListSearch, tableComponent ])

  useEffect(() => {
    dispatch(fetchListSearchesAction(id))
  }, [ dispatch, id ])

  return <Card>
    <Card.Body>
      <ListResultTable ref={ tableComponent } id={ `list${ id }` } listId={ id } />
    </Card.Body>
    <Modal size={ 'md' }
           isOpen={ importRequested }
           title={ trans('bulkImport.title') }
           onClose={ () => dispatch(cancelImport()) }>
      <BulkImport trans={ trans }/>
    </Modal>

    <Modal title={ trans('search.manageSearch') }
           isOpen={ showListSearchManager }
           onClose={ () => setShowListSearchManager(false) }
    >
      { searches.map((e, i) =>
        <Row className={ 'mb-2' } key={ i }>
          <Col>
            { e.name }
          </Col>
          <Col md={ 'auto' } className={ 'me-3' }>
            <Button variant={ 'danger' } onClick={ () => dispatchDeleteSearch(e.id) }><i className={ 'fas fa-trash' }/></Button>
          </Col>
        </Row>
      ) }
    </Modal>

    <Modal title={ trans('search.newSearch') }
           isOpen={ addingListSearch }
           onClose={ () => setAddingListSearch(false) }
    >
      <Row className={ 'mb-2' }>
        <Col>
          <FormControl placeholder={ trans('search.searchTitle') }
                       onChange={ e => setNewSearchTitle(e.target.value) }/>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button className={ 'd-flex justify-content-end' } onClick={ dispatchSaveSearch }>
            { trans('submit') }
          </Button>
        </Col>
      </Row>
    </Modal>
  </Card>
}

export default ListRun
