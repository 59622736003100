import { SUBSCRIBE_EVENT_SOURCE, CLOSE_EVENT_SOURCE } from './actionTypes'

export const initialState = {
  eventSourceTopicWaitingForSubscribe: null,
  eventSourceTopicWaitingForClose: null,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SUBSCRIBE_EVENT_SOURCE:
      return {
        ...state,
        eventSourceTopicWaitingForSubscribe: payload.topic || null,
      }
    case CLOSE_EVENT_SOURCE:
      return {
        ...state,
        eventSourceTopicWaitingForClose: payload.topic || null,
      }
    default:
      return state
  }
}
