import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { translate } from 'src/Services/translation'
import Modal from 'src/Components/Modal'
import ConfirmModal from 'src/Components/ConfirmModal'
import translation from './translations'
import Form from './Components/Form'
import {
  setDeletingItem, deleteItem, setUpdatingItem, updateItem, toggleCreateWorkflowModal, createItem
} from './state/actions'

const WorkflowModals = () => {
  const dispatch = useDispatch()
  const { language } = useSelector(state => state.Root?.user)
  const { creatingItem, updatingItem, deletingItem } = useSelector(state => state.WorkflowItemModals)

  const trans = translate(translation)(language)

  const onModalCreateFormSubmit = data => dispatch(createItem(data))
  const onModalUpdateClose = () => dispatch(setUpdatingItem(null))
  const onModalUpdateFormSubmit = data => dispatch(updateItem(data))
  const onConfirmDeleteModalClose = () => dispatch(setDeletingItem(null))
  const onConfirmDeleteModalConfirm = () => dispatch(deleteItem())

  return (
    <>
      <Modal
        isOpen={!!creatingItem}
        title={trans('modal.create.title')}
        onClose={ () => dispatch(toggleCreateWorkflowModal()) }
        size="lg"
      >
        <Form trans={trans} onSubmit={ onModalCreateFormSubmit }/>
      </Modal>

      <Modal isOpen={!!updatingItem} title={trans('modal.update.title') } onClose={ onModalUpdateClose } size="lg">
        <Form trans={trans}
              onSubmit={ onModalUpdateFormSubmit }
              item={updatingItem}
        />
      </Modal>

      <ConfirmModal title={ trans('modal.confirmDelete.title') }
                    isOpen={ !!deletingItem }
                    onClose={ onConfirmDeleteModalClose }
                    onConfirm={ onConfirmDeleteModalConfirm }
      />
    </>
  )
}

export default WorkflowModals
