import * as types from './actionTypes'

export const fetchData = (payload) => ({
  type: types.FETCH_DATA,
  payload
})

export const setData = (payload) => ({
  type: types.SET_DATA,
  payload
})
