import * as type from './actionTypes'

export const reset = () => ({
  type: type.RESET
})
export const fetchZmr = payload => ({
  type: type.FETCH_ZMR,
  payload
})

export const fetchFilters = payload => ({
  type: type.FETCH_FILTERS,
  payload
})

export const fetchColumns = payload => ({
  type: type.FETCH_COLUMNS,
  payload
})

export const saveZmr = payload => ({
  type: type.SAVE_ZMR,
  payload
})
export const setSaveStatus = payload => ({
  type: type.SET_ZMR_SAVE_STATUS,
  payload
})

export const setZmr = payload => ({
  type: type.SET_ZMR,
  payload
})

export const setOperators = payload => ({
  type: type.SET_OPERATORS,
  payload
})

export const setLabel = payload => ({
  type: type.SET_LABEL,
  payload
})

export const setDescription = payload => ({
  type: type.SET_DESCRIPTION,
  payload
})

export const setDraggedColumn = payload => ({
  type: type.SET_DRAGGED_COLUMN,
  payload
})

export const setType = payload => ({
  type: type.SET_TYPE,
  payload
})

export const editColumn = () => ({
  type: type.EDIT_COLUMN
})

export const addColumn = () => ({
  type: type.ADD_COLUMN
})

export const addFilter = payload => ({
  type: type.ADD_FILTER,
  payload
})

export const removeColumn = () => ({
  type: type.REMOVE_COLUMN
})

export const removeFilter = (listId, filterId) => ({
  type: type.REMOVE_FILTER,
  payload: {
    listId,
    filterId
  }
})

export const sortColumn = (listId, draggedColumn, targetColumn) => ({
  type: type.SORT_COLUMN,
  payload: {
    listId,
    draggedColumn,
    targetColumn
  }
})

export const setSelectedReferenceField = payload => ({
  type: type.SET_SELECTED_REFERENCE_FIELD,
  payload
})

export const setAddColumnStatus = payload => ({
  type: type.SET_ADD_COLUMN_STATUS,
  payload
})

export const setShowAddButtonOption = payload => ({
  type: type.SET_SHOW_ADD_BUTTON_OPTION,
  payload
})

export const setColumnBeingCreated = payload => ({
  type: type.SET_COLUMN_BEING_CREATED,
  payload
})

export const setColumnBeingEdited = payload => ({
  type: type.SET_COLUMN_BEING_EDITED,
  payload
})

export const setColumnBeingRemoved = payload => ({
  type: type.SET_COLUMN_BEING_REMOVED,
  payload
})

export const setIsAddFilterModalOpened = payload => ({
  type: type.SET_IS_ADD_FILTER_MODAL_OPENED,
  payload
})

export const setAddFilterStatus = payload => ({
  type: type.SET_ADD_FILTER_STATUS,
  payload
})

export const setFilters = payload => ({
  type: type.SET_FILTERS,
  payload
})

export const setColumns = payload => ({
  type: type.SET_COLUMNS,
  payload
})

export const setFilterBeingRemoved = payload => ({
  type: type.SET_FILTER_BEING_REMOVED,
  payload
})

export const setSelectedReferenceFieldDetails = payload => ({
  type: type.SET_SELECTED_REFERENCE_FIELD_DETAILS,
  payload
})
