export const FETCH_FORM_SYSTEM_FIELDS = '[SYSTEM_FIELDS] FETCH_FORM_SYSTEM_FIELDS'
export const SET_FORM_SYSTEM_FIELDS = '[SYSTEM_FIELDS] SET_FORM_SYSTEM_FIELDS'

export const TOGGLE_FORM_MODAL = '[SYSTEM_FIELDS] TOGGLE_FORM_MODAL'
export const TOGGLE_LOADING = '[SYSTEM_FIELDS] TOGGLE_LOADING'

export const FETCH_REMOVE_SYSTEM_FIELD = '[SYSTEM_FIELDS] FETCH_REMOVE_SYSTEM_FIELD'
export const FETCH_ADD_SYSTEM_FIELD = '[SYSTEM_FIELDS] FETCH_ADD_SYSTEM_FIELD'
export const REMOVE_DELETED_SYSTEM_FIELD = '[SYSTEM_FIELDS] REMOVE_DELETED_SYSTEM_FIELD'
export const ADD_NEW_SYSTEM_FIELD = '[SYSTEM_FIELDS] ADD_NEW_SYSTEM_FIELD'
export const SYSTEM_FIELD_LIMIT_CHANGE = '[SYSTEM_FIELDS] SYSTEM_FIELD_LIMIT_CHANGE'
export const SYSTEM_FIELD_PAGE_CHANGE = '[SYSTEM_FIELDS] SYSTEM_FIELD_PAGE_CHANGE'
export const SET_FORM_ID = '[SYSTEM_FIELDS] SET_FORM_ID'
