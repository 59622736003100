import React, { useEffect, useRef } from 'react'
import { useLoadScript, Autocomplete } from '@react-google-maps/api'
import { translate } from '../../Services/translation'
import translation from './translations'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Spinner } from 'react-bootstrap'
import { formatPlaceResponse } from './utils'
import { COORDINATES } from '../../Services/Constants'
import { logMessage } from '../../Services/Store/Root/actions'

const libraires = [ 'places' ]
/** @url https://developers.google.com/maps/documentation/javascript/supported_types */
const types = [ 'address' ]
/** @url https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult */
const fields = [ 'formatted_address', 'address_components', 'geometry.location' ]

const AddressSearch = ({ onKeyUp, onChange, onUnableToLoad }) => {
  const dispatch = useDispatch()
  const { config, user: { language, locale } } = useSelector(state => state.Root)
  const trans = translate(translation)(language)

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: config.GOOGLE_API_KEY,
    libraries: libraires
  })

  const inputRef = useRef()

  useEffect(() => {
    if (!config?.GOOGLE_API_KEY) {
      onUnableToLoad()
      dispatch(logMessage('No google api key. Hiding address search component.', 'warning'))
    }
  }, [ config ])

  const handlePlaceChanged = () => {
    const place = inputRef.current.getPlace()
    onChange(place ? formatPlaceResponse(place) : null)
  }

  if (!config?.GOOGLE_API_KEY)
    return <></>

  return isLoaded ?
    <Autocomplete bounds={ new google.maps.LatLngBounds(COORDINATES[locale].southWest, COORDINATES[locale].northEast) }
                  onLoad={ ref => inputRef.current = ref }
                  onPlaceChanged={ handlePlaceChanged }
                  types={ types }
                  fields={ fields }
    >
      <Form.Control placeholder={ trans('placeholder') } onKeyUp={onKeyUp} />
    </Autocomplete>
    : <Spinner variant={ 'primary' } animation="border" role="main">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
}

export default AddressSearch
