import React, { FC, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Badge } from 'react-bootstrap'
import { setDraggedFieldType } from '../state/actions'

interface FieldTypeData {
  id: string
  name: string
  icon: string
}

interface FieldTypeProps {
  fieldType: FieldTypeData
}

export const FieldType: FC<FieldTypeProps> = ({ fieldType }) => {
  const dispatch = useDispatch()

  const dispatchDraggedFieldType = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.dataTransfer.setData('field-type', fieldType.id.toString())
    dispatch(setDraggedFieldType(fieldType))
  }, [dispatch, fieldType])

  return (
    <Badge
      title={fieldType.name}
      bg={'primary'}
      className={'draggable cursor-drag px-2 pt-2 d-flex align-items-center flex-column mb-2'}
      draggable={true}
      onDragStart={dispatchDraggedFieldType}
    >
      <div>
        <i className={`mb-1 ${fieldType.icon}`} />
      </div>
      <div>
        <small>{fieldType.name}</small>
      </div>
    </Badge>
  )
}
