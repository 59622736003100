export const INIT = '[PATIENT] INIT'

export const SET_PATIENT = '[PATIENT] SET_PATIENT'

export const FETCH_DASHBOARD_DATA = '[PATIENT] FETCH_DASHBOARD_DATA'
export const FETCH_SIDEBAR_EVENTS = '[PATIENT] FETCH_SIDEBAR_EVENTS'
export const SET_INSTANCIATED_FORMS = '[PATIENT] SET_INSTANCIATED_FORMS'
export const FETCH_INSTANCIATED_FORMS = '[PATIENT] FETCH_INSTANCIATED_FORMS'
export const FETCH_FAILED = '[PATIENT] FETCH_FAILED'
export const SET_IS_ADD_MENU_OPEN = '[PATIENT] SET_IS_ADD_MENU_OPEN'
export const TOGGLE_ADD_MENU = '[PATIENT] TOGGLE_ADD_MENU'
export const SET_ACTIVE_SECTION_INDEX = '[PATIENT] SET_ACTIVE_SECTION_INDEX'
export const SET_SIDEBAR_EVENTS = '[PATIENT] SET_SIDEBAR_EVENTS'
export const SET_IS_SECTION_NAV = '[PATIENT] SET_IS_SECTION_NAV'
export const FETCH_DETAILS_OVERVIEW = '[PATIENT] FETCH_DETAILS_OVERVIEW'
// prettier-ignore
export const FETCH_DETAILS_OVERVIEW_SUCCEEDED = '[PATIENT] FETCH_DETAILS_OVERVIEW_SUCCEEDED'
export const FETCH_DETAILS_OVERVIEW_FAILED = '[PATIENT] FETCH_DETAILS_OVERVIEW_FAILED'
export const FETCH_PATIENT_RELATED_FORMS = '[PATIENT] FETCH_PATIENT_RELATED_FORMS'
export const SET_PATIENT_RELATED_FORMS = '[PATIENT] SET_PATIENT_RELATED_FORMS'
export const ADD_INSTANCE = '[PATIENT] ADD_INSTANCE'
export const FETCH_ENCOUNTERS = '[PATIENT] FETCH_ENCOUNTERS'
export const SET_ENCOUNTERS = '[PATIENT] SET_ENCOUNTERS'
export const FETCH_DOCUMENTS = '[PATIENT] FETCH_DOCUMENTS'
export const DOCUMENTS_FETCHED = '[PATIENT] DOCUMENTS_FETCHED'
export const DOCUMENTS_FETCH_ERROR = '[PATIENT] DOCUMENTS_FETCH_ERROR'
export const DELETE_DOCUMENT = '[PATIENT] DELETE_DOCUMENT'
export const DOCUMENT_DELETED = '[PATIENT] DOCUMENT_DELETED'
export const DOCUMENT_DELETE_ERROR = '[PATIENT] DOCUMENT_DELETE_ERROR'
export const DOWNLOAD_DOCUMENT = '[PATIENT] DOWNLOAD_DOCUMENT'
export const EDIT_DOCUMENT_NAME = '[PATIENT] EDIT_DOCUMENT_NAME'
export const CHANGE_DOCUMENT_NAME = '[PATIENT] CHANGE_DOCUMENT_NAME'
export const SAVE_DOCUMENT_NAME = '[PATIENT] SAVE_DOCUMENT_NAME'
export const DOCUMENT_NAME_SAVE_ERROR = '[PATIENT] DOCUMENT_NAME_SAVE_ERROR'
export const DOCUMENT_NAME_SAVED = '[PATIENT] DOCUMENT_NAME_SAVED'

export const RESET = '[PATIENT] RESET'
