import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { translate } from 'src/Services/translation'
import Column from './Column'
import translation from '../translations'
import { selectWorkflow } from '../state/actions'
import { setUpdatingItem as setUpdatingTrigger } from '../Modals/Triggers/state/actions'
import { setUpdatingItem as setUpdatingWorkflow } from '../Modals/Workflows/state/actions'
import { setUpdatingItem as setUpdatingAction } from '../Modals/Actions/state/actions'

const WorkflowColumns = () => {
  const dispatch = useDispatch()
  // @ts-ignore
  const { language } = useSelector(state => state.Root?.user)
  // @ts-ignore
  const { selectedWorkflow } = useSelector(state => state.WorkflowManager)
  const { originalTriggers, filteredTriggers, originalWorkflows, filteredWorkflows, originalActions,
  // @ts-ignore
    filteredActions, isFilterApplied  } = useSelector(state => state.WorkflowManager)

  const trans = translate(translation)(language)

  const onEditTrigger = (trigger: any) => dispatch(setUpdatingTrigger(trigger))
  const onEditWorkflow = (workflow: any) => dispatch(setUpdatingWorkflow(workflow))
  const onEditAction = (action: any) => dispatch(setUpdatingAction(action))

  const onSelectWorkflow = (item: any) => selectedWorkflow?.id === item.id
    ? dispatch(selectWorkflow(null))
    : dispatch(selectWorkflow(item))

  const triggers = isFilterApplied ? filteredTriggers : originalTriggers
  const workflows = isFilterApplied ? filteredWorkflows : originalWorkflows
  const actions = isFilterApplied ? filteredActions : originalActions

  return (
    <div className="workflow-columns">
      <Column
        title={trans('trigger')}
        items={triggers}
        renderText={item => item.name}
        onEditClick={onEditTrigger}
      />

      <Column
        title={trans('workflow')}
        selectable={true}
        items={workflows}
        renderText={item => `${item.trigger.name} \n ${item.action.name}`}
        onClick={onSelectWorkflow}
        onEditClick={onEditWorkflow}
      />

      <Column
        title={trans('action')}
        items={actions}
        renderText={item => item.name}
        onEditClick={onEditAction}
      />
    </div>
  )
}

export default WorkflowColumns
