import React, { ChangeEvent } from 'react'
import { useSelector } from 'react-redux'
import { Card, Col, Row } from 'react-bootstrap'
import TableNav from './TableNav'
import Table from './Table'
import Pagination from 'src/Components/Pagination'
import TableRow from 'src/Components/NavTable/Types/TableRow'
import LimitWrapper from '../LimitWrapper'
import { translate } from 'src/Services/translation'
import IPagination, { SortItem } from 'src/Types/Pagination'
import TableColumnHeader from 'src/Components/NavTable/Types/TableColumnHeader'
import translation from './translations'
import { StoreState } from 'src/Services/Store/reducers'

interface Props {
  rows: TableRow[]
  columnHeaders: string[] | TableColumnHeader[]
  pagination: IPagination
  sortItem?: SortItem
  onPageClick?: (currentPage: number, newPage: number) => void
  onLimitChange?: (limit: number) => void
  onSortUserClick?: (sortItem: SortItem) => void
  onSearchInputChange?: (event: ChangeEvent<HTMLInputElement>) => void
  isLoading?: boolean
  isSearchable?: boolean
}

const NavTable = ({
                    columnHeaders,
                    sortItem,
                    pagination,
                    rows,
                    onSearchInputChange,
                    onLimitChange,
                    onPageClick,
                    onSortUserClick,
                    isLoading,
                    isSearchable = true,
                  }: Props) => {
  const { language } = useSelector((state: StoreState) => state.Root.user)
  const trans = translate(translation)(language)

  const getColumnHeaders = (): TableColumnHeader[] =>
      columnHeaders.map(h => typeof h === 'string' ? { id: h, label: h } : h)

  return (
      <Card>
        <Card.Body>
          <TableNav pagination={pagination}
                    onSearchInputChange={onSearchInputChange}
                    onPageClick={onPageClick}
                    isSearchable={isSearchable}
          />
          <Table rows={rows}
                 columnHeaders={getColumnHeaders()}
                 sortItem={sortItem}
                 isLoading={isLoading}
                 onSortUserClick={onSortUserClick}
          />
          <Row className="justify-content-between">
            <Col xs="auto">
              <LimitWrapper limit={pagination.pageLimit}
                            onLimitChange={onLimitChange}
                            totalMsg={trans('entries', { total: pagination.totalItemsCount })}
              />
            </Col>
            { pagination && <Col xs="auto">
              <Pagination currentPage={pagination.currentPage}
                          onPageClick={onPageClick}
                          pageCount={pagination.totalPagesCount}
              />
            </Col> }
          </Row>
        </Card.Body>
      </Card>
  )
}

export default NavTable
