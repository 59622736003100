import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'react-bootstrap'
import { AuditTrailLog, AuditTrailLogFields } from 'src/Types/AuditTrailLog'
import AuditTrailLogCell from 'src/Components/AuditTrailLogCell'
import { RequestFilter } from 'src/Types/RequestFilter'
import { translate } from 'src/Services/translation'
import { StoreState } from 'src/Services/Store/reducers'
import { Instance } from 'src/Types/Instance'
import { fetchData, fetchInstanceData } from './state/actions'
import { Column } from './Types'
import translations from './translations'
import defaultColumns from './columns'

interface Props {
  filter?: RequestFilter | null
  columns?: Column[] | null
  instance?: Instance | null
}

const AuditTrailsSimpleTable = ({ filter = null, columns = null, instance = null }: Props) => {
  const dispatch = useDispatch()

  const { language } = useSelector((state: StoreState) => state.Root.user)
  const { rows, isLoading } = useSelector((state: StoreState) => state.AuditTrailsSimpleTable)
  const rowsFiltered = rows.filter(row => !row.code.includes('email.send'))

  const trans = translate(translations)(language)

  useEffect(() => {
    if (!isLoading) {
      if (instance)
        dispatch(fetchInstanceData(instance, filter))
      else if (filter)
        dispatch(fetchData(filter))
    }
  }, [ filter, instance ])

  if (isLoading)
    return <Spinner className={ 'm-auto d-block' } variant={ 'primary' } animation={ 'border' } role={ 'main' }>
      <span className={ 'visually-hidden' }>Loading...</span>
    </Spinner>

  // @ts-ignore
  const getLogValue = (column: Column, log: AuditTrailLog): any => log[column.name]

  const getColumns = (): Column[] => columns || defaultColumns || []

  return <>
    <table className={ 'table striped hover text-center' } style={ { tableLayout: 'fixed' } }>
      <thead>
      <tr>
        { getColumns().map(column => <th key={ column.name }>{ trans(`header.${ column.name }`) }</th>) }
      </tr>
      </thead>
      <tbody>
      { rowsFiltered.map((log, i) =>
        <tr key={ i }>
          { getColumns().map(column => (
            <td key={ i + column.name } style={ { overflow: 'auto', padding: '10px' } }>
              <AuditTrailLogCell column={ (column.name as AuditTrailLogFields) }
                                 value={ getLogValue(column, log) }
                                 level={ log.level }
              />
            </td>
          )) }
        </tr>,
      ) }
      </tbody>
    </table>
  </>
}

export default AuditTrailsSimpleTable
