import { call, put, takeLatest, select } from 'redux-saga/effects'
import { closeEventSource, subscribeEventSource } from 'src/Services/EventSourceManager/state/actions'
import { EVENT_SOURCE_MESSAGE } from 'src/Services/EventSourceManager/state/actionTypes'
import { logMessage } from 'src/Services/Store/Root/actions'
import { v4 as uuidv4 } from 'uuid'
import { getTopicName, TASKS_PREFIX } from 'src/Services/EventSourceManager/topics'
import {
  FETCH_FORM, SEND_IMPORT, UPDATE_IMPORT_TASK
} from './actionTypes'
import { setForm, updateImportTask } from './actions'
import apiMethods from '../../../Services/api/apiMethods'
import {
  closeSnackbar,
  pushNotification,
  VARIANT_ERROR,
  VARIANT_INFO,
  VARIANT_SUCCESS
} from '../../../Services/notifier/actions/index'
import { translate } from '../../../Services/translation/index'
import translation from '../translations'
import {
  getMessageStatus,
  IMPORT_TASK_STATUS_FAILED,
  IMPORT_TASK_STATUS_STARTED,
  IMPORT_TASK_STATUS_SUCCEEDED
} from '../index'

function* fetchForm(props, { payload }) {
  try {
    const { data } = yield call(apiMethods.get, `/forms/${ payload.id }`)
    yield put(setForm(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'fetchFailed'))
  }
}

function* sendImport(props, { payload }) {
  if (!payload.data.length)
    return

  const taskId = uuidv4()

  try {
    yield put(updateImportTask(taskId, IMPORT_TASK_STATUS_STARTED))

    yield call(apiMethods.create, '/instances/bulk_create', {
      data: {
        taskId,
        formId: payload.formId,
        userPin: payload.userPin,
        columns: Object.keys(payload.data[0]),
        data: payload.data
      }
    })
  } catch (error) {
    yield put(updateImportTask(taskId, IMPORT_TASK_STATUS_FAILED))
    yield put(props.globalActions.handleError(error, 'actionFailed'))
  }
}

function* onEventSourceMessage(props, { payload: { topic, message } }) {
  try {
    const taskId = topic.split('/').pop()

    const { currentImportTasks } = yield select(state => state.ImportInstances)

    if (!currentImportTasks.includes(taskId))
      return

    const status = getMessageStatus(message)

    yield put(updateImportTask(taskId, status, message))

  } catch (error) {
    yield put(props.globalActions.handleError(error))
  }
}

function* onImportTaskUpdate(props, { payload: { id, status, data } }) {
  try {
    const { language } = yield select(state => state.Root.user)
    const trans = translate(translation)(language)

    const topic = getTopicName(TASKS_PREFIX, id)

    if (status === IMPORT_TASK_STATUS_STARTED) {
      yield put(subscribeEventSource(topic))

      yield put(pushNotification(
        VARIANT_INFO,
        trans('taskStarted'),
        id,
        { vertical: 'bottom', horizontal: 'center' },
        0
      ))
    } else if ([ IMPORT_TASK_STATUS_SUCCEEDED, IMPORT_TASK_STATUS_FAILED ].includes(status)) {
      yield put(closeEventSource(topic))

      if (status === IMPORT_TASK_STATUS_FAILED)
        yield put(logMessage(`Import task failed, description : ${ data?.description }`, 'warning'))

      yield put(closeSnackbar(id))
      yield put(pushNotification(
        status === IMPORT_TASK_STATUS_FAILED ? VARIANT_ERROR : VARIANT_SUCCESS,
        trans(status === IMPORT_TASK_STATUS_FAILED ? 'taskFailed' : 'taskSucceeded'),
        null,
        { vertical: 'bottom', horizontal: 'center' },
        5000
      ))
    }
  } catch (error) {
    yield put(props.globalActions.handleError(error))
  }
}

export default function* translationsSagaWatcher(props) {
  yield takeLatest(FETCH_FORM, fetchForm, props)
  yield takeLatest(SEND_IMPORT, sendImport, props)
  yield takeLatest(UPDATE_IMPORT_TASK, onImportTaskUpdate, props)
  yield takeLatest(EVENT_SOURCE_MESSAGE, onEventSourceMessage, props)
}
