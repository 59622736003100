import { call, put, takeLatest } from 'redux-saga/effects'
import apiMethods from '../../../Services/api/apiMethods'
import * as types from './actionTypes'
import { setData } from './actions'

function* fetchData(props, { payload }) {
  try {
    const { data } = yield call(apiMethods.get, '/about/data')
    yield put(setData(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'fetchFailed'))
  }
}

export default function* formFillerSagaWatcher(props) {
  yield takeLatest(types.FETCH_DATA, fetchData, props)
}
