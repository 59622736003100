import React from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { FieldEvent } from 'src/Views/FormFiller/Types/Field'
import translations from 'src/Views/FormFiller/translations.ts'
import { FieldOption } from 'src/Types/FieldOption'
import { translate } from '../../../../Services/translation/index'
import { fieldEvent } from '../../state/actions'

const CheckboxesField = ({
  id = null,
  field = null,
  value,
  disabled = false,
  isRequired = false
}) => {
  const dispatch = useDispatch()
  const fieldsContainer = React.useRef()

  const { language } = useSelector(state => state.Root.user)

  const trans = translate(translations)(language)

  const getChecked = () => value?.map(o => o.systemName || o) || []

  const onOptionCheck = (e, option) => {
    let results = getChecked()

    results = e.target.checked
      ? results.some(_ => _ === option.systemName) ? results : [ ...results, option.systemName ]
      : results.filter(_ => _ !== option.systemName)

    dispatch(fieldEvent(field, FieldEvent.VALUE_CHANGE, { value: results }))
  }

  const showRequired = () => isRequired && !getChecked().length

  const getOptions = () => {
    const options = field.options[FieldOption.VALUES]

    return field.options[FieldOption.SORT_ALPHA] === true
      ? options.sort((a, b) => a.label > b.label ? 1 : -1)
      : options
  }

  return <div key={ `field${ id }` } ref={ fieldsContainer }>
    { getOptions().map((option, i) =>
      <Form.Check inline={ !field.options[FieldOption.ALIGN_OPTIONS_VERTICALLY] }
                  id={ `field${ id }.${ i }` }
                  key={ `field${ id }.${ i }` }
                  name={ `field${ id }.${ i }` }
                  label={ option.label }
                  value={ option.systemName }
                  checked={ getChecked().includes(option.systemName) }
                  onChange={ e => onOptionCheck(e, option) }
                  disabled={ disabled }
                  required={ showRequired() }
      />
    ) }
  </div>
}

export default CheckboxesField
