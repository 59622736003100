export const FETCH_ITEMS = '[MESSAGE_CONFIGS] FETCH_ITEMS'
export const FETCH_ITEM = '[MESSAGE_CONFIGS] FETCH_ITEM'
export const CREATE_ITEM = '[MESSAGE_CONFIGS] CREATE_ITEM'
export const DELETE_ITEM = '[MESSAGE_CONFIGS] DELETE_ITEM'
export const UPDATE_ITEM = '[MESSAGE_CONFIGS] UPDATE_ITEM'

export const ITEMS_RECEIVED = '[MESSAGE_CONFIGS] ITEMS_RECEIVED'
export const ITEM_RECEIVED = '[MESSAGE_CONFIGS] ITEM_RECEIVED'
export const ITEM_CREATED = '[MESSAGE_CONFIGS] ITEM_CREATED'
export const ITEM_DELETED = '[MESSAGE_CONFIGS] ITEM_DELETED'
export const ITEM_UPDATED = '[MESSAGE_CONFIGS] ITEM_UPDATED'

export const SET_DELETING_ITEM = '[MESSAGE_CONFIGS] SET_DELETING_ITEM'
export const SET_UPDATING_ITEM = '[MESSAGE_CONFIGS] SET_UPDATING_ITEM'
export const SET_CREATING_ITEM = '[MESSAGE_CONFIGS] SET_CREATING_ITEM'

export const SET_QUERY = '[MESSAGE_CONFIGS] SET_QUERY'
export const SET_LIMIT = '[MESSAGE_CONFIGS] SET_LIMIT'
export const SET_PAGE = '[MESSAGE_CONFIGS] SET_PAGE'

export const SEND_TEST_EMAIL = '[MESSAGE_CONFIGS] SEND_TEST_EMAIL'
