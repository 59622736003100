import {
  SET_FORM, UPDATE_IMPORT_TASK
} from './actionTypes'
import { IMPORT_TASK_STATUS_FAILED, IMPORT_TASK_STATUS_STARTED, IMPORT_TASK_STATUS_SUCCEEDED } from '../index'

const initialState = {
  form: null,
  currentImportTasks: []
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_FORM:
      return {
        ...state,
        form: payload.form || null
      }
    case UPDATE_IMPORT_TASK:

      let currentImportTasks = state.currentImportTasks

      if (payload.status === IMPORT_TASK_STATUS_STARTED)
        currentImportTasks = [ ...currentImportTasks, payload.id ]
      else if ([ IMPORT_TASK_STATUS_SUCCEEDED, IMPORT_TASK_STATUS_FAILED ].includes(payload.status))
        currentImportTasks = currentImportTasks.filter(id => id !== payload.id)

      return { ...state, currentImportTasks }
    default:
      return state
  }
}
