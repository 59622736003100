import * as types from './actionTypes'

export const initialState = {
  contactTemplate: null,
  legalPages: [],
  versions: [],
}

export default (state = initialState, {
  type,
  payload
}) => {
  switch (type) {
    case types.SET_DATA:
      return {
        ...state,
        contactTemplate: payload.contact || null,
        legalPages: payload?.legalPages || [],
        versions: payload?.versions || [],
      }
    default:
      return state
  }
}
