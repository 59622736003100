import {
  RECEIVE_SECTIONS,
  SET_DRAGGED_ELEMENT_ID,
  SET_TARGET_ELEMENT_ID,
  REORDER_SECTIONS,
  SECTIONS_REORDERED,
  ERROR_SWAPPING_ELEMENTS,
  ADD_SECTION,
  SECTION_ADDED,
  ERROR_ADDING_SECTION
} from './actionTypes'

export const receiveSections = (data) => ({
  type: RECEIVE_SECTIONS,
  payload: data
})

export const setDraggedElementId = (id) => ({
  type: SET_DRAGGED_ELEMENT_ID,
  payload: id
})

export const setTargetElementId = (id) => ({
  type: SET_TARGET_ELEMENT_ID,
  payload: id
})

export const swapElements = () => ({
  type: REORDER_SECTIONS
})

export const sectionsReordered = () => ({
  type: SECTIONS_REORDERED
})

export const errorSwappingElements = (data) => ({
  type: ERROR_SWAPPING_ELEMENTS,
  payload: data
})

export const addSection = (data) => ({
  type: ADD_SECTION,
  payload: data
})

export const sectionAdded = () => ({
  type: SECTION_ADDED
})

export const errorAddingSection = (data) => ({
  type: ERROR_ADDING_SECTION,
  payload: data
})
