import { WeekDay } from 'src/Components/Calendar/Types/Calendar'
import { WEEK_DAYS } from 'src/Services/Constants'

export interface WeekDayTemplate {
  label: string
  days: WeekDay[]
}

export interface WeekDayTemplates {
  [template: string]: WeekDayTemplate
}

export const WEEK_DAYS_TEMPLATES: WeekDayTemplates = {
  all: {
    label: 'All',
    days: WEEK_DAYS
  },
  weekdays: {
    label: 'Weekdays',
    days: [ WeekDay.MONDAY, WeekDay.TUESDAY, WeekDay.WEDNESDAY, WeekDay.THURSDAY, WeekDay.FRIDAY ]
  },
  weekends: {
    label: 'Weekends',
    days: [ WeekDay.SATURDAY, WeekDay.SUNDAY ]
  },
  monWedFri: {
    label: 'Mon/Wed/Fri',
    days: [ WeekDay.MONDAY, WeekDay.WEDNESDAY, WeekDay.FRIDAY ]
  },
  tueThu: {
    label: 'Tue/Thu',
    days: [ WeekDay.TUESDAY, WeekDay.THURSDAY ]
  },
  none: {
    label: 'None',
    days: []
  }
}
