import React, { useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import ReferenceFieldSelect from 'src/Components/ReferenceFieldSelect/index.tsx'
import { LIST_TYPE_REFERENCE } from 'src/Services/Constants'
import SystemNameInput from '../../SystemNameInput'

const ColumnForm = ({ trans, list, refForm, onSubmit }) => {
  const [ field, setField ] = React.useState(null)
  const [ label, setLabel ] = React.useState(null)
  const [ isAvailable, setIsAvailable ] = useState(null)

  const resetForm = () => {
    setField(null)
    setLabel(null)
    setIsAvailable(null)
  }

  const onSubmitColumn = e => {
    e.preventDefault()

    const systemName = e.target.systemName.value

    const column = {
      systemName, label, field
    }

    onSubmit(column)
    resetForm()
  }

  const onSystemNameChange = (systemName, isAvailable) => {
    setIsAvailable(isAvailable)
  }

  return <div className={ 'blue-card' }>
    <Form onSubmit={ onSubmitColumn }>
      <Form.Group className={ 'mb-2' }>
        <Form.Label>{ trans('formField') }</Form.Label>
        <ReferenceFieldSelect
          type={ LIST_TYPE_REFERENCE }
          refForm={ refForm }
          value={ field }
          onChange={ field => setField(field) }
          elementId={ 'referenceColumnFieldSelect' }
          required
          size={ 'sm' }
        />
      </Form.Group>
      <Row className={ 'mb-2' }>
        <Col>
          <Form.Group className={ 'mb-2' }>
            <Form.Label>{ trans('label') }</Form.Label>
            <Form.Control size={ 'sm' } name={ 'label' }
                          onChange={ e => setLabel(e.target.value) } required/>
          </Form.Group>
        </Col>
      </Row>
      <Row className={ 'mb-2' }>
        <Col>
          <Form.Group className={ 'mb-2' }>
            <Form.Label>{ trans('systemName') }</Form.Label>
            <SystemNameInput size={ 'sm' } label={ label } parent={ list.systemName } model={ 'ListColumn' }
                             required onChange={ onSystemNameChange } name={ 'systemName' }/>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className={ 'd-flex justify-content-end' }>
          <Button type={ 'submit' } disabled={ !isAvailable } size={ 'sm' }>
            { trans('submitColumn') }
          </Button>
        </Col>
      </Row>
    </Form>
  </div>
}

export default ColumnForm
