import React from 'react'
import EntitySelect from '../EntitySelect'
import SystemField from 'src/Types/SystemField'

interface SystemFieldSelectProps {
    formId?: string
    id?: string
    name?: string
    isMulti?: boolean
    required?: boolean
    disabled?: boolean
    clearable?: boolean
    defaultValue?: object
    value?: object
    onChange: (newValue: SystemField) => void
    placeholder?: string
}

const SystemFieldSelect = ({
   formId = null,
   id = 'entitySelect',
   name = 'entitySelect',
   isMulti = false,
   defaultValue = null,
   value = null,
   required = false,
   disabled = false,
   clearable = false,
   onChange = () => null,
    placeholder = null,
}: SystemFieldSelectProps) => {

    const getFetchUrl = () => formId ? `/forms/${formId}/system_fields` : '/system_fields'

    return <EntitySelect id={id}
                         name={name}
                         isMulti={isMulti}
                         defaultValue={defaultValue}
                         value={value}
                         required={required}
                         disabled={disabled}
                         clearable={clearable}
                         fetchUrl={getFetchUrl()}
                         onChange={onChange}
                         valueKey={'id'}
                         labelKey={'name'}
                         placeholder={placeholder}
    />
}

export default SystemFieldSelect
