import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
  FETCH_ITEMS, FETCH_ITEM,
  CREATE_ITEM,
  DELETE_ITEM,
  UPDATE_ITEM
} from './actionTypes'
import {
  itemUpdated, itemDeleted, itemCreated, itemsReceived, itemReceived
} from './actions'
import apiMethods from '../../../Services/api/apiMethods'
import { contentRangeToPagination } from '../../../Utils/EffectsUtil.ts'

const STORE = 'Reports'

function* updateItem(props, { payload }) {
  try {
    const item = (yield select())[STORE].updatingItem

    const id = item.id
    delete item.id

    const { data } = yield call(apiMethods.update, `/reports/${id}`, { data: item })
    yield put(itemUpdated(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'updateFailed'))
  }
}

function* deleteItem(props, { payload }) {
  try {
    const { id } = (yield select())[STORE].deletingItem
    yield call(apiMethods.delete, `/reports/${id}`)
    yield put(itemDeleted(id))
  } catch (error) {
      yield put(props.globalActions.handleError(error, 'createFailed'))
  }
}

function* createItem(props, { payload }) {
  try {
    const item = (yield select())[STORE].creatingItem
    const { data } = yield call(apiMethods.create, '/reports', { data: item })
    yield put(itemCreated(data))
  } catch (error) {
      yield put(props.globalActions.handleError(error, 'createFailed'))
  }
}

function* fetchItems(props, { payload }) {
  try {
    const query = (yield select())[STORE].query
    const { pagination } = (yield select())[STORE]

    const { data, headers } = yield call(apiMethods.get, '/reports', {
      query, ...pagination
    })
    yield put(itemsReceived({
      items: data,
      pagination: contentRangeToPagination(headers['content-range'], pagination)
    }))
  } catch (error) {
     yield put(props.globalActions.handleError(error, 'loadListFailed'))
  }
}

function* fetchItem(props, { payload }) {
  try {
    const { data } = yield call(apiMethods.get, `/reports/${payload}`)
    yield put(itemReceived(data))
  } catch (error) {
      yield put(props.globalActions.handleError(error, 'fetchFailed'))
  }
}

export default function* reportsSagaWatcher(props) {
  yield takeLatest(FETCH_ITEMS, fetchItems, props)
  yield takeLatest(FETCH_ITEM, fetchItem, props)
  yield takeLatest(CREATE_ITEM, createItem, props)
  yield takeLatest(DELETE_ITEM, deleteItem, props)
  yield takeLatest(UPDATE_ITEM, updateItem, props)
}
