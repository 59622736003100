export const FETCH_ITEMS = 'FETCH_ITEMS'
export const FETCH_ITEM = 'FETCH_ITEM'
export const CREATE_ITEM = 'CREATE_ITEM'
export const DELETE_ITEM = 'DELETE_ITEM'
export const UPDATE_ITEM = 'UPDATE_ITEM'

export const ITEMS_RECEIVED = 'ITEMS_RECEIVED'
export const ITEM_RECEIVED = 'ITEM_RECEIVED'
export const ITEM_CREATED = 'ITEM_CREATED'
export const ITEM_DELETED = 'ITEM_DELETED'
export const ITEM_UPDATED = 'ITEM_UPDATED'

export const SET_DELETING_ITEM = 'SET_DELETING_ITEM'
export const SET_UPDATING_ITEM = 'SET_UPDATING_ITEM'
export const SET_CREATING_ITEM = 'SET_CREATING_ITEM'

export const SET_QUERY = 'SET_QUERY'
export const SET_LIMIT = 'SET_LIMIT'
export const SET_PAGE = 'SET_PAGE'
