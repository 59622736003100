import React from 'react'
import styled from 'styled-components'
import Button from 'react-bootstrap/Button'
import { translate } from 'src/Services/translation'
import { AlertMessage } from 'src/Components/Alert'
import { useSelector } from 'src/Services/Store'
import { getParams } from 'src/Utils/Location'
import translation from './translations'
import { getFullApiBaseUrl } from 'src/Services/Firewall/utils'
import { StoreState } from 'src/Services/Store/reducers'
import NonProdLogin from './Components/NonProdLogin'
// @ts-ignore
import biosLogo from 'src/logo.png'

const params = getParams()

const Auth = () => {
  const { config, user: { language } } = useSelector((state: StoreState) => state.Root)

  const trans = translate(translation)(language)

  return <Container>
    <Centered className={ 'p-4' }>
      <Header>
        <Brand>
          <Logo className="m-auto" src={ biosLogo }/>
        </Brand>
      </Header>
      <div className="text-center">
        { params.error && <div className="mt-4 d-flex justify-content-center">
          <AlertMessage type={ 'danger' }
                        message={ trans(`error.${ params.error }`) }
                        classes={ 'w-75 text-center' }
          />
        </div> }
        { process.env.NODE_ENV === 'development'
          ? <NonProdLogin/>
          : <Button href={ `${ getFullApiBaseUrl(config) }/login?redirectUrl=${ window.location.origin }` }
                    className="my-2"
          >
            { trans('login') }
          </Button>
        }
      </div>
    </Centered>
  </Container>
}

const Container = styled.section`
  background-color: ${ props => props.theme.colors.grey.extralight };
  width: 100vw;
  height: 100vh;
`

const Centered = styled.section`
  background-color: #ffffff;
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 420px;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 10px rgb(153 153 153 / 20%);
`

const Header = styled.div`
  width: 100%;
  height: 58px;
  background-color: #ffffff;
  margin-bottom: 30px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`
const Logo = styled.img`
  width: 190px;
  height: auto;
  display: block;
`

const Brand = styled.div`
  text-align: center;
  line-height: 17px;
`

export default Auth
