import React, { useState } from 'react'
import { Form as BForm, Button, Row, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import SystemNameInput from '../../../Components/SystemNameInput/index'
import { translateConf } from '../../../Services/translation/index'

const Form = ({ trans, onSubmit = () => null, item = null }) => {

  const { navigationItems } = useSelector(state => state.Dictionary)
  const { configTranslations } = useSelector(state => state.Root)

  const [ label, setLabel ] = React.useState(null)
  const [ isAvailable, setIsAvailable ] = useState(null)

  const transConf = translateConf(configTranslations)

  const onFormSubmit = e => {
    e.preventDefault()

    const navigationItem = {
      ...item,
      label: e.target.label.value,
      internalLink: e.target.internalLink.value || null,
      externalLink: e.target.externalLink.value || null,
      order: parseInt(e.target.order.value, 10),
      parent: e.target.parent.value !== 'null' ? e.target.parent.value : null,
      isActive: e.target.isActive.checked,
    }

    if (!item)
      navigationItem.systemName = e.target.systemName.value

    onSubmit(navigationItem)
    setLabel(null)
    setIsAvailable(null)
  }

  const onSystemNameChange = (systemName, isAvailable) => {
    setIsAvailable(isAvailable)
  }

  const getPotentialParents = () => navigationItems
    .filter(_ => _.id !== item?.id && !_.parent && !_.internalLink && !_.externalLink)

  return (
    <BForm onSubmit={ onFormSubmit }>
      { item && <small className={ 'text-muted' }>{ trans('systemName') } : { item.systemName }</small> }
      <Row className={ 'mb-2' }>
        <Col>
          <BForm.Group className={ 'mb-2' }>
            <BForm.Label>{ trans('label') }</BForm.Label>
            <BForm.Control name="label" defaultValue={ item?.label || '' }
                           onChange={ e => setLabel(e.target.value) } required/>
          </BForm.Group>
        </Col>
        { !item &&
          <Col>
            <BForm.Group className={ 'mb-2' }>
              <BForm.Label>{ trans('systemName') }</BForm.Label>
              <SystemNameInput label={ label } model={ 'NavigationItem' }
                               required onChange={ onSystemNameChange } name={ 'systemName' }/>
            </BForm.Group>
          </Col> }
      </Row>
      <Row className={ 'mb-2' }>
        <Col sm={ 2 }>
          <BForm.Group>
            <BForm.Label>{ trans('form.order') }</BForm.Label>
            <BForm.Control defaultValue={ item?.order || 0 } name={ 'order' } required type="number" min={ 0 }
                           placeholder={ trans('form.order') }/>
          </BForm.Group>
        </Col>
        <Col sm={ 3 } className={ 'd-flex' }>
          <BForm.Switch defaultChecked={ item?.isActive } className={ 'align-self-end' }
                        name={ 'isActive' } label={ trans('form.isActive') }/>
        </Col>
      </Row>
      <Row className={ 'mb-2' }>
        <Col>
          <BForm.Group>
            <BForm.Label>{ trans('form.internalLink') }</BForm.Label>
            <BForm.Control defaultValue={ item?.internalLink } name={ 'internalLink' } type="text"
                           placeholder={ trans('form.internalLink') }/>
          </BForm.Group>
        </Col>
        <Col>
          <BForm.Group>
            <BForm.Label>{ trans('form.externalLink') }</BForm.Label>
            <BForm.Control defaultValue={ item?.externalLink } name={ 'externalLink' } type="text"
                           placeholder={ trans('form.externalLink') }/>
          </BForm.Group>
        </Col>
      </Row>

      <Row className={ 'mb-2' }>
        <Col>
          <BForm.Group>
            <BForm.Label>{ trans('form.parent') }</BForm.Label>
            <BForm.Select defaultValue={ item?.parent?.id } name={ 'parent' } aria-label={ trans('form.parent') }>
              <option value={ 'null' }>{ trans('none') }</option>
              { getPotentialParents().map(navigationItem =>
                <option value={ navigationItem.id } key={ navigationItem.id }>{ transConf('NAVIGATION_ITEM')(navigationItem) }</option>) }
            </BForm.Select>
            <BForm.Text className={ 'small' }>{ trans('form.parentInfo') }</BForm.Text>
          </BForm.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Button variant={ 'success' } disabled={ !item && !isAvailable } style={ { float: 'right' } }
                  type={ 'submit' }>
            { trans('submit') }
          </Button>
        </Col>
      </Row>
    </BForm>
  )
}

export default Form
