export const TOGGLE_USER_DROPDOWN = '[HEADER] TOGGLE_USER_DROPDOWN'
export const SET_IS_SEARCH_INPUT = '[HEADER] SET_IS_SEARCH_INPUT'
export const SET_SEARCH_RESULTS = '[HEADER] SET_SEARCH_RESULTS'
export const SET_SEARCH_INPUT_VALUE = '[HEADER] SET_SEARCH_INPUT_VALUE'
export const LAUNCH_FETCH_INPUT_VALUE = '[HEADER] LAUNCH_FETCH_INPUT_VALUE'
export const SEARCH = '[HEADER] SEARCH'
export const CLEAR = '[HEADER] CLEAR'
export const RESET_PATIENT_SEARCH = '[HEADER] RESET_PATIENT_SEARCH'
export const TOGGLE_BURGER = '[HEADER] TOGGLE_BURGER'
export const SEARCH_INSTANCE = '[HEADER] SEARCH_INSTANCE'
export const SET_INSTANCE_DATA = '[HEADER] SET_INSTANCE_DATA'
