import * as types from 'src/Views/UserForm/state/actionTypes'
import { Action } from 'src/Services/Store/reducers'
import { User } from 'src/Types/User'

export interface UserFormState {
  user: User|null
  newUser: User|null
}

export const initialState: UserFormState = {
  user: null,
  newUser: null
}

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.RESET: {
      return initialState
    }
    case types.SET_USER: {
      return {
        ...state,
        user: payload.user
      }
    }
    case types.SET_NEW_USER: {
      return {
        ...state,
        newUser: payload.user
      }
    }
    default:
      return state
  }
}
