export default {
  english: {
    title: 'Trigger',
    addNew: 'Add',
    entries: 'of {total} entries',
    form: {
      valueInfo: 'Leave empty to react on empty value. Use * as every value (including empty).',
      valueInfo2: 'Exemple for a multiple select (order matters) :',
      valueInfo2Exemple: 'MALE#GENDER#PATIENT,FEMALE#GENDER#PATIENT',
      name: 'Name',
      type: 'Type',
      form: 'Form',
      formEvent: 'Form event',
      field: 'Field',
      oldValue: 'Old value',
      newValue: 'New value'
    },
    column: {
      name: 'Name',
      type: 'Type',
      form: 'Form',
      formEvent: 'Form event',
      field: 'Field',
      oldValue: 'Old value',
      newValue: 'New value'
    },
    modal: {
      create: {
        title: 'Create a new trigger',
      },
      update: {
        title: 'Update a trigger'
      },
      confirmDelete: {
        title: 'Are you sure you want to delete this trigger ?'
      }
    }
  },
  french: {
    title: 'Déclencheurs',
    addNew: 'Créer',
    entries: 'sur {total} entrées',
    form: {
      valueInfo: 'Laissez vide pour réagir sur une valeur vide. Utilisez * pour n\'importe quel valeur (y compris vide).',
      valueInfo2: 'Laissez vide pour réagir sur une valeur vide. Utilisez * pour n\'importe quel valeur (y compris vide).',
      name: 'Nom',
      type: 'Type',
      form: 'Formulaire',
      formEvent: 'Évènement de formulaire',
      field: 'Champ',
      oldValue: 'Ancienne valeur',
      newValue: 'Nouvelle valeur'
    },
    column: {
      name: 'Nom',
      type: 'Type',
      form: 'Formulaire',
      formEvent: 'Évènement de formulaire',
      field: 'Champ',
      oldValue: 'Ancienne valeur',
      newValue: 'Nouvelle valeur'
    },
    modal: {
      create: {
        title: 'Créer un nouveau déclencheur',
      },
      update: {
        title: 'Modifier un déclencheur'
      },
      confirmDelete: {
        title: 'Êtes-vous sûr de vouloir supprimer ce déclencheur ?'
      }
    }
  }
}
