import * as types from './actionTypes'
import { User } from 'src/Types/User'
import Pagination from 'src/Types/Pagination'
import { NavigateFunction } from 'react-router/dist/lib/hooks'
import { SortItem } from 'src/Types/Pagination'

export const errorHandlerAction = () => ({
  type: types.ERROR_HANDLER
})

export const load = () => ({
  type: types.LOAD
})

export const receive = (users: User[], pagination: Pagination) => ({
  type: types.RECEIVE,
  payload: { users, pagination }
})

export const changeLimit = (limit: number) => ({
  type: types.CHANGE_LIMIT,
  payload: { limit }
})

export const changePage = (page: number) => ({
  type: types.CHANGE_PAGE,
  payload: { page }
})

export const searchUser = (query: string) => ({
  type: types.SEARCH_USER,
  payload: { query }
})

export const sortUsers = (sortItem: SortItem) => ({
  type: types.SORT_USERS,
  payload: { sortItem }
})

export const impersonateUser = (user: User) => ({
  type: types.IMPERSONATE_USER,
  payload: { user }
})

export const deleteUser = (user: User, navigate: NavigateFunction) => ({
  type: types.DELETE_USER,
  payload: { user, navigate }
})

export const userDeleted = () => ({
  type: types.USER_DELETED
})
