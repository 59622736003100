import {
  ITEMS_RECEIVED,
  ITEM_CREATED,
  ITEM_DELETED,
  ITEM_RECEIVED,
  FETCH_ITEMS,
  CREATE_ITEM, SET_QUERY,
  DELETE_ITEM, UPDATE_ITEM, ITEM_UPDATED, SET_LIMIT, SET_PAGE, SET_DELETING_ITEM, SET_UPDATING_ITEM, SET_CREATING_ITEM
} from './actionTypes'
import Pagination from 'src/Types/Pagination'
import { EventCancellationReason } from '../../../Types/EventCancellationReason'
import { Action } from 'src/Services/Store/reducers'

export interface EventCancellationStoreState {
    EventCancellationReasons: EventCancellationState
}

export interface EventCancellationState {
  isLoading: boolean,
  creatingItem: null | EventCancellationReason,
  updatingItem: null | EventCancellationReason,
  deletingItem: null | EventCancellationReason,
  items: EventCancellationReason[],
  query: null,
  pagination: Pagination,
}

const initialState: EventCancellationState = {
  isLoading: false,
  creatingItem: null,
  updatingItem: null,
  deletingItem: null,
  items: [],
  query: null,
  pagination: {
    offset: 0,
    limit: 10,
    itemsCount: 10,
    totalItemsCount: 10,
    pageLimit: 10,
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPagesCount: 1,
  },
}

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case FETCH_ITEMS:
      return {
        ...state,
        isLoading: true
      }
    case CREATE_ITEM:
      return {
        ...state,
        isLoading: true
      }
    case UPDATE_ITEM:
      return {
        ...state,
        isLoading: true
      }
    case DELETE_ITEM:
      return {
        ...state,
        isLoading: true
      }
    case ITEMS_RECEIVED:
      return {
        ...state,
        isLoading: false,
        items: payload.items,
        pagination: payload.pagination
      }
    case ITEM_CREATED:
      return {
        ...state,
        creatingItem: null,
        isLoading: false,
        items: [ ...state.items, payload ]
      }
    case ITEM_UPDATED:
        return {
        ...state,
        updatingItem: null,
        isLoading: false,
        items: state.items.map(item => item.id === payload.id ? payload : item)
      }
    case ITEM_DELETED:
      return {
        ...state,
        deletingItem: null,
        isLoading: false,
        items: state.items.filter(item => item.id !== payload)
      }
    case SET_DELETING_ITEM:
      return {
        ...state,
        deletingItem: payload
      }
    case SET_UPDATING_ITEM:
      return {
        ...state,
        updatingItem: payload
      }
    case SET_CREATING_ITEM:
      return {
        ...state,
        creatingItem: payload
      }
    case SET_QUERY:
      return {
        ...state,
        query: payload
      }
    case SET_LIMIT:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: 1,
          pageLimit: payload
        }
      }
    case SET_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: payload
        }
      }
    default:
      return state
  }
}
