import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { USER_ACTIONS } from 'src/Services/Constants'
import { showWarning } from 'src/Services/notifier/actions'
import { FormCustomizationConditionType } from 'src/Types/FormCustomizationCondition'
import CustomizationConditionForm from './CustomizationConditionForm'
import { getFields } from '../state/formReducer'

const DEFAULT_CONDITION = {
  field: null,
  type: FormCustomizationConditionType.VALUE,
  value: null,
  operator: null,
  listColumn: null
}

const CustomizationOrderForm = ({ trans, item, onSubmit }) => {
  const dispatch = useDispatch()

  const { form, isCustomizationOrderLoading } = useSelector(state => state.FormEditor.formReducer)

  const [ label, setLabel ] = useState(item.label || null)
  const [ action, setAction ] = useState(item.action || null)
  const [ targetFields, setTargetFields ] = useState(item.targetFields || [])
  const [ conditions, setConditions ] = useState(item.conditions || [])

  useEffect(() => {
    setLabel(item.label || null)
    setAction(item.action || null)
    setTargetFields(item.targetFields || [])
    setConditions(item.conditions || [])
  }, [ item ])

  const getFieldsForSelect = form =>
    getFields(form)
      .filter(f => f.id)
      .map(f => ({ id: f.id, systemName: f.systemName, label: f.label }))
      .sort((a, b) => a.systemName.localeCompare(b.systemName))

  const onAddConditionButtonClick = e =>
    setConditions([ ...conditions, DEFAULT_CONDITION ])

  const onConditionDelete = index => () =>
    setConditions(conditions.filter((c, i) => i !== index))

  const onConditionChange = index => condition =>
    setConditions(conditions.map((c, i) => i === index ? condition : c))

  const onFormSubmit = e => {
    e.preventDefault()

    if (targetFields.length === 0) {
      dispatch(showWarning('formIncomplete'))
      return
    }

    onSubmit({
      ...item,
      form: form.id, label, action,
      targetFields: targetFields.map(f => f?.id),
      conditions: conditions.map(c => ({ ...c, field: c.field?.id }))
    })
  }

  return <div className={ 'blue-card' }>
    <Form onSubmit={ onFormSubmit }>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>{ trans('form.customizationOrder.label') }</Form.Label>
            <Form.Control size={ 'sm' } name={ 'label' } value={ label || '' }
                          required onChange={ e => setLabel(e.target.value) } />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>{ trans('form.customizationOrder.action') }</Form.Label>
            <Form.Select size={ 'sm' } name={ 'action' } value={ action || '' }
                         required onChange={ e => setAction(e.target.value) }>
              <option value={ null }>{ trans('none') }</option>
              { USER_ACTIONS.map(action => <option key={ action } value={ action }>{ action }</option>) }
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>{ trans('form.customizationOrder.targetFields') }</Form.Label>
            <Select isMulti name={ 'targetFields' } options={ getFieldsForSelect(form) }
                    getOptionValue={ field => field.id }
                    getOptionLabel={ field => field.systemName }
                    value={ targetFields }
                    onChange={ setTargetFields }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Label className={ 'mb-0' }>{ trans('form.customizationOrder.conditions') }</Form.Label>
          <div>
            <Form.Text>{ trans('form.customizationOrder.conditionsInfo') }</Form.Text><br/>
            <Form.Text>{ trans('form.customizationOrder.conditionsInfo2') }</Form.Text><br/>
            <strong className={ 'cursor-pointer text-primary' } onClick={ onAddConditionButtonClick }>
              <i className={ 'fas fa-lg fa-plus-circle me-1' }/>
              { trans('form.customizationOrder.addCondition') }
            </strong>
          </div>
          { conditions.map((c, i) =>
            <CustomizationConditionForm key={ i } trans={ trans } condition={ c }
                                        onChange={ onConditionChange(i) } onDelete={ onConditionDelete(i) }/>) }
        </Col>
      </Row>
      <Row className={ 'mt-2' }>
        <Col>
          <Button type={ 'submit' } disabled={ isCustomizationOrderLoading }>
            { isCustomizationOrderLoading &&
              <Spinner className={ 'me-1' } animation={ 'border' } size={ 'sm' }>
                <span className={ 'visually-hidden' }>Loading...</span>
              </Spinner> }
            { trans('form.customizationOrder.submit') }
          </Button>
        </Col>
      </Row>
    </Form>
  </div>
}

export default CustomizationOrderForm
