import React, { ChangeEvent, FormEvent, FormEventHandler, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Form, Row } from 'react-bootstrap'
import translation from 'src/Components/Calendar/translations'
import { translate } from 'src/Services/translation'
import LocalizedDatepicker from 'src/Components/LocalizedDatepicker'
import { WEEK_DAYS } from 'src/Services/Constants'
import { createSlotSerie, setSlotFormModal, setSlotResources } from 'src/Components/Calendar/state/actions'
import EntitySelect from '../../EntitySelect'
import { StoreState } from 'src/Services/Store/reducers'
import { WeekDay } from 'src/Components/Calendar/Types/Calendar'
import { Size } from 'src/Types/Size'
import { Iso8601 } from 'src/Types/Date'
import { WEEK_DAYS_TEMPLATES } from 'src/Components/Calendar/Constants'

const SlotSerieForm = () => {
  const dispatch = useDispatch()

  const { language } = useSelector((state: StoreState) => state.Root.user)
  const { locations } = useSelector((state: StoreState) => state.Dictionary)
  const { slotType } = useSelector((state: StoreState) => state.Calendar.slotForm)

  const trans = translate(translation)(language)

  const [ formErrors, setFormErrors ] = useState<any>({})

  const [ location, setLocation ] = useState(undefined)
  const [ templateName ] = useState<string>(null)
  const [ startDate, setStartDate ] = useState<Iso8601>(null)
  const [ endDate, setEndDate ] = useState<Iso8601>(null)
  const [ firstSlotAt, setFirstSlotAt ] = useState<Iso8601>(null)
  const [ isAvailability, setIsAvailability ] = useState<boolean>(true)
  const [ slotsCount, setSlotsCount ] = useState<number>(1)
  const [ slotsCountPerDay, setSlotsCountPerDay ] = useState(1)
  const [ days, setDays ] = useState<WeekDay[]>([])
  const [ resource, setResource ] = useState(null)

  const handleWeekDaysTemplateChange = (e: ChangeEvent<HTMLSelectElement>) =>
      setDays(WEEK_DAYS_TEMPLATES[e.target.value]?.days)
  const handleWeekDaysChange = (day: WeekDay) =>
    setDays(days.includes(day) ? days.filter(_ => _ !== day) : [ ...days, day ])

  const handleLocationChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setLocation(e.target.value)
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!isValid()) return

    dispatch(createSlotSerie({
      resource, templateName, isAvailability, slotsCount, slotsCountPerDay, days, firstSlotAt,
      type: slotType.id, location: location, startDate, endDate
    }))
    dispatch(setSlotFormModal(false, slotType))
    dispatch(setSlotResources([]))
  }

  const isValid = () => {
    let isValid = true
    const errors: any = {}

    if (!days.length) {
      errors.days = 'You have to select at least one day'
      isValid = false
    }
    if (endDate < startDate) {
      errors.startDate = 'End date can\'t be before start date'
      errors.endDate = 'End date can\'t be before start date'
      isValid = false
    }

    setFormErrors(errors)
    return isValid
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Col>
          <Form.Group>
            <Form.Label>Location</Form.Label>
            <Form.Select onChange={handleLocationChange} value={location} placeholder="Select a location">
              <option value={null}></option>
              {locations.map(val => <option value={val.id} key={val.id}>{val.identityString}</option>)}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Resource</Form.Label>
            <EntitySelect fetchUrl={`/instances/resources/${slotType?.eventType?.id}`}
                          name={'resourceSelect'}
                          placeholder={'Select a resource'}
                          labelKey={'identityString'}
                          value={resource?.id ?? null}
                          key={location ?? 'resources'}
                          isDefaultOptions
                          additionalParams={location ? { locationId: location } : null}
                          onChange={selected => setResource(selected?.id ?? null)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Group>
            <Form.Label>Start date</Form.Label>
            <LocalizedDatepicker showDateOnly
                                 size={ Size.MEDIUM }
                                 selected={startDate}
                                 onChange={setStartDate}
                                 portalId="root"
                                 required
            />
            {formErrors.startDate && <Form.Text className="text-danger">{formErrors.startDate}</Form.Text>}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>End date</Form.Label>
            <LocalizedDatepicker showDateOnly
                                 size={ Size.MEDIUM }
                                 selected={endDate}
                                 onChange={setEndDate}
                                 portalId="root"
                                 required
            />
            {formErrors.endDate && <Form.Text className="text-danger">{formErrors.endDate}</Form.Text>}
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Group>
            <Form.Label>First Slot Starts At</Form.Label>
            <LocalizedDatepicker
              size={ Size.MEDIUM }
              showTimeOnly
              timeIntervals={5}
              timeCaption="Time"
              selected={firstSlotAt}
              onChange={setFirstSlotAt}
              portalId="root"
              required
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Slot count</Form.Label>
            <Form.Control
              min={1}
              type="number"
              value={slotsCount}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setSlotsCount(parseInt(e.target.value, 10))}
              required
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Slot per day count</Form.Label>
            <Form.Control
              min={1}
              type="number"
              value={slotsCountPerDay}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setSlotsCountPerDay(parseInt(e.target.value, 10))}
              required
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Switch checked={isAvailability} label="Is availability"
                         onChange={e => setIsAvailability(e.target.checked)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Group>
            <Form.Label>On</Form.Label>
            <Form.Select onChange={handleWeekDaysTemplateChange}
                         placeholder="Select a configuration">
              <option value={null}></option>
              {Object.keys(WEEK_DAYS_TEMPLATES)
                .map(_ => <option value={_} key={_}>{trans(`weekdayTemplate.${_}`)}</option>)}
            </Form.Select>
            {formErrors.days && <Form.Text className="text-danger">{formErrors.days}</Form.Text>}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            {WEEK_DAYS.map(day => <Form.Check key={day} type="checkbox" label={trans(`weekday.${day}`)}
                                              checked={days.includes(day)}
                                              onChange={() => handleWeekDaysChange(day)}
            />)}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button type="submit" className="float-right" size="sm" variant="primary">{trans('create')}</Button>
        </Col>
      </Row>
    </Form>
  )
}
export default SlotSerieForm

