import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form as BForm, Row, Col, Button } from 'react-bootstrap'
import { translate } from 'src/Services/translation'
import translation from 'src/Views/FormEditor/translations'
import Modal from 'src/Components/Modal'
import { toggleSwpConfigModal, updateDetails } from 'src/Views/FormEditor/state/actions'
import { SWP_NEEDED_SYSTEM_FIELDS } from './Constants'
import { getSystemFieldsStartingByName } from 'src/Services/Selectors'
import SystemField from 'src/Types/SystemField'
import { StoreState } from 'src/Services/Store/reducers'
import { ProductContextExamType, ProductContextProduct } from 'src/Views/FormFiller/Types/Swp'

const SwpConfigModal = () => {
  const dispatch = useDispatch()

  const { language } = useSelector((state: StoreState) => state.Root.user)
  const { systemFields, form, isSwpConfigModalOpen }
      = useSelector((state: StoreState) => state.FormEditor.formReducer)

  const trans = translate(translation)(language)

  const getProductValue = () => {
    if (form.swpProduct === ProductContextProduct.WEMU)
      return 'eeg'
    else if (form.swpProduct === ProductContextProduct.CCW)
      return 'ecg'
    else
      return null
  }

  const onProductChange = (e: React.ChangeEvent<HTMLSelectElement>) => {

    let swpProduct, swpExamType

    switch (e.target.value) {
      case 'eeg':
        swpProduct = ProductContextProduct.WEMU
        swpExamType = ProductContextExamType.ROUTINE_EEG
        break
      case 'ecg':
        swpProduct = ProductContextProduct.CCW
        swpExamType = ProductContextExamType.REST_ECG
        break
      default:
        swpProduct = null
        swpExamType = null
    }

    dispatch(updateDetails({ swpProduct, swpExamType }))
  }

  const getNeededFields = () =>
      getProductValue()
      ? SWP_NEEDED_SYSTEM_FIELDS[getProductValue()]
          .map(field => ({
            ...field,
            matchingFields: getSystemFieldsStartingByName(systemFields, field.prefix)
          }))
      : []

  const isPrimaryButtonDisabled = () =>
      !form.isSwpEnabled &&
      (!getNeededFields().length || getNeededFields().some(field => field.isRequired && !field.matchingFields.length))

  const onPrimaryButtonClick = () => {
    dispatch(updateDetails({ isSwpEnabled: !form.isSwpEnabled }))
    dispatch(toggleSwpConfigModal())
  }

  return (
      <Modal title={trans('swpConfig.title')}
             onClose={() => dispatch(toggleSwpConfigModal())}
             isOpen={isSwpConfigModalOpen}
             size={'lg'}
      >
        <Row className={'mb-2'}>
          <Col>
            <BForm>
              <BForm.Group>
                <BForm.Label>{trans('swpConfig.productFieldLabel')}</BForm.Label>
                <BForm.Select name={'product'}
                             value={getProductValue() || ''}
                             onChange={onProductChange}
                             disabled={form.isSwpEnabled}
                >
                  <option>{trans('none')}</option>
                  <option value={'eeg'}>{trans('swpConfig.product.eeg')}</option>
                  <option value={'ecg'}>{trans('swpConfig.product.ecg')}</option>
                </BForm.Select>
              </BForm.Group>
            </BForm>
          </Col>
        </Row>
        { systemFields.length && getProductValue() && <Row className={'mb-2'}>
          {getNeededFields().map(field => <Col key={field.name} sm={6}>
            <strong>{trans(`swpConfig.field.${getProductValue()}.${field.name}`)}&nbsp;
              {field.isRequired ? <span className={'text-danger'}>*</span> : ''}
            </strong>
            {field.isMultiple ? <small className={'text-muted'}>{trans('swpConfig.multiple')}</small> : ''}
            <br/>
            { field.matchingFields.length > 0
             ? <span className={'text-success'}>
                  {trans('swpConfig.fieldDetected')} :<br/>
               { field.matchingFields.map((matchingField: SystemField, i: number) =>
                                              <i key={matchingField.id}>{matchingField.name}<br/></i>) }
              </span>
             : <span className={field.isRequired ? 'text-danger' : 'text-warning'}>
                <span>{trans(`swpConfig.${field.isMultiple ? 'mustContainFields' : 'mustContainAField'}`)}
                  : <i>{field.prefix}</i></span>
              </span>}
          </Col>)}
        </Row>}
        <Row className={'mb-2'}>
          <Col>
            <small className={'text-muted'}>{ trans('swpConfig.helpInformations') }</small>
          </Col>
        </Row>
        <Button variant={form.isSwpEnabled ? 'danger' : 'primary'}
                className={'d-block ms-auto'}
                disabled={isPrimaryButtonDisabled()}
                onClick={onPrimaryButtonClick}
        >
          {trans(form.isSwpEnabled ? 'deactivate' : 'activate')}
        </Button>
      </Modal>
  )
}

export default SwpConfigModal
