import { REQUEST_LOGOUT, AUTH_GRANTED, GET_NON_PROD_AUTH_TOKEN } from './actionTypes'

export const getNonProdAuthToken = (username: string, password: string) => ({
  type: GET_NON_PROD_AUTH_TOKEN,
  payload: { username, password }
})

export const requestLogout = (useSoftLogout = true) => ({
  type: REQUEST_LOGOUT,
  payload: { useSoftLogout }
})

export const authGranted = (isSilent = false) => ({
  type: AUTH_GRANTED,
  payload: { isSilent }
})
