import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { removeSnackbar } from './actions'
import translations from '../../Services/api/translations'
import { translate } from '../translation'
import translation from './translations'

let displayed = []

const Notifier = () => {
  const dispatch = useDispatch()
  const { language } = useSelector((store) => store.Root.user)
  const notifications = useSelector((store) => store.Notification.notifications || [])

  const trans = translate(translation)(language)

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const storeDisplayed = (id) => {
    displayed = [ ...displayed, id ]
  }

  const removeDisplayed = (id) => {
    displayed = [ ...displayed.filter((key) => id !== key) ]
  }

  React.useEffect(() => {
    notifications.forEach(
      ({
        key,
        translationKey,
        message,
        options = {},
        dismissed = false
      }) => {

        // Dismiss snackbar
        if (dismissed)
          return closeSnackbar(key)

        // If snackbar is already displayed : do nothing
        if (displayed.includes(key))
          return

        message = translationKey ? translate(translations)(language)(translationKey) : message

        enqueueNotification(key, message, options)

        // keep track of snackbars that we've displayed
        storeDisplayed(key)
      }
    )
  }, [ notifications, closeSnackbar, enqueueSnackbar, dispatch ])

  const enqueueNotification = (key, message, options) => {
    // display snackbar using notistack https://iamhosseindhv.com/notistack/demos
    enqueueSnackbar(message, {
      key, ...options,
      onClick: () => closeSnackbar(key),
      onExited: (e, myKey) => {
        // Remove this snackbar from everywhere
        dispatch(removeSnackbar(myKey))
        removeDisplayed(myKey)
      }
    })
  }

  return null
}

export default Notifier
