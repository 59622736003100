import { call, put, select, takeLatest } from 'redux-saga/effects'
import apiMethods from '../../../Services/api/apiMethods'
import {
  errorHandlerAction,
  receive,
  userDeleted,
} from './actions'
import * as types from './actionTypes'
import { contentRangeToPagination } from 'src/Utils/EffectsUtil'
import { showSuccess } from 'src/Services/notifier/actions'
import { GlobalActions } from 'src/Types/GlobalActions'
import { Action, StoreState } from 'src/Services/Store/reducers'

function* loadUserList(props: GlobalActions) {
  try {
    const state: StoreState = yield select()
    const { pagination, searchQuery, sortItem } = state.UserList

    const { data, headers } = yield call(apiMethods.get, '/users', {
                                            query: searchQuery,
                                            sortCol: sortItem.column,
                                            sortDir: sortItem.direction,
                                            ...pagination
                                          })
    yield put(receive(
        data,
        contentRangeToPagination(headers['content-range'], pagination),
    ))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'loadUserListFailed'))
    yield put(errorHandlerAction())
  }
}

function* deleteUser(props: GlobalActions, { payload: { user, navigate } }: Action) {
  try {
    yield call(apiMethods.delete, `/users/${user.id}`)
    yield put(userDeleted())
    yield put(showSuccess('deleteUserSucceeded'))
    yield call(navigate, '/users')
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'deleteUserFailed'))
    yield put(errorHandlerAction())
  }
}

function impersonateUser(props: GlobalActions, { payload: { user } }: Action) {
  localStorage.setItem('impersonatedUser', JSON.stringify(user))
  window.location.href = '/'
}

export default function* userListSagaWatcher(props: GlobalActions) {
  yield takeLatest(types.LOAD, loadUserList, props)
  yield takeLatest(types.CHANGE_LIMIT, loadUserList, props)
  yield takeLatest(types.CHANGE_PAGE, loadUserList, props)
  yield takeLatest(types.USER_DELETED, loadUserList, props)
  yield takeLatest(types.SEARCH_USER, loadUserList, props)
  yield takeLatest(types.SORT_USERS, loadUserList, props)
  yield takeLatest(types.DELETE_USER, deleteUser, props)
  yield takeLatest(types.IMPERSONATE_USER, impersonateUser, props)
}
