import { Form } from '../../Components/General'
import styled from 'styled-components'

export const DialogForm = styled(Form)`
  width: 300px;
  min-height: 20em;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;

  fieldset:first-of-type {
    padding-top: 0;
  }

  button[type=submit] {
    display: block;
    margin: 1em auto 0 auto;
  }
`
export const BarsIcon = styled.i`
  color:  ${props => props.theme.styleguideColors.lightGrey};
  margin-left: 1em;
`
export const ButtonsWrapper = styled.div`
  button:first-of-type {
    margin-right: 1em;
  }
`
export const ColumnsWrapper = styled.div`
  background-color: #F7F7F7;
  border: 1px dashed rgba(179,179,179,0.35);
  padding: 1em;
  margin-bottom: 1em;

  > div {
    margin-bottom: 0.5em;
  }

  > div:last-of-type {
    margin-bottom: 0;
  }
`
