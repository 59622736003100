import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector
} from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { rootReducer } from 'src/Services/Store/reducers'
import rootSaga from 'src/Services/Store/rootSaga'
import * as globalActions from './Root/actions'

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const configureStore = (preloadedState = {}) => createStore(
  rootReducer,
  preloadedState,
  composeEnhancers(applyMiddleware(sagaMiddleware))
)

export default configureStore()

sagaMiddleware.run(rootSaga, { globalActions })

export const useSelector = useReduxSelector
export const useDispatch = useReduxDispatch
