import React, { useEffect, useState } from 'react'
import { Form as BForm, Button, Row, Col, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import SystemNameInput from 'src/Components/SystemNameInput'
import { showWarning } from 'src/Services/notifier/actions'
import ContentEditor from './ContentEditor'
import PageContent from './PageContent'

const Form = ({
  trans,
  onSubmit = () => {},
  item = null
}) => {
  const dispatch = useDispatch()
  const { editingContent, rows } = useSelector(state => state.LandingPages.form)
  const [ systemName, setSystemName ] = useState(item?.systemName || null)
  const [ label, setLabel ] = useState(item?.label || null)
  const [ isAvailable, setIsAvailable ] = useState(null)

  // Component unmount
  useEffect(() =>
      () => {
        setSystemName(null)
        setLabel(null)
        setIsAvailable(null)
      }
    , [])

  useEffect(() => {
    setSystemName(item?.systemName || null)
    setLabel(item?.label || null)
    setIsAvailable(null)
  }, [ item ])

  const formatContents = () =>
    rows.reduce((contents, row, rowIndex) =>
        [ ...contents, ...row.contents.reduce((rowContents, content, contentIndex) => {

          const getColumnNumber = () => {
            if (contentIndex === 0)
              return 1

            const lastContent = rowContents[contentIndex - 1]

            return lastContent.column + lastContent.size
          }

          const newContent = {
            label: content.label,
            type: content.type,
            size: content.size,
            column: getColumnNumber(),
            row: rowIndex + 1,
            list: content.list?.id || null,
            numericReport: content.numericReport?.id || null
          }

          if (content.id)
            newContent.id = content.id

          return [ ...rowContents, newContent ]
        }, []) ]
      , [])

  const onSubmitClick = () => {
    if ((!item && !systemName) || !label)
      return dispatch(showWarning('formIncomplete'))

    const landingPage = {
      ...item,
      label,
      contents: formatContents()
    }

    if (!item)
      landingPage.systemName = systemName

    onSubmit(landingPage)
  }

  const onSystemNameChange = (systemName, isAvailable) => {
    setSystemName(systemName)
    setIsAvailable(isAvailable)
  }

  return <>
    { item && <small className="text-muted">{ trans('systemName') } : { item.systemName }</small> }
    <Row className="mb-2">
      <Col>
        <BForm.Group className="mb-2">
          <BForm.Label>{ trans('label') }</BForm.Label>
          <BForm.Control size="sm" name="label" defaultValue={ item?.label || '' }
                         onChange={ e => setLabel(e.target.value) } required/>
        </BForm.Group>
      </Col>
      { !item &&
        <Col>
          <BForm.Group className="mb-2">
            <BForm.Label>{ trans('systemName') }</BForm.Label>
            <SystemNameInput size="sm" label={ label } model={ 'LandingPage' }
                             required onChange={ onSystemNameChange } name="systemName"/>
          </BForm.Group>
        </Col> }
    </Row>
    <hr/>
    <Row className="mb-2">
      <Col>
        <PageContent trans={ trans }/>
      </Col>
      { editingContent && <Col sm={ 3 }>
        <Card>
          <ContentEditor trans={ trans }/>
        </Card>
      </Col> }
    </Row>
    <Row>
      <Col>
        <Button variant="success" disabled={ !item && !isAvailable } style={ { float: 'right' } }
                onClick={ onSubmitClick }>
          { trans('submit') }
        </Button>
      </Col>
    </Row>
  </>
}

export default Form
