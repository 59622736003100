import * as type from './actionTypes'

export const setSections = (data) => ({
  type: type.SET_SECTIONS,
  payload: data
})
export const setActiveSection = (data) => ({
  type: type.SET_ACTIVE_SECTION,
  payload: data
})
