import React, { FormEvent } from 'react'
import { Form as BForm, Button, Row, Col } from 'react-bootstrap'
import MessageConfig from 'src/Types/MessageConfig'

interface MessageConfigFormProps {
  trans: (data: string) => string
  onSubmit: (data: MessageConfig) => void
  item?: MessageConfig | null
}

interface MessageConfigFormElements extends HTMLFormControlsCollection {
  server: HTMLInputElement
  port: HTMLInputElement
  fromAddress: HTMLInputElement
  fromName: HTMLInputElement
  username: HTMLInputElement
  password: HTMLInputElement
}

interface MessageConfigForm extends HTMLFormElement {
  readonly elements: MessageConfigFormElements
}

const Form = ({
  trans = null,
  onSubmit = (item: MessageConfig) => null,
  item = null
}: MessageConfigFormProps) => {
  const onFormSubmit = (e: FormEvent<MessageConfigForm>) => {
    e.preventDefault()
    onSubmit({
      ...item,
      server: e.currentTarget.elements.server.value,
      port: parseInt(e.currentTarget.elements.port.value, 10),
      fromAddress: e.currentTarget.elements.fromAddress.value,
      fromName: e.currentTarget.elements.fromName.value,
      username: e.currentTarget.elements.username.value,
      password: e.currentTarget.elements.password.value
    })
  }

  return (
    <BForm onSubmit={onFormSubmit}>
      <Row className={'mb-2'}>
        <Col>
          <BForm.Group>
            <BForm.Label>{trans('form.server')}</BForm.Label>
            <BForm.Control defaultValue={item?.server} name={'server'} required type="text"
                          placeholder={trans('form.server')}/>
          </BForm.Group>
        </Col>
        <Col>
          <BForm.Group>
            <BForm.Label>{trans('form.port')}</BForm.Label>
            <BForm.Control defaultValue={item?.port} name={'port'} required type="text"
                          placeholder={trans('form.port')}/>
          </BForm.Group>
        </Col>
      </Row>
      <Row className={'mb-2'}>
        <Col>
          <BForm.Group>
            <BForm.Label>{trans('form.fromEmail')}</BForm.Label>
            <BForm.Control defaultValue={item?.fromAddress} name={'fromAddress'} required type="text"
                          placeholder={trans('form.fromEmail')}/>
          </BForm.Group>
        </Col>
        <Col>
          <BForm.Group>
            <BForm.Label>{trans('form.fromName')}</BForm.Label>
            <BForm.Control defaultValue={item?.fromName} name={'fromName'} required type="text"
                          placeholder={trans('form.fromName')}/>
          </BForm.Group>
        </Col>
      </Row>
      <Row className={'mb-2'}>
        <Col>
          <BForm.Group>
            <BForm.Label>{trans('form.username')}</BForm.Label>
            <BForm.Control defaultValue={item?.username} name={'username'} type="text"
                          placeholder={trans('form.username')}/>
          </BForm.Group>
        </Col>
        <Col>
          <BForm.Group>
            <BForm.Label>{trans('form.password')}</BForm.Label>
            <BForm.Control defaultValue={item?.password} name={'password'} type="text"
                          placeholder={trans('form.password')}/>
          </BForm.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button variant={'success'} style={{ float: 'right' }} type={'submit'}>{trans('submit')}</Button>
        </Col>
      </Row>
    </BForm>
  )
}

export default Form
