import { call, put, takeLatest, select } from 'redux-saga/effects'
import {
  FETCH_ADD_SYSTEM_FIELD,
  FETCH_FORM_SYSTEM_FIELDS,
  FETCH_REMOVE_SYSTEM_FIELD
} from './actionTypes'
import apiMethods from '../../../Services/api/apiMethods'
import { showSuccess } from 'src/Services/notifier/actions'
import translationsFields from '../translations'
import { path } from 'ramda'
import {
  addNewSystemField,
  removeDeletedSystemField,
  setSystemFields,
  toggleFormModal, toggleLoading,
} from './actions'
import { translate } from 'src/Services/translation'
import { contentRangeToPagination } from 'src/Utils/EffectsUtil.ts'

function* fetchFields(props, { payload: { id } }) {
  try {
    const state = yield select()
    yield put(toggleLoading(true))
    const { pagination } = state.SystemFields

    const { data, headers } = yield call(apiMethods.get, `/forms/${id}/system_fields`, {
      ...pagination
    })

    yield put(setSystemFields({
      data,
      pagination: contentRangeToPagination(headers['content-range'], pagination)
    }))
    yield put(toggleLoading(false))
  } catch (e) {
    yield put(props.globalActions.handleError(e, 'fetchSystemFieldsFailed'))
  }
}

function* fetchRemoveField(props, { payload: { formId, id } }) {
  try {
    const { data } = yield call(apiMethods.delete, `/forms/${formId}/system_fields/${id}`)
    yield put(removeDeletedSystemField(id))
  } catch (e) {
    yield put(props.globalActions.handleError(e, 'deleteFailed'))
  }
}

function* fetchAddField(props, { payload: { formId, fieldBody } }) {
  const trans = translate(translationsFields)(path([ 'Root', 'user', 'language' ], yield select()))
  try {
    const { data } = yield call(apiMethods.create, `/forms/${formId}/system_fields`, {
      data: fieldBody
    })
    yield put(toggleFormModal())
    yield put(addNewSystemField(data))

    showSuccess('createSucceeded')

  } catch (e) {
    const errMsg = e.response?.data?.error?.message

    yield put(props.globalActions.handleError(e, errMsg))
  }
}

export default function* manageSystemFieldsSagaWatcher(props) {
  yield takeLatest(FETCH_FORM_SYSTEM_FIELDS, fetchFields, props)
  yield takeLatest(FETCH_REMOVE_SYSTEM_FIELD, fetchRemoveField, props)
  yield takeLatest(FETCH_ADD_SYSTEM_FIELD, fetchAddField, props)
}
