import React, { MouseEvent, useEffect, useState } from 'react'
import { Badge, Button, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { translate, translateConf } from 'src/Services/translation'
import { useNavigate } from 'react-router-dom'
import { FieldEvent } from 'src/Views/FormFiller/Types/Field'
import { Field } from 'src/Types/Field'
import File from 'src/Types/File'
import { ButtonSize } from 'src/Views/FormFiller/Types/Size'
import { StoreState } from 'src/Services/Store/reducers'
import translation from 'src/Views/FormFiller/translations'
import {
    getOpenedInstanceFieldById,
    getSystemFieldsStartingByName
} from 'src/Services/Selectors'

import {
    FIELD_MS_SANTE_PREFIX_CONTENT_TO_SEND, FIELD_MS_SANTE_PREFIX_SEND_BUTTON, FIELD_MS_SANTE_PREFIX_TRACKING_LIST,
} from '../../../../../Services/Constants/MsSante'
import { computeTrackingGlobalDetailedStatus, hasTrackingId, hasTrackingStatus } from '../../../utils/MSSante'
import {
    fieldEvent, saveInstanceFieldValue, toggleMsSanteModal, toggleMsSanteTrackingModal,
} from '../../../state/actions'

export interface SimpleHTMLEventTarget {
    id: string
    className: string
}

export interface SimpleHTMLEvent {
    target?: SimpleHTMLEventTarget
}

interface Props {
    field: Field,
    isLoading?: boolean
    isDisable?: boolean
    size?: ButtonSize
}

const MsSanteButtonField = ({
                                field = null,
                                isLoading = false,
                                isDisable = false,
                                size = ButtonSize.sm,
                            }: Props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const state = useSelector((state: StoreState) => state.FormFiller)
    const { configTranslations } = useSelector((state: StoreState) => state.Root)
    const { language } = useSelector((state: StoreState) => state.Root.user)
    const { openedInstance, openedInstanceForm, openedInstanceSystemFields, msSante } = state
    const instanceId = openedInstance?.id
    const isMsSanteEnabled = openedInstanceForm?.isMsSanteEnabled || false

    const [ msSanteContentToSend, setMsSanteContentToSend ] = useState<Field[]>([])

    const trans = translate(translation)(language)
    const transConf = translateConf(configTranslations)

    useEffect(() => {
        const contentSystemFields = getSystemFieldsStartingByName(openedInstanceSystemFields, FIELD_MS_SANTE_PREFIX_CONTENT_TO_SEND)
        if (contentSystemFields) {
            const field = getOpenedInstanceFieldById(openedInstance, contentSystemFields[0].field.id)
            field && setMsSanteContentToSend([ field ])
        }
    }, [ openedInstance.fields ])


    const onButtonClick = (e: MouseEvent<HTMLElement>) => {
        e.preventDefault()

        const target = (e.target as HTMLInputElement)

        // Minimize payload size by picking necessary event data
        const event: SimpleHTMLEvent = {
            target: {
                id: target.id,
                className: target.className,
            },
        }

        dispatch(saveInstanceFieldValue(
            instanceId,
            field.id,
            { clickedAt: new Date() },
            true
        ))

        dispatch(fieldEvent(field, FieldEvent.CLICK, event))

        if (isMsSanteEnabled) {
            dispatch(toggleMsSanteModal(true))
        }

    }


    const isMsSanteBtnDisable = () => {
        return !openedInstanceForm?.isMsSanteEnabled ||
                !msSanteContentToSend.length ||
                msSanteContentToSend.every(field => (field.value as File[]).length === 0)
    }

    return (
        <>

            <Button size={ size }
                    variant={ 'info' }
                    id={ `field${ field.id }` }
                    onClick={ onButtonClick }
                    disabled={ isDisable || isMsSanteBtnDisable() }
                    className={ 'me-2' }
            >
                {
                    isLoading &&
                    <Spinner as={ 'span' }
                             animation={ 'border' }
                             size={ 'sm' }
                             role={ 'status' }
                             aria-hidden={ 'true' }
                             className={ 'me-2' }
                    />
                }
                { transConf('FIELD')(field) }
            </Button>
            {
                hasTrackingStatus(openedInstance, msSante.tracking) &&
                <Badge
                    bg={ msSante.tracking && computeTrackingGlobalDetailedStatus(msSante.tracking)?.color }
                    onClick={ e => dispatch(toggleMsSanteTrackingModal(true)) }
                    role={ 'button' }
                >
                    { msSante.tracking && trans(`modal.msSanteStatus.${ computeTrackingGlobalDetailedStatus(msSante.tracking)?.label }`) }
                </Badge>
            }

        </>
    )
}

export default MsSanteButtonField
