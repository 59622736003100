import * as type from './actionTypes'

export const requestPatientCreation = patient => ({
  type: type.REQUEST_PATIENT_CREATION,
  payload: { patient }
})
export const resetPatientCreation = () => ({
  type: type.RESET_PATIENT_CREATION,
  payload: { }
})
export const searchSimilarPatients = (patient, fullPatient, navigate) => ({
  type: type.SEARCH_SIMILAR_PATIENTS,
  payload: { patient, fullPatient, navigate }
})
export const setSimilarPatients = (patients, targetPatient, navigate) => ({
  type: type.SET_SIMILAR_PATIENTS,
  payload: { patients, targetPatient, navigate }
})
export const createPatient = (patient, navigate) => ({
  type: type.CREATE_PATIENT,
  payload: { patient, navigate }
})
