export const RESET = '[LIST_EDIT]: RESET'
export const FETCH_ZMR = '[LIST_EDIT]: FETCH_ZMR'
export const FETCH_OPERATORS = '[LIST_EDIT]: FETCH_OPERATORS'
export const FETCH_FILTERS = '[LIST_EDIT]: FETCH_FILTERS'
export const FETCH_COLUMNS = '[LIST_EDIT]: FETCH_COLUMNS'
export const SAVE_ZMR = '[LIST_EDIT]: SAVE_ZMR'
export const SET_ZMR_SAVE_STATUS = '[LIST_EDIT]: SET_ZMR_SAVE_STATUS'
export const SET_ZMR = '[LIST_EDIT]: SET_ZMR'
export const SET_OPERATORS = '[LIST_EDIT]: SET_OPERATORS'
export const SET_LABEL = '[LIST_EDIT]: SET_LABEL'
export const SET_DESCRIPTION = '[LIST_EDIT]: SET_DESCRIPTION'
export const SET_DRAGGED_COLUMN = '[LIST_EDIT]: SET_DRAGGED_COLUMN'
export const SET_TYPE = '[LIST_EDIT]: SET_TYPE'
export const ADD_COLUMN = '[LIST_EDIT]: ADD_COLUMN'
export const ADD_FILTER = '[LIST_EDIT]: ADD_FILTER'
export const EDIT_COLUMN = '[LIST_EDIT]: EDIT_COLUMN'
export const REMOVE_COLUMN = '[LIST_EDIT]: REMOVE_COLUMN'
export const REMOVE_FILTER = '[LIST_EDIT]: REMOVE_FILTER'
export const SORT_COLUMN = '[LIST_EDIT]: SORT_COLUMN'
export const SET_SELECTED_REFERENCE_FIELD = '[LIST_EDIT]: SET_SELECTED_REFERENCE_FIELD'
export const SET_ADD_COLUMN_STATUS = '[LIST_EDIT]: SET_ADD_COLUMN_STATUS'
export const SET_SHOW_ADD_BUTTON_OPTION = '[LIST_EDIT]: SET_SHOW_ADD_BUTTON_OPTION'
export const SET_COLUMN_BEING_CREATED = '[LIST_EDIT]: SET_COLUMN_BEING_CREATED'
export const SET_COLUMN_BEING_EDITED = '[LIST_EDIT]: SET_COLUMN_BEING_EDITED'
export const SET_COLUMN_BEING_REMOVED = '[LIST_EDIT]: SET_COLUMN_BEING_REMOVED'
export const SET_IS_ADD_FILTER_MODAL_OPENED = '[LIST_EDIT]: SET_IS_ADD_FILTER_MODAL_OPENED'
export const SET_ADD_FILTER_STATUS = '[LIST_EDIT]: SET_ADD_FILTER_STATUS'
export const SET_FILTERS = '[LIST_EDIT]: SET_FILTERS'
export const SET_COLUMNS = '[LIST_EDIT]: SET_COLUMNS'
export const SET_FILTER_BEING_REMOVED = '[LIST_EDIT]: SET_FILTER_BEING_REMOVED'
export const SET_SELECTED_REFERENCE_FIELD_DETAILS = '[LIST_EDIT]: SET_SELECTED_REFERENCE_FIELD_DETAILS'
