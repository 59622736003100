export default {
  english: {
    fieldsAvailable: 'Fields available',
    targetForm: 'Target form',
    downloadHeaderButton: 'Download CSV header',
    dateTimeInstructionsPrefix: 'Accepted date formats',
    instructionsPrefix: 'Instructions',
    instructions: 'Download CSV files with comma delimited data. Use the column headers corresponding to the actual fields\'s system name. Columns not associated with a system field will be ignored.',
    parsingError: 'Error while parsing file',
    fieldsFound: 'Fields detected',
    fieldsUnrecognised: 'Fields unrecognised',
    dateTimeParseError: 'Date parsing error',
    taskStarted: 'Task started',
    taskSucceeded: 'Task succeeded',
    taskFailed: 'Task failed',
  },
  french: {
    fieldsAvailable: 'Champs disponibles',
    targetForm: 'Formulaire cible',
    instructionsPrefix: 'Instructions',
    downloadHeaderButton: 'Télécharger en-têtes CSV',
    instructions: 'Télécharger les fichiers CSV avec des données délimitées par des virgules. Utiliser les en-têtes de colonne correspondants aux nom système des champs. Les colonnes non associées à un champ système ne seront pas prises en compte.',
    parsingError: 'Erreur lors de l\'analyse du fichier',
    fieldsFound: 'Champs compatibles',
    fieldsUnrecognised: 'Champs non-reconnus',
    dateTimeParseError: 'Format de la date incorrect',
    dateTimeInstructionsPrefix: 'Formats de dates acceptés',
    taskStarted: 'Tâche démarrée',
    taskSucceeded: 'Tâche réussie',
    taskFailed: 'Tâche échouée',
  }
}
