import React from 'react'
import { useDispatch } from 'react-redux'
import { Col, Dropdown, Row } from 'react-bootstrap'
import { deleteRow } from '../../state/actions'

const RowHeader = ({ trans, row, sectionId }) => {
  const dispatch = useDispatch()

  const handleRowDeleteButtonClick = () => dispatch(deleteRow(sectionId, row.id))

  return <header>
    <Row className={'mb-1'}>
      <Col>
        <i className="fa-solid fa-grip-lines cursor-drag me-1"/>
        <Dropdown className={ 'd-inline' }>
          <Dropdown.Toggle variant={ 'transparent' } id={ `rowOptions${ row.id }` } size={ 'sm' } className={ 'p-0' }>
            <i className="fas fa-cog"/>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={ handleRowDeleteButtonClick }>{ trans('delete') }</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </Row>
  </header>
}

export default RowHeader
