import { translate } from 'src/Services/translation'
import translation from 'src/Views/FormFiller/translations'
import { getFieldsBySystemFieldName } from 'src/Views/FormFiller/state/selectors'
import {
  SWP_OPEN_MONITOR_FIELD_SYSTEM_NAME,
  SWP_OPEN_PLAYBACK_FIELD_SYSTEM_NAME,
  SWP_OPEN_RECORD_FIELD_SYSTEM_NAME
} from 'src/Services/Constants/Swp'
import apiMethods from 'src/Services/api/apiMethods'
import {
  initiateSwpAcquisition,
  pushCustomizationOrder,
  redirectToSwpDynamicLink,
  saveInstance,
  setSwpFeatureStatus
} from 'src/Views/FormFiller/state/actions'
import { addStoreData } from 'src/Services/ScriptExecutor/state/actions'
import { showError, showInfo, showSuccess, showWarning } from 'src/Services/notifier/actions'
import * as types from 'src/Views/FormFiller/state/actionTypes'
import { call, cancel, put, select, takeLatest } from 'redux-saga/effects'
import { GlobalActions } from 'src/Types/GlobalActions'
import { StoreState } from 'src/Services/Store/reducers'
import { Action } from 'src/Views/FormFiller/Types/Field'
import { Session, SessionStatus, SwpFeatureStatus } from 'src/Views/FormFiller/Types/Swp'
import { AxiosResponse } from 'axios'
import { FormElementType } from 'src/Views/FormFiller/Types/ScriptFormCustomizationOrder'

function* doInitSwp(props: GlobalActions) {
  const state = ((yield select()) as StoreState).FormFiller
  const { user: { language } } = ((yield select()) as StoreState).Root
  const { openedInstance: instance } = state

  const trans = translate(translation)(language)

  const openRecordButtons = getFieldsBySystemFieldName(state, SWP_OPEN_RECORD_FIELD_SYSTEM_NAME)
  const openPlaybackButtons = getFieldsBySystemFieldName(state, SWP_OPEN_PLAYBACK_FIELD_SYSTEM_NAME)
  const openMonitorButtons = getFieldsBySystemFieldName(state, SWP_OPEN_MONITOR_FIELD_SYSTEM_NAME)

  try {
    const { data } = yield call(
        apiMethods.get,
        `/middlewares/swp/acquisition/${ instance.id }`,
        { withAccessToken: true }
    )
    let session: Session|null = data

    const accessToken = session?.accessToken || null

    const { data: swpConfig } = yield call(apiMethods.get, '/middlewares/swp/config')

    if (accessToken && swpConfig.audience) {
      // This fetch is necessary to have the auth cookie set
      /** https://bioserenity.atlassian.net/wiki/spaces/CLOUD/pages/1718190603/Acquisition+Session+API+usage */
      const response: AxiosResponse = yield call(apiMethods.get, '/acquisition-session/get', {}, {
        baseURL: swpConfig.audience,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${ accessToken }`
        }
      })
      session = response?.data || session
    }

    if (session?.sessionId) {
      yield put(addStoreData('session', session))
      for (const openRecordButton of openRecordButtons)
        yield put(pushCustomizationOrder(
            FormElementType.FIELD, openRecordButton.id, Action.SHOW_INFO,
            { value: trans('swp.sessionAvailableForRecording'), variant: 'success' })
        )
    } else if (accessToken && !session) {
      //action.customizeForm(FormElementType.FIELD, openRecordButton.id, 'HIDE_INFO')
    }

    // Update playback button
    if (session?.sessionId && session.canReadDataAsDeferred && session.productContext?.product) {
      yield put(addStoreData(
          'playbackUrl',
          `${ swpConfig.playbackUrl }/${ session?.productContext?.product.toLowerCase() }/${ session.sessionId }`)
      )
      for (const openPlaybackButton of openPlaybackButtons) {
        yield put(pushCustomizationOrder(FormElementType.FIELD, openPlaybackButton.id, Action.SHOW_INFO,
                                         { value: trans('swp.sessionAvailableForPlayback'), variant: 'success' }))
        yield put(pushCustomizationOrder(FormElementType.FIELD, openPlaybackButton.id, Action.ENABLE))
      }
    } else {
      for (const openPlaybackButton of openPlaybackButtons) {
        yield put(pushCustomizationOrder(FormElementType.FIELD, openPlaybackButton.id, Action.SHOW_INFO,
                                         { value: trans('swp.sessionUnavailableForPlayback'), variant: 'warning' }))
        yield put(pushCustomizationOrder(FormElementType.FIELD, openPlaybackButton.id, Action.DISABLE))
      }
    }

    // Update monitor button
    if (session?.sessionId && (session.canReadDataAsLive || session.status === SessionStatus.RUNNING)) {
      yield put(addStoreData('monitorUrl', `${ swpConfig.monitorUrl }/${ session.sessionId }`))

      for (const openMonitorButton of openMonitorButtons) {
        yield put(pushCustomizationOrder(FormElementType.FIELD, openMonitorButton.id, Action.SHOW_INFO,
                                         { value: trans('swp.sessionAvailableForMonitoring'), variant: 'success' }))
        yield put(pushCustomizationOrder(FormElementType.FIELD, openMonitorButton.id, Action.ENABLE))
      }
    } else {
      for (const openMonitorButton of openMonitorButtons) {
        yield put(pushCustomizationOrder(FormElementType.FIELD, openMonitorButton.id, Action.SHOW_INFO,
                                         { value: trans('swp.sessionUnavailableForMonitoring'), variant: 'warning' }))
        yield put(pushCustomizationOrder(FormElementType.FIELD, openMonitorButton.id, Action.DISABLE))
      }
    }

  } catch (e) {
    for (const openRecordButton of openRecordButtons) {
      yield put(pushCustomizationOrder(
        FormElementType.FIELD,
        openRecordButton.id,
        Action.SHOW_INFO,
        { value: trans('swp.errorOnSessionFetch'), variant: 'danger' }
      ))
      yield put(pushCustomizationOrder(FormElementType.FIELD, openRecordButton.id, Action.DISABLE))
    }

    for (const openPlaybackButton of openPlaybackButtons) {
      yield put(pushCustomizationOrder(FormElementType.FIELD, openPlaybackButton.id, Action.SHOW_INFO,
        { value: trans('swp.sessionUnavailableForPlayback'), variant: 'warning' }))
      yield put(pushCustomizationOrder(FormElementType.FIELD, openPlaybackButton.id, Action.DISABLE))
    }

    for (const openMonitorButton of openMonitorButtons) {
      yield put(pushCustomizationOrder(FormElementType.FIELD, openMonitorButton.id, Action.SHOW_INFO,
        { value: trans('swp.sessionUnavailableForMonitoring'), variant: 'warning' }))
      yield put(pushCustomizationOrder(FormElementType.FIELD, openMonitorButton.id, Action.DISABLE))
    }

    yield put(props.globalActions.handleError(e))
    yield put(setSwpFeatureStatus(SwpFeatureStatus.DOWN))
    yield cancel()
  }

  yield put(setSwpFeatureStatus(SwpFeatureStatus.READY))
}

function* doInitiateSwpAcquisition(props: GlobalActions) {
  const state = ((yield select()) as StoreState).FormFiller
  const { openedInstance: instance } = state

  const openRecordButtons = getFieldsBySystemFieldName(state, SWP_OPEN_RECORD_FIELD_SYSTEM_NAME)

  try {
    yield call(apiMethods.get, `/middlewares/swp/initiate_acquisition/${ instance.id }`)

    yield put(showSuccess('Initiated acquisition successfully'))
    yield put(showInfo('Now trying to launch acquisition...'))

    yield put(redirectToSwpDynamicLink())
  } catch (e) {
    const errorCode = e?.response?.data?.error?.code
    const errorMessage = errorCode === 'patientIncomplete'
                         ? 'Patient is incomplete. Please fill all required fields.'
                         : 'Initiate acquisition failed'

    yield put(showError(errorMessage))
    for (const openRecordButton of openRecordButtons) {
      yield put(pushCustomizationOrder(FormElementType.FIELD, openRecordButton.id, Action.STOP_LOADING))

      if (errorCode !== 'patientIncomplete')
        yield put(pushCustomizationOrder(
            FormElementType.FIELD, openRecordButton.id, Action.SHOW_INFO,
            { value: 'Error while fetching acquisition informations.', variant: 'danger' }
        ))
    }
    yield put(props.globalActions.handleError(e))
  }
}

function* doRedirectToSwpDynamicLink(props: GlobalActions) {
  const state = ((yield select()) as StoreState).FormFiller
  const { openedInstance: instance } = state

  const openRecordButtons = getFieldsBySystemFieldName(state, SWP_OPEN_RECORD_FIELD_SYSTEM_NAME)

  try {
    const { data } = yield call(apiMethods.get, `/middlewares/swp/dynamic_link/${ instance.id }`)

    yield put(showSuccess('You will be redirect to acquisition...'))
    const dynamicLink = decodeURI(data.dynamicLink)

    setTimeout(() => {
      //window.open(dynamicLink, '_blank')
      window.location.href = dynamicLink
    }, 2000)

    for (const openRecordButton of openRecordButtons)
      yield put(pushCustomizationOrder(FormElementType.FIELD, openRecordButton.id, Action.STOP_LOADING))

  } catch (error) {
    yield put(showError('Acquisition launch failed'))
    for (const openRecordButton of openRecordButtons) {
      yield put(pushCustomizationOrder(FormElementType.FIELD, openRecordButton.id, Action.STOP_LOADING))
      yield put(pushCustomizationOrder(
          FormElementType.FIELD, openRecordButton.id, Action.SHOW_INFO,
          { value: 'Error while fetching acquisition informations.', variant: 'danger' }
      ))
    }
    yield put(props.globalActions.handleError(error))
  }
}

function* openSwpRecord(props: GlobalActions) {
  const { store } = ((yield select()) as StoreState).ScriptExecutor
  const state = ((yield select()) as StoreState).FormFiller
  const { openedInstance: instance } = state

  const openRecordButtons = getFieldsBySystemFieldName(state, SWP_OPEN_RECORD_FIELD_SYSTEM_NAME)

  for (const openRecordButton of openRecordButtons)
    yield put(pushCustomizationOrder(FormElementType.FIELD, openRecordButton.id, Action.START_LOADING))

  const session = store.find(_ => _.id === 'session')

  yield put(saveInstance(instance.id, true))

  if (session?.data?.sessionId)
    yield put(redirectToSwpDynamicLink())
  else
    yield put(initiateSwpAcquisition())
}

function* openSwpPlayback(props: GlobalActions) {
  const { store } = ((yield select()) as StoreState).ScriptExecutor

  const playbackUrl = store.find(_ => _.id === 'playbackUrl')

  if (playbackUrl?.data)
    window.open(playbackUrl.data, '_blank')
  else {
    yield put(showWarning('Playback is not ready yet'))
  }
}

function* openSwpMonitor(props: GlobalActions) {
  const { store } = ((yield select()) as StoreState).ScriptExecutor

  const monitorUrl = store.find(_ => _.id === 'monitorUrl')

  if (monitorUrl?.data)
    window.open(monitorUrl.data, '_blank')
  else {
    yield put(showWarning('Monitor is not ready yet'))
  }
}

export default function* formFillerSwpSagaWatcher(props: GlobalActions) {
  yield takeLatest(types.INIT_SWP, doInitSwp, props)
  yield takeLatest(types.OPEN_SWP_RECORD, openSwpRecord, props)
  yield takeLatest(types.OPEN_SWP_PLAYBACK, openSwpPlayback, props)
  yield takeLatest(types.OPEN_SWP_MONITOR, openSwpMonitor, props)
  yield takeLatest(types.INITIATE_SWP_ACQUISITION, doInitiateSwpAcquisition, props)
  yield takeLatest(types.REDIRECT_TO_SWP_DYNAMIC_LINK, doRedirectToSwpDynamicLink, props)
}
