import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Auth from 'src/Views/Auth'
import { AppLoader } from 'src/Components/AppLoader'
import { StoreState } from 'src/Services/Store/reducers'
import { useSelector } from '../Store'
import { getCurrentUser } from '../Store/Root/actions'

const Firewall = ({ children }: any) => {
  const dispatch = useDispatch()

  const { isFetchingUser, user, isAuthenticated } = useSelector((state: StoreState) => state.Root)

  useEffect(() => {
    if (!isFetchingUser && !user.id && isAuthenticated === null)
      dispatch(getCurrentUser())
  }, [])

  if (isAuthenticated)
    return children
  else if (isAuthenticated === null)
    return <AppLoader/>
  else
    return <Auth/>
}

export default Firewall
