export const RESET = '[USER_FORM] RESET'

export const FETCH_USER = '[USER_FORM] FETCH_USER'

export const SET_USER = '[USER_FORM] SET_USER'
export const SET_NEW_USER = '[USER_FORM] SET_NEW_USER'

export const CREATE_USER = '[USER_FORM] CREATE_USER'
export const UPDATE_USER = '[USER_FORM] UPDATE_USER'
export const DELETE_USER = '[USER_FORM] DELETE_USER'
export const BLOCK_USER = '[USER_FORM] BLOCK_USER'
export const UNBLOCK_USER = '[USER_FORM] UNBLOCK_USER'

export const CREATE_PATIENT_USER = '[USER_FORM] CREATE_PATIENT_USER'
