import React, { useState } from 'react'
import {
  changePage,
  changeLimit,
  searchFieldType,
  toggleDeleteFieldType, toggleUpdateFieldType, fetchFieldTypes
} from '../state/actions'
import { translate } from '../../../Services/translation'
import translation from '../translations'
import Table from '../../../Components/NavTable'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ButtonGroup } from 'react-bootstrap'

const FieldTypesTable = () => {

  const dispatch = useDispatch()
  const {
    fieldTypes,
    pagination,
    loading
  } = useSelector(state => state.FieldTypes)
  const { language } = useSelector(state => state.Root?.user)
  const trans = translate(translation)(language)
  const [ fetchListRequest, setFetchListRequest ] = useState(null)

  const onDeleteClick = fieldType => dispatch(toggleDeleteFieldType(fieldType))
  const onEditClick = fieldType => dispatch(toggleUpdateFieldType(fieldType))

  const columnHeaders = [ trans('name'), trans('form.icon'), trans('form.baseFieldType'), trans('actions') ]
  const rows = getRows(fieldTypes, trans, onDeleteClick, onEditClick)

  const applyNewFilters = () => {
    clearTimeout(fetchListRequest)
    setFetchListRequest(setTimeout(() => dispatch(fetchFieldTypes()), 1000))
  }

  const onSearchInputChange = e => {
    e.preventDefault()
    dispatch(searchFieldType(e.target.value))
    applyNewFilters()
  }

  const onPageClick = (currentPage, page) => {
    if (currentPage === page)
      return

    dispatch(changePage(page))
    applyNewFilters()
  }

  const onLimitChange = limit => {
    dispatch(changeLimit(limit))
    applyNewFilters()
  }

  return <Table pagination={ pagination }
                rows={ rows }
                columnHeaders={ columnHeaders }
                onSearchInputChange={ onSearchInputChange }
                onLimitChange={ onLimitChange }
                onPageClick={ onPageClick }
                isLoading={ loading }
  />
}

export const ActionCell = ({
  onDeleteClick = () => {
  },
  onEditClick = () => {
  },
  trans
}) =>
  (
    <ButtonGroup>
      <Button variant={ 'outline-info' } size={ 'sm' } onClick={ onDeleteClick }>
        <i className="fas fa-trash"/> { trans('delete') }
      </Button>
      <Button variant={ 'info' } size={ 'sm' } onClick={ onEditClick }>
        <i className="fas fa-edit"/> { trans('edit') }
      </Button>
    </ButtonGroup>
  )

const getRows = (
  fieldTypes,
  trans,
  onDeleteClick,
  onEditClick
) =>
  fieldTypes.map(fieldType => [
    <>{ fieldType.name }</>,
    <i className={ fieldType.icon }/>,
    <>{ trans(`baseFieldTypes.${ fieldType.baseFieldType }`) }</>,
    <ActionCell onDeleteClick={ () => onDeleteClick(fieldType) }
                onEditClick={ () => onEditClick(fieldType) }
                trans={ trans }
    />
  ])

export default FieldTypesTable
