import React from 'react'
import { useSelector } from 'react-redux'
import { AppLoader } from '../../Components/AppLoader/index'

const Dictionary = ({ children }) => {
  const { isReady } = useSelector(state => state.Dictionary)

  return isReady ? children : <AppLoader/>
}

export default Dictionary
