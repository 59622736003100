import { put, takeLatest, select, cancel, call } from 'redux-saga/effects'
import { requestConfigTranslationsFetch } from 'src/Services/Store/Root/actions'
import { fetchDictionaries } from 'src/Services/Dictionary/state/actions'
import { GlobalActions } from 'src/Types/GlobalActions'
import { Action, StoreState } from 'src/Services/Store/reducers'
import { logout } from 'src/Services/Firewall/utils'
import apiMethods from 'src/Services/api/apiMethods'
import { showWarning } from 'src/Services/notifier/actions'
import { AUTH_GRANTED, GET_NON_PROD_AUTH_TOKEN, REQUEST_LOGOUT } from './actionTypes'

function* onAuthenticated(props: GlobalActions, { payload: { isSilent = false } }: Action) {
  try {
    if (!isSilent) {
      yield put(requestConfigTranslationsFetch())
      yield put(fetchDictionaries())
    }
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'userAuthFailed'))
  }
}

function* doLogout(props: GlobalActions, { payload: { useSoftLogout = false } }: Action) {
  const { config, isAuthenticated } = ((yield select()) as StoreState).Root

  if (!isAuthenticated)
    yield cancel()

  logout(config, useSoftLogout)
}

function* getNonProdAuthToken(props: GlobalActions, { payload: { username, password } }: Action) {
  try {
    const { data } = yield call(apiMethods.create, '/authenticate/generate_access_token', { data: { username, password } })
    location.reload()
  } catch (error) {
    if (error.response.status === 401)
      yield put(showWarning('loginCredentialsRefused'))
    else
      yield put(props.globalActions.handleError(error, error.toString()))
  }
}

export default function* authSagaWatcher(props: GlobalActions) {
  yield takeLatest(AUTH_GRANTED, onAuthenticated, props)
  yield takeLatest(REQUEST_LOGOUT, doLogout, props)
  yield takeLatest(GET_NON_PROD_AUTH_TOKEN, getNonProdAuthToken, props)
}
