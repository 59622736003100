import { path } from 'ramda'
import { call, cancel, put, select, takeLatest } from 'redux-saga/effects'
import apiMethods from 'src/Services/api/apiMethods'
import { showSuccess } from 'src/Services/notifier/actions'
import { getFieldIdFromReferenceFieldId } from 'src/Utils/index.ts'
import { SaveStatus } from 'src/Components/SaveButton'
import {
  fetchColumns,
  fetchFilters,
  setAddColumnStatus,
  setAddFilterStatus,
  setColumnBeingRemoved, setColumnBeingEdited,
  setColumns,
  setFilterBeingRemoved,
  setFilters,
  setIsAddFilterModalOpened,
  setOperators, setSaveStatus,
  setZmr, setColumnBeingCreated, setSelectedReferenceFieldDetails
} from './actions'
import {
  ADD_COLUMN,
  ADD_FILTER,
  FETCH_COLUMNS,
  FETCH_FILTERS,
  FETCH_OPERATORS,
  FETCH_ZMR, EDIT_COLUMN,
  REMOVE_COLUMN, REMOVE_FILTER, SAVE_ZMR,
  SET_SELECTED_REFERENCE_FIELD, SORT_COLUMN
} from './actionTypes'
import { parseFieldId, parseReferenceId } from '../utils'

const STORE = 'ListEdit'

function* addColumn(props, { payload }) {
  try {
    const state = yield select()
    const { id, columnBeingCreated } = state[STORE]
    const { data } = yield call(apiMethods.create, `/lists/${ id }/columns`, {
      data: columnBeingCreated
    })
    yield put(fetchColumns(data.list))
    yield put(setColumnBeingCreated(null))
    yield put(setAddColumnStatus('success'))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'addColumnFailed'))
  }
}

function* addFilter(props, { payload }) {
  try {
    let { value, fieldId, alias, operator } = payload

    const { data } = yield call(apiMethods.create, `/lists/${ payload.listId }/filters`, {
      data: {
        field: parseFieldId(fieldId),
        referenceField: parseReferenceId(fieldId),
        alias,
        operator,
        value
      }
    })
    yield put(fetchFilters(data.list.id))
    yield put(setIsAddFilterModalOpened(false))
    yield put(setAddFilterStatus('success'))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'addFilterFailed'))
  }
}

function* loadColumns(props, { payload }) {
  try {
    const { data } = yield call(apiMethods.get, `/lists/${ payload }/columns`)
    yield put(setColumns(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'fetchColumnsFailed'))
  }
}

function* fetchFiltersHandler(props, { payload }) {
  try {
    const { data } = yield call(apiMethods.get, `/lists/${ payload }/filters`)
    yield put(setFilters(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'fetchFiltersFailed'))
  }
}

function* fetchOperators(props, { payload }) {

  if (!payload)
    yield cancel()

  const state = yield select()
  const isAddFilterModalOpened = path([ 'ListEdit', 'isAddFilterModalOpened' ], state)

  if (!isAddFilterModalOpened)
    yield cancel()

  try {
    const { data } = yield call(apiMethods.get, `/lists/filters/valid/${ parseFieldId(payload.id) }`)
    yield put(setOperators(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'fetchOperatorsFailed'))
  }
}

function* fetchSelectedFieldDetails(props, { payload: referenceField }) {

  if (!referenceField)
    yield cancel()

  const fieldId = getFieldIdFromReferenceFieldId(referenceField.id)

  const { data } = yield call(apiMethods.get, `/fields/${ fieldId }`)

  yield put(setSelectedReferenceFieldDetails(data))
}

function* fetchZmr(props, { payload }) {
  try {
    const { data } = yield call(apiMethods.get, `/lists/${ payload }`)
    yield put(setZmr({ ...data, columns: data.listColumns }))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'fetchZmrFailed'))
  }
}

function* editColumn(props, { payload }) {
  try {
    const state = yield select()
    const { id, columnBeingEdited } = state[STORE]

    const columnId = columnBeingEdited.id
    delete columnBeingEdited.id

    const { data } = yield call(apiMethods.update, `/lists/${ id }/columns/${ columnId }`, {
      data: columnBeingEdited
    })
    yield put(fetchColumns(data.list))
    yield put(setColumnBeingEdited(null))
    yield put(setAddColumnStatus('success'))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'editColumnFailed'))
  }
}

function* removeColumn(props, { payload }) {
  try {
    const state = yield select()
    const { id, columnBeingRemoved } = state[STORE]

    yield call(apiMethods.delete, `/lists/${ id }/columns/${ columnBeingRemoved }`)
    yield put(fetchColumns(id))
    yield put(setColumnBeingRemoved(null))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'removeColumnFailed'))
  }
}

function* removeFilter(props, { payload }) {
  try {
    const { listId, filterId } = payload

    yield call(apiMethods.delete, `/lists/${ listId }/filters/${ filterId }`)
    yield put(fetchFilters(listId))
    yield put(setFilterBeingRemoved(''))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'removeFilterFailed'))
  }
}

function* saveZmr(props) {
  try {
    yield put(setSaveStatus(SaveStatus.ONGOING))

    const { id, label, description, type, showAddButton } = (yield select()).ListEdit

    yield call(apiMethods.update, `/lists/${ id }`, {
      data: { label, description, type, showAddButton }
    })
    yield put(setSaveStatus(SaveStatus.SUCCESS))
    yield put(showSuccess('updateSucceeded'))
  } catch (error) {
    yield put(setSaveStatus(SaveStatus.FAIL))
    yield put(props.globalActions.handleError(error, 'updateFailed'))
  }
}

function* sortColumn(props, { payload }) {
  const { listId, draggedColumn, targetColumn } = payload

  try {
    yield call(apiMethods.update, `/lists/${ listId }/columns/sort`, {
      data: [
        {
          id: draggedColumn.id,
          sortOrder: targetColumn.sortOrder
        }, {
          id: targetColumn.id,
          sortOrder: draggedColumn.sortOrder
        }
      ]
    })
    yield put(fetchColumns(listId))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'sortColumnFailed'))
  }
}

export default function* formFillerSagaWatcher(props) {
  yield takeLatest(ADD_COLUMN, addColumn, props)
  yield takeLatest(ADD_FILTER, addFilter, props)
  yield takeLatest(FETCH_COLUMNS, loadColumns, props)
  yield takeLatest(FETCH_FILTERS, fetchFiltersHandler, props)
  yield takeLatest(FETCH_OPERATORS, fetchOperators, props)
  yield takeLatest(SET_SELECTED_REFERENCE_FIELD, fetchSelectedFieldDetails, props)
  yield takeLatest(SET_SELECTED_REFERENCE_FIELD, fetchOperators, props)
  yield takeLatest(FETCH_ZMR, fetchZmr, props)
  yield takeLatest(EDIT_COLUMN, editColumn, props)
  yield takeLatest(REMOVE_COLUMN, removeColumn, props)
  yield takeLatest(REMOVE_FILTER, removeFilter, props)
  yield takeLatest(SAVE_ZMR, saveZmr, props)
  yield takeLatest(SORT_COLUMN, sortColumn, props)
}
