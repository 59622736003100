import React, { useState } from 'react'
import { Button, ButtonGroup, Col, Collapse, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { deleteCustomizationOrder } from '../state/actions'
import CustomizationConditionDisplay from './CustomizationConditionDisplay'

const CustomizationOrderDisplay = ({ trans, order, onUpdateRequest }) => {
  const dispatch = useDispatch()

  const { form, isCustomizationOrderLoading } = useSelector(state => state.FormEditor.formReducer)

  const [ itemOpen, setItemOpen ] = useState(false)

  const onDeleteClick = () => dispatch(deleteCustomizationOrder(order))
  const onUpdateClick = () => onUpdateRequest(order)

  return <>
    <div className="d-flex justify-content-between">
      <div className={'cursor-pointer'} onClick={ e => setItemOpen(!itemOpen) }>
        <div>
          <i className={ `fas fa-chevron-${ itemOpen ? 'up' : 'down' } me-2` }/>
          <b className={ 'text-primary me-1' }>{ order.label }</b>
          <span>{ order.action }</span>
        </div>
        <div>
          <span>
          {/* <span>{ trans('form.customizationOrder.targetFields') } :&nbsp;</span> */}
          { order.targetFields.map((f, i) =>
            <span key={ f.id }><b>{ f.systemName }</b>{ ++i < order.targetFields.length ? ', ' : '' }</span>) }</span>
        </div>
      </div>
      <ButtonGroup>
        <Button variant="outline-primary" size="sm" disabled={isCustomizationOrderLoading} onClick={onUpdateClick}>
          <i className="fas fa-lg fa-edit"/>
        </Button>
        <Button variant="outline-danger" size="sm" disabled={isCustomizationOrderLoading} onClick={onDeleteClick}>
          <i className="fas fa-lg fa-trash"/>
        </Button>
      </ButtonGroup>
    </div>
    <Collapse in={ itemOpen }>
      <div className={'mt-2 white-card'}>
        { !order.conditions.length && <span>{ trans('none') }</span> }
        { order.conditions.map((c, i) =>
          <Row key={ i }>
            <Col>
              <CustomizationConditionDisplay trans={ trans } condition={ c }/>
              { ++i < order.conditions.length && <hr className={'my-1'} /> }
            </Col>
          </Row>
        ) }
      </div>
    </Collapse>
  </>
}

export default CustomizationOrderDisplay
