import React from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { Form } from 'react-bootstrap'
import { FIELD_BASE_TYPE_DATE_TIME, FIELD_BASE_TYPE_REFERENCE } from 'src/Services/Constants'
import LocalizedDatepicker from 'src/Components/LocalizedDatepicker'
import { translate } from 'src/Services/translation'
import { FieldOption } from 'src/Types/FieldOption'
import translations from './translations'

const FieldValueSetter = ({ field, value, onChange, size = 'md' }) => {
  const { language } = useSelector(state => state.Root.user)

  const trans = translate(translations)(language)

  const getFieldOptionValue = () =>
    field.options[FieldOption.VALUES].find(option => option.systemName === value) || null

  if (!field?.type || !field?.options)
    return <></>
  else if (field.type.baseFieldType === FIELD_BASE_TYPE_DATE_TIME)
    return (
      <LocalizedDatepicker
        showDateOnly={ field.options[FieldOption.SHOW_DATE] && !field.options[FieldOption.SHOW_TIME] }
        showTimeOnly={ !field.options[FieldOption.SHOW_DATE] && field.options[FieldOption.SHOW_TIME] }
        id={ `fieldValueSetter${ field.id }` }
        name={ `fieldValueSetter${ field.id }` }
        placeholder={ trans('valuePlaceholder') }
        size={ size }
        selected={ value }
        onChange={ onChange }
        isClearable
      />
    )
  else if (field.options[FieldOption.VALUES])
    return <Select size={ size }
                   id={ `fieldValueSetter${ field.id }` }
                   name={ `fieldValueSetter${ field.id }` }
                   value={ getFieldOptionValue() }
                   onChange={ f => onChange(f?.systemName || null) }
                   placeholder={ trans('valuePlaceholder') }
                   options={ field.options[FieldOption.VALUES] }
                   getOptionValue={ field => field.systemName }
                   getOptionLabel={ field => field.systemName }
                   isClearable
    />
  else if (field.type.baseFieldType === FIELD_BASE_TYPE_REFERENCE)
    return <Form.Control type="number" size={ size } min={1}
                         placeholder={ trans('referenceValuePlaceholder') }
                         id={ `fieldValueSetter${ field.id }` }
                         name={ `fieldValueSetter${ field.id }` }
                         value={ value || '' }
                         onChange={ e => onChange(e.target.value) }
    />
  else
    return <Form.Control type="text" size={ size }
                         placeholder={ trans('valuePlaceholder') }
                         id={ `fieldValueSetter${ field.id }` }
                         name={ `fieldValueSetter${ field.id }` }
                         value={ value || '' }
                         onChange={ e => onChange(e.target.value) }
    />
}

export default FieldValueSetter
