export default {
  english: {
    header: {
      timestamp: 'DATE',
      code: 'ACTION',
      user: 'AUTHOR',
      context: 'TYPE',
      ip: 'IP',
      workflowId: 'Workflow',
      impersonator: 'IMPERSONATE BY',
      data: 'CHANGES'
    },
    empty: 'NONE'
  },
  french: {
    header: {
      timestamp: 'DATE',
      code: 'ACTION',
      user: 'AUTEUR',
      context: 'TYPE',
      ip: 'IP',
      workflowId: 'Workflow',
      impersonator: 'Usurpé par',
      data: 'MODIFICATIONS'
    },
    empty: 'AUCUN'
  }
}
