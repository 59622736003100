import React, { useEffect } from 'react'
import {
  enqueueSnackbar,
  VARIANT_ERROR,
  VARIANT_INFO,
  VARIANT_SUCCESS,
  VARIANT_WARNING
} from '../notifier/actions'
import Axios from '../../Services/api/apiMethods'
import { useDispatch, useSelector } from 'react-redux'
import { SCRIPT_ACTIONS, FIELD_ACTION_OPTIONS, FORM_ELEMENT_TYPES } from '../Constants'
import { handleError, logMessage, navigateTo } from '../Store/Root/actions'
import {
  pushCustomizationOrder, saveInstance,
} from 'src/Views/FormFiller/state/actions.ts'
import { addStoreData, updateStoreData } from './state/actions'

const ScriptExecutor = () => {
  const dispatch = useDispatch()

  const getAction = () => {
    const action = {}

    action.saveInstance = (isSilent = false) => {
      dispatch(saveInstance(instance.id, isSilent))
    }

    action.navigateTo = path => {
      if (!typeof path === 'string')
        return dispatch(logMessage(`NavigateTo: path '${ path }' is not a string.`, 'warning'))

      dispatch(navigateTo(path))
    }

    action.notify = (message, variant = VARIANT_INFO) => {
      if (!typeof message === 'string')
        return dispatch(logMessage(`Notify: message '${ message }' is not a string.`, 'warning'))
      if (![ VARIANT_INFO, VARIANT_ERROR, VARIANT_SUCCESS, VARIANT_WARNING ].includes(variant))
        return dispatch(logMessage(`Notify: variant '${ variant }' do not exist.`, 'warning'))

      dispatch(enqueueSnackbar({ message, options: { variant } }))
    }

    action.log = (message, variant = VARIANT_INFO) => {
      if (!typeof message === 'string')
        return dispatch(logMessage(`Log: message '${ message }' is not a string.`, 'warning'))
      if (![ VARIANT_INFO, VARIANT_ERROR, VARIANT_SUCCESS, VARIANT_WARNING ].includes(variant))
        return dispatch(logMessage(`Log: variant '${ variant }' do not exist.`, 'warning'))

      dispatch(logMessage(message, variant))
    }

    action.customizeForm = (elementType, elementId, action, options = {}) => {
      if (!FORM_ELEMENT_TYPES.includes(elementType))
        return dispatch(logMessage(`EmitCustomizeForm: element '${ elementType }' do not exist.`, 'warning'))
      if (!SCRIPT_ACTIONS.includes(action))
        return dispatch(logMessage(`EmitCustomizeForm: action '${ action }' do not exist.`, 'warning'))
      if (!Number.isInteger(elementId))
        return dispatch(logMessage(`EmitCustomizeForm: elementId '${ elementId }' is not a integer.`, 'warning'))
      for (const option in options)
        if (!FIELD_ACTION_OPTIONS.includes(option))
          return dispatch(logMessage(`EmitCustomizeForm: option '${ option }' do not exist.`, 'warning'))

      dispatch(pushCustomizationOrder(elementType, elementId, action, options))
    }

    action.addStoreData = (id, data) => dispatch(addStoreData(id, data))

    action.updateStoreData = (id, data) => dispatch(updateStoreData(id, data))

    action.executeWorkflow = (name, data = {}) =>
      Axios.get(`/workflow/actions/execute/${name}`, { ...data, instanceId: instance.id || null })

    return action
  }

  const getUtility = () => {
    const utility = {}

    utility.getFieldBySystemName = systemName => {

      if (!instance?.fields || !systemName || typeof systemName !== 'string')
        return null

      // Allows to use only prefix of system name
      systemName = systemName.split('#')[0]

      const fieldId = Object.keys(instance.fields)
        .find(id => instance.fields[id].systemName.toUpperCase().split('#')[0] === systemName.toUpperCase())

      if (!fieldId)
        dispatch(handleError(new Error(`Field not found for system name : '${ systemName }'.`)))

      return fieldId ? {
        ...instance.fields[fieldId],
        id: fieldId
      } : null
    }

    return utility
  }

  // Script parameters
  const { scriptExecutionId, script, event, store } =
    useSelector(state => state.ScriptExecutor)
  const user = useSelector(state => state.Root.user)
  const formFiller = useSelector(state => state.FormFiller)
  const instance = formFiller?.openedInstance
  const patient = useSelector(state => null)
  const action = getAction()
  const utility = getUtility()

  // Script execution
  useEffect(() => {
    try {
      if (script) {
        const executeScript =
          new Function('e', 'store', 'user', 'instance', 'patient', 'Axios', 'action', 'utility', script)
        executeScript(event, store, user, instance, patient, Axios, action, utility)
      }
    } catch (e) {
      dispatch(handleError(e, null, `Script execution n°${ scriptExecutionId } failed`))
    }
  }, [ scriptExecutionId ])

  return null
}

export default ScriptExecutor
