export const FETCH_LIST_INFOS = '[LIST_RUN] FETCH_LIST_INFOS'
export const SET_DATA = '[LIST_RUN] SET_DATA'
export const REQUEST_IMPORT = '[LIST_RUN] REQUEST_IMPORT'
export const CANCEL_IMPORT = '[LIST_RUN] CANCEL_IMPORT'
export const DO_IMPORT = '[LIST_RUN] DO_IMPORT'

export const FETCH_LIST_SEARCHES = '[LIST_SEARCH] FETCH_LIST_SEARCHES'
export const POST_LIST_SEARCH = '[LIST_SEARCH] POST_LIST_SEARCH'
export const DELETE_LIST_SEARCH = '[LIST_SEARCH] DELETE_LIST_SEARCH'
export const SET_LIST_SEARCHES = '[LIST_SEARCH] SET_LIST_SEARCHES'
