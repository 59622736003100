import * as at from './actionTypes'
import {
  enqueueSnackbar,
  closeSnackbar,
  removeSnackbar
} from '../../notifier/actions'

export const navigateTo = path => ({
  type: at.NAVIGATE_TO,
  payload: path
})
export const locationChanged = route => ({
  type: at.LOCATION_CHANGED,
  payload: { route }
})

export const setAppConfig = config => ({
  type: at.SET_APP_CONFIG,
  payload: config
})
export const setIsApiReady = data => ({
  type: at.SET_IS_API_READY,
  payload: data
})

export const setAutoRefresh = data => ({
  type: at.SET_AUTO_REFRESH,
  payload: data
})

export const requestConfigTranslationsFetch = () => ({
  type: at.REQUEST_CONFIG_TRANSLATIONS_FETCH
})
export const getConfigTranslations = () => ({
  type: at.GET_CONFIG_TRANSLATIONS
})
export const setConfigTranslations = data => ({
  type: at.SET_CONFIG_TRANSLATIONS,
  payload: data
})
export const configTranslationsFetched = data => ({
  type: at.CONFIG_TRANSLATIONS_FETCHED,
  payload: data
})

export const getCurrentUser = () => ({
  type: at.GET_CURRENT_USER
})
export const setCurrentUser = data => ({
  type: at.SET_CURRENT_USER,
  payload: data
})

export const getAnnouncements = () => ({
  type: at.GET_ANNOUNCEMENTS
})
export const setAnnouncements = data => ({
  type: at.SET_ANNOUNCEMENTS,
  payload: data
})

export const handleError = (error, translationKey = null, data = null) => ({
  type: at.HANDLE_ERROR,
  payload: { error, translationKey, data }
})
export const logMessage = (message, level = 'info', data = null) => ({
  type: at.LOG_MESSAGE,
  payload: { message, level, data }
})

export const toggleOverlayLoader = (isEnable = null) => ({
  type: at.TOGGLE_OVERLAY_LOADER,
  payload: isEnable
})

export const setIsAuthenticated = (isAuthenticated = null) => ({
  type: at.SET_IS_AUTHENTICATED,
  payload: { isAuthenticated }
})

export { enqueueSnackbar, closeSnackbar, removeSnackbar }
