
export default {
  english: {
    title: 'Modules manager',
    addNew: 'Add a module',
    entries: 'of {total} entries',
    isActive: 'Is active',
    form: {
      label: 'Label',
      systemName: 'System name',
      systemNameInfo: 'Use only caps and underscore for spacing.',
      isActive: 'Active',
    },
    modal: {
      create: {
        title: 'Create a new module'
      },
      update: {
        title: 'Update a module'
      },
      confirmDelete: {
        title: 'Are you sure you want to delete this module ?'
      }
    }
  },
  french: {
    title: 'Gestionnaire de modules',
    addNew: 'Créer un module',
    entries: 'sur {total} entrées',
    isActive: 'Actif',
    form: {
      label: 'Label',
      systemName: 'Nom système',
      systemNameInfo: 'Utilisez seulement majuscules et tiret bas pour les espaces.',
      isActive: 'Actif',
    },
    modal: {
      create: {
        title: 'Créer un nouveau module'
      },
      update: {
        title: 'Modifier un module'
      },
      confirmDelete: {
        title: 'Êtes-vous sûr de vouloir supprimer ce module ?'
      }
    }
  }
}
