import React from 'react'
import TriggerModals from './Triggers'
import WorkflowModals from './Workflows'
import ActionModals from './Actions'

const WorkflowManagerModals = () => (
  <>
    <TriggerModals />
    <WorkflowModals />
    <ActionModals />
  </>
)

export default WorkflowManagerModals
