import {
  FETCH_RECENT_LISTS,
  RECENT_LISTS_FETCHED,
  RECENT_LISTS_FETCH_ERROR,
  CLEAR_RECENT_LISTS,
  FETCH_RECENT_VIEWED,
  RECENT_VIEWED_FETCHED,
  RECENT_VIEWED_FETCH_ERROR,
  CLEAR_RECENT_VIEWED,
  FETCH_LISTS,
  LISTS_FETCHED,
  CHANGE_LISTS_CURRENT_PAGE,
  CHANGE_LISTS_PAGE_SIZE,
  CHANGE_LISTS_SEARCH,
  LISTS_FETCH_ERROR,
  CLEAR_LISTS
} from './actionTypes'

export const initialState = {
  fetchingRecentLists: false,
  recentLists: [],
  recentListsFetchError: false,
  fetchingRecentViewed: false,
  recentViewed: [],
  recentViewedFetchError: false,
  fetchingLists: false,
  lists: [],
  listsFetchError: false,
  listsQuery: null,
  listsPagination: {
    offset: 0,
    limit: 10,
    itemsCount: 10,
    totalItemsCount: 10,
    pageLimit: 10,
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    totalPagesCount: 1,
  }
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_RECENT_LISTS:
      return {
        ...state,
        fetchingRecentLists: true,
        recentListsFetchError: false
      }
    case RECENT_LISTS_FETCHED:
      return {
        ...state,
        fetchingRecentLists: false,
        recentLists: payload.lists
      }
    case RECENT_LISTS_FETCH_ERROR:
      return {
        ...state,
        fetchingRecentLists: false,
        recentListsFetchError: true
      }
    case CLEAR_RECENT_LISTS:
      return {
        ...state,
        recentLists: []
      }
    case FETCH_RECENT_VIEWED:
      return {
        ...state,
        fetchingRecentViewed: true,
        recentViewedFetchError: false
      }
    case RECENT_VIEWED_FETCHED:
      return {
        ...state,
        fetchingRecentViewed: false,
        recentViewed: payload
      }
    case RECENT_VIEWED_FETCH_ERROR:
      return {
        ...state,
        fetchingRecentViewed: false,
        recentViewedFetchError: true
      }
    case CLEAR_RECENT_VIEWED:
      return {
        ...state,
        recentViewed: []
      }
    case FETCH_LISTS:
      return {
        ...state,
        fetchingLists: true,
        listsFetchError: false
      }
    case LISTS_FETCHED:
      return {
        ...state,
        fetchingLists: false,
        lists: payload.lists,
        listsPagination: payload.pagination,
      }
    case CHANGE_LISTS_CURRENT_PAGE:
      return {
        ...state,
        listsPagination: {
          ...state.listsPagination,
          currentPage: payload.currentPage
        }
      }
    case CHANGE_LISTS_PAGE_SIZE:
      return {
        ...state,
        listsPagination: {
          ...state.listsPagination,
          currentPage: 1,
          pageLimit: payload.pageLimit
        }
      }
    case CHANGE_LISTS_SEARCH:
      return {
        ...state,
        listsPagination: {
          ...state.listsPagination,
          currentPage: 1
        },
        listsQuery: payload.query
      }
    case LISTS_FETCH_ERROR:
      return {
        ...state,
        fetchingLists: false,
        listsFetchError: true
      }
    case CLEAR_LISTS:
      return {
        ...state,
        lists: [],
        listsPagination: initialState.listsPagination,
        listsFetchError: false,
        listsQuery: initialState.listsQuery
      }
    default:
      return state
  }
}
