import * as types from './actionTypes'
import dayjs from 'dayjs'
import { sortNumbersAscending } from 'src/Services/UserHelpers.ts'

export const initialState = {
  loading: false,
  slotTypes: null,
  providers: null,
  events: null,
  slots: null,
  type: '',
  isLocationsSelectOpen: false,
  locations: [],
  isProvidersSelectOpen: false,
  provider: '',
  days: [
    {
      name: 'sunday',
      number: 1,
      checked: false
    },
    {
      name: 'monday',
      number: 2,
      checked: false
    },
    {
      name: 'tuesday',
      number: 3,
      checked: false
    },
    {
      name: 'wednesday',
      number: 4,
      checked: false
    },
    {
      name: 'thursday',
      number: 5,
      checked: false
    },
    {
      name: 'friday',
      number: 6,
      checked: false
    },
    {
      name: 'saturday',
      number: 7,
      checked: false
    }
  ],
  dateAfter: dayjs()
    .startOf('day')
    .format('YYYY-MM-DD'),
  timeAfter: '00:00',
  dateBefore: '',
  timeBefore: '23:59',
  selectedSlots: null
}

export default (state = initialState, {
  type,
  payload
}) => {
  switch (type) {
    case types.LOAD_APPOINTMENT_DICTIONARY:
      return {
        ...state,
        loading: true,
        events: null,
        slots: null
      }
    case types.DICTIONARY_SLOT_TYPES_RECEIVED:
      return {
        ...state,
        loading: !payload || !state.providers,
        slotTypes: payload
      }
    case types.DICTIONARY_PROVIDERS_RECEIVED:
      return {
        ...state,
        loading: !payload || !state.slotTypes,
        providers: payload
      }
    case types.SEARCH_EVENTS:
      return {
        ...state,
        loading: true
      }
    case types.EVENTS_RECEIVED:
      return {
        ...state,
        loading: false,
        events: payload
      }
    case types.SEARCH_SLOTS:
      return {
        ...state,
        loading: true
      }
    case types.SLOTS_RECEIVED:
      return {
        ...state,
        loading: false,
        selectedSlots: payload
      }
    case types.APPOINTMENT_CHANGE_TYPE: {
      return {
        ...state,
        type: payload,
        selectedEvents: null,
        selectedSlots: null
      }
    }
    case types.APPOINTMENT_CHANGE_PROVIDER: {
      return {
        ...state,
        provider: payload,
        selectedEvents: null,
        selectedSlots: null
      }
    }
    case types.TOGGLE_LOCATIONS_SELECT: {
      return {
        ...state,
        isLocationsSelectOpen: !state.isLocationsSelectOpen
      }
    }
    case types.APPOINTMENT_ADD_LOCATION: {
      const newLocations = state.locations
        .concat(payload)
        .sort(sortNumbersAscending)

      return {
        ...state,
        locations: newLocations,
        selectedEvents: null,
        selectedSlots: null
      }
    }
    case types.APPOINTMENT_DELETE_LOCATION: {
      const newLocations = state.locations
        .filter(location => location !== payload)
        .sort(sortNumbersAscending)

      return {
        ...state,
        locations: newLocations,
        selectedEvents: null,
        selectedSlots: null
      }
    }
    case types.APPOINTMENT_CHANGE_DAYS: {
      const {
        name,
        checked
      } = payload

      const newDays = state.days
        .map(day => day.name === name ? {
          ...day,
          checked
        } : day)

      return {
        ...state,
        days: newDays,
        selectedEvents: null,
        selectedSlots: null
      }
    }
    case types.APPOINTMENT_CHANGE_DATE_AFTER: {
      const after = dayjs(payload)
      const before = dayjs(state.dateBefore)

      const newDateBefore = after.isSame(before, 'd') || after.isAfter(before, 'd')
        ? after.add(1, 'd')
          .format('YYYY-MM-DD')
        : state.dateBefore

      return {
        ...state,
        dateAfter: payload,
        dateBefore: newDateBefore,
        selectedEvents: null,
        selectedSlots: null
      }
    }
    case types.APPOINTMENT_CHANGE_TIME_AFTER: {
      return {
        ...state,
        timeAfter: payload,
        selectedEvents: null,
        selectedSlots: null
      }
    }
    case types.APPOINTMENT_CHANGE_DATE_BEFORE: {
      return {
        ...state,
        dateBefore: payload,
        selectedEvents: null,
        selectedSlots: null
      }
    }
    case types.APPOINTMENT_CHANGE_TIME_BEFORE: {
      return {
        ...state,
        timeBefore: payload,
        selectedEvents: null,
        selectedSlots: null
      }
    }
    case types.APPOINTMENT_SET_SLOTS: {
      return {
        ...state,
        selectedSlots: payload
      }
    }
    case types.APPOINTMENT_SELECT_SLOT: {
      const {
        name,
        checked
      } = payload

      const newSelectedSlots = state.selectedSlots
        .map(item => item.id === name ? {
          ...item,
          checked
        } : item)

      return {
        ...state,
        selectedSlots: newSelectedSlots
      }
    }
    default:
      return state
  }
}
