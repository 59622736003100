// getSelectedReferenceField :: State -> Object
export const getSelectedReferenceField = state => {
  if (state.selectedReferenceField?.value) {
    return state.selectedReferenceField
  }

  return state.referenceFieldSearchResults
    .find(field => field.id === state.selectedReferenceField?.id)
}

// getDraggedColumn :: State -> Object
export const getDraggedColumn = state => state.columns.find(col => col.id === state.draggedColumn)

// getIsRemoveColumnModalOpened :: State -> Boolean
export const getIsRemoveColumnModalOpened = state => Boolean(state.columnBeingRemoved)

// getSelectedReferenceFieldType :: State -> Boolean
export const getSelectedReferenceFieldType = state => {
  const fields = state.referenceFieldSearchResults || []
  const selectedFieldId = state.selectedReferenceField?.id || null
  const selectedField = fields.find(field => field.id === selectedFieldId)

  return selectedField ? selectedField.type : null
}
