import React from 'react'
import { fieldEvent } from '../../state/actions'
import { FormControl } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { FieldEvent } from 'src/Views/FormFiller/Types/Field'

const HiddenField = ({
  id = null,
  field = null,
  value,
  disabled = false
}) => {
  const dispatch = useDispatch()

  const { isAdmin } = useSelector(state => state.Root.user)

  const onChange = e => {
    dispatch(fieldEvent(field, FieldEvent.VALUE_CHANGE, { value: e.target.value }))
  }

  return <FormControl id={ `field${ id }` }
               type={ isAdmin ? 'text' : 'hidden' }
               value={ value || '' }
               onChange={ onChange }
               disabled={ disabled }
  />
}

export default HiddenField
