import React from 'react'
import { flexRender } from '@tanstack/react-table'

const AdvancedRow = ({ row, onRowClick }) =>
  <tr className="cursor-pointer" onClick={() => onRowClick(row.original)}>
    { row.getVisibleCells().map(cell => <td key={cell.id} className="advanced-table-row">
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </td>) }
  </tr>

export default AdvancedRow
