import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { StoreState } from 'src/Services/Store/reducers'
import { EventType } from 'src/Types/Event'
import { ListColumnMapping } from 'src/Types/ListColumn'
import { List } from 'src/Types/List'

const ListHead = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  color: #999;
  text-shadow: 0 1px 0 rgb(255 255 255 / 50%);
  margin-bottom: 2px;
`

export interface MappedEventType extends EventType{
  mapping: ListColumnMapping[]
}

const EventTypesList = () => {
  const { calendarTypeLists, eventTypes } = useSelector((state: StoreState) => state.Dictionary)

  const getMappedEventType = (eventType: EventType): MappedEventType => {
    // TODO: optimise the mapping, one of the options is to use hashmap of calendarTypeLists
    const typeList: List|null = calendarTypeLists.find(calendarTypeList => eventType.list?.id === calendarTypeList.id)

    if (!typeList)
      console.warn(`No calendar type list found for event type : ${eventType?.id}`, 'warning')

    return {
      ...eventType,
      list: typeList || null,
      mapping: typeList?.listColumns?.reduce((acc, { mapping }) => [ ...acc, ...mapping ], []) || []
    }
  }

  return <>
    { eventTypes.map(eventType => (
    <div style={{ marginBottom: '8px' }} key={eventType.systemName}>
      <ListHead>{eventType.label || eventType.systemName}</ListHead>
      <div>
        { getMappedEventType(eventType).mapping.map(({ icon, color, value, id }) =>
            <div style={{ marginBottom: '2px' }} key={id}>
              { icon && <>
                <i style={{ color, marginRight: '4px' }} className={icon.content}></i>
                <span>{ value }</span>
              </>
              }
            </div>
        )}
      </div>
    </div>)) }
  </>
}

export default EventTypesList
