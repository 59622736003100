
export default {
  english: {
    homepage: 'Home page',
    lists: 'Lists',
    listsSearchDefaultValue: 'Search lists',
    listsSearchTotalResults: count => `Of ${count} result${count > 1 ? 's' : ''}`,
    recentLists: 'Recent lists',
    recentListsFetchError: 'Error while fetching the recent list.',
    recentViewed: 'Recent viewed',
    recentViewedFetchError: 'Error while fetching the recent viewed.'
  },
  french: {
    homepage: 'Accueil',
    lists: 'Listes',
    listsSearchDefaultValue: 'Rechercher des listes',
    listsSearchTotalResults: count => `${count} résultat${count > 1 ? 's' : ''}`,
    recentLists: 'Listes récentes',
    recentListsFetchError: 'Erreur lors de la récupération des listes récentes.',
    recentViewed: 'Instances récentes',
    recentViewedFetchError: 'Erreur lors de la récupération des instances récentes.'
  }
}
