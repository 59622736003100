import { ADD_STORE_DATA, CLEAR_STORE, EXECUTE_SCRIPT, UPDATE_STORE_DATA, RESET } from 'src/Services/ScriptExecutor/state/actionTypes'
import { SimpleHTMLEvent } from 'src/Views/FormFiller/Components/Fields/ButtonField/ButtonField'
import { Action as ActionState } from 'src/Services/Store/reducers'
import { StoreItem } from 'src/Services/ScriptExecutor/Types'

export interface ScriptExecutorState {
  scriptExecutionId: number
  script: () => void | null
  event: SimpleHTMLEvent | null
  store: StoreItem[]
}

export const initialState: ScriptExecutorState = {
  scriptExecutionId: 0,
  script: null,
  event: null,
  store: []
}

export default (state = initialState, { type, payload }: ActionState) => {
  switch (type) {
    case EXECUTE_SCRIPT:
      return {
        ...state,
        scriptExecutionId: ++state.scriptExecutionId,
        script: payload.script,
        event: payload.e
      }
    case ADD_STORE_DATA:
      return {
        ...state,
        store: [
          ...state.store,
          {
            id: payload.id || 'unknown',
            data: payload.data || null
          }
        ]
      }
    case UPDATE_STORE_DATA:
      return {
        ...state,
        store: state.store.map(_ => _.id === payload.id ? payload : _)
      }
    case CLEAR_STORE:
      return {
        ...state,
        store: []
      }
    case RESET:
      return {
        ...state,
        store: initialState.store,
        script: initialState.script,
        event: initialState.event
      }
    default:
      return state
  }
}
