import React from 'react'
import { fieldEvent } from '../../state/actions'
import LocalizedDatepicker from 'src/Components/LocalizedDatepicker'
import { FieldEvent } from 'src/Views/FormFiller/Types/Field'
import { useDispatch } from 'react-redux'

const DateTimeField = ({
  id = null,
  field = null,
  options = {},
  value,
  disabled = false,
  isRequired = false,
}) => {
  const dispatch = useDispatch()

  const onChange = value => {
    dispatch(fieldEvent(field, FieldEvent.VALUE_CHANGE, { value }))
  }

  return <LocalizedDatepicker id={ `field${ id }` }
                              showDateOnly={ options.showDate && !options.showTime }
                              showTimeOnly={ !options.showDate && options.showTime }
                              selected={ value }
                              onChange={ onChange }
                              disabled={ disabled }
                              required={ isRequired }
  />
}

export default DateTimeField
