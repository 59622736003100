import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import { translate } from 'src/Services/translation'
import { NAME } from 'src/Services/Constants/Config/Person'
import translation from 'src/Views/FormFiller/translations.ts'
import { FieldOption } from 'src/Types/FieldOption'
import { getReferenceSearchQuery } from '../../../selectors'
import {
  clearReference, searchReference, changeReferenceToSelf, requestInstanceCreationForReference,
  requestInstanceEditForReference, setIsEditing
} from '../../../state/actions'

const ReferenceFieldActions = ({ field, disabled = false }) => {
  const dispatch = useDispatch()

  const user = useSelector(state => state.Root.user)
  const { referenceFieldOnEdit } = useSelector(state => state.FormFiller)
  const getSearchQuery = useSelector(state => getReferenceSearchQuery(state.FormFiller))

  const [ isAddButtonDisabled, setIsAddButtonDisabled ] = useState(true)

  const { id, list, value, options } = field

  const refFormRole = user.roleInstances.find(el => el.role === list.refForm.systemName)
  const showAssignSelfButton = list.refForm.systemName === NAME || refFormRole !== undefined
  const selfAssignId = list.refForm.systemName === NAME ? user.instance.id : refFormRole?.id

  const trans = translate(translation)(user.language)

  const createReference = () => dispatch(requestInstanceCreationForReference(field))
  const enableEditing = () => dispatch(setIsEditing(true, field))
  const disableEditing = () => dispatch(setIsEditing(false, field))

  const onClear = () => {
    disableEditing()
    setIsAddButtonDisabled(false)
    dispatch(clearReference(field))
  }

  const editingButtonsBehaviour = () => {
    if (referenceFieldOnEdit?.id !== id && value && options[FieldOption.SHOW_EDIT_BUTTON])
      return <Button variant={ 'secondary' } disabled={ disabled } size={ 'sm' } onClick={ enableEditing }>
        <i className={ 'me-1 fas fa-pen' }/>{ trans('edit') }
      </Button>
    else if (referenceFieldOnEdit?.id === id) {
      return (
        <div>
          <Button className={ 'me-1' } variant={ 'primary' } disabled={ disabled } size={ 'sm' }
                  onClick={ () => {
                    disableEditing()
                    dispatch(requestInstanceEditForReference(field))
                  } }
          >
            <i className={ 'me-1 fas fa-circle-check' }/>
            { trans('confirm') }
          </Button>
          <Button variant={ 'warning' }
                  size={ 'sm' } onClick={ disableEditing }>
            <i className={ 'fas fa-rotate-left' }/>
          </Button>
        </div>
      )
    }
  }

  return <div className={ 'text-end d-flex justify-content-between' }>

    { !value && options[FieldOption.SHOW_ADD_BUTTON] &&
      <Button variant={ 'primary' } disabled={ isAddButtonDisabled || disabled } size={ 'sm' }
              onClick={ createReference }
      >
        <i className={ 'me-1 fas fa-add' }/>{ trans('add') }
      </Button> }

    { referenceFieldOnEdit ? editingButtonsBehaviour() : null }

    { value && options[FieldOption.SHOW_CLEAR_BUTTON] &&
      <Button variant={ 'light' } disabled={ disabled } size={ 'sm' } onClick={ onClear }>
        <i className={ 'me-1 fas fa-eraser' }/>{ trans('clear') }
      </Button> }

    { !value && showAssignSelfButton &&
      <Button className={ 'me-1' } disabled={ disabled } size={ 'sm' }
              onClick={ () => dispatch(changeReferenceToSelf(id, selfAssignId, list?.id)) }
      >
        <i className={ 'me-1 fas fa-user-alt' }/>{ trans('assignMe') }
      </Button> }

    { !value &&
      <Button disabled={ disabled } size={ 'sm' }
              onClick={ () => {
                dispatch(searchReference(list?.id, id, getSearchQuery(id), options[FieldOption.SHOW_ADD_BUTTON]))
                setIsAddButtonDisabled(false)
              } }
      >
        <i className={ 'me-1 fas fa-search' }/>{ trans('search') }
      </Button> }
  </div>
}

export default ReferenceFieldActions
