import {
  TRIGGER_CREATED, TRIGGER_DELETED, TRIGGER_RECEIVED, CREATE_TRIGGER, DELETE_TRIGGER, UPDATE_TRIGGER,
  TRIGGER_UPDATED, SET_DELETING_TRIGGER, SET_UPDATING_TRIGGER, TOGGLE_CREATE_TRIGGER_MODAL
} from './actionTypes'

const initialState = {
  isLoading: false,
  creatingItem: null,
  updatingItem: null,
  deletingItem: null,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CREATE_TRIGGER:
      return {
        ...state,
        isLoading: true
      }
    case UPDATE_TRIGGER:
      return {
        ...state,
        isLoading: true
      }
    case DELETE_TRIGGER:
      return {
        ...state,
        isLoading: true
      }
    case TRIGGER_RECEIVED:
      return {
        ...state
      }
    case TRIGGER_CREATED:
      return {
        ...state,
        creatingItem: null,
      }
    case TRIGGER_UPDATED:
      return {
        ...state,
        updatingItem: null,
      }
    case TRIGGER_DELETED:
      return {
        ...state,
        deletingItem: null,
        updatingItem: null,
      }
    case SET_DELETING_TRIGGER:
      return {
        ...state,
        deletingItem: payload
      }
    case SET_UPDATING_TRIGGER:
      return {
        ...state,
        updatingItem: payload
      }
    case TOGGLE_CREATE_TRIGGER_MODAL:
      return {
        ...state,
        creatingItem: !state.creatingItem
      }
    default:
      return state
  }
}
