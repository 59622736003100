
export default {
  english: {
    setting: {
      exportCheckedFields: 'Export checked fields',
      exportAllFields: 'Export all fields',
      importFields: 'Import fields ...'
    },
    selectAllFields: 'Select all fields',
    emptyPlaceholder: {
      dragDropOr: 'Empty slot',
      dragDropExtensible: 'Drop a field here to add it',
      import: 'import'
    },
    field: {
      addingCheckboxField: 'Adding Checkbox field',
      addingDateTimeField: 'Adding Date/Time field',
      addingDropdownField: 'Adding Dropdown field',
      addingEmailField: 'Adding Email field',
      addingRadioField: 'Adding Radio field',
      addingReferenceField: 'Adding Reference field',
      addingTextField: 'Adding Text field'
    },
    addRow: 'Add row'
  },
  french: {
    setting: {
      exportCheckedFields: 'Exporter les champs cochés',
      exportAllFields: 'Exporter tous les champs',
      importFields: 'Importer les champs ...'
    },
    selectAllFields: 'Selectionner tous les champs',
    emptyPlaceholder: {
      dragDropOr: 'Champ vide',
      dragDropExtensible: 'Déposez un champ ici pour l\'ajouter',
      import: 'importer'
    },
    field: {
      addingCheckboxField: 'Ajout d\'un champ Checkbox',
      addingDateTimeField: 'Ajout d\'un champ Date/Time',
      addingDropdownField: 'Ajout d\'un champ Dropdown',
      addingEmailField: 'Ajout d\'un champ Email',
      addingRadioField: 'Ajout d\'un champ Radio',
      addingReferenceField: 'Ajout d\'un champ Reference',
      addingTextField: 'Ajout d\'un champ Text'
    },
    addRow: 'Ajouter une ligne'
  }
}
