import { call, put, takeLatest } from 'redux-saga/effects'
import { GlobalActions } from 'src/Types/GlobalActions'
import { Action } from 'src/Services/Store/reducers'
import * as types from './actionTypes'
import { setSearchResults, setInstanceData } from './actions'
import apiMethods from '../../../Services/api/apiMethods'

function* searchPatients(props: GlobalActions, { payload: { query } }: Action) {
  try {
    const { data } = yield call(apiMethods.get, '/patients/search', { query: query })

    yield put(setSearchResults(data || []))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'searchPatientFailed'))
  }
}

function* searchInstance(props: GlobalActions, { payload: { query } }: Action) {
    try {
      const { data } = yield call(apiMethods.get, `/instances/${ query }`, { withMinimalData: true })
      yield put(setInstanceData(data))
    } catch (error) { /* empty */ }
}
export default function* headerSagaWatcher(props: GlobalActions) {
  yield takeLatest(types.SEARCH, searchPatients, props)
  yield takeLatest(types.SEARCH_INSTANCE, searchInstance, props)
}

