import React from 'react'
import { Field } from 'src/Types/Field'
import { ButtonSize } from 'src/Views/FormFiller/Types/Size'
import { FIELD_MS_SANTE_PREFIX_SEND_BUTTON } from 'src/Services/Constants/MsSante'
import { useSelector } from 'react-redux'
import { StoreState } from 'src/Services/Store/reducers'
import SystemField from 'src/Types/SystemField'
import GenericButtonField from './GenericButtonField'
import MsSanteButtonField from './MsSanteButtonField'

export interface SimpleHTMLEventTarget {
  id: string
  className: string
}

export interface SimpleHTMLEvent {
  target?: SimpleHTMLEventTarget
}

interface Props {
  field: Field,
  isLoading?: boolean
  isDisable?: boolean
  size?: ButtonSize
}

const ButtonField = ({
                       field = null,
                       isLoading = false,
                       isDisable = false,
                       size = ButtonSize.sm,
                     }: Props) => {

  const { openedInstanceSystemFields } = useSelector((state: StoreState) => state.FormFiller)

  const getAssociatedSystemFields = (): SystemField[] => openedInstanceSystemFields.filter(sf => sf.field.id === field.id) || []
  const isMsSanteButton = () => getAssociatedSystemFields().some(sf => sf.name.startsWith(FIELD_MS_SANTE_PREFIX_SEND_BUTTON))

  return <>
    { isMsSanteButton()
      ? <MsSanteButtonField field={ field } isLoading={ isLoading } isDisable={ isLoading || isDisable } size={ size } />
      : <GenericButtonField field={ field } isLoading={ isLoading } isDisable={ isLoading || isDisable } size={ size } />
    }
  </>
}

export default ButtonField
