import { Instance } from 'src/Types/Instance'
import File from 'src/Types/File'
import { ConsentFieldValue } from 'src/Types/FieldType/ConsentField'
import { ButtonFieldValue } from 'src/Types/FieldType/ButtonField'
import FileConstraint from 'src/Types/FieldOption/FileConstraint'
import { List } from 'src/Types/List'
import { ListColumn } from 'src/Types/ListColumn'
import { UuidV4 } from 'src/Types/Uuid'
import { FieldOptionSaveTypes } from 'src/Types/FieldOption'
import Row from 'src/Types/Row'

export interface Field {
  id: UuidV4 | string | null
  systemName: string
  label: string | null
  referenceFieldId?: string | null
  javaScriptCode?: null | FieldJavaScriptCode
  extensible: Field | null
  type: FieldType
  options: {
    isSensitive?: boolean|null
    validateUserIdentity?: boolean|null
    required?: boolean|null
    readOnly?: boolean|null
    showTime?: boolean|null
    showDate?: boolean|null
    showAddButton?: boolean|null
    showEditButton?: boolean|null
    showClearButton?: boolean|null
    supportOnBlurCode?: boolean|null
    supportOnChangeCode?: boolean|null
    supportOnClickCode?: boolean|null
    setAtCreation?: boolean|null
    setAtModification?: boolean|null
    setNowAtModification?: boolean|null
    setNowAtCreation?: boolean|null
    redirectToHomePage?: boolean|null
    isTemplateCreator?: boolean|null
    alignOptionsVertically?: boolean|null
    autocompleteAtCreation?: boolean|null
    excludeFromClone?: boolean|null
    selfPopulation?: boolean|null
    sortAlpha?: boolean|null
    necessary?: boolean|null
    consentDescription?: string|null
    userTextResponse?: string|null
    content?: any
    sortOrder?: string|number|null
    fileField?: string|null
    fontSize?: string|null
    fontColor?: string|null
    cssClass?: string|null
    labelHover?: string|null
    defaultTemplate?: string|null
    defaultOption?: string|null
    defaultValue?: string|null
    saveType?: FieldOptionSaveTypes|null
    values?: FieldOption[]
    calculation?: object|null
    targetedFields?: object|null
    consentFields?: string[]
    autocompleteFields?: FieldOptionAutocompleteField[]
    documentType?: string|null
    fileTypes?: FileConstraint[]
    isValidator?: boolean|null
  }
  // Extensible fields or extensible fields ids
  fields?: Field[] | string[] | number[]
  value: FieldValue
  accessLevel?: number | null
  list?: List
  // Only in reference field's children
  listColumn?: ListColumn
  row?: Row | null
  extensibleFieldId?: string | null
  rowColumn?: 1 | 2 | 3 | null
  rowSize?: 1 | 2 | 3 | null
}

export type FieldValue =
    string | null | boolean | number | Instance | FieldValue[] | File | File[] | ConsentFieldValue | ButtonFieldValue | FieldOption | FieldOption[] | ExtensibleRow[]

export interface ExtensibleRow {
  id: string
  values: {
    [fieldSystemName: string]: FieldValue
  }
}

/**
 * Value for dropdown, radio, checkbox fields
 */
export interface FieldOption {
  systemName: string
  label: string|null
}

export enum BaseFieldType {
  REFERENCE = 'REFERENCE',
  EXTENSIBLE = 'EXTENSIBLE',
  BUTTON = 'BUTTON',
  DROPDOWN = 'DROPDOWN',
  MULTISELECT = 'MULTISELECT',
  CHECKBOX = 'CHECKBOX',
  RADIO = 'RADIO',
  DATE_TIME = 'DATE_TIME',
  FILE = 'FILE',
  ESSAY = 'ESSAY',
  JSON = 'JSON',
  TEXT = 'TEXT',
  DOCUMENT = 'DOCUMENT',
  INSTANCE_ID = 'INSTANCE_ID',
  SIGNATURE = 'SIGNATURE',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  HIDDEN = 'HIDDEN',
  ADDRESS = 'ADDRESS',
  USERNAME = 'USERNAME',
  SENTENCE = 'SENTENCE',
  HTML_CONTENT = 'HTML_CONTENT',
  CALCULATION = 'CALCULATION',
  CONSENT = 'CONSENT',
}

export interface FieldType {
  id: string | null
  name: string | null
  icon: string | null
  baseFieldType: BaseFieldType
}

export interface FieldJavaScriptCode {
  onClick?: () => null
  onChange?: () => null
  onBlur?: () => null
}

export enum FieldOptionAutocompleteFieldType {
  CONSTANT = 'CONSTANT',
  DYNAMIC = 'DYNAMIC',
}

export interface FieldOptionAutocompleteFieldDynamicValue {
  id: string
  value: string
}

export interface FieldOptionAutocompleteField {
  // Field's system name
  field: string
  type: FieldOptionAutocompleteFieldType
  // If dynamic, value is a system field
  value: any
}
