import { call, put, select, takeLatest } from 'redux-saga/effects'
import { ADD_ADMIN_TOOL, DELETE_ADMIN_TOOL, FETCH_ADMIN_TOOLS, UPDATE_ADMIN_TOOL } from './actionTypes'
import {
    adminToolAdded,
    adminToolDeleted,
    adminToolUpdated,
    receiveAdminTools, toggleAddAdminTool,
    toggleDeleteAdminTool,
    toggleUpdateAdminTool
} from './actions'
import apiMethods from '../../../Services/api/apiMethods'
import { contentRangeToPagination } from 'src/Utils/EffectsUtil'
import { errorHandlerAction } from '../../UserList/state/actions'
import { GlobalActions } from 'src/Types/GlobalActions'
import { Action, StoreState } from 'src/Services/Store/reducers'

function* fetchAdminTools(props: GlobalActions) {
    try {
        const state: StoreState = yield select()
        const { searchQuery: query, pagination } = state.AdminTools

        const { data, headers } = yield call(apiMethods.get, '/admin_tools', {
            query, ...pagination
        })

        yield put(receiveAdminTools({
            adminToolsList: data,
            pagination: contentRangeToPagination(headers['content-range'], pagination)
        }))
    } catch (error) {
        yield put(props.globalActions.handleError(error, 'loadListFailed'))
    }
}

function* createAdminToolsHandler(props: GlobalActions, { payload }: Action) {
    try {
        const { data } = yield call(apiMethods.create, '/admin_tools',
            { data: payload })
        yield put(toggleAddAdminTool())
        yield put(adminToolAdded(data))
    } catch (e) {
        const translationKey = e.response?.data?.error ? `${e.response.data.error.code}Error` : 'createAdminToolFailed'
        yield put(props.globalActions.handleError(e, translationKey))
        yield put(errorHandlerAction())
    }
}

function* updateAdminTools(props: GlobalActions, { payload }: Action) {
    try {
        const state: StoreState = yield select()
        const { id } = state.AdminTools.adminToolOnUpdate
        const { data } = yield call(apiMethods.update, `/admin_tools/${id}`, { data: payload })
        yield put(toggleUpdateAdminTool(null))
        yield put(adminToolUpdated(data))
    } catch (e) {
        const translationKey = e.response?.data?.error ? `${e.response.data.error.code}Error` : 'createAdminToolFailed'
        yield put(props.globalActions.handleError(e, translationKey))
        yield put(errorHandlerAction())
    }
}

function* deleteAdminTools(props: GlobalActions) {
    try {
        const state: StoreState = yield select()
        const { id } = state.AdminTools.adminToolOnDelete
        yield call(apiMethods.delete, `/admin_tools/${id}`)
        yield put(toggleDeleteAdminTool(null))
        yield put(adminToolDeleted(id))
    } catch (error) {
        yield put(props.globalActions.handleError(error, 'deleteAdminToolsFailed'))
    }
}

export default function* adminToolsSagaWatcher(props: GlobalActions) {
    yield takeLatest(FETCH_ADMIN_TOOLS, fetchAdminTools, props)
    yield takeLatest(ADD_ADMIN_TOOL, createAdminToolsHandler, props)
    yield takeLatest(UPDATE_ADMIN_TOOL, updateAdminTools, props)
    yield takeLatest(DELETE_ADMIN_TOOL, deleteAdminTools, props)
}
