import React  from 'react'
import { Form as BForm, Button, Row, Col } from 'react-bootstrap'
import { LANGUAGES } from '../../../Services/Constants'

const Form = ({
  trans,
  onSubmit = () => {},
  item = null
}) => {
  const onFormSubmit = e => {
    e.preventDefault()
    onSubmit({
      ...item,
      code: e.target.code.value,
      language: e.target.language.value,
      content: e.target.content.value
    })
  }

  return (
    <BForm onSubmit={onFormSubmit}>
      <Row className={'mb-2'}>
        <Col>
          <BForm.Group>
            <BForm.Label>{trans('form.code')}</BForm.Label>
            <BForm.Control defaultValue={item?.code} name={'code'} required type="text"
                          placeholder={trans('form.code')}/>
          </BForm.Group>
        </Col>
        <Col>
          <BForm.Group>
            <BForm.Label>{trans('form.language')}</BForm.Label>
            <BForm.Select defaultValue={item?.language} name={'language'}
                          required aria-label={trans('form.language')}>
              {LANGUAGES.map(lang => <option value={lang} key={lang}>{lang}</option>)}
            </BForm.Select>
          </BForm.Group>
        </Col>
      </Row>
      <Row className={'mb-2'}>
        <Col>
          <BForm.Group>
            <BForm.Label>{trans('form.content')}</BForm.Label>
            <BForm.Control as={'textarea'} defaultValue={item?.content} name={'content'}
                           required placeholder={trans('form.content')}/>
          </BForm.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button variant={'success'} style={{ float: 'right' }} type={'submit'}>{trans('submit')}</Button>
        </Col>
      </Row>
    </BForm>
  )
}

export default Form
