export const FETCH_FIELD_TYPES = '[FIELD_TYPES] FETCH_FIELD_TYPES'
export const FETCH_BASE_FIELD_TYPES = '[FIELD_TYPES] FETCH_BASE_FIELD_TYPES'
export const RECEIVE = '[FIELD_TYPES] RECEIVE'
export const RECEIVE_BASE_FIELD_TYPES = '[FIELD_TYPES] RECEIVE_BASE_FIELD_TYPES'
export const RECEIVE_FIELD_TYPES = '[FIELD_TYPES] RECEIVE_FIELD_TYPES'
export const CHANGE_LIMIT = '[FIELD_TYPES] CHANGE_LIMIT'
export const CHANGE_PAGE = '[FIELD_TYPES] CHANGE_PAGE'
export const TOGGLE_CREATE_FIELD_TYPE = '[FIELD_TYPES] TOGGLE_CREATE_FIELD_TYPE'
export const TOGGLE_UPDATE_FIELD_TYPE = '[FIELD_TYPES] TOGGLE_UPDATE_FIELD_TYPE'
export const TOGGLE_DELETE_FIELD_TYPE = '[FIELD_TYPES] TOGGLE_DELETE_FIELD_TYPE'
export const CREATE_FIELD_TYPE = '[FIELD_TYPES] CREATE_FIELD_TYPE'
export const FIELD_TYPE_CREATED = '[FIELD_TYPES] FIELD_TYPE_CREATED'
export const UPDATE_FIELD_TYPE = '[FIELD_TYPES] UPDATE_FIELD_TYPE'
export const FIELD_TYPE_UPDATED = '[FIELD_TYPES] FIELD_TYPE_UPDATED'
export const DELETE_FIELD_TYPE = '[FIELD_TYPES] DELETE_FIELD_TYPE'
export const FIELD_TYPE_DELETED = '[FIELD_TYPES] FIELD_TYPE_DELETED'
export const SEARCH_FIELD_TYPE = '[FIELD_TYPES] SEARCH_FIELD_TYPE'
