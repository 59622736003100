import React, { useEffect, useState } from 'react'
import { withState } from '../../../../Components/Util'
import { translate } from '../../../../Services/translation'
import { path } from 'ramda'
import { Button, ButtonGroup } from 'react-bootstrap'
import translation from '../../translations'
import NavTable from '../../../../Components/NavTable'
import { useDispatch } from 'react-redux'
import { changeLimitRestoreDeletedSections, changePage, fetchDeletedSections, fetchRestoreSection, searchDeletedSection } from './state/actions'
import { toggleConfirmModal } from '../../../../Services/Store/GlobalControls/actions'

const propsFromState = {
  language: path([ 'Root', 'user', 'language' ]),
  pagination: path([ 'RestoreDeletedSections', 'pagination' ]),
  loading: path([ 'RestoreDeletedSections', 'loading' ]),
  deletedSections: path([ 'RestoreDeletedSections', 'deletedSections' ])
}

const ActionCell = ({ onClick = () => {}, trans }) =>
  <ButtonGroup>
    <Button variant={'outline-info'} size={'sm'} onClick={onClick}>
      <i className="bi bi-arrow-counterclockwise"></i> {trans('restore')}
    </Button>
  </ButtonGroup>

// View :: Props -> React.Element
const restoreDeletedSections = ({
  language,
  pagination,
  loading,
  deletedSections
}) => {
  const trans = translate(translation)(language)
  const columnHeaders = [ trans('name'), trans('actions') ]
  const dispatch = useDispatch()
  const [ fetchDeletedSectionsRequest, setDeletedSectionsRequest ] = useState(null)

  useEffect(() => {
    dispatch(fetchDeletedSections())
  }, [ dispatch ])

  const onLimitChange = dispatch => limit => {
    dispatch(changeLimitRestoreDeletedSections(limit))
    dispatch(fetchDeletedSections())
  }

  const onRestoreClick = item => {
    dispatch(toggleConfirmModal(() => {
      dispatch(fetchRestoreSection(item))
      applyNewFilters()
    }))
  }

  const applyNewFilters = () => {
    clearTimeout(fetchDeletedSectionsRequest)
    setDeletedSectionsRequest(setTimeout(() => dispatch(fetchDeletedSections()), 1000))
  }

  const onSearchInputChange = e => {
    e.preventDefault()
    dispatch(searchDeletedSection(e.target.value))
    applyNewFilters()
  }

  const onPageClick = (currentPage, page) => {
    if (currentPage === page)
      return
    dispatch(changePage(page))
    dispatch(fetchDeletedSections())
  }

  const getRows = () =>
    deletedSections.map(item => [
      <>{item.name}</>,
      <ActionCell
        onClick={() => onRestoreClick(item)}
        trans={trans}
      />
    ])

  return (
    <NavTable pagination={pagination}
      rows={getRows()}
      columnHeaders={columnHeaders}
      onSearchInputChange={onSearchInputChange}
      onLimitChange={onLimitChange(dispatch)}
      onPageClick={onPageClick}
      isLoading={loading}
    />
  )
}

export default withState(propsFromState)(restoreDeletedSections)
