import { call, put, takeLatest } from 'redux-saga/effects'
import { setDictionaries, setListsDictionary } from './actions'
import apiMethods from 'src/Services/api/apiMethods'
import { FETCH_DICTIONARIES, FETCH_LISTS_DICTIONARY } from './actionTypes'

function* loadAll(props) {
  try {
    const { data } = yield call(apiMethods.get, '/dictionary', { all: true })

    yield put(setDictionaries(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error))
  }
}

function* loadListsDictionary(props) {
  try {
    const { data } = yield call(apiMethods.get, '/dictionary', { lists: true })

    yield put(setListsDictionary(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error))
  }
}

export default function* dictionarySagaWatcher(props) {
  yield takeLatest(FETCH_DICTIONARIES, loadAll, props)
  yield takeLatest(FETCH_LISTS_DICTIONARY, loadListsDictionary, props)
}
