import { select, all, fork } from 'redux-saga/effects'
import aboutSaga from 'src/Views/About/state/saga'
import adminToolsSaga from 'src/Views/AdminTools/state/saga'
import authSaga from 'src/Views/Auth/state/saga'
import announcementsSaga from 'src/Views/Announcements/state/saga'
import calendarSagaWatcher from 'src/Components/Calendar/state/saga'
import deletedFieldsSagaWatcher from 'src/Views/RestoreDeletedInstances/Components/restoreDeletedFields/state/saga'
import deletedFormsSagaWatcher from 'src/Views/RestoreDeletedInstances/Components/restoreForms/state/saga'
import deletedInstancesSagaWatcher from 'src/Views/RestoreDeletedInstances/Components/restoreInstances/state/saga'
import deletedSectionsSagaWatcher from 'src/Views/RestoreDeletedInstances/Components/restoreSections/state/saga'
import dictionarySaga from 'src/Services/Dictionary/state/saga'
import eventTypesSaga from 'src/Views/EventTypes/state/saga'
import eventStatusesSaga from 'src/Views/EventStatuses/state/saga'
import eventCancellationReasonsSagaWatcher from 'src/Views/EventCancellationReasons/state/saga'
import fieldTypesSaga from 'src/Views/FieldTypes/state/saga'
import formListSaga from 'src/Views/FormList/state/saga'
import formEditorSaga from 'src/Views/FormEditor/saga'
import formFillerSaga from 'src/Views/FormFiller/state/saga'
import formFillerSwpSaga from 'src/Views/FormFiller/state/swpSaga'
import globalControlsSagaWatcher from './GlobalControls/saga'
import headerSaga from 'src/Layouts/Header/state/saga'
import view from 'src/Layouts/View/state/saga'
import homePageSaga from 'src/Views/Homepage/state/saga'
import iconSaga from 'src/Views/Icons/state/saga'
import landingPageSaga from 'src/Views/LandingPages/state/saga'
import manageSystemFieldsSagaWatcher from 'src/Views/SystemFields/state/saga'
import messageConfigs from 'src/Views/MessageConfigs/state/saga'
import moduleSaga from 'src/Views/Modules/state/saga'
import navigationItemSaga from 'src/Views/NavigationItems/state/saga'
import patientSaga from 'src/Views/Patient/state/saga'
import permissionsSaga from 'src/Views/Permissions/state/saga'
import rootSaga from '../Store/Root/saga.js'
import schedulingSagaWatcher from 'src/Views/Calendar/state/saga'
import slotTypesSagaWatcher from 'src/Views/SlotTypes/state/saga'
import userListSaga from 'src/Views/UserList/state/saga'
import userFormSaga from 'src/Views/UserForm/state/saga'
import reportsSaga from 'src/Views/Reports/state/saga'
import tasks from 'src/Views/Tasks/state/saga'
import tasksCategories from 'src/Views/TasksCategories/state/saga'
import tasksStatus from 'src/Views/TasksStatus/state/saga'
import tasksTypes from 'src/Views/TasksTypes/state/saga'
import translations from 'src/Views/Translations/state/saga'
import workflowItems from 'src/Views/Workflows/Modals/Workflows/state/saga'
import workflowManager from 'src/Views/Workflows/state/saga'
import workflowTriggers from 'src/Views/Workflows/Modals/Triggers/state/saga'
import workflowActions from 'src/Views/Workflows/Modals/Actions/state/saga'
import zmrEditSaga from 'src/Views/ListEdit/state/saga'
import zmrListsSaga from 'src/Views/Lists/state/saga'
import zmrResultSaga from 'src/Views/ListRun/state/saga'
import importInstancesSaga from 'src/Views/ImportInstances/state/saga'
import listResultTableSaga from 'src/Components/ListResultTable/state/saga'
import patientCreatorSaga from 'src/Components/PatientCreator/state/saga'
import documentEditorSagaWatcher from 'src/Components/DocumentEditor/state/saga'
import auditTrailsSimpleTableSaga from 'src/Components/AuditTrailsSimpleTable/state/saga'
import auditTrailsSaga from 'src/Views/AuditTrails/state/saga'
import { GlobalActions } from 'src/Types/GlobalActions'
import { StoreState } from 'src/Services/Store/reducers'

export default function* (props: GlobalActions) {
  const { config } = yield select((state: StoreState) => state.Root)

  try {
    yield all([
      fork(aboutSaga, props),
      fork(adminToolsSaga, props),
      fork(auditTrailsSaga, props),
      fork(auditTrailsSimpleTableSaga, props),
      fork(authSaga, props),
      fork(announcementsSaga, props),
      fork(calendarSagaWatcher, props),
      fork(deletedFieldsSagaWatcher, props),
      fork(deletedFormsSagaWatcher, props),
      fork(deletedInstancesSagaWatcher, props),
      fork(deletedSectionsSagaWatcher, props),
      fork(dictionarySaga, props),
      fork(eventTypesSaga, props),
      fork(eventStatusesSaga, props),
      fork(eventCancellationReasonsSagaWatcher, props),
      fork(fieldTypesSaga, props),
      fork(formListSaga, props),
      fork(formEditorSaga, props),
      fork(formFillerSaga, props),
      fork(formFillerSwpSaga, props),
      fork(globalControlsSagaWatcher, props),
      fork(headerSaga, props),
      fork(homePageSaga, props),
      fork(iconSaga, props),
      fork(importInstancesSaga, props),
      fork(landingPageSaga, props),
      fork(manageSystemFieldsSagaWatcher, props),
      fork(messageConfigs, props),
      fork(moduleSaga, props),
      fork(navigationItemSaga, props),
      fork(patientSaga, props),
      fork(permissionsSaga, props),
      fork(patientCreatorSaga, props),
      fork(rootSaga, props),
      fork(schedulingSagaWatcher, props),
      fork(slotTypesSagaWatcher, props),
      fork(userListSaga, props),
      fork(userFormSaga, props),
      fork(reportsSaga, props),
      fork(tasks, props),
      fork(tasksCategories, props),
      fork(tasksStatus, props),
      fork(tasksTypes, props),
      fork(translations, props),
      fork(view, props),
      fork(workflowManager, props),
      fork(workflowItems, props),
      fork(workflowTriggers, props),
      fork(workflowActions, props),
      fork(zmrEditSaga, props),
      fork(zmrListsSaga, props),
      fork(zmrResultSaga, props),
      fork(listResultTableSaga, props),
      fork(documentEditorSagaWatcher, props)
    ])
  } catch (e) {
    if (config.DEBUG_STATE === '1')
      console.error('Saga error : ', e)
  }
}
