
export default {
  english: {
    true: 'Yes',
    false: 'No',
    incorrectValue: 'Incorrect value',
  },
  french: {
    true: 'Oui',
    false: 'Non',
    incorrectValue: 'Valeur incorrecte',
  }
}
