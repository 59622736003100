import React from 'react'
import { path } from 'ramda'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Modal from 'src/Components/Modal'
import { translate } from 'src/Services/translation'
import { withState } from 'src/Components/Util'
import { updateDetails, toggleScriptDialog } from '../state/actions'
import translation from '../translations'

// propsFromState :: Map String (State -> a)
const propsFromState = {
  form: path([ 'FormEditor', 'formReducer', 'form' ]),
  saveDetailsUpdateError: path([ 'FormEditor', 'formReducer', 'scriptError' ]),
  scriptDialogOpened: path([
    'FormEditor',
    'formReducer',
    'scriptDialogOpened'
  ]),
  language: path([ 'Root', 'user', 'language' ])
}

const ScriptModal = ({
  form,
  scriptDialogOpened,
  language,
  dispatch
}) => {
  const trans = translate(translation)(language)

  const toggleScriptModal = () =>
    dispatch(toggleScriptDialog())

  const submitScript = e => {
    e.preventDefault()
    dispatch(updateDetails({ javaScriptCode: { onPageLoad: e.target.onPageLoadScript.value } }))
  }

  return (
    <Modal
      title={ trans('scriptModal.title') }
      onClose={ toggleScriptModal }
      isOpen={ scriptDialogOpened }
    >
      <Form onSubmit={ submitScript }>
        <Row className={ 'mb-2' }>
          <Col>
            <Form.Group>
              <Form.Label>{ trans('scriptModal.onPageLoadScript') }</Form.Label>
              <Form.Control name={ 'onPageLoadScript' } as={ 'textarea' } size={ 'sm' }
                            defaultValue={ form.javaScriptCode?.onPageLoad || '' }/>
              <Form.Text>{ trans('onPageLoadCodeInfo') }</Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button variant={ 'success' } type={ 'submit' }>
              { trans('scriptModal.save') }
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default withState(propsFromState)(ScriptModal)
