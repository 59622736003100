import { call, put, select, takeLatest } from 'redux-saga/effects'
import { FETCH_DELETED_INSTANCES, FETCH_RESTORE_INSTANCE } from './actionTypes'
import {
  receiveDeletedInstances
} from './actions'
import apiMethods from '../../../../../Services/api/apiMethods'
import { contentRangeToPagination } from '../../../../../Utils/EffectsUtil.ts'

function* fetchDeletedInstances(props) {
  try {
    const state = yield select()
    const { pagination, searchQuery } = state.RestoreDeletedInstances

    const { data, headers } = yield call(apiMethods.get, '/instances', {
      query: searchQuery, ...pagination, onlyDeleted: true
    })
    yield put(receiveDeletedInstances({
      slotTypes: data,
      pagination: contentRangeToPagination(headers['content-range'], pagination)
    }))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'fetchFailed'))
  }
}

function* fetchRestoreInstance(props, { payload }) {
  try {
    const data = yield call(apiMethods.update, `/instances/${payload.id}/restore`, {})
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'fetchFailed'))
  }
}


export default function* deletedInstancesSagaWatcher(props) {
  yield takeLatest(FETCH_DELETED_INSTANCES, fetchDeletedInstances, props)
  yield takeLatest(FETCH_RESTORE_INSTANCE, fetchRestoreInstance, props)
}
