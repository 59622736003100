import * as Sentry from '@sentry/react'
import * as types from './actionTypes'
import AppSentry from 'src/Services/Sentry'
import { Action } from 'src/Services/Store/reducers'
import Config from 'src/Types/Config'
import { User } from 'src/Types/User'

export interface RootState {
  config: null | Config
  isApiReady: boolean
  user: User
  announcements: []
  configTranslations: object
  configTranslationsFetchRequested: boolean
  isFetchingUser: boolean
  displayOverlayLoader: boolean
  autoRefresh: boolean
  isAuthenticated: boolean
}

const initialState: RootState = {
  config: null,
  isApiReady: false,
  user: {
    id: null,
    email: null,
    firstName: null,
    lastName: null,
    username: null,
    isAdmin: false,
    roles: [],
    impersonatorUser: null,
    instance: null,
    locale: null,
    language: null,
    modules: [],
    navigationItems: [],
    landingPages: [],
    portalTimezone: 'UTC',
    hasPin: false,
    logAsRoles: [],
    primaryRole: null,
    primaryRoleInstance: null,
    secondaryRoles: [],
    secondaryRolesInstances: [],
    personInstance: null,
    signature: null,
  },
  announcements: [],
  configTranslations: null,
  configTranslationsFetchRequested: false,
  isFetchingUser: false,
  displayOverlayLoader: false,
  autoRefresh: false,
  isAuthenticated: null,
}

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.SET_AUTO_REFRESH:
      return {
        ...state,
        autoRefresh: payload,
      }
    case types.SET_IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: payload.isAuthenticated,
      }
    case types.SET_IS_API_READY:
      return {
        ...state,
        isApiReady: payload,
      }
    case types.REQUEST_CONFIG_TRANSLATIONS_FETCH:
      return {
        ...state,
        configTranslationsFetchRequested: true,
      }
    case types.GET_CURRENT_USER:
      return {
        ...state,
        isFetchingUser: true,
      }
    case types.SET_CURRENT_USER:

      if (payload === null) {
        return {
          ...state,
          user: initialState.user,
          isFetchingUser: false,
          isAuthenticated: false,
        }
      }

      const user = {
        ...state.user,
        ...payload,
        modules: payload.modules || [],
        navigationItems: payload.navigationItems || [],
        email: payload.email || null,
        username: payload.username || null,
        roles: payload.roles || [],
        instance: payload.instance || null,
        locale: payload.locale ? payload.locale.split('_')[1] : null,
        language: payload.locale ? payload.locale.split('_')[0] : null,
      }

      Sentry.setUser({ ...user, id: user.id?.toString(), })

      return {
        ...state,
        user,
        isFetchingUser: false,
        isAuthenticated: true,
      }
    case types.SET_CONFIG_TRANSLATIONS:
      return {
        ...state,
        configTranslations: payload,
      }
    case types.CONFIG_TRANSLATIONS_FETCHED:
      const results: { [key: string]: string } = {}

      for (const { code, content } of payload)
        results[code] = content

      localStorage.setItem('configTranslations', JSON.stringify(results))

      return {
        ...state,
        configTranslations: results,
        configTranslationsFetchRequested: false,
      }
    case types.SET_APP_CONFIG:

      // Avoid to re-declare config
      if (payload.config)
        return state

      try {
        AppSentry.init(
            payload.SENTRY_DSN, payload.SENTRY_RELEASE, payload.SENTRY_ENVIRONMENT,
            payload.DEBUG_STATE, payload.SENTRY_ENABLE_PERFORMANCE_MONITORING,
        )
      } catch (e) {
        console.error('Sentry init error', e)
      }

      return { ...state, config: payload }
    case types.SET_ANNOUNCEMENTS:
      return { ...state, announcements: payload || [] }
    case types.TOGGLE_OVERLAY_LOADER:
      return {
        ...state,
        displayOverlayLoader: payload === null ? !state.displayOverlayLoader : payload,
      }
    default:
      return state
  }
}
