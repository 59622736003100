import React, { useState }  from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { Form as BForm, Button, Row, Col, Tooltip, OverlayTrigger } from 'react-bootstrap'
import EntitySelect from 'src/Components/EntitySelect'
import { EMAIL_NAME } from 'src/Services/Constants/Config/EmailTemplate'
import { setDeletingItem } from '../state/actions'

const Form = ({
  trans,
  onSubmit = () => {},
  item = null
}) => {

  const dispatch = useDispatch()
  const { workflowActionTypes, messageTemplates: templatesList } = useSelector(state => state.Dictionary)
  const [ emailTemplate, setEmailTemplate ] = useState(item?.messageTemplate?.id)

  const onChange = template => {
    setEmailTemplate(template)
  }
  const onFormSubmit = e => {
    e.preventDefault()
    onSubmit({
      name: e.target.name.value,
      label: e.target.label.value,
      type: e.target.type.value || workflowActionTypes[0],
      code: e.target.code.value || null,
      field: e.target.field.value|| null,
      fieldValue: e.target.fieldValue.value || null,
      messageTemplate: emailTemplate || null,
      attachmentName: e.target.attachmentName.value || null
    })
  }

  const onDeleteClick = () => dispatch(setDeletingItem(item))

  return (
    <BForm onSubmit={onFormSubmit}>
      <Row className={'mb-2'}>
        <Col>
          <BForm.Group>
            <BForm.Label>{trans('form.name')}</BForm.Label>
            <BForm.Control defaultValue={item?.name} name={'name'} required type="text"
                          placeholder={trans('form.name')}/>
          </BForm.Group>
        </Col>
        <Col>
          <BForm.Group>
            <BForm.Label>{trans('form.label')}</BForm.Label>
            <BForm.Control defaultValue={item?.label} name={'label'} required type="text"
                          placeholder={trans('form.label')}/>
          </BForm.Group>
        </Col>
        <Col>
          <BForm.Group>
            <BForm.Label>{trans('form.type')}</BForm.Label>
            <BForm.Select defaultValue={item?.type} name={'type'} required aria-label={trans('form.type')}>
              { workflowActionTypes.map(_ => <option key={_} value={_}>{ _ }</option>) }
            </BForm.Select>
          </BForm.Group>
        </Col>
      </Row>

      <Row className={'mb-2'}>
        <Col>
          <BForm.Group>
            <BForm.Label>{trans('form.code')}</BForm.Label>
            <BForm.Control as={'textarea'} defaultValue={item?.code} name={'code'} placeholder={trans('form.code')}/>
          </BForm.Group>
        </Col>
      </Row>

      <Row className={'mb-2'}>
        <Col>
          <BForm.Group>
            <BForm.Label>{trans('form.field')}</BForm.Label>
            <EntitySelect value={item?.field || null}
                          name={'field'}
                          clearable
                          id={'workflowActionFormFieldSelect'}
                          fetchUrl={'/fields'}
                          valueKey={'id'}
                          getOptionLabel={o => <span>{o.systemName}&nbsp;
                            <small className={'text-muted'}>{o.form?.systemName}</small>
                          </span>}
            />
          </BForm.Group>
        </Col>
        <Col>
          <BForm.Group>
            <BForm.Label>
              {trans('form.fieldValue')}
              <OverlayTrigger
                key="top"
                placement="top"
                overlay={
                  <Tooltip
                    id={'setFieldId'}
                  >
                    <p>{ trans('fieldTooltipTitle') }</p>
                    <ul>
                      <li>{ trans('fieldTooltipItem1') }</li>
                      <li>{ trans('fieldTooltipItem2') }</li>
                      <li>{ trans('fieldTooltipItem3') }</li>
                    </ul>
                  </Tooltip>
                }
              >
                <i className="fa-solid fa-circle-info ms-2"></i>
              </OverlayTrigger>
            </BForm.Label>
            <BForm.Control defaultValue={item?.fieldValue} name={'fieldValue'}
                           placeholder={trans('form.fieldValue')} />
          </BForm.Group>
        </Col>
      </Row>

      <Row className={'mb-2'}>
        <Col>
          <BForm.Group>
            <BForm.Label>{trans('form.messageTemplate')}</BForm.Label>
            { templatesList &&
              <Select
              id="default-template"
              name="defaultTemplate"
              isClearable={ true }
              options={ templatesList }
              getOptionValue={ field => field.values[EMAIL_NAME] }
              getOptionLabel={ field => field.values[EMAIL_NAME] }
              value={ templatesList?.find(row => row.id === item?.messageTemplate?.id) }
              placeholder={ trans('form.defaultTemplatePlaceholder') }
              onChange={ selected => onChange(selected?.id) }
            />
            }
          </BForm.Group>
        </Col>
        <Col>
          <BForm.Group>
            <BForm.Label>{trans('form.attachmentName')}</BForm.Label>
            <BForm.Control defaultValue={item?.attachmentName} name={'attachmentName'}
                           placeholder={trans('form.attachmentName')} disabled />
          </BForm.Group>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col>
          <Button type={'button'} variant={'danger'} onClick={onDeleteClick}>
            { trans('delete') }
            <i className="ms-2 bi bi-trash3"></i>
          </Button>
        </Col>
        <Col>
          <Button variant={'success'} style={{ float: 'right' }} type={'submit'}>
            {trans('submit')}
            <i className="ms-2 bi bi-check-circle"></i>
          </Button>
        </Col>
      </Row>
    </BForm>
  )
}

export default Form
