import React from 'react'
import { Card } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { translateConf } from 'src/Services/translation'
import {
  PAGE_CONTENT_TYPE_ALL_LISTS, PAGE_CONTENT_TYPE_LIST,
  PAGE_CONTENT_TYPE_NUMERIC_REPORT,
  PAGE_CONTENT_TYPE_RECENT_INSTANCES, PAGE_CONTENT_TYPE_RECENT_LISTS
} from 'src/Services/Constants'
import ListResultTable from 'src/Components/ListResultTable/index'
import Lists from './Lists/index'
import RecentViewed from './RecentViewed'
import RecentLists from './RecentLists'


const LandingPageContent = ({ content }) => {
  const { configTranslations } = useSelector(state => state.Root)

  const transConf = translateConf(configTranslations)

  const getContent = () => {
    switch (content.type) {
      case PAGE_CONTENT_TYPE_LIST:
        return <ListResultTable id={`content${content.id}list${content.list.id}`} listId={content.list.id} />
      case PAGE_CONTENT_TYPE_ALL_LISTS:
        return <Lists/>
      case PAGE_CONTENT_TYPE_NUMERIC_REPORT:
        return <>Numeric report not implemented yet</>
      case PAGE_CONTENT_TYPE_RECENT_INSTANCES:
        return <RecentViewed/>
      case PAGE_CONTENT_TYPE_RECENT_LISTS:
        return <RecentLists/>
      default:
        return <>Content type unrecognized</>
    }
  }

  return <Card>
    <Card.Header>
      <Card.Title className="m-0 py-2">{ transConf('LANDING_PAGE')(content) }</Card.Title>
    </Card.Header>
    <Card.Body>
      { getContent() }
    </Card.Body>
  </Card>
}

export default LandingPageContent
