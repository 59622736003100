import * as Sentry from '@sentry/react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { translate } from 'src/Services/translation'
import { hasUserModulePermission } from 'src/Utils'
import {
  MODULE_PATIENT_SEARCH_ALL, MODULE_PATIENT_SEARCH_LOCATION, MODULE_PATIENT_SEARCH_REFERS_TO_ME,
} from 'src/Services/Constants/Config/Modules'
import { requestLogout } from 'src/Views/Auth/state/actions'
import translations from '../translations'
import HeaderSearch from '../HeaderSearch'
import { PermissionAccessLevel } from 'src/Types/Permission'
import { StoreState } from 'src/Services/Store/reducers'

const NavbarEnd = () => {
  const dispatch = useDispatch()

  const {
    username,
    impersonatorUser,
    language,
    personInstance,
  } = useSelector((state: StoreState) => state.Root.user)
  const user = useSelector((state: StoreState) => state.Root.user)

  const trans = translate(translations)(language)

  const onStopImpersonateClick = () => {
    localStorage.removeItem('impersonatedUser')
    window.location.href = '/users'
  }
  const onLogoutClick = () => {
    localStorage.removeItem('impersonatedUser')
    Sentry.setUser(null)
    dispatch(requestLogout(false))
  }

  return (
    <Nav className="me-4">
      { (hasUserModulePermission(user, MODULE_PATIENT_SEARCH_ALL, PermissionAccessLevel.READ) ||
        hasUserModulePermission(user, MODULE_PATIENT_SEARCH_LOCATION, PermissionAccessLevel.READ) ||
        hasUserModulePermission(user, MODULE_PATIENT_SEARCH_REFERS_TO_ME, PermissionAccessLevel.READ)) &&
        <HeaderSearch/>
      }
      <Dropdown className="m-auto navbar-user">
        <Dropdown.Toggle as={ Nav.Link } className="is-transparent">
          <i className="far fa-user me-1"/>
          { username }
        </Dropdown.Toggle>
        <Dropdown.Menu className={'dropdown-menu-end'}>
          { impersonatorUser && <>
            <Dropdown.Item onClick={ () => onStopImpersonateClick() }>
              { trans('goBackAsYourself')(impersonatorUser.username || impersonatorUser.email) }
            </Dropdown.Item><Dropdown.Divider/>
          </>
          }
          <Dropdown.Item as={ Link } to={ `/instance/${personInstance?.id}?customTitle=${trans('myAccount')}` }>
            <i className="fas fa-user-cog me-1"/>
            { trans('manageMyAccount') }
          </Dropdown.Item>
          <Dropdown.Item onClick={ onLogoutClick }
                         onKeyDown={ e => e.key === 'Enter' && onLogoutClick() }
          >
            <i className="fas fa-sign-out-alt me-1"/>
            { trans('logout') }
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Nav>
  )
}

export default NavbarEnd
