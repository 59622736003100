export const FETCH_ITEMS = '[EVENT_CANCELLATION] FETCH_ITEMS'
export const FETCH_ITEM = '[EVENT_CANCELLATION] FETCH_ITEM'
export const CREATE_ITEM = '[EVENT_CANCELLATION] CREATE_ITEM'
export const DELETE_ITEM = '[EVENT_CANCELLATION] DELETE_ITEM'
export const UPDATE_ITEM = '[EVENT_CANCELLATION] UPDATE_ITEM'

export const ITEMS_RECEIVED = '[EVENT_CANCELLATION] ITEMS_RECEIVED'
export const ITEM_RECEIVED = '[EVENT_CANCELLATION] ITEM_RECEIVED'
export const ITEM_CREATED = '[EVENT_CANCELLATION] ITEM_CREATED'
export const ITEM_DELETED = '[EVENT_CANCELLATION] ITEM_DELETED'
export const ITEM_UPDATED = '[EVENT_CANCELLATION] ITEM_UPDATED'

export const SET_DELETING_ITEM = '[EVENT_CANCELLATION] SET_DELETING_ITEM'
export const SET_UPDATING_ITEM = '[EVENT_CANCELLATION] SET_UPDATING_ITEM'
export const SET_CREATING_ITEM = '[EVENT_CANCELLATION] SET_CREATING_ITEM'

export const SET_QUERY = '[EVENT_CANCELLATION] SET_QUERY'
export const SET_LIMIT = '[EVENT_CANCELLATION] SET_LIMIT'
export const SET_PAGE = '[EVENT_CANCELLATION] SET_PAGE'
