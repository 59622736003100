import * as actions from './actionTypes'

export const fetchListSearchesAction = id => ({
  type: actions.FETCH_LIST_SEARCHES,
  payload: id
})

export const setListSearchesAction = data => ({
  type: actions.SET_LIST_SEARCHES,
  payload: data
})

export const postListSearchAction = (listId, data, title) => ({
  type: actions.POST_LIST_SEARCH,
  payload: { listId, data, title }
})

export const deleteListSearchAction = (listId, id) => ({
  type: actions.DELETE_LIST_SEARCH,
  payload: { listId, id }
})

export const requestImport = () => ({
  type: actions.REQUEST_IMPORT
})

export const cancelImport = () => ({
  type: actions.CANCEL_IMPORT
})

export const doImport = items => ({
  type: actions.DO_IMPORT,
  payload: items
})
