import { useDispatch, useSelector } from 'react-redux'
import { translate } from 'src/Services/translation'
import translation from '../translations'
import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select'
import SectionContent from './SectionContent'
import { setNewSubjectData } from '../state/actions'
import { getFormPermissions } from '../selectors'
import ValidationTrigger from '../../../Components/ValidationTrigger/index'
import { PermissionSubject } from 'src/Views/Permissions/Types/Subject'
import FormPermission, { FormPermissionAccessLevel } from 'src/Types/FormPermission'
import { StoreState } from 'src/Services/Store/reducers'

export interface FormPermissionFormProps {
  permission: FormPermission
  remainingForms: any
  index: any
}

const FormPermissionForm = ({ permission, remainingForms = [], index }: FormPermissionFormProps) => {
  const dispatch = useDispatch()

  const { language } = useSelector((state: StoreState) => state.Root.user)
  const state = useSelector((state: StoreState) => state.Permissions)

  const formPermissions = getFormPermissions(state)
  const { user, forms } = state

  const trans = translate(translation)(language)

  const getCurrentForm = () => forms.find(f => f.id === permission.form.id) || null

  const onFieldChange = (field: string) => (value: any) => {
    const newPermission = ({ ...permission, [field]: value })
    const newPermissions = formPermissions.map((p: FormPermission, i: number) => i === index ? newPermission : p)

    dispatch(setNewSubjectData(newPermissions, PermissionSubject.FORM))
  }

  const getAccessLevelLabelByValue = (value: FormPermissionAccessLevel) =>
      Object.keys(FormPermissionAccessLevel)
          .find((key: keyof typeof FormPermissionAccessLevel) => FormPermissionAccessLevel[key] === value)

  return <>
    <Row>
      <Col>
        <Form.Group>
          <Form.Label>{trans('form')}</Form.Label>
          <Select
              options={remainingForms}
              getOptionLabel={o => o?.systemName}
              getOptionValue={o => o.id?.toString()}
              value={permission.form}
              onChange={onFieldChange('form')}
              isDisabled={Boolean(user) || !!permission.id}
          />
          <ValidationTrigger isRequired={true} isValid={Boolean(permission?.form?.systemName)}/>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Label>{trans('defaultSection')}</Form.Label>
          <Select options={getCurrentForm()?.sections || []}
                  getOptionLabel={o => o.name}
                  getOptionValue={o => (o.id as string)}
                  value={permission.defaultSection}
                  onChange={onFieldChange('defaultSection')}
                  isDisabled={Boolean(user) || !permission.form}
          />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Label>{trans('accessLevel')}</Form.Label>
          <Select
              options={
                Object.values(FormPermissionAccessLevel)
                    .filter(o => !isNaN(Number(o)))
                    .map(o => ({ value: (o as FormPermissionAccessLevel) }))
              }
              value={({ value: permission.accessLevel })}
              onChange={(o: any) => onFieldChange('accessLevel')(o.value)}
              getOptionLabel={(o: any) => trans(`formAccessLevels.${getAccessLevelLabelByValue(o.value)}`)}
              /* @ts-ignore */
              title={trans('accessLevel')}
              isDisabled={Boolean(user)}
          />
        </Form.Group>
      </Col>
    </Row>
    <Row className={'mt-2'}>
      <Col>
        <Form.Group>
          <Form.Switch name={'grantAccessToInstancesCreatedByMe'}
                       label={trans('grantAccessToInstancesCreatedByMe')}
                       checked={permission.grantAccessToInstancesCreatedByMe || false}
                       onChange={e => onFieldChange('grantAccessToInstancesCreatedByMe')(e.target.checked)}
                       disabled={Boolean(user)}
          />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Switch name={'grantAccessToInstancesThatRefersToMe'}
                       label={trans('grantAccessToInstancesThatRefersToMe')}
                       checked={permission.grantAccessToInstancesThatRefersToMe || false}
                       onChange={e => onFieldChange('grantAccessToInstancesThatRefersToMe')(e.target.checked)}
                       disabled={Boolean(user)}
          />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Switch name={'grantAccessToAllLocations'}
                       label={trans('grantAccessToAllLocations')}
                       checked={permission.grantAccessToAllLocations || false}
                       onChange={e => onFieldChange('grantAccessToAllLocations')(e.target.checked)}
                       disabled={Boolean(user)}
          />
        </Form.Group>
      </Col>
    </Row>

    <Form.Label>{trans('sections')}</Form.Label>
    {getCurrentForm() && <SectionContent form={getCurrentForm()}/>}
  </>
}

export default FormPermissionForm
