import { append, filter, map } from 'ramda'
import * as types from './actionTypes'

export const initialState = {
  id: null,
  patient: null,
  isAddMenuOpen: false,
  sidebarEvents: [],
  instanciatedForms: [],
  patientRelatedForms: [],
  isSectionNav: false,
  htmlDetails: '',
  detailsOverviewStatus: '',
  encounters: [],
  fetchEncountersStatus: '',
  documents: [],
  isFetchingDocuments: false,
  hasDocumentsFetchError: false,
  documentsToDelete: [],
  documentsDeleteError: [],
  editedDocumentsName: []
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.INIT:
      return {
        ...state,
        id: payload.id,
      }
    case types.RESET:
      return initialState
    case types.SET_PATIENT:
      return {
        ...state,
        patient: payload.patient,
      }
    case types.TOGGLE_ADD_MENU:
      return {
        ...state,
        isAddMenuOpen: !state.isAddMenuOpen
      }
    case types.SET_IS_ADD_MENU_OPEN:
      return {
        ...state,
        isAddMenuOpen: payload
      }
    case types.SET_SIDEBAR_EVENTS:
      return {
        ...state,
        sidebarEvents: payload
      }
    case types.SET_INSTANCIATED_FORMS:
      return {
        ...state,
        instanciatedForms: payload
      }
    case types.SET_IS_SECTION_NAV:
      return {
        ...state,
        isSectionNav: payload
      }
    case types.FETCH_DETAILS_OVERVIEW:
      return {
        ...state,
        detailsOverviewStatus: 'ongoing'
      }
    case types.FETCH_DETAILS_OVERVIEW_FAILED:
      return {
        ...state,
        detailsOverviewStatus: 'fail'
      }
    case types.FETCH_DETAILS_OVERVIEW_SUCCEEDED:
      return {
        ...state,
        detailsOverviewStatus: 'success',
        htmlDetails: payload
      }
    case types.SET_PATIENT_RELATED_FORMS:
      return {
        ...state,
        patientRelatedForms: payload
      }
    case types.FETCH_ENCOUNTERS:
      return {
        ...state,
        fetchEncountersStatus: 'ongoing'
      }
    case types.SET_ENCOUNTERS:
      return {
        ...state,
        encounters: payload,
        fetchEncountersStatus: 'success'
      }
    case types.FETCH_DOCUMENTS:
      return {
        ...state,
        isFetchingDocuments: true,
        hasDocumentsFetchError: false
      }
    case types.DOCUMENTS_FETCHED:
      return {
        ...state,
        isFetchingDocuments: false,
        documents: payload
      }
    case types.DOCUMENTS_FETCH_ERROR:
      return {
        ...state,
        isFetchingDocuments: false,
        hasDocumentsFetchError: true
      }
    case types.DELETE_DOCUMENT:
      return {
        ...state,
        documentsToDelete: append(payload, state.documentsToDelete),
        documentsDeleteError: filter(
          docId => docId !== payload,
          state.documentsDeleteError
        )
      }
    case types.DOCUMENT_DELETED:
      return {
        ...state,
        documents: filter(
          doc => doc.id !== payload,
          state.documents
        ),
        documentsToDelete: filter(
          docId => docId !== payload,
          state.documentsToDelete
        )
      }
    case types.DOCUMENT_DELETE_ERROR:
      return {
        ...state,
        documentsToDelete: filter(
          docId => docId !== payload,
          state.documentsToDelete
        ),
        documentsDeleteError: append(payload, state.documentIdToDelete)
      }
    case types.EDIT_DOCUMENT_NAME:
      return {
        ...state,
        editedDocumentsName: append(
          {
            documentId: payload.documentId,
            documentName: payload.documentName,
            hasError: false,
            isSaving: false
          },
          state.editedDocumentsName
        )
      }
    case types.CHANGE_DOCUMENT_NAME:
      return {
        ...state,
        editedDocumentsName: map(
          editedDoc => editedDoc.documentId === payload.documentId
            ? {
              ...editedDoc,
              documentName: payload.documentName
            }
            : editedDoc,
          state.editedDocumentsName
        )
      }
    case types.SAVE_DOCUMENT_NAME:
      return {
        ...state,
        editedDocumentsName: map(
          editedDoc => editedDoc.documentId === payload.documentId
            ? {
              ...editedDoc,
              isSaving: true,
              hasError: false
            }
            : editedDoc,
          state.editedDocumentsName
        )
      }
    case types.DOCUMENT_NAME_SAVE_ERROR:
      return {
        ...state,
        editedDocumentsName: map(
          editedDoc => editedDoc.documentId === payload
            ? {
              ...editedDoc,
              isSaving: false,
              hasError: true
            }
            : editedDoc,
          state.editedDocumentsName
        )
      }
    case types.DOCUMENT_NAME_SAVED:
      return {
        ...state,
        documents: map(
          doc => doc.id === payload.documentId
            ? {
              ...doc,
              name: payload.documentName
            }
            : doc,
          state.documents
        ),
        editedDocumentsName: filter(
          editedDoc => editedDoc.documentId !== payload.documentId,
          state.editedDocumentsName
        )
      }
    default:
      return state
  }
}
