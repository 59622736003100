import React from 'react'
import { Route, Routes } from 'react-router-dom'
import View from 'src/Layouts/View'
import Homepage from 'src/Views/Homepage'
import Announcements from 'src/Views/Announcements'
import About from 'src/Views/About'
import AdminTools from 'src/Views/AdminTools'
import Calendar from 'src/Views/Calendar'
import EventTypes from 'src/Views/EventTypes'
import FieldTypes from 'src/Views/FieldTypes'
import FormList from 'src/Views/FormList'
import FormEditor from 'src/Views/FormEditor'
import FormFiller from 'src/Views/FormFiller'
import Lists from 'src/Views/Lists'
import ListEdit from 'src/Views/ListEdit'
import ListRun from 'src/Views/ListRun'
import Permissions from 'src/Views/Permissions'
import Patient from 'src/Views/Patient'
import PdfEdit from 'src/Views/PdfEdit'
import Reports from 'src/Views/Reports'
import Translations from 'src/Views/Translations'
import LandingPages from 'src/Views/LandingPages'
import ManageSystemFields from 'src/Views/SystemFields'
import MessageConfigs from 'src/Views/MessageConfigs/index'
import SchedulingEvents from 'src/Views/SchedulingEvents'
import SlotTypes from 'src/Views/SlotTypes'
import TasksCategories from 'src/Views/TasksCategories'
import TasksEverybodyTasks from 'src/Views/TasksEverybodyTasks'
import TasksMyTasks from 'src/Views/TasksMyTasks'
import TasksPage from 'src/Views/TasksPage'
import TasksTypes from 'src/Views/TasksTypes'
import TasksStatus from 'src/Views/TasksStatus'
import UserForm from 'src/Views/UserForm'
import UserList from 'src/Views/UserList/index'
import Modules from 'src/Views/Modules'
import NavigationItems from 'src/Views/NavigationItems'
import Icons from 'src/Views/Icons'
import Workflows from 'src/Views/Workflows'
import RestoreDeletedInstances from 'src/Views/RestoreDeletedInstances'
import Page404 from 'src/Views/404'
import ImportInstances from 'src/Views/ImportInstances/index'
import EventStatuses from 'src/Views/EventStatuses/index'
import EventCancellationReasons from 'src/Views/EventCancellationReasons'
import {
  NAVIGATION_ITEM_ABOUT,
  NAVIGATION_ITEM_ADMIN_TOOLS,
  NAVIGATION_ITEM_ANNOUNCEMENTS,
  NAVIGATION_ITEM_DASHBOARD,
  NAVIGATION_ITEM_MESSAGE_CONFIGS,
  NAVIGATION_ITEM_EVENT_TYPES,
  NAVIGATION_ITEM_EVENT_CANCELLATION_REASONS,
  NAVIGATION_ITEM_EVERYBODY_TASKS,
  NAVIGATION_ITEM_FIELD_TYPES,
  NAVIGATION_ITEM_FORMS,
  NAVIGATION_ITEM_ICONS,
  NAVIGATION_ITEM_LISTS,
  NAVIGATION_ITEM_MODULES,
  NAVIGATION_ITEM_MY_TASKS,
  NAVIGATION_ITEM_NUMERIC_REPORTS,
  NAVIGATION_ITEM_PERMISSIONS,
  NAVIGATION_ITEM_RESTORER,
  NAVIGATION_ITEM_SCHEDULING,
  NAVIGATION_ITEM_SLOT_TYPES,
  NAVIGATION_ITEM_TASK_CATEGORIES,
  NAVIGATION_ITEM_TASK_STATUS,
  NAVIGATION_ITEM_TASK_TYPES,
  NAVIGATION_ITEM_TASKS,
  NAVIGATION_ITEM_TRANSLATIONS,
  NAVIGATION_ITEM_USERS,
  NAVIGATION_ITEM_WORKFLOWS,
  NAVIGATION_ITEM_LANDING_PAGES,
  NAVIGATION_ITEM_NAVIGATION_ITEMS,
  NAVIGATION_ITEM_HOMEPAGE,
  NAVIGATION_ITEM_FORM_FILLER,
  NAVIGATION_ITEM_FORM_EDITOR,
  NAVIGATION_ITEM_LIST_RUNNER,
  NAVIGATION_ITEM_LIST_EDITOR,
  NAVIGATION_ITEM_PATIENT,
  NAVIGATION_ITEM_PDF_EDITOR,
  NAVIGATION_ITEM_REPORT_RUNNER,
  NAVIGATION_ITEM_SYSTEM_FIELD_EDITOR,
  NAVIGATION_ITEM_CALENDAR, NAVIGATION_ITEM_IMPORT_INSTANCES,
  NAVIGATION_ITEM_USER_EDITOR, NAVIGATION_ITEM_USER_CREATOR, NAVIGATION_ITEM_EVENT_STATUSES,
  NAVIGATION_ITEM_AUDIT_TRAILS
} from '../Constants'
import AuditTrails from 'src/Views/AuditTrails'

export interface Route {
  path: string
  component: () => JSX.Element
  navigationItem?: string | null
  autoRefresh?: boolean
  name?: string | null
}

export const routesMap: Route[] = [
  {
    path: '/',
    component: Homepage,
    navigationItem: NAVIGATION_ITEM_HOMEPAGE,
    autoRefresh: true
  },
  {
    path: '/announcements',
    component: Announcements,
    navigationItem: NAVIGATION_ITEM_ANNOUNCEMENTS
  },
  {
    path: '/about',
    component: About,
    navigationItem: NAVIGATION_ITEM_ABOUT
  },
  {
    path: '/about/:legalPage?',
    component: About,
    navigationItem: NAVIGATION_ITEM_ABOUT
  },
  {
    path: '/admin_tools',
    component: AdminTools,
    navigationItem: NAVIGATION_ITEM_ADMIN_TOOLS
  },
  {
    path: '/audit_trails',
    component: AuditTrails,
    navigationItem: NAVIGATION_ITEM_AUDIT_TRAILS
  },
  {
    path: '/events/calendar',
    component: Calendar,
    navigationItem: NAVIGATION_ITEM_SCHEDULING
  },
  {
    path: '/event_types',
    component: EventTypes,
    navigationItem: NAVIGATION_ITEM_EVENT_TYPES
  },
  {
    path: '/event_statuses',
    component: EventStatuses,
    navigationItem: NAVIGATION_ITEM_EVENT_STATUSES
  },
  {
    path: '/event_cancellation_reasons',
    component: EventCancellationReasons,
    navigationItem: NAVIGATION_ITEM_EVENT_CANCELLATION_REASONS
  },
  {
    path: '/field_types',
    component: FieldTypes,
    navigationItem: NAVIGATION_ITEM_FIELD_TYPES
  },
  {
    path: '/forms',
    component: FormList,
    navigationItem: NAVIGATION_ITEM_FORMS
  },
  {
    path: '/form/:id',
    component: FormEditor,
    navigationItem: NAVIGATION_ITEM_FORM_EDITOR
  },
  {
    path: '/instance/:id',
    component: FormFiller,
    navigationItem: NAVIGATION_ITEM_FORM_FILLER
  },
  {
    path: '/lists',
    component: Lists,
    navigationItem: NAVIGATION_ITEM_LISTS
  },
  {
    path: '/list/:id',
    component: ListEdit,
    navigationItem: NAVIGATION_ITEM_LIST_EDITOR
  },
  {
    path: '/list/:id/run',
    component: ListRun,
    navigationItem: NAVIGATION_ITEM_LIST_RUNNER,
    autoRefresh: true
  },
  {
    path: '/permissions',
    component: Permissions,
    navigationItem: NAVIGATION_ITEM_PERMISSIONS
  },
  {
    path: '/permissions/:entity/:id',
    component: Permissions,
    navigationItem: NAVIGATION_ITEM_PERMISSIONS
  },
  {
    path: '/patient/:id',
    component: Patient,
    navigationItem: NAVIGATION_ITEM_PATIENT,
    autoRefresh: true
  },
  {
    path: '/patient/:id/:openedPane',
    component: Patient,
    navigationItem: NAVIGATION_ITEM_PATIENT,
    autoRefresh: true
  },
  {
    path: '/patient/:id/instance/:instanceId',
    component: Patient,
    navigationItem: NAVIGATION_ITEM_PATIENT,
    autoRefresh: true
  },
  {
    path: '/pdf',
    component: PdfEdit,
    navigationItem: NAVIGATION_ITEM_PDF_EDITOR
  },
  {
    path: '/reports',
    component: Reports,
    navigationItem: NAVIGATION_ITEM_NUMERIC_REPORTS
  },
  {
    path: '/translations',
    component: Translations,
    navigationItem: NAVIGATION_ITEM_TRANSLATIONS
  },
  {
    path: '/landing_pages',
    component: LandingPages,
    navigationItem: NAVIGATION_ITEM_LANDING_PAGES
  },
  {
    path: '/system_fields/manage_system_fields/:id',
    component: ManageSystemFields,
    navigationItem: NAVIGATION_ITEM_SYSTEM_FIELD_EDITOR
  },
  {
    path: '/scheduling/events',
    component: SchedulingEvents,
    navigationItem: NAVIGATION_ITEM_CALENDAR
  },
  {
    path: '/slot_types',
    component: SlotTypes,
    navigationItem: NAVIGATION_ITEM_SLOT_TYPES
  },
  {
    path: '/tasks_categories',
    component: TasksCategories,
    navigationItem: NAVIGATION_ITEM_TASK_CATEGORIES
  },
  {
    path: '/tasks_everybody_tasks',
    component: TasksEverybodyTasks,
    navigationItem: NAVIGATION_ITEM_EVERYBODY_TASKS
  },
  {
    path: '/tasks_my_tasks',
    component: TasksMyTasks,
    navigationItem: NAVIGATION_ITEM_MY_TASKS
  },
  {
    path: '/tasks',
    component: TasksPage,
    navigationItem: NAVIGATION_ITEM_TASKS
  },
  {
    path: '/tasks_types',
    component: TasksTypes,
    navigationItem: NAVIGATION_ITEM_TASK_TYPES
  },
  {
    path: '/tasks_status',
    component: TasksStatus,
    navigationItem: NAVIGATION_ITEM_TASK_STATUS
  },
  {
    path: '/user/:id',
    component: UserForm,
    navigationItem: NAVIGATION_ITEM_USER_EDITOR
  },
  {
    path: '/user/new',
    component: UserForm,
    navigationItem: NAVIGATION_ITEM_USER_CREATOR
  },
  {
    path: '/users',
    component: UserList,
    navigationItem: NAVIGATION_ITEM_USERS
  },
  {
    path: '/modules',
    component: Modules,
    navigationItem: NAVIGATION_ITEM_MODULES
  },
  {
    path: '/navigation_items',
    component: NavigationItems,
    navigationItem: NAVIGATION_ITEM_NAVIGATION_ITEMS
  },
  {
    path: '/icons',
    component: Icons,
    navigationItem: NAVIGATION_ITEM_ICONS
  },
  {
    path: '/import_instances',
    component: ImportInstances,
    navigationItem: NAVIGATION_ITEM_IMPORT_INSTANCES
  },
  {
    path: '/message_configs',
    component: MessageConfigs,
    navigationItem: NAVIGATION_ITEM_MESSAGE_CONFIGS
  },
  {
    path: '/workflows',
    component: Workflows,
    navigationItem: NAVIGATION_ITEM_WORKFLOWS
  },
  {
    path: '/restorer',
    component: RestoreDeletedInstances,
    navigationItem: NAVIGATION_ITEM_RESTORER
  },
  {
    path: '*',
    component: Page404,
    navigationItem: null,
    name: '404'
  }
]

const RoutesMapper = () => {
  const mappedRoutes = routesMap.map(route =>
    <Route path={ route.path }
           key={ route.path }
           element={ <View route={ route } /> }
    />)

  return <Routes>{ mappedRoutes }</Routes>
}

export default RoutesMapper
