export default {
  english: {
    backToList: 'Back to list',
    scripts: 'Scripts',
    viewDetails: 'View details',
    lockedTooltip: 'You can\'t edit a locked form. Somebody else is editing this form.',
    formType: 'Form type',
    systemFields: 'System fields',
    configureConditionalActionsButton: 'Conditions',
    configureConditionalActions: 'Configure conditional actions',
    saveChoices: {
      exportAllSections: 'Export all sections',
      exportAllFields: 'Export all fields'
    },
    tooltipMaxConstraintDesc: 'If Max is empty, no maximum constraint is set',
    backToFields: 'Back to fields',
    onPageLoadCodeInfo: 'Params available : e, user, instance, patient, Axios, actions',
    titleTemplate: {
      viewTitle: 'Title template',
      removeButtonTitle: 'Remove last element'
    },
    editField: {
      addSysField: 'Add system field',
      calculationField: 'Calculation Field',
      systemName: 'System name',
      headingField: 'Label',
      necessary: 'Necessary',
      readOnly: 'Read Only',
      rowSize: 'Size',
      showDate: 'Show date',
      showTime: 'Show time',
      showClearButton: 'Show clear button',
      showAddButton: 'Show add button',
      autocompleteAtCreation: 'Autocomplete at creation',
      autocompleteAtCreationInfo: 'Will autocomplete with user\'s location if he has one or by its primary role if types match',
      showEditButton: 'Show edit button',
      sortAlpha: 'Sort alphabetically',
      selfPopulation: 'Self population',
      userTextResponse: 'User text response',
      setNowAtCreation: 'Set now at creation',
      setNowAtModification: 'Set now at modification',
      setAtCreation: 'Set at creation',
      setAtModification: 'Set at modification',
      alignOptionsVertically: 'Align options vertically',
      excludeFromClone: 'Exclude from clone',
      validateUserIdentity: 'Validate user identity',
      isSensitive: 'Sensitive data',
      isValidator: 'Validates JSON format',
      width: 'Width',
      labelHover: 'Label Hover',
      isTemplateCreator: 'Is template creator',
      defaultTemplate: 'Default template',
      defaultTemplatePlaceholder: 'Default template',
      consentFields: 'Ask consents',
      defaultConsentFieldsPlaceholder: 'consents list',
      fileField: 'File field to associate',
      fileFieldPlaceholder: 'System field',
      defaultValue: 'Default value',
      defaultValuePlaceholder: 'Default value',
      defaultOption: 'Default value',
      defaultOptionPlaceholder: 'Default value system name',
      defaultOptionInfo: 'Use the system name of the value you want to be selected by default (for multiple values, separate with commas)',
      values: 'Values',
      autocompleteFields: 'Autocomplete fields',
      addValue: 'Add a value',
      importValues: 'Import values',
      editList: 'Edit list',
      fileType: 'File type',
      fieldId: 'Field ID',
      rowId: 'Row ID',
      onClickCode: 'On click JS code',
      onChangeCode: 'On change JS code',
      onBlurCode: 'On blur JS code',
      onClickCodeInfo: 'Params available : e, user, instance, patient, Axios, actions',
      onChangeCodeInfo: 'Params available : e, user, instance, patient, Axios, actions',
      onBlurCodeInfo: 'Params available : e, user, instance, patient, Axios, actions',
      fileTypeSetting: {
        title: 'File type constraints',
        fileType: 'Type',
        allType: 'All Type',
        max: 'Max',
        min: 'Min',
        add: 'Add constraint'
      },
      openValuesModalButton: 'Manage values',
      content: 'Content',
      contentPlaceholder: 'Content to display',
      fontColor: 'Font color',
      fontColorPlaceholder: 'Font color',
      fontSize: 'Font size',
      fontSizePlaceholder: 'Font size',
      cssClass: 'CSS Class',
      cssClassInfoButton: 'Infos',
      cssClassInfoHeader: 'Classes available',
      cssClassInfoBody: 'text-center, text-end, font-weight-bold, font-italic, text-underline, bg-primary, ...',
      cssClassPlaceholder: 'Classes separated by a space',
      redirectToHomePage: 'Redirect to Homepage',
      description: 'Description',
      saveTypeLabel: 'Save type',
      saveTypePlaceholder: 'Select...',
      saveType: {
        saveOnly: 'Save only',
        saveAndSign: 'Save and sign',
        saveAsFinal: 'Save as final',
        saveAndSignWithPinCode: 'Save and sign with pin code',
        saveAsFinalWithPinCode: 'Save as final with pin code'
      },
      documentType: 'Document type'
    },
    basicFields: 'Basic fields',
    fieldTypes: 'Field Types',
    advancedFields: 'Advanced fields',
    viewAll: 'View all',
    details: {
      formLabel: 'Form label',
      formSystemName: 'Form system name',
      addInstance: 'Add instance',
      defaultAppointmentTime: 'Default appointment time',
      defaultDuration: 'Default duration',
      eventBackgroundColor: 'Event background-color',
      needSlot: 'Need slot',
      restrictQEToSidebar: 'Restrict QE to Sidebar',
      useQuickEntryModal: 'Use Quick Entry Modal',
      showInSidebarAddDropdown: 'Show in Sidebar Add Dropdown',
      isResource: 'Is resource',
      isPatientResource: 'show in Patient sidebar add button',
      biosDevices: 'Bioserenity devices',
      biosDevicesButton: 'Configure',
      msSanteFeature: 'MS Santé shipment',
      msSanteFeatureButton: 'Configure'
    },
    swpConfig: {
      title: 'Configure Bioserenity device',
      multiple: 'multiple',
      productFieldLabel: 'Product',
      fieldDetected: 'Detected',
      mustContainAField: 'Must contain a system field named',
      mustContainFields: 'Must contain system fields starting with',
      helpInformations: 'Those requirements should be met using system fields that are named the same way ' +
        '(excluding the "#MY_FORM_NAME").',
      product: {
        eeg: 'Neuronaute',
        ecg: 'Cardioskin'
      },
      field: {
        eeg: {
          sessionIdField: 'Session ID (text)',
          accessTokensField: 'Access tokens (text)',
          openDeviceButtons: 'Open device (button)',
          openPlaybackButtons: 'Open playback (button)',
          openMonitorButtons: 'Open monitoring (button)',
          productSerialNumberField: 'Product serial number (text)',
          startRecordAtField: 'Start record at (date time)',
          endRecordAtField: 'End record at (date time)',
          recordDurationField: 'Record duration (text)',
          sessionStatus: 'Session status (text)',
        },
        ecg: {}
      }
    },
    msSanteConfig: {
      title: 'Configure MS Sante email send',
      fieldDetected: 'Detected',
      mustContainAField: 'Must contain a field named',
      mustContainAReferenceChildSystemField: 'Must contain a child system field of the reference named',
      mustContainASystemField: 'Must contain a System field named',
      mustContainAReferenceFields: 'Must contain Reference system field(s) starting with',
      mustContainReferenceFieldChildsFields: 'Must contain childs system fields of the reference named',
      mustContainFields: 'Must contain fields starting with',
      field: {
        lastName: 'Lastname',
        firstName: 'Firstname',
        rpps: 'RPPS',
        telecom: 'Email',
        zipCode: 'Zipcode',
        city: 'City',
        country: 'Country',
        address: 'Address',
        lifenId: 'Lifen ID',
        contentToSend: 'Send content',
        sendButton: 'Send button',
        trackingList: 'tracking list field',
        recipient: 'Recipients fields',
        sender: 'Sender field(s)'
      },
      messagesFields: 'Messages fields'
    },
    fields: {
      reference: 'Reference',
      dropdown: 'Dropdown',
      datetime: 'DateTime',
      radio: 'Radio',
      checkbox: 'Checkbox',
      text: 'Text',
      email: 'Email',
      heading: 'Heading',
      extensible: 'Extensible table',
      file: 'File',
      essay: 'Essay',
      javascript: 'JavaScript Button',
      phone: 'Phone Number',
      hidden: 'Hidden',
      username: 'Username',
      locations: 'Locations',
      appointment: 'Appointment',
      changelog: 'Changelog',
      combobox: 'Combobox',
      createAccountButton: 'Create Account Button',
      endFieldset: 'End Fieldset',
      instanceId: 'Instance Id',
      newLine: 'New Line',
      password: 'Password',
      pdf: 'PDF',
      pdfDrafts: 'PDF Drafts',
      primaryRole: 'PrimaryRole',
      psgData: 'PSG Data',
      region: 'Region',
      sectionText: 'Section Text',
      shippingLabel: 'Shipping Label',
      signature: 'Signature',
      startFieldset: 'Start Fieldset',
      theme: 'Theme',
      timezone: 'Timezone',
      video: 'Video'
    },
    refModal: {
      title: 'Add a Reference field',
      form: 'Form',
      addColumn: 'Add column',
      submitColumn: 'Submit column',
      alias: 'Alias',
      formField: 'Form field'
    },
    fullRowModal: {
      title: 'We can\'t add this field here.',
      warning: 'You are trying to add a field that needs to be the only field of its row.',
      question: 'There is not enough space on this row. Do you want to add this field inside a new row ?',
      confirm: 'Add in a new row'
    },
    scriptModal: {
      title: 'Scripts modal',
      onPageLoadScript: 'Script to execute on page load',
      save: 'Save'
    },
    form: {
      customizationOrder: {
        submit: 'Submit action',
        label: 'Label',
        action: 'Action',
        targetFields: 'Target fields',
        conditions: 'Conditions',
        addCondition: 'Add condition',
        conditionsInfo: 'Conditions are process with a logical AND. If you want to use a logical OR, you need to create a new conditional action.',
        conditionsInfo2: 'For a field with values, you can use the value system name. Use comma to separate multiple values.',
        condition: {
          label: 'Label',
          type: 'Type',
          field: 'Field',
          operator: 'Operator',
          value: 'Value',
          valueInfo: 'Set * for any value (including none), or leave empty for no value',
          listColumn: 'Column (optional)'
        },
        display: {
          title: 'Conditions',
          on: 'on',
          if: 'If'
        }
      }
    },
    modal: {
      calculationField: {
        title: 'Calculation field'
      },
      conditions: {
        title: 'Configure conditional actions'
      },
      autocompleteFields: {
        title: 'Autocomplete fields',
        targetField: 'Target field',
        value: 'Set value',
        type: {
          CONSTANT: 'Constant',
          DYNAMIC: 'Dynamic'
        },
        dynamicValueBasedOn: 'Dynamic value based on another field'
      },
      fieldValues: {
        title: 'Field values',
        form: {
          systemNameDuplicates: 'System name already used',
          confirmDelete: 'Replace & delete',
          replaceOptionLabel: 'Replace current values by',
        }
      },
      fieldDetails: {
        title: 'Field details'
      },
      changelog: {
        title: 'Changelogs',
        header: {
          date: 'Date',
          action: 'Action',
          author: 'Author',
          data: 'Changes'
        },
        empty: 'Empty'
      }
    }
  },
  french: {
    backToList: 'Retour à la liste',
    viewDetails: 'Voir les détails',
    lockedTooltip: 'Vous ne pouvez pas éditer un formulaire bloqué. Quelqu\'un d\'autre est entrain d\'effectuer des changements.',
    scripts: 'Scripts',
    formType: 'Types de formulaires',
    systemFields: 'Champs systèmes',
    configureConditionalActionsButton: 'Conditions',
    configureConditionalActions: 'Configurer les actions conditionnelles',
    saveChoices: {
      exportAllSections: 'Exporter les sections',
      exportAllFields: 'Exporter les champs'
    },
    backToFields: 'Retour aux champs',
    onPageLoadCodeInfo: 'Paramètres disponibles : e, user, instance, patient, Axios, actions',
    titleTemplate: {
      viewTitle: 'Template de titre',
      removeButtonTitle: 'Retirer le dernier élément'
    },
    tooltipMaxConstraintDesc: 'Si Max est vide, aucune contrainte maximum',
    scriptModal: {
      title: 'Scripts modaux',
      onPageLoadScript: 'Script à exécuter au chargement de la page',
      save: 'Enregistrer'
    },
    editField: {
      addSysField: 'Ajouter un system field',
      calculationField: 'Champ calcul',
      systemName: 'Nom système',
      headingField: 'Nom',
      readOnly: 'Lecture seule',
      necessary: 'Obligatoire',
      rowSize: 'Taille',
      showDate: 'Afficher la date',
      showTime: 'Afficher l\'heure',
      showClearButton: 'Afficher le bouton Supprimer',
      showAddButton: 'Afficher le bouton d\'ajout',
      autocompleteAtCreation: 'Auto-compléter à la création',
      autocompleteAtCreationInfo: 'Sera complété automatiquement par la location de l\'utilisateur s\'il en a une ou par son rôle principal si les types correspondent.',
      showEditButton: 'Afficher le bouton d\'édition',
      sortAlpha: 'Tri alphabétique',
      selfPopulation: 'Ajouter une entrée',
      userTextResponse: 'Utiliser la réponse utilisateur',
      setNowAtCreation: 'Mettre la date en cours à la création',
      setNowAtModification: 'Mettre la date en cours à la modification',
      setAtCreation: 'Remplir à la création',
      setAtModification: 'Remplir à la modification',
      alignOptionsVertically: 'Aligner les options verticalement',
      excludeFromClone: 'Exclure des clones',
      validateUserIdentity: 'Valider l\'identitée de l\'utilisateur',
      isSensitive: 'Données sensibles',
      isValidator: 'Valide le format JSON',
      isTemplateCreator: 'Est créateur de template',
      defaultTemplate: 'Template par défaut',
      defaultTemplatePlaceholder: 'Template par défaut',
      consentFields: 'Demander les consentements',
      defaultConsentFieldsPlaceholder: 'liste des consentements',
      fileField: 'Champ fichier associé',
      fileFieldPlaceholder: 'System field',
      defaultValue: 'Valeur par défaut',
      defaultValuePlaceholder: 'Valeur par défaut',
      defaultOption: 'Valeur par défaut',
      defaultOptionPlaceholder: 'Valeur par défaut (nom système)',
      defaultOptionInfo: 'Utilisez le nom système de la valeur que vous souhaitez voir sélectionnée par défaut. (Pour des valeurs multiples, séparez les par une virgule)',
      width: 'Largeur',
      labelHover: 'Libellé Hover',
      values: 'Valeurs',
      autocompleteFields: 'Auto-complétion de champs',
      addValue: 'Ajouter une valeur',
      importValues: 'Importer des valeurs',
      editList: 'Editer la liste',
      fileType: 'Type de fichier',
      fieldId: 'ID du champ',
      rowId: 'ID de la ligne',
      onClickCode: 'Code JS sur click',
      onChangeCode: 'Code JS sur changement de la valeur',
      onBlurCode: 'Code JS sur sortie du champ',
      onClickCodeInfo: 'Paramètres disponibles : e, user, instance, patient, Axios, actions',
      onChangeCodeInfo: 'Paramètres disponibles : e, user, instance, patient, Axios, actions',
      onBlurCodeInfo: 'Paramètres disponibles : e, user, instance, patient, Axios, actions',
      fileTypeSetting: {
        title: 'Contraintes de type de fichier',
        fileType: 'Type',
        allType: 'Tout Type',
        max: 'Max',
        min: 'Min',
        add: 'Ajouter contrainte'
      },
      openValuesModalButton: 'Gérer les valeurs',
      content: 'Contenu',
      contentPlaceholder: 'Contenu à afficher',
      fontColor: 'Couleur de la police',
      fontColorPlaceholder: 'Couleur de la police',
      fontSize: 'Taille de la police',
      fontSizePlaceholder: 'Taille de la police',
      cssClass: 'Classe CSS',
      cssClassInfoButton: 'Infos',
      cssClassInfoHeader: 'Class disponibles',
      cssClassInfoBody: 'text-center, text-end, font-weight-bold, font-italic, text-underline, bg-primary, ...',
      cssClassPlaceholder: 'Class séparées par un espace',
      redirectToHomePage: 'Rediriger vers la page d\'accueil',
      description: 'Description',
      saveTypeLabel: 'Type de sauvegarde',
      saveTypePlaceholder: 'Sélectionner...',
      saveType: {
        saveOnly: 'Sauvegarde seule',
        saveAndSign: 'Sauvegarde & signature',
        saveAsFinal: 'Sauvegarde en final',
        saveAndSignWithPinCode: 'Sauvegarde & signature avec code pin',
        saveAsFinalWithPinCode: 'Sauvegarde en final avec code pin'
      },
      documentType: 'Type de document'
    },
    basicFields: 'Champs simples',
    fieldTypes: 'Field Types',
    advancedFields: 'Champs avancés',
    viewAll: 'Voir tout',
    details: {
      formLabel: 'Libellé du formulaire',
      formSystemName: 'Nom du formulaire system',
      addInstance: 'Ajouter une instance',
      defaultAppointmentTime: 'Heure de RDV par défaut',
      defaultDuration: 'Durée par défaut',
      eventBackgroundColor: 'background-color de l\'évènement',
      needSlot: 'Besoin d\'un emplacement',
      restrictQEToSidebar: 'Restriction du QE à la Sidebar',
      useQuickEntryModal: 'Utiliser la modal de saisie rapide',
      showInSidebarAddDropdown: 'Afficher dans la Sidebar Ajout d\'un Dropdown',
      isResource: 'Est une ressource',
      isPatientResource: 'Pouvoir ajouter ce formulaire depuis la sidebar patient',
      biosDevices: 'Dispositifs Bioserenity',
      biosDevicesButton: 'Paramétrer',
      msSanteFeature: 'Envoi MS Santé',
      msSanteFeatureButton: 'Paramétrer'
    },
    swpConfig: {
      title: 'Configuration du dispositif',
      multiple: 'multiple',
      productFieldLabel: 'Produit',
      fieldDetected: 'Détecté',
      mustContainAField: 'Doit contenir un champ système nommé',
      mustContainFields: 'Doit contenir des champs système commençant par',
      helpInformations: 'Ces conditions concernent les champs systèmes, et s\'attendent à ce qu\'ils soient nommés ' +
        'de la même façon (en excluant le "#MON_NOM_DE_FORMULAIRE").',
      product: {
        eeg: 'Neuronaute',
        ecg: 'Cardioskin'
      },
      field: {
        eeg: {
          sessionIdField: 'ID de session (texte)',
          accessTokensField: 'Tokens d\'accès (texte)',
          openDeviceButtons: 'Ouverture de dispositif (bouton)',
          openPlaybackButtons: 'Ouverture de lecture (bouton)',
          openMonitorButtons: 'Ouverture du monitoring (bouton)',
          productSerialNumberField: 'Numéro de série du produit (texte)',
          startRecordAtField: 'Enregistrement commence à (date)',
          endRecordAtField: 'Enregistrement termine à (date)',
          recordDurationField: 'Durée d\'enregistrement (texte)',
          sessionStatus: 'Status de session (texte)',
        },
        ecg: {}
      }
    },
    msSanteConfig: {
      title: 'Configuration de l\'envoi de mail MS Santé',
      mustContainAField: 'Doit contenir un champ nommé',
      mustContainAReferenceChildSystemField: 'Doit contenir un systéme field enfant nommé',
      mustContainAReferenceFields: 'Doit contenir un/des systéme fields référence commencant par',
      mustContainReferenceFieldChildsFields: 'Doit contenir des systémes fields enfants de la référence nommés',
      mustContainFields: 'Doit contenir des champs commençant par',
      fieldDetected: 'Détecté',
      field: {
        lastName: 'Nom',
        firstName: 'Prénom',
        rpps: 'RPPS',
        telecom: 'Adresse email',
        zipCode: 'Code postal',
        city: 'Ville',
        country: 'Pays',
        address: 'Adresse',
        lifenId: 'Lifen ID',
        contentToSend: 'Contenu de l\'envoi',
        sendButton: 'Bouton d\'envoi',
        trackingList: 'Champ de liste de trackingID',
        recipient: 'Champs destinataires',
        sender: 'Champ(s) expéditeur'
      },
      messagesFields: 'Champs messages'
    },
    fields: {
      reference: 'Référence',
      dropdown: 'Menu déroulant',
      datetime: 'Date/Heure',
      radio: 'Radio',
      checkbox: 'Case à cocher',
      text: 'Texte',
      email: 'Courriel',
      heading: 'Titre',
      extensible: 'Tableau extensible',
      file: 'Fichier',
      essay: 'Essay',
      javascript: 'JavaScript Button',
      phone: 'Numéro de téléphone',
      hidden: 'Cachée',
      username: 'Nom d\'utilisateur',
      locations: 'Emplacements',
      appointment: 'Rendez-vous',
      changelog: 'Journal des modifications',
      combobox: 'Combobox',
      createAccountButton: 'Créer un bouton de compte',
      endFieldset: 'EndFieldset',
      instanceID: 'ID d\'instance',
      newLine: 'NouvelleLigne',
      password: 'Mot de passe',
      pdf: 'PDF',
      pdfDrafts: 'Brouillons PDF',
      primaryRole: 'PrimaryRole',
      psgData: 'Données PSG',
      region: 'Région',
      sectionText: 'Texte de la section',
      shippingLabel: 'Libellé d\'expédition',
      signature: 'Signature',
      startFieldset: 'StartFieldset',
      theme: 'Thème',
      timezone: 'Fuseau horaire',
      video: 'Vidéo'
    },
    refModal: {
      title: 'Créer un champ Référence',
      form: 'Formulaire',
      addColumn: 'Ajouter une colonne',
      submitColumn: 'Ajouter la colonne',
      alias: 'Alias',
      formField: 'Champ du formulaire'
    },
    fullRowModal: {
      title: 'Nous ne pouvons pas ajouter ce champ ici.',
      warning: 'Vous essayez d\'ajouter un champ qui nécessite d\'être seul sur sa ligne.',
      question: 'Il n\'y a pas assez de place sur cette ligne. Souhaitez-vous l\'ajouter dans une nouvelle ligne ?',
      confirm: 'Ajouter dans une nouvelle ligne'
    },
    modal: {
      calculationField: {
        title: 'Champ calcul'
      },
      fieldValues: {
        title: 'Valeurs du champ',
        form: {
          systemNameDuplicates: 'System name already used'
        }
      },
      fieldDetails: {
        title: 'Détails du champ'
      },
      autocompleteFields: {
        title: 'Auto-complétion de champs',
        targetField: 'Champ cible',
        value: 'Valeur à appliquer',
        type: {
          CONSTANT: 'Constante',
          DYNAMIC: 'Dynamique'
        },
        dynamicValueBasedOn: 'Valeur dynamique basée sur un autre champ'
      },
      changelog: {
        title: 'Historique des changements',
        header: {
          date: 'Date',
          action: 'Action',
          author: 'Auteur',
          data: 'Modifications'
        },
        empty: 'Vide'
      }
    }
  }
}
