export const CHANGE_LIMIT = '[ADMIN_TOOLS] CHANGE_LIMIT'
export const CHANGE_PAGE = '[ADMIN_TOOLS] CHANGE_PAGE'
export const FETCH_ADMIN_TOOLS = '[ADMIN_TOOLS] FETCH_ADMIN_TOOLS'
export const RECEIVE_ADMIN_TOOLS = '[ADMIN_TOOLS] RECEIVE_ADMIN_TOOLS'
export const SEARCH_ADMIN_TOOL = '[ADMIN_TOOLS] SEARCH_ADMIN_TOOL'

export const ADD_ADMIN_TOOL = '[ADMIN_TOOLS] ADD_ADMIN_TOOL'
export const UPDATE_ADMIN_TOOL = '[ADMIN_TOOLS] UPDATE_ADMIN_TOOL'
export const DELETE_ADMIN_TOOL = '[ADMIN_TOOLS] DELETE_ADMIN_TOOL'
export const ADMIN_TOOL_ADDED = '[ADMIN_TOOLS] ADMIN_TOOL_ADDED'
export const ADMIN_TOOL_UPDATED = '[ADMIN_TOOLS] ADMIN_TOOL_UPDATED'
export const ADMIN_TOOL_DELETED = '[ADMIN_TOOLS] ADMIN_TOOL_DELETED'
export const TOGGLE_ADD_ADMIN_TOOL = '[ADMIN_TOOLS] TOGGLE_ADD_ADMIN_TOOL'
export const TOGGLE_UPDATE_TOOL = '[ADMIN_TOOLS] TOGGLE_UPDATE_ADMIN_TOOL'
export const TOGGLE_DELETE_ADMIN_TOOL = '[ADMIN_TOOLS] TOGGLE_DELETE_ADMIN_TOOL'


