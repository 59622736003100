import React, { useEffect, useState } from 'react'
import { translate } from 'src/Services/translation'
import { Button, Col, Row, Form } from 'react-bootstrap'
import Select from 'react-select'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ListType } from 'src/Types/List'
import {
  addNewList, cloneList, requestCloneList,
  setSelectedListForm,
  setSelectedListType,
  toggleAddingList
} from '../state/actions'
import translation from '../translations'
import EntitySelect from '../../../Components/EntitySelect'
import Modal from '../../../Components/Modal'
import SystemNameInput from '../../../Components/SystemNameInput/index'
import { showWarning } from '../../../Services/notifier/actions/index'

const AddListModal = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { selectedListType, selectedListForm, cloningList, addingList } = useSelector(state => state.Lists)
  const { listTypes } = useSelector(state => state.Dictionary)
  const { language } = useSelector(state => state.Root.user)

  const trans = translate(translation)(language)

  const [ label, setLabel ] = useState(null)
  const [ description, setDescription ] = useState(null)
  const [ isSystemNameAvailable, setIsSystemNameAvailable ] = useState(null)

  useEffect(() => {
    if (cloningList) {
      setLabel(cloningList.label)
      setDescription(cloningList.description)
    }
  }, [ cloningList ])

  const submitForm = e => {
    e.preventDefault()

    const [ systemName, label, description ] = [
      e.target.systemName.value,
      e.target.label.value,
      e.target.description.value
    ]

    if (!selectedListForm?.id)
      return dispatch(showWarning(trans('modal.newList.formMissing')))

    if (!selectedListType)
      return dispatch(showWarning(trans('modal.newList.typeMissing')))

    if (cloningList)
      dispatch(cloneList(cloningList.id, { systemName, label, description }, navigate))
    else
      dispatch(addNewList({ systemName, label, description, type: selectedListType, form: selectedListForm }))
  }

  const onFormsDropdownItemClick = selected => dispatch(setSelectedListForm(selected))
  const onClose = () => addingList
    ? dispatch(toggleAddingList(false))
    : dispatch(requestCloneList(null))
  const onTypesDropdownItemClick = selected => dispatch(setSelectedListType(selected?.value))

  const onSystemNameChange = (systemName, isAvailable) => {
    setIsSystemNameAvailable(isAvailable)
  }

  const getTypes = () => listTypes.filter(lt => ![
    ListType.REFERENCE, ListType.HOMEPAGE, ListType.SYSTEM
  ].includes(lt))

  return (
    <Modal title={ trans('modal.newList.title') }
           isOpen={ cloningList || addingList }
           onClose={ onClose }
    >
      <Form onSubmit={ submitForm }>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>{ trans('modal.newList.listType') }</Form.Label>
              <Select options={ getTypes().map(type => ({ value: type, label: type })) }
                      name={ 'listType' }
                      value={ selectedListType ? ({ value: selectedListType, label: selectedListType }) : null }
                      onChange={ onTypesDropdownItemClick }
                      isDisabled={ cloningList }
                      required id={ 'listTypeSelect' }
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className={ 'mt-2' }>
          <Col>
            <Form.Group>
              <Form.Label>{ trans('form') }</Form.Label>
              <EntitySelect fetchUrl={ '/forms' } name={ 'form' } labelKey={ 'label' }
                            value={ selectedListForm }
                            onChange={ onFormsDropdownItemClick } required id={ 'formSelect' }
                            disabled={ cloningList }
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className={ 'mt-2' }>
          <Col>
            <Form.Group>
              <Form.Label>{ trans('label') }</Form.Label>
              <Form.Control name={ 'label' } required value={ label || '' }
                            onChange={ e => setLabel(e.target.value) }/>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>{ trans('systemName') }</Form.Label>
              <SystemNameInput label={ label } required model={ 'ZmrList' } onChange={ onSystemNameChange }/>
            </Form.Group>
          </Col>
        </Row>
        <Row className={ 'mt-2' }>
          <Col>
            <Form.Group>
              <Form.Label>{ trans('description') }</Form.Label>
              <Form.Control as={ 'textarea' } name={ 'description' } value={ description || '' }
                            onChange={ e => setDescription(e.target.value) } />
            </Form.Group>
          </Col>
        </Row>
        <Row className={ 'mt-2' }>
          <Col>
            <Button type={ 'submit' } className={ 'float-right' } disabled={ !isSystemNameAvailable }>
              { trans(cloningList ? 'clone' : 'submit') }
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default AddListModal
