import { all, call, put, takeLatest } from 'redux-saga/effects'
import { FETCH_WORKFLOW_MANAGER } from './actionTypes'
import { workflowManagerReceived } from './actions'
import apiMethods from 'src/Services/api/apiMethods'

function* fetchWorkflowManager(props: any): any {
  try {
    const [
        { data: actions },
        { data: workflows },
        { data: triggers },
    ] = yield all([
      call(apiMethods.get, '/workflow/actions', { all: true }),
      call(apiMethods.get, '/workflow/items', { all: true }),
      call(apiMethods.get, '/workflow/triggers', { all: true }),
    ])

    yield put(workflowManagerReceived(({
      actions,
      workflows,
      triggers
    })))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'loadListFailed'))
  }
}

export default function* workflowActionsSagaWatcher(props: any) {
  yield takeLatest(FETCH_WORKFLOW_MANAGER, fetchWorkflowManager, props)
}
