
export default {
  english: {
    title: 'Restorer',
    restoreDeletedFields: 'Restore Fields',
    restoreForms: 'Restore Forms',
    restoreInstances: 'Restore Instances',
    restoreSections: 'Restore Sections'
  },
  french: {
    title: 'Restaurateur',
    restoreDeletedFields: 'Restaurer les champs',
    restoreForms: 'Restaurer les formulaires',
    restoreInstances: 'Restaurer les instances',
    restoreSections: 'Restaurer les sections'
  }
}
