import React from 'react'
import EntitySelect from 'src/Components/EntitySelect'
import { Form } from 'src/Types/Form'
import { ListType } from 'src/Types/List'
import ReferenceField from 'src/Types/ReferenceField'

interface Props {
  type: ListType
  form: Form
  refForm: Form
  name: string
  elementId: string
  size: string
  required: boolean
  onChange: (newValue: ReferenceField) => void
  value: ReferenceField | null
}

const ReferenceFieldSelect = ({
  type, refForm, form, value, onChange, name = 'columnField', elementId = 'referenceFieldSelect', size = 'md', required = false,
}: Props) => {

  const getLabel = (referenceField: ReferenceField): React.ReactElement => {
    if (referenceField.label.includes('#'))
      return <span dangerouslySetInnerHTML={ { __html: referenceField.label.replace(/\((.*?)\)/g, '<i>($1)</i>') } }></span>

    return <span>{ referenceField.label }</span>
  }

  return <>
    <EntitySelect
        fetchUrl={ `/forms/${ type === ListType.REFERENCE ? refForm.id : form.id }/reference_fields` }
        value={ value }
        onChange={ onChange }
        name={ name }
        id={ elementId }
        labelKey={ 'label' }
        isDefaultOptions
        getOptionLabel={ getLabel }
        required={ required }
        size={ size }
    />
  </>
}

export default ReferenceFieldSelect
