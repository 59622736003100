import * as types from './actionTypes'

export const setOptions = (data: object) => ({
  type: types.SET_OPTIONS,
  payload: data
})

export const setRightHeaderTemplate = (data: object) => ({
  type: types.SET_RIGHT_TEMPLATE,
  payload: data
})

export const setIsLoading = (data: boolean) => ({
  type: types.TOGGLE_IS_LOADING,
  payload: data
})
