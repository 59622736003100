import React, { useState } from 'react'
import { setPage, setQuery, setLimit, fetchItems } from '../../Tasks/state/actions'
import { translate } from '../../../Services/translation'
import translation from '../../Tasks/translations'
import NavTable from '../../../Components/NavTable'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ButtonGroup } from 'react-bootstrap'

const TasksPageTable = ({ onUpdateClick, onDeleteClick }) => {
  const dispatch = useDispatch()

  const { language } = useSelector(state => state.Root.user)
  const { items, isLoading, pagination } = useSelector(state => state.Tasks)

  const trans = translate(translation)(language)
  const [ fetchListRequest, setFetchListRequest ] = useState(null)

  const columnHeaders = [ trans('name'), trans('actions') ]
  const rows = getRows(items, trans, onDeleteClick, onUpdateClick)

  const applyNewFilters = () => {
    clearTimeout(fetchListRequest)
    setFetchListRequest(dispatch(fetchItems()))
  }

  const onSearchInputChange = e => {
    e.preventDefault()
    dispatch(setQuery(e.target.value))
    applyNewFilters()
  }

  const onPageClick = (currentPage, page) => {
    if (currentPage === page)
      return

    dispatch(setPage(page))
    applyNewFilters()
  }

  const onLimitChange = limit => {
    dispatch(setLimit(limit))
    applyNewFilters()
  }

  return <NavTable pagination={ pagination }
                   rows={ rows }
                   columnHeaders={ columnHeaders }
                   onSearchInputChange={ onSearchInputChange }
                   onLimitChange={ onLimitChange }
                   onPageClick={ onPageClick }
                   isLoading={ isLoading }
  />
}

export const ActionCell = ({
  onDeleteClick = () => {},
  onEditClick = () => {},
  trans
}) =>
  <ButtonGroup>
    <Button variant={ 'outline-info' } size={ 'sm' } onClick={ onDeleteClick }>
      <i className="fas fa-trash"/> { trans('delete') }
    </Button>
    <Button variant={ 'info' } size={ 'sm' } onClick={ onEditClick }>
      <i className="fas fa-edit"/> { trans('edit') }
    </Button>
  </ButtonGroup>

const getRows = (
  items,
  trans,
  onDeleteClick,
  onUpdateClick
) =>
  items.map(item => [
    <>{ item.name }</>,
    <ActionCell
      onDeleteClick={ e => onDeleteClick(e, item) }
      onEditClick={ e => onUpdateClick(e, item) }
      trans={ trans }
    />
  ])

export default TasksPageTable
