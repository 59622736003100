import React, { FC } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { User } from 'src/Types/User'
import { toggleConfirmModal } from 'src/Services/Store/GlobalControls/actions'
import { deleteUser, impersonateUser } from '../state/actions'
import { translate } from 'src/Services/translation'
import translation from '../translations'
import { StoreState } from 'src/Services/Store/reducers'

const ActionButtons: FC<{ user: User }> = ({ user }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { language, logAsRoles } = useSelector((state: StoreState) => state.Root.user)

  const trans = translate(translation)(language)

  const onDeleteClick = (user: User) => dispatch(toggleConfirmModal(() => {
    dispatch(deleteUser(user, navigate))
  }))

  const onLogAsClick = (user: User) => dispatch(impersonateUser(user))

  const getImpersonatedUser = () => localStorage.getItem('impersonatedUser')

  const canUserImpersonate = (targetUser: User): boolean =>
    logAsRoles.some(r => targetUser.roles.some(tr => tr.systemName === r.systemName))

  return (
    <div className="table-actions">
      <Button variant="outline-danger" size="sm" onClick={ () => onDeleteClick(user) } title={trans('delete')}>
        <i className="fas fa-trash-can"/>
      </Button>

      <Link to={ `/user/${ user.id }` }>
        <Button variant="outline-primary" size="sm" title={trans('edit')}>
          <i className="fas fa-light fa-pen"/>
        </Button>
      </Link>

      {
        !user.isAdmin && canUserImpersonate(user) &&
        <Button variant="outline-primary"
                size="sm"
                onClick={ () => onLogAsClick(user) }
                disabled={ Boolean(getImpersonatedUser())}
                title={ trans(getImpersonatedUser() ? 'logAsDisabledTitle' : 'logAsTitle') }
        >
          <i className="fas fa-user"/>
        </Button>
      }
    </div>
  )
}

export default ActionButtons
