import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { StoreState } from 'src/Services/Store/reducers'

const Badge = styled.span`
  padding: 1px 15px 2px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
  line-height: 14px;
  color: #fff;
  margin-right: 10px;
`

const EventStatusesList = () => {
  const { eventStatuses } = useSelector((state: StoreState) => state.Dictionary)

  return <>
    {eventStatuses.map(status =>
       <div style={{ marginBottom: '2px' }} key={status.id}>
         <>
           <Badge style={{ backgroundColor: status.color }}>
             <i className="fas fa-pencil-alt"></i>
           </Badge>
           {status.label || status.systemName}
         </>
       </div>,
    )}
  </>
}

export default EventStatusesList
