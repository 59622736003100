
export default {
  english: {
    fetchError: 'Error while fetching the documents.',
    lastModified: 'Last modified',
    lastModifiedBy: 'Last modified by',
    nameEditionError: 'Error while renaming the document.',
    patientDocuments: 'Patient documents',
    uploadedAt: 'Upload date',
    uploadedBy: 'Uploaded by'
  },
  french: {
    fetchError: 'Erreur lors de la récupération des documents.',
    lastModified: 'Dernière modification',
    lastModifiedBy: 'Dernière modification par',
    nameEditionError: 'Erreur lors du renommage du document.',
    patientDocuments: 'Documents du patient',
    uploadedAt: 'Date d\'envoi',
    uploadedBy: 'Envoyé par'
  }
}
