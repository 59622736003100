import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { useSelector } from 'react-redux'
import ListGroup from 'react-bootstrap/ListGroup'

const MissingFieldsModal = ({
  isOpen = false,
  trans,
  onClose,
  missingFields
}) => {
  const { fields } = useSelector(state => state.FormFiller.openedInstance)

  return <Modal isOpen={ isOpen }
                onClosed={ onClose }
                id={ 'missingFieldsModal' }
                size={ 'lg' }
  >
    <ModalHeader toggle={ onClose }>
      { trans('modal.missingFields.title') }
    </ModalHeader>
    <ModalBody>
      <ListGroup>
        { missingFields.map((field, idx) =>
          <ListGroup.Item key={ idx }>
            {
              field.referenceFieldId
                ? `${ fields[field.referenceFieldId].label } - ${ field.label }`
                : field.label
            }
          </ListGroup.Item>
        ) }
      </ListGroup>
    </ModalBody>
  </Modal>
}

export default MissingFieldsModal
