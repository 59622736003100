export default {
  english: {
    title: 'Form Editor',
    searchForm: 'Search form',
    addNewForm: 'Add new form',
    entries: 'of {total} entries',
    exportFields: 'Configure Export Fields',
    systemFields: 'Manage System Fields',
    th: {
      lastModifiedAt: 'Last modified'
    },
    modal: {
      newForm: {
        title: 'Create a new form',
        formName: 'System name',
        type: 'Form type',
      },
      deleteForm: {
        title: 'Are you sure you want to delete this form ?'
      },
      confirmForm: {
        title: 'Are you sure you want to unlock this form ?'
      }
    }
  },
  french: {
    title: 'Editeur de formulaire',
    searchForm: 'Chercher un formulaire',
    save: 'Enregistrer',
    addNewForm: 'Créer un formulaire',
    entries: 'sur {total} entrées',
    exportFields: 'Configurer les champs d\'exportation',
    systemFields: 'Gérer les champs système',
    th: {
      lastModifiedAt: 'Dernière modification'
    },
    modal: {
      newForm: {
        title: 'Créer un nouveau formulaire',
        formName: 'Nom du formulaire',
        type: 'Type de formulaire',
      },
      deleteForm: {
        title: 'Êtes-vous sûr de vouloir supprimer ce formulaire ?'
      },
      confirmForm: {
        title: 'Voulez-vous vraiment déverrouiller ce formulaire?'
      }
    }
  }
}
